import { useMapElement } from '../../contextProviders/MapElementProvider';
import { getByID } from '../../../../../utils/collection';
import ButtonPrimary from '../../../../buttons/ButtonPrimary';
import screens from '../screens';
import Glyphicon from '../../../../generic/Glyphicon';
import { Trash } from '@phosphor-icons/react';
import translations from '../../../../../translations';
import DraggableTable, { type TableColumnList } from '../../../../generic/DraggableTable';
import type { Outcome } from '../../../../../types';

const OutcomeList = () => {
    const {
        mapElement,
        onEditOutcome,
        onDeleteOutcome,
        mapElements,
        setSelectedOutcomeIndex,
        onSwitchScreen,
        setMapElement,
    } = useMapElement();

    const onAddOutcome = () => {
        setSelectedOutcomeIndex(null);
        onSwitchScreen(screens.outcome);
    };

    const onReorderOutcomes = (orderedOutcomes: Outcome[]) => {
        setMapElement({
            ...mapElement,
            outcomes: orderedOutcomes.map((o, i) => ({ ...o, order: i })),
        });
    };

    const columns: TableColumnList<Outcome> = [
        {
            renderHeader: () => translations.COMMON_TABLE_name,
            renderCell: ({ item: { developerName }, rowIndex }) => (
                <button
                    className="link-emulate"
                    onClick={() => onEditOutcome(rowIndex)}
                    type="button"
                >
                    {developerName ?? ''}
                </button>
            ),
        },
        {
            renderHeader: () => translations.COMMON_TABLE_next_map_element,
            renderCell: ({ item: { nextMapElementId } }) =>
                nextMapElementId ? getByID(nextMapElementId, mapElements)?.developerName : '',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_order,
            renderCell: ({ item: { order } }) => order ?? '',
            size: '5rem',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_actions,
            renderCell: ({ item, rowIndex }) => (
                <div className="action-btn-wrapper">
                    <button
                        title={`Delete ${item.developerName}`}
                        className="table-icon table-icon-delete"
                        aria-label={`Delete ${item.developerName}`}
                        onClick={() => onDeleteOutcome(rowIndex)}
                        type="button"
                    >
                        <Trash />
                    </button>
                </div>
            ),
            size: '7rem',
        },
    ];

    return (
        <>
            <h4>{translations.OUTCOME_list_heading}</h4>
            <div className="margin-bottom flex">
                <ButtonPrimary onClick={onAddOutcome}>
                    <Glyphicon glyph="plus" />
                    {translations.OUTCOME_add_outcome_button_label}
                </ButtonPrimary>
            </div>
            <DraggableTable<Outcome>
                wrapperClassName="margin-bottom-large"
                columns={columns}
                items={mapElement.outcomes ?? []}
                testId="outcomes-list"
                tableId="outcomes-list"
                onReorder={onReorderOutcomes}
            />
        </>
    );
};

export default OutcomeList;
