import { Trash } from '@phosphor-icons/react';
import { type ComponentPropsWithoutRef, useState } from 'react';
import { notifyError } from '../../../js/actions/reduxActions/notification';
import { removeTenant } from '../../sources/organization';
import type { OrganizationTenant } from '../../types/organization';
import translations from '../../translations';
import { stringContains, stringReplace } from '../../utils/string';
import { isSubtenant } from '../../utils/tenant';
import { useAuth } from '../AuthProvider';
import SearchInput from '../generic/SearchInput';
import Table, { type TableColumnList } from '../generic/Table';
import TenantName from '../generic/TenantName';
import ConfirmModal from '../generic/modal/ConfirmModal';
import TenantUsersDetails from './TenantUsersDetails';
import { dateTimerFormatter } from './utils';

type JoinedTenantProps = {
    isLoading: boolean;
    tenants: OrganizationTenant[];
    setIsLoading: (isLoading: boolean) => void;
    fetchTenants: () => Promise<void>;
} & Pick<ComponentPropsWithoutRef<typeof ConfirmModal>, 'container'>;

type TenantItem = {
    addedAt: string | null;
    id: string;
    developerName: string;
    userCount: number;
};

const JoinedTenants = ({
    isLoading,
    container,
    tenants,
    setIsLoading,
    fetchTenants,
}: JoinedTenantProps) => {
    const [showConfirmRemove, setShowConfirmRemove] = useState(false);
    const [selectedTenant, setSelectedTenant] = useState<TenantItem | null>(null);
    const [searchTerm, setSearchTerm] = useState('');
    const { tenant, fetchUser } = useAuth();

    const tenantsToDisplay: TenantItem[] = tenants
        .flatMap((tenant) => [
            tenant,
            ...tenant.subtenants.map((subtenant) => ({
                ...subtenant,
                addedAt: tenant.addedAt,
                userCount: tenant.userCount,
            })),
        ])
        .filter((tenant) => stringContains(tenant.developerName, searchTerm, false));

    const handleRemove = (tenant: TenantItem) => {
        setSelectedTenant(tenant);
        setShowConfirmRemove(true);
    };

    const handleRemoveConfirm = async () => {
        if (!selectedTenant || !tenant) {
            return;
        }

        try {
            setIsLoading(true);

            await removeTenant(selectedTenant.id);

            if (selectedTenant.id === tenant.id) {
                // This will trigger the orgs UI to only display tenant invites
                // since the current authorized tenant has been removed from the org
                await fetchUser();
            } else {
                // This will just refresh the list of org tenants currently listed.
                await fetchTenants();
            }

            setSelectedTenant(null);
            setShowConfirmRemove(false);
        } catch (error) {
            notifyError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const onSearch = (searchTerm: string) => {
        setSearchTerm(searchTerm);
    };

    const columns: TableColumnList<TenantItem> = [
        {
            renderHeader: () => translations.COMMON_TABLE_tenant,
            renderCell: ({ item: tenant }) => {
                return (
                    <span title={`${translations.COMMON_TABLE_id}: ${tenant.id}`}>
                        <TenantName name={tenant.developerName} />
                    </span>
                );
            },
        },
        {
            renderHeader: () => translations.COMMON_TABLE_users,
            renderCell: ({ item: tenant }) => {
                return <span>{tenant.userCount}</span>;
            },
        },
        {
            renderHeader: () => translations.COMMON_TABLE_date_joined,
            renderCell: ({ item: tenant }) => {
                return tenant.addedAt
                    ? dateTimerFormatter.format(new Date(tenant.addedAt))
                    : translations.FORG_unknown_field_value_text;
            },
            size: '11rem',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_actions,
            renderCell: ({ item: tenant }) => {
                return isSubtenant(tenant.developerName) ? (
                    <div className="table-icon" />
                ) : (
                    <div className="action-btn-wrapper">
                        <button
                            title={translations.FORG_joined_tenants_remove_button_label}
                            className="table-icon table-icon-delete"
                            aria-label={translations.FORG_joined_tenants_remove_button_label}
                            onClick={() => handleRemove(tenant)}
                            type="button"
                        >
                            <Trash />
                        </button>
                    </div>
                );
            },
            size: '5rem',
        },
    ];

    return (
        <div data-testid="tenant-members">
            <SearchInput value={searchTerm} onChange={onSearch} />
            <Table
                isLoading={isLoading}
                caption={translations.FORG_joined_tenants_table_caption}
                wrapperClassName="margin-top"
                columns={columns}
                items={tenantsToDisplay}
                pagination={true}
                rowClassName={() => 'generic-row table-row-expandable'}
                renderExpandedRow={(item) => <TenantUsersDetails tenantId={item.id} />}
                onRowSelect={() => true}
            />
            {selectedTenant && (
                <ConfirmModal
                    show={showConfirmRemove}
                    title={translations.FORG_remove_tenant_title}
                    messages={[
                        <TenantName
                            key={selectedTenant.developerName}
                            name={stringReplace(translations.FORG_remove_tenant_message, {
                                name: selectedTenant.developerName,
                            })}
                        />,
                    ]}
                    onConfirm={handleRemoveConfirm}
                    onCancel={() => setShowConfirmRemove(false)}
                    buttonStyle="danger"
                    buttonCaption={translations.COMMON_remove}
                    isInProgress={isLoading}
                    container={container}
                />
            )}
        </div>
    );
};

export default JoinedTenants;
