import { getElements } from '../../../config/graphElements';
import translations from '../../../translations';
import type {
    Edge,
    EdgeCollection,
    FlowGraphGroupElementResponseAPI,
    FlowGraphMapElementResponseAPI,
    GraphElement,
} from '../../../types';
import { stringReplace } from '../../../utils/string';
import ButtonDanger from '../../buttons/ButtonDanger';
import ButtonDefault from '../../buttons/ButtonDefault';
import { useFeatureFlag } from '../../featureFlags/FeatureFlagProvider';
import Table, { type TableColumnList } from '../../generic/Table';
import ModalBody from '../../generic/modal/ModalBody';
import ModalFooter from '../../generic/modal/ModalFooter';
import ConfigModal from '../../graph/ConfigModal';
import { getDescendantElements } from '../utils/node';

interface Props {
    focusedObjectId: string;
    selectedObjectIds: string[];
    edges: EdgeCollection;
    mapElements: FlowGraphMapElementResponseAPI[];
    groupElements: FlowGraphGroupElementResponseAPI[];
    container: HTMLDivElement;
    dismissMapElementConfig: () => void;
    onConfirmDelete: (nodeId: string) => Promise<void>;
}

const DeleteElements = ({
    mapElements,
    groupElements,
    edges,
    focusedObjectId,
    selectedObjectIds,
    container,
    dismissMapElementConfig,
    onConfirmDelete,
}: Props) => {
    const { getFlag } = useFeatureFlag();

    const allElements: GraphElement[] = [...mapElements, ...groupElements];
    const elementsToDelete: GraphElement[] = [];
    let descendants: GraphElement[] = [];
    let connectedEdges: Edge[] = [];

    if (focusedObjectId === 'selectionBox') {
        // Multiple, or all selected elements to delete
        selectedObjectIds.forEach((id) => {
            const element = allElements.find((element) => element.id === id);
            if (element) {
                elementsToDelete.push(element);
            }
        });
    } else {
        // Single, focused element to delete
        const focusedElement = allElements.find((element) => element.id === focusedObjectId);
        if (focusedElement) {
            elementsToDelete.push(focusedElement);
        }
    }

    const isMultiDelete = elementsToDelete.length > 1;

    elementsToDelete.forEach((elementToDelete) => {
        const descendantsOfElement = getDescendantElements(allElements, elementToDelete);
        const descendantIds = descendantsOfElement.map((element) => element.id);

        const connectedEdgesOfElements = Object.values(edges).filter((edge) => {
            const elementsIdsToCheck = [elementToDelete.id, ...descendantIds];
            return (
                elementsIdsToCheck.includes(edge.nodeIds[0]) ||
                (edge.nodeIds[1] && elementsIdsToCheck.includes(edge.nodeIds[1]))
            );
        });

        descendants = descendants.concat(descendantsOfElement);
        connectedEdges = connectedEdges.concat(connectedEdgesOfElements);
    });

    let itemsToList = [...descendants, ...connectedEdges];

    if (isMultiDelete) {
        // If deleting multiple elements add them to the table because we won't name them in the message.
        itemsToList = itemsToList.concat(elementsToDelete);
    }

    const itemsToListDeduped = [...new Set(itemsToList)];

    const tableItems = itemsToListDeduped
        .map((item) => {
            const name = Object.hasOwn(item, 'label')
                ? (item as Edge).label
                : Object.hasOwn(item, 'developerName')
                  ? (item as GraphElement).developerName
                  : 'Unknown';

            const elementType = Object.hasOwn(item, 'nodeIds')
                ? 'Outcome'
                : getElements(getFlag).find(
                      (el) =>
                          el.id.toLowerCase() === (item as GraphElement).elementType.toLowerCase(),
                  )?.name || (item as GraphElement).elementType;

            return {
                id: item.id,
                name,
                elementType,
            };
        })
        .sort((a, b) => a.elementType.toLowerCase().localeCompare(b.elementType.toLowerCase()));

    const columns: TableColumnList<(typeof tableItems)[0]> = [
        {
            renderHeader: () => 'Name',
            renderCell: ({ item }) => item.name,
        },
        {
            renderHeader: () => 'Element Type',
            renderCell: ({ item }) => item.elementType,
        },
        {
            renderHeader: () => 'ID',
            renderCell: ({ item }) => item.id,
        },
    ];

    const singleElementType = isMultiDelete
        ? ''
        : (elementsToDelete[0].elementType === 'input'
              ? 'page'
              : elementsToDelete[0].elementType
          ).toLocaleLowerCase();

    const title = isMultiDelete
        ? translations.ELEMENT_delete_multi
        : stringReplace(translations.ELEMENT_delete, singleElementType);

    const message = isMultiDelete
        ? null
        : stringReplace(
              translations.ELEMENT_delete_confirm,
              elementsToDelete[0].developerName,
              singleElementType,
          );

    const onDelete = async () => {
        await onConfirmDelete(focusedObjectId);
    };

    return (
        <ConfigModal
            id={''}
            title={title}
            elementType={'delete_header'}
            onHide={dismissMapElementConfig}
            container={container}
        >
            <ModalBody>
                {message && <p>{message}</p>}
                {tableItems.length > 0 && (
                    <>
                        <p>
                            {isMultiDelete
                                ? translations.ELEMENT_delete_multi_body
                                : translations.ELEMENT_delete_dependencies}
                        </p>
                        <Table
                            columns={columns}
                            items={tableItems}
                            wrapperClassName="table-scrollable"
                            tableClass="table"
                        />
                    </>
                )}
            </ModalBody>
            <ModalFooter>
                <ButtonDefault
                    key={translations.COMMON_cancel}
                    className="flex-child-right"
                    onClick={dismissMapElementConfig}
                >
                    {translations.COMMON_cancel}
                </ButtonDefault>
                <ButtonDanger
                    key={translations.COMMON_delete}
                    className="margin-left"
                    onClick={onDelete}
                >
                    {translations.COMMON_delete}
                </ButtonDanger>
            </ModalFooter>
        </ConfigModal>
    );
};

export default DeleteElements;
