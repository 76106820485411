import { Trash } from '@phosphor-icons/react';
import { type ComponentPropsWithoutRef, useState } from 'react';
import { notifyError, notifySuccess } from '../../../js/actions/reduxActions/notification';
import { removeUser } from '../../sources/organization';
import type { OrganizationUser } from '../../types/organization';
import translations from '../../translations';
import { resolveOrgUserRole } from '../../utils/organization';
import { stringReplace } from '../../utils/string';
import { useAuth } from '../AuthProvider';
import Table, { type TableColumnList } from '../generic/Table';
import ConfirmModal from '../generic/modal/ConfirmModal';
import { dateTimerFormatter } from './utils';

type JoinedUsersProps = {
    isLoading: boolean;
    users: OrganizationUser[];
    setIsLoading: (isLoading: boolean) => void;
    fetchUsers: () => Promise<void>;
} & Pick<ComponentPropsWithoutRef<typeof ConfirmModal>, 'container'>;

const JoinedUsers = ({
    isLoading,
    container,
    users,
    setIsLoading,
    fetchUsers,
}: JoinedUsersProps) => {
    const [showConfirmRemove, setShowConfirmRemove] = useState(false);
    const [selectedUser, setSelectedUser] = useState<OrganizationUser | null>(null);

    const { fetchUser, user, tenant } = useAuth();

    const handleRemove = (user: OrganizationUser) => {
        setSelectedUser(user);
        setShowConfirmRemove(true);
    };

    const handleRemoveConfirm = async () => {
        if (!selectedUser || !user || !tenant) {
            return;
        }

        try {
            setIsLoading(true);

            await removeUser(selectedUser.id);

            notifySuccess(
                stringReplace(translations.FORG_left_confirmation, {
                    user: selectedUser.email,
                }),
            );

            if (selectedUser.id === user.id) {
                // Currently authorized user has been removed from the org,
                // so this will trigger them to be taken to the non-member org screen.
                await fetchUser();
            } else if (
                selectedUser.id !== user.id &&
                resolveOrgUserRole(user, tenant).isUserAdmin
            ) {
                // This will refresh the list of users that are org members
                await fetchUsers();
            }

            setSelectedUser(null);
            setShowConfirmRemove(false);
        } catch (error) {
            notifyError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const columns: TableColumnList<OrganizationUser> = [
        {
            renderHeader: () => translations.COMMON_TABLE_user,
            renderCell: ({ item: user }) => (
                <span title={`${translations.COMMON_TABLE_id}: ${user.id}`}>{user.email}</span>
            ),
        },
        {
            renderHeader: () => translations.COMMON_TABLE_date_joined,
            renderCell: ({ item: user }) =>
                user.addedAt
                    ? dateTimerFormatter.format(new Date(user.addedAt))
                    : translations.FORG_unknown_field_value_text,
            size: '11rem',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_actions,
            renderCell: ({ item: user }) =>
                // cannot remove last user from an organization
                users.length === 1 ? (
                    <div className="table-icon" />
                ) : (
                    <div className="action-btn-wrapper">
                        <button
                            title={translations.FORG_joined_users_table_button_label}
                            className="table-icon table-icon-delete"
                            aria-label={translations.FORG_joined_users_table_button_label}
                            onClick={() => handleRemove(user)}
                            type="button"
                        >
                            <Trash />
                        </button>
                    </div>
                ),
            size: '5rem',
        },
    ];

    return (
        <div data-testid="admin-user-members">
            <Table
                isLoading={isLoading}
                caption={translations.FORG_joined_users_table_caption}
                wrapperClassName="margin-top"
                columns={columns}
                items={users}
                pagination={true}
            />
            {selectedUser && (
                <ConfirmModal
                    show={showConfirmRemove}
                    title={translations.FORG_remove_user_title}
                    messages={[
                        stringReplace(translations.FORG_remove_user_message, {
                            name: selectedUser.email,
                        }),
                    ]}
                    onConfirm={handleRemoveConfirm}
                    onCancel={() => setShowConfirmRemove(false)}
                    buttonStyle="danger"
                    buttonCaption={translations.COMMON_remove}
                    container={container}
                    isInProgress={isLoading}
                />
            )}
        </div>
    );
};

export default JoinedUsers;
