import Table, { type TableColumnList } from '../../../../../generic/Table';
import ButtonIcon from '../../../../../buttons/ButtonIcon';
import ButtonPrimary from '../../../../../buttons/ButtonPrimary';
import Glyphicon from '../../../../../generic/Glyphicon';
import translations from '../../../../../../translations';
import { useDataAction } from '../../../contextProviders/DataActionProvider';
import type { WhereStatement } from '../../../../../../types';

const FilterCriterias = () => {
    const {
        filterToEdit,
        onCreateDataActionFilterCriteria,
        onEditDataActionFilterCriteria,
        onDeleteDataActionFilterCriteria,
    } = useDataAction();

    const criterias = filterToEdit?.where || [];

    const columns: TableColumnList<WhereStatement> = [
        {
            renderHeader: () => translations.COMMON_TABLE_actions,
            renderCell: ({ item, rowIndex }) => (
                <>
                    <ButtonIcon
                        glyph="Edit"
                        onClick={() => onEditDataActionFilterCriteria(item, rowIndex)}
                        title={`Edit criteria where ${item.columnTypeElementPropertyDeveloperName} is ${item.criteriaTypeFriendly} ${item.valueElementToReferenceDeveloperName}`}
                        iconClass="icon-medium"
                    />
                    <ButtonIcon
                        className="margin-left-small danger"
                        glyph="Delete"
                        onClick={() => onDeleteDataActionFilterCriteria(rowIndex)}
                        title={`Delete criteria where ${item.columnTypeElementPropertyDeveloperName} is ${item.criteriaTypeFriendly} ${item.valueElementToReferenceDeveloperName}`}
                        iconClass="icon-medium"
                    />
                </>
            ),
        },
        {
            renderHeader: () => translations.COMMON_TABLE_property,
            renderCell: ({ item }) => item.columnTypeElementPropertyDeveloperName,
        },
        {
            renderHeader: () => translations.COMMON_TABLE_criteria,
            renderCell: ({ item }) => item.criteriaTypeFriendly,
        },
        {
            renderHeader: () => translations.COMMON_TABLE_value,
            renderCell: ({ item }) => item.valueElementToReferenceDeveloperName,
        },
    ];

    return (
        <>
            <div className="margin-bottom flex">
                <ButtonPrimary onClick={onCreateDataActionFilterCriteria} title="Add New Criteria">
                    <Glyphicon glyph="plus" />
                    {translations.DATA_ACTION_add_criteria_button_text}
                </ButtonPrimary>
            </div>
            <Table testId="filter-criteria-list" columns={columns} items={criterias} />
        </>
    );
};

export default FilterCriterias;
