import classnames from 'classnames';
import { XCircle } from '@phosphor-icons/react';
import { memo, type MouseEvent, type MouseEventHandler } from 'react';
import MapElementIcon from '../../../icons/MapElementIcon';
import { executeIfNotDragging } from '../../../../../js/components/graph/utils';
import type { MapElementRenderingProps } from './MapElementBase';
import { isNullOrEmpty } from '../../../../utils/guard';

/**
 * @description The draggable element that appears on the Flow canvas to represent functional map elements
 */
const StandardElement = ({
    startDrag,
    onSelect,
    isSelected,
    isDragging,
    dragging,
    styles,
    onDelete,
    ports,
    developerName,
    onDoubleClick,
    isHovered,
    actionText,
    elementType,
    keyboardDragging,
    isColorFillMode,
    selectedElementIds,
    isClone,
}: MapElementRenderingProps) => {
    const selectMapElement: MouseEventHandler<SVGRectElement> = (e: MouseEvent<SVGRectElement>) => {
        if (onSelect) {
            onSelect(e, selectedElementIds);
        }
    };

    return (
        <g onDoubleClick={onDoubleClick} data-testid={isClone ? 'map-element-clone' : undefined}>
            {/*
            Invisible out border around the element so hovering off the element is a bit harder.
            Without this, as soon as your mouse goes off the element (possibly towards the outcome port),
            the outcome is no longer hovered, and so the port disappears.
            */}
            {isHovered ? (
                <rect
                    {...styles.outBorder}
                    stroke={'none'}
                    data-testid="map-element-hover-out-border"
                    className="hover-out-border"
                />
            ) : null}
            {/* Visible dotted grey out border around the element */}
            {isSelected && (!isDragging || keyboardDragging) && (
                <rect
                    {...styles.outBorder}
                    data-testid="map-element-selected"
                    className="no-focus-border hover-out-border"
                />
            )}
            {/* Solid coloured border around the element */}
            <rect
                {...styles.border}
                data-testid="map-element-border"
                className="map-element-border"
                onMouseDown={startDrag}
                onMouseUp={selectMapElement}
            />
            {/* White/Optionally coloured fill inside the element */}
            <rect
                {...styles.fill}
                data-testid="standardElement"
                className="map-element-fill"
                onMouseDown={startDrag}
                onMouseUp={selectMapElement}
            />
            {/* Phosphor icon must stay within foreignObject due to Safari issues that do not support an svg within an svg */}
            {isSelected && !isDragging && onDelete && (
                <foreignObject {...styles.deleteIcon}>
                    <XCircle
                        size={10}
                        weight="fill"
                        className="map-element-delete"
                        data-testid="elementDeleteButton"
                        onClick={onDelete}
                        onMouseUp={(e) => executeIfNotDragging(e, dragging)}
                        alt="Delete"
                    />
                </foreignObject>
            )}
            {/* Icon and label foreign objects */}
            {!isDragging && ports}
            <foreignObject {...styles.content} onMouseDown={startDrag} onMouseUp={selectMapElement}>
                <span className="map-element-content">
                    <span
                        className={classnames({
                            'map-element-icon': true,
                            'safari-fix': true,
                            glyphicon: true,
                            'map-element-no-actions': isNullOrEmpty(actionText),
                            'color-white': isColorFillMode,
                        })}
                    >
                        <MapElementIcon elementType={elementType} size={15} />
                    </span>
                    <span className="map-element-text">
                        <span
                            className={classnames({
                                'map-element-developer-name': true,
                                'map-element-no-actions': isNullOrEmpty(actionText),
                                'color-white': isColorFillMode,
                            })}
                            title={developerName}
                        >
                            {developerName}
                        </span>
                        {actionText ? (
                            <span className="map-element-description">{actionText}</span>
                        ) : null}
                    </span>
                </span>
            </foreignObject>
        </g>
    );
};

/**
    return true if passing nextProps to render would return
    the same result as passing prevProps to render,
    otherwise return false
*/
function areEqual(prevProps: MapElementRenderingProps, nextProps: MapElementRenderingProps) {
    if (
        prevProps.mapElement?.x === nextProps.mapElement?.x &&
        prevProps.mapElement?.y === nextProps.mapElement?.y &&
        Boolean(prevProps.isSelected) === Boolean(nextProps.isSelected) &&
        Boolean(prevProps.isDragging) === Boolean(nextProps.isDragging) &&
        Boolean(prevProps.isHovered) === Boolean(nextProps.isHovered) &&
        Boolean(prevProps.keyboardDragging) === Boolean(nextProps.keyboardDragging) &&
        Boolean(prevProps.isColorFillMode) === Boolean(nextProps.isColorFillMode) &&
        prevProps.styles === nextProps.styles &&
        prevProps.actionText === nextProps.actionText &&
        prevProps.developerName === nextProps.developerName &&
        prevProps.selectedElementIds === nextProps.selectedElementIds &&
        // Only update when dragging changes if this element is being dragged
        (nextProps.isSelected && nextProps.isDragging
            ? prevProps.dragging.dragType === nextProps.dragging.dragType &&
              prevProps.dragging.elementId === nextProps.dragging.elementId &&
              prevProps.dragging.previousElementPosition ===
                  nextProps.dragging.previousElementPosition &&
              prevProps.dragging.previousMousePosition ===
                  nextProps.dragging.previousMousePosition &&
              prevProps.dragging.initialMousePosition === nextProps.dragging.initialMousePosition &&
              prevProps.dragging.hasMovedEnough === nextProps.dragging.hasMovedEnough
            : true)
    ) {
        return true;
    }
    return prevProps === nextProps;
}

export default memo(StandardElement, areEqual);
