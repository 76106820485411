import type { NavigationElementResponseAPI } from '../../../types';
import { isNullOrEmpty } from '../../../utils';

export type NavigationElement = Partial<NavigationElementResponseAPI>;

export type NavigationErrors = {
    url: boolean;
    label: boolean;
    mapElement: boolean;
};

export const isPageValid = (developerName: string | null, errors: NavigationErrors) => {
    if (isNullOrEmpty(developerName)) {
        return false;
    }

    if (!isNullOrEmpty(errors)) {
        if (errors.label || errors.mapElement || errors.url) {
            return false;
        }
    }

    return true;
};
