import { Trash } from '@phosphor-icons/react';
import type { WhereStatement } from '../../../../../../../../../types';

import ButtonPrimary from '../../../../../../../../buttons/ButtonPrimary';
import Glyphicon from '../../../../../../../../generic/Glyphicon';
import Table from '../../../../../../../../generic/Table';
import translations from '../../../../../../../../../translations';

const criteriaPhrase = {
    EQUAL: 'is equal to',
    NOT_EQUAL: 'is not equal to',
    GREATER_THAN: 'is greater than',
    GREATER_THAN_OR_EQUAL: 'is greater than or equal to',
    LESS_THAN: 'is less than',
    LESS_THAN_OR_EQUAL: 'is less than or equal to',
    STARTS_WITH: 'starts with',
    ENDS_WITH: 'ends with',
    CONTAINS: 'contains',
    IS_EMPTY: 'is Empty',
    ALL_EQUAL: 'All Equal',
    ANY_EQUAL: 'Any Equal',
};

interface Props {
    listItems: WhereStatement[] | null;
    editItem: (rowIndex: number) => void;
    deleteItem: (rowIndex: number) => void;
    createItem: () => void;
    screen?: string;
}

const FilterWhereList = ({ listItems, editItem, deleteItem, createItem }: Props) => {
    const columns = [
        {
            renderHeader: () => 'Return data rows WHERE...',
            renderCell: ({ item, rowIndex }: { item: WhereStatement; rowIndex: number }) => (
                <button
                    className="link-emulate"
                    title="Edit"
                    aria-label="Edit"
                    onClick={() => editItem(rowIndex)}
                    type="button"
                >
                    <div className="item-info">
                        Column
                        <span className="item-column">
                            {item.columnTypeElementPropertyDeveloperName}
                        </span>
                        {item.criteriaType && (
                            <span className="item-criteria">
                                {criteriaPhrase[item.criteriaType]}
                            </span>
                        )}
                        {item.criteriaType !== 'IS_EMPTY' ? (
                            <span className="item-value">
                                {item.valueElementToReferenceDeveloperName}
                            </span>
                        ) : (
                            <>
                                equals
                                <span className="item-value">
                                    {item.valueElementToReferenceDeveloperName}
                                </span>
                            </>
                        )}
                    </div>
                </button>
            ),
        },
        {
            renderHeader: () => translations.COMMON_TABLE_actions,
            renderCell: ({ rowIndex }: { rowIndex: number }) => (
                <div className="action-btn-wrapper">
                    <button
                        title="Delete"
                        className="table-icon table-icon-delete"
                        aria-label="Delete"
                        onClick={() => deleteItem(rowIndex)}
                        type="button"
                    >
                        <Trash />
                    </button>
                </div>
            ),
            size: '5rem',
        },
    ];

    return (
        <>
            <div className="flex flex-child-right">
                <ButtonPrimary onClick={createItem} glyph="plus" title="Add new filter condition">
                    <Glyphicon glyph="plus" />
                    Add filter condition
                </ButtonPrimary>
            </div>
            {listItems?.length ? <Table columns={columns} items={listItems} /> : null}
        </>
    );
};

export default FilterWhereList;
