import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { notifyError } from '../../../../../js/actions/reduxActions/notification';
import { MAP_ELEMENT_TYPES } from '../../../../constants';
import { getMapElement } from '../../../../sources/graph';
import translations from '../../../../translations';
import { stringReplace } from '../../../../utils/string';
import ButtonDefault from '../../../buttons/ButtonDefault';
import ButtonPrimary from '../../../buttons/ButtonPrimary';
import { useGraph } from '../../../../../js/components/graph/GraphProvider';
import Loader from '../../../loader/Loader';
import ConfigLayoutWrapper from '../common/ConfigLayoutWrapper';
import type { MapElement, NotifyError } from '../../../../types';

type Props = {
    id: string;
    dismissMapElementConfig: () => void;
    container: HTMLElement;
    flowId: string;
    nextMapElement: MapElement;
    outcomeId: string;
    targetId: string;
    onError: NotifyError;
};

const OutcomeRedirect = ({
    id,
    dismissMapElementConfig,
    container,
    flowId,
    nextMapElement,
    outcomeId,
    targetId,
    onError,
}: Props) => {
    const {
        saveMapElement,
        refreshFlow,
    }: { saveMapElement: (mapElement: MapElement) => Promise<void>; refreshFlow: () => void } =
        useGraph();
    const [mapElement, setMapElement] = useState<MapElement | null>(null);
    const title = translations.OUTCOME_confirm_move;
    const message = stringReplace(
        translations.OUTCOME_confirm_move_message,
        nextMapElement.developerName,
    );

    const elementType = MAP_ELEMENT_TYPES.outcome;

    const onSave = async () => {
        if (mapElement) {
            const updatedOutcomes =
                mapElement.outcomes?.map((outcome) => {
                    if (outcome.id === outcomeId) {
                        return {
                            ...outcome,
                            nextMapElementId: targetId,
                        };
                    }
                    return outcome;
                }) ?? null;

            const updatedMapElement = {
                ...mapElement,
                outcomes: updatedOutcomes,
            };

            await saveMapElement(updatedMapElement);
            dismissMapElementConfig();
        }
    };

    const onCancel = () => {
        // Reset the flow canvas
        refreshFlow();
        dismissMapElementConfig();
    };

    const fetchMapElement = async () => {
        try {
            const mapElementData = await getMapElement(id, flowId);

            setMapElement(mapElementData);
        } catch (error) {
            // If something goes wrong, then show an error
            // and just close the outcome redirect config panel
            onError(error);
            onCancel();
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        fetchMapElement();
    }, []);

    const renderFooter = () => (
        <>
            <ButtonDefault className="flex-child-right" onClick={onCancel}>
                {translations.GRAPH_config_panel_cancel}
            </ButtonDefault>
            <ButtonPrimary className="margin-left" onClick={onSave}>
                {translations.GRAPH_config_panel_save}
            </ButtonPrimary>
        </>
    );

    if (!mapElement) {
        return <Loader />;
    }

    return (
        <ConfigLayoutWrapper
            id={id}
            renderBody={() => <p>{message}</p>}
            renderFooter={renderFooter}
            title={title}
            elementType={elementType}
            onHide={onCancel}
            container={container}
        />
    );
};

export default connect(null, {
    onError: notifyError,
})(OutcomeRedirect);
