import { SYSTEM_VALUE_NAMES } from '../../../constants';
import { getItem, setItem } from '../../../utils/storage';
import type { ValueElementIdReferenceAPI } from '../../../types';
import { isNullOrEmpty } from '../../../utils/guard';

export const MAX_RECENT_ITEMS_CACHE_COUNT = 20;

export const isNonEditableValue = (value: ValueElementIdReferenceAPI) =>
    value.parentContentType ||
    SYSTEM_VALUE_NAMES.some((systemValue) => systemValue === value.developerName);

export const getValueName = (value: ValueElementIdReferenceAPI) => {
    return value.typeElementPropertyDeveloperName
        ? `${value.developerName} / ${value.typeElementPropertyDeveloperName}`
        : `${value.developerName}`;
};

export const getValuePropertyName = (value: ValueElementIdReferenceAPI) => {
    return value.typeElementPropertyDeveloperName;
};

const contentTypeToTypeDisplay = (contentType: string | null) => {
    if (!contentType) {
        return '';
    }

    switch (contentType) {
        case 'ContentBoolean':
            return 'Boolean';
        case 'ContentContent':
            return 'Content';
        case 'ContentDateTime':
            return 'DateTime';
        case 'ContentDate':
            return 'Date';
        case 'ContentList':
            return 'List';
        case 'ContentNumber':
            return 'Number';
        case 'ContentObject':
            return 'Object';
        case 'ContentPassword':
            return 'Password';
        case 'ContentEncrypted':
            return 'Encrypted';
        case 'ContentString':
            return 'String';
        case 'ContentCode':
            return 'Code';
        default:
            return '';
    }
};

export const getValueType = (value: ValueElementIdReferenceAPI) => {
    const parentType = value.parentContentType
        ? contentTypeToTypeDisplay(value.parentContentType)
        : null;
    const type = contentTypeToTypeDisplay(value.contentType);

    const parentTypeText = parentType ? `${parentType} of ` : '';
    const objectTypeText = value.typeElementDeveloperName
        ? `${value.typeElementDeveloperName}`
        : '';
    const serviceTypeText = value.serviceElementDeveloperName
        ? ` from  ${value.serviceElementDeveloperName}`
        : '';
    const separator = value.typeElementDeveloperName ? ' / ' : '';

    return `
    ${parentTypeText}
    ${objectTypeText}
    ${serviceTypeText}
    ${separator}
    ${type}
    `;
};

export const getValuePropertyType = (value: ValueElementIdReferenceAPI) => {
    return value.typeElementPropertyTypeElementDeveloperName
        ? `${contentTypeToTypeDisplay(value.contentType)} of ${
              value.typeElementPropertyTypeElementDeveloperName
          }`
        : contentTypeToTypeDisplay(value.contentType);
};

export const addRecentValue = (value: ValueElementIdReferenceAPI) => {
    if (!value) {
        return;
    }

    const storedValues = getItem<ValueElementIdReferenceAPI[]>('values-recent-new') ?? [];

    const filteredValues = storedValues.filter(
        (recentValue) =>
            recentValue.id !== value.id &&
            recentValue.typeElementPropertyId !== value.typeElementPropertyId,
    );

    filteredValues.unshift(value);

    setItem('values-recent-new', filteredValues.slice(0, MAX_RECENT_ITEMS_CACHE_COUNT));
};

export const getRecentValues = () =>
    getItem<ValueElementIdReferenceAPI[]>('values-recent-new') ?? [];

// Formats raw value/type metadata so that it is suitable for displaying in text fields
export const formatAsValue = (
    valueDeveloperName: string,
    typeElementPropertyDeveloperName: string | null,
) =>
    isNullOrEmpty(typeElementPropertyDeveloperName)
        ? `{![${valueDeveloperName.replace(/ /g, '&nbsp;')}]}`
        : `{![${valueDeveloperName.replace(
              / /g,
              '&nbsp;',
          )}].[${typeElementPropertyDeveloperName.replace(/ /g, '&nbsp;')}]}`;

/*
 * List of available Value Types
 *
 * requiresType: Indicates if the Value Type requires a Type specification, i.e. List & Object need a Type
 * canBeFormatted: Indicates if the Value can have user-defined formatting
 */
export const VALUE_TYPES = [
    {
        value: 'ContentString',
        inputType: 'text',
        label: 'String',
        requiresType: false,
        canBeFormatted: true,
    },
    {
        value: 'ContentNumber',
        inputType: 'number',
        label: 'Number',
        requiresType: false,
        canBeFormatted: true,
    },
    {
        value: 'ContentPassword',
        inputType: 'password',
        label: 'Password',
        requiresType: false,
        canBeFormatted: false,
    },
    {
        value: 'ContentBoolean',
        inputType: 'checkbox',
        label: 'Boolean',
        requiresType: false,
        canBeFormatted: false,
    },
    {
        value: 'ContentEncrypted',
        inputType: 'text',
        label: 'Encrypted',
        requiresType: false,
        canBeFormatted: false,
    },
    {
        value: 'ContentDateTime',
        inputType: 'text',
        label: 'Date/Time',
        requiresType: false,
        canBeFormatted: true,
    },
    {
        value: 'ContentDate',
        inputType: 'text',
        label: 'Date',
        requiresType: false,
        canBeFormatted: true,
    },
    {
        value: 'ContentContent',
        inputType: 'text',
        label: 'Content',
        requiresType: false,
        canBeFormatted: false,
    },
    {
        value: 'ContentObject',
        inputType: 'text',
        label: 'Object',
        requiresType: true,
        canBeFormatted: false,
    },
    {
        value: 'ContentList',
        inputType: 'text',
        label: 'List',
        requiresType: true,
        canBeFormatted: false,
    },
];

export const ELEMENT_LIST_VIEW = 'ELEMENT_LIST';
