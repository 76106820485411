import { type ComponentPropsWithoutRef, useState } from 'react';
import type { GroupBase, MultiValue, Options, OptionsOrGroups } from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
import '../../../../css/tenant-name-input.less';
import { notifyError, notifySuccess } from '../../../js/actions/reduxActions/notification';
import { REGEX_EMAIL, SYSTEM_ROLES } from '../../constants';
import { provisionTenantAndOrUser } from '../../sources/organization';
import { getAllUsersForCurrentTenant } from '../../sources/user';
import translations from '../../translations';
import { getSharedStyles } from '../../utils/select';
import { useAuth } from '../AuthProvider';
import FormGroup from '../generic/FormGroup';
import GenericModal from '../generic/modal/GenericModal';
import Loader from '../loader/Loader';
import TenantNameInput from '../tenant/TenantNameInput';
import { useDispatch } from 'react-redux';

type ProvisionTenantProps = {
    isLoading: boolean;
    setIsLoading: (isLoading: boolean) => void;
    fetchTenants: () => Promise<void>;
} & Pick<ComponentPropsWithoutRef<typeof GenericModal>, 'container' | 'show' | 'onHide'>;

type EmailOption = {
    label: string;
    value: string;
};

const ProvisionOrganizationTenant = ({
    isLoading,
    show = false,
    container,
    onHide,
    setIsLoading,
    fetchTenants,
}: ProvisionTenantProps) => {
    const [tenantName, setTenantName] = useState('');
    const [selectedEmails, setSelectedEmails] = useState<MultiValue<EmailOption>>([]);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [isTenantNameValid, setIsTenantNameValid] = useState(false);
    const dispatch = useDispatch();
    const { fetchUser } = useAuth();

    const isEmailValid = selectedEmails.length > 0;

    const resetForm = () => {
        setTenantName('');
        setSelectedEmails([]);
        setHasSubmitted(false);
    };

    const validateEmailOption = (
        input: string,
        _value: Options<EmailOption>,
        options: OptionsOrGroups<EmailOption, GroupBase<EmailOption>>,
    ) => {
        const isValid = REGEX_EMAIL.test(input.trim());
        const isNew = !options.find((option) => option.label === input);
        return isValid && isNew;
    };

    const loadUsersForCurrentTenant = async (searchString: string) => {
        try {
            const response = await getAllUsersForCurrentTenant();

            const newUserOptions = response.map(({ email }) => ({
                label: email,
                value: email,
            }));

            if (newUserOptions.length === 0) {
                return [];
            }

            const formattedSearchString = searchString.trim().toLowerCase();

            const filteredOptions = formattedSearchString
                ? newUserOptions.filter(({ label }) =>
                      label.toLowerCase().includes(formattedSearchString),
                  )
                : newUserOptions;

            return filteredOptions;
        } catch (error) {
            dispatch(notifyError(error));
            return [];
        }
    };

    const handleTenantNameChange = ({ value, isValid }: { value: string; isValid: boolean }) => {
        setTenantName(value);
        setIsTenantNameValid(isValid);
    };

    const handleCreate = async () => {
        setHasSubmitted(true);
        if (!isTenantNameValid || !isEmailValid) {
            return;
        }

        try {
            setIsLoading(true);

            await provisionTenantAndOrUser({
                developerName: tenantName,
                users: selectedEmails.map((email) => ({
                    email: email.value,
                    role: SYSTEM_ROLES.administrator.developerName,
                })),
            });

            await Promise.all([fetchTenants(), fetchUser()]);

            dispatch(notifySuccess(translations.FORG_tenant_user_provisioned));

            resetForm();
        } catch (error) {
            dispatch(notifyError(error));
        } finally {
            setIsLoading(false);
        }
    };

    const handleClose = () => {
        resetForm();
        onHide();
    };

    return (
        <GenericModal
            show={show}
            title={translations.FORG_provision_title}
            container={container}
            onHide={handleClose}
            renderBody={() => (
                <div>
                    <p>{translations.FORG_provision_description}</p>
                    <FormGroup
                        label={translations.FORG_provision_tenant_user_select_label}
                        labelId="user-select-label"
                        isRequired={true}
                        validationMessage={
                            translations.FORG_provision_tenant_user_select_validation_message
                        }
                        isValid={isEmailValid}
                        showValidation={hasSubmitted}
                    >
                        <AsyncCreatableSelect
                            isMulti
                            className="full-width"
                            styles={getSharedStyles<EmailOption, true>()}
                            isValidNewOption={validateEmailOption}
                            loadOptions={loadUsersForCurrentTenant}
                            defaultOptions
                            cacheOptions
                            onChange={setSelectedEmails}
                            value={selectedEmails}
                            formatCreateLabel={(input: string) =>
                                `${translations.FORG_provision_add_user_text} ${input}`
                            }
                            noOptionsMessage={(input: { inputValue: string }) =>
                                input.inputValue.length > 0
                                    ? `${input.inputValue} ${translations.FORG_provision_invalid_email_text}`
                                    : translations.FORG_provision_no_results_text
                            }
                            aria-labelledby="user-select-label"
                        />
                        <span className="help-block">
                            {translations.FORG_provision_tenant_user_select_help_text}
                        </span>
                    </FormGroup>
                    <TenantNameInput
                        value={tenantName}
                        inputId="tenant-name-input"
                        labelText={translations.FORG_provision_tenant_name_input_label}
                        onChange={handleTenantNameChange}
                        showValidation={hasSubmitted}
                    />
                    {isLoading && <Loader />}
                </div>
            )}
            renderFooter={() => (
                <>
                    <button type="button" className="btn btn-sm btn-default" onClick={handleClose}>
                        {translations.FORG_provision_tenant_cancel_button_label}
                    </button>
                    <button
                        type="button"
                        className="btn btn-sm btn-success"
                        onClick={handleCreate}
                        disabled={isLoading}
                    >
                        {translations.FORG_provision_tenant_submit_button_label}
                    </button>
                </>
            )}
        />
    );
};

export default ProvisionOrganizationTenant;
