import { MAP_ELEMENT_TYPES } from '../../../../constants';
import MapElementModal from '../../../graph/MapElementModal';
import screens from '../common/screens';
import { ListenerProvider } from '../contextProviders/ListenerProvider';
import {
    MapElementProvider,
    type MapElementConfigProps,
} from '../contextProviders/MapElementProvider';
import { MessageActionProvider } from '../contextProviders/MessageActionProvider';
import { WaitProvider } from '../contextProviders/WaitProvider';
import PageRouter from './PageRouter';

const Modal = (props: MapElementConfigProps) => {
    return (
        <MapElementProvider
            {...props}
            defaultScreen={screens.page}
            elementType={MAP_ELEMENT_TYPES.modal}
            isLoading={false}
        >
            <MapElementModal>
                <MessageActionProvider defaultScreen={screens.page}>
                    <ListenerProvider defaultScreen={screens.page}>
                        <WaitProvider>
                            <PageRouter />
                        </WaitProvider>
                    </ListenerProvider>
                </MessageActionProvider>
            </MapElementModal>
        </MapElementProvider>
    );
};

export default Modal;
