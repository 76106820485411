import { type ChangeEvent, type KeyboardEvent, type MouseEvent, useEffect, useState } from 'react';
import { createOrUpdateNavigationElements, getNavigationElement } from '../../../sources/flow';
import { isNullOrEmpty } from '../../../utils/guard';
import { guid } from '../../../utils/guid';
import Loader from '../../loader/Loader';
import Modal from '../../generic/modal/GenericModal';
import { filterOutNotes } from '../../../../js/components/graph/utils';
import { URL_TARGET } from '../constants';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import type { SingleValue } from 'react-select';
import {
    ElementType,
    type NavigationItemAPI,
    type AddNotification,
    type MapElement,
    type ValueElementIdAPI,
    type NavigationElementRequestAPI,
    type FlowGraphMapElementResponseAPI,
} from '../../../types';
import NavigationFooter from './editor/NavigationFooter';
import NavigationForm from './editor/NavigationForm';
import NavigationPreview from './editor/NavigationPreview';
import ConfigureNavigationItem from './editor/ConfigureNavigationItem';
import { isPageValid } from './utils';
import ConfigurePosition from './editor/ConfigurePosition';
import ConfigureState from './editor/ConfigureState';
import Comments from './editor/Comments';
import type { NavigationElement, NavigationErrors } from './utils';

type Props = {
    navigationElementId: string | null;
    tenantId: string;
    flowId: string;
    addNotification: AddNotification;
    close: () => void;
    closeModal: () => void;
    done: () => void;
    container: HTMLElement | null;
    mapElements: FlowGraphMapElementResponseAPI[];
};

type MapElementTypeOption = {
    label: string;
    value: string;
};

const NavigationEditor = ({
    navigationElementId,
    tenantId,
    flowId,
    addNotification,
    close,
    closeModal,
    done,
    container,
    mapElements,
}: Props) => {
    const [navigationElement, setNavigationElement] = useState<NavigationElement>({
        locationMapElementId: null,
        developerName: '',
        label: '',
        navigationItems: [],
        order: 0,
        persistState: true,
        persistValues: true,
        elementType: ElementType.Navigation,
        position: 'Top',
    });

    const [editing, setEditing] = useState<string | null>(null);
    const [editingParent, setEditingParent] = useState<string | null>(null);
    const [name, setName] = useState<string | null>(null);
    const [label, setLabel] = useState<string | null>(null);
    const [mapElement, setMapElement] = useState<string | null>(null);
    const [isNewItem, setIsNewItem] = useState(false);
    const [isUrl, setIsUrl] = useState(false);
    const [url, setUrl] = useState<ValueElementIdAPI | null>(null);
    const [urlTarget, setUrlTarget] = useState<string | null>(null);

    const mapElementsSorted = (filterOutNotes(mapElements ?? []) as MapElement[]).sort((a, b) => {
        return (a.developerName as string).localeCompare(b.developerName as string);
    });

    const initialErrors = {
        url: false,
        label: false,
        mapElement: false,
    };

    const [errors, setErrors] = useState<NavigationErrors>(initialErrors);

    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        (async () => {
            if (isNullOrEmpty(navigationElementId)) {
                setIsLoading(false);
            } else {
                try {
                    const response = await getNavigationElement({
                        tenantId: tenantId,
                        flowId: flowId,
                        navigationElementId: navigationElementId,
                    });

                    setNavigationElement(response);
                    setIsLoading(false);
                } catch (error) {
                    addNotification({
                        type: 'error',
                        message: (error as Error).message,
                        isPersistent: true,
                    });

                    close();
                }
            }
        })();
    }, [addNotification, tenantId, flowId, navigationElementId, close]);

    const stopEditingItem = () => {
        setEditing(null);
        setName(null);
        setLabel(null);
        setMapElement(null);
        setIsNewItem(false);
        setErrors(initialErrors);
        setIsUrl(false);
        setUrl(null);
    };

    const findItem = (
        itemId: string | null,
        items: NavigationItemAPI[] | undefined | null,
    ): NavigationItemAPI | undefined => {
        if (items) {
            for (const item of items) {
                if (item.id === itemId) {
                    return item;
                }
                const foundItem = findItem(itemId, item.navigationItems);
                if (foundItem) {
                    return foundItem;
                }
            }
        }

        return undefined;
    };

    const addItem = (id: string, e: KeyboardEvent<HTMLDivElement> | MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();

        setEditing(guid());
        setEditingParent(id);
        setName('');
        setLabel('');
        setMapElement('');
        setIsUrl(false);
        setUrl(null);
        setIsNewItem(true);
        setUrlTarget(URL_TARGET.BLANK);
    };

    const updateItem = (
        items: NavigationItemAPI[] | null,
        itemId: string | null,
        name: string | null,
        label: string | null,
        mapElement: string | null,
        url: ValueElementIdAPI | null,
        urlTarget: string | null,
    ) => {
        if (items) {
            return items.map((item) => {
                if (item.id === itemId) {
                    item.developerName = name ?? label;
                    item.label = label;
                    item.locationMapElementId = mapElement;
                    item.url = url;
                    item.urlTarget = urlTarget;

                    setNavigationElement({ ...navigationElement, navigationItems: items });
                }

                const navItems = item.navigationItems;

                updateItem(navItems, itemId, name, label, mapElement, url, urlTarget);
                return item;
            });
        }

        return [];
    };

    const deleteItem = (items: NavigationItemAPI[] | undefined | null, itemId: string | null) => {
        if (items) {
            let foundItem = false;
            const newItems = [];

            for (const item of items) {
                item.navigationItems = deleteItem(item.navigationItems, itemId);

                if (foundItem && item.order) {
                    item.order--;
                }

                if (item.id === itemId) {
                    foundItem = true;
                } else {
                    newItems.push(item);
                }
            }

            return newItems;
        }

        return [];
    };

    const moveItem = (parent: NavigationItemAPI | null, id: string, order: number) => {
        let items = parent?.navigationItems
            ? parent.navigationItems
            : navigationElement.navigationItems !== undefined
              ? navigationElement.navigationItems
              : [];

        const item = findItem(id, items);

        if (item) {
            const oldOrder = item.order;
            items = orderItems(items, oldOrder, order);
            item.order = order;
        }

        if (parent && navigationElement.navigationItems) {
            items = navigationElement.navigationItems;
        }

        setNavigationElement({ ...navigationElement, navigationItems: items });
    };

    const orderItems = (items: NavigationItemAPI[] | null, oldIndex: number, newIndex: number) => {
        const delta = Math.abs(newIndex - oldIndex);
        return items
            ? items.map((item) => {
                  const order = item.order;

                  if (newIndex > oldIndex) {
                      if (order <= newIndex && order > newIndex - delta) {
                          item.order--;
                      }
                  } else if (order < oldIndex && order >= oldIndex - delta) {
                      item.order++;
                  }
                  return item;
              })
            : [];
    };

    const onItemClick = (
        e: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>,
        itemId: string | null,
    ) => {
        e.nativeEvent.preventDefault();
        e.stopPropagation();

        const item = findItem(itemId, navigationElement.navigationItems);

        setEditing(itemId);
        setName(item?.developerName || null);
        setLabel(item?.label || null);
        setMapElement(item?.locationMapElementId || null);
        setIsNewItem(false);
        setErrors(initialErrors);
        if (item?.url) {
            setIsUrl(true);
            setUrl(item?.url || null);
            setUrlTarget(item.urlTarget);
        } else {
            setIsUrl(false);
        }
    };

    const onDeleteClick = () => {
        const items = deleteItem(navigationElement.navigationItems, editing);

        stopEditingItem();
        setNavigationElement({ ...navigationElement, navigationItems: items });
    };

    const updateMapElement = (option: SingleValue<MapElementTypeOption>) => {
        // labels that are empty or untouched by the user will be changed
        const shouldChangeLabel =
            isNullOrEmpty(label) || label === name || (isUrl && label === 'URL');

        if (option && option.value === 'URL') {
            setIsUrl(true);
            setErrors({ url: errors.url, label: errors.label, mapElement: false });
            setLabel(shouldChangeLabel ? 'URL' : label);
            return;
        }

        setIsUrl(false);
        setUrl(null);

        const selectedMapElement = mapElements.find((item) => item.id === option?.value);
        setLabel(
            shouldChangeLabel && selectedMapElement
                ? selectedMapElement.developerName || null
                : label,
        );
        setName(selectedMapElement?.developerName || null);
        setMapElement(selectedMapElement?.id || null);
        setErrors({ url: errors.url, label: errors.label, mapElement: false });
    };

    const updateLabel = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setLabel(e.currentTarget.value);
        setErrors({ url: errors.url, label: false, mapElement: errors.mapElement });
    };

    const onUrlChanged = (value: ValueElementIdAPI | null) => {
        setUrl(value);
        setErrors({ url: false, label: errors.label, mapElement: errors.mapElement });
    };

    const updateUrlTarget = (e: ChangeEvent<HTMLSelectElement>) => {
        e.preventDefault();
        setUrlTarget(e.currentTarget.value);
    };

    const cancelEdit = () => {
        stopEditingItem();
    };

    const saveNavigationItem = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        const errors = initialErrors;

        if (isNullOrEmpty(label)) {
            errors.label = true;
        }

        if (isNullOrEmpty(mapElement) && !isUrl) {
            errors.mapElement = true;
        }

        if (isUrl && isNullOrEmpty(url)) {
            errors.url = true;
        }

        if (Object.values(errors).some((error) => error === true)) {
            setErrors(errors);
        } else {
            const navigationItems = navigationElement?.navigationItems ?? [];
            const mapElementCopy = mapElements.find((item) => item.id === mapElement);

            if (isNewItem && mapElementCopy) {
                const newNavItem: NavigationItemAPI = {
                    id: editing,
                    locationMapElementId: mapElement,
                    developerName: isUrl ? label : mapElementCopy.developerName || null,
                    label: label,
                    navigationItems: null,
                    url: isUrl ? url : null,
                    urlTarget: isUrl ? (urlTarget ?? URL_TARGET.BLANK) : null,
                    developerSummary: null,
                    order: 0,
                    tags: null,
                    valuesToReset: null,
                };

                if (editingParent !== '') {
                    const item = findItem(editingParent, navigationItems);

                    if (item && isNullOrEmpty(item?.navigationItems)) {
                        item.navigationItems = [];
                    }

                    newNavItem.order = item?.navigationItems?.length || 0;
                    item?.navigationItems?.push(newNavItem);
                } else {
                    newNavItem.order = navigationItems.length;
                    navigationItems.push(newNavItem);
                }

                setNavigationElement({ ...navigationElement, navigationItems });
            } else {
                const updatedItems = updateItem(
                    navigationItems,
                    editing,
                    name,
                    label,
                    mapElement,
                    url,
                    urlTarget,
                );

                setNavigationElement({ ...navigationElement, navigationItems: updatedItems });
            }

            stopEditingItem();
        }
    };

    const saveNavigationElement = async () => {
        setHasSubmitted(true);

        if (isPageValid(navigationElement.developerName || null, errors)) {
            try {
                await createOrUpdateNavigationElements(
                    {
                        tenantId: tenantId,
                        flowId: flowId,
                    },
                    navigationElement as NavigationElementRequestAPI,
                );

                done();
            } catch (error) {
                addNotification({
                    type: 'error',
                    message: (error as Error).message,
                    isPersistent: true,
                });
            }
        }
    };

    const pageBody = () =>
        isLoading ? (
            <Loader message="Loading navigation details" />
        ) : (
            <>
                <NavigationForm
                    navigationElement={navigationElement}
                    errors={errors}
                    onChangeNavigationElement={setNavigationElement}
                    showValidation={hasSubmitted}
                />
                <NavigationPreview
                    navigationElement={navigationElement}
                    onMoveNavItem={moveItem}
                    onClickNavItem={onItemClick}
                    onAddNavItem={addItem}
                    onFindNavItem={findItem}
                />
                <ConfigureNavigationItem
                    mapElements={mapElementsSorted}
                    isEditing={!!editing}
                    errors={errors}
                    label={label}
                    url={url}
                    mapElement={mapElement}
                    isNewItem={isNewItem}
                    onDeleteClick={onDeleteClick}
                    updateMapElement={updateMapElement}
                    isUrl={isUrl}
                    urlTarget={urlTarget}
                    onUrlChanged={onUrlChanged}
                    updateUrlTarget={updateUrlTarget}
                    cancelEdit={cancelEdit}
                    saveNavigationItem={saveNavigationItem}
                    updateLabel={updateLabel}
                    name={name}
                />
                <div className="section-wrapper">
                    <ConfigurePosition
                        navigationElement={navigationElement}
                        setNavigationElement={setNavigationElement}
                    />
                    <ConfigureState
                        navigationElement={navigationElement}
                        setNavigationElement={setNavigationElement}
                    />
                    <Comments
                        navigationElement={navigationElement}
                        setNavigationElement={setNavigationElement}
                    />
                </div>
            </>
        );

    return (
        <DndProvider backend={HTML5Backend}>
            <Modal
                className="config-modal"
                renderHeader={() => <h4 className="modal-title">Navigation Editor</h4>}
                renderBody={pageBody}
                renderFooter={() => (
                    <NavigationFooter saveNavigationElement={saveNavigationElement} close={close} />
                )}
                container={container}
                onHide={closeModal}
            />
        </DndProvider>
    );
};

export default NavigationEditor;
