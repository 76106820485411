import { CaretLeft, CaretRight } from '@phosphor-icons/react';
import classNames from 'classnames';
import { useState } from 'react';
import { type ConnectedProps, connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import '../../../css/sidebar.less';
import { groups } from '../config/admin';
import { HOSTS, TAB_TYPES } from '../constants';
import translations from '../translations';
import type { Tab, TabConfiguration, TabType } from '../types';
import { getItem, setItem } from '../utils';
import { isNullOrEmpty } from '../utils/guard';
import { generateRouteUrl, generateTabKey } from '../utils/routing';
import { useAuth } from './AuthProvider';
import ComponentWithTooltip from './generic/tooltip/ComponentWithTooltip';
import TooltipWithHeader from './generic/tooltip/TooltipWithHeader';

const SIDEBAR_COLLAPSED_KEY = 'sidebar-collapsed';

const mapStateToProps = ({ tabs }: { tabs: Tab[] }) => ({ tabs });

const connector = connect(mapStateToProps);

type AdminSidebarProps = ConnectedProps<typeof connector>;

const AdminSidebar = ({ tabs }: AdminSidebarProps) => {
    const { tenant } = useAuth();

    const navigate = useNavigate();

    const [isCollapsed, setIsCollapsed] = useState(getItem<boolean>(SIDEBAR_COLLAPSED_KEY) ?? true);

    const tenantId = tenant?.id;

    if (!tenantId) {
        return null;
    }

    const isFederal = window.location.hostname === HOSTS.federal;

    const openAdminTab = (tabType: TabType) => {
        const route = generateRouteUrl({
            tabType,
            tenantId,
            options: {
                tabKey: generateTabKey(),
            },
        });
        navigate(route);
    };

    const filterNavigationItems = (item: TabConfiguration) => {
        if (item.type === TAB_TYPES.themes && !tenant?.tenantSettings?.themes) {
            return false;
        }

        if (item.type === TAB_TYPES.players && (tenant?.tenantSettings?.themes ?? false)) {
            return false;
        }

        if (item.type === TAB_TYPES.assets && isFederal) {
            return false;
        }

        return true;
    };

    const isItemActive = (item: TabConfiguration) => {
        const activeTab = tabs.find((tab) => tab.isActive);

        if (isNullOrEmpty(activeTab)) {
            return false;
        }

        if (activeTab.type === TAB_TYPES.flow && item.type === 'flows') {
            return true;
        }

        if (
            (activeTab.type === TAB_TYPES.page || activeTab.type === TAB_TYPES.newPage) &&
            item.type === 'pages'
        ) {
            return true;
        }

        return activeTab.type === item.type;
    };

    const toggleIsCollapsed = () => {
        const newState = !isCollapsed;
        setIsCollapsed(newState);
        setItem(SIDEBAR_COLLAPSED_KEY, newState);
    };

    const buttons = groups.map((group) =>
        [
            <div className="heading" key={group.name}>
                {group.label}
            </div>,
        ].concat(
            group.items.filter(filterNavigationItems).map((item) => {
                const classes = classNames('sidebar-btn', {
                    active: isItemActive(item),
                });

                const element = (
                    <button
                        key={item.type}
                        className={classes}
                        onClick={() => openAdminTab(item.type)}
                        type="button"
                    >
                        <div className="glyphicon">
                            <item.icon width="20" height="20" />
                        </div>
                        <div className="sidebar-item-text">{item.title}</div>
                    </button>
                );

                return item.description ? (
                    <ComponentWithTooltip
                        key={`tooltip-${item.type}`}
                        trigger={['hover', 'focus']}
                        tooltipPlacement="right"
                        fadeTime={0.1}
                        tooltipContent={
                            <TooltipWithHeader header={item.title} content={item.description} />
                        }
                        wrapperClass="full-width"
                        tooltipClass="graph-tooltip"
                        tooltipArrowClass="graph-tooltip-arrow"
                        clearance={10}
                        arrowClearance={7}
                    >
                        {element}
                    </ComponentWithTooltip>
                ) : (
                    element
                );
            }),
        ),
    );

    const sidebarClassName = classNames('sidebar', 'sidebar-admin', {
        collapsed: isCollapsed,
    });

    const label = isCollapsed
        ? translations.SIDEBAR_show_sidebar_button_label
        : translations.SIDEBAR_hide_sidebar_button_label;

    return (
        <div className={sidebarClassName} data-testid="sidebar-admin">
            <div className="sidebar-inner added">
                <button
                    className="sidebar-collapse"
                    onClick={toggleIsCollapsed}
                    data-testid="sidebar-collapse-button"
                    type="button"
                    aria-label={label}
                    title={label}
                >
                    {isCollapsed ? <CaretRight /> : <CaretLeft />}
                </button>
                <div className="list-unstyled">{buttons}</div>
            </div>
        </div>
    );
};

export default connector(AdminSidebar);
