import ButtonDefault from '../../buttons/ButtonDefault';
import Glyphicon from '../../generic/Glyphicon';
import SearchInput from '../../generic/SearchInput';

type Props = {
    searchText: string;
    setSearchText: (searchText: string) => void;
    filterBySearchTerms: (searchText: string) => void;
    loadImportPage: () => void;
    loadMainPage: () => void;
};

const TabContentControls = ({
    searchText,
    setSearchText,
    filterBySearchTerms,
    loadImportPage,
    loadMainPage,
}: Props) => {
    return (
        <div className="tab-content-header">
            <SearchInput
                className="tab-header-search"
                value={searchText}
                onChange={(searchText) => {
                    setSearchText(searchText);
                    filterBySearchTerms(searchText);
                }}
            />
            <div className="tab-header-controls">
                <ButtonDefault
                    className="btn-sm btn-success"
                    title="Import Existing"
                    onClick={loadImportPage}
                >
                    Import Existing
                </ButtonDefault>
                <ButtonDefault className="btn-sm" title="Refresh" onClick={loadMainPage}>
                    <Glyphicon glyph="refresh" />
                </ButtonDefault>
            </div>
        </div>
    );
};

export default TabContentControls;
