import { useState, useEffect } from 'react';
import FormGroup from '../../../../../generic/FormGroup';
import translations from '../../../../../../translations';
import FilterCriterias from './FilterCriterias';
import { getType } from '../../../../../../sources/type';
import Loader from '../../../../../loader/Loader';

import { useDataAction } from '../../../contextProviders/DataActionProvider';
import { useMapElement } from '../../../contextProviders/MapElementProvider';
import { isNullOrEmpty } from '../../../../../../utils/guard';
import type { TypeElementResponseAPI } from '../../../../../../types';

const COMPARATORS = [
    { label: 'And', value: 'AND' },
    { label: 'Or', value: 'OR' },
];

const ORDER_DIRECTIONS = [
    { label: 'Ascending', value: 'ASC' },
    { label: 'Descending', value: 'DESC' },
];

type Props = {
    comparisonType: string | null;
    hasSubmitted: boolean;
};

const FilterByWhereClause = ({ comparisonType, hasSubmitted }: Props) => {
    const [type, setType] = useState<TypeElementResponseAPI | null>(null);

    const {
        onSetFilterComparisonType,
        onSetFilterOrderDirectionType,
        onSetFilterOrderByTypePropertyId,
        onSetFilterRecordLimit,
        dataActionToEdit,
        filterToEdit,
    } = useDataAction();

    const { notifyError } = useMapElement();
    const { dataAction } = dataActionToEdit;

    const orderByDirectionType = filterToEdit?.orderByDirectionType;
    const orderByTypeElementPropertyId = filterToEdit?.orderByTypeElementPropertyId;
    const recordLimit = filterToEdit?.limit;
    const typeElementId = dataAction?.objectDataRequest?.typeElementId;

    const isFilteredByWhereClause = filterToEdit?.where && !filterToEdit?.filterId;

    const fetchType = async () => {
        if (typeElementId) {
            try {
                const typeResult = await getType(typeElementId);
                setType(typeResult);
            } catch (error) {
                notifyError(error);
            }
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        fetchType();
    }, []);

    if (!isFilteredByWhereClause) {
        return null;
    }

    if (!type) {
        return <Loader />;
    }

    return (
        <>
            <FilterCriterias />
            <FormGroup
                label={translations.DATA_ACTION_comparator_label}
                htmlFor="comparator-type"
                isRequired
                validationMessage={translations.MAP_ELEMENT_value_field_validation_message}
                isValid={!isNullOrEmpty(comparisonType)}
                showValidation={hasSubmitted}
            >
                <select
                    id="comparator-type"
                    defaultValue={comparisonType ?? undefined}
                    onChange={(e) => onSetFilterComparisonType(e.target.value)}
                    className="form-control form-control-width"
                >
                    <option value="">Select a comparator</option>
                    {COMPARATORS.map((comparator) => (
                        <option key={comparator.value} value={comparator.value}>
                            {comparator.label}
                        </option>
                    ))}
                </select>
            </FormGroup>

            <FormGroup
                label={translations.DATA_ACTION_order_by_type_property}
                htmlFor="type-properties"
                isRequired={false}
            >
                <select
                    id="type-properties"
                    value={orderByTypeElementPropertyId ?? undefined}
                    onChange={(e) => onSetFilterOrderByTypePropertyId(e.target.value)}
                    className="form-control form-control-width"
                >
                    <option value="">Select a property</option>
                    {type.properties.map((property) => (
                        <option key={property.id} value={property.id}>
                            {property.developerName}
                        </option>
                    ))}
                </select>
            </FormGroup>

            <FormGroup
                label={translations.DATA_ACTION_order_direction_label}
                htmlFor="order-direction"
                isRequired={false}
            >
                <select
                    id="order-direction"
                    defaultValue={orderByDirectionType || ''}
                    onChange={(e) => onSetFilterOrderDirectionType(e.target.value)}
                    className="form-control form-control-width"
                >
                    <option value="">Select an order direction</option>
                    {ORDER_DIRECTIONS.map((direction) => (
                        <option key={direction.value} value={direction.value}>
                            {direction.label}
                        </option>
                    ))}
                </select>
            </FormGroup>

            <FormGroup
                label={translations.DATA_ACTION_number_of_records_label}
                htmlFor="number-records"
                isRequired={false}
            >
                <input
                    id="number-records"
                    defaultValue={recordLimit || 0}
                    onChange={(e) => onSetFilterRecordLimit(e.target.valueAsNumber)}
                    maxLength={255}
                    required
                    className="form-control form-control-width"
                    type="number"
                    min="0"
                />
            </FormGroup>
        </>
    );
};

export default FilterByWhereClause;
