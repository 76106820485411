import classNames from 'classnames';
import { memo, useEffect, useState } from 'react';
import Loader from '../loader/Loader';
import { getIdentityProviderList } from '../../sources/identityprovider';
import type {
    FlowIdentityProviderAPI,
    IdentityProviderAPI,
    NotifyError,
    GroupAuthorizationAPI,
} from '../../types';
import IdentityProvider from './IdentityProvider';
import { getAllServices } from '../../sources/service';
import type { ServiceElementResponseAPI } from '../../types/service';
import Connector from './Connector';
import '../../../../css/flow/shared-elements.less';
import '../../../../css/flow/authorization-config.less';

type TabName = 'idp' | 'connector';

interface Props {
    identityProvider: FlowIdentityProviderAPI | null;
    onChangeIdentityProvider: (identityProvider: FlowIdentityProviderAPI | null) => void;
    authorization: GroupAuthorizationAPI | null;
    onChangeAuthorization: (authorization: GroupAuthorizationAPI) => void;
    notifyError: NotifyError;
}

const Authorization = ({
    identityProvider,
    onChangeIdentityProvider,
    authorization,
    onChangeAuthorization,
    notifyError,
}: Props) => {
    const [activeTab, setActiveTab] = useState<TabName>('idp');

    const [identityProviders, setIdentityProviders] = useState<IdentityProviderAPI[]>([]);
    const [isLoadingIdentityProviders, setIsLoadingIdentityProviders] = useState(false);

    const [connectors, setConnectors] = useState<ServiceElementResponseAPI[]>([]);
    const [isLoadingConnectors, setIsLoadingConnectors] = useState(false);

    useEffect(() => {
        const fetchIDPList = async () => {
            setIsLoadingIdentityProviders(true);
            const identityProviderResponse = await getIdentityProviderList({
                limit: 1000000,
            });
            setIdentityProviders(identityProviderResponse.items);
            setIsLoadingIdentityProviders(false);
        };

        fetchIDPList().catch(notifyError);
    }, [notifyError]);

    useEffect(() => {
        const fetchConnectorList = async () => {
            setIsLoadingConnectors(true);
            const connectors = await getAllServices();
            setConnectors(connectors);
            setIsLoadingConnectors(false);
        };

        fetchConnectorList().catch(notifyError);
    }, [notifyError]);

    return (
        <div className="authorization-config">
            <h2>Authentication</h2>
            <ul className="nav nav-tabs">
                <li className={classNames({ active: activeTab === 'idp' })}>
                    <button
                        className="nav-tab"
                        onClick={() => setActiveTab('idp')}
                        title="Identity Provider"
                        role="tab"
                        name="Identity Provider"
                        type="button"
                    >
                        Identity Provider
                    </button>
                </li>
                <li className={classNames({ active: activeTab === 'connector' })}>
                    <button
                        className="nav-tab"
                        onClick={() => setActiveTab('connector')}
                        title="Connector"
                        role="tab"
                        name="Identity Connector"
                        type="button"
                    >
                        Connector
                    </button>
                </li>
            </ul>
            {activeTab === 'idp' &&
                (isLoadingIdentityProviders ? (
                    <Loader />
                ) : (
                    <IdentityProvider
                        identityProvider={identityProvider}
                        providers={identityProviders}
                        onChangeIdentityProvider={onChangeIdentityProvider}
                    />
                ))}
            {activeTab === 'connector' &&
                (isLoadingConnectors ? (
                    <Loader />
                ) : (
                    <Connector
                        authorization={authorization}
                        connectors={connectors}
                        onChangeAuthorization={onChangeAuthorization}
                        notifyError={notifyError}
                    />
                ))}
        </div>
    );
};

export default memo(Authorization);
