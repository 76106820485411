import screens from '../common/screens';
import { DataActionProvider } from '../contextProviders/DataActionProvider';
import {
    type MapElementConfigProps,
    MapElementProvider,
} from '../contextProviders/MapElementProvider';
import { ListenerProvider } from '../contextProviders/ListenerProvider';
import SaveRouter from './SaveRouter';
import MapElementModal from '../../../graph/MapElementModal';
import { MAP_ELEMENT_TYPES } from '../../../../constants';
import { CRUD_OPERATION_TYPES } from '../common/constants';

const Save = (props: MapElementConfigProps) => {
    return (
        <MapElementProvider
            {...props}
            defaultScreen={screens.save}
            elementType={MAP_ELEMENT_TYPES.databaseSave}
            isLoading={false}
        >
            <MapElementModal>
                <DataActionProvider
                    defaultScreen={screens.save}
                    crudOperationType={CRUD_OPERATION_TYPES['save']}
                >
                    <ListenerProvider defaultScreen={screens.save}>
                        <SaveRouter />
                    </ListenerProvider>
                </DataActionProvider>
            </MapElementModal>
        </MapElementProvider>
    );
};

export default Save;
