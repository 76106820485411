import translations from '../../../../translations';
import Footer from '../../elementConfigurations/common/Footer';

type Props = {
    saveNavigationElement: () => void;
    close: () => void;
};

const NavigationFooter = ({ saveNavigationElement, close }: Props) => (
    <Footer
        save={saveNavigationElement}
        saveButtonText={translations.GRAPH_config_panel_save}
        cancelButtonText="Back"
        cancel={close}
    />
);

export default NavigationFooter;
