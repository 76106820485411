import { CheckCircle, Question, Trash, WarningCircle } from '@phosphor-icons/react';
import { formatDistanceToNowStrict } from 'date-fns';
import {
    type ComponentPropsWithoutRef,
    type ReactNode,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { notifyError } from '../../../../js/actions/reduxActions/notification';
import { getRuntimes } from '../../../sources/runtime';
import translations from '../../../translations';
import { type RuntimeListResponse, RuntimeStatus } from '../../../types/runtime';
import { stringContains } from '../../../utils/string';
import SearchInput from '../../generic/SearchInput';
import Table, { type TableColumnList } from '../../generic/Table';
import type GenericModal from '../../generic/modal/GenericModal';
import LocalRuntimeDeleteModal from './LocalRuntimeDeleteModal';
import LocalRuntimeEditModal from './LocalRuntimeEditModal';

type LocalRuntimeListProps = Pick<ComponentPropsWithoutRef<typeof GenericModal>, 'container'>;

const LocalRuntimeList = ({ container }: LocalRuntimeListProps) => {
    const [searchFilter, setSearchFilter] = useState('');
    const [selectedRuntime, setSelectedRuntime] = useState<RuntimeListResponse | null>(null);
    const [runtimes, setRuntimes] = useState<RuntimeListResponse[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const dispatch = useDispatch();

    const fetchLocalRuntimes = useCallback(async () => {
        try {
            setIsLoading(true);

            const runtimes = await getRuntimes();

            setRuntimes(runtimes);
        } catch (error) {
            dispatch(notifyError(error));
        } finally {
            setIsLoading(false);
        }
    }, [dispatch]);

    useEffect(() => {
        fetchLocalRuntimes();
    }, [fetchLocalRuntimes]);

    useEffect(() => {
        // Loader is visually disruptive when displayed frequently, so don't display on regular refreshes
        // Should be safe because this fetch shouldn't result in runtime addition or removal, only stat changes
        const refreshRuntimes = async () => {
            try {
                const runtimes = await getRuntimes();
                setRuntimes(runtimes);
            } catch (error) {
                dispatch(notifyError(error));
            }
        };

        const interval = setInterval(refreshRuntimes, 5_000);

        return () => {
            clearInterval(interval);
        };
    }, [dispatch]);

    const handleSearch = (searchTerm: string) => {
        setSearchFilter(searchTerm);
    };

    const handleRuntimeEdit = async (runtime: RuntimeListResponse) => {
        // Open the editor modal with selected runtime
        setSelectedRuntime(runtime);
        setShowEditModal(true);
    };

    const handleRuntimeDelete = (runtime: RuntimeListResponse) => {
        setSelectedRuntime(runtime);
        setShowDeleteModal(true);
    };

    const handleRuntimeConfirm = async () => {
        setSelectedRuntime(null);
        setShowDeleteModal(false);
        await fetchLocalRuntimes();
    };

    const handleRuntimeDeleteCancel = () => {
        setSelectedRuntime(null);
        setShowDeleteModal(false);
    };

    const handleRuntimeCreate = () => {
        setSelectedRuntime(null);
        setShowEditModal(true);
    };

    const handleEditModalClose = async () => {
        setSelectedRuntime(null);
        setShowEditModal(false);
        await fetchLocalRuntimes();
    };

    const renderStatusCell = ({ item: { id, status } }: { item: RuntimeListResponse }) => {
        let icon: ReactNode;
        switch (status) {
            case RuntimeStatus.Offline: {
                icon = (
                    <span
                        data-testid={`online-status-${id}`}
                        title={translations.LOCAL_RUNTIME_possible_offline_runtime_title_text}
                        className="table-icon-large"
                    >
                        <WarningCircle color="#F4C57F" />
                    </span>
                );
                break;
            }
            case RuntimeStatus.Unknown: {
                icon = (
                    <span
                        data-testid={`online-status-${id}`}
                        title={translations.LOCAL_RUNTIME_unknown_status_title_text}
                        className="table-icon-large"
                    >
                        <Question color="#8C8C8C" />
                    </span>
                );
                break;
            }
            case RuntimeStatus.Online: {
                icon = (
                    <span
                        data-testid={`online-status-${id}`}
                        title={translations.LOCAL_RUNTIME_online_status_title_text}
                        className="table-icon-large"
                    >
                        <CheckCircle color="#0EA076" />
                    </span>
                );
                break;
            }
            // Omitting default case because all enum variants are covered; if a new variant is added, icon will error when it's used, forcing developer to handle the variant
            case RuntimeStatus.Leader: {
                icon = '';
                break;
            }
        }

        return (
            <div className="table-icon-text-wrapper" data-testid="runtime-status">
                {icon}
                <span>{status}</span>
            </div>
        );
    };

    const columns: TableColumnList<RuntimeListResponse> = [
        {
            renderHeader: () => translations.COMMON_TABLE_connection_name,
            renderCell: ({ item: runtime }) => (
                <button
                    className="link-emulate"
                    title={translations.LOCAL_RUNTIME_list_edit_runtime_button_label}
                    onClick={() => handleRuntimeEdit(runtime)}
                    aria-label={translations.LOCAL_RUNTIME_list_edit_runtime_button_label}
                    type="button"
                >
                    {runtime.developerName}
                </button>
            ),
        },
        {
            renderHeader: () => translations.COMMON_TABLE_version,
            renderCell: ({ item: runtime }) => {
                const version =
                    runtime.latestVersion || translations.LOCAL_RUNTIME_list_unknown_version;
                return <span>{version}</span>;
            },
        },
        {
            renderHeader: () => translations.COMMON_TABLE_status,
            renderCell: renderStatusCell,
        },
        {
            renderHeader: () => translations.COMMON_TABLE_last_seen,
            renderCell: ({ item: runtime }) => {
                const pingDateTime = runtime.lastPingAt
                    ? formatDistanceToNowStrict(new Date(runtime.lastPingAt), {
                          addSuffix: true,
                      })
                    : translations.LOCAL_RUNTIME_list_no_last_ping;
                return <span>{pingDateTime}</span>;
            },
        },
        {
            renderHeader: () => translations.COMMON_TABLE_shared_tenants,
            renderCell: ({ item: runtime }) => runtime.numberOfTenants,
            size: '11rem',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_actions,
            renderCell: ({ item: runtime }) => (
                <div className="action-btn-wrapper">
                    <button
                        title={translations.LOCAL_RUNTIME_list_delete_runtime_button_label}
                        className="table-icon table-icon-delete"
                        aria-label={translations.LOCAL_RUNTIME_list_delete_runtime_button_label}
                        onClick={() => handleRuntimeDelete(runtime)}
                        type="button"
                    >
                        <Trash />
                    </button>
                </div>
            ),
            size: '5rem',
        },
    ];

    return (
        <>
            <h2 className="admin-heading-2" id="localRuntimes">
                {translations.LOCAL_RUNTIME_title}
            </h2>
            <p>{translations.LOCAL_RUNTIME_summary}</p>
            <button
                onClick={handleRuntimeCreate}
                className="btn btn-sm btn-primary margin-bottom"
                type="button"
            >
                {translations.LOCAL_RUNTIME_create_new_runtime_button_label}
            </button>
            <SearchInput value={searchFilter} onChange={handleSearch} />
            <Table
                caption={translations.LOCAL_RUNTIME_list_table_caption}
                wrapperClassName="margin-top runtimes-table"
                items={runtimes.filter((runtime) =>
                    stringContains(runtime.developerName, searchFilter, false),
                )}
                columns={columns}
                isLoading={isLoading}
                rowKeyGenerator={(runtime) => runtime.id}
            />
            {showDeleteModal && selectedRuntime && (
                <LocalRuntimeDeleteModal
                    container={container}
                    selectedRuntime={selectedRuntime}
                    onCancel={handleRuntimeDeleteCancel}
                    onConfirm={handleRuntimeConfirm}
                />
            )}
            {showEditModal && (
                <LocalRuntimeEditModal
                    container={container}
                    runtimeId={selectedRuntime?.id ?? null}
                    onHide={handleEditModalClose}
                />
            )}
        </>
    );
};

export default LocalRuntimeList;
