import { useEffect } from 'react';
import type { OrganizationInvite } from '../../types/organization';
import translations from '../../translations';
import Table, { type TableColumnList } from '../generic/Table';
import { useOrganizationInvite } from './OrganizationInviteProvider';

type ReceivedInvitesProps = {
    caption: string;
    invitations: OrganizationInvite[];
    matchInvite: (invite: OrganizationInvite) => boolean;
    loadInvites: () => Promise<void>;
    onAccept: (id: string) => Promise<void>;
    onReject: (id: string) => Promise<void>;
};

const dateTimerFormatter = new Intl.DateTimeFormat(undefined, {
    dateStyle: 'medium',
    timeStyle: 'short',
});

const ReceivedInvites = ({
    caption,
    invitations,
    matchInvite,
    loadInvites,
    onAccept,
    onReject,
}: ReceivedInvitesProps) => {
    const { isLoading } = useOrganizationInvite();

    useEffect(() => {
        loadInvites();
    }, [loadInvites]);

    const pendingInvitations = invitations.filter((item) => {
        const isMatch = matchInvite(item);
        const isActionable =
            item.status !== 'accepted' &&
            item.status !== 'rejected' &&
            item.status !== 'canceled' &&
            item.status !== 'acknowledged';
        return isMatch && isActionable;
    });

    const columns: TableColumnList<OrganizationInvite> = [
        {
            renderHeader: () => translations.COMMON_TABLE_organization,
            renderCell: ({ item: invite }) => (
                <span title={`ID: ${invite.organization.id}`}>{invite.organization.name}</span>
            ),
        },
        {
            renderHeader: () => translations.COMMON_TABLE_status,
            renderCell: ({ item: invite }) => <span className="capitalize">{invite.status}</span>,
            size: '20%',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_date_sent,
            renderCell: ({ item: invite }) => dateTimerFormatter.format(new Date(invite.invitedAt)),
            size: '11rem',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_actions,
            renderCell: ({ item: invite }) => (
                <div className="action-btn-wrapper">
                    <button
                        className="btn btn-primary"
                        onClick={() => onAccept(invite.organization.id)}
                        type="button"
                    >
                        {translations.FORG_received_invite_list_accept_invite}
                    </button>
                    <button
                        className="btn btn-danger"
                        onClick={() => onReject(invite.organization.id)}
                        type="button"
                    >
                        {translations.FORG_received_invite_list_reject_invite}
                    </button>
                </div>
            ),
            size: '11rem',
        },
    ];

    return (
        <Table
            caption={caption}
            wrapperClassName="margin-top margin-bottom-large"
            isLoading={isLoading}
            columns={columns}
            items={pendingInvitations}
            pagination={true}
        />
    );
};

export default ReceivedInvites;
