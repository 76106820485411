import { type RefObject, useEffect } from 'react';

/**
 * @param excludedNode The node within which clicks will not trigger the `onClick` callback
 * @param onClick Callback triggered when the user clicks outside `excludedNode`
 * @description Adds an on-click event listener and removes it on clean-up.
 * The listener executes the callback when the event target is outside of
 * the specified DOM node.
 */
export const useDocumentClick = (excludedNode: RefObject<Node | null>, onClick: () => void) => {
    useEffect(() => {
        const handleClick = (event: MouseEvent) => {
            const excluded = excludedNode.current;

            if (excluded && !excluded.contains(event.target as Node)) {
                onClick();
            }
        };

        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [onClick, excludedNode]);
};

export const useDocumentKeyDown = (
    keyCombo: string,
    elementInFocus: HTMLElement | null | Document,
    onKeyDown: () => void,
) => {
    useEffect(() => {
        // Split the keyCombo into individual parts.
        const combo = keyCombo.split('+');
        const comboKey = combo.pop() ?? '';
        const hasCtrl = keyCombo.includes('CTRL');
        const hasAlt = keyCombo.includes('ALT');
        const hasShift = keyCombo.includes('SHIFT');

        const handleKeyDown = (event: KeyboardEvent) => {
            const { key, ctrlKey, altKey, shiftKey } = event;

            if (
                key.toLowerCase() === comboKey.toLowerCase() &&
                ctrlKey === hasCtrl &&
                altKey === hasAlt &&
                shiftKey === hasShift
            ) {
                // Ignore any browser default kbd shortcuts
                event.preventDefault();
                onKeyDown();
            }
        };

        elementInFocus?.addEventListener('keydown', handleKeyDown as EventListener);

        return () => {
            elementInFocus?.removeEventListener('keydown', handleKeyDown as EventListener);
        };
    }, [onKeyDown, elementInFocus, keyCombo]);
};
