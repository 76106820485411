import translations from '../../translations';
import InviteList from './InviteList';
import { useOrganizationInvite } from './OrganizationInviteProvider';

type PendingUserInviteListProps = {
    organizationId: string;
};

const PendingUserInviteList = ({ organizationId }: PendingUserInviteListProps) => {
    const {
        sentUserInvites,
        loadSentUserInvites,
        acknowledgeRejectedUserInvite,
        cancelUserInvite,
    } = useOrganizationInvite();

    return (
        <InviteList
            caption={translations.FORG_user_invite_list_caption}
            invitations={sentUserInvites}
            organizationId={organizationId}
            loadInvites={loadSentUserInvites}
            onAcknowledge={acknowledgeRejectedUserInvite}
            onCancel={cancelUserInvite}
        />
    );
};

export default PendingUserInviteList;
