import FormGroup from '../../../../../../../../generic/FormGroup';
import translations from '../../../../../../../../../translations';
import ButtonDefault from '../../../../../../../../buttons/ButtonDefault';
import ButtonPrimary from '../../../../../../../../buttons/ButtonPrimary';

import { getType } from '../../../../../../../../../sources/type';
import { getValue } from '../../../../../../../../../sources/value';
import { defaultDataPresentationColumnConfig } from '../../../../../../../templates';
import { isNullOrEmpty } from '../../../../../../../../../utils/guard';
import { usePageEditor } from '../../../../../../PageEditorProvider';
import { NOTIFICATION_TYPES } from '../../../../../../../../../constants';
import { useEffect, useState } from 'react';
import type { ValueElementIdAPI, column, ColumnOption } from '../../../../../../../../../types';
import Select from 'react-select';
import { getSharedStyles } from '../../../../../../../../../utils/select';
import { COMPONENT_GROUPS } from '../../../../../../../constants';

interface Props {
    screen?: string;
    stateSaveValue: ValueElementIdAPI | null | undefined;
    dataSourceTypeElementId: string | null;
    listItems: column[] | null;
    selectedItemIndex: number | null;
    propertyOptions: ColumnOption[] | null;
    save: (columns: column[]) => void;
    cancel: () => void;
    hasEditableColumns: boolean;
    canUseCustomComponent: boolean;
    canPinColumns: boolean;
}

const DataPresentationEditor = ({
    stateSaveValue,
    dataSourceTypeElementId,
    listItems,
    selectedItemIndex,
    propertyOptions,
    save,
    cancel,
    hasEditableColumns,
    canUseCustomComponent,
    canPinColumns,
}: Props) => {
    const { addNotification, componentRegistryList, isLoading } = usePageEditor();

    const selectedItem =
        selectedItemIndex !== null && listItems ? listItems[selectedItemIndex] : null;

    const excludedPropertyOptions = listItems?.map((item) => item.typeElementPropertyId);
    const availablePropertyOptions = propertyOptions?.filter(
        (option) => !excludedPropertyOptions?.includes(option.value),
    );

    // Setup initial values.
    const itemProperty = selectedItem
        ? {
              value: selectedItem.typeElementPropertyId,
              label: selectedItem.typeElementPropertyDeveloperName,
          }
        : null;

    const customComponents = componentRegistryList
        .filter((cr) => cr.group === COMPONENT_GROUPS['CUSTOM'])
        .map((cr) => ({
            value: cr.type,
            label: cr.ui.caption,
        }));

    const selectedComponentType = selectedItem?.componentType
        ? {
              value: selectedItem.componentType?.toUpperCase() || null,
              label:
                  customComponents.find(
                      (customComponent) =>
                          customComponent.value.toUpperCase() ===
                          selectedItem.componentType?.toUpperCase(),
                  )?.label || null,
          }
        : null;

    const itemLabel = selectedItem?.label || '';

    // If an item is selected then use whatever display is set on the item, else default to true.
    const itemDisplay = selectedItem ? selectedItem.isDisplayValue : true;
    const itemIsEditable = selectedItem ? selectedItem.isEditable : true;

    const itemIsPinned = selectedItem ? selectedItem.isPinned : false;

    // Setup local values.
    const [boundProperty, setBoundProperty] = useState<{ value: string; label: string } | null>(
        null,
    );
    const [property, setProperty] = useState(itemProperty);
    const [label, setLabel] = useState(itemLabel);
    const [display, setDisplay] = useState(itemDisplay);
    const [isEditable, setIsEditable] = useState(itemIsEditable);
    const [isPinned, setIsPinned] = useState(itemIsPinned);

    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [bindingPropertyOptions, setBindingPropertyOptions] = useState<
        { value: string; label: string }[]
    >([]);
    const [componentType, setComponentType] = useState(selectedComponentType);

    // biome-ignore lint/correctness/useExhaustiveDependencies: Treat warnings as errors, fix later
    useEffect(() => {
        setProperty(itemProperty);
        setLabel(itemLabel);
        setDisplay(itemDisplay);
    }, [selectedItemIndex]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: Treat warnings as errors, fix later
    useEffect(() => {
        if (stateSaveValue?.id && dataSourceTypeElementId) {
            generatePropertiesForPartialSave(stateSaveValue.id);
        }
    }, [stateSaveValue, dataSourceTypeElementId, selectedItem]);

    const onCancel = () => {
        cancel(); // reset some shared bits so list and editor sync up

        setProperty(null);
        setLabel('');
        setDisplay(true);
        setHasSubmitted(false);
    };

    const onSave = () => {
        setHasSubmitted(true);

        if (!property) {
            return;
        }

        const newPresentationItem = {
            ...defaultDataPresentationColumnConfig,
            boundTypeElementPropertyId: boundProperty ? boundProperty.value : null,
            isBound: !!boundProperty?.value,
            isDisplayValue: display,
            isEditable: hasEditableColumns ? isEditable : false,
            isPinned,
            label: label || null,
            order: isNullOrEmpty(selectedItem?.order)
                ? listItems
                    ? listItems.length
                    : 0
                : selectedItem?.order || 0,
            typeElementPropertyDeveloperName: property.label,
            typeElementPropertyId: property.value,
            componentType: componentType?.value?.toLowerCase() || null,
        };

        // If editing, replace existing item with the new one, else add the new item to the others.
        if (selectedItemIndex !== null && listItems) {
            const updatedItems = [...listItems];
            updatedItems[selectedItemIndex] = newPresentationItem;
            save(updatedItems);
        } else {
            const updatedItems = listItems?.length
                ? [...listItems, newPresentationItem]
                : [newPresentationItem];
            save(updatedItems);
        }

        // Reset and close the editor.
        setHasSubmitted(false);
        onCancel();
    };

    /**
     * We need to fetch all of the type properties for
     * the type associated to the value selected for saving page component state.
     * This is so that we can firstly, compare the type ID of the value used
     * for saving with the type ID of the page components data source.
     * If they are different then a partial save is feasible.
     *
     * Secondly we need the type properties of the type associated to
     * the value selected for saving page component state, so that
     * they can populate a selectbox which allows for mapping a column
     * to a property and therefore configuring the partial save.
     */
    const generatePropertiesForPartialSave = async (valueId: string) => {
        try {
            let properties = [] as { value: string; label: string }[];

            const rawValue = await getValue(valueId);

            // The page component that leverages columns should not typically be configured to
            // save component state to a primitive value, so we only want to continue
            // if the value has a type element associated to it.
            if (rawValue.typeElementId) {
                const rawType = await getType(rawValue.typeElementId);

                const canPerformPartialSave = rawType.id !== dataSourceTypeElementId;

                if (canPerformPartialSave || selectedItem?.boundTypeElementPropertyId) {
                    properties = rawType.properties.map((prop) => ({
                        value: prop.id,
                        label: prop.developerName,
                    }));
                }

                setBindingPropertyOptions(properties);

                const boundTypeElementPropertyId = selectedItem?.boundTypeElementPropertyId;
                const selectedBoundPropertyLabel = properties.find(
                    (bpo) => bpo.value === boundTypeElementPropertyId,
                )?.label;

                if (
                    boundTypeElementPropertyId &&
                    !selectedBoundPropertyLabel &&
                    rawType.developerName
                ) {
                    throw Error(
                        `The property with an ID of ${boundTypeElementPropertyId} could not be found on the type ${rawType.developerName}`,
                    );
                }
                setBoundProperty(
                    selectedBoundPropertyLabel && selectedItem?.boundTypeElementPropertyId
                        ? {
                              value: selectedItem.boundTypeElementPropertyId,
                              label: selectedBoundPropertyLabel,
                          }
                        : null,
                );
            }
        } catch (error) {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: `Error for the field "Save this column into the following property": ${
                    (error as Error).message
                }`,
                isPersistent: true,
            });
        }
    };

    return (
        <div className="sidebar-mini-editor">
            <h4 className="sidebar-section-heading">
                {selectedItem ? 'Edit column' : 'Add new column'}
            </h4>

            <FormGroup
                label="Property"
                htmlFor="presentation-property"
                isValid={!isNullOrEmpty(property)}
                validationMessage={translations.PAGE_BUILDER_field_is_required_validation_message}
                showValidation={hasSubmitted}
                isRequired
            >
                <Select
                    inputId="presentation-property"
                    className="select-field"
                    styles={getSharedStyles<{ label: string | null; value: string | null }>()}
                    options={availablePropertyOptions}
                    onChange={(selectedProperty) => setProperty(selectedProperty)}
                    value={property}
                    noOptionsMessage={() => 'No results found'}
                    placeholder="Select a column"
                />
            </FormGroup>

            <FormGroup label="Label" htmlFor="presentation-label">
                <input
                    className="form-control"
                    id="presentation-label"
                    type="text"
                    value={label}
                    onChange={(event) => {
                        setLabel(event.target.value);
                    }}
                    title="Label"
                    placeholder="Enter a label"
                />
            </FormGroup>

            {bindingPropertyOptions.length > 0 ? (
                <FormGroup
                    label="Save this column into the following property"
                    htmlFor="presentation-bound-property"
                >
                    <Select
                        inputId="presentation-bound-property"
                        className="select-field"
                        styles={getSharedStyles<{ label: string; value: string }>()}
                        options={bindingPropertyOptions}
                        onChange={(
                            selectedBoundProperty: {
                                value: string;
                                label: string;
                            } | null,
                        ) => setBoundProperty(selectedBoundProperty)}
                        value={boundProperty}
                        noOptionsMessage={() => 'No results found'}
                        placeholder="Select a property"
                    />
                </FormGroup>
            ) : null}

            <div className="form-group">
                <label>
                    <input
                        type="checkbox"
                        onChange={() => setDisplay(!display)}
                        checked={display}
                    />
                    Display this column
                </label>
            </div>

            {hasEditableColumns && (
                <div className="form-group">
                    <label>
                        <input
                            type="checkbox"
                            onChange={() => setIsEditable(!isEditable)}
                            checked={isEditable}
                        />
                        Make editable
                    </label>
                </div>
            )}

            {canPinColumns && (
                <div className="form-group">
                    <label>
                        <input
                            type="checkbox"
                            onChange={() => setIsPinned(!isPinned)}
                            checked={isPinned}
                        />
                        Pin column
                    </label>
                </div>
            )}

            {canUseCustomComponent && !isLoading && (
                <FormGroup label="Component Type" htmlFor="component-type">
                    <Select
                        inputId="component-type"
                        className="select-field"
                        styles={getSharedStyles<{ label: string | null; value: string | null }>()}
                        options={customComponents}
                        onChange={(selectedComponentType) =>
                            setComponentType(selectedComponentType)
                        }
                        value={componentType}
                        noOptionsMessage={() => 'No results found'}
                        placeholder="Select a custom component"
                        isClearable
                    />
                </FormGroup>
            )}

            <footer className="sidebar-mini-editor-footer">
                <ButtonDefault onClick={onCancel}>Cancel</ButtonDefault>
                <ButtonPrimary onClick={onSave}>
                    {selectedItem !== null ? 'Update' : 'Add'} Column
                </ButtonPrimary>
            </footer>
        </div>
    );
};

export default DataPresentationEditor;
