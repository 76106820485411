import { type ReactNode, createContext, useCallback, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { notifyError } from '../../../js/actions/reduxActions/notification';
import { enableFeatureFlag, getFeatureFlags } from '../../sources/featureFlags';
import type { FeatureFlagAPI } from '../../types';

type FeatureFlagProviderProps = {
    defaultFlags?: FeatureFlagAPI[];
    children?: ReactNode;
};

type FeatureFlagContext = {
    flags: FeatureFlagAPI[];
    isLoading: boolean;
    getFlag: (flagName: string) => boolean;
    fetchFeatureFlags: () => Promise<void>;
    setFeatureFlag: (id: string, enabled: boolean) => Promise<void>;
};

const FeatureFlagContext = createContext<FeatureFlagContext | null>(null);

export const FeatureFlagProvider = ({ defaultFlags, children }: FeatureFlagProviderProps) => {
    const [flags, setFlags] = useState<FeatureFlagAPI[]>(defaultFlags ?? []);
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();

    const fetchFeatureFlags = useCallback(async () => {
        try {
            setIsLoading(true);

            const flags = await getFeatureFlags();

            setFlags(flags);
        } catch (error) {
            dispatch(notifyError(error));
        } finally {
            setIsLoading(false);
        }
    }, [dispatch]);

    const setFeatureFlag = useCallback(
        async (id: string, enabled: boolean) => {
            try {
                setIsLoading(true);

                await enableFeatureFlag({ featureFlagId: id, enabled });

                await fetchFeatureFlags();
            } catch (error) {
                dispatch(notifyError(error));
            } finally {
                setIsLoading(false);
            }
        },
        [fetchFeatureFlags, dispatch],
    );

    const getFlag = useCallback(
        (flagName: string) => {
            return flags.find((flag) => flag.developerName === flagName)?.enabled ?? false;
        },
        [flags],
    );

    const contextValue: FeatureFlagContext = {
        flags,
        isLoading,
        getFlag,
        fetchFeatureFlags,
        setFeatureFlag,
    };

    return <FeatureFlagContext value={contextValue}>{children}</FeatureFlagContext>;
};

export const useFeatureFlag = () => {
    const context = useContext(FeatureFlagContext);
    if (!context) {
        throw new Error('useFeatureFlag must be used within a FeatureFlag context');
    }

    return context;
};
