import type { ComponentType } from 'react';
import { getElementStyles } from '../../../js/components/graph/elements/elementStyles';
import type { CustomIconProps } from '../../types';

type MapElementIconProps = {
    elementType: string;
} & CustomIconProps;

const MapElementIcon = ({ elementType, ...otherProps }: MapElementIconProps) => {
    const Icon = (getElementStyles(elementType) as { icon: ComponentType<CustomIconProps> }).icon;
    return <Icon {...otherProps} />;
};

export default MapElementIcon;
