import { useEffect, useState } from 'react';
import FormGroup from '../../generic/FormGroup';
import { getAllPlayerNames } from '../../../sources/player';
import Toggle from '../../inputs/Toggle';
import translations from '../../../translations';
import { useAuth } from '../../AuthProvider';
import { getAllThemeNames } from '../../../sources/theme';
import { isNullOrEmpty } from '../../../utils/guard';
import CopyableText from '../../generic/CopyableText';
import type { Environment } from '../../../types/environment';

type Props = {
    environment: Environment | null;
    updateEnvironment: (environment: Environment) => void;
    hasSubmitted: boolean;
};

const EnvironmentSettings = ({ environment, updateEnvironment, hasSubmitted }: Props) => {
    const { tenant } = useAuth();
    const [players, setPlayers] = useState<string[]>([]);
    const [themes, setThemes] = useState<string[]>([]);

    const onDataChange = (name: string, value: string | boolean) => {
        if (environment) {
            updateEnvironment({
                ...environment,
                [name]: value,
            });
        }
    };

    useEffect(() => {
        const loadPlayersAndThemes = async () => {
            const players = await getAllPlayerNames();
            const themes = await getAllThemeNames();
            setPlayers(players);
            setThemes(themes);
        };

        loadPlayersAndThemes();
    }, []);

    return (
        <div className="margin-top">
            <FormGroup
                label="Environment Name:"
                htmlFor="environment-name"
                isRequired={true}
                validationMessage="Environment Name field is required"
                isValid={!isNullOrEmpty(environment?.name)}
                showValidation={hasSubmitted}
            >
                <input
                    id="environment-name"
                    className="form-control form-control-dynamic"
                    value={environment?.name}
                    type="text"
                    onChange={(e) => onDataChange('name', e.target.value)}
                />
            </FormGroup>
            <FormGroup label="Description:" htmlFor="environment-description" isRequired={false}>
                <textarea
                    id="environment-description"
                    className="form-control form-control-textarea"
                    value={environment?.description}
                    onChange={(e) => onDataChange('description', e.target.value)}
                    rows={3}
                />
            </FormGroup>
            {!tenant?.tenantSettings?.themes && (
                <FormGroup
                    label="Select a default player"
                    htmlFor="default-player"
                    isRequired
                    validationMessage="Default player is required"
                    isValid={!isNullOrEmpty(environment?.defaultPlayerName)}
                    showValidation={hasSubmitted}
                >
                    <select
                        id="default-player"
                        className="form-control form-control-dynamic"
                        value={environment?.defaultPlayerName}
                        onChange={(e) => onDataChange('defaultPlayerName', e.target.value)}
                    >
                        <option key="-1" value="">
                            Select player
                        </option>
                        {players
                            ? players.map((player) => (
                                  <option key={player} value={player}>
                                      {player}
                                  </option>
                              ))
                            : null}
                    </select>
                </FormGroup>
            )}
            {tenant?.tenantSettings?.themes && (
                <FormGroup
                    label="Select a default theme"
                    htmlFor="default-theme"
                    isRequired
                    validationMessage="Default theme is required"
                    isValid={!isNullOrEmpty(environment?.defaultThemeName)}
                    showValidation={hasSubmitted}
                >
                    <select
                        id="default-theme"
                        className="form-control form-control-dynamic"
                        value={environment?.defaultThemeName}
                        onChange={(e) => onDataChange('defaultThemeName', e.target.value)}
                    >
                        <option key="-1" value="">
                            Select theme
                        </option>
                        {themes?.map((theme) => (
                            <option key={theme} value={theme}>
                                {theme}
                            </option>
                        ))}
                    </select>
                </FormGroup>
            )}

            <div className="form-group">
                <label htmlFor="is-default-toggle">
                    <Toggle
                        id="is-default-toggle"
                        isOn={!!environment?.isDefault}
                        onChange={({ isOn }) => onDataChange('isDefault', isOn)}
                        testId="is-default"
                    />
                    {translations.ENVIRONMENT_default_option}
                </label>
            </div>
            <CopyableText
                labelText="Environment ID:"
                copyableText={environment?.id || ''}
                hasCopyButton={true}
            />
        </div>
    );
};

export default EnvironmentSettings;
