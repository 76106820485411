import { pathOr } from 'ramda';
import {
    type ChangeEventHandler,
    type ComponentProps,
    type ElementRef,
    useEffect,
    useRef,
    useState,
} from 'react';
import { type ConnectedProps, connect } from 'react-redux';
import '../../../css/importExport.css';
import {
    exportPackage,
    exportWithPasswords,
    fetchActivatedFlows,
    finishImport,
    importPackage,
    importSharedPackage,
    selectExport,
    setExportedFlow,
    setImportToken,
    setImportedPackage,
    sharePackage,
    showImportConfirmation,
} from '../../js/actions/reduxActions/flows';
import { notifyError, notifySuccess } from '../../js/actions/reduxActions/notification';
import { getAllEnvironments } from '../sources/environments';
import translations from '../translations';
import type { FlowResponseAPI, PackageConflictResponse, UserTenantResponseAPI } from '../types';
import type { Environment } from '../types/environment';
import { isNullOrUndefined } from '../utils/guard';
import { stringReplace } from '../utils/string';
import ButtonPrimary from './buttons/ButtonPrimary';
import DependencyTable from './generic/DependencyTable';
import FormGroup from './generic/FormGroup';
import ConfirmModal from './generic/modal/ConfirmModal';
import ComponentWithTooltip from './generic/tooltip/ComponentWithTooltip';
import FileDrop from './generic/upload/FileDrop';
import ClipboardInput from './inputs/ClipboardInput';
import Loader from './loader/Loader';
import ThemeImportExport from './theme/ThemeImportExport';
import TranslationBase from './translations/TranslationsBase';

const mapStateToProps = ({
    flows,
}: {
    flows: {
        all: FlowResponseAPI[];
        activated: FlowResponseAPI[];
        selectedFlowId: string | null;
        exportedJSON: string | null;
        sharingToken: string | null;
        includePasswords: boolean;
        importedJSON: string | null;
        importToken: string | null;
        showImportConfirmation: boolean;
        importSuccess: FlowResponseAPI | PackageConflictResponse | boolean;
        importConflict: boolean;
        importConflictDependents: PackageConflictResponse;
        isDeleting: boolean;
        isLoading: boolean;
    };
}) => {
    const { activated, ...rest } = flows;

    const sortedFlows = activated
        .slice()
        .sort((a, b) => a.developerName.toLowerCase().localeCompare(b.developerName.toLowerCase()));

    return {
        flows: sortedFlows,
        flowsReduxState: rest,
    };
};

const mapDispatchToProps = {
    fetchActivatedFlows,
    selectExport,
    exportPackage,
    exportWithPasswords,
    sharePackage,
    setExportedFlow,
    setImportToken,
    showImportConfirmation,
    setImportedPackage,
    importPackage,
    importSharedPackage,
    finishImport,
    notifySuccess,
    notifyError,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ImportExportProps = {
    tenant: UserTenantResponseAPI;
} & ConnectedProps<typeof connector>;

const ImportExport = ({
    tenant,
    flowsReduxState,
    flows,
    fetchActivatedFlows,
    selectExport,
    exportPackage,
    exportWithPasswords,
    sharePackage,
    setExportedFlow,
    setImportToken,
    showImportConfirmation,
    setImportedPackage,
    importPackage,
    importSharedPackage,
    finishImport,
    notifySuccess,
    notifyError,
}: ImportExportProps) => {
    const [environments, setEnvironments] = useState<Environment[]>();
    const [environmentId, setEnvironmentId] = useState<string | null>(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const modalContainerRef = useRef<ElementRef<'div'>>(null);
    const downloadRef = useRef<ElementRef<'a'>>(null);

    const onFetchActivatedFlows = (environmentId: string) => {
        fetchActivatedFlows(tenant.id, environmentId);
    };

    const onChangeImportToken: ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => {
        setImportToken(value);
    };

    const onEnvironmentSelected: ChangeEventHandler<HTMLSelectElement> = ({
        target: { value },
    }) => {
        setEnvironmentId(value);

        selectExport('');

        onFetchActivatedFlows(value);
    };

    const onFlowSelected: ChangeEventHandler<HTMLSelectElement> = ({ target: { value } }) => {
        selectExport(value);
    };

    /**
     * Generate Sharing Token
     */
    const onShare = async () => {
        if (!flowsReduxState.selectedFlowId) {
            return;
        }

        setIsProcessing(true);

        await sharePackage(
            flowsReduxState.selectedFlowId,
            tenant.id,
            flowsReduxState.includePasswords,
            environmentId,
        );

        setIsProcessing(false);
    };

    /**
     * Kick off Package Download
     */
    const onExport = async () => {
        if (!flowsReduxState.selectedFlowId) {
            return;
        }

        setIsProcessing(true);

        await exportPackage(
            flowsReduxState.selectedFlowId,
            tenant.id,
            flowsReduxState.includePasswords,
            environmentId,
        );

        setIsProcessing(false);
    };

    /**
     * Import package via JSON encoded string. Usually read from a file via drag-n-drop
     * @param force if true import regardless of any conflicts, otherwise honour conflicts
     */
    const onImport = (force = false) => {
        if (!flowsReduxState.importedJSON) {
            return;
        }

        importPackage(flowsReduxState.importedJSON, tenant.id, force);
        showImportConfirmation(false);
    };

    /**
     * Import package via a sharing token
     * @param force if true import regardless of any conflicts, otherwise honour conflicts
     */
    const onImportShared = (force = false) => {
        if (!flowsReduxState.importToken) {
            return;
        }

        importSharedPackage(flowsReduxState.importToken, tenant.id, force);
        showImportConfirmation(false);
    };

    const onDrop = (files: File[]) => {
        for (const file of files) {
            const reader = new FileReader();
            reader.onloadend = (event) => {
                confirmImport(event.target?.result?.toString() ?? null);
            };
            reader.readAsText(file);
        }
    };

    const onIncludePasswordsChange: React.ChangeEventHandler<HTMLInputElement> = ({
        target: { checked },
    }) => {
        exportWithPasswords(checked);
    };

    const getNameFromFlowPackage = (flowJSON: string | null) => {
        if (!flowJSON) {
            return null;
        }

        /*
            Properly typing flowPackage would be onerous as its type is complex and deeply nested, making it difficult to write and maintain.
            Moreover, this type of value appears not to be used elsewhere; and here, where it is used, it is for its developerName property only.
            Using unknown and pathOr is therefore more expedient and probably safer as it makes fewer assumptions and no guarantees of the type's shape. 
        */
        const flowPackage: unknown = (() => {
            try {
                return JSON.parse(flowJSON);
            } catch {
                return null;
            }
        })();

        return pathOr<string | null>(null, ['flowConfiguration', 'developerName'], flowPackage);
    };

    /**
     * The confirmation handles uploading via drag-n-drop or using an import token.
     *
     * @param uploadJSON optional package JSON received from the drag-n-drop.
     */
    const confirmImport = (uploadJSON: string | null = null) => {
        if (uploadJSON) {
            setImportedPackage(uploadJSON);
        }
        showImportConfirmation(true);
    };

    const cancelImport = () => {
        showImportConfirmation(false);
    };

    const createDownload = () => {
        const flowName =
            (flows &&
                flowsReduxState.selectedFlowId &&
                flows.find(({ id: { id } }) => id === flowsReduxState.selectedFlowId)
                    ?.developerName) ??
            null;

        const blob = new Blob([flowsReduxState.exportedJSON || ''], {
            type: 'application/json',
        });

        return {
            fileName: `${flowName}.package`,
            blob,
        };
    };

    const download = createDownload();

    const downloadUrl = URL.createObjectURL(download.blob);

    const environmentOptions = environments
        ? [
              <option value="" key="no environment">
                  {translations.ENVIRONMENT_filters_select_environment}
              </option>,
          ].concat(
              environments.map((environment) => (
                  <option value={environment.id} key={environment.id}>
                      {environment.name}
                  </option>
              )),
          )
        : [];

    const options = [
        <option value="" key="no flow">
            {translations.IMPORT_EXPORT_default_flow_option_label}
        </option>,
    ].concat(
        flows.map((flow) => (
            <option value={flow.id.id} key={flow.id.id}>
                {flow.developerName}
            </option>
        )),
    );

    const isFlowSelected =
        flowsReduxState.selectedFlowId !== null && flowsReduxState.selectedFlowId !== '';

    const importedFlowName = getNameFromFlowPackage(flowsReduxState.importedJSON);

    const message =
        isNullOrUndefined(importedFlowName) === false
            ? stringReplace(
                  // If there is a Flow name found, then show the flow name import text
                  translations.IMPORT_confirm_message_with_flow_name,
                  importedFlowName,
                  tenant.developerName,
              )
            : stringReplace(
                  // Otherwise show the import text without the flow name
                  translations.IMPORT_confirm_message,
                  tenant.developerName,
              );

    const confirmProps = {
        title: translations.IMPORT_confirm_title,
        messages: [message],
        onCancel: cancelImport,
        onConfirm: flowsReduxState.importedJSON
            ? () => onImport(false)
            : () => onImportShared(false),
        show: flowsReduxState.showImportConfirmation,
    };

    const dependencyList = flowsReduxState.importConflict ? (
        <DependencyTable dependents={flowsReduxState.importConflictDependents} />
    ) : null;

    const confirmOverwrite: ComponentProps<typeof ConfirmModal> = {
        title: translations.IMPORT_confirm_overwrite_title,
        messages: [
            translations.IMPORT_confirm_overwrite_body_1,
            translations.IMPORT_confirm_overwrite_body_2,
            dependencyList,
            translations.IMPORT_confirm_overwrite_body_3,
            translations.IMPORT_confirm_overwrite_body_4,
        ],
        onCancel: cancelImport,
        onConfirm: flowsReduxState.importedJSON ? () => onImport(true) : () => onImportShared(true),
        show: flowsReduxState.importConflict,
    };

    useEffect(() => {
        (async () => {
            if (tenant?.tenantSettings?.environments) {
                const environments = await getAllEnvironments();
                setEnvironments(environments);
            } else {
                fetchActivatedFlows(tenant.id);
            }
        })();
    }, [fetchActivatedFlows, tenant?.tenantSettings?.environments, tenant.id]);

    useEffect(() => {
        if (flowsReduxState.exportedJSON) {
            downloadRef.current?.click();
            // Reset exported package for next download.
            setExportedFlow('');
        }

        if (flowsReduxState.importSuccess && typeof flowsReduxState.importSuccess !== 'boolean') {
            const flowName =
                'developerName' in flowsReduxState.importSuccess
                    ? ` '${flowsReduxState.importSuccess.developerName}' `
                    : '';

            notifySuccess(
                stringReplace(translations.IMPORT_EXPORT_import_success_message, {
                    flowName,
                }),
            );

            finishImport();
        }
    }, [
        finishImport,
        notifySuccess,
        setExportedFlow,
        flowsReduxState.exportedJSON,
        flowsReduxState.importSuccess,
    ]);

    return (
        <div className="import-export" ref={modalContainerRef}>
            {isProcessing && <Loader message={translations.IMPORT_EXPORT_loading_message} />}
            <h1>{translations.IMPORT_EXPORT_export_section_heading}</h1>
            <div className="export">
                <div
                    className="form-group"
                    hidden={!tenant?.tenantSettings?.environments}
                    data-testid="environmentSelectGroup"
                >
                    <select
                        onChange={onEnvironmentSelected}
                        className="select-flow form-control"
                        data-testid="environmentSelect"
                    >
                        {environmentOptions}
                    </select>
                </div>
                <div className="form-group">
                    <select
                        value={flowsReduxState.selectedFlowId || ''}
                        onChange={onFlowSelected}
                        className="select-flow form-control"
                        disabled={!!tenant?.tenantSettings?.environments && !environmentId}
                        data-testid="flowSelect"
                    >
                        {options}
                    </select>
                </div>
                <div className="form-group">
                    <ComponentWithTooltip
                        trigger={['hover', 'focus']}
                        tooltipPlacement="bottom-start"
                        tooltipContent={
                            translations.IMPORT_EXPORT_include_passwords_checkbox_description
                        }
                        tooltipClass="import-export-tooltip"
                        tooltipArrowClass="import-export-tooltip-arrow"
                        fadeTime={0.2}
                        arrowClearance={8}
                    >
                        <label>
                            <input
                                type="checkbox"
                                checked={flowsReduxState.includePasswords}
                                onChange={onIncludePasswordsChange}
                            />
                            {translations.IMPORT_EXPORT_include_passwords_checkbox_label}
                        </label>
                    </ComponentWithTooltip>
                </div>
                <ButtonPrimary
                    onClick={onShare}
                    disabled={!isFlowSelected || isProcessing}
                    className="share"
                    title={translations.IMPORT_EXPORT_generate_sharing_token}
                >
                    {translations.IMPORT_EXPORT_generate_sharing_token}
                </ButtonPrimary>
                <ButtonPrimary
                    onClick={onExport}
                    disabled={!isFlowSelected || isProcessing}
                    title={translations.IMPORT_EXPORT_download_package}
                >
                    {translations.IMPORT_EXPORT_download_package}
                </ButtonPrimary>
                <div>
                    <h2>{translations.IMPORT_EXPORT_sharing_token_section_heading}</h2>
                    <FormGroup label={translations.IMPORT_EXPORT_sharing_token_label}>
                        <ClipboardInput
                            value={flowsReduxState.sharingToken || ''}
                            placeholder={translations.IMPORT_EXPORT_sharing_token_placeholder}
                            inputClassName="sharing-token form-control with-extension"
                        />
                    </FormGroup>
                </div>
                <a
                    href={downloadUrl}
                    className="hidden"
                    download={download.fileName}
                    ref={downloadRef}
                >
                    {translations.IMPORT_EXPORT_download_link_text}
                </a>
            </div>
            <div className="import margin-top-large">
                <h1>{translations.IMPORT_EXPORT_import_section_heading}</h1>
                <FileDrop
                    onChange={onDrop}
                    placeholder={translations.IMPORT_EXPORT_file_drop_placeholder}
                    fileTypes=".package"
                />
                <h2>{translations.IMPORT_EXPORT_import_shared_package_section_heading}</h2>
                <p>{translations.IMPORT_EXPORT_sharing_token_input_description}</p>
                <div className="shared">
                    <input
                        type="text"
                        className="form-control import-token"
                        onChange={onChangeImportToken}
                        placeholder={translations.IMPORT_EXPORT_shared_token_input_placeholder}
                        value={flowsReduxState.importToken || ''}
                    />
                    <ButtonPrimary
                        disabled={!flowsReduxState.importToken}
                        onClick={() => confirmImport()}
                    >
                        {translations.IMPORT_EXPORT_import_button_label}
                    </ButtonPrimary>
                </div>
            </div>
            {tenant?.tenantSettings?.themes && (
                <ThemeImportExport
                    environmentOptions={environmentOptions}
                    tenant={tenant}
                    container={modalContainerRef.current}
                    notifyError={notifyError}
                    notifySuccess={notifySuccess}
                />
            )}
            <TranslationBase
                container={modalContainerRef.current}
                setIsProcessing={(isProcessing: boolean) => setIsProcessing(isProcessing)}
            />
            <ConfirmModal {...confirmProps} container={modalContainerRef.current} />
            <ConfirmModal {...confirmOverwrite} container={modalContainerRef.current} />
        </div>
    );
};

export default connector(ImportExport);
