import { useCollaboration } from '../../../../collaboration/CollaborationProvider';
import {
    getGroupElement as getGroupElementAPI,
    type GroupElementRequestAPI,
    saveGroupElement as saveGroupElementAPI,
} from '../../../../sources/graph';
import type { NotifyError } from '../../../../types';
import { guid } from '../../../../utils/guid';

type Props = {
    flowId: string;
    notifyError: NotifyError;
    setIsLoading: (isLoading: boolean) => void;
    refreshFlow: () => void;
    focusAndSelectElement: (id: string) => void;
};

const useGroup = ({
    flowId,
    notifyError,
    setIsLoading,
    refreshFlow,
    focusAndSelectElement,
}: Props) => {
    const { invoke } = useCollaboration();

    const getGroupElement = async (id: string) => {
        try {
            return await getGroupElementAPI(id, flowId);
        } catch (error) {
            notifyError(error);
        }

        return undefined;
    };

    const saveGroupElement = async (groupElement: GroupElementRequestAPI) => {
        try {
            // Validate that all the selected users/groups have an attribute selected
            if (
                (groupElement.authorization?.groups &&
                    (groupElement.authorization.groups.some((item) => !item.authenticationId) ||
                        groupElement.authorization.groups.some((item) => !item.attribute))) ||
                (groupElement.authorization?.users &&
                    (groupElement.authorization.users.some((item) => !item.authenticationId) ||
                        groupElement.authorization.users.some((item) => !item.attribute)))
            ) {
                throw Error('Please select items from all of the dropdowns');
            }

            if (!groupElement.id) {
                groupElement.id = guid();
            }

            setIsLoading(true);

            const updatedGroupElement = await saveGroupElementAPI(groupElement, flowId);

            invoke('GraphChanged', flowId, [updatedGroupElement.id], null);

            refreshFlow();
            focusAndSelectElement(updatedGroupElement.id);
        } catch (error) {
            notifyError(error);
        } finally {
            setIsLoading(false);
        }
    };

    return {
        getGroupElement,
        saveGroupElement,
    };
};

export default useGroup;
