import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { notifyError } from '../../../../js/actions/reduxActions/notification';
import { getTenants } from '../../../sources/organization';
import translations from '../../../translations';
import type { OrganizationTenant } from '../../../types';
import type { TenantMinimalAPI } from '../../../types/Tenant';
import { stringContains, stringReplace } from '../../../utils/string';
import SearchInput from '../../generic/SearchInput';
import Table, { type TableColumnList } from '../../generic/Table';
import TenantName from '../../generic/TenantName';

type LocalRuntimeOrgTenants = {
    connectedTenantIds: string[];
    onSelect: (isSelected: boolean, tenantId: string) => void;
};

const LocalRuntimeOrgTenants = ({ connectedTenantIds, onSelect }: LocalRuntimeOrgTenants) => {
    const [isLoading, setIsLoading] = useState(false);
    const [tenants, setTenants] = useState<OrganizationTenant[]>([]);
    const [searchFilter, setSearchFilter] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchTenants = async () => {
            try {
                setIsLoading(true);

                const tenants = await getTenants();

                setTenants(tenants);
            } catch (error) {
                dispatch(notifyError(error));
            } finally {
                setIsLoading(false);
            }
        };

        fetchTenants();
    }, [dispatch]);

    const tenantsToDisplay = tenants
        .flatMap((tenant) => [tenant, ...tenant.subtenants])
        .filter((tenant) => stringContains(tenant.developerName, searchFilter, false));

    const handleChange = (searchTerm: string) => {
        setSearchFilter(searchTerm);
    };

    const tenantTableColumns: TableColumnList<TenantMinimalAPI> = [
        {
            renderHeader: () =>
                stringReplace(translations.LOCAL_RUNTIME_create_screen_number_tenants_selected, {
                    tenantsSelected: connectedTenantIds.length,
                }),
            renderCell: ({ item: tenant }) => (
                <input
                    checked={!!connectedTenantIds.find((tenantId) => tenantId === tenant.id)}
                    onChange={({ target: { checked } }) => onSelect(checked, tenant.id)}
                    type="checkbox"
                />
            ),
            size: '6rem',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_tenant_name,
            renderCell: ({ item: tenant }) => <TenantName name={tenant.developerName} />,
        },
    ];

    return (
        <>
            <SearchInput value={searchFilter} onChange={handleChange} />
            <Table
                caption={translations.LOCAL_RUNTIME_create_screen_shared_tenants_table_caption}
                wrapperClassName="margin-top"
                items={tenantsToDisplay}
                columns={tenantTableColumns}
                isLoading={isLoading}
                pagination={true}
                rowKeyGenerator={(tenant) => tenant.id}
            />
        </>
    );
};

export default LocalRuntimeOrgTenants;
