import OperatorRouter from './OperatorRouter';
import { OperatorProvider } from './OperatorProvider';
import {
    MapElementProvider,
    type MapElementConfigProps,
} from '../contextProviders/MapElementProvider';
import screens from '../common/screens';
import MapElementModal from '../../../graph/MapElementModal';
import { MAP_ELEMENT_TYPES } from '../../../../constants';

const Operator = (props: MapElementConfigProps) => {
    return (
        <MapElementProvider
            {...props}
            defaultScreen={screens.operator}
            elementType={MAP_ELEMENT_TYPES.operator}
            isLoading={false}
        >
            <MapElementModal>
                <OperatorProvider>
                    <OperatorRouter />
                </OperatorProvider>
            </MapElementModal>
        </MapElementProvider>
    );
};

export default Operator;
