import translations from '../../../translations';
import type { RuntimeListResponse } from '../../../types';
import { stringReplace } from '../../../utils';
import ConfirmModal from '../../generic/modal/ConfirmModal';
import { useState, type ComponentPropsWithoutRef } from 'react';
import { useDispatch } from 'react-redux';
import { notifyError, notifySuccess } from '../../../../js/actions/reduxActions/notification';
import { deleteRuntime } from '../../../sources/runtime';

type LocalRuntimeDeleteModalProps = {
    selectedRuntime: RuntimeListResponse;
    // Wrong type gets inferred when picking onConfirm from ConfirmModal props, so declare a new prop here
    onConfirm: () => void;
} & Pick<ComponentPropsWithoutRef<typeof ConfirmModal>, 'container' | 'onCancel'>;

const LocalRuntimeDeleteModal = ({
    selectedRuntime,
    container,
    onConfirm,
    onCancel,
}: LocalRuntimeDeleteModalProps) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const dispatch = useDispatch();

    const handleRuntimeDeleteConfirm = async () => {
        try {
            setIsDeleting(true);

            await deleteRuntime(selectedRuntime.id);

            dispatch(
                notifySuccess(
                    stringReplace(translations.LOCAL_RUNTIME_runtime_delete_success_message, {
                        name: selectedRuntime.developerName,
                    }),
                ),
            );

            // Clear selected runtime and hide the delete modal
            onConfirm();
        } catch (error) {
            dispatch(notifyError(error));
        } finally {
            setIsDeleting(false);
        }
    };

    return (
        <ConfirmModal
            show
            title={translations.LOCAL_RUNTIME_delete_modal_title}
            messages={[
                stringReplace(translations.LOCAL_RUNTIME_delete_modal_message, {
                    connectionName: selectedRuntime?.developerName,
                }),
            ]}
            buttonStyle="danger"
            buttonCaption={translations.COMMON_delete}
            onCancel={onCancel}
            onConfirm={handleRuntimeDeleteConfirm}
            container={container}
            isInProgress={isDeleting}
        />
    );
};

export default LocalRuntimeDeleteModal;
