import ListenerDetails from '../common/listeners/ListenerDetails';
import MessageActionDetails from '../common/messageActions/MessageActionDetails';
import OutcomeDetails from '../common/OutcomeDetails';
import screens from '../common/screens';
import { useMapElement } from '../contextProviders/MapElementProvider';
import MessageConfiguration from './MessageConfiguration';

const MessageRouter = () => {
    const {
        currentScreen,
        mapElement,
        onSwitchScreen,
        selectedOutcomeIndex,
        setSelectedOutcomeIndex,
    } = useMapElement();

    switch (currentScreen) {
        case screens.message:
            return <MessageConfiguration />;

        case screens.messageActions:
            return <MessageActionDetails />;

        case screens.listener:
            return <ListenerDetails />;

        case screens.outcome: {
            const selectedOutcome = mapElement.outcomes?.[selectedOutcomeIndex ?? 0] ?? null;

            return (
                <OutcomeDetails
                    outcome={selectedOutcome}
                    onSave={() => onSwitchScreen(screens.message)}
                    onCancel={() => {
                        onSwitchScreen(screens.message);
                        setSelectedOutcomeIndex(null);
                    }}
                />
            );
        }

        default:
            return null;
    }
};

export default MessageRouter;
