import { useEffect, useState } from 'react';
import Select, { type MenuPosition, type SingleValue } from 'react-select';
import { getFlows } from '../../../../sources/flow';
import translations from '../../../../translations';
import type { FlowResponseAPI, NotifyError } from '../../../../types';
import { getSharedStyles } from '../../../../utils/select';
import FormGroup from '../../../generic/FormGroup';

type Props = {
    onChange: (e: SingleValue<{ label: string | null; value: string | null }>) => void;
    selectedItem: string | null;
    flowId: string;
    notifyError: NotifyError;
    tenantId: string;
    isRequired: boolean;
    isValid: boolean;
    showValidation: boolean;
    validationMessage: string;
    menuPosition: MenuPosition;
    menuPortalTarget: HTMLElement | null;
    closeMenuOnScroll: (e: Event) => boolean;
};

const FlowSelectBox = ({
    onChange,
    selectedItem,
    flowId,
    notifyError,
    tenantId,
    isRequired,
    isValid,
    showValidation,
    validationMessage,
    menuPosition,
    menuPortalTarget,
    closeMenuOnScroll,
}: Props) => {
    const [flows, setFlows] = useState<FlowResponseAPI[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchFlows = async () => {
            try {
                setIsLoading(true);
                const flows = await getFlows(tenantId);
                setFlows(flows);
            } catch (error) {
                notifyError(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchFlows();
    }, [tenantId, notifyError]);

    const selectedFlow =
        selectedItem && flows.length > 0
            ? flows.find((flow) => flow.developerName === selectedItem)
            : null;

    const currentSelection =
        selectedFlow !== null
            ? { label: selectedFlow?.developerName ?? null, value: selectedFlow?.id.id ?? null }
            : null;

    const options = flows
        // Don't show current (parent) flow in select list
        .filter((item) => item.id.id !== flowId)
        .map((flow) => ({ label: flow.developerName, value: flow.id.id }));

    return (
        <FormGroup
            label={translations.SUBFLOW_select_label}
            htmlFor="flow-select-box"
            isRequired={isRequired}
            isValid={isValid}
            showValidation={showValidation}
            validationMessage={validationMessage}
        >
            <Select
                inputId="flow-select-box"
                styles={{
                    ...getSharedStyles<{
                        label: string | null;
                        value: string | null;
                    }>({
                        container: (baseStyles) => ({
                            ...baseStyles,
                            width: '300px',
                        }),
                    }),
                }}
                placeholder={translations.SUBFLOW_select_placeholder}
                onChange={onChange}
                value={currentSelection}
                isLoading={isLoading}
                options={options}
                noOptionsMessage={() => 'No results found'}
                menuPosition={menuPosition}
                menuPortalTarget={menuPortalTarget}
                closeMenuOnScroll={closeMenuOnScroll}
                isClearable
            />
        </FormGroup>
    );
};

export default FlowSelectBox;
