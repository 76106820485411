import FormGroup from '../../../../../../../generic/FormGroup';
import Select from 'react-select';
import ButtonDefault from '../../../../../../../buttons/ButtonDefault';
import ButtonPrimary from '../../../../../../../buttons/ButtonPrimary';
import { useState } from 'react';
import { getSharedStyles } from '../../../../../../../../utils/select';

interface Props {
    screen?: string;
    onCancel: () => void;
    onSave: (chartType: string) => void;
}

const NewDataSet = ({ onCancel, onSave }: Props) => {
    const [chartType, setChartType] = useState<string | null | undefined>(null);

    const onAddNewDataset = () => {
        if (chartType) {
            onSave(chartType);
        }
    };

    const options = [
        { value: 'chart-bar', label: 'Bar Chart' },
        { value: 'chart-line', label: 'Line Chart' },
        { value: 'chart-pie', label: 'Pie Chart' },
        { value: 'chart-doughnut', label: 'Doughnut Chart' },
        { value: 'chart-polar', label: 'Polar Chart' },
    ];

    const selectedChartType = options.find((option) => option.value === chartType) ?? null;

    return (
        <div className="sidebar-mini-editor">
            <h4 className="sidebar-section-heading">Add New Dataset</h4>
            <FormGroup label="Chart Type" htmlFor="chart-type" isRequired>
                <Select
                    inputId="chart-type"
                    className="select-field"
                    styles={getSharedStyles<(typeof options)[number]>()}
                    options={options}
                    value={selectedChartType}
                    placeholder="Choose a chart"
                    onChange={(e) => setChartType(e?.value)}
                    noOptionsMessage={() => 'No results found'}
                />
            </FormGroup>
            <footer className="sidebar-mini-editor-footer">
                <ButtonDefault onClick={onCancel}>Cancel</ButtonDefault>
                <ButtonPrimary title="Add Dataset" onClick={onAddNewDataset}>
                    Add Dataset
                </ButtonPrimary>
            </footer>
        </div>
    );
};

export default NewDataSet;
