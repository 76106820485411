import { useEffect, useState } from 'react';
import CopyableText from '../../generic/CopyableText';
import Table, { type TableColumnList } from '../../generic/Table';
import translations from '../../../translations';
import { isNullOrUndefined } from '../../../utils/guard';
import SearchInput from '../../generic/SearchInput';
import { useEnvironments } from './EnvironmentsProvider';
import type { Environment, EnvironmentTheme } from '../../../types/environment';
import { PaintBrush } from '@phosphor-icons/react';
import PreviewThemeModal from './PreviewThemeModal';
import type { FlowResponseAPI } from '../../../types';
import { getActivatedForEnvironment } from '../../../sources/flow';

type Props = {
    environment: Environment | null;
};

const EnvironmentThemes = ({ environment }: Props) => {
    const { getEnvironmentThemes, tenantId, environmentThemesLoading } = useEnvironments();

    const [themes, setThemes] = useState<EnvironmentTheme[]>([]);
    const [page, setPage] = useState(1);
    const pageSize = 20;
    const [total, setTotal] = useState<number>(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedTheme, setSelectedTheme] = useState('');
    const [showRunThemeModal, setShowRunThemeModal] = useState(false);
    const [flows, setFlows] = useState<FlowResponseAPI[]>([]);

    useEffect(() => {
        loadEnvironmentThemes(1, null);
    }, []);

    async function openPreviewThemeModal(theme: string) {
        setSelectedTheme(theme);
        setShowRunThemeModal(!showRunThemeModal);

        if (environment) {
            setFlows(await getActivatedForEnvironment(tenantId, environment.id));
        }
    }

    async function loadEnvironmentThemes(page: number, term: string | null) {
        if (environment) {
            const params = {
                environmentId: environment.id,
                page: page ?? 1,
                pageSize,
                searchTerm: term,
            };

            const response = await getEnvironmentThemes(params);

            setThemes(response?.items || []);
            setTotal(response?._meta.total || 0);
        }
    }

    const onSearch = (term: string) => {
        setPage(1);
        setSearchQuery(term);
        loadEnvironmentThemes(1, term);
    };

    const columns: TableColumnList<EnvironmentTheme> = [
        {
            renderHeader: () => 'Type',
            renderCell: ({ item }) => {
                if (!isNullOrUndefined(item)) {
                    return <PaintBrush width="20" height="20" />;
                }

                return null;
            },
            size: '60px',
        },
        {
            renderHeader: () => 'Name',
            renderCell: ({ item }) => item.name,
        },
        {
            renderHeader: () => 'Version ID',
            renderCell: ({ item }) => (
                <CopyableText copyableText={item.versionId} hasCopyButton={true} />
            ),
        },
        {
            renderHeader: () => 'Deployment Notes',
            renderCell: ({ item }) => item.summary,
            size: '35rem',
        },
        {
            renderHeader: () => 'Last Modified',
            renderCell: ({ item }) => {
                return new Date(item.updatedAt).toLocaleString();
            },
        },
        {
            renderHeader: () => 'Preview',
            renderCell: ({ item }) => {
                return (
                    <button
                        type="button"
                        className="btn btn-primary outcome"
                        onClick={() => openPreviewThemeModal(item.name)}
                    >
                        {translations.ENVIRONMENT_preview_in + environment?.name}
                    </button>
                );
            },
        },
    ];

    return (
        <>
            <SearchInput className="margin-top" onChange={onSearch} value={searchQuery} />

            <Table
                wrapperClassName="table margin-top margin-bottom"
                columns={columns}
                items={themes}
                isLoading={environmentThemesLoading}
                pagination={{
                    page,
                    total,
                    pageSize,
                    changePage: (nextPage) => {
                        setPage(nextPage);
                        loadEnvironmentThemes(nextPage, null);
                    },
                }}
            />
            <PreviewThemeModal
                showRunThemeModal={showRunThemeModal}
                setShowRunThemeModal={setShowRunThemeModal}
                flows={flows}
                selectedTheme={selectedTheme}
                environment={environment}
            />
        </>
    );
};

export default EnvironmentThemes;
