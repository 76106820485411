import { type KeyboardEvent, memo, useCallback, useEffect, useReducer, useState } from 'react';
import '../../../../../../css/flow/properties.less';
import FlowVersionManager from './FlowVersionManager';
import { getLatest, setLatest } from '../../../../sources/flow';
import translations from '../../../../translations';
import type {
    FlowIdentityProviderAPI,
    GroupAuthorizationAPI,
    NotifyError,
    NotifySuccess,
} from '../../../../types';
import { isNullOrEmpty, isNullOrUndefined } from '../../../../utils/guard';
import Modal from '../../../generic/modal/GenericModal';
import ModalBody from '../../../generic/modal/ModalBody';
import ModalFooter from '../../../generic/modal/ModalFooter';
import ModalHeader from '../../../generic/modal/ModalHeader';
import Loader from '../../../loader/Loader';
import Authorization from '../../../runtime-authorization/Authorization';
import Comments from './Comments';
import Deactivation from './Deactivation';
import Name from './Name';
import Navigation from './Navigation';
import State from './State';
import { reducer } from './reducer';
import ButtonDefault from '../../../buttons/ButtonDefault';
import ButtonPrimary from '../../../buttons/ButtonPrimary';

interface Props {
    flowId: string;
    setTabTitle: (title: string) => void;
    notifyError: NotifyError;
    notifySuccess: NotifySuccess;
    environmentsIsOn: boolean;
    container: HTMLElement;
    close: () => void;
}

const Properties = ({
    flowId,
    close,
    container,
    environmentsIsOn,
    setTabTitle,
    notifyError,
    notifySuccess,
}: Props) => {
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [flowConfiguration, dispatchFlowConfiguration] = useReducer(reducer, null);

    const isFormValid = () => {
        if (isNullOrUndefined(flowConfiguration)) {
            return false;
        }
        if (flowConfiguration.developerName === '') {
            return false;
        }

        return true;
    };

    useEffect(() => {
        const handleEscKey = ({ key }: KeyboardEvent) => {
            if (key === 'Escape') {
                close();
            }
        };

        document.addEventListener(
            'keydown',
            handleEscKey as unknown as EventListenerOrEventListenerObject,
        );

        return () => {
            document.removeEventListener(
                'keydown',
                handleEscKey as unknown as EventListenerOrEventListenerObject,
            );
        };
    }, [close]);

    useEffect(() => {
        const fetchFlowConfiguration = async () => {
            const flowConfiguration = await getLatest(flowId);
            dispatchFlowConfiguration({ type: 'setFlowConfiguration', payload: flowConfiguration });
        };

        fetchFlowConfiguration().catch(notifyError);
    }, [flowId, notifyError]);

    const saveChanges = async () => {
        if (flowConfiguration === null) {
            return;
        }

        setHasSubmitted(true);

        if (isFormValid()) {
            // If the Flow has been set to not use Users and Groups
            if (
                flowConfiguration.authorization &&
                flowConfiguration.authorization?.globalAuthenticationType !== 'SPECIFIED'
            ) {
                // Then remove the users and groups
                flowConfiguration.authorization.users = null;
                flowConfiguration.authorization.groups = null;
            }

            if (
                // If there are groups
                (flowConfiguration.authorization?.groups &&
                    // And the groups are wrong
                    (flowConfiguration.authorization.groups.some(
                        (item) => item.authenticationId === '-1',
                    ) ||
                        flowConfiguration.authorization.groups.some(
                            (item) => item.attribute === '-1',
                        ))) ||
                // If there are users
                (flowConfiguration.authorization?.users &&
                    // And the users are wrong
                    (flowConfiguration.authorization.users.some(
                        (item) => item.authenticationId === '-1',
                    ) ||
                        flowConfiguration.authorization.users.some(
                            (item) => item.attribute === '-1',
                        )))
            ) {
                notifyError('Please select items from all of the dropdowns');
                return;
            }

            if (flowConfiguration.authorization?.globalAuthenticationType === 'SPECIFIED') {
                if (
                    isNullOrEmpty(flowConfiguration.authorization.groups) &&
                    isNullOrEmpty(flowConfiguration.authorization.users)
                ) {
                    notifyError(translations.GROUPS_USERS_NONE_SELECTED_ERROR_MESSAGE);
                    return;
                }
            }

            try {
                await setLatest(flowConfiguration);

                setTabTitle(flowConfiguration.developerName);
                close();
                notifySuccess('Flow Properties successfully saved');
            } catch (error) {
                notifyError(error as Error);
            }
        }
    };

    const onChangeIdentityProvider = (identityProvider: FlowIdentityProviderAPI | null) =>
        dispatchFlowConfiguration({
            type: 'updateIdentityProvider',
            payload: { identityProvider },
        });

    const onChangeAuthorization = useCallback(
        (authorization: GroupAuthorizationAPI) =>
            dispatchFlowConfiguration({
                type: 'updateAuthorization',
                payload: { authorization },
            }),
        [],
    );

    return (
        <Modal container={container} onHide={close} className="flow-properties-page config-modal">
            <ModalHeader onHide={close}>
                <h4 className="modal-title">Flow Properties</h4>
            </ModalHeader>
            <ModalBody>
                {flowConfiguration === null ? (
                    <Loader message="Loading flow details" />
                ) : (
                    <>
                        <Name
                            isFormValid={isFormValid()}
                            hasSubmitted={hasSubmitted}
                            flowId={flowId}
                            developerName={flowConfiguration.developerName}
                            dispatchFlowConfiguration={dispatchFlowConfiguration}
                        />
                        <Comments
                            developerSummary={flowConfiguration.developerSummary}
                            dispatchFlowConfiguration={dispatchFlowConfiguration}
                        />
                        <Authorization
                            identityProvider={flowConfiguration.identityProvider}
                            onChangeIdentityProvider={onChangeIdentityProvider}
                            authorization={flowConfiguration.authorization}
                            onChangeAuthorization={onChangeAuthorization}
                            notifyError={notifyError}
                        />
                        <Navigation
                            allowJumping={flowConfiguration.allowJumping}
                            dispatchFlowConfiguration={dispatchFlowConfiguration}
                        />
                        <State
                            stateExpirationLength={flowConfiguration.stateExpirationLength}
                            idleStateExpirationLength={flowConfiguration.idleStateExpirationLength}
                            dispatchFlowConfiguration={dispatchFlowConfiguration}
                        />

                        {environmentsIsOn ? (
                            <Deactivation
                                flowId={flowId}
                                modalContainer={container}
                                notifyError={notifyError}
                                notifySuccess={notifySuccess}
                            />
                        ) : (
                            <FlowVersionManager
                                flowId={flowId}
                                modalContainer={container}
                                notifyError={notifyError}
                                notifySuccess={notifySuccess}
                            />
                        )}
                    </>
                )}
            </ModalBody>
            <ModalFooter>
                <ButtonDefault onClick={close}>{translations.COMMON_cancel}</ButtonDefault>
                <ButtonPrimary onClick={saveChanges}>
                    {translations.GRAPH_config_panel_save}
                </ButtonPrimary>
            </ModalFooter>
        </Modal>
    );
};

export default memo(Properties);
