import { useState, createContext, useContext } from 'react';
import { useMapElement } from '../contextProviders/MapElementProvider';
import screens from '../common/screens';

type ContextValue = {
    selectedOperationIndex: number | null;
    setSelectedOperationIndex: (index: number | null) => void;
    onEditOperation: (operationIndex: number) => void;
    onDeleteOperation: (operationIndex: number) => void;
};

type Props = {
    initialOperationIndex?: number | null;
    children: React.ReactNode;
};

const Context = createContext<ContextValue | undefined>(undefined);

const OperatorProvider = ({ initialOperationIndex = null, children }: Props) => {
    const { mapElement, setMapElement, onSwitchScreen } = useMapElement();

    const [selectedOperationIndex, setSelectedOperationIndex] = useState<number | null>(
        initialOperationIndex,
    );

    const onEditOperation = (operationIndex: number) => {
        setSelectedOperationIndex(operationIndex);
        if (
            Array.isArray(mapElement.operations) &&
            mapElement.operations[operationIndex].macroElementToExecuteId !== null
        ) {
            onSwitchScreen(screens.macroDetails);
        } else {
            onSwitchScreen(screens.operationDetails);
        }
    };

    const onDeleteOperation = (operationIndex: number) => {
        setMapElement({
            ...mapElement,
            operations: (mapElement.operations ?? []).filter(
                (_, index) => index !== operationIndex,
            ),
        });
    };

    const contextValue: ContextValue = {
        selectedOperationIndex,
        setSelectedOperationIndex,
        onEditOperation,
        onDeleteOperation,
    };

    return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

const useOperator = () => {
    const context = useContext(Context);
    if (context === undefined) {
        throw new Error('useOperator must be used within a OperatorProvider');
    }
    return context;
};

export { OperatorProvider, useOperator };
