import translations from '../../../../../../../../translations';
import ConfigSection from '../../ConfigSection';
import { useEffect, useState } from 'react';
import type { ObjectDataRequest, ServiceElementResponseAPI } from '../../../../../../../../types';
import TypeElementDropdown from '../../../../../../../types/TypeElementDropdown';
import FormGroup from '../../../../../../../generic/FormGroup';
import Select from 'react-select';
import { getSharedStyles } from '../../../../../../../../utils/select';
import { isNullOrEmpty } from '../../../../../../../../utils/guard';
import { getAllServicesV2 } from '../../../../../../../../sources/service';
import { usePageEditor } from '../../../../../PageEditorProvider';
import { NOTIFICATION_TYPES } from '../../../../../../../../constants';

interface Props {
    value: ObjectDataRequest | null;
    onChange: (value: ObjectDataRequest | null) => void;
}

const DataSource2Config = ({ value, onChange }: Props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [services, setServices] = useState<ServiceElementResponseAPI[]>([]);

    const { addNotification } = usePageEditor();

    // biome-ignore lint/correctness/useExhaustiveDependencies: Treat warnings as errors, fix later
    useEffect(() => {
        const loadServices = async () => {
            setIsLoading(true);

            try {
                const response = await getAllServicesV2({ limit: 500 });
                setServices(response.items);
            } catch (error) {
                addNotification({
                    type: NOTIFICATION_TYPES.error,
                    message: `${translations.PAGE_BUILDER_unable_to_load_services}: ${(error as Error).toString()}`,
                    isPersistent: true,
                });
            } finally {
                setIsLoading(false);
            }
        };

        loadServices();
    }, []);

    const onServiceElementIdChange = (serviceElementId: string | null) => {
        onChange({
            ...value,
            serviceElementId,
        } as ObjectDataRequest);
    };

    const onTypeElementIdChange = (typeElementId: string | null) => {
        onChange({
            ...value,
            typeElementId,
        } as ObjectDataRequest);
    };

    const options = services.map((service) => ({
        label: service.developerName || '',
        value: service.id,
    }));
    const selectedOption = value?.serviceElementId
        ? options.find((service) => service.value === value.serviceElementId)
        : null;

    return (
        <>
            <ConfigSection
                dataTestId="data-source-2-config"
                title={translations.PAGE_BUILDING_data_source_2_title}
            >
                <FormGroup
                    label={translations.PAGE_BUILDING_data_source_2_connector_label}
                    htmlFor="connector-select"
                    isValid={!isNullOrEmpty(value?.serviceElementId)}
                    validationMessage={
                        translations.PAGE_BUILDER_field_is_required_validation_message
                    }
                    showValidation={!isLoading}
                    isRequired={true}
                >
                    <Select
                        inputId="connector-select"
                        className="select-field"
                        styles={getSharedStyles<(typeof options)[number] | null | undefined>()}
                        options={options}
                        onChange={(selectedBinding) =>
                            onServiceElementIdChange(selectedBinding?.value || null)
                        }
                        placeholder="Select a connector"
                        value={selectedOption}
                        noOptionsMessage={() => 'No results found'}
                    />
                </FormGroup>
                <TypeElementDropdown
                    isValid={!isNullOrEmpty(value?.typeElementId)}
                    validationMessage={
                        translations.PAGE_BUILDER_field_is_required_validation_message
                    }
                    isRequired={true}
                    label={translations.PAGE_BUILDING_data_source_2_type_label}
                    typeElementId={value?.typeElementId || null}
                    onChange={onTypeElementIdChange}
                />
            </ConfigSection>
        </>
    );
};

export default DataSource2Config;
