import type { ChangeEvent } from 'react';
import type { NavigationElement } from '../utils';

type Props = {
    navigationElement: NavigationElement;
    setNavigationElement: (navigationElement: NavigationElement) => void;
};

const ConfigureState = ({ navigationElement, setNavigationElement }: Props) => {
    const handlePersistInputSelection = (e: ChangeEvent<HTMLInputElement>) => {
        setNavigationElement({
            ...navigationElement,
            persistState: e.target.checked,
        });
    };

    const handlePersistValuesSelection = (e: ChangeEvent<HTMLInputElement>) => {
        setNavigationElement({
            ...navigationElement,
            persistValues: e.target.checked,
        });
    };

    return (
        <div className="state-wrapper">
            <h3>State</h3>
            <label>
                Persist input/selection data on navigation
                <input
                    id="input-state-checkbox"
                    className="state-checkbox"
                    type="checkbox"
                    checked={navigationElement.persistState}
                    onChange={handlePersistInputSelection}
                />
            </label>
            <span className="help-block">
                If unchecked, navigating away from this page will clear any current user input or
                selections
            </span>
            <label>
                Persist values on navigation
                <input
                    id="value-state-checkbox"
                    className="state-checkbox"
                    type="checkbox"
                    checked={navigationElement.persistValues}
                    onChange={handlePersistValuesSelection}
                />
            </label>
            <span className="help-block">
                If unchecked, navigating away from this page will clear any values
            </span>
        </div>
    );
};

export default ConfigureState;
