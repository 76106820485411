import type { DebugTabId, StateValue } from '../../../../types';
import { useDebug } from '../DebugProvider';

import CodeEditor from '../../../generic/CodeEditor';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { ExAccordion, ExAccordionItem, ExEmptyState, ExIcon } from '@boomi/exosphere';
import type { Ace } from 'ace-builds';

type MarkerType = 'text' | 'fullLine' | 'screenLine' | Ace.MarkerRenderer;
type ValueType = 'valueElementId' | 'typeElementPropertyId';
type Marker = { type: ValueType; id: string; line: number }[];
type MarkerLine = { [key: string]: Marker };

const StateValues = (_: { screen?: DebugTabId }) => {
    const { callStack: callStackItems } = useDebug();

    const [hasStateValuesChanged, setHasStateValuesChanged] = useState(false);

    const selectedCallStackItem = callStackItems.length
        ? callStackItems.find((callStackItem) => callStackItem.isSelected)
        : null;

    const stateValues = selectedCallStackItem ? selectedCallStackItem.stateValues : null;
    const breakpointsHit = selectedCallStackItem ? selectedCallStackItem.breakpointsHit : null;

    const getContentValueLineIndex = (lines: string[][], lineIndex: number) => {
        for (let i = 0; lines.length; i++) {
            if (lines[i][0].includes('contentValue')) {
                return i + lineIndex;
            }
        }

        return lineIndex;
    };

    // This is used to determine which line in the code editer should
    // have a marker applied to indicate a breakpoint has been hit.
    const breakLines: MarkerLine = {};
    stateValues?.forEach((sv) => {
        // Split by line break, so we get an array of "lines"
        const lines = JSON.stringify(sv, null, '\t').split(/\r?\n|\r|\n/g);
        const linesAsKeyValuePairs = lines.map((line) => line.split(':'));
        breakLines[sv.valueElementId] = [] as Marker;
        linesAsKeyValuePairs.forEach((line, index) => {
            if (line[0].includes('valueElementId')) {
                breakLines[sv.valueElementId].push({
                    type: 'valueElementId',
                    id: line[1].replace(/"|,/g, '').trim(),
                    line: getContentValueLineIndex(linesAsKeyValuePairs.slice(index), index),
                });
            }

            if (line[0].includes('typeElementPropertyId')) {
                breakLines[sv.valueElementId].push({
                    type: 'typeElementPropertyId',
                    id: line[1].replace(/"|,/g, '').trim(),
                    line: getContentValueLineIndex(linesAsKeyValuePairs.slice(index), index),
                });
            }
        });
    });

    const generateMarkers = (stateValue: StateValue) => {
        const breakpointsHitForValue = breakpointsHit?.filter(
            (b) => b.value.id === stateValue.valueElementId,
        );

        const breakLinesForValue = breakLines[stateValue.valueElementId];

        return breakpointsHitForValue?.map((hit) => {
            const marker = breakLinesForValue.find((breakLineForValue) =>
                hit.value.typeElementPropertyId
                    ? hit.value.typeElementPropertyId === breakLineForValue.id
                    : hit.value.id === breakLineForValue.id,
            );

            return {
                startRow: marker?.line as number,
                type: 'fullLine' as MarkerType,
                className: 'breakpoint-marker',
                startCol: 0,
                endRow: 0,
                endCol: 0,
            };
        });
    };

    const classes = classNames('full-height', 'flex', 'align-center', 'call-stack-outer', {
        'has-changed': hasStateValuesChanged,
    });

    useEffect(() => {
        if (selectedCallStackItem?.id) {
            setHasStateValuesChanged(true);
            setTimeout(() => {
                setHasStateValuesChanged(false);
            }, 300);
        }
    }, [selectedCallStackItem?.id]);

    return (
        <div data-testid="state-values" className={classes}>
            {stateValues && selectedCallStackItem ? (
                <div className="debug-tabs-inner padding">
                    <ExAccordion>
                        {stateValues.map((stateValue) => {
                            return (
                                <ExAccordionItem
                                    key={`${stateValue.valueElementId}-${selectedCallStackItem.id}`}
                                    label={stateValue.developerName}
                                >
                                    <CodeEditor
                                        maxLines={Number.POSITIVE_INFINITY}
                                        readOnly
                                        wrapEnabled={false}
                                        mode="json"
                                        value={JSON.stringify(stateValue, null, '\t')}
                                        markers={generateMarkers(stateValue)}
                                    />
                                </ExAccordionItem>
                            );
                        })}
                    </ExAccordion>
                </div>
            ) : (
                <div className="center">
                    <ExEmptyState
                        label="There are no state values to display"
                        text="Select a call stack item to view state values."
                    >
                        <ExIcon icon="values-flow" slot="header" />
                    </ExEmptyState>
                </div>
            )}
        </div>
    );
};

export default StateValues;
