import { useState, createContext, useContext } from 'react';
import screens, { type Screen } from '../common/screens';

import { useMapElement } from './MapElementProvider';
import { isNullOrEmpty } from '../../../../utils/guard';
import type { Input, MessageAction, Output, ValueElementIdAPI } from '../../../../types';

type Props = {
    defaultScreen: Screen;
    children: React.ReactNode;
};

type ContextValue = {
    messageActionToEdit: {
        messageAction: MessageAction | null;
        index: number | null;
        isEditing: boolean;
    };
    onCreateMessageAction: () => void;
    onEditMessageAction: (messageAction: MessageAction, index: number) => void;
    onDeleteMessageAction: (index: number) => void;
    onChangeName: (developerName: string) => void;
    onChangeOrder: (order: number) => void;
    onChangeDisabledFlag: (disabled: boolean) => void;
    onChangeService: (serviceElementId: string, serviceElementDeveloperName: string) => void;
    selectMessageAction: (messageAction: {
        developerName: string;
        uriPart: string;
        serviceInputs: Input[];
        serviceOutputs: Output[];
    }) => void;
    setInputValue: (value: ValueElementIdAPI | null, item: Input) => void;
    setOutputValue: (value: ValueElementIdAPI | null, item: Output) => void;
    onApplyMessageAction: (index: number) => void;
    onReturnToDefaultScreen: () => void;
    setInputsAndOutputs: (params: { inputs: Input[]; outputs: Output[] }) => void;
};

const Context = createContext<ContextValue | undefined>(undefined);

const MessageActionProvider = ({ defaultScreen, children }: Props) => {
    const { mapElement, setMapElement, onSwitchScreen } = useMapElement();

    const initialMessageAction: MessageAction = {
        attributes: null,
        developerName: null,
        disabled: false,
        inputs: null,
        order: 0,
        outputs: null,
        serviceActionName: null,
        serviceElementDeveloperName: null,
        serviceElementId: null,
        selectedOutcomeId: null,
        uriPart: null,
    };

    const [messageActionToEdit, setMessageActionToEdit] = useState<{
        messageAction: MessageAction | null;
        index: number | null;
        isEditing: boolean;
    }>({
        messageAction: null,
        index: null,
        isEditing: false,
    });

    const onCreateMessageAction = () => {
        const totalMessageActions = mapElement.messageActions?.length ?? 0;
        setMessageActionToEdit((state) => ({
            ...state,
            messageAction: { ...initialMessageAction, order: totalMessageActions },
            index: totalMessageActions + 1,
        }));
        onSwitchScreen(screens.messageActions);
    };

    const onEditMessageAction = (messageAction: MessageAction, index: number) => {
        setMessageActionToEdit({ messageAction, index, isEditing: true });
        onSwitchScreen(screens.messageActions);
    };

    const onDeleteMessageAction = (index: number) => {
        setMapElement({
            ...mapElement,
            messageActions: (mapElement.messageActions ?? []).filter((_, i) => i !== index),
        });
    };

    const onChangeName = (developerName: string) => {
        setMessageActionToEdit((state) => ({
            ...state,
            messageAction: messageActionToEdit.messageAction
                ? { ...messageActionToEdit.messageAction, developerName }
                : null,
        }));
    };

    const onChangeOrder = (order: number) => {
        setMessageActionToEdit((state) => ({
            ...state,
            messageAction: messageActionToEdit.messageAction
                ? { ...messageActionToEdit.messageAction, order }
                : null,
        }));
    };

    const onChangeDisabledFlag = (disabled: boolean) => {
        setMessageActionToEdit((state) => ({
            ...state,
            messageAction: messageActionToEdit.messageAction
                ? { ...messageActionToEdit.messageAction, disabled }
                : null,
        }));
    };

    const onChangeService = (serviceElementId: string, serviceElementDeveloperName: string) => {
        setMessageActionToEdit((state) => ({
            ...state,
            messageAction: messageActionToEdit.messageAction
                ? {
                      ...messageActionToEdit.messageAction,
                      serviceElementId,
                      serviceElementDeveloperName,
                  }
                : null,
        }));
    };

    const selectMessageAction = (messageAction: {
        developerName: string;
        uriPart: string;
        serviceInputs: Input[];
        serviceOutputs: Output[];
    }) => {
        const { developerName, uriPart, serviceInputs, serviceOutputs } = messageAction;
        setMessageActionToEdit((state) => ({
            ...state,
            messageAction: messageActionToEdit.messageAction
                ? {
                      ...messageActionToEdit.messageAction,
                      inputs: serviceInputs,
                      outputs: serviceOutputs,
                      developerName,
                      uriPart,
                  }
                : null,
        }));
    };

    const updateValueElementReferences = <T extends Input | Output>(
        listName: string,
        inputOutputList: T[],
        value: ValueElementIdAPI | null,
        item: T,
    ): T[] => {
        if (listName !== 'inputs' && listName !== 'outputs') {
            return inputOutputList;
        }

        const propertyName =
            listName === 'outputs' ? 'valueElementToApplyId' : 'valueElementToReferenceId';

        return inputOutputList.map((ioConfig): T => {
            if (ioConfig.developerName !== item.developerName) {
                return ioConfig;
            }

            if (isNullOrEmpty(value)) {
                return {
                    ...ioConfig,
                    [propertyName]: null,
                    valueElementToReferenceDeveloperName: '',
                };
            }

            return {
                ...ioConfig,
                [propertyName]: {
                    id: value.id,
                    typeElementPropertyId: value.typeElementPropertyId,
                },
            };
        });
    };

    const setInputValue = (value: ValueElementIdAPI | null, item: Input) => {
        const updatedReferences = updateValueElementReferences<Input>(
            'inputs',
            messageActionToEdit.messageAction?.inputs ?? [],
            value,
            item,
        );

        setMessageActionToEdit((state) => ({
            ...state,
            messageAction: messageActionToEdit.messageAction
                ? {
                      ...messageActionToEdit.messageAction,
                      inputs: updatedReferences,
                  }
                : null,
        }));
    };

    const setOutputValue = (value: ValueElementIdAPI | null, item: Output) => {
        const updatedReferences = updateValueElementReferences<Output>(
            'outputs',
            messageActionToEdit.messageAction?.outputs ?? [],
            value,
            item,
        );

        setMessageActionToEdit((state) => ({
            ...state,
            messageAction: messageActionToEdit.messageAction
                ? {
                      ...messageActionToEdit.messageAction,
                      outputs: updatedReferences,
                  }
                : null,
        }));
    };

    const setInputsAndOutputs = ({ inputs, outputs }: { inputs: Input[]; outputs: Output[] }) =>
        setMessageActionToEdit((state) => ({
            ...state,
            messageAction: messageActionToEdit.messageAction
                ? {
                      ...messageActionToEdit.messageAction,
                      inputs,
                      outputs,
                  }
                : null,
        }));

    const onApplyMessageAction = (index: number) => {
        const messageActionExists = mapElement.messageActions
            ? mapElement.messageActions.find((_, i) => i === index)
            : null;
        const messageActions = messageActionExists
            ? (mapElement.messageActions ?? []).map((existingMessageAction, i) =>
                  i === index ? messageActionToEdit.messageAction : existingMessageAction,
              )
            : [...(mapElement.messageActions ?? []), messageActionToEdit.messageAction];

        setMapElement({ ...mapElement, messageActions: messageActions as MessageAction[] });
        onSwitchScreen(defaultScreen);
    };

    const onReturnToDefaultScreen = () => {
        onSwitchScreen(defaultScreen);
    };

    const contextValue: ContextValue = {
        messageActionToEdit,
        onCreateMessageAction,
        onEditMessageAction,
        onDeleteMessageAction,
        onChangeName,
        onChangeOrder,
        onChangeDisabledFlag,
        onChangeService,
        selectMessageAction,
        setInputValue,
        setOutputValue,
        onApplyMessageAction,
        onReturnToDefaultScreen,
        setInputsAndOutputs,
    };

    return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

const useMessageAction = () => {
    const context = useContext(Context);
    if (context === undefined) {
        throw new Error('useMessageAction must be used within a MessageActionProvider');
    }
    return context;
};

export { MessageActionProvider, useMessageAction };
