import type { Filter, ItemCollectionResponse } from '../types';
import type {
    ThemeAPI,
    ThemeBuildRequestAPI,
    ThemeEdit,
    ThemeSnapshotInfoAPI,
} from '../types/theme';
import { downloadFile, fetchAndParse, filterToQuery } from '../utils/ajax';
import { getTenantId } from '../utils/tenant';
import type { ThemePublishRequest } from './build';

export const saveTheme = (request: ThemeEdit) =>
    fetchAndParse<ThemeAPI>({
        url: '/api/draw/1/element/theme',
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ManyWhoTenant: getTenantId(),
        },
        body: request,
    });

export const exportTheme = (themeId: string, themeName: string) =>
    downloadFile(`/api/draw/1/element/theme/${themeId}/export`, themeName, getTenantId());

export const getThemeList = (filters?: Filter | null) => {
    const query = filters ? filterToQuery(filters) : '';

    return fetchAndParse<ItemCollectionResponse<ThemeAPI>>({
        url: `/api/draw/1/element/theme${query}`,
        method: 'GET',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });
};

export const deleteTheme = ({ themeId }: { themeId: string }) => {
    return fetchAndParse<void>({
        url: `/api/draw/1/element/theme/${themeId}`,
        method: 'DELETE',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });
};

/**
 * @description Fetches all themes within the current tenant.
 * Returns an array of text/html where each entry is a theme within the specified tenant.
 */
export const getAllThemeNames = async () => {
    const tenantId = getTenantId();

    const response = await fetchAndParse<string[]>({
        url: '/api/draw/1/element/theme/released',
        headers: {
            ManyWhoTenant: tenantId,
        },
    });

    return response;
};

/**
 * @description Fetches all themes within the current tenant for a given environment
 * Returns an array of text/html where each entry is a theme within the specified tenant.
 */
export const getAllThemeNamesForEnvironment = async (environmentId: string) => {
    const tenantId = getTenantId();

    const response = await fetchAndParse<string[]>({
        url: `/api/draw/1/element/theme/released?environment-id=${environmentId}`,
        headers: {
            ManyWhoTenant: tenantId,
        },
    });

    return response;
};

/**
 * @description Snapshot the given theme, add it to a new or existing release and deploy it automatically to the development environment
 */
export const buildTheme = (request: ThemeBuildRequestAPI) =>
    fetchAndParse<void>({
        url: '/api/draw/1/element/theme/build',
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ManyWhoTenant: getTenantId(),
        },
        body: request,
    });

/**
 * @description Snapshot the given theme, and send it down to a MCR node
 */
export const publishTheme = (request: ThemePublishRequest) =>
    fetchAndParse<void>({
        url: '/api/draw/1/element/theme/publish',
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ManyWhoTenant: getTenantId(),
        },
        body: request,
    });

/**
 * @description Exports a given theme by name
 * Returns a json serialized representation of the theme
 */
export const exportThemeConfig = (themeName: string) => {
    return downloadFile(`/api/package/1/theme/${themeName}`, `${themeName}.thme`, getTenantId());
};

/**
 * @description Imports a theme from a previously exported package
 */
export const importThemeConfig = async (importedTheme: string) => {
    return await fetchAndParse<void>({
        url: '/api/package/1/theme',
        method: 'POST',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
        body: importedTheme,
    });
};

/**
 * @description Gets all available released theme snapshot names with created dates
 */
export const getThemeSnapshots = async () => {
    const tenantId = getTenantId();

    const response = await fetchAndParse<ThemeSnapshotInfoAPI[]>({
        url: '/api/draw/1/element/theme/snapshots',
        headers: {
            ManyWhoTenant: tenantId,
        },
    });

    return response;
};

/**
 * @description deletes all snapshots with the given theme name in this tenant.
 */
export const deleteSnapshot = ({ themeName }: { themeName: string }) => {
    return fetchAndParse<void>({
        url: `/api/draw/1/element/theme/snapshots/${themeName}`,
        method: 'DELETE',
        headers: {
            ManyWhoTenant: getTenantId(),
        },
    });
};
