import type { CustomIconProps } from '../../types';

const GroupIcon = ({ size = '1em', ...otherProps }: CustomIconProps) => (
    // biome-ignore lint/a11y/noSvgWithoutTitle: Including a title breaks tests, requires dedicated refactor
    <svg
        width={size}
        height={size}
        viewBox="0 0 23 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...otherProps}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.31 0.69H20.24L20.24 2.76H22.31V0.69ZM20.24 0C19.8589 0 19.55 0.308924 19.55 0.69V2.76C19.55 3.14108 19.8589 3.45 20.24 3.45H22.31C22.6911 3.45 23 3.14108 23 2.76V0.69C23 0.308924 22.6911 0 22.31 0H20.24Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.76 0.69H0.69L0.69 2.76H2.76V0.69ZM0.69 0C0.308924 0 0 0.308924 0 0.69V2.76C0 3.14108 0.308924 3.45 0.69 3.45H2.76C3.14108 3.45 3.45 3.14108 3.45 2.76V0.69C3.45 0.308924 3.14108 0 2.76 0H0.69Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.31 16.79H20.24L20.24 18.86H22.31V16.79ZM20.24 16.1C19.8589 16.1 19.55 16.4089 19.55 16.79V18.86C19.55 19.2411 19.8589 19.55 20.24 19.55H22.31C22.6911 19.55 23 19.2411 23 18.86V16.79C23 16.4089 22.6911 16.1 22.31 16.1H20.24Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.76 16.79H0.69L0.69 18.86H2.76V16.79ZM0.69 16.1C0.308924 16.1 0 16.4089 0 16.79V18.86C0 19.2411 0.308924 19.55 0.69 19.55H2.76C3.14108 19.55 3.45 19.2411 3.45 18.86V16.79C3.45 16.4089 3.14108 16.1 2.76 16.1H0.69Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.55 2.3H3.45V0.574997H19.55V2.3Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.55 18.975H3.45V17.25H19.55V18.975Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.7 16.1L20.7 3.45L22.425 3.45L22.425 16.1L20.7 16.1Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.575002 16.1L0.575003 3.45L2.3 3.45L2.3 16.1L0.575002 16.1Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.1 11.9C4.60294 11.9 4.2 11.4971 4.2 11V5.10001C4.2 4.60296 4.60294 4.20001 5.1 4.20001H12.15C12.6471 4.20001 13.05 4.60296 13.05 5.10001V8.11H17.21C17.7071 8.11 18.11 8.51295 18.11 9.01V14.91C18.11 15.4071 17.7071 15.81 17.21 15.81H10.16C9.66294 15.81 9.26 15.4071 9.26 14.91V11.9H5.1ZM10.06 11.9V14.91C10.06 14.9652 10.1048 15.01 10.16 15.01H17.21C17.2652 15.01 17.31 14.9652 17.31 14.91V9.01C17.31 8.95477 17.2652 8.91 17.21 8.91H13.05V11C13.05 11.4971 12.6471 11.9 12.15 11.9H10.06ZM12.25 8.91V11C12.25 11.0552 12.2052 11.1 12.15 11.1H10.06V9.01C10.06 8.95477 10.1048 8.91 10.16 8.91H12.25ZM12.25 8.11H10.16C9.66294 8.11 9.26 8.51295 9.26 9.01V11.1H5.1C5.04477 11.1 5 11.0552 5 11V5.10001C5 5.04478 5.04477 5.00001 5.1 5.00001H12.15C12.2052 5.00001 12.25 5.04478 12.25 5.10001V8.11Z"
            fill="currentColor"
        />
    </svg>
);

export default GroupIcon;
