import { useState } from 'react';
import ButtonDefault from '../../../buttons/ButtonDefault';
import ButtonPrimary from '../../../buttons/ButtonPrimary';
import FormGroup from '../../../generic/FormGroup';
import Glyphicon from '../../../generic/Glyphicon';
import NameInput from '../common/NameInput';
import NavigationOverrideList from '../common/navigationOverrides/NavigationOverrideList';
import screens from '../common/screens';
import { useMapElement } from '../contextProviders/MapElementProvider';
import OperationsList from './OperationsList';
import { useOperator } from './OperatorProvider';
import ModalBody from '../../../generic/modal/ModalBody';
import ModalFooter from '../../../generic/modal/ModalFooter';
import type { MapElementRequestAPI } from '../../../../sources/graph';

/**
 * The main operator config screen
 */
const OperatorConfiguration = () => {
    const {
        mapElement,
        onSaveMapElement,
        onClose,
        onUpdateName,
        onSwitchScreen,
        onUpdateComments,
    } = useMapElement();

    const { setSelectedOperationIndex } = useOperator();

    const [hasSubmitted, setHasSubmitted] = useState(false);
    const isValid = mapElement.developerName !== '';

    const onAddOperation = () => {
        setSelectedOperationIndex(null);
        onSwitchScreen(screens.operationDetails);
    };

    const onAddMacro = () => {
        setSelectedOperationIndex(null);
        onSwitchScreen(screens.macroDetails);
    };

    const onSave = () => {
        setHasSubmitted(true);
        if (isValid) {
            onSaveMapElement({
                ...mapElement,
                // set order prop to index in list for new operations
                operations: (mapElement.operations ?? []).map((o, i) => ({ ...o, order: i })),
            } as MapElementRequestAPI);
        }
    };

    const renderBody = () => (
        <>
            <NameInput
                isValid={isValid}
                showValidation={hasSubmitted}
                id="operator-name"
                name={mapElement.developerName ?? ''}
                onUpdateName={onUpdateName}
            />
            <h4>Operations</h4>
            <div className="margin-bottom flex">
                <ButtonPrimary onClick={onAddOperation}>
                    <Glyphicon glyph="plus" />
                    Add Operation
                </ButtonPrimary>

                <ButtonPrimary onClick={onAddMacro}>
                    <Glyphicon glyph="plus" />
                    Add Macro
                </ButtonPrimary>
            </div>
            <OperationsList />
            <NavigationOverrideList />
            <FormGroup label="Comments about this Operator" htmlFor="comments">
                <textarea
                    id="comments"
                    data-testid="comments"
                    className="form-control form-textarea"
                    value={mapElement.developerSummary ?? ''}
                    onChange={({ target: { value } }) => onUpdateComments(value)}
                />
            </FormGroup>
        </>
    );

    return (
        <>
            <ModalBody>{renderBody()}</ModalBody>
            <ModalFooter>
                <ButtonDefault className="flex-child-right" onClick={onClose}>
                    Cancel
                </ButtonDefault>
                <ButtonPrimary className="margin-left" onClick={onSave}>
                    Save
                </ButtonPrimary>
            </ModalFooter>
        </>
    );
};

export default OperatorConfiguration;
