import type { TabType } from '../types';

export const TAB_TYPES: Record<TabType, TabType> = {
    userSettings: 'userSettings',
    flows: 'flows',
    flow: 'flow',
    pages: 'pages',
    page: 'page',
    newPage: 'newPage',
    environments: 'environments',
    environment: 'environment',
    values: 'values',
    services: 'services',
    types: 'types',
    identityProviders: 'identityProviders',
    assets: 'assets',
    api: 'api',
    importExport: 'importExport',
    themes: 'themes',
    players: 'players',
    macros: 'macros',
    dashboard: 'dashboard',
    auditing: 'auditing',
    tenant: 'tenant',
    organization: 'organization',
    features: 'features',
    components: 'components',
};

export const ALWAYS_OPEN_NEW_TAB: string[] = [
    TAB_TYPES.api,
    TAB_TYPES.players,
    TAB_TYPES.macros,
    TAB_TYPES.newPage,
    TAB_TYPES.environment,
];
