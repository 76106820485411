import Table from '../../../generic/Table';
import translations from '../../../../translations';
import type {
    DefinedProcessPropertyAPI,
    ComponentMetadataAPI,
    ProcessPropertiesMetadataAPI,
    ProcessProperties,
} from '../../../../types/integration';
import type { ValueElementIdAPI } from '../../../../types';
import { assocPath } from 'ramda';
import { useProcess } from '../contextProviders/ProcessProvider';
import { useEffect, useState } from 'react';
import { getAllProcessProperties, getProcessProperties } from '../../../../sources/integration';
import ButtonPrimary from '../../../buttons/ButtonPrimary';
import ButtonDefault from '../../../buttons/ButtonDefault';
import { useMapElement } from '../contextProviders/MapElementProvider';
import FormGroup from '../../../generic/FormGroup';
import Select, { type SingleValue } from 'react-select';
import { Check, X } from '@phosphor-icons/react';
import { AlertBannerType, ExAlertBanner } from '@boomi/exosphere';
import { isNullOrEmpty } from '../../../../utils/guard';
import { stringReplace } from '../../../../utils/string';
import ModalBody from '../../../generic/modal/ModalBody';
import ModalFooter from '../../../generic/modal/ModalFooter';
import ValueSelectorModal from '../../../values/selector/ValueSelectorModal';
import { getSharedStyles } from '../../../../utils';

interface Option {
    value: string;
    label: string;
}

type ProcessPropertiesDataTypes = Record<string, string>;

const processPropertiesDataTypes = {
    string: translations.PROCESS_process_properties_data_type_string,
    password: translations.PROCESS_process_properties_data_type_password,
    number: translations.PROCESS_process_properties_data_type_number,
    boolean: translations.PROCESS_process_properties_data_type_boolean,
    date: translations.PROCESS_process_properties_data_type_date,
};

const ProcessPropertiesDetails = () => {
    const { onSwitchScreen, container } = useMapElement();

    const {
        processToEdit,
        processPropertiesToEdit,
        onProcessPropertiesChange,
        onApplyProcessProperties,
    } = useProcess();

    const [availableProcessProperties, setAvailableProcessProperties] = useState<
        ComponentMetadataAPI[]
    >([]);

    const [selectedProcessPropertiesId, setSelectedProcessPropertiesId] = useState<string>(
        processPropertiesToEdit.processProperties?.componentId || '',
    );

    const [selectedProcessProperties, setSelectedProcessProperties] =
        useState<ProcessPropertiesMetadataAPI | null>(null);

    const [menuPortalTarget, setMenuPortalTarget] = useState<HTMLElement | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const isProcessPropertiesValid = !isNullOrEmpty(selectedProcessPropertiesId);

    useEffect(() => {
        // The modal appears after the DOM is loaded so we need to wait in order to set a menuPortalTarget
        requestAnimationFrame(() => {
            setMenuPortalTarget(document.querySelector('.process-modal') as HTMLElement);
        });
    }, []);

    // biome-ignore lint/correctness/useExhaustiveDependencies: Treat warnings as errors, fix later
    useEffect(() => {
        const loadAll = async () => {
            try {
                setAvailableProcessProperties(
                    await getAllProcessProperties(processToEdit.process.accountId),
                );
            } catch (error) {
                setError((error as Error).message);
            }
        };

        const load = async () => {
            try {
                setSelectedProcessProperties(
                    await getProcessProperties(
                        processToEdit.process.accountId,
                        selectedProcessPropertiesId,
                    ),
                );
            } catch (error) {
                setError((error as Error).message);
            }
        };

        processPropertiesToEdit.processProperties ? load() : loadAll();
    }, []);

    const onSelectProcessProperties = async (value: SingleValue<Option> | undefined) => {
        setSelectedProcessPropertiesId(value?.value || '');

        if (value?.value) {
            setSelectedProcessProperties(
                await getProcessProperties(processToEdit.process.accountId, value?.value),
            );
            onProcessPropertiesChange({
                componentId: value?.value || '',
                processPropertyValue: {},
            });
        } else {
            setSelectedProcessProperties(null);
        }
    };

    const onSetPropertyValue = (propertyValue: ValueElementIdAPI | null, key: string) => {
        onProcessPropertiesChange(
            assocPath(
                ['processPropertyValue', key],
                propertyValue,
                processPropertiesToEdit.processProperties,
            ) as ProcessProperties,
        );
    };

    const onSave = () => {
        setHasSubmitted(true);

        if (isProcessPropertiesValid) {
            onApplyProcessProperties();
        }
    };

    const columns = [
        {
            renderHeader: () => translations.PROCESS_process_property_column_label,
            renderCell: ({ item }: { item: DefinedProcessPropertyAPI }) => item.label,
            size: '15rem',
        },
        {
            renderHeader: () => translations.PROCESS_process_property_column_help_text,
            renderCell: ({ item }: { item: DefinedProcessPropertyAPI }) => item.helpText,
            size: '12rem',
        },
        {
            renderHeader: () => translations.PROCESS_process_property_column_type,
            renderCell: ({ item }: { item: DefinedProcessPropertyAPI }) =>
                (processPropertiesDataTypes as ProcessPropertiesDataTypes)[item.type],
            size: '6rem',
        },
        {
            renderHeader: () => translations.PROCESS_process_property_column_persisted,
            renderCell: ({ item }: { item: DefinedProcessPropertyAPI }) =>
                item.persisted ? <Check /> : <X />,
            size: '6rem',
            cellClassName: 'text-center',
        },
        {
            renderHeader: () => translations.PROCESS_dynamic_process_property_column_value,
            renderCell: ({ item }: { item: DefinedProcessPropertyAPI }) => {
                const processPropertiesValue =
                    processPropertiesToEdit.processProperties?.processPropertyValue?.[item.key] ||
                    null;

                return (
                    <ValueSelectorModal
                        includeSystemValues={true}
                        value={processPropertiesValue}
                        onChange={(value: ValueElementIdAPI | null) =>
                            onSetPropertyValue(value, item.key)
                        }
                        container={container}
                    />
                );
            },
        },
    ];

    const processPropertiesOptions = [
        {
            value: '',
            label: translations.PROCESS_process_properties_placeholder,
        },
        ...availableProcessProperties.map((processProperties) => ({
            value: processProperties.id,
            label: processProperties.name,
        })),
    ];

    const selectedProcessPropertiesOption = processPropertiesOptions.find(
        (processProperties) => processProperties.value === selectedProcessPropertiesId,
    );

    const renderBody = () => (
        <>
            <h4>{translations.PROCESS_process_properties_heading}</h4>
            {selectedProcessProperties ? (
                <h5>{selectedProcessProperties.name}</h5>
            ) : (
                <FormGroup
                    label={translations.PROCESS_process_properties_label}
                    isRequired={true}
                    isValid={isProcessPropertiesValid}
                    validationMessage={translations.PROCESS_process_properties_required}
                    showValidation={hasSubmitted}
                    htmlFor="processproperties"
                >
                    <Select
                        styles={getSharedStyles<
                            (typeof processPropertiesOptions)[number] | undefined
                        >()}
                        inputId="processproperties"
                        className="margin-bottom-small"
                        options={processPropertiesOptions}
                        value={selectedProcessPropertiesOption}
                        onChange={onSelectProcessProperties}
                        menuPosition="fixed"
                        menuPortalTarget={menuPortalTarget}
                    />
                </FormGroup>
            )}
            {selectedProcessProperties ? (
                <Table<DefinedProcessPropertyAPI>
                    columns={columns}
                    tableClass="generic-table margin-top margin-bottom"
                    items={Object.values(selectedProcessProperties.properties)}
                    testId="process-properties"
                />
            ) : null}
            {error ? (
                <ExAlertBanner open={true} type={AlertBannerType.ERROR}>
                    {stringReplace(translations.PROCESS_process_properties_names_error, error)}
                </ExAlertBanner>
            ) : null}
        </>
    );

    const renderFooter = () => (
        <>
            <ButtonDefault
                className="flex-child-right"
                onClick={() => onSwitchScreen('processDetails')}
            >
                Cancel
            </ButtonDefault>
            <ButtonPrimary className="margin-left" onClick={onSave}>
                {processPropertiesToEdit.isEditing
                    ? translations.GRAPH_config_panel_save
                    : translations.GRAPH_config_panel_add}
            </ButtonPrimary>
        </>
    );

    return (
        <>
            <ModalBody>{renderBody()}</ModalBody>
            <ModalFooter>{renderFooter()}</ModalFooter>
        </>
    );
};

export default ProcessPropertiesDetails;
