import type { ReactNode } from 'react';
import { SYSTEM_ROLES } from '../../constants';
import translations from '../../translations';
import { useAuth } from '../AuthProvider';
import DropdownList from './DropdownList';
import TenantDropdown from './TenantDropdown';
import DropdownButton from './headerDropdown/DropdownButton';
import DropdownListButton from './headerDropdown/DropdownListButton';
import DropdownListLink from './headerDropdown/DropdownListLink';
import { useFeatureFlag } from '../featureFlags/FeatureFlagProvider';

const appLinks = {
    integration: 'https://platform.boomi.com/AtomSphere.html',
    dataHub: 'https://platform.boomi.com/MdmSphere.html',
    apiManagement: 'https://platform.boomi.com/ApiSphere.html',
    taskAutomation: 'https://taskautomation.boomi.com',
    eventStreams: 'https://platform.boomi.com/EventStreams.html',
} as const;

const helpLinks = {
    docs: 'https://help.boomi.com/category/flow',
    support: 'https://community.boomi.com/s/support',
    community: 'https://community.boomi.com/s/topic/0TO1W000000cffIWAQ/flow',
    training: 'https://train.boomi.com/',
    discover: 'https://discover.boomi.com/browse-solutions?category=flow',
    billing: 'https://billing.stripe.com/p/login/aEU6s02oi0Gw7IIcMM',
} as const;

const labsLinks = {
    spaces: 'https://boomi.com/boomi-labs/#spaces',
    bundles: 'https://platform.boomi.com/BoomiLabs.html#pub_bundles',
    packs: 'https://platform.boomi.com/BoomiLabs.html#pub_ipacks',
    processLib: 'https://platform.boomi.com/BoomiLabs.html#pub_pl',
    connectors: 'https://platform.boomi.com/AtomSphere.html#settings',
} as const;

const settingsScreens = {
    orgs: 'organization',
    tenant: 'tenant',
    environments: 'environments',
    stores: 'data-stores',
} as const;

interface Link {
    text: string;
    summary: string;
    action:
        | (typeof appLinks)[keyof typeof appLinks]
        | (typeof helpLinks)[keyof typeof helpLinks]
        | (typeof labsLinks)[keyof typeof labsLinks];
}

interface HeaderNavSection {
    id: string;
    listClass: string;
    buttonContents: ReactNode;
    links: Link[];
    buttons: {
        text: string;
        summary: string;
        screen: (typeof settingsScreens)[keyof typeof settingsScreens];
        icon: string;
        visible: boolean;
    }[];
    icon: boolean;
}

interface HeaderNavProps {
    openTenantSelector: () => void;
}

const HeaderNav = ({ openTenantSelector }: HeaderNavProps) => {
    const { tenant } = useAuth();
    const { getFlag } = useFeatureFlag();

    if (!tenant) {
        return null;
    }

    const showEnvironmentMenuItem = !!(
        tenant.tenantSettings?.environments &&
        tenant.role === SYSTEM_ROLES.administrator.developerName
    );

    const servicesLinks: Link[] = [
        {
            text: translations.HEADER_integration_link_text,
            summary: translations.HEADER_integration_summary_text,
            action: appLinks.integration,
        },
        {
            text: translations.HEADER_datahub_link_text,
            summary: translations.HEADER_datahub_summary_text,
            action: appLinks.dataHub,
        },
        {
            text: translations.HEADER_apim_link_text,
            summary: translations.HEADER_apim_summary_text,
            action: appLinks.apiManagement,
        },
        {
            text: translations.HEADER_event_streams_link_text,
            summary: translations.HEADER_event_streams_summary_text,
            action: appLinks.eventStreams,
        },
    ];

    if (getFlag('AUTO')) {
        servicesLinks.push({
            text: translations.HEADER_auto_link_text,
            summary: translations.HEADER_auto_summary_text,
            action: appLinks.taskAutomation,
        });
    }

    const headerNavConfig: HeaderNavSection[] = [
        {
            id: 'boomi-services-list',
            listClass: 'boomi-services-list',
            buttonContents: <DropdownButton buttonText={translations.HEADER_services} />,
            links: servicesLinks,
            buttons: [],
            icon: true,
        },
        {
            id: 'help-dropdown-list',
            listClass: 'help-dropdown-list',
            buttonContents: <DropdownButton buttonText={translations.HEADER_resources} />,
            links: [
                {
                    text: translations.HEADER_docs_link_text,
                    summary: translations.HEADER_docs_summary_text,
                    action: helpLinks.docs,
                },
                {
                    text: translations.HEADER_support_link_text,
                    summary: translations.HEADER_support_summary_text,
                    action: helpLinks.support,
                },
                {
                    text: translations.HEADER_community_link_text,
                    summary: translations.HEADER_community_summary_text,
                    action: helpLinks.community,
                },
                {
                    text: translations.HEADER_training_link_text,
                    summary: translations.HEADER_training_summary_text,
                    action: helpLinks.training,
                },
                {
                    text: translations.HEADER_discover_link_text,
                    summary: translations.HEADER_discover_summary_text,
                    action: helpLinks.discover,
                },
                {
                    text: translations.HEADER_billing_link_text,
                    summary: translations.HEADER_billing_summary_text,
                    action: helpLinks.billing,
                },
            ],
            buttons: [],
            icon: true,
        },
        {
            id: 'labs-dropdown-list',
            listClass: 'labs-dropdown-list',
            buttonContents: <DropdownButton buttonText={translations.HEADER_labs} />,
            links: [
                {
                    text: translations.HEADER_spaces_link_text,
                    summary: translations.HEADER_spaces_summary_text,
                    action: helpLinks.support,
                },
                {
                    text: translations.HEADER_bundles_link_text,
                    summary: translations.HEADER_bundles_summary_text,
                    action: labsLinks.bundles,
                },
                {
                    text: translations.HEADER_packs_link_text,
                    summary: translations.HEADER_packs_summary_text,
                    action: labsLinks.packs,
                },
                {
                    text: translations.HEADER_process_lib_link_text,
                    summary: translations.HEADER_process_lib_summary_text,
                    action: labsLinks.processLib,
                },
                {
                    text: translations.HEADER_connectors_link_text,
                    summary: translations.HEADER_connectors_summary_text,
                    action: labsLinks.connectors,
                },
            ],
            buttons: [],
            icon: true,
        },
        {
            id: 'settings-dropdown-list',
            listClass: 'settings-dropdown-list',
            buttonContents: <DropdownButton buttonText={translations.HEADER_settings} />,
            links: [],
            buttons: [
                {
                    text: translations.HEADER_org_link_text,
                    summary: translations.HEADER_org_summary_text,
                    screen: settingsScreens.orgs,
                    icon: 'Organization',
                    visible: true,
                },
                {
                    text: translations.HEADER_tenant_link_text,
                    summary: translations.HEADER_tenant_summary_text,
                    screen: settingsScreens.tenant,
                    icon: 'Tenant',
                    visible: true,
                },
                {
                    text: translations.HEADER_environments_link_text,
                    summary: translations.HEADER_environments_summary_text,
                    screen: settingsScreens.environments,
                    icon: 'Environments',
                    visible: showEnvironmentMenuItem,
                },
            ],
            icon: false,
        },
    ];

    if (tenant.role !== SYSTEM_ROLES.administrator.developerName) {
        headerNavConfig.pop();
    }

    return (
        <nav className="tab-header-admin header-admin-left">
            {headerNavConfig.map(({ id, listClass, links, buttonContents, buttons, icon }) => (
                <DropdownList key={id} alignRight={false} buttonContents={buttonContents}>
                    <div className={`list-unstyled dropdown-list ${listClass}`}>
                        {links.map(({ text, summary, action }) => (
                            <DropdownListLink
                                key={text}
                                linkText={text}
                                linkSummary={summary}
                                linkHref={action}
                                hasIcon={icon}
                                rel="noopener noreferrer"
                                target="_blank"
                            />
                        ))}
                        {buttons.map(({ text, screen, summary, icon, visible }) => (
                            <DropdownListButton
                                key={text}
                                navigateTo={screen}
                                buttonText={text}
                                buttonSummary={summary}
                                icon={icon}
                                tenantId={tenant.id}
                                visible={visible}
                            />
                        ))}
                    </div>
                </DropdownList>
            ))}
            {tenant && <TenantDropdown tenant={tenant} openTenantSelector={openTenantSelector} />}
        </nav>
    );
};

export default HeaderNav;
