import { useState } from 'react';
import type {
    ValueElementIdAPI,
    ValueElementIdReferenceAPI,
    ValueElementResponseAPI,
} from '../../../types';
import GenericModal from '../../generic/modal/GenericModal';
import ValueSelectorValues from './ValueSelectorValues';
import { ButtonFlavor, ButtonType, ExButton } from '@boomi/exosphere';
import '../../../../../css/value-selector.less';
import translations from '../../../translations';
import ValueEditorModal from '../editor/ValueEditorModal';

type ViewState = 'SELECTING' | 'CREATE_NEW' | 'EDITING_FROM_VALUES' | 'ERROR';

interface Props {
    container: HTMLElement | null | undefined;
    onClose: () => void;
    onChange: (value: ValueElementIdReferenceAPI) => void;
}

const TinyMCEValueSelectorModal = ({ container, onClose, onChange }: Props) => {
    const [view, setView] = useState<ViewState>('SELECTING');
    const [valueToEdit, setValueToEdit] = useState<ValueElementIdAPI | null>(null);

    const onNewValueSave = (value: ValueElementResponseAPI) => {
        onChange({
            id: value.id,
            access: value.access,
            developerName: value.developerName,
            contentType: value.contentType,
            typeElementId: value.typeElementId,
            typeElementDeveloperName: value.typeElementDeveloperName,
            typeElementPropertyDeveloperName: null,
            typeElementPropertyId: null,
            typeElementPropertyTypeElementDeveloperName: null,
            typeElementPropertyTypeElementId: null,
            confidence: null,
            elementType: 'VARIABLE',
            serviceElementId: null,
            serviceElementDeveloperName: null,
            parentContentType: null,
            command: null,
        });
    };

    const onEditFromSelectingValue = (value: ValueElementIdReferenceAPI) => {
        setView('EDITING_FROM_VALUES');
        setValueToEdit(value);
    };

    const onEditingFromListClose = () => {
        setView('SELECTING');
        setValueToEdit(null);
    };

    let modal = null;

    switch (view) {
        case 'SELECTING': {
            modal = (
                <GenericModal
                    onHide={onClose}
                    className="value-selector-modal"
                    renderBody={() => {
                        return (
                            <ValueSelectorValues
                                includeSystemValues={true}
                                onChange={onChange}
                                onCreate={() => setView('CREATE_NEW')}
                                onEdit={onEditFromSelectingValue}
                            />
                        );
                    }}
                    renderFooter={() => {
                        return (
                            <ExButton
                                onClick={onClose}
                                flavor={ButtonFlavor.BRANDED}
                                type={ButtonType.SECONDARY}
                                className="value-selector-modal-cancel"
                            >
                                {translations.COMMON_cancel}
                            </ExButton>
                        );
                    }}
                    container={container}
                />
            );
            break;
        }

        case 'CREATE_NEW': {
            modal = (
                <ValueEditorModal
                    title={translations.VALUE_EDITOR_modal_title_new}
                    onCancel={() => setView('SELECTING')}
                    onSave={onNewValueSave}
                    container={container}
                />
            );
            break;
        }

        case 'EDITING_FROM_VALUES': {
            modal = (
                <ValueEditorModal
                    id={valueToEdit?.id}
                    title={translations.VALUE_EDITOR_modal_title_edit}
                    onCancel={onEditingFromListClose}
                    onSave={onEditingFromListClose}
                    container={container}
                />
            );
            break;
        }
    }

    return modal;
};

export default TinyMCEValueSelectorModal;
