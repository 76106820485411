import { useEffect, useState } from 'react';
import { exportFlowsForTranslation, getAllCultures, getDefaultCulture } from '../../sources/flow';
import translations from '../../translations';
import { useAuth } from '../AuthProvider';
import ButtonPrimary from '../buttons/ButtonPrimary';
import FormGroup from '../generic/FormGroup';
import type { Culture, CultureApi, TranslateFlowExportRequest } from '../../types/translation';
import Select from 'react-select';
import { getSharedStyles } from '../../utils/select';
import type { FlowResponseAPI } from '../../types';

interface Props {
    flows: FlowResponseAPI[];
    notifyError: (error: unknown) => void;
    setIsProcessing: (isProcessing: boolean) => void;
}

const TranslationExport = ({ flows, notifyError, setIsProcessing }: Props) => {
    const [selectedFlows, setSelectedFlows] = useState<string[]>([]);
    const [cultures, setCultures] = useState<Culture[]>([]);
    const [sourceLanguage, setSourceLanguage] = useState('');
    const [targetLanguage, setTargetLanguage] = useState('');

    const { tenant } = useAuth();

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        getAllCultures({ tenantId: tenant?.id ?? '' })
            .then((response: Culture[]) => setCultures(response))
            .catch(({ message }) => {
                notifyError(message);
            });
    }, [tenant]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (cultures.length > 0) {
            getDefaultCulture({ tenantId: tenant?.id ?? '' })
                .then((response: CultureApi) => {
                    const srcCulture = cultures.find(
                        (culture) => culture.code === response.language.toLowerCase(),
                    );

                    if (srcCulture) {
                        setSourceLanguage(`${srcCulture.name} | ${response.developerName}`);
                    }
                })
                .catch(({ message }) => {
                    notifyError(message);
                });
        }
    }, [cultures]);

    const getAllFlowOptions = () =>
        flows.map((flow) => ({ label: flow.developerName, value: flow.id.id }));

    const getAllLanguageOptions = () =>
        cultures.map((culture) => ({ label: culture.name, value: culture.code }));

    const exportFlow = async () => {
        try {
            if (!tenant?.id) {
                throw new Error('Tenant ID is missing');
            }

            if (selectedFlows && selectedFlows.length > 0 && targetLanguage) {
                setIsProcessing(true);

                const translateFlowExportRequest: TranslateFlowExportRequest = {
                    flowIds: selectedFlows,
                    targetLanguage,
                };
                await exportFlowsForTranslation({
                    tenantId: tenant.id,
                    translateFlowExportRequest,
                });

                setIsProcessing(false);
            }
        } catch (error) {
            notifyError((error as Error).message);
        } finally {
            setIsProcessing(false);
        }
    };

    const flowSelector = (
        <FormGroup label="Flow" htmlFor="translation-flow-select" isRequired>
            <Select
                inputId="translation-flow-select"
                className="select-flow"
                styles={getSharedStyles<ReturnType<typeof getAllFlowOptions>[number], true>()}
                options={getAllFlowOptions()}
                onChange={(selectedOptions) =>
                    setSelectedFlows(selectedOptions.map((option) => option.value))
                }
                placeholder={translations.COMMON_select_flow_placeholder}
                menuPlacement="auto"
                isMulti
                closeMenuOnSelect={false}
            />
        </FormGroup>
    );

    const languageInputs = (
        <>
            <FormGroup label="Default Culture" htmlFor="default-culture-display">
                <input
                    id="default-culture-display"
                    className="form-control source-language-display"
                    value={sourceLanguage}
                    readOnly
                />
            </FormGroup>
            <FormGroup label="Target Language" htmlFor="translation-target-select" isRequired>
                <Select
                    inputId="translation-target-select"
                    className="language-select"
                    styles={getSharedStyles<ReturnType<typeof getAllLanguageOptions>[number]>()}
                    options={getAllLanguageOptions()}
                    onChange={(selectedOption) => setTargetLanguage(selectedOption?.value ?? '')}
                    placeholder={translations.TRANSLATIONS_target_select_placeholder}
                    menuPlacement="auto"
                />
            </FormGroup>
        </>
    );

    return (
        <>
            <h4>{translations.TRANSLATIONS_export_header}</h4>
            {flowSelector}
            {languageInputs}
            <ButtonPrimary onClick={exportFlow} disabled={!(selectedFlows && targetLanguage)}>
                {translations.TRANSLATIONS_export_button}
            </ButtonPrimary>
        </>
    );
};

export default TranslationExport;
