import screens from '../../../../../components/flow/elementConfigurations/common/screens';
import {
    type MapElementConfigProps,
    MapElementProvider,
} from '../../../../../components/flow/elementConfigurations/contextProviders/MapElementProvider';
import { WaitProvider } from '../../../../../components/flow/elementConfigurations/contextProviders/WaitProvider';
import WaitConfiguration from './WaitConfiguration';
import MapElementModal from '../../../../../components/graph/MapElementModal';
import { MAP_ELEMENT_TYPES } from '../../../../../constants';

/**
 * The wait config screen
 */
const Wait = (props: MapElementConfigProps) => {
    return (
        <MapElementProvider
            {...props}
            defaultScreen={screens.wait}
            elementType={MAP_ELEMENT_TYPES.wait}
            isLoading={false}
        >
            <MapElementModal>
                <WaitProvider>
                    <WaitConfiguration />
                </WaitProvider>
            </MapElementModal>
        </MapElementProvider>
    );
};

export default Wait;
