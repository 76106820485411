import type { OrganizationInvite } from '../../types/organization';
import translations from '../../translations';
import { stringReplace } from '../../utils';
import { useAuth } from '../AuthProvider';
import Loader from '../loader/Loader';
import { useOrganizationInvite } from './OrganizationInviteProvider';
import ReceivedInvites from './ReceivedInvites';

const ReceivedUserInviteList = () => {
    const { user } = useAuth();

    const { receivedUserInvites, loadReceivedUserInvites, acceptUserInvite, rejectUserInvite } =
        useOrganizationInvite();

    const matchUserInvite = (invite: OrganizationInvite) => {
        if (!user?.id) {
            return false;
        }

        if (invite.type !== 'user') {
            return false;
        }

        if (invite.user.id !== user.id) {
            return false;
        }

        return true;
    };

    const handleAccept = async (id: string) => {
        await acceptUserInvite(id);
    };

    const handleReject = async (id: string) => {
        await rejectUserInvite(id);
    };

    if (!user) {
        return <Loader />;
    }

    return (
        <>
            <p className="description">{translations.FORG_user_invitations_description}</p>
            <ReceivedInvites
                caption={stringReplace(translations.FORG_received_user_invites_table_caption, {
                    userEmail: user.email,
                })}
                invitations={receivedUserInvites}
                matchInvite={matchUserInvite}
                loadInvites={loadReceivedUserInvites}
                onAccept={handleAccept}
                onReject={handleReject}
            />
        </>
    );
};

export default ReceivedUserInviteList;
