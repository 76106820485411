import DataActionConfiguration from '../common/dataActions/DataActionConfiguration';
import DataActionFilter from '../common/dataActions/filters/DataActionFilter';
import FilterCriteria from '../common/dataActions/filters/FilterCriteria';
import OutcomeDetails from '../common/OutcomeDetails';
import screens from '../common/screens';
import { useMapElement } from '../contextProviders/MapElementProvider';
import LoadConfiguration from './LoadConfiguration';
import ListenerDetails from '../common/listeners/ListenerDetails';

const Load = () => {
    const {
        currentScreen,
        mapElement,
        selectedOutcomeIndex,
        onReturnToDefaultScreen,
        setSelectedOutcomeIndex,
    } = useMapElement();

    let screen = null;

    switch (currentScreen) {
        case screens.load:
            screen = <LoadConfiguration />;
            break;
        case screens.dataAction:
            screen = <DataActionConfiguration />;
            break;
        case screens.filter:
            screen = <DataActionFilter />;
            break;
        case screens.criteria:
            screen = <FilterCriteria />;
            break;
        case screens.outcome: {
            const selectedOutcome =
                typeof selectedOutcomeIndex === 'number' &&
                mapElement?.outcomes?.[selectedOutcomeIndex]
                    ? mapElement?.outcomes?.[selectedOutcomeIndex]
                    : null;

            screen = (
                <OutcomeDetails
                    outcome={selectedOutcome}
                    onSave={onReturnToDefaultScreen}
                    onCancel={() => {
                        onReturnToDefaultScreen();
                        setSelectedOutcomeIndex(null);
                    }}
                />
            );
            break;
        }
        case screens.listener:
            screen = <ListenerDetails />;
            break;
        default:
            break;
    }

    return screen;
};

export default Load;
