import translations from '../../../translations';
import CodeEditor from '../../generic/CodeEditor';
import FormGroup from '../../generic/FormGroup';
import { CODE_TYPES } from './constants';

interface Props {
    value: string | boolean | number | null;
    codeType?: string;
    editorLabel?: string;
    placeholder?: string;
    isRequired?: boolean;
    onCodeEditorChange: (value: string | boolean | number) => void;
    onCodeTypeChange?: ((codeType: string) => void) | undefined;
}

const CodeTypeEditor = ({
    value,
    codeType = 'yaml',
    editorLabel,
    isRequired = false,
    onCodeEditorChange,
    onCodeTypeChange,
}: Props) => {
    return (
        <>
            <FormGroup label={translations.COMMON_code_type} htmlFor="code-type">
                <select
                    id="code-type"
                    value={codeType}
                    onChange={({ target: { value } }) => {
                        if (onCodeTypeChange) {
                            onCodeTypeChange(value);
                        }
                    }}
                    className="form-control form-control-width"
                    data-testid="code-type-select"
                >
                    <option value={CODE_TYPES.yaml}>{CODE_TYPES.yaml}</option>
                    <option value={CODE_TYPES.json}>{CODE_TYPES.json}</option>
                    <option value={CODE_TYPES.html}>{CODE_TYPES.html}</option>
                </select>
            </FormGroup>
            <FormGroup
                label={editorLabel || ''}
                isRequired={isRequired}
                htmlFor="default-content-value-code"
                className="code-type-editor"
            >
                <CodeEditor
                    mode={codeType}
                    value={value as string}
                    highlightActiveLine
                    name="code-editor"
                    onChange={onCodeEditorChange}
                    height="500px"
                />
            </FormGroup>
        </>
    );
};

export default CodeTypeEditor;
