import { useState, createContext, useContext } from 'react';
import screens from '../common/screens';
import { isNullOrEmpty } from '../../../../utils/guard';
import type { Screen } from '../common/screens';
import { useMapElement } from './MapElementProvider';
import type { Listener, ValueElementIdAPI } from '../../../../types';

type Props = {
    defaultScreen: Screen;
    children: React.ReactNode;
};

type ContextValue = {
    listenerToEdit: {
        listener: Listener | null;
        index: number | null;
    };
    onCreateListener: () => void;
    onEditListener: (listener: Listener, index: number) => void;
    onDeleteListener: (index: number) => void;
    onSelectDeveloperName: (developerName: string) => void;
    onSelectListenerType: (listenerType: string) => void;
    onSelectServiceElementId: (serviceElementId: string) => void;
    onSelectValueReference: (valueReference: ValueElementIdAPI | null) => void;
    onApplyListener: (index: number) => void;
    onReturnToDefaultScreen: () => void;
};

const Context = createContext<ContextValue | undefined>(undefined);

const ListenerProvider = ({ defaultScreen, children }: Props) => {
    const { mapElement, setMapElement, onSwitchScreen } = useMapElement();

    const initialListener: Listener = {
        attributes: {},
        developerName: '',
        listenerType: '',
        serviceElementId: '',
        valueElementToReferenceForListeningId: null,
    };

    const [listenerToEdit, setListenerToEdit] = useState<{
        listener: Listener | null;
        index: number | null;
    }>({
        listener: null,
        index: null,
    });

    const onCreateListener = () => {
        const totalListeners = mapElement.listeners ? mapElement.listeners.length : 0;
        setListenerToEdit({
            listener: { ...initialListener },
            index: totalListeners + 1,
        });
        onSwitchScreen(screens.listener);
    };

    const onEditListener = (listener: Listener, index: number) => {
        setListenerToEdit({ listener, index });
        onSwitchScreen(screens.listener);
    };

    const onDeleteListener = (index: number) => {
        setMapElement({
            ...mapElement,
            listeners: (mapElement.listeners ?? []).filter((_, i) => i !== index),
        });
    };

    const onSelectDeveloperName = (developerName: string) => {
        if (listenerToEdit.listener !== null) {
            setListenerToEdit({
                ...listenerToEdit,
                listener: {
                    ...listenerToEdit.listener,
                    developerName,
                },
            });
        }
    };

    const onSelectListenerType = (listenerType: string) => {
        if (listenerToEdit.listener !== null) {
            setListenerToEdit({
                ...listenerToEdit,
                listener: {
                    ...listenerToEdit.listener,
                    listenerType,
                },
            });
        }
    };

    const onSelectServiceElementId = (serviceElementId: string) => {
        if (listenerToEdit.listener !== null) {
            setListenerToEdit({
                ...listenerToEdit,
                listener: {
                    ...listenerToEdit.listener,
                    serviceElementId,
                },
            });
        }
    };

    const onSelectValueReference = (valueReference: ValueElementIdAPI | null) => {
        let selectedValue = null;

        if (!isNullOrEmpty(valueReference)) {
            selectedValue = {
                id: valueReference.id,
                typeElementPropertyId: valueReference.typeElementPropertyId,
                command: null,
            };
        }

        if (selectedValue !== null && listenerToEdit.listener !== null) {
            setListenerToEdit({
                ...listenerToEdit,
                listener: {
                    ...listenerToEdit.listener,
                    valueElementToReferenceForListeningId: selectedValue,
                },
            });
        }
    };

    const onApplyListener = (index: number) => {
        if (listenerToEdit.listener === null) {
            return;
        }

        const listenerExists = mapElement.listeners
            ? mapElement.listeners.find((_, i) => i === index)
            : null;
        const listeners = listenerExists
            ? (mapElement.listeners ?? []).map((existingListener, i) =>
                  i === index && listenerToEdit.listener !== null
                      ? listenerToEdit.listener
                      : existingListener,
              )
            : [...(mapElement.listeners ?? []), listenerToEdit.listener];

        setMapElement({ ...mapElement, listeners });
        onSwitchScreen(defaultScreen);
    };

    const onReturnToDefaultScreen = () => {
        onSwitchScreen(defaultScreen);
    };

    const contextValue: ContextValue = {
        listenerToEdit,
        onCreateListener,
        onEditListener,
        onDeleteListener,
        onSelectDeveloperName,
        onSelectListenerType,
        onSelectServiceElementId,
        onSelectValueReference,
        onApplyListener,
        onReturnToDefaultScreen,
    };

    return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

const useListener = () => {
    const context = useContext(Context);
    if (context === undefined) {
        throw new Error('useListener must be used within a ListenerProvider');
    }
    return context;
};

export { ListenerProvider, useListener };
