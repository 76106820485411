import { useEffect, useState } from 'react';
import ButtonPrimary from '../../../../../../buttons/ButtonPrimary';
import {
    COMPONENT_PROPERTIES as componentProperties,
    TOGGLE_CATEGORY_OPTIONS as toggleCategoryOptions,
} from '../../../../../constants';
import ButtonDefault from '../../../../../../buttons/ButtonDefault';
import { CONTENT_TYPES_LIST } from '../../../../../../../constants';
import { usePageEditor } from '../../../../PageEditorProvider';
import { usePageCondition } from '../../PageConditionsProvider';
import RadioToggle from '../../common/RadioToggle';
import {
    getItemCategoryOption,
    getOperationContentTypes,
    getPageComponentOptions,
    getSelectedComponent,
    getSelectedProperty,
    updateComponent,
    updateProperty,
    updateValue,
    isOperationConfigured,
    checkForNewIDs,
} from '../../common/pageConditionsUtils';
import ComponentOrValueConfig from '../../common/ComponentOrValueConfig';
import translations from '../../../../../../../translations';
import { stringReplace } from '../../../../../../../utils/string';
import { defaultPageOperationConfig } from '../../../../../templates';
import type { Assignment, ContentType } from '../../../../../../../types';

const PageConditionOperationConfig = () => {
    const { state: pageBuilderState, container } = usePageEditor();
    const { page } = pageBuilderState;
    const { pageComponents } = page;
    const {
        state: pageConditionState,
        updateOperationIndex,
        applyOperation,
        updateRefreshValues,
    } = usePageCondition();
    const { values, condition, operationIndex } = pageConditionState;
    const oi = operationIndex === null ? 0 : operationIndex;
    const operation =
        oi >= 0 && condition && condition.pageOperations
            ? condition.pageOperations[oi]
            : defaultPageOperationConfig;

    // Storing all the bits that we plan to edit in local state.
    const [operationConfig, setOperationConfig] = useState(operation);
    // Using local copy of the values, so I only trigger re-renders of this component when there are changes
    // (mainly to do the contentType error checking). Also, since I can cancel my operation edits at any time
    // I don't want to trigger the update of the values in the context until the edits have been applied (saved).
    const [localValues, setLocalValues] = useState(values ?? []);

    // Using "assignee" and "assignor" can be confusing, so we can think of them as "left" and "right" instead
    // where assignee = left and assignor = right to match JS.
    // operationConfigLeft = operationConfig?.assignment?.assignee = SET section of the operation config
    // operationConfigRight = operationConfig?.assignment?.assignor = TO section of the operation config
    const operationConfigLeft = operationConfig?.assignment?.assignee;
    const operationConfigRight = operationConfig?.assignment?.assignor;

    // Used for showing input validation!
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [rightSelector, setRightSelector] = useState(toggleCategoryOptions['COMPONENT']);
    const [contentTypeFilter, setContentTypeFilter] = useState<ContentType | null | undefined>(
        null,
    );
    const [incompatibleContentTypesMsg, setIncompatibleContentTypesMsg] = useState<string | null>(
        null,
    );

    // Preselect the radio buttons on initial load.
    useEffect(() => {
        const initialCategory = getItemCategoryOption(operationConfigRight, false);

        initialCategory && setRightSelector(initialCategory);
    }, [operationConfigRight]);

    // When the operation is edited and/or a new value is introduced we need to go over and update some things.
    useEffect(() => {
        // Figure out the left and right content types, so we can...
        const { leftContentType, rightContentType } = getOperationContentTypes(
            operationConfig,
            localValues,
            pageComponents,
        );

        // ...apply a filter when selecting the right value.
        setContentTypeFilter(leftContentType);

        // ...catch and warn about incompatible types before the rule is saved.
        const isIncompatibleContentTypes =
            leftContentType && rightContentType ? leftContentType !== rightContentType : false;

        const contentTypeErrorMsg = isIncompatibleContentTypes
            ? stringReplace(
                  translations.PAGE_BUILDER_page_conditions_operation_invalid_content_types_message,
                  {
                      contentTypeOne:
                          CONTENT_TYPES_LIST?.find((item) => item.key === leftContentType)?.label ??
                          leftContentType,
                      contentTypeTwo:
                          CONTENT_TYPES_LIST?.find((item) => item.key === rightContentType)
                              ?.label ?? rightContentType,
                  },
              )
            : null;

        setIncompatibleContentTypesMsg(contentTypeErrorMsg);
    }, [operationConfig, localValues, pageComponents]);

    const selectedLeftComponent = getSelectedComponent(operationConfigLeft, pageComponents);
    const selectedLeftProperty = getSelectedProperty(operationConfigLeft);

    const selectedRightComponent = getSelectedComponent(operationConfigRight, pageComponents);
    const selectedRightProperty = getSelectedProperty(operationConfigRight);
    const selectedRightValue = operationConfigRight?.valueElementToReferenceId;

    const updateOperationConfigLeft = (newData: Assignment | null) => {
        if (operationConfig.assignment) {
            setOperationConfig({
                ...operationConfig,
                assignment: {
                    ...operationConfig.assignment,
                    assignee: newData,
                },
            });
        }
    };

    const updateOperationConfigRight = (newData: Assignment | null) => {
        if (operationConfig.assignment) {
            setOperationConfig({
                ...operationConfig,
                assignment: {
                    ...operationConfig.assignment,
                    assignor: newData,
                },
            });
        }
    };

    const onCancel = () => {
        updateOperationIndex(null);
        setHasSubmitted(false);
    };

    const onApply = () => {
        setHasSubmitted(true);

        if (isOperationConfigured(operationConfig)) {
            // Check if we have any new values.
            updateRefreshValues(checkForNewIDs(condition, operationConfig));

            // Save the new/updated operation into the context.
            applyOperation(operationConfig);
            onCancel();
        }
    };

    const onUpdateToggle = (selected: string) => {
        // When using the toggle to switch between "component" or "value" reset any config related
        // to the other one.
        if (operationConfigRight) {
            const resetOperationConfigRight =
                selected === toggleCategoryOptions['COMPONENT']
                    ? {
                          ...operationConfigRight,
                          metadataType: null,
                          typeElementPropertyId: null,
                          valueElementToReferenceId: null,
                      }
                    : {
                          ...operationConfigRight,
                          metadataType: null,
                          pageObjectReferenceDeveloperName: '',
                          pageObjectReferenceId: null,
                      };

            updateOperationConfigRight(resetOperationConfigRight);
            setRightSelector(selected);
        }
    };

    return (
        <div className="sidebar-mini-editor" data-testid="operation-editor">
            <h4 className="sidebar-section-heading">
                {operationIndex !== null && operationIndex >= 0
                    ? 'Edit operation'
                    : 'New operation'}
            </h4>

            {/* LEFT (ASSIGNEE) */}
            <h4 className="sidebar-section-heading">SET</h4>

            <ComponentOrValueConfig
                componentOptions={getPageComponentOptions(pageComponents)}
                propertyOptions={componentProperties}
                selectedComponent={selectedLeftComponent || null}
                selectedProperty={selectedLeftProperty || null}
                updateSelectedComponent={(newComponent) =>
                    updateOperationConfigLeft(
                        operationConfigLeft
                            ? updateComponent(operationConfigLeft, newComponent)
                            : null,
                    )
                }
                updateSelectedProperty={(newProperty) =>
                    updateOperationConfigLeft(
                        operationConfigLeft
                            ? updateProperty(operationConfigLeft, newProperty)
                            : null,
                    )
                }
                hasSubmitted={hasSubmitted}
                identifier="left"
                container={container}
            />

            {/* RIGHT (ASSIGNOR) */}
            <h4 className="sidebar-section-heading">TO</h4>

            <RadioToggle
                options={toggleCategoryOptions}
                selectedOption={rightSelector}
                updateSelectedOption={onUpdateToggle}
            />

            <ComponentOrValueConfig
                selectedConfigType={rightSelector}
                componentOptions={getPageComponentOptions(pageComponents)}
                propertyOptions={componentProperties}
                selectedComponent={selectedRightComponent || null}
                selectedProperty={selectedRightProperty || null}
                selectedValue={selectedRightValue || null}
                updateSelectedComponent={(newComponent) =>
                    updateOperationConfigRight(
                        operationConfigRight
                            ? updateComponent(operationConfigRight, newComponent)
                            : null,
                    )
                }
                updateSelectedProperty={(newProperty) =>
                    updateOperationConfigRight(
                        operationConfigRight
                            ? updateProperty(operationConfigRight, newProperty)
                            : null,
                    )
                }
                updateSelectedValue={(newValue) => {
                    updateOperationConfigRight(
                        operationConfigRight ? updateValue(operationConfigRight, newValue) : null,
                    );

                    // If a new value is selected then we should...
                    if (newValue) {
                        // ...add it to our local values while we are still editing
                        setLocalValues([...localValues, newValue]);
                    }
                }}
                hasSubmitted={hasSubmitted}
                identifier="right"
                // Applying a filter so only compatible content types can be picked.
                contentTypeFilter={contentTypeFilter}
            />

            {incompatibleContentTypesMsg && (
                <span className="help-block error-state" data-testid="operation-type-error">
                    {translations.PAGE_BUILDER_page_conditions_invalid_content_types}
                    <br />
                    {incompatibleContentTypesMsg}
                </span>
            )}

            <br />

            <footer className="sidebar-mini-editor-footer">
                <ButtonDefault onClick={onCancel} title="Cancel operation">
                    Cancel
                </ButtonDefault>
                <ButtonPrimary
                    onClick={onApply}
                    title="Apply operation"
                    disabled={!!incompatibleContentTypesMsg}
                >
                    Apply
                </ButtonPrimary>
            </footer>
        </div>
    );
};

export default PageConditionOperationConfig;
