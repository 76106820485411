import GenericModal from '../../../../generic/modal/GenericModal';
import ButtonDefault from '../../../../buttons/ButtonDefault';
import ButtonPrimary from '../../../../buttons/ButtonPrimary';
import { useRef, useState } from 'react';
import { PAGE_ELEMENT_TYPES } from '../../../constants';
import type {
    ComponentRegistry,
    ComposerElement,
    TypeElementResponseAPI,
    ValueElementIdReferenceAPI,
} from '../../../../../types';
import { stringReplace } from '../../../../../utils/string';
import Select, { type SingleValue } from 'react-select';
import { getValidProperties, invalidContentTypesString } from './constants';
import Loader from '../../../../loader/Loader';
import translations from '../../../../../translations';
import { AlertBannerType, ExAlertBanner } from '@boomi/exosphere';
import { usePageEditor } from '../../PageEditorProvider';
import { useComposer } from '../composer/ComposerProvider';
import { getValueName } from '../../../../values/selector/value-selector-utils';
import { getSharedStyles } from '../../../../../utils/select';

interface SelectOption {
    label: string;
    value: string;
}

const ComponentSuggestSingleColumn = ({
    selectedComponent,
    goToOptionsScreen,
    type,
    mainContainer,
    order,
}: {
    selectedComponent: ComponentRegistry;
    goToOptionsScreen: () => void;
    type: TypeElementResponseAPI | null;
    mainContainer: string;
    order: number;
}) => {
    const ref = useRef<HTMLDivElement>(null);

    const { state, container, setStopComponentSuggest } = usePageEditor();
    const { onPageElementDrop } = useComposer();
    const componentSuggestValue = state.componentSuggestValue as ValueElementIdReferenceAPI;

    const validProperties = getValidProperties(type);
    const wereAnyColumnsObjectList =
        type?.properties?.length && type?.properties?.length > validProperties.length;

    const [selectedProperty, setSelectedProperty] = useState<SingleValue<SelectOption>>({
        value: validProperties?.[0]?.id ?? '',
        label: validProperties?.[0]?.developerName ?? '',
    });

    const createSingleColumnComponent = () => {
        if (!selectedProperty) {
            return;
        }

        let partialElement = {} as Partial<ComposerElement>;

        partialElement = {
            developerName: `"${getValueName(componentSuggestValue)}"`,
            valueElementDataBindingReferenceId: {
                id: componentSuggestValue.id,
                typeElementPropertyId: componentSuggestValue.typeElementPropertyId as string,
                command: null,
                relativeUnit: null,
            },
            columns: [
                {
                    isDisplayValue: true,
                    order: 0,
                    typeElementPropertyDeveloperName: selectedProperty.label,
                    typeElementPropertyId: selectedProperty.value,
                    boundTypeElementPropertyId: null,
                    isBound: false,
                    isEditable: false,
                    componentType: null,
                    typeElementPropertyToDisplayId: null,
                    isPinned: false,
                },
            ],
        };

        onPageElementDrop({
            id: null,
            targetId: mainContainer,
            order,
            pageElementType: PAGE_ELEMENT_TYPES['component'],
            componentType: selectedComponent.type.toLowerCase(),
            select: true,
            partialElement,
        });
        setStopComponentSuggest();
    };

    return (
        <GenericModal
            container={container}
            show
            className="config-modal component-suggestion-modal"
            onHide={setStopComponentSuggest}
            title={translations.COMPONENT_SUGGEST_single_column_view_title}
            animation={false}
            bodyClassName="flex-column"
            renderBody={() => {
                if (type === null) {
                    return <Loader />;
                }

                if (validProperties.length === 0) {
                    return (
                        <>
                            {stringReplace(
                                translations.COMPONENT_SUGGEST_list_has_no_valid_properties as string,
                                invalidContentTypesString,
                            )}
                        </>
                    );
                }

                return (
                    <>
                        {wereAnyColumnsObjectList && (
                            <ExAlertBanner
                                className="margin-bottom"
                                type={AlertBannerType.INFO}
                                open
                            >
                                {invalidContentTypesString} properties have been removed
                            </ExAlertBanner>
                        )}
                        <Select
                            styles={getSharedStyles<{ label: string; value: string }>()}
                            data-testid="single-column-select"
                            options={validProperties.map(({ id, developerName }) => ({
                                label: developerName,
                                value: id,
                            }))}
                            onChange={(option: SingleValue<SelectOption>) =>
                                setSelectedProperty(option)
                            }
                            placeholder="Select a property to render"
                            noOptionsMessage={() => 'No results found'}
                            required={true}
                            value={selectedProperty}
                            menuPosition="fixed"
                            menuPortalTarget={ref?.current}
                        />
                    </>
                );
            }}
            renderFooter={() => (
                <>
                    <ButtonDefault title="Back" onClick={goToOptionsScreen}>
                        Back
                    </ButtonDefault>
                    <ButtonPrimary
                        onClick={createSingleColumnComponent}
                        title={`Create ${selectedComponent.ui.caption} component`}
                        disabled={validProperties.length === 0}
                    >
                        Create {selectedComponent.ui.caption} component
                    </ButtonPrimary>
                </>
            )}
        />
    );
};

export default ComponentSuggestSingleColumn;
