import ButtonDanger from '../../../buttons/ButtonDanger';
import ButtonDefault from '../../../buttons/ButtonDefault';
import translations from '../../../../translations';
import { getElements } from '../../../../config/graphElements';
import { MAP_ELEMENT_TYPES } from '../../../../constants';
import { useGraph } from '../../../../../js/components/graph/GraphProvider';
import {
    GraphElementType,
    type GroupElement,
    type MapElement,
    type Outcome,
} from '../../../../types/graph';
import Table, { type TableColumnList } from '../../../generic/Table';
import { partition, prop, sortBy, uniqBy } from 'ramda';
import { getByID } from '../../../../utils/collection';
import { stringReplace } from '../../../../utils/string';
import { isGroupElement } from '../../../../../js/components/graph/utils';
import ModalBody from '../../../generic/modal/ModalBody';
import ModalFooter from '../../../generic/modal/ModalFooter';
import ConfigModal from '../../../graph/ConfigModal';
import { useFeatureFlag } from '../../../featureFlags/FeatureFlagProvider';

interface Props {
    container: HTMLElement;
    dismissMapElementConfig: () => void;
    elementId?: string;
    outcomeId?: string;
}

interface UseGraph {
    mapElements: MapElement[];
    groupElements: GroupElement[];
    selectedElementIds: string[];
    deleteElements: (
        elementsToDeleteIds: string[],
        allDeletedElementIds: string[],
        mapElementsToUpdate: string[],
    ) => Promise<void>;
    deleteOutcome: (outcomeId: string, elementId: string) => Promise<void>;
}

interface Children {
    mapElements: MapElement[];
    groupElements: GroupElement[];
    outcomes: UniqueOutcome[];
}

interface UniqueOutcome extends Outcome {
    uniqueId: string;
    mapElementId: string;
}

/**
 * @description This component allows the user to delete a multiple elements,
 * this includes outcomes of selected map elements and children of selected group elements.
 * 1. Delete selected elements: elementId: null,                outcomeId: null
 * 2. Delete map/group element: elementId: map/group id,        outcomeId: null
 * 3. Delete outcome:           elementId: map id with outcome, outcomeId: outcome id
 */
const MultiElementDelete = ({
    container,
    dismissMapElementConfig,
    elementId,
    outcomeId,
}: Props) => {
    const {
        mapElements,
        groupElements,
        selectedElementIds,
        deleteElements,
        deleteOutcome,
    }: UseGraph = useGraph();
    const { getFlag } = useFeatureFlag();
    const graphElements = getElements(getFlag);
    const elementIds = elementId ? [elementId] : selectedElementIds;

    const onDelete = async () => {
        if (outcomeId && elementId) {
            await deleteOutcome(outcomeId, elementId);
        } else {
            const [mapElementIds, groupElementIds] = partition(
                (id) => getByID(id, mapElements) !== undefined,
                elementIds,
            );
            const deletingMapElements = mapElementIds.map(
                (id) => getByID(id, mapElements) as MapElement,
            );
            const deletingGroupElements = groupElementIds.map(
                (id) => getByID(id, groupElements) as GroupElement,
            );

            const children = getChildren(groupElementIds, mapElements, groupElements);
            const deletingOutcomes = getOutcomes(deletingMapElements);

            const mapElementsToUpdate = deletingOutcomes
                .filter(
                    (outcome) =>
                        !deletingMapElements.find(
                            (mapElement) => mapElement.id === outcome.mapElementId,
                        ),
                )
                .map((outcome) => outcome.mapElementId);

            const allElementIds = [
                ...deletingMapElements,
                ...children.mapElements,
                ...deletingGroupElements,
                ...children.groupElements,
            ].map((child) => child.id);

            await deleteElements(elementIds, allElementIds, mapElementsToUpdate);
        }
        dismissMapElementConfig();
    };

    /** given an array of group element ids, returns all their child elements */
    const getChildren = (
        newGroupIds: string[],
        allMapElements: MapElement[],
        currentGroupElements: GroupElement[],
    ): Children => {
        // find child map and group elements
        const [childMapElements, notChildMapElements] = partition(
            (el) => newGroupIds.includes(el.groupElementId || ''),
            allMapElements,
        );
        const [childGroupElements, notChildGroupElements] = partition(
            (el) => newGroupIds.includes(el.groupElementId || ''),
            currentGroupElements,
        );

        // find outcomes connected to child map elements
        const childOutcomes = getOutcomes(childMapElements, notChildMapElements);

        let grandchildMapElements: MapElement[] = [];
        let grandchildGroupElements: GroupElement[] = [];
        let grandchildOutcomes: UniqueOutcome[] = [];
        // find the children of any new group elements, removing child group elements to avoid infinite loops
        if (childGroupElements.length > 0) {
            ({
                mapElements: grandchildMapElements,
                groupElements: grandchildGroupElements,
                outcomes: grandchildOutcomes,
            } = getChildren(
                childGroupElements.map((group) => group.id),
                allMapElements,
                notChildGroupElements,
            ));
        }
        return {
            mapElements: sortBy(prop('developerName'))(
                uniqBy(prop('id'), [...childMapElements, ...grandchildMapElements]),
            ),
            groupElements: sortBy(prop('developerName'))(
                uniqBy(prop('id'), [...childGroupElements, ...grandchildGroupElements]),
            ),
            outcomes: sortBy(prop('developerName'))(
                uniqBy(prop('uniqueId'), [...childOutcomes, ...grandchildOutcomes]),
            ),
        };
    };

    /** Outcome ids are not unique as all FAULT outcomes have the same id */
    const addUniqueIdToOutcomes = (mapElement: MapElement): UniqueOutcome[] =>
        (mapElement.outcomes || []).map((out) => ({
            ...out,
            mapElementId: mapElement.id,
            uniqueId: mapElement.id + out.id,
        }));

    /** Returns all outcomes that start or end on `deletingMapElements` */
    const getOutcomes = (
        deletingMapElements: MapElement[],
        allMapElements: MapElement[] = mapElements,
    ): UniqueOutcome[] => {
        const deletingMapElementIds = deletingMapElements.map((deletingMap) => deletingMap.id);
        return [
            ...deletingMapElements.flatMap((map) => addUniqueIdToOutcomes(map)),
            ...allMapElements.flatMap((map) =>
                addUniqueIdToOutcomes(map).filter((out) =>
                    deletingMapElementIds.includes(out.nextMapElementId || ''),
                ),
            ),
        ];
    };

    const columns: TableColumnList<MapElement | GroupElement | UniqueOutcome> = [
        {
            renderHeader: () => translations.COMMON_TABLE_name,
            renderCell: ({ item }) => item.developerName,
        },
        {
            renderHeader: () => translations.COMMON_TABLE_element_type,
            renderCell: ({ item }) => {
                if ('elementType' in item) {
                    return (
                        graphElements.find(
                            (el) => el.id.toLowerCase() === item.elementType.toLowerCase(),
                        )?.name ?? item.elementType
                    );
                }
                if ('nextMapElementId' in item) {
                    return graphElements.find((el) => el.id === GraphElementType.outcome)?.name;
                }
                return '';
            },
        },
        {
            renderHeader: () => translations.COMMON_TABLE_id,
            renderCell: ({ item }) => item.id,
        },
    ];

    let orderedChildren: (MapElement | GroupElement | UniqueOutcome)[] = [];
    let outcomeDeveloperName = '';
    const element =
        elementId &&
        (getByID(elementId, [...mapElements, ...groupElements]) as MapElement | GroupElement);

    if (outcomeId) {
        orderedChildren = [];

        outcomeDeveloperName = (
            getByID(outcomeId, (element as MapElement)?.outcomes || []) as Outcome
        )?.developerName;
    } else {
        const [mapElementIds, groupElementIds] = partition(
            (id) => getByID(id, mapElements) !== undefined,
            elementIds,
        );
        const deletingMapElements = mapElementIds.map(
            (id) => getByID(id, mapElements) as MapElement,
        );
        const deletingGroupElements = groupElementIds.map(
            (id) => getByID(id, groupElements) as GroupElement,
        );

        const children = getChildren(groupElementIds, mapElements, groupElements);

        const deletingOutcomes = getOutcomes(deletingMapElements);

        orderedChildren = [
            ...uniqBy(prop('id'), [...deletingMapElements, ...children.mapElements]),
            ...uniqBy(prop('id'), [...deletingGroupElements, ...children.groupElements]),
            ...uniqBy(prop('uniqueId'), [...deletingOutcomes, ...children.outcomes]),
        ].filter((child) => child !== undefined && child?.id !== elementId); // Remove the item you are deleting from the children list
    }

    let elementType = '';
    let developerName = '';
    if (outcomeId) {
        elementType = getByID(MAP_ELEMENT_TYPES.outcome, graphElements)?.name || '';
        developerName = outcomeDeveloperName;
    } else if (elementId && element) {
        elementType =
            getByID(element.elementType.toLowerCase(), graphElements)?.name ||
            `${isGroupElement(element) ? ' Element' : ' Map Element'}`;
        developerName = element.developerName || '';
    }

    const renderBody = () => (
        <>
            {outcomeId ? (
                // Deleting an outcome
                <p key="delete">
                    {stringReplace(
                        translations.ELEMENT_delete_confirm,
                        developerName,
                        MAP_ELEMENT_TYPES.outcome,
                    )}
                </p>
            ) : (
                <>
                    {elementId ? (
                        // Deleting a map or group element
                        <>
                            <p key="delete">
                                {stringReplace(
                                    translations.ELEMENT_delete_confirm,
                                    developerName,
                                    elementType.toLowerCase(),
                                )}
                            </p>
                            {orderedChildren.length > 0 && (
                                <p key="delete-dependencies">
                                    {translations.ELEMENT_delete_dependencies}
                                </p>
                            )}
                        </>
                    ) : (
                        // Deleting multiple elements
                        <p key="delete">{translations.ELEMENT_delete_multi_body}</p>
                    )}
                    {orderedChildren.length > 0 && (
                        <Table columns={columns} items={orderedChildren} />
                    )}
                </>
            )}
        </>
    );

    const renderFooter = () => (
        <>
            <ButtonDefault
                key={translations.COMMON_cancel}
                className="flex-child-right"
                onClick={dismissMapElementConfig}
            >
                {translations.COMMON_cancel}
            </ButtonDefault>
            <ButtonDanger
                key={translations.COMMON_delete}
                className="margin-left"
                onClick={onDelete}
            >
                {translations.COMMON_delete}
            </ButtonDanger>
        </>
    );

    const title = elementType
        ? stringReplace(translations.ELEMENT_delete, elementType)
        : translations.ELEMENT_delete_multi;

    return (
        <ConfigModal
            id={''}
            title={title}
            elementType={'delete_header'}
            onHide={dismissMapElementConfig}
            container={container}
        >
            <ModalBody>{renderBody()}</ModalBody>
            <ModalFooter>{renderFooter()}</ModalFooter>
        </ConfigModal>
    );
};

export default MultiElementDelete;
