import { CirclesThreePlus } from '@phosphor-icons/react';
import type { ValueElementIdAPI, ValueElementIdReferenceAPI } from '../../types';
import { isNullOrUndefined, stringReplace } from '../../utils';
import ValueSelectorModal, {
    type ValueSelectorModalProps,
} from '../values/selector/ValueSelectorModal';
import ComponentWithTooltip from './tooltip/ComponentWithTooltip';
import translations from '../../translations';

type Props = Omit<ValueSelectorModalProps, 'value'> & {
    children: React.ReactElement<HTMLInputElement>;
    value: string | ValueElementIdAPI | ValueElementIdReferenceAPI | null;
};

const SelectorIconButton = () => (
    <ComponentWithTooltip
        trigger={['hover', 'focus']}
        fadeTime={0.1}
        tooltipContent={stringReplace(translations.VS_select_button_text, { element: 'Value' })}
        wrapperClass="input-value-picker-tooltip-wrapper"
    >
        <span
            className="input-value-picker-button btn btn-default"
            data-testid="input-value-picker-button"
        >
            <CirclesThreePlus height="24" />
        </span>
    </ComponentWithTooltip>
);

const InputOrValuePicker = ({ children, value, ...valueSelectorProps }: Props) => {
    const isValueObject =
        !isNullOrUndefined(value) && typeof value === 'object' && Object.hasOwn(value, 'id');

    if (isValueObject) {
        return <ValueSelectorModal {...valueSelectorProps} value={value} />;
    }

    return (
        <div className="input-value-picker" data-testid="input-value-picker">
            {children}
            <ValueSelectorModal
                {...valueSelectorProps}
                value={null}
                renderSelectorButton={() => <SelectorIconButton />}
            />
        </div>
    );
};

export default InputOrValuePicker;
