import { useEffect, useState } from 'react';
import { assocPath, path, remove } from 'ramda';
import PrimitiveContentTypeEditor from './PrimitiveContentTypeEditor';
import {
    AlertBannerType,
    ButtonFlavor,
    ButtonType,
    ExAlertBanner,
    ExButton,
    ExInput,
    ExLoader,
} from '@boomi/exosphere';
import type {
    ContentType,
    ObjectAPI,
    ObjectPath,
    PropertyAPI,
    TypeElementResponseAPI,
} from '../../../types';
import { getType } from '../../../sources/type';
import ObjectDataEditorItem, {
    includeProperty,
    isComplexProperty,
    newObjectDataFromType,
} from './ObjectDataEditorItem';
import translations from '../../../translations';
import { stringReplace } from '../../../utils/string';

interface Props {
    value: ObjectAPI[] | null;
    onChange: (objectData: ObjectAPI[] | null) => void;
    typeElementId: string;
    contentType: ContentType | null;
}

const ObjectDataEditor = ({ value, typeElementId, contentType, onChange }: Props) => {
    const [filter, setFilter] = useState<string>('');
    const [selectedPath, setSelectedPath] = useState<ObjectPath>([]);
    const [hoveredPath, setHoeveredPath] = useState<ObjectPath>([]);
    const [editorOffsetTop, setEditorOffsetTop] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [type, setType] = useState<TypeElementResponseAPI | null>(null);
    const [typeDictionary, setTypeDictionary] = useState<Record<string, TypeElementResponseAPI>>(
        {},
    );

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const load = async () => {
            try {
                setError(null);
                setLoading(true);
                const loadedType = await getType(typeElementId);
                setType(loadedType);

                const newTypeDictionary = { [typeElementId]: loadedType } as Record<
                    string,
                    TypeElementResponseAPI
                >;

                const findOrFetchType = async (typeElementId: string) => {
                    const foundLoadedType = newTypeDictionary[typeElementId];
                    if (foundLoadedType) {
                        return foundLoadedType;
                    }
                    const loadedType = await getType(typeElementId);
                    newTypeDictionary[typeElementId] = loadedType;
                    return loadedType;
                };

                const addNewTypeProperties = async (objectData: ObjectAPI): Promise<ObjectAPI> => {
                    const loadedType = await findOrFetchType(objectData.typeElementId);

                    const newProperties = loadedType.properties.map((typeProp) => {
                        const foundObjectDataEntry = objectData.properties.find(
                            (prop) => prop.typeElementPropertyId === typeProp.id,
                        );

                        return (
                            foundObjectDataEntry || {
                                typeElementPropertyId: typeProp.id,
                                developerName: typeProp.developerName,
                                contentValue: '',
                                contentType: typeProp.contentType,
                                contentFormat: typeProp.contentFormat ?? '',
                                objectData: null,
                                typeElementId: typeProp.typeElementId,
                            }
                        );
                    });

                    return {
                        ...objectData,
                        properties: await Promise.all(
                            newProperties.map(async (prop) => ({
                                ...prop,
                                objectData:
                                    prop.objectData !== null
                                        ? await Promise.all(
                                              prop.objectData.map(
                                                  async (obj) => await addNewTypeProperties(obj),
                                              ),
                                          )
                                        : null,
                            })),
                        ),
                    };
                };

                if (value !== null) {
                    const newValue = await Promise.all(
                        value.map(async (entry) => await addNewTypeProperties(entry)),
                    );

                    onChange(newValue);
                }

                setTypeDictionary(newTypeDictionary);
            } catch (error) {
                setError((error as Error).message);
            } finally {
                setLoading(false);
            }
        };

        load();
    }, [typeElementId, value === null]);

    const onSelect = (path: ObjectPath, offsetTop: number) => {
        setSelectedPath(path);
        setEditorOffsetTop(offsetTop);
    };

    const onHover = (path: ObjectPath) => {
        setHoeveredPath(path);
    };

    const onPrimitivePropertyChange = (propertyValue: string | number | boolean) => {
        onChange(assocPath([...selectedPath, 'contentValue'], propertyValue.toString(), value));
    };

    const onObjectDataPropertyChange = (objectData: ObjectAPI[], propertyPath: ObjectPath) => {
        onChange(assocPath([...propertyPath, 'objectData'], objectData, value));
    };

    const onAddItem = () => {
        if (type) {
            onChange([...(value || []), newObjectDataFromType(type, value?.length || 0)]);
        }
    };

    const onMouseLeave = () => setHoeveredPath([]);

    const canAddNewItem =
        (type && contentType === 'ContentObject' && (!value || value.length === 0)) ||
        contentType === 'ContentList';

    const selectedProperty = selectedPath ? path<PropertyAPI>(selectedPath, value) : null;
    let editor = null;

    if (selectedProperty) {
        if (isComplexProperty(selectedProperty)) {
            editor = null;
        } else {
            editor = (
                <div style={{ position: 'relative', top: editorOffsetTop - 6 }}>
                    <PrimitiveContentTypeEditor
                        value={selectedProperty.contentValue ?? ''}
                        isRequired={false}
                        type={selectedProperty.contentType}
                        onChange={onPrimitivePropertyChange}
                        label="Value"
                    />
                </div>
            );
        }
    }

    return (
        <div className="object-data-editor">
            {error ? (
                <ExAlertBanner type={AlertBannerType.ERROR} open>
                    {error}
                </ExAlertBanner>
            ) : null}
            {loading ? <ExLoader /> : null}
            <ExInput
                type="search"
                placeholder="Filter properties"
                value={filter}
                onInput={(e: Event) => setFilter((e.currentTarget as HTMLInputElement).value)}
                clearable={true}
                className="object-data-editor-filter"
            />
            {type ? (
                <>
                    <div className="object-data-editor-tree" onMouseLeave={onMouseLeave}>
                        <ol role="tree">
                            {value?.map((item, index) => (
                                <li key={item.internalId} role="treeitem" aria-selected={false}>
                                    <div className="object-data-editor-item-header">
                                        <span>{`Item - ${index}`}</span>
                                        <ExButton
                                            flavor={ButtonFlavor.BRANDED}
                                            type={ButtonType.SECONDARY}
                                            onClick={() => onChange(remove(index, 1, value))}
                                            data-testId={`item-${index}-remove`}
                                        >
                                            {translations.OBJECT_DATA_EDITOR_remove}
                                        </ExButton>
                                    </div>
                                    <ol>
                                        {item.properties
                                            .filter((property) => includeProperty(filter, property))
                                            .map((property, propertyIndex) => (
                                                <ObjectDataEditorItem
                                                    value={property}
                                                    path={[index, 'properties', propertyIndex]}
                                                    selectedPath={selectedPath}
                                                    hoveredPath={hoveredPath}
                                                    onHover={onHover}
                                                    filter={filter}
                                                    onSelect={onSelect}
                                                    onObjectDataPropertyChange={
                                                        onObjectDataPropertyChange
                                                    }
                                                    key={`${[
                                                        index,
                                                        property.typeElementPropertyId,
                                                    ].join('_')}`}
                                                    typeDictionary={typeDictionary}
                                                />
                                            ))}
                                    </ol>
                                </li>
                            ))}
                        </ol>
                        {canAddNewItem ? (
                            <ExButton
                                flavor={ButtonFlavor.BRANDED}
                                type={ButtonType.SECONDARY}
                                onClick={onAddItem}
                                className="object-data-editor-add-new-item"
                                data-testId="object-data-editor-add-new-item"
                            >
                                {stringReplace(
                                    translations.OBJECT_DATA_EDITOR_add_new_item as string,
                                    type.developerName,
                                )}
                            </ExButton>
                        ) : null}
                    </div>
                </>
            ) : null}
            <div className="object-data-editor-value">{editor}</div>
        </div>
    );
};

export default ObjectDataEditor;
