import { type ChangeEvent, type ReactNode, useEffect, useState } from 'react';
import { addNotification } from '../../../../js/actions/reduxActions/notification';
import { getIdentityProviderList } from '../../../../ts/sources/identityprovider';
import translations from '../../../translations';
import FormGroup from '../../generic/FormGroup';
import type { NewServiceData } from '../contextProviders/ServicesProvider';
import { isNullOrEmpty } from '../../../utils/guard';

interface NewFlowServiceProps {
    newServiceData: NewServiceData;
    setNewServiceDetails: (newServiceData: NewServiceData) => void;
    hasSubmitted: boolean;
}

/**
 * Allow the user to name the supported Flow service that they have selected for install
 * @param {Object} newServiceData = {} - Object containing all the data about the new service being created
 * @param {Function} setNewServiceDetails = {} - Function on ServiceController that allows us to update the newServiceData
 * @param {Boolean} hasSubmitted
 */
const NewFlowService = ({
    newServiceData,
    setNewServiceDetails,
    hasSubmitted,
}: NewFlowServiceProps) => {
    const [identityProviderOptions, setIdentityProviderOptions] = useState<ReactNode>(null);

    useEffect(() => {
        async function GetIdps(): Promise<void> {
            try {
                const data = await getIdentityProviderList({
                    limit: 1000000,
                    types: ['boomiApiKey'],
                });

                let idpOptions: ReactNode[] = [];

                if (data.items) {
                    idpOptions = data.items.map((idp) => (
                        <option value={idp.id} key={idp.id}>
                            {idp.developerName}
                        </option>
                    ));
                    idpOptions.unshift(
                        <option value="" key="no authorisation">
                            {translations.SC_identity_provider_none_selected_text}
                        </option>,
                    );

                    setIdentityProviderOptions(idpOptions);
                }
            } catch (error) {
                addNotification({
                    type: 'error',
                    message: (error as Error).toString(),
                });
            }
        }

        GetIdps();
    }, []);

    const updateSelectedIdentityProvider = (event: ChangeEvent<HTMLSelectElement>) => {
        if (event.target.value) {
            setNewServiceDetails({
                ...newServiceData,
                identityProviderId: event.target.value,
            });
        }
    };

    return (
        <>
            <h1>New Connector</h1>
            <span>{newServiceData.description}</span>
            <FormGroup
                isRequired
                htmlFor="connector-name"
                label="Name"
                validationMessage={translations.SC_name_field_validation_message}
                isValid={!isNullOrEmpty(newServiceData.name ?? '')}
                showValidation={hasSubmitted}
            >
                <input
                    id="connector-name"
                    className="form-control form-control-long"
                    onChange={({ target }) =>
                        setNewServiceDetails({
                            ...newServiceData,
                            name: target.value,
                        })
                    }
                    value={newServiceData.name ?? ''}
                />
            </FormGroup>
            <FormGroup htmlFor="connector-url" label="Url">
                <input
                    className="form-control form-control-long"
                    value={newServiceData.url}
                    disabled
                />
            </FormGroup>
            <FormGroup
                htmlFor="connector-auth"
                label={translations.SC_authentication_text}
                className={`${newServiceData.canHaveIdentity ? '' : 'hidden'} form-control-long`}
            >
                <select
                    id="connector-auth"
                    onChange={updateSelectedIdentityProvider}
                    className="form-control validation-type"
                    data-testid="authSelect"
                >
                    {identityProviderOptions}
                </select>
            </FormGroup>
        </>
    );
};

export default NewFlowService;
