import { useState } from 'react';
import { type ConnectedProps, connect } from 'react-redux';
import { notifyError } from '../../js/actions/reduxActions/notification';
import { CollaborationProvider } from '../collaboration/CollaborationProvider';
import AdminSidebar from './AdminSidebar';
import Footer from './Footer';
import Tenant from './Tenant';
import IdleTimeout from './authentication/IdleTimeout';
import FeatureFlagGuard from './featureFlags/FeatureFlagGuard';
import { FeatureFlagProvider } from './featureFlags/FeatureFlagProvider';
import Header from './header/Header';
import TenantSwitcher from './masthead/TenantSwitcher';
import NotificationList from './notifications/NotificationList';

type ToolingProps = { currentLoggedInTenantId: string | undefined } & ConnectedProps<
    typeof connector
>;

const connector = connect(null, { notifyError });

/**
 *
 * @description The base component that renders all parts of the application that needs
 * to display the header and footer.
 */
const Tooling = ({ currentLoggedInTenantId }: ToolingProps) => {
    const [isTenantSelectorOpen, setIsTenantSelectorOpen] = useState(false);

    const onCloseTenantSelector = () => setIsTenantSelectorOpen(false);
    const openTenantSelector = () => setIsTenantSelectorOpen(true);

    return (
        <FeatureFlagProvider>
            <FeatureFlagGuard>
                <div className="draw">
                    <Header openTenantSelector={openTenantSelector} />
                    <AdminSidebar />
                    {isTenantSelectorOpen || !currentLoggedInTenantId ? (
                        <TenantSwitcher onClose={onCloseTenantSelector} />
                    ) : null}
                    {currentLoggedInTenantId && (
                        <>
                            <IdleTimeout />
                            <CollaborationProvider tenantId={currentLoggedInTenantId}>
                                <div className="tab-outer">
                                    <Tenant isTenantSelectorOpen={isTenantSelectorOpen} />
                                </div>
                            </CollaborationProvider>
                        </>
                    )}
                    <Footer />
                    <NotificationList />
                </div>
            </FeatureFlagGuard>
        </FeatureFlagProvider>
    );
};

export default connector(Tooling);
