import type { ContainerType, PageElementType } from './components/dnd-utils';
import type { FilterType, PreviewMode, PageComponentName } from '../../types';

export const COMPONENT_GROUPS: { [key: string]: string } = {
    CONTAINERS: 'Containers',
    CONTENT: 'Content',
    INPUT: 'Input',
    DATA: 'Data',
    CHARTS: 'Charts',
    CUSTOM: 'Custom',
};

export const ELEMENT_EDGES: { [key: string]: string } = {
    top: 'top',
    bottom: 'bottom',
    left: 'left',
    right: 'right',
    all: 'all',
};

export const COMPONENT_PROPERTIES: { label: string; value: string }[] = [
    { label: 'Visibility', value: 'METADATA.VISIBLE' },
    { label: 'Required', value: 'METADATA.REQUIRED' },
    { label: 'Enabled', value: 'METADATA.ENABLED' },
    { label: 'Editable', value: 'METADATA.EDITABLE' },
    { label: 'Value', value: 'VALUE' },
];

export const COMPONENT_CONFIGURATION_PATH: { [key: string]: string[] } = {
    NAME: ['developerName'],
    LABEL: ['label'],
    CONTENT: ['content'],
    IMAGE_URI: ['imageUri'],
    SOURCE: ['attributes', '$src'],
    FILENAME: ['attributes', '$filename'],
    AUTO_UPLOAD: ['attributes', '$autoUpload'],
    SORTABLE_COLUMNS: ['attributes', '$sortableColumns'],
    EXPORT_CSV_BUTTON: ['attributes', '$exportCsvButton'],
    CSV_FILE_NAME: ['attributes', '$csvFileName'],
    WIDTH: ['width'],
    HEIGHT: ['height'],
    EDITABLE: ['isEditable'],
    MULTISELECT: ['isMultiSelect'],
    REQUIRED: ['isRequired'],
    SEARCHABLE: ['isSearchable'],
    SEARCHABLEDISPLAYCOLUMNS: ['isSearchableDisplayColumns'],
    STATE_VALUE: ['valueElementValueBindingReferenceId'],
    DATA_SOURCE_LIST: ['valueElementDataBindingReferenceId'],
    DATA_SOURCE_LIST_VALUE: ['valueElementDataBindingReferenceId', 'id'],
    DATA_SOURCE_CONNECTOR: ['objectDataRequest'],
    DATA_SOURCE_CONNECTOR_TYPE: ['objectDataRequest', 'typeElementId'],
    DATA_SOURCE_CONNECTOR_BINDING: ['objectDataRequest', 'typeElementBindingId'],
    DATA_SOURCE_FILTER: ['objectDataRequest', 'listFilter'],
    DATA_SOURCE_2: ['objectDataRequest'],
    DATA_PRESENTATION: ['columns'],
    ROW: ['row'],
    COLUMN: ['column'],
    ROW_SPAN: ['rowSpan'],
    COL_SPAN: ['colSpan'],
    ATTRIBUTES: ['attributes'],
    COMPONENT_TYPE: ['componentType'],
    FILE_DATA_REQUEST_RESOURCE_PATH: ['fileDataRequest', 'resourcePath'],
    FILE_DATA_REQUEST_CONNECTOR_ID: ['fileDataRequest', 'serviceElementId'],
    FILE_DATA_REQUEST_PATH: ['fileDataRequest', 'path'],
    HELP_INFO: ['helpInfo'],
    HINT_VALUE: ['hintValue'],
    VALIDATIONS: ['validations'],
    CLASSNAME: ['className'],
};

export const COMPONENT_TYPE: { [key: string]: PageComponentName } = {
    INPUT: 'INPUT',
    TEXTAREA: 'TEXTAREA',
    CHECKBOX: 'CHECKBOX',
    CHECKBOXLIST: 'CHECKBOX-LIST',
    TABLE: 'TABLE',
    DATAGRID: 'DATAGRID',
    SELECT: 'SELECT',
    NATIVESELECT: 'NATIVE-SELECT',
    RADIO: 'RADIO',
    TOGGLE: 'TOGGLE',
    CONTENT: 'CONTENT',
    FILEUPLOAD: 'FILE-UPLOAD',
    /** @deprecated */
    FILES: 'FILES',
    TILES: 'TILES',
    CHARTBAR: 'CHART-BAR',
    CHARTLINE: 'CHART-LINE',
    CHARTPIE: 'CHART-PIE',
    CHARTDOUGHNUT: 'CHART-DOUGHNUT',
    CHARTPOLAR: 'CHART-POLAR',
    /** @deprecated */
    HIDDEN: 'HIDDEN',
    PRESENTATION: 'PRESENTATION',
    LIST: 'LIST',
    OUTCOMES: 'OUTCOMES',
    /** @deprecated */
    IMAGE: 'IMAGE',
    PDF: 'PDF-DOWNLOADER',
    PAYMENTCARD: 'PAYMENT-CARD',
    SIGNATUREPAD: 'SIGNATURE-PAD',
    IFRAME: 'IFRAME',
    DOWNLOADBUTTON: 'DOWNLOAD-BUTTON',
    CODEGENERATOR: 'CODE-GENERATOR',
    CODESCANNER: 'CODE-SCANNER',
    AICHAT: 'AI-CHAT',
    UNKNOWN: 'UNKNOWN',
};

export const COMPONENT_CONFIGURATION_LABELS: { [key: string]: string } = {
    NAME: 'Name',
    LABEL: 'Label',
    CONTENT: 'Content',
    IMAGE_URI: 'Image URI',
    SOURCE: 'URL',
    FILENAME: 'Filename',
    AUTO_UPLOAD: 'Begin uploading when a file is selected',
    SORTABLE_COLUMNS: 'Allow for sorting on columns',
    EXPORT_CSV_BUTTON: 'Display export CSV button',
    CSV_FILE_NAME: 'File name of exported CSV',
    WIDTH: 'Width',
    HEIGHT: 'Height',
    EDITABLE: 'Editable',
    MULTISELECT: 'Multi-select',
    SEARCHABLE: 'Searchable',
    SEARCHABLEDISPLAYCOLUMNS: 'Only Search Display Columns',
    STATE_VALUE: 'Save the component state into',
    DATA_SOURCE_LIST: 'Get the data from a List Value',
    DATA_SOURCE_CONNECTOR: 'Get the data from a Connector',
    DATA_SOURCE_2: 'Data source',
    FILE_DATA_SOURCE: 'Select the connector to load files from',
    DATA_SOURCE_FILTER: 'Data source filter',
    DATA_PRESENTATION: 'Data presentation',
    VALIDATION_MIN_NUMBER: 'Minimum number',
    VALIDATION_MAX_NUMBER: 'Maximum number',
    VALIDATION_MIN_DATE: 'Minimum date',
    VALIDATION_MAX_DATE: 'Maximum date',
    VALIDATION_MIN_DATE_TIME: 'Minimum date and time',
    VALIDATION_MAX_DATE_TIME: 'Maximum date and time',
    VALIDATION_PATTERN: 'Regular expression pattern',
    HINT_VALUE: 'Placeholder text',
    HELP_INFO: 'Help information about this component',
};

export const TOGGLE_TRIGGER_OPTIONS: { [key: string]: string } = {
    CHANGE: 'Component changes',
    LOAD: 'Page loads',
};

export const TOGGLE_CATEGORY_OPTIONS: { [key: string]: string } = {
    COMPONENT: 'COMPONENT',
    VALUE: 'VALUE',
};

export const SUMMARY_CONTEXT: { [key: string]: string } = {
    CONDITIONS: 'CONDITIONS',
    RULES: 'RULES',
    OPERATIONS: 'OPERATIONS',
};

export const DATA_SOURCE: { [key: string]: string } = {
    LIST: 'LIST',
    CONNECTOR: 'CONNECTOR',
};

export const PREVIEW_MODE: { [key: string]: PreviewMode } = {
    MOBILE: 'MOBILE',
    TABLET: 'TABLET',
    DESKTOP: 'DESKTOP',
};

export const PAGE_ELEMENT_TYPES: { [key: string]: PageElementType } = {
    container: 'CONTAINER',
    component: 'COMPONENT',
};

export const CONTAINER_TYPE: { [key: string]: ContainerType } = {
    row: 'VERTICAL_FLOW',
    column: 'HORIZONTAL_FLOW',
    inline: 'INLINE_FLOW',
    group: 'GROUP',
    chart: 'CHARTS',
};

export const CONTAINER_LABELS: { [key: string]: string } = {
    VERTICAL_FLOW: 'Row',
    HORIZONTAL_FLOW: 'Column',
    INLINE_FLOW: 'Inline',
    GROUP: 'Tabs',
    CHARTS: 'Chart',
};

export const filterOptions: { [key: string]: FilterType } = {
    NONE: 'DO_NOT_FILTER',
    UNIQUE: 'FILTER_BY_UNIQUE',
    WHERE: 'FILTER_BY_WHERE',
};

export const COMPONENT_CONFIGURATION_OPTIONS: { [key: string]: string } = {
    NAME: 'NAME',
    LABEL: 'LABEL',
    CONTENT: 'CONTENT',
    IMAGE_URI: 'IMAGE_URI',
    SOURCE: 'SOURCE',
    FILENAME: 'FILENAME',
    WIDTH: 'WIDTH',
    HEIGHT: 'HEIGHT',
    EDITABLE: 'EDITABLE',
    MULTISELECT: 'MULTISELECT',
    AUTO_UPLOAD: 'AUTO_UPLOAD',
    SORTABLE_COLUMNS: 'SORTABLE_COLUMNS',
    EXPORT_CSV_BUTTON: 'EXPORT_CSV_BUTTON',
    CSV_FILE_NAME: 'CSV_FILE_NAME',
    SEARCHABLE: 'SEARCHABLE',
    SEARCHABLEDISPLAYCOLUMNS: 'SEARCHABLEDISPLAYCOLUMNS',
    STATE_VALUE: 'STATE_VALUE',
    DATA_SOURCE_LIST: 'DATA_SOURCE_LIST',
    DATA_SOURCE_LIST_VALUE: 'DATA_SOURCE_LIST_VALUE',
    DATA_SOURCE_CONNECTOR: 'DATA_SOURCE_CONNECTOR',
    DATA_SOURCE_CONNECTOR_TYPE: 'DATA_SOURCE_CONNECTOR_TYPE',
    DATA_SOURCE_CONNECTOR_BINDING: 'DATA_SOURCE_CONNECTOR_BINDING',
    DATA_SOURCE_FILTER: 'DATA_SOURCE_FILTER',
    DATA_SOURCE_2: 'DATA_SOURCE_2',
    DATA_PRESENTATION: 'DATA_PRESENTATION',
    FILE_DATA_SOURCE: 'FILE_DATA_SOURCE',
    HELP_INFO: 'HELP_INFO',
    HINT_VALUE: 'HINT_VALUE',
    EDITABLE_COLUMNS: 'EDITABLE_COLUMNS',
    COLUMN_COMPONENT_TYPE: 'COLUMN_COMPONENT_TYPE',
    PIN_COLUMNS: 'PIN_COLUMNS',
};
