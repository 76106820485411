import classnames from 'classnames';
import {
    type ForwardedRef,
    type ReactNode,
    type RefObject,
    forwardRef,
    useEffect,
    useRef,
} from 'react';
import { createPortal } from 'react-dom';
import '../../../../../css/modal.less';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';
import ModalHeader from './ModalHeader';

interface Props {
    renderHeader?: () => ReactNode;
    title?: string;
    onHide: () => void;
    headerClassName?: string;
    renderBody?: () => ReactNode;
    bodyClassName?: string;
    renderFooter?: () => ReactNode;
    footerClassName?: string;
    backdropClassName?: string;
    container?: HTMLElement | null | undefined;
    dialogClassName?: string;
    backdrop?: boolean;
    className?: string;
    animation?: boolean;
    show?: boolean;
    children?: ReactNode;
    ref?: RefObject<HTMLElement>;
}

const GenericModal = forwardRef(function GenericModal(
    {
        // Header props
        renderHeader,
        title = '',
        onHide,
        headerClassName,
        // Body props
        renderBody,
        bodyClassName,
        // Footer props
        renderFooter,
        footerClassName,
        // Backdrop props
        backdropClassName,
        // Modal props
        container,
        dialogClassName,
        className,
        show = true,
        children,
    }: Props,
    ref: ForwardedRef<HTMLDivElement>,
) {
    const dialogRef = useRef<HTMLDialogElement>(null);

    const defaultContainer = document.getElementById('generic-modal');
    const modalContainer = container ?? defaultContainer;

    useEffect(() => {
        const focusCurrentModal = () => {
            if (!(show && modalContainer)) {
                return;
            }

            const dialog = dialogRef.current;
            if (!dialog || dialog.contains(document.activeElement)) {
                return;
            }

            dialog.focus();
        };

        focusCurrentModal();
    }, [show, modalContainer]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const focusOtherModal = () => {
            const otherModals = document.querySelectorAll<HTMLElement>(
                '.admin.active .modal-backdrop',
            );
            if (otherModals.length === 0) {
                return;
            }

            const latestModal = otherModals[otherModals.length - 1];
            if (latestModal.contains(document.activeElement)) {
                return;
            }

            latestModal.focus();
        };

        return focusOtherModal;
    }, [show]);

    if (!show || modalContainer === null) {
        return null;
    }

    return createPortal(
        <div
            className={classnames('modal-backdrop', backdropClassName)}
            ref={ref}
            onKeyDown={(e) => {
                if (show && e.code === 'Escape') {
                    onHide?.();
                    e.stopPropagation();
                }
            }}
            tabIndex={-1}
        >
            <dialog
                className={classnames('modal-dialog', dialogClassName, className)}
                ref={dialogRef}
                tabIndex={-1}
                open
            >
                {(renderHeader || title) && (
                    <ModalHeader className={headerClassName} onHide={onHide}>
                        {renderHeader?.() ?? <h4 className="modal-title">{title}</h4>}
                    </ModalHeader>
                )}
                {renderBody && <ModalBody className={bodyClassName}>{renderBody()}</ModalBody>}
                {children}
                {renderFooter && (
                    <ModalFooter className={footerClassName}>{renderFooter()}</ModalFooter>
                )}
            </dialog>
        </div>,
        modalContainer,
    );
});

export default GenericModal;
