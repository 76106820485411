import { useEffect, useRef, useState } from 'react';
import Sortable from '../../generic/Sortable';
import translations from '../../../../ts/translations';
import PageHeader from '../../generic/PageHeader';
import { SYSTEM_VALUE_IDS, SYSTEM_VALUE_NAMES, CONTENT_TYPES_LIST } from '../../../constants';
import { useValues } from './ValuesProvider';
import { Check, Trash } from '@phosphor-icons/react';
import ComponentWithTooltip from '../../../../ts/components/generic/tooltip/ComponentWithTooltip';
import Table, { type TableColumnList } from '../../../../ts/components/generic/Table';
import type { OrderDirection, ValueElementResponseAPI } from '../../../types';
import ConfirmModal from '../../generic/modal/ConfirmModal';
import { isTooltipRequired } from '../../../utils/display';
import { stringReplace } from '../../../utils/string';

interface CellProps {
    item: ValueElementResponseAPI;
}

const ValuesList = () => {
    const [deleteModalIsVisible, setDeleteModalIsVisible] = useState(false);

    const {
        values,
        fetchValues,
        valuesLoading,
        editValue,
        createValue,
        valueToDelete,
        setValueToDelete,
        deleteValue,
        paging,
        addNotification,
    } = useValues();

    const { pageSize, page, total } = paging;

    const modalContainerRef = useRef<HTMLDivElement | null>(null);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        fetchValues(paging);
    }, []);

    const onRefresh = () => {
        fetchValues(paging);
    };

    const onSort = ({
        orderBy,
        orderDirection,
    }: {
        orderBy: string;
        orderDirection: OrderDirection;
    }) => {
        fetchValues({
            ...paging,
            page: 1,
            orderBy,
            orderDirection,
        });
    };

    const onPage = (page: number) => {
        fetchValues({
            ...paging,
            page,
        });
    };

    const onFilter = (search: string) => {
        fetchValues({
            ...paging,
            page: 1,
            search,
        });
    };

    const onDelete = (value: ValueElementResponseAPI) => {
        if (SYSTEM_VALUE_IDS.includes(value.id)) {
            addNotification({
                type: 'error',
                message: 'Deleting system values is not allowed.',
                isPersistent: true,
            });
        } else {
            setValueToDelete(value);
            setDeleteModalIsVisible(true);
        }
    };

    const columns = [
        {
            renderHeader: () => (
                <Sortable
                    direction={paging.orderBy === 'developerName' ? paging.orderDirection : null}
                    onSort={(orderDirection) =>
                        onSort({ orderBy: 'developerName', orderDirection })
                    }
                >
                    {translations.COMMON_TABLE_name}
                </Sortable>
            ),
            renderCell: ({ item }: CellProps) =>
                isTooltipRequired(item.developerName, 25) ? (
                    <ComponentWithTooltip
                        trigger={['hover', 'focus']}
                        showDelay={500}
                        fadeTime={0.2}
                        tooltipPlacement="top"
                        tooltipContent={item.developerName}
                        wrapperClass="width-expand-to-full"
                    >
                        <button
                            className="link-emulate overflow-ellipsis"
                            onClick={() => editValue(item)}
                            type="button"
                        >
                            {item.developerName}
                        </button>
                    </ComponentWithTooltip>
                ) : (
                    <button
                        className="link-emulate overflow-ellipsis"
                        onClick={() => editValue(item)}
                        title={`Edit ${item.developerName}`}
                        aria-label={`Edit ${item.developerName}`}
                        type="button"
                    >
                        {item.developerName}
                    </button>
                ),
        },
        {
            renderHeader: () => translations.COMMON_TABLE_kind,
            renderCell: ({ item }: CellProps) => (
                <div>
                    {
                        CONTENT_TYPES_LIST.find(
                            (contentType) => item.contentType === contentType.key,
                        )?.label
                    }
                </div>
            ),
            size: '11rem',
        },
        {
            renderHeader: () => 'Secret',
            renderCell: ({ item }: CellProps) => (
                <span className="block full-width text-center table-icon-medium">
                    {item.isSecret ? <Check /> : null}
                </span>
            ),
            size: '4rem',
        },
        {
            renderHeader: () => (
                <Sortable
                    defaultDirection={'ASC'}
                    direction={paging.orderBy === 'dateModified' ? paging.orderDirection : null}
                    onSort={(orderDirection) => onSort({ orderBy: 'dateModified', orderDirection })}
                >
                    {translations.COMMON_TABLE_last_modified}
                </Sortable>
            ),
            renderCell: ({ item }: CellProps) =>
                SYSTEM_VALUE_IDS.includes(item.id)
                    ? null
                    : new Date(item.dateModified).toLocaleString(undefined, {
                          dateStyle: 'medium',
                          timeStyle: 'short',
                      }),
            size: '11rem',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_type,
            renderCell: ({ item }: CellProps) =>
                isTooltipRequired(item.typeElementDeveloperName || '', 25) ? (
                    <ComponentWithTooltip
                        trigger={['hover', 'focus']}
                        showDelay={500}
                        fadeTime={0.2}
                        tooltipPlacement="top"
                        tooltipContent={item.developerName}
                        wrapperClass="width-expand-to-full"
                    >
                        <div className="overflow-ellipsis">{item.typeElementDeveloperName}</div>
                    </ComponentWithTooltip>
                ) : (
                    <div>{item.typeElementDeveloperName}</div>
                ),
        },
        {
            renderHeader: () => translations.COMMON_TABLE_summary,
            renderCell: ({ item }: CellProps) => <div>{item.developerSummary}</div>,
        },
        {
            renderHeader: () => translations.COMMON_TABLE_actions,
            renderCell: ({ item }: CellProps) =>
                SYSTEM_VALUE_NAMES.includes(item.developerName) ? (
                    // Need to maintain row height that the buttons provide.
                    <div className="table-icon" />
                ) : (
                    <div className="action-btn-wrapper">
                        <button
                            title={`Delete ${item.developerName}`}
                            className="table-icon table-icon-delete"
                            aria-label={`Delete ${item.developerName}`}
                            onClick={() => onDelete(item)}
                            type="button"
                        >
                            <Trash />
                        </button>
                    </div>
                ),
            size: '5rem',
        },
    ].filter((column) => !!column) as TableColumnList<ValueElementResponseAPI>;

    return (
        <div className="admin-page flow-wrapper" ref={modalContainerRef}>
            <ConfirmModal
                show={deleteModalIsVisible}
                title={translations.VALUE_delete_confirmation_title}
                messages={[
                    stringReplace(translations.VALUE_delete_confirmation_message, {
                        valueName: valueToDelete?.developerName ?? '',
                    }),
                    translations.GENERAL_cannot_be_undone,
                ]}
                buttonStyle="danger"
                buttonCaption="Delete"
                onCancel={() => setDeleteModalIsVisible(false)}
                onConfirm={() => {
                    deleteValue();
                    setDeleteModalIsVisible(false);
                }}
                container={modalContainerRef.current}
            />

            <PageHeader
                title="Values"
                onAdd={createValue}
                onRefresh={onRefresh}
                onSearch={onFilter}
                searchQuery={paging.search}
                searchPlaceholderText="Search"
                addText="New Value"
                refreshTitle={translations.COMMON_refresh_results}
            />

            <Table
                wrapperClassName="margin-top"
                columns={columns}
                items={values}
                isLoading={valuesLoading}
                pagination={{
                    page,
                    total,
                    pageSize,
                    changePage: onPage,
                }}
            />
        </div>
    );
};

export default ValuesList;
