import { type ReactNode, createContext, useCallback, useContext, useState } from 'react';
import { type ConnectedProps, connect } from 'react-redux';
import { notifyError, notifySuccess } from '../../../js/actions/reduxActions/notification';
import type { OrganizationInvite } from '../../types/organization';
import * as organizationSource from '../../sources/organization';
import translations from '../../translations';
import { useAuth } from '../AuthProvider';

type OrganizationInviteContext = {
    isLoading: boolean;
    receivedUserInvites: OrganizationInvite[];
    receivedTenantInvites: OrganizationInvite[];
    sentUserInvites: OrganizationInvite[];
    sentTenantInvites: OrganizationInvite[];
    loadReceivedTenantInvites: () => Promise<void>;
    loadSentTenantInvites: () => Promise<void>;
    loadReceivedUserInvites: () => Promise<void>;
    loadSentUserInvites: () => Promise<void>;
    acceptTenantInvite: (organizationId: string) => Promise<void>;
    acceptUserInvite: (organizationId: string) => Promise<void>;
    sendTenantInvite: (developerName?: string, id?: string) => Promise<void>;
    sendUserInvite: (email?: string, id?: string) => Promise<void>;
    cancelTenantInvite: (invitedTenantId: string) => Promise<void>;
    cancelUserInvite: (invitedUserId: string) => Promise<void>;
    rejectTenantInvite: (organizationId: string) => Promise<void>;
    rejectUserInvite: (organizationId: string) => Promise<void>;
    acknowledgeRejectedTenantInvite: (invitedTenantId: string) => Promise<void>;
    acknowledgeRejectedUserInvite: (invitedUserId: string) => Promise<void>;
};

type OrganizationInviteProviderProps = {
    children?: ReactNode;
} & ConnectedProps<typeof connector>;

const OrganizationInviteContext = createContext<OrganizationInviteContext | null>(null);

const mapDispatchToProps = {
    notifyError,
    notifySuccess,
};

const connector = connect(null, mapDispatchToProps);

const OrganizationInviteProvider = ({
    children,
    notifyError,
    notifySuccess,
}: OrganizationInviteProviderProps) => {
    const [receivedUserInvites, setReceivedUserInvites] = useState<OrganizationInvite[]>([]);
    const [receivedTenantInvites, setReceivedTenantInvites] = useState<OrganizationInvite[]>([]);
    const [sentUserInvites, setSentUserInvites] = useState<OrganizationInvite[]>([]);
    const [sentTenantInvites, setSentTenantInvites] = useState<OrganizationInvite[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const { fetchUser } = useAuth();

    const loadReceivedTenantInvites = useCallback(async () => {
        try {
            setIsLoading(true);

            const invites = await organizationSource.getReceivedTenantInvites();

            setReceivedTenantInvites(invites);
        } catch (error) {
            notifyError(error);
        } finally {
            setIsLoading(false);
        }
    }, [notifyError]);

    const loadSentTenantInvites = useCallback(async () => {
        try {
            setIsLoading(true);

            const invites = await organizationSource.getSentTenantInvites();

            setSentTenantInvites(invites);
        } catch (error) {
            notifyError(error);
        } finally {
            setIsLoading(false);
        }
    }, [notifyError]);

    const loadReceivedUserInvites = useCallback(async () => {
        try {
            setIsLoading(true);

            const invites = await organizationSource.getReceivedUserInvites();

            setReceivedUserInvites(invites);
        } catch (error) {
            notifyError(error);
        } finally {
            setIsLoading(false);
        }
    }, [notifyError]);

    const loadSentUserInvites = useCallback(async () => {
        try {
            setIsLoading(true);

            const invites = await organizationSource.getSentUserInvites();

            setSentUserInvites(invites);
        } catch (error) {
            notifyError(error);
        } finally {
            setIsLoading(false);
        }
    }, [notifyError]);

    const acceptTenantInvite = useCallback(
        async (organizationId: string) => {
            try {
                setIsLoading(true);

                await organizationSource.acceptTenantInvite(organizationId);

                await fetchUser();
            } catch (error) {
                notifyError(error);
            } finally {
                setIsLoading(false);
            }
        },
        [fetchUser, notifyError],
    );

    const acceptUserInvite = useCallback(
        async (organizationId: string) => {
            try {
                setIsLoading(true);

                await organizationSource.acceptUserInvite(organizationId);

                await fetchUser();
            } catch (error) {
                notifyError(error);
            } finally {
                setIsLoading(false);
            }
        },
        [fetchUser, notifyError],
    );

    const sendTenantInvite = useCallback(
        async (developerName?: string, id?: string) => {
            try {
                setIsLoading(true);

                await organizationSource.sendTenantInvite(developerName, id);

                await loadSentTenantInvites();

                notifySuccess(translations.FORG_tenant_invite_send_success_message);
            } catch (error) {
                notifyError(error);
            } finally {
                setIsLoading(false);
            }
        },
        [loadSentTenantInvites, notifyError, notifySuccess],
    );

    const sendUserInvite = useCallback(
        async (email?: string, id?: string) => {
            try {
                setIsLoading(true);

                await organizationSource.sendUserInvite(email, id);

                await loadSentUserInvites();

                notifySuccess(translations.FORG_user_invite_send_success_message);
            } catch (error) {
                notifyError(error);
            } finally {
                setIsLoading(false);
            }
        },
        [loadSentUserInvites, notifyError, notifySuccess],
    );

    const cancelTenantInvite = useCallback(
        async (invitedTenantId: string) => {
            try {
                setIsLoading(true);

                await organizationSource.cancelTenantInvite(invitedTenantId);

                await loadSentTenantInvites();
            } catch (error) {
                notifyError(error);
            } finally {
                setIsLoading(false);
            }
        },
        [loadSentTenantInvites, notifyError],
    );

    const cancelUserInvite = useCallback(
        async (invitedUserId: string) => {
            try {
                setIsLoading(true);

                await organizationSource.cancelUserInvite(invitedUserId);

                await loadSentUserInvites();
            } catch (error) {
                notifyError(error);
            } finally {
                setIsLoading(false);
            }
        },
        [loadSentUserInvites, notifyError],
    );

    const rejectTenantInvite = useCallback(
        async (organizationId: string) => {
            try {
                setIsLoading(true);

                await organizationSource.rejectTenantInvite(organizationId);

                await loadReceivedTenantInvites();
            } catch (error) {
                notifyError(error);
            } finally {
                setIsLoading(false);
            }
        },
        [loadReceivedTenantInvites, notifyError],
    );

    const rejectUserInvite = useCallback(
        async (organizationId: string) => {
            try {
                setIsLoading(true);

                await organizationSource.rejectUserInvite(organizationId);

                await loadReceivedUserInvites();
            } catch (error) {
                notifyError(error);
            } finally {
                setIsLoading(false);
            }
        },
        [loadReceivedUserInvites, notifyError],
    );

    const acknowledgeRejectedTenantInvite = useCallback(
        async (invitedTenantId: string) => {
            try {
                setIsLoading(true);

                await organizationSource.acknowledgeRejectedTenantInvite(invitedTenantId);

                await loadSentTenantInvites();
            } catch (error) {
                notifyError(error);
            } finally {
                setIsLoading(false);
            }
        },
        [loadSentTenantInvites, notifyError],
    );

    const acknowledgeRejectedUserInvite = useCallback(
        async (invitedUserId: string) => {
            try {
                setIsLoading(true);

                await organizationSource.acknowledgeRejectedUserInvite(invitedUserId);

                await loadSentUserInvites();
            } catch (error) {
                notifyError(error);
            } finally {
                setIsLoading(false);
            }
        },
        [loadSentUserInvites, notifyError],
    );

    const contextValue: OrganizationInviteContext = {
        isLoading,
        receivedUserInvites,
        receivedTenantInvites,
        sentTenantInvites,
        sentUserInvites,
        loadReceivedTenantInvites,
        loadSentTenantInvites,
        loadReceivedUserInvites,
        loadSentUserInvites,
        acceptTenantInvite,
        acceptUserInvite,
        sendTenantInvite,
        sendUserInvite,
        cancelTenantInvite,
        cancelUserInvite,
        rejectTenantInvite,
        rejectUserInvite,
        acknowledgeRejectedTenantInvite,
        acknowledgeRejectedUserInvite,
    };

    return (
        <OrganizationInviteContext.Provider value={contextValue}>
            {children}
        </OrganizationInviteContext.Provider>
    );
};

export const useOrganizationInvite = () => {
    const context = useContext(OrganizationInviteContext);
    if (!context) {
        throw new Error('useOrganizationContext must be used within an OrganizationInvite context');
    }
    return context;
};

export default connector(OrganizationInviteProvider);
