import type { Point } from '../../../types';

export type Bound = Point & { width: number; height: number };

export const calculateSVGPositionFromXY = (svgElement: SVGSVGElement, { x, y }: Point): Point => {
    const point = svgElement.createSVGPoint();
    point.x = x;
    point.y = y;
    const screenCTM = svgElement.getScreenCTM();
    const invertedCTM = screenCTM?.inverse();
    const transformedPoint = point.matrixTransform(invertedCTM);
    return {
        x: transformedPoint.x,
        y: transformedPoint.y,
    };
};

export const positionsAreEqual = (pointA: Point | null, pointB: Point | null) => {
    if (pointA === null || pointB === null) {
        return false;
    }
    return pointA.x === pointB.x && pointA.y === pointB.y;
};

export const calculateIfPointIsWithinBounds = ({
    point: { x: pointX, y: pointY },
    bounds: { x: boundsX, y: boundsY, width: boundsWidth, height: boundsHeight },
}: { point: Point; bounds: Bound }): boolean => {
    // If the point's x coordinate lies with the bound's horizontal area
    // │ ●     │
    // and the point's y coordinate lies with the bound's vertical area,
    //  ───────
    //   ●
    //  ───────
    // then the point is within the bound
    // ┌───────┐
    // │ ●     │
    // └───────┘
    if (
        // Check if the point is inside the bounds horizontally
        pointX >= boundsX &&
        pointX <= boundsX + boundsWidth &&
        // Check if the point is inside the bounds vertically
        pointY >= boundsY &&
        pointY <= boundsY + boundsHeight
    ) {
        return true;
    }
    return false;
};

export const calculateIfBoundIsWithinAnotherBounds = ({
    innerBound: { x: boundsX, y: boundsY, width: boundsWidth, height: boundsHeight },
    outerBound,
}: { innerBound: Bound; outerBound: Bound }) => {
    // If the top left and bottom right corners of the inside bound are within the outside bound,
    // ┌───────┐
    // │ ●──●  │
    // │ │  │  │
    // │ ●──●  │
    // └───────┘
    // then the inner object is completely within the outer bound
    if (
        // Top left corner check
        calculateIfPointIsWithinBounds({
            point: { x: boundsX, y: boundsY },
            bounds: outerBound,
        }) &&
        // Bottom right corner check
        calculateIfPointIsWithinBounds({
            point: { x: boundsX + boundsWidth, y: boundsY + boundsHeight },
            bounds: outerBound,
        })
    ) {
        return true;
    }
    return false;
};
