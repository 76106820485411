import ButtonDefault from '../../../../buttons/ButtonDefault';
import { CRUD_OPERATION_TYPES } from '../constants';
import Glyphicon from '../../../../generic/Glyphicon';
import translations from '../../../../../translations';

import { useDataAction } from '../../contextProviders/DataActionProvider';

type Props = {
    typeElementId: string | null | undefined;
};

const FilterButton = ({ typeElementId }: Props) => {
    const { dataActionToEdit, onEditDataActionFilter } = useDataAction();
    const { dataAction } = dataActionToEdit;
    const shouldDisplay =
        typeElementId && dataAction?.crudOperationType === CRUD_OPERATION_TYPES['load'];

    if (shouldDisplay) {
        return (
            <div className="margin-bottom flex">
                <ButtonDefault title="Edit Filter" onClick={onEditDataActionFilter}>
                    <Glyphicon glyph="edit" />
                    {translations.DATA_ACTION_edit_filter_button_text}
                </ButtonDefault>
            </div>
        );
    }

    return null;
};

export default FilterButton;
