import MapElementModal from '../../../graph/MapElementModal';
import { MAP_ELEMENT_TYPES } from '../../../../constants';
import {
    type MapElementConfigProps,
    MapElementProvider,
} from '../contextProviders/MapElementProvider';
import SubFlowRouter from './SubFlowRouter';

/**
 * Handles switching between the subflow configuration screens
 */
const SubFlow = (props: MapElementConfigProps) => {
    return (
        <MapElementProvider
            {...props}
            defaultScreen={'subflow'}
            elementType={MAP_ELEMENT_TYPES.subflow}
            isLoading={false}
        >
            <MapElementModal>
                <SubFlowRouter />
            </MapElementModal>
        </MapElementProvider>
    );
};

export default SubFlow;
