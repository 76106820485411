import FormGroup from '../../../generic/FormGroup';
import IdentityProviderAttributeMappings from '../IdentityProviderAttributeMappings';
import Toggle from '../../../inputs/Toggle';
import type { OAuth2GrantType, OAUTH2IdentityProviderAPI } from '../../../../types';
import Select, { type MultiValue } from 'react-select';
import { isNullOrEmpty } from '../../../../utils/guard';
import { getSharedStyles } from '../../../../utils';

interface Props {
    item: OAUTH2IdentityProviderAPI;
    updateItem: (item: Partial<OAUTH2IdentityProviderAPI>) => void;
    hasSubmitted: boolean;
}

interface GrantTypeOption {
    label: string;
    value: OAuth2GrantType;
}

const IdentityProviderOAUTH2 = ({ item, updateItem, hasSubmitted }: Props) => {
    const onAttributeMappingChange = (name: string, value: string) => {
        updateItem({
            attributeMappings: {
                ...item.attributeMappings,
                [name]: value,
            },
        });
    };

    const onGrantTypesChange = (grantTypes: MultiValue<GrantTypeOption>) => {
        updateItem({
            grantTypes: grantTypes.map((option) => option.value),
        });
    };

    const grantTypeOptions: GrantTypeOption[] = [
        { label: 'Authorization Code', value: 'AuthorizationCode' },
        { label: 'Client Credentials', value: 'ClientCredentials' },
    ];

    const selectedGrantTypes = item.grantTypes
        ? grantTypeOptions.filter((option) => item.grantTypes?.includes(option.value))
        : [grantTypeOptions[0]];
    if (!item.grantTypes) {
        onGrantTypesChange(selectedGrantTypes);
    }

    return (
        <>
            <FormGroup
                label="Client ID"
                htmlFor="identity-provider-client-id"
                isRequired
                validationMessage="Client ID field is required"
                isValid={!isNullOrEmpty(item.clientId)}
                showValidation={hasSubmitted}
            >
                <input
                    id="identity-provider-client-id"
                    className="form-control form-control-dynamic"
                    value={item.clientId}
                    onChange={(e) => updateItem({ clientId: e.target.value })}
                    type="text"
                />
            </FormGroup>

            <FormGroup label="Client Secret" htmlFor="identity-provider-client-secret">
                <input
                    id="identity-provider-client-secret"
                    className="form-control form-control-dynamic"
                    value={item.clientSecret}
                    onChange={(e) => updateItem({ clientSecret: e.target.value })}
                    type="password"
                />
                <span className="help-block">
                    Previously added client secret keys are not shown in this field
                </span>
            </FormGroup>

            <FormGroup
                label="Well Known URL"
                htmlFor="identity-provider-well-known-url"
                isRequired
                validationMessage="Well Known URL field is required"
                isValid={!isNullOrEmpty(item.wellKnownUrl)}
                showValidation={hasSubmitted}
            >
                <input
                    id="identity-provider-well-known-url"
                    className="form-control form-control-long"
                    value={item.wellKnownUrl}
                    onChange={(e) => updateItem({ wellKnownUrl: e.target.value })}
                    type="url"
                />
            </FormGroup>

            <FormGroup
                label="Allowed Audience"
                htmlFor="identity-provider-allowed-audience"
                isRequired
                validationMessage="Allowed audience field is required"
                isValid={!isNullOrEmpty(item.allowedAudience)}
                showValidation={hasSubmitted}
            >
                <input
                    id="identity-provider-allowed-audience"
                    className="form-control form-control-dynamic"
                    value={item.allowedAudience}
                    onChange={(e) => updateItem({ allowedAudience: e.target.value })}
                    type="text"
                />
                <span className="help-block">
                    The audience for the access token, this must match the &quot;aud&quot; claim in
                    the token
                </span>
            </FormGroup>

            <FormGroup
                label="Scope"
                htmlFor="identity-provider-scope"
                isRequired
                validationMessage="Scope field is required"
                isValid={!isNullOrEmpty(item.scope)}
                showValidation={hasSubmitted}
            >
                <input
                    id="identity-provider-scope"
                    className="form-control form-control-dynamic"
                    value={item.scope}
                    onChange={(e) => updateItem({ scope: e.target.value })}
                    type="text"
                />
            </FormGroup>

            <FormGroup label="Resource" htmlFor="identity-provider-resource">
                <input
                    id="identity-provider-resource"
                    className="form-control form-control-dynamic"
                    value={item.resource}
                    onChange={(e) => updateItem({ resource: e.target.value })}
                    type="text"
                />
            </FormGroup>

            <FormGroup
                label="Allowed Grant Types"
                htmlFor="identity-provider-grant-types"
                isRequired
                validationMessage="Allowed Grant Types field is required"
                isValid={!isNullOrEmpty(item.grantTypes)}
                showValidation={hasSubmitted}
            >
                <Select
                    inputId="identity-provider-grant-types"
                    name="Allowed Grant Types"
                    isMulti={true}
                    options={grantTypeOptions}
                    value={selectedGrantTypes}
                    onChange={onGrantTypesChange}
                    styles={getSharedStyles<(typeof grantTypeOptions)[number], true>()}
                />
            </FormGroup>

            <FormGroup>
                <label htmlFor="send-access-token">
                    <Toggle
                        id="send-access-token"
                        testId="send-access-token"
                        isOn={item.sendAccessTokenToConnectors ?? false}
                        onChange={({ isOn }) => updateItem({ sendAccessTokenToConnectors: isOn })}
                    />
                    Send Access Token to Connectors
                </label>
            </FormGroup>

            <IdentityProviderAttributeMappings
                item={item}
                header="Claim Name"
                onChange={onAttributeMappingChange}
            />

            <FormGroup label="Comments" htmlFor="identity-provider-comments">
                <textarea
                    id="identity-provider-comments"
                    className="form-control form-control-textarea"
                    value={item.developerSummary ?? ''}
                    onChange={(e) => updateItem({ developerSummary: e.target.value })}
                    placeholder="Comments about this Identity Provider"
                />
            </FormGroup>
        </>
    );
};

export default IdentityProviderOAUTH2;
