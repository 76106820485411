import type { DispatchFlowConfiguration } from './reducer';

interface Props {
    allowJumping: boolean;
    dispatchFlowConfiguration: DispatchFlowConfiguration;
}

const Navigation = ({ allowJumping, dispatchFlowConfiguration }: Props) => {
    return (
        <>
            <h2>Navigation</h2>
            <label htmlFor="properties-allow-jumping">
                <input
                    id="properties-allow-jumping"
                    className="checkbox-margin-left"
                    type="checkbox"
                    checked={allowJumping}
                    onChange={({ target }) => {
                        dispatchFlowConfiguration({
                            type: 'updateAllowJumping',
                            payload: { isEnabled: target.checked },
                        });
                    }}
                />
                Allow the user to move to any Map Element location in the Flow
            </label>
        </>
    );
};

export default Navigation;
