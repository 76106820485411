import type { ChangeEvent } from 'react';
import type { NavigationElement } from '../utils';

type Props = {
    navigationElement: NavigationElement;
    setNavigationElement: (navigationElement: NavigationElement) => void;
};

const Comments = ({ navigationElement, setNavigationElement }: Props) => {
    const handleCommentChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setNavigationElement({
            ...navigationElement,
            developerSummary: e.target.value,
        });
    };

    return (
        <>
            <label htmlFor="comments">Comments</label>
            <textarea
                maxLength={255}
                className="form-control comments"
                rows={5}
                value={navigationElement.developerSummary || ''}
                onChange={handleCommentChange}
                id="comments"
                data-testid="comment-input"
            />
        </>
    );
};

export default Comments;
