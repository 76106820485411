import { useEffect, type RefObject } from 'react';

export const useScrollSpy = <T extends Element | null>(
    contentRef: RefObject<T>,
    hasMenu: boolean,
    setActiveSectionId: (id: string | null) => void,
) => {
    useEffect(() => {
        if (typeof IntersectionObserver !== 'function' || !hasMenu || !contentRef.current) {
            return;
        }

        const contentSectionElements = Array.from(contentRef.current.querySelectorAll('h2[id]'));

        if (!contentSectionElements || contentSectionElements.length === 0) {
            return;
        }

        // handler will be executed when a section enters the view when scrolling up or down
        // (may produce jumps in the side-menu highlights but that is normal if we have
        // multiple smaller sections fully visible at the same time since the observer
        // triggers on intersection change (enter/leave) and not pixel position in the view)
        const observerHandler: IntersectionObserverCallback = (observedItems) => {
            if (observedItems[0].isIntersecting && observedItems[0].intersectionRatio === 1) {
                setActiveSectionId(observedItems[0].target.getAttribute('id'));
            }
        };

        const observer = new IntersectionObserver(observerHandler, {
            root: contentRef.current, // closest scrollable parent element/container of the observed item
            rootMargin: '1px', // margin around root considered when calculating intersections/visibility
            threshold: 1, // at least 100% of an item needs to be hidden/visible to trigger the handler
        });

        // tell the observer to watch the sections
        contentSectionElements.forEach((section) => observer.observe(section));

        // disconnect existing observer when current page is closed or
        // switched away from
        return () => observer.disconnect();
    });
};
