import { useState } from 'react';
import translations from '../../../../translations';
import FormGroup from '../../../generic/FormGroup';
import DataActions from '../common/dataActions/DataActions';
import ListenerList from '../common/listeners/ListenerList';
import NameInput from '../common/NameInput';
import OutcomeList from '../common/outcomes/OutcomeList';
import { useMapElement } from '../contextProviders/MapElementProvider';
import ModalBody from '../../../generic/modal/ModalBody';
import ModalFooter from '../../../generic/modal/ModalFooter';
import { isNullOrEmpty } from '../../../../utils/guard';
import Footer from '../common/Footer';

const SaveConfiguration = () => {
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const { mapElement, onUpdateName, onUpdateComments, onSaveMapElement, onClose } =
        useMapElement();

    const { developerName, developerSummary } = mapElement;

    const isSaveElementNameValid = !isNullOrEmpty(developerName);

    const onSave = () => {
        setHasSubmitted(true);
        if (isSaveElementNameValid) {
            onSaveMapElement(mapElement);
        }
    };

    const renderBody = () => (
        <>
            <NameInput
                isValid={isSaveElementNameValid}
                showValidation={hasSubmitted}
                id="save-element-name"
                name={mapElement.developerName}
                onUpdateName={onUpdateName}
            />
            <DataActions />
            <OutcomeList />
            <ListenerList />
            <FormGroup htmlFor="save-element-comments" label="Comments about this Database Save">
                <textarea
                    id="save-element-comments"
                    className="form-control form-textarea"
                    value={developerSummary ?? ''}
                    onChange={(e) => onUpdateComments(e.target.value)}
                />
            </FormGroup>
        </>
    );

    const renderFooter = () => {
        return (
            <Footer
                save={onSave}
                saveButtonText={translations.GRAPH_config_panel_save}
                cancel={onClose}
            />
        );
    };

    return (
        <>
            <ModalBody>{renderBody()}</ModalBody>
            <ModalFooter>{renderFooter()}</ModalFooter>
        </>
    );
};

export default SaveConfiguration;
