import type { CustomIconProps } from '../../types';

const OpenInNewWindow = (props: CustomIconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
        <title>Opens in a new window</title>
        <path d="M12.29,9.36v2.7a2.37,2.37,0,0,1-.7,1.73,2.31,2.31,0,0,1-1.71.71h-7a2.31,2.31,0,0,1-1.71-.71,2.37,2.37,0,0,1-.7-1.73V5A2.35,2.35,0,0,1,1.2,3.3a2.37,2.37,0,0,1,1.71-.73H8.81A.25.25,0,0,1,9,2.65a.27.27,0,0,1,.08.2V3.4a.27.27,0,0,1-.08.2.24.24,0,0,1-.18.08H2.91A1.28,1.28,0,0,0,2,4.06a1.36,1.36,0,0,0-.4,1v7A1.28,1.28,0,0,0,2,13a1.37,1.37,0,0,0,.95.41h7a1.26,1.26,0,0,0,.95-.41,1.34,1.34,0,0,0,.39-1V9.36a.27.27,0,0,1,.07-.2.26.26,0,0,1,.2-.08H12a.26.26,0,0,1,.2.08A.27.27,0,0,1,12.29,9.36ZM15.5,2V6.37a.54.54,0,0,1-.16.39.46.46,0,0,1-.37.15.64.64,0,0,1-.37-.15l-1.47-1.5L7.65,10.78a.25.25,0,0,1-.39,0l-.95-1a.26.26,0,0,1,0-.39l5.46-5.52L10.29,2.43a.55.55,0,0,1,0-.76.5.5,0,0,1,.39-.17H15a.48.48,0,0,1,.37.17A.62.62,0,0,1,15.5,2Z" />
    </svg>
);

export default OpenInNewWindow;
