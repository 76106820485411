import { type ReactNode, useEffect, useState } from 'react';
import translations from '../../translations';
import FormGroup from '../generic/FormGroup';
import type { UserTenantResponseAPI } from '../../types';
import {
    exportThemeConfig,
    getAllThemeNames,
    getAllThemeNamesForEnvironment,
    importThemeConfig,
} from '../../sources/theme';
import ButtonPrimary from '../buttons/ButtonPrimary';
import FileDrop from '../generic/upload/FileDrop';
import ConfirmModal from '../generic/modal/ConfirmModal';
import { pathOr } from 'ramda';
import { isNullOrUndefined } from '../../utils/guard';

interface Props {
    environmentOptions: ReactNode;
    tenant: UserTenantResponseAPI;
    container: HTMLElement | null | undefined;
    notifyError: (error: string) => void;
    notifySuccess: (message: string) => void;
}

const ThemeImportExport = ({
    environmentOptions,
    tenant,
    container,
    notifyError,
    notifySuccess,
}: Props) => {
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [selectedEnvironment, setSelectedEnvironment] = useState('');
    const [selectedTheme, setSelectedTheme] = useState('');
    const [themes, setThemes] = useState<string[]>([]);
    const [importTheme, setImportTheme] = useState<string | null>();
    const tenantSettings = tenant?.tenantSettings;
    const isEnvironmentsOn = tenantSettings?.environments;
    const isThemeSelected =
        (isEnvironmentsOn && selectedEnvironment !== '' && selectedTheme !== '') ||
        (!isEnvironmentsOn && selectedTheme !== '');
    const themeSelectionAvailable =
        !isEnvironmentsOn || (isEnvironmentsOn && selectedEnvironment !== '');

    useEffect(() => {
        const loadThemes = async () => {
            const themes =
                selectedEnvironment === ''
                    ? await getAllThemeNames()
                    : await getAllThemeNamesForEnvironment(selectedEnvironment);
            setThemes(themes);
        };

        loadThemes();
    }, [selectedEnvironment]);

    const onExport = async () => {
        await exportThemeConfig(selectedTheme);
    };

    const onDrop = (files: File[]) => {
        // Only accept one file at a time for now.
        if (files.length === 1) {
            const reader = new FileReader();
            reader.onloadend = (response) => {
                if (response?.target?.result) {
                    // eslint-disable-next-line @typescript-eslint/no-base-to-string
                    setImportTheme(response.target.result.toString());
                    setShowConfirmation(true);
                }
            };

            if (files[0]) {
                reader.readAsText(files[0]);
            }
        }
    };

    const getNameFromThemeConfig = () => {
        if (isNullOrUndefined(importTheme)) {
            return null;
        }

        try {
            return pathOr(null, ['developerName'], JSON.parse(JSON.parse(importTheme) as string));
        } catch (_error) {
            return null;
        }
    };

    const confirmImport = async () => {
        try {
            if (importTheme) {
                await importThemeConfig(importTheme);
                notifySuccess(translations.THEMES_import_success);
            }
        } catch (error) {
            notifyError((error as Error).message);
        } finally {
            setImportTheme(null);
            setShowConfirmation(false);
        }
    };

    const cancelImport = () => {
        setShowConfirmation(false);
        setImportTheme(null);
    };

    const onEnvironmentSelect = (selectedOption: React.ChangeEvent<HTMLSelectElement>) =>
        setSelectedEnvironment(selectedOption?.target?.value ?? '');

    const environmentSelector = (
        <FormGroup htmlFor="theme-environment-select">
            <select
                onChange={onEnvironmentSelect}
                className="select-flow"
                data-testid="themeEnvironmentSelect"
                id="theme-environment-select"
            >
                {environmentOptions as ReactNode}
            </select>
        </FormGroup>
    );

    const themeOptions =
        themes
            ?.filter((theme) => theme !== 'default')
            .map((theme) => (
                <option value={theme} key={theme}>
                    {theme}
                </option>
            )) ?? [];
    themeOptions.unshift(
        <option value="" key="">
            {translations.THEMES_select}
        </option>,
    );

    const themeSelector = (
        <FormGroup htmlFor="theme-select">
            <select
                onChange={(selectedOption) => setSelectedTheme(selectedOption?.target?.value ?? '')}
                className="select-flow form-control"
                data-testid="themeSelect"
                id="theme-select"
                disabled={!themeSelectionAvailable}
            >
                {themeOptions as ReactNode}
            </select>
        </FormGroup>
    );

    const importedThemeName = getNameFromThemeConfig();

    const importMessage: string =
        isNullOrUndefined(importedThemeName) === false
            ? translations.THEMES_confirm_import_message.replace(
                  '${themeName}',
                  importedThemeName ?? '',
              ).replace('${tenant}', tenant.developerName)
            : translations.THEMES_confirm_import_message_without_name.replace(
                  '${tenant}',
                  tenant.developerName,
              );

    return (
        <>
            <h2>{translations.TENANT_theme_heading}</h2>
            <h4>{translations.THEMES_export_heading}</h4>
            {isEnvironmentsOn && environmentSelector}
            {themeSelector}
            <ButtonPrimary onClick={onExport} disabled={!isThemeSelected}>
                {translations.THEMES_export}
            </ButtonPrimary>
            <h4>{translations.THEMES_import_heading}</h4>
            <FileDrop
                onChange={onDrop}
                placeholder={translations.THEMES_import_placeholder}
                fileTypes=".thme"
            />
            <ConfirmModal
                title={translations.IMPORT_confirm_title}
                messages={[importMessage]}
                onCancel={cancelImport}
                onConfirm={confirmImport}
                show={showConfirmation}
                container={container}
            />
        </>
    );
};

export default ThemeImportExport;
