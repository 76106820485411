import { FileCss, FileJs, ImageSquare, Trash } from '@phosphor-icons/react';
import classNames from 'classnames';
import { remove, update } from 'ramda';
import { useRef, useState } from 'react';
import Select from 'react-select';
import ComponentsDependentModal from './CustomPageComponentsDependentModal';
import { useComponents } from './CustomPageComponentsProvider';
import { CSS_VALID_FILE_TYPES, IMAGE_VALID_FILE_TYPES, JS_VALID_FILE_TYPES } from './constants';
import AssetManagerModal from '../../assets/AssetManagerModal';
import ButtonPrimary from '../../buttons/ButtonPrimary';
import Footer from '../../generic/Footer';
import FormGroup from '../../generic/FormGroup';
import Glyphicon from '../../generic/Glyphicon';
import Table from '../../generic/Table';
import { COMPONENT_CONFIGURATION_LABELS, COMPONENT_TYPE } from '../../page-editor/constants';
import { componentRegistry } from '../../page-editor/registry';
import translations from '../../../translations';
import { isNullOrEmpty, isNullOrWhitespace } from '../../../utils/guard';
import { guid } from '../../../utils/guid';
import { getSharedStyles } from '../../../utils/select';
import { componentConfigurationEditorSectionsWithLabels } from './constants';
import IconPicker from './IconPicker';
import { ExAccordion, ExAccordionItem } from '@boomi/exosphere';

type Attribute = { key: string; value: string; id: string };

const urlValidityCheck = (string: string | null | undefined) => {
    if (!string) {
        return false;
    }

    try {
        const url = new URL(string);
        return url.protocol === 'https:' || url.hostname === 'localhost';
    } catch {
        return false;
    }
};

const leadingOrTrailingWhitespaceCheck = (string: string | null | undefined) => {
    const trimmedString = string?.trim();
    return string === trimmedString;
};

const createExtensionValidationRegex = (extensions: string[]) => {
    const regex = new RegExp(
        extensions.map((extension) => `\\.${extension}$`).join('|'),
        'i', // Ignore case when matching
    );

    return regex;
};

const jsExtensionValidationRegex = createExtensionValidationRegex(JS_VALID_FILE_TYPES);
const cssExtensionValidationRegex = createExtensionValidationRegex(CSS_VALID_FILE_TYPES);
const imageExtensionValidationRegex = createExtensionValidationRegex(IMAGE_VALID_FILE_TYPES);

const ComponentsDetail = (_: { screen: string }) => {
    const {
        editingComponent,
        setEditingComponent,
        setCurrentScreen,
        COMPONENT_SCREENS,
        saveEditingComponent,
    } = useComponents();

    const [assetProperty, setAssetProperty] = useState<string>();
    const [allowedExtensions, setAllowedExtensions] = useState<string[]>([]);
    const [showAssetModal, setShowAssetModal] = useState(false);
    const modalContainerRef = useRef(null);

    const isScriptEmpty = isNullOrEmpty(editingComponent?.scriptURL);
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const nameWhitespaceValidity = leadingOrTrailingWhitespaceCheck(
        editingComponent?.developerName,
    );
    const nameValidity =
        !isNullOrWhitespace(editingComponent?.developerName) && nameWhitespaceValidity;

    const keyWhitespaceValidity = leadingOrTrailingWhitespaceCheck(editingComponent?.key);
    const keyValidity = !isNullOrWhitespace(editingComponent?.key) && keyWhitespaceValidity;

    const jsURLValidity = urlValidityCheck(editingComponent?.scriptURL);
    const jsWhitespaceValidity = leadingOrTrailingWhitespaceCheck(editingComponent?.scriptURL);
    const jsExtensionValidity = jsExtensionValidationRegex.test(editingComponent?.scriptURL || '');
    const jsValidity =
        isScriptEmpty || (jsURLValidity && jsWhitespaceValidity && jsExtensionValidity);

    const cssURLValidity = urlValidityCheck(editingComponent?.styleSheetURL);
    const cssWhitespaceValidity = leadingOrTrailingWhitespaceCheck(editingComponent?.styleSheetURL);
    const cssExtensionValidity = cssExtensionValidationRegex.test(
        editingComponent?.styleSheetURL || '',
    );
    const cssValidity =
        isNullOrEmpty(editingComponent?.styleSheetURL) ||
        (cssURLValidity && cssWhitespaceValidity && cssExtensionValidity);

    const imageURLValidity = urlValidityCheck(editingComponent?.designTimeImageURL);
    const imageWhitespaceValidity = leadingOrTrailingWhitespaceCheck(
        editingComponent?.designTimeImageURL,
    );
    const imageExtensionValidity = imageExtensionValidationRegex.test(
        editingComponent?.designTimeImageURL || '',
    );
    const imageValidity =
        isNullOrEmpty(editingComponent?.designTimeImageURL) ||
        (imageURLValidity && imageWhitespaceValidity && imageExtensionValidity);

    const [attributeDuplicateKeys, setAttributeDuplicateKeys] = useState<string[]>([]);
    const [attributeEmptyKeys, setAttributeEmptyKeys] = useState<string[]>([]);
    const [attributeEmptyLabels, setAttributeEmptyLabels] = useState<string[]>([]);

    const isValid =
        nameValidity &&
        keyValidity &&
        jsValidity &&
        cssValidity &&
        imageValidity &&
        attributeDuplicateKeys.length === 0 &&
        attributeEmptyKeys.length === 0 &&
        attributeEmptyLabels.length === 0;

    const onSubmit = () => {
        setHasSubmitted(true);

        if (isValid && editingComponent) {
            saveEditingComponent();
        }
    };

    const [attributeList, setAttributeList] = useState<Attribute[]>(
        Object.entries(editingComponent?.attributes || {}).map(([key, value]) => ({
            key,
            value,
            id: guid(),
        })),
    );

    const renderPreviewComponent = () => {
        if (editingComponent?.designTimeImageURL && editingComponent?.developerName) {
            return (
                <img
                    className="full-width component-preview"
                    src={editingComponent.designTimeImageURL}
                    alt={editingComponent.developerName}
                />
            );
        }

        if (
            editingComponent?.designTimeRenderType &&
            componentRegistry[editingComponent.designTimeRenderType.toUpperCase()]
        ) {
            const RenderComponent =
                componentRegistry[editingComponent.designTimeRenderType.toUpperCase()].renderFn;

            return (
                <RenderComponent
                    columns={[
                        {
                            typeElementPropertyDeveloperName: 'Column 1',
                            typeElementPropertyId: 1,
                            isDisplayValue: true,
                            label: 'Preview Column',
                        },
                        {
                            typeElementPropertyDeveloperName: 'Column 2',
                            typeElementPropertyId: 2,
                            isDisplayValue: true,
                            label: 'Preview Column',
                        },
                    ]}
                    label="Preview Label"
                    content="<h1>Preview Header</h1><p>Preview Paragraph</p><ul><li>Preview List Item</li></ul>"
                />
            );
        }

        return (
            <span className="full-width component-preview">{editingComponent?.developerName}</span>
        );
    };

    const updateAttributeList = (newAttributeList: Attribute[]) => {
        const attributes: { [key: string]: string } = {};
        const attributeConflicts: string[] = [];
        const attributeEmptyKeys: string[] = [];
        const attributeEmptyValues: string[] = [];
        newAttributeList.forEach(({ key, value }) => {
            if (isNullOrWhitespace(value)) {
                attributeEmptyValues.push(value);
            }
            if (isNullOrWhitespace(key)) {
                attributeEmptyKeys.push(key);
            }
            if (key in attributes) {
                attributeConflicts.push(key);
            }
            attributes[key] = value;
        });

        setAttributeDuplicateKeys(attributeConflicts);
        setAttributeEmptyKeys(attributeEmptyKeys);
        setAttributeEmptyLabels(attributeEmptyValues);

        if (editingComponent) {
            setEditingComponent({
                ...editingComponent,
                attributes,
            });
        }

        setAttributeList(newAttributeList);
    };

    return (
        <>
            <div className="admin-page components" ref={modalContainerRef}>
                <h1>{`Component: ${editingComponent?.developerName ?? ''}`}</h1>
                <AssetManagerModal
                    onInsertAsset={(currentAsset) => {
                        if (editingComponent && assetProperty) {
                            setEditingComponent({
                                ...editingComponent,
                                [assetProperty]: currentAsset,
                            });
                        }
                    }}
                    showAssetModal={showAssetModal}
                    setShowAssetModal={setShowAssetModal}
                    container={modalContainerRef.current}
                    allowedExtensions={allowedExtensions}
                    filterListToAllowedExtensions
                />
                <ComponentsDependentModal
                    isDeletionModal={false}
                    container={modalContainerRef.current}
                />
                <FormGroup
                    label="Name"
                    htmlFor="component-name"
                    validationMessage={
                        isNullOrWhitespace(editingComponent?.developerName)
                            ? 'Name field is required'
                            : 'Name field cannot have leading or trailing blank space'
                    }
                    isValid={nameValidity}
                    showValidation={hasSubmitted}
                    isRequired
                >
                    <input
                        id="component-name"
                        className="form-control form-control-long"
                        value={editingComponent?.developerName ?? ''}
                        onChange={(e) => {
                            if (editingComponent) {
                                setEditingComponent({
                                    ...editingComponent,
                                    developerName: e.target.value,
                                });
                            }
                        }}
                        type="text"
                        placeholder="A name for this component"
                    />
                </FormGroup>
                <FormGroup
                    label="Key"
                    htmlFor="component-key"
                    validationMessage={
                        isNullOrWhitespace(editingComponent?.key)
                            ? 'Key field is required'
                            : 'Key field cannot have leading or trailing blank space'
                    }
                    isValid={keyValidity}
                    showValidation={hasSubmitted}
                    isRequired
                >
                    <input
                        id="component-key"
                        className={classNames({
                            'form-control': true,
                            'form-control-long': true,
                            lowercase: editingComponent?.key,
                        })}
                        value={editingComponent?.key ?? ''}
                        onChange={(e) => {
                            if (editingComponent) {
                                setEditingComponent({
                                    ...editingComponent,
                                    key: e.target.value,
                                });
                            }
                        }}
                        type="text"
                        placeholder="A key for this component"
                    />
                    <p className="help-block">
                        A lowercase unique component registry key for this component
                    </p>
                </FormGroup>
                <FormGroup label="Description" htmlFor="component-description">
                    <textarea
                        id="component-description"
                        className="form-control form-control-long"
                        value={editingComponent?.developerSummary ?? ''}
                        onChange={(e) => {
                            if (editingComponent) {
                                setEditingComponent({
                                    ...editingComponent,
                                    developerSummary: e.target.value,
                                });
                            }
                        }}
                        placeholder="Describe what this component does"
                        rows={3}
                    />
                </FormGroup>

                <div className="form-control-long">
                    <h4>Runtime</h4>
                    <FormGroup
                        label="JavaScript"
                        htmlFor="component-js"
                        validationMessage={
                            jsWhitespaceValidity
                                ? jsURLValidity
                                    ? `JavaScript field must be a ${new Intl.ListFormat('en', {
                                          style: 'long',
                                          type: 'disjunction',
                                      }).format(JS_VALID_FILE_TYPES)} file`
                                    : 'JavaScript field must be a valid https url'
                                : 'JavaScript field cannot have leading or trailing blank space'
                        }
                        isValid={jsValidity}
                        showValidation={hasSubmitted}
                    >
                        <span className="flex">
                            <input
                                id="component-js"
                                className="form-control form-control-long asset-input"
                                value={editingComponent?.scriptURL ?? ''}
                                onChange={(e) => {
                                    if (editingComponent) {
                                        setEditingComponent({
                                            ...editingComponent,
                                            scriptURL: e.target.value,
                                        });
                                    }
                                }}
                                type="text"
                                placeholder="JavaScript file for the component to use in the runtime"
                            />
                            <button
                                className="btn btn-default btn-sm asset-button"
                                onClick={() => {
                                    setAssetProperty('scriptURL');
                                    setAllowedExtensions(JS_VALID_FILE_TYPES);
                                    setShowAssetModal(true);
                                }}
                                type="button"
                            >
                                <FileJs size={20} />
                            </button>
                        </span>
                    </FormGroup>
                    <FormGroup
                        label="CSS"
                        htmlFor="component-css"
                        validationMessage={
                            cssWhitespaceValidity
                                ? cssURLValidity
                                    ? `CSS field must be a ${new Intl.ListFormat('en', {
                                          style: 'long',
                                          type: 'disjunction',
                                      }).format(CSS_VALID_FILE_TYPES)} file`
                                    : 'CSS field must be a valid https url'
                                : 'CSS field cannot have leading or trailing blank space'
                        }
                        isValid={cssValidity}
                        showValidation={hasSubmitted}
                    >
                        <span className="flex">
                            <input
                                id="component-css"
                                className="form-control form-control-long asset-input"
                                value={editingComponent?.styleSheetURL ?? ''}
                                onChange={(e) => {
                                    if (editingComponent) {
                                        setEditingComponent({
                                            ...editingComponent,
                                            styleSheetURL: e.target.value,
                                        });
                                    }
                                }}
                                type="text"
                                placeholder="Optional CSS file for the component to use in the runtime"
                            />
                            <button
                                className="btn btn-default btn-sm asset-button"
                                onClick={() => {
                                    setAssetProperty('styleSheetURL');
                                    setAllowedExtensions(CSS_VALID_FILE_TYPES);
                                    setShowAssetModal(true);
                                }}
                                type="button"
                            >
                                <FileCss size={20} />
                            </button>
                        </span>
                    </FormGroup>
                </div>

                <div className="form-control-long">
                    <ExAccordion className="ex-mt-large">
                        <ExAccordionItem label="Legacy runtime options">
                            <FormGroup
                                label="Legacy runtime JavaScript"
                                htmlFor="component-legacy-js"
                            >
                                <span className="flex">
                                    <input
                                        id="component-legacy-js"
                                        className="form-control form-control-long asset-input"
                                        value={editingComponent?.legacyScriptURL ?? ''}
                                        onChange={(e) => {
                                            if (editingComponent) {
                                                setEditingComponent({
                                                    ...editingComponent,
                                                    legacyScriptURL: e.target.value,
                                                });
                                            }
                                        }}
                                        type="text"
                                        placeholder="JavaScript file for the component to use in the legacy runtime"
                                    />
                                    <button
                                        className="btn btn-default btn-sm asset-button"
                                        onClick={() => {
                                            setAssetProperty('legacyScriptURL');
                                            setAllowedExtensions(JS_VALID_FILE_TYPES);
                                            setShowAssetModal(true);
                                        }}
                                        type="button"
                                    >
                                        <FileJs size={20} />
                                    </button>
                                </span>
                            </FormGroup>
                            <FormGroup label="Legacy runtime CSS" htmlFor="component-legacy-css">
                                <span className="flex">
                                    <input
                                        id="component-legacy-css"
                                        className="form-control form-control-long asset-input"
                                        value={editingComponent?.legacyStyleSheetURL ?? ''}
                                        onChange={(e) => {
                                            if (editingComponent) {
                                                setEditingComponent({
                                                    ...editingComponent,
                                                    legacyStyleSheetURL: e.target.value,
                                                });
                                            }
                                        }}
                                        type="text"
                                        placeholder="Optional CSS file for the component to use in the legacy runtime"
                                    />
                                    <button
                                        className="btn btn-default btn-sm asset-button"
                                        onClick={() => {
                                            setAssetProperty('legacyStyleSheetURL');
                                            setAllowedExtensions(CSS_VALID_FILE_TYPES);
                                            setShowAssetModal(true);
                                        }}
                                        type="button"
                                    >
                                        <FileCss size={20} />
                                    </button>
                                </span>
                            </FormGroup>
                        </ExAccordionItem>
                    </ExAccordion>
                </div>

                <h4>Design Time</h4>
                <FormGroup label="Icon" htmlFor="component-icon">
                    <IconPicker />
                </FormGroup>
                <FormGroup label="Configuration Editors" htmlFor="component-editors">
                    <Select
                        inputId="component-editors"
                        className="form-control-long"
                        classNamePrefix="component-editors"
                        styles={getSharedStyles<
                            { label: string; value: string } | undefined,
                            true
                        >()}
                        isClearable
                        isMulti={true}
                        menuPlacement="top"
                        onChange={(options) => {
                            if (editingComponent) {
                                setEditingComponent({
                                    ...editingComponent,
                                    configurationEditors: options.map((o) => o?.value as string),
                                });
                            }
                        }}
                        placeholder="Select which configuration options appear in the page builder for this component"
                        value={editingComponent?.configurationEditors
                            ?.map((editor) => ({
                                label: COMPONENT_CONFIGURATION_LABELS[editor],
                                value: editor,
                            }))
                            .filter(({ label }) => !isNullOrWhitespace(label))}
                        options={componentConfigurationEditorSectionsWithLabels}
                    />
                </FormGroup>
                <FormGroup
                    label="Available Attributes"
                    className="form-control-long"
                    htmlFor="component-attributes"
                >
                    <div className="margin-bottom flex">
                        <ButtonPrimary
                            onClick={() =>
                                updateAttributeList([
                                    ...attributeList,
                                    { key: '', value: '', id: guid() },
                                ])
                            }
                        >
                            <Glyphicon glyph="plus" />
                            Add Attribute
                        </ButtonPrimary>
                    </div>
                    <Table
                        items={attributeList}
                        tableClass="generic-table form-control-long"
                        rowKeyGenerator={(i) => i.id}
                        columns={[
                            {
                                renderHeader: () => translations.COMMON_TABLE_key,
                                renderCell: ({ item, rowIndex }) => (
                                    <FormGroup
                                        htmlFor={`key${rowIndex}`}
                                        validationMessage={
                                            attributeEmptyKeys.includes(item.key)
                                                ? 'Attribute keys cannot be empty'
                                                : 'Attributes cannot have the same key'
                                        }
                                        isValid={
                                            attributeDuplicateKeys.includes(item.key) === false &&
                                            attributeEmptyKeys.includes(item.key) === false
                                        }
                                        showValidation={hasSubmitted}
                                    >
                                        <input
                                            id={`key${rowIndex}`}
                                            data-testid={`key${rowIndex}`}
                                            defaultValue={item.key}
                                            onChange={({ target: { value } }) =>
                                                updateAttributeList(
                                                    update(
                                                        rowIndex,
                                                        { ...attributeList[rowIndex], key: value },
                                                        attributeList,
                                                    ),
                                                )
                                            }
                                            className="form-control"
                                            type="text"
                                        />
                                    </FormGroup>
                                ),
                                size: '50%',
                                cellClassName: 'cell-input',
                            },
                            {
                                renderHeader: () => translations.COMMON_TABLE_label,
                                renderCell: ({ item, rowIndex }) => (
                                    <FormGroup
                                        htmlFor={`description${rowIndex}`}
                                        validationMessage="Attribute labels cannot be empty"
                                        isValid={
                                            attributeEmptyLabels.includes(item.value) === false
                                        }
                                        showValidation={hasSubmitted}
                                    >
                                        <input
                                            data-testid={`description${rowIndex}`}
                                            defaultValue={item.value}
                                            onChange={({ target: { value } }) =>
                                                updateAttributeList(
                                                    update(
                                                        rowIndex,
                                                        { ...attributeList[rowIndex], value },
                                                        attributeList,
                                                    ),
                                                )
                                            }
                                            className="form-control"
                                            type="text"
                                        />
                                    </FormGroup>
                                ),
                                cellClassName: 'cell-input',
                            },
                            {
                                renderHeader: () => translations.COMMON_TABLE_actions,
                                renderCell: ({ rowIndex }) => (
                                    <button
                                        title={translations.COMMON_delete}
                                        className="table-icon table-icon-delete"
                                        onClick={() =>
                                            updateAttributeList(remove(rowIndex, 1, attributeList))
                                        }
                                        type="button"
                                    >
                                        <Trash />
                                    </button>
                                ),
                                size: '70px',
                            },
                        ]}
                    />
                </FormGroup>
                <FormGroup
                    label="Preview Image"
                    htmlFor="component-image"
                    validationMessage={
                        imageWhitespaceValidity
                            ? imageURLValidity
                                ? `Preview Image field must be a ${new Intl.ListFormat('en', {
                                      style: 'long',
                                      type: 'disjunction',
                                  }).format(IMAGE_VALID_FILE_TYPES)} file`
                                : 'Preview Image field must be a valid https url'
                            : 'Preview Image field cannot have leading or trailing blank space'
                    }
                    isValid={imageValidity}
                    showValidation={hasSubmitted}
                >
                    <span className="flex">
                        <input
                            id="component-image"
                            className="form-control form-control-long asset-input"
                            value={editingComponent?.designTimeImageURL ?? ''}
                            onChange={(e) => {
                                if (editingComponent) {
                                    setEditingComponent({
                                        ...editingComponent,
                                        designTimeImageURL: e.target.value,
                                    });
                                }
                            }}
                            type="text"
                            placeholder="Optional image file to show in the page editor for the component"
                        />
                        <button
                            className="btn btn-default btn-sm asset-button"
                            onClick={() => {
                                setAssetProperty('designTimeImageURL');
                                setAllowedExtensions(IMAGE_VALID_FILE_TYPES);
                                setShowAssetModal(true);
                            }}
                            type="button"
                        >
                            <ImageSquare size={20} />
                        </button>
                    </span>
                </FormGroup>
                <FormGroup label="Preview Component" htmlFor="component-preview">
                    <Select
                        inputId="component-preview"
                        className="form-control-long"
                        styles={getSharedStyles<{ label: string | null; value: string }>()}
                        isClearable
                        menuPlacement="top"
                        onChange={(option) => {
                            if (editingComponent && option) {
                                setEditingComponent({
                                    ...editingComponent,
                                    designTimeRenderType: option.value,
                                });
                            }
                        }}
                        placeholder="Optional component to show in the page editor for this component"
                        value={
                            editingComponent?.designTimeRenderType
                                ? {
                                      value: editingComponent.designTimeRenderType,
                                      label: componentRegistry[
                                          editingComponent.designTimeRenderType
                                      ]
                                          ? componentRegistry[editingComponent.designTimeRenderType]
                                                .ui.caption
                                          : null,
                                  }
                                : null
                        }
                        options={Object.values(componentRegistry)
                            .filter((comp) => comp.type !== COMPONENT_TYPE['UNKNOWN'])
                            .map((comp) => ({
                                label: comp.ui.caption,
                                value: comp.type,
                            }))}
                    />
                </FormGroup>
                <div className="form-control-long preview-panel">
                    Preview
                    <div className="nextgen-page-builder active composer">
                        <div className="composer">
                            <article className="component">{renderPreviewComponent()}</article>
                        </div>
                    </div>
                </div>
            </div>
            <Footer>
                <button
                    className="btn btn-default flex-child-right"
                    title="Back"
                    onClick={() => setCurrentScreen(COMPONENT_SCREENS.componentList)}
                    type="button"
                >
                    <span>Back</span>
                </button>
                <button
                    className="btn btn-primary"
                    onClick={onSubmit}
                    title="Save Component"
                    type="button"
                >
                    <span>Save Component</span>
                </button>
            </Footer>
        </>
    );
};

export default ComponentsDetail;
