import { RUNTIME_URI } from '../../../constants/platform';
import translations from '../../../translations';
import { isNullOrEmpty } from '../../../utils';
import FormGroup from '../../generic/FormGroup';
import ClipboardInput from '../../inputs/ClipboardInput';
import Modal from '../../generic/modal/GenericModal';
import { useState, type ChangeEvent } from 'react';
import type { FlowResponseAPI } from '../../../types';
import type { Environment } from '../../../types/environment';
import { useEnvironments } from './EnvironmentsProvider';

interface Props {
    showRunThemeModal: boolean;
    setShowRunThemeModal: (showRunThemeModal: boolean) => void;
    flows: FlowResponseAPI[];
    selectedTheme: string;
    environment: Environment | null;
}

const PreviewThemeModal = ({
    showRunThemeModal,
    setShowRunThemeModal,
    flows,
    selectedTheme,
    environment,
}: Props) => {
    const [selectedFlow, setSelectedFlow] = useState('');
    const { tenantId } = useEnvironments();

    const onSelectedFlowChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setSelectedFlow(e.target.value);
    };

    function getThemeUrl() {
        const flow = flows.find((x) => x.developerName === selectedFlow);
        if (isNullOrEmpty(flow)) {
            return '';
        }
        return `${RUNTIME_URI}/${tenantId}/play/theme/${selectedTheme}?flow-id=${flow.id.id}&environment-id=${environment?.id}`;
    }

    function runTheme() {
        const url = getThemeUrl();
        window.open(url, '_blank');
        setSelectedFlow('');
        setShowRunThemeModal(!showRunThemeModal);
    }

    return (
        <Modal
            show={showRunThemeModal}
            onHide={() => setShowRunThemeModal(!showRunThemeModal)}
            title={translations.ENVIRONMENT_run}
            bodyClassName="modal-body-no-height"
            renderBody={() => (
                <>
                    <FormGroup
                        label="Which Flow do you want to apply the theme to?"
                        htmlFor="run-type-select"
                        isRequired={true}
                    >
                        <select
                            id="run-type-select"
                            value={selectedFlow}
                            onChange={onSelectedFlowChange}
                            required
                            className="form-control"
                        >
                            <option value="" key="please-select">
                                {translations.ENVIRONMENT_select_flow}
                            </option>
                            {flows.map((flows) => (
                                <option key={flows.developerName} value={flows.developerName}>
                                    {flows.developerName}
                                </option>
                            ))}
                        </select>
                    </FormGroup>
                    <FormGroup label="Url" className="margin-top">
                        <ClipboardInput value={getThemeUrl()} buttonTitle="Copy URL" />
                    </FormGroup>
                </>
            )}
            className="config-modal"
            renderFooter={() => (
                <>
                    <button
                        type="button"
                        className="btn btn-default outcome"
                        onClick={() => setShowRunThemeModal(!showRunThemeModal)}
                    >
                        {translations.ENVIRONMENT_cancel}
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary outcome"
                        onClick={() => runTheme()}
                        disabled={isNullOrEmpty(selectedFlow)}
                    >
                        {translations.ENVIRONMENT_run}
                    </button>
                </>
            )}
        />
    );
};

export default PreviewThemeModal;
