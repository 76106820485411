import { type ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useCollaboration } from '../../../../collaboration/CollaborationProvider';
import ButtonDefault from '../../../buttons/ButtonDefault';
import ButtonPrimary from '../../../buttons/ButtonPrimary';
import FormGroup from '../../../generic/FormGroup';
import ModalBody from '../../../generic/modal/ModalBody';
import ModalFooter from '../../../generic/modal/ModalFooter';
import ConfigModal from '../../../graph/ConfigModal';
import Authorization from '../../../runtime-authorization/Authorization';
import { COLLABORATION_ITEM_TYPES, MAP_ELEMENT_TYPES } from '../../../../constants';
import { useDocumentKeyDown } from '../../../../hooks';
import translations from '../../../../translations';
import NameInput from '../common/NameInput';
import useGroup from '../group/useGroup';
import { guid, isNullOrEmpty, isNullOrUndefined, isNullOrWhitespace } from '../../../../utils';
import type {
    NotifyError,
    FlowIdentityProviderAPI,
    GroupAuthorizationAPI,
} from '../../../../types';
import type { GroupElementAPI, GroupElementRequestAPI } from '../../../../sources/graph';

type Props = {
    groupElementId: string | null;
    id: string | null;
    x: number;
    y: number;
    flowId: string;
    dismissMapElementConfig: (flowId: string) => void;
    refreshFlow: () => void;
    container: HTMLElement;
    width: number;
    height: number;
    notifyError: NotifyError;
    currentUserId: string | undefined;
    focusAndSelectElement: () => void;
};

const Swimlane = ({
    groupElementId: parentGroupElementId,
    id = null,
    x,
    y,
    flowId,
    dismissMapElementConfig,
    refreshFlow,
    container,
    width,
    height,
    notifyError,
    currentUserId,
    focusAndSelectElement,
}: Props) => {
    const [groupElementId, setGroupElementId] = useState(id);
    const [isLoading, setIsLoading] = useState(true);
    const [hasSubmitted, updateHasSubmitted] = useState(false);

    const { itemOpened, itemClosed, itemChanged, getItem } = useCollaboration();

    const retrieveItem = getItem as (id: string) => GroupElementRequestAPI;

    const currentGroupElement = retrieveItem(groupElementId ?? '');

    const { getGroupElement, saveGroupElement } = useGroup({
        flowId,
        notifyError,
        setIsLoading,
        refreshFlow,
        focusAndSelectElement,
    });

    const isFormValid = () => {
        if (isNullOrUndefined(currentGroupElement)) {
            return false;
        }
        if (currentGroupElement.developerName === '') {
            return false;
        }
        return true;
    };

    const setGroupElement = useCallback(
        (newGroupElement: GroupElementAPI) => {
            if (groupElementId) {
                itemChanged(
                    groupElementId,
                    COLLABORATION_ITEM_TYPES.groupElement,
                    newGroupElement,
                    !!id,
                );
            }
        },
        [groupElementId, id, itemChanged],
    );

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const load = async () => {
            setIsLoading(true);

            let groupElement = null;

            try {
                if (id) {
                    groupElement = await getGroupElement(id);
                } else {
                    groupElement = {
                        elementType: MAP_ELEMENT_TYPES.swimlane,
                        x,
                        y,
                        developerName: '',
                        id: guid(),
                        authorization: { streamBehaviourType: 'NONE' },
                        width,
                        height,
                        groupElementId: parentGroupElementId ?? null,
                    };
                }

                if (groupElement) {
                    setGroupElementId(groupElement.id);
                    itemOpened(
                        groupElement.id,
                        flowId,
                        COLLABORATION_ITEM_TYPES.groupElement,
                        groupElement,
                        !!id,
                    );
                }
            } catch (error) {
                notifyError(error);
            } finally {
                setIsLoading(false);
            }
        };

        load();
    }, []);

    const onDeveloperNameChange = (developerName: string) => {
        if (groupElementId) {
            setGroupElement({ ...retrieveItem(groupElementId), developerName });
        }
    };

    const onCommentChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        if (groupElementId) {
            setGroupElement({ ...retrieveItem(groupElementId), developerSummary: e.target.value });
        }
    };

    const onIdpChange = (identityProvider: FlowIdentityProviderAPI | null) => {
        if (groupElementId) {
            setGroupElement({ ...retrieveItem(groupElementId), identityProvider });
        }
    };

    const onAuthorizationChange = (authorization: GroupAuthorizationAPI) => {
        if (groupElementId) {
            setGroupElement({ ...retrieveItem(groupElementId), authorization });
        }
    };

    const onSave = async () => {
        updateHasSubmitted(true);

        if (isFormValid()) {
            // If the Flow has been set to not use Users and Groups
            if (
                currentGroupElement.authorization &&
                currentGroupElement.authorization?.globalAuthenticationType !== 'SPECIFIED'
            ) {
                // Then remove the users and groups
                currentGroupElement.authorization.users = null;
                currentGroupElement.authorization.groups = null;
            }

            if (
                // If there are groups
                (currentGroupElement.authorization?.groups &&
                    // And the groups are wrong
                    (currentGroupElement.authorization.groups.some(
                        (item) => item.authenticationId === '-1',
                    ) ||
                        currentGroupElement.authorization.groups.some(
                            (item) => item.attribute === '-1',
                        ))) ||
                // If there are users
                (currentGroupElement.authorization?.users &&
                    // And the users are wrong
                    (currentGroupElement.authorization.users.some(
                        (item) => item.authenticationId === '-1',
                    ) ||
                        currentGroupElement.authorization.users.some(
                            (item) => item.attribute === '-1',
                        )))
            ) {
                notifyError('Please select items from all of the dropdowns');
                return;
            }

            if (
                currentGroupElement.authorization &&
                isNullOrUndefined(currentGroupElement.authorization?.globalAuthenticationType)
            ) {
                currentGroupElement.authorization.globalAuthenticationType = 'PUBLIC';
            }

            if (currentGroupElement.authorization?.globalAuthenticationType === 'SPECIFIED') {
                if (
                    isNullOrEmpty(currentGroupElement.authorization?.groups) &&
                    isNullOrEmpty(currentGroupElement.authorization?.users)
                ) {
                    notifyError(translations.GROUPS_USERS_NONE_SELECTED_ERROR_MESSAGE);
                    return;
                }
            }

            try {
                await saveGroupElement(currentGroupElement);
                onClose();
            } catch (error) {
                notifyError(error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const onClose = () => {
        // Closes the config panel
        dismissMapElementConfig(flowId);
        refreshFlow();
        itemClosed(id, COLLABORATION_ITEM_TYPES.groupElement, !!id);
    };

    // For when the ESC is pressed
    useDocumentKeyDown('Escape', document, onClose);

    const renderBody = () => (
        <>
            <NameInput
                key="swimlane-name"
                isValid={!isNullOrEmpty(currentGroupElement.developerName)}
                showValidation={hasSubmitted}
                id="swimlane-name"
                name={currentGroupElement.developerName ?? ''}
                onUpdateName={onDeveloperNameChange}
            />

            <Authorization
                identityProvider={currentGroupElement.identityProvider}
                onChangeIdentityProvider={onIdpChange}
                authorization={currentGroupElement.authorization}
                onChangeAuthorization={onAuthorizationChange}
                notifyError={notifyError}
            />

            <FormGroup
                key="swimlane-comments"
                label={translations.SWIMLANE_CONFIG_comment_label}
                htmlFor="swimlane-comments"
            >
                <textarea
                    id="swimlane-comments"
                    className="form-control form-textarea"
                    value={currentGroupElement?.developerSummary ?? ''}
                    onChange={onCommentChange}
                />
            </FormGroup>
        </>
    );
    const renderFooter = () => [
        <ButtonDefault
            key={translations.SWIMLANE_CONFIG_cancel_button_text}
            className="flex-child-right"
            onClick={onClose}
        >
            {translations.SWIMLANE_CONFIG_cancel_button_text}
        </ButtonDefault>,
        <ButtonPrimary
            key={translations.GRAPH_config_panel_save}
            className="margin-left"
            onClick={onSave}
        >
            {translations.GRAPH_config_panel_save}
        </ButtonPrimary>,
    ];

    return isLoading ? null : (
        <ConfigModal
            id={currentGroupElement.id}
            title={
                isNullOrWhitespace(currentGroupElement?.developerName)
                    ? `New ${MAP_ELEMENT_TYPES.swimlane}`
                    : currentGroupElement.developerName
            }
            elementType={MAP_ELEMENT_TYPES.swimlane}
            container={container}
            currentUserId={currentUserId}
            onHide={onClose}
        >
            <ModalBody>{renderBody()}</ModalBody>
            <ModalFooter>{renderFooter()}</ModalFooter>
        </ConfigModal>
    );
};

export default Swimlane;
