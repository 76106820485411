import { useMapElement } from '../contextProviders/MapElementProvider';
import NameInput from '../common/NameInput';
import translations from '../../../../translations';
import ButtonDefault from '../../../buttons/ButtonDefault';
import ButtonPrimary from '../../../buttons/ButtonPrimary';
import FormGroup from '../../../generic/FormGroup';
import ModalBody from '../../../generic/modal/ModalBody';
import ModalFooter from '../../../generic/modal/ModalFooter';
import { useOpenApi } from '../contextProviders/OpenApiProvider';
import OpenApiActionList from './OpenApiActionList';
import type { MapElementRequestAPI } from '../../../../sources/graph';

/**
 * The openapi config screen
 */
const OpenApiConfiguration = () => {
    const { onClose, mapElement, setMapElement, onSaveMapElement } = useMapElement();
    const { hasSubmitted, setHasSubmitted, isNameValid, onUpdateName, isValidOpenApiElement } =
        useOpenApi();

    const saveOpenApiElement = () => {
        setHasSubmitted(true);
        if (isValidOpenApiElement()) {
            onSaveMapElement(mapElement as MapElementRequestAPI);
        }
    };

    const renderBody = () => (
        <>
            <NameInput
                isValid={isNameValid()}
                showValidation={hasSubmitted}
                id="openapi-name"
                name={mapElement.developerName ?? ''}
                onUpdateName={onUpdateName}
            />
            <OpenApiActionList />
            <FormGroup
                key="openapi-comments"
                label={translations.OPENAPI_ELEMENT_comments_label}
                htmlFor="openapi-comments"
            >
                <textarea
                    id="openapi-comments"
                    className="form-control form-textarea"
                    value={mapElement.developerSummary ?? ''}
                    onChange={({ target: { value } }) => {
                        setMapElement({
                            ...mapElement,
                            developerSummary: value,
                        });
                    }}
                />
            </FormGroup>
        </>
    );

    const renderFooter = () => [
        <ButtonDefault key="cancelButton" className="flex-child-right" onClick={() => onClose()}>
            {translations.GRAPH_config_panel_cancel}
        </ButtonDefault>,
        <ButtonPrimary key="saveButton" className="margin-left" onClick={saveOpenApiElement}>
            {translations.GRAPH_config_panel_save}
        </ButtonPrimary>,
    ];

    return (
        <>
            <ModalBody>{renderBody()}</ModalBody>
            <ModalFooter>{renderFooter()}</ModalFooter>
        </>
    );
};

export default OpenApiConfiguration;
