import SidebarItem from './SidebarItem';
import { usePageEditor } from '../../../PageEditorProvider';
import { COMPONENT_GROUPS, COMPONENT_TYPE } from '../../../../constants';
import { Columns, Rows, DotsThreeOutline, Tabs, ChartBar } from '@phosphor-icons/react';
import { componentRegistry } from '../../../../registry';
import ValueSelectorModal from '../../../../../values/selector/ValueSelectorModal';
import type { ComponentRegistry } from '../../../../../../types';
import classNames from 'classnames';
import { useFeatureFlag } from '../../../../../featureFlags/FeatureFlagProvider';

const SideBar = () => {
    const { componentRegistryList, selectComponentSuggestValue, container, state } =
        usePageEditor();

    const { getFlag } = useFeatureFlag();

    const { preview } = state;

    const classes = classNames({
        'sidebar-left': true,
        preview,
    });

    const componentGroups = [
        COMPONENT_GROUPS['CONTENT'],
        COMPONENT_GROUPS['INPUT'],
        COMPONENT_GROUPS['DATA'],
    ];

    if (
        componentRegistryList.filter((component) => component.group === COMPONENT_GROUPS['CUSTOM'])
            .length > 0
    ) {
        componentGroups.push(COMPONENT_GROUPS['CUSTOM']);
    }

    const renderComponentMenuItem = (groupedItems: ComponentRegistry[], group: string) => {
        const menuItems = groupedItems
            .filter((component) => {
                switch (component.type) {
                    case COMPONENT_TYPE['AICHAT']: {
                        return getFlag('OPENAI');
                    }
                    case COMPONENT_TYPE['DATAGRID']: {
                        return getFlag('DATGRD');
                    }
                    case COMPONENT_TYPE['DOWNLOADBUTTON']: {
                        return getFlag('DNLDBN');
                    }

                    case COMPONENT_TYPE['IMAGE']:
                    /** The image component is deprecated and should not show in the components list */
                    case COMPONENT_TYPE['FILES']:
                    /** The Files component is deprecated and should not show in the components list */
                    case COMPONENT_TYPE['HIDDEN']: {
                        /** The hidden component is deprecated and should not show in the components list */

                        return null;
                    }

                    default: {
                        return true;
                    }
                }
            })
            .map((component) => {
                return (
                    <SidebarItem
                        key={component.type}
                        label={component.ui.caption}
                        pageElementType="COMPONENT"
                        componentType={component.type.toLowerCase()}
                        icon={component.ui.icon}
                    />
                );
            });

        if (group === COMPONENT_GROUPS['DATA']) {
            menuItems.push(
                <SidebarItem
                    key="chart"
                    label="Chart"
                    containerType="CHARTS"
                    pageElementType="CONTAINER"
                    icon={<ChartBar />}
                />,
            );
        }

        if (group !== COMPONENT_GROUPS['CUSTOM']) {
            // Find components that the custom set have overridden
            Object.entries(componentRegistry)
                .filter(([, standardComponent]) => standardComponent.group === group)
                .forEach(([standardKey, standardComponent]) => {
                    const registryItem = componentRegistryList.find(
                        (element) => element.type === standardKey,
                    );
                    // This component was a standard component
                    // but if it has now been overridden by a custom one
                    if (registryItem && registryItem.group === COMPONENT_GROUPS['CUSTOM']) {
                        // We push a disabled entry to explain why this standard entry can't be used in this Tenant
                        menuItems.push(
                            <SidebarItem
                                key={standardComponent.type}
                                label={standardComponent.ui.caption}
                                pageElementType="COMPONENT"
                                componentType={standardComponent.type.toLowerCase()}
                                icon={standardComponent.ui.icon}
                                overridden
                            />,
                        );
                    }
                });
        }

        return menuItems;
    };

    return (
        <aside className={classes} data-testid="sidebar-left">
            <div className="sidebar-left-panels">
                <section className="flex-column">
                    <section className="component-group">
                        <div className="component-group-heading">Component Suggest</div>
                        <div className="padding">
                            <ValueSelectorModal
                                includeSystemValues={false}
                                value={null}
                                onChangeAsValueReference={(value) => {
                                    selectComponentSuggestValue(value);
                                }}
                                container={container}
                                forgetValueSelection={true}
                            />
                        </div>
                    </section>
                    <section className="component-group">
                        <div className="component-group-heading">
                            {COMPONENT_GROUPS['CONTAINERS']}
                        </div>
                        <SidebarItem
                            label="Row"
                            containerType="VERTICAL_FLOW"
                            pageElementType="CONTAINER"
                            icon={<Rows />}
                        />
                        <SidebarItem
                            label="Column"
                            containerType="HORIZONTAL_FLOW"
                            pageElementType="CONTAINER"
                            icon={<Columns />}
                        />
                        <SidebarItem
                            label="Inline"
                            containerType="INLINE_FLOW"
                            pageElementType="CONTAINER"
                            icon={<DotsThreeOutline />}
                        />
                        <SidebarItem
                            label="Tabs"
                            containerType="GROUP"
                            pageElementType="CONTAINER"
                            icon={<Tabs />}
                        />
                    </section>
                    {componentGroups.map((group) => (
                        <section key={group} className="component-group">
                            <div className="component-group-heading">{group}</div>
                            {renderComponentMenuItem(
                                componentRegistryList.filter(
                                    (component) => component.group === group,
                                ),
                                group,
                            )}
                        </section>
                    ))}
                </section>
            </div>
        </aside>
    );
};

export default SideBar;
