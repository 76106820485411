import classnames from 'classnames';
import type { ReactNode } from 'react';
import '../../../css/admin.less';
import '../../../css/modal.less';
import { TAB_TYPES } from '../constants';
import translations from '../translations';
import type { TabType } from '../types';
import ApiTool from './ApiTool';
import { useAuth } from './AuthProvider';
import ImportExport from './ImportExport';
import CustomPageComponents from './admin/customPageComponents/CustomPageComponents';
import { CustomPageComponentsProvider } from './admin/customPageComponents/CustomPageComponentsProvider';
import Environment from './admin/environments/Environment';
import Environments from './admin/environments/Environments';
import { EnvironmentsProvider } from './admin/environments/EnvironmentsProvider';
import IdentityProviders from './admin/identityproviders/IdentityProviders';
import PageList from './admin/pages/PageList';
import Values from './admin/values/Values';
import { ValuesProvider } from './admin/values/ValuesProvider';
import AssetTab from './assets/AssetTab';
import AuditLogs from './auditLogs/AuditLogs';
import InsightsDashboard from './developerDashboard/InsightsDashboard';
import Features from './featureFlags/Features';
import Home from './flow/homePage/Home';
import ErrorBoundary from './generic/ErrorBoundary';
import MacroEditor from './macro-editor/MacroEditor';
import InitialOrgPage from './organization/InitialOrgPage';
import PlayerEditor from './player-editor/PlayerEditor';
import ServiceController from './services/ServiceController';
import { ServicesProvider } from './services/contextProviders/ServicesProvider';
import TenantPageSwitcher from './tenant/TenantPageSwitcher';
import Themes from './theme/Themes';
import Types from './types/Types';
import { TypesProvider } from './types/TypesProvider';
import UserSettings from './userSettings/UserSettings';

type AdminProps = {
    isActive: boolean;
    tabType: TabType;
};

const Admin = ({ isActive, tabType }: AdminProps) => {
    const { tenant } = useAuth();

    if (!tenant) {
        return null;
    }

    const { id: tenantId, developerName: tenantName, tenantSettings } = tenant;
    const environmentsIsOn = tenantSettings?.environments ?? false;

    let content: ReactNode = null;

    switch (tabType) {
        case TAB_TYPES.auditing: {
            content = (
                <ErrorBoundary>
                    <AuditLogs tenantId={tenantId} />
                </ErrorBoundary>
            );
            break;
        }
        case TAB_TYPES.tenant: {
            content = (
                <ErrorBoundary>
                    <TenantPageSwitcher tenantName={tenantName} />
                </ErrorBoundary>
            );
            break;
        }
        case TAB_TYPES.flows: {
            content = (
                <ErrorBoundary>
                    <Home isEditorActive={isActive} />
                </ErrorBoundary>
            );
            break;
        }
        case TAB_TYPES.services: {
            content = (
                <ErrorBoundary>
                    <ServicesProvider tenantId={tenantId}>
                        <ServiceController />
                    </ServicesProvider>
                </ErrorBoundary>
            );
            break;
        }
        case TAB_TYPES.types: {
            content = (
                <ErrorBoundary>
                    <TypesProvider tenantId={tenantId}>
                        <Types />
                    </TypesProvider>
                </ErrorBoundary>
            );
            break;
        }
        case TAB_TYPES.values: {
            content = (
                <ValuesProvider tenantId={tenantId}>
                    <Values />
                </ValuesProvider>
            );
            break;
        }
        case TAB_TYPES.identityProviders: {
            content = (
                <ErrorBoundary>
                    <IdentityProviders />
                </ErrorBoundary>
            );
            break;
        }
        case TAB_TYPES.environments: {
            content = (
                <ErrorBoundary>
                    <EnvironmentsProvider tenantId={tenantId}>
                        <Environments />
                    </EnvironmentsProvider>
                </ErrorBoundary>
            );
            break;
        }
        case TAB_TYPES.environment: {
            if (environmentsIsOn) {
                content = (
                    <ErrorBoundary>
                        <EnvironmentsProvider tenantId={tenantId}>
                            <Environment />
                        </EnvironmentsProvider>
                    </ErrorBoundary>
                );
            } else {
                content = <span>{translations.ENVIRONMENT_not_on}</span>;
            }
            break;
        }
        case TAB_TYPES.pages: {
            content = (
                <ErrorBoundary>
                    <PageList tenantId={tenantId} />
                </ErrorBoundary>
            );
            break;
        }
        case TAB_TYPES.assets: {
            content = (
                <ErrorBoundary>
                    <AssetTab />
                </ErrorBoundary>
            );
            break;
        }
        case TAB_TYPES.themes: {
            content = (
                <ErrorBoundary>
                    <Themes key="themes" />
                </ErrorBoundary>
            );
            break;
        }
        case TAB_TYPES.players: {
            content = (
                <ErrorBoundary>
                    <PlayerEditor key="players" tenantId={tenantId} isActive={isActive} />
                </ErrorBoundary>
            );
            break;
        }
        case TAB_TYPES.api: {
            content = (
                <ErrorBoundary>
                    <ApiTool key="api-tool" isActive={isActive} />
                </ErrorBoundary>
            );
            break;
        }
        case TAB_TYPES.macros: {
            content = (
                <ErrorBoundary>
                    <MacroEditor key="macro-editor" />
                </ErrorBoundary>
            );
            break;
        }
        case TAB_TYPES.importExport: {
            content = (
                <ErrorBoundary>
                    <ImportExport key="import-export" tenant={tenant} />
                </ErrorBoundary>
            );
            break;
        }
        case TAB_TYPES.dashboard: {
            content = (
                <ErrorBoundary>
                    <InsightsDashboard />
                </ErrorBoundary>
            );
            break;
        }
        case TAB_TYPES.organization: {
            content = (
                <ErrorBoundary>
                    <InitialOrgPage />
                </ErrorBoundary>
            );
            break;
        }
        case TAB_TYPES.userSettings: {
            content = <UserSettings />;
            break;
        }
        case TAB_TYPES.features: {
            content = <Features />;
            break;
        }
        case TAB_TYPES.components: {
            content = (
                <ErrorBoundary>
                    <CustomPageComponentsProvider tenantId={tenantId}>
                        <CustomPageComponents />
                    </CustomPageComponentsProvider>
                </ErrorBoundary>
            );
            break;
        }
        default: {
            content = null;
            break;
        }
    }

    const classes = classnames('admin', {
        active: isActive,
    });

    return (
        <div className={classes}>
            <span className="admin-transition">{content}</span>
        </div>
    );
};

export default Admin;
