import ButtonDefault from '../../../buttons/ButtonDefault';
import ButtonPrimary from '../../../buttons/ButtonPrimary';
import translations from '../../../../translations';

type Props = {
    save: () => void;
    saveButtonText?: string;
    cancel: () => void;
    cancelButtonText?: string;
};

const Footer = ({ save, saveButtonText, cancel, cancelButtonText }: Props) => {
    return (
        <>
            <ButtonDefault className="flex-child-right" onClick={cancel}>
                {cancelButtonText ?? translations.GRAPH_config_panel_cancel}
            </ButtonDefault>
            <ButtonPrimary className="margin-left" onClick={save}>
                {saveButtonText ?? translations.GRAPH_config_panel_save}
            </ButtonPrimary>
        </>
    );
};

export default Footer;
