import { memo } from 'react';
import Delete from '../../flow/elementConfigurations/delete/Delete';
import GroupConfiguration from '../../flow/elementConfigurations/group/GroupConfiguration';
import Load from '../../flow/elementConfigurations/load/Load';
import Message from '../../flow/elementConfigurations/message/Message';
import Operator from '../../flow/elementConfigurations/operator/Operator';
import Save from '../../flow/elementConfigurations/save/Save';
import Step from '../../flow/elementConfigurations/step/Step';
import SubFlow from '../../flow/elementConfigurations/subflow/SubFlow';
import Swimlane from '../../flow/elementConfigurations/swinlane/Swimlane';
import Wait from '../../flow/elementConfigurations/common/wait/Wait';
import type {
    EdgeCollection,
    FlowGraphGroupElementResponseAPI,
    FlowGraphMapElementResponseAPI,
    GroupNodeCollection,
    LeafNodeCollection,
    NotifyError,
} from '../../../types';
import { useAuth } from '../../AuthProvider';
import DeleteElements from './DeleteElements';
import Outcome from '../../flow/elementConfigurations/outcome/Outcome';
import Page from '../../flow/elementConfigurations/page/Page';
import Modal from '../../flow/elementConfigurations/page/Modal';
import Decision from '../../flow/elementConfigurations/decision/Decision';

export type FlowConfigEditorType =
    | 'step'
    | 'input'
    | 'modal'
    | 'decision'
    | 'operator'
    | 'message'
    | 'wait'
    | 'database_load'
    | 'database_save'
    | 'database_delete'
    | 'swimlane'
    | 'subflow'
    | 'group'
    | 'element_delete'
    | 'outcome'
    | 'outcome_delete'
    | 'outcome_redirect';

interface Props {
    flowId: string;
    editorType: FlowConfigEditorType;
    container: HTMLDivElement;
    focusedObjectId: string;
    selectedObjectIds: string[];
    mapElements: FlowGraphMapElementResponseAPI[];
    groupElements: FlowGraphGroupElementResponseAPI[];
    leafNodes: LeafNodeCollection;
    groupNodes: GroupNodeCollection;
    edges: EdgeCollection;
    setIsLoading: () => void;
    refreshFlow: () => void;
    dismissMapElementConfig: () => void;
    focusAndSelectElement: () => void;
    onConfirmDelete: (nodeId: string) => Promise<void>;
    notifyError: NotifyError;
}

const GraphConfigEditor = ({
    flowId,
    leafNodes,
    groupNodes,
    edges,
    editorType: editorName,
    container,
    focusedObjectId,
    selectedObjectIds,
    mapElements,
    groupElements,
    setIsLoading,
    refreshFlow,
    dismissMapElementConfig,
    focusAndSelectElement,
    notifyError,
    onConfirmDelete,
}: Props) => {
    const { user } = useAuth();
    const currentUserId = user?.id;

    const commonProps = {
        flowId,
        container,
        mapElements,
        currentUserId,
        setIsLoading,
        refreshFlow,
        dismissMapElementConfig,
        focusAndSelectElement,
        notifyError,
        x: 0,
        y: 0,
        outcomeId: null,
        nextMapElementId: null,
    };

    switch (editorName) {
        case 'step': {
            const node = leafNodes[focusedObjectId];
            const id = node.id === 'new' ? null : node.id;
            if (node) {
                return <Step {...commonProps} x={node.x} y={node.y} id={id as string} />;
            }
            break;
        }
        case 'input': {
            const node = leafNodes[focusedObjectId];
            const id = node.id === 'new' ? null : node.id;
            if (node) {
                return <Page {...commonProps} x={node.x} y={node.y} id={id as string} />;
            }
            break;
        }

        case 'modal': {
            const node = leafNodes[focusedObjectId];
            const id = node.id === 'new' ? null : node.id;
            if (node) {
                return <Modal {...commonProps} x={node.x} y={node.y} id={id as string} />;
            }
            break;
        }

        case 'decision': {
            const node = leafNodes[focusedObjectId];
            const id = node.id === 'new' ? null : node.id;
            if (node) {
                return <Decision {...commonProps} x={node.x} y={node.y} id={id as string} />;
            }
            break;
        }

        case 'operator': {
            const node = leafNodes[focusedObjectId];
            const id = node.id === 'new' ? null : node.id;
            if (node) {
                return <Operator {...commonProps} x={node.x} y={node.y} id={id as string} />;
            }
            break;
        }

        case 'message': {
            const node = leafNodes[focusedObjectId];
            const id = node.id === 'new' ? null : node.id;
            if (node) {
                return <Message {...commonProps} x={node.x} y={node.y} id={id as string} />;
            }
            break;
        }

        case 'wait': {
            const node = leafNodes[focusedObjectId];
            const id = node.id === 'new' ? null : node.id;
            if (node) {
                return <Wait {...commonProps} x={node.x} y={node.y} id={id as string} />;
            }
            break;
        }

        case 'database_load': {
            const node = leafNodes[focusedObjectId];
            const id = node.id === 'new' ? null : node.id;
            if (node) {
                return <Load {...commonProps} x={node.x} y={node.y} id={id} />;
            }
            break;
        }

        case 'database_save': {
            const node = leafNodes[focusedObjectId];
            const id = node.id === 'new' ? null : node.id;
            if (node) {
                return <Save {...commonProps} x={node.x} y={node.y} id={id} />;
            }
            break;
        }

        case 'database_delete': {
            const node = leafNodes[focusedObjectId];
            const id = node.id === 'new' ? null : node.id;
            if (node) {
                return <Delete {...commonProps} x={node.x} y={node.y} id={id} />;
            }
            break;
        }

        case 'subflow': {
            const node = leafNodes[focusedObjectId];
            const id = node.id === 'new' ? null : node.id;
            if (node) {
                return <SubFlow {...commonProps} x={node.x} y={node.y} id={id} />;
            }
            break;
        }

        case 'swimlane': {
            const node = groupNodes[focusedObjectId];
            const id = node.id === 'new' ? null : node.id;
            if (node) {
                return (
                    <Swimlane
                        {...commonProps}
                        id={id}
                        x={node.x}
                        y={node.y}
                        width={node.width}
                        height={node.height}
                        groupElementId={node.groupId}
                    />
                );
            }
            break;
        }

        case 'group': {
            const node = groupNodes[focusedObjectId];
            const id = node.id === 'new' ? null : node.id;
            if (node) {
                return (
                    <GroupConfiguration
                        {...commonProps}
                        id={id}
                        x={node.x}
                        y={node.y}
                        groupElementId={node.groupId}
                    />
                );
            }
            break;
        }

        case 'element_delete': {
            return (
                <DeleteElements
                    {...commonProps}
                    groupElements={groupElements}
                    focusedObjectId={focusedObjectId}
                    selectedObjectIds={selectedObjectIds}
                    edges={edges}
                    onConfirmDelete={onConfirmDelete}
                />
            );
        }

        case 'outcome': {
            return (
                <Outcome
                    {...commonProps}
                    id={
                        mapElements.find((mapEl) =>
                            mapEl.outcomes?.find((out) => out.id === focusedObjectId),
                        )?.id ?? edges['new']?.nodeIds[0]
                    }
                    nextMapElementId={edges['new']?.nodeIds[1] ?? null}
                    outcomeId={edges['new'] ? null : focusedObjectId}
                />
            );
        }
    }

    return null;
};

export default memo(GraphConfigEditor);
