import { useEffect, useState } from 'react';
import Select from 'react-select';
import { useGraph } from '../../../../../js/components/graph/GraphProvider';
import { MAP_ELEMENT_TYPES, UI_ELEMENTS } from '../../../../constants';
import ButtonDefault from '../../../buttons/ButtonDefault';
import ButtonPrimary from '../../../buttons/ButtonPrimary';
import translations from '../../../../translations';
import type { MapElement, Outcome } from '../../../../types/graph';
import FormGroup from '../../../generic/FormGroup';
import ConfigModal from '../../../graph/ConfigModal';
import ModalBody from '../../../generic/modal/ModalBody';
import ModalFooter from '../../../generic/modal/ModalFooter';
import { isNullOrEmpty } from '../../../../utils/guard';
import { guid } from '../../../../utils/guid';
import { stringReplace } from '../../../../utils/string';
import { getSharedStyles } from '../../../../utils';

interface Props {
    dismissMapElementConfig: () => void;
    container: HTMLElement;
    sourceMapElementId: string;
}

interface UseGraph {
    mapElements: [MapElement];
    refreshFlow: () => void;
    saveMapElement: (mapElement: MapElement) => Promise<void>;
}

const CreateNewOutcome = ({ dismissMapElementConfig, container, sourceMapElementId }: Props) => {
    const { mapElements, refreshFlow, saveMapElement }: UseGraph = useGraph();

    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [sourceMapElement, setSourceMapElement] = useState<MapElement>();
    const [targetMapElement, setTargetMapElement] = useState<MapElement>();
    const [otherMapElements, setOtherMapElements] = useState<MapElement[]>([]);
    const [newOutcome, setNewOutcome] = useState<Outcome>({
        attributes: {},
        className: null,
        comparison: null,
        controlPoints: null,
        developerName: '',
        developerSummary: null,
        flowOut: null,
        id: guid(),
        isBulkAction: false,
        label: null,
        nextMapElementDeveloperName: null,
        nextMapElementId: null,
        order: 0,
        pageActionBindingType: 'SAVE',
        pageActionType: null,
        pageObjectBindingId: null,
    });

    const [menuPortalTarget, setMenuPortalTarget] = useState<HTMLElement | null>(null);

    useEffect(() => {
        // The modal appears after the DOM is loaded so we need to wait in order to set a menuPortalTarget
        requestAnimationFrame(() => {
            setMenuPortalTarget(document.querySelector('.outcome-modal') as HTMLElement);
        });
    }, []);

    useEffect(() => {
        const sourceEle = mapElements.find((ele) => ele.id === sourceMapElementId);

        if (!isNullOrEmpty(sourceEle)) {
            setOtherMapElements(
                mapElements.filter((ele) => {
                    if (ele.id !== sourceEle?.id) {
                        return true;
                    }

                    return false;
                }),
            );
            setSourceMapElement(sourceEle);
        }
    }, [mapElements, sourceMapElementId]);

    const message = stringReplace(
        translations.OUTCOME_CREATE_message,
        sourceMapElement?.developerName ?? '',
    );

    const labelRequired = UI_ELEMENTS.includes(sourceMapElement?.elementType ?? '');

    const isTargetElementValid = !isNullOrEmpty(targetMapElement);
    const isNameValid = !isNullOrEmpty(newOutcome.developerName);
    const isLabelValid = !(isNullOrEmpty(newOutcome.label) && labelRequired);
    const isFormValid = isTargetElementValid && isNameValid && isLabelValid;

    const onSave = async () => {
        setHasSubmitted(true);

        if (isFormValid && sourceMapElement) {
            const updatedOutcomes: Outcome[] = sourceMapElement.outcomes || [];
            updatedOutcomes.push(newOutcome);

            const newMapElement: MapElement = {
                ...sourceMapElement,
                outcomes: updatedOutcomes,
            };

            await saveMapElement(newMapElement);
            dismissMapElementConfig();
        }
    };

    const onCancel = () => {
        refreshFlow();
        dismissMapElementConfig();
    };

    const onSetTargetElement = (elementId: string) => {
        const selectedElement = otherMapElements.find((ele) => ele.id === elementId);

        if (isNullOrEmpty(selectedElement)) {
            setTargetMapElement(undefined);
            setNewOutcome({ ...newOutcome, nextMapElementId: null });
        } else {
            setTargetMapElement(selectedElement);
            const name = selectedElement?.developerName
                ? `Go to ${selectedElement?.developerName}`
                : 'Go';
            setNewOutcome({
                ...newOutcome,
                nextMapElementId: selectedElement?.id ?? null,
                developerName: name,
                label: name,
            });
        }
    };

    const getElementValue = () => {
        if (targetMapElement) {
            return { value: targetMapElement.id, label: targetMapElement.developerName };
        }

        return null;
    };

    const renderBody = () => (
        <>
            <p>{message}</p>
            <div className="margin-top">
                <FormGroup
                    label={translations.OUTCOME_CREATE_element_select_label}
                    htmlFor="add-outcome-select"
                    isRequired
                    validationMessage={translations.OUTCOME_CREATE_element_select_validation}
                    isValid={isTargetElementValid}
                    showValidation={hasSubmitted}
                >
                    <Select
                        inputId="add-outcome-select"
                        className="form-control-width"
                        value={getElementValue()}
                        onChange={(selection) => onSetTargetElement(selection?.value ?? '')}
                        options={otherMapElements.map((ele) => ({
                            label: ele.developerName,
                            value: ele.id,
                        }))}
                        placeholder={translations.OUTCOME_CREATE_element_select_placeholder}
                        menuPosition="fixed"
                        menuPortalTarget={menuPortalTarget}
                        closeMenuOnScroll={(e) => {
                            if (e.target === document.getElementsByClassName('modal-body')[0]) {
                                return true;
                            }
                            return false;
                        }}
                        styles={getSharedStyles<{
                            label: string | null | undefined;
                            value: string;
                        }>()}
                    />
                </FormGroup>
                <FormGroup
                    label={translations.OUTCOME_CREATE_name_label}
                    htmlFor="add-outcome-name"
                    isRequired
                    validationMessage={translations.OUTCOME_CREATE_name_validation}
                    isValid={isNameValid}
                    showValidation={hasSubmitted}
                >
                    <input
                        id="add-outcome-name"
                        className="form-control form-control-width"
                        value={newOutcome?.developerName}
                        onChange={(e) =>
                            setNewOutcome({
                                ...newOutcome,
                                developerName: e.target.value,
                            })
                        }
                    />
                </FormGroup>
                {labelRequired ? (
                    <FormGroup
                        label={translations.OUTCOME_CREATE_label_label}
                        htmlFor="add-outcome-label"
                        isRequired
                        validationMessage={translations.OUTCOME_CREATE_label_validation}
                        isValid={isLabelValid}
                        showValidation={hasSubmitted}
                    >
                        <input
                            id="add-outcome-label"
                            className="form-control form-control-width"
                            value={newOutcome?.label ?? ''}
                            onChange={(e) =>
                                setNewOutcome({
                                    ...newOutcome,
                                    label: e.target.value,
                                })
                            }
                        />
                    </FormGroup>
                ) : null}
            </div>
        </>
    );

    const renderFooter = () => (
        <>
            <ButtonDefault className="flex-child-right" onClick={onCancel}>
                {translations.GRAPH_config_panel_cancel}
            </ButtonDefault>
            <ButtonPrimary className="margin-left" onClick={onSave}>
                {translations.GRAPH_config_panel_save}
            </ButtonPrimary>
        </>
    );
    return (
        <ConfigModal
            id={sourceMapElement?.id ?? ''}
            title={translations.OUTCOME_CREATE_heading}
            elementType={MAP_ELEMENT_TYPES.outcome}
            onHide={onCancel}
            container={container}
        >
            <ModalBody>{renderBody()}</ModalBody>
            <ModalFooter>{renderFooter()}</ModalFooter>
        </ConfigModal>
    );
};

export default CreateNewOutcome;
