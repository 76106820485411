import { useEffect, useState } from 'react';
import FormGroup from '../../../../../../../../generic/FormGroup';
import { isNullOrEmpty } from '../../../../../../../../../utils/guard';
import translations from '../../../../../../../../../translations';
import ButtonDefault from '../../../../../../../../buttons/ButtonDefault';
import ButtonPrimary from '../../../../../../../../buttons/ButtonPrimary';
import { criteriaOptions } from '../../../../../../../../../config/rules';
import { NOTIFICATION_TYPES } from '../../../../../../../../../constants';
import { usePageEditor } from '../../../../../../PageEditorProvider';
import { getValueList } from '../../../../../../../../../sources/value';
import type {
    ListFilter,
    ContentType,
    AddNotification,
    ValueElementIdReferenceAPI,
    CriteriaType,
    ColumnOption,
} from '../../../../../../../../../types';
import ValueSelectorModal from '../../../../../../../../values/selector/ValueSelectorModal';
import Select from 'react-select';
import { getSharedStyles } from '../../../../../../../../../utils/select';

const getCriteriaOptions = (selectedColumn: ColumnOption | null) => {
    const options = selectedColumn?.type ? criteriaOptions[selectedColumn.type] : [];

    return options.map((c) => ({
        value: c.criteria,
        label: c.label,
    }));
};

const getValueOption = async (
    valueId: string,
    typeId: string | null,
    addNotification: AddNotification,
): Promise<ValueElementIdReferenceAPI | undefined> => {
    try {
        const allValues = await getValueList({ id: valueId });
        const specifiedValue = typeId
            ? allValues.find((vr) => vr.id === valueId && vr.typeElementPropertyId === typeId)
            : // If no typeElement property ID is supplied then we
              // want the top-level value
              allValues.find((vr) => vr.typeElementPropertyId === null);

        return specifiedValue;
    } catch (error) {
        addNotification({
            type: NOTIFICATION_TYPES.error,
            message: (error as Error).message,
            isPersistent: false,
        });
    }

    return;
};

interface CriteriaOption {
    value: CriteriaType;
    label: string;
}

interface Props {
    dataSourceFilter: ListFilter | null;
    selectedItemIndex: number | null;
    columnOptions: ColumnOption[];
    save: (filter: ListFilter) => void;
    cancel: () => void;
    screen?: string;
}

const FilterWhereEditor = ({
    dataSourceFilter,
    selectedItemIndex,
    columnOptions,
    save,
    cancel,
}: Props) => {
    const { addNotification } = usePageEditor();

    const whereItems = dataSourceFilter?.where || [];
    const selectedItem = selectedItemIndex !== null ? whereItems[selectedItemIndex] : null;

    // Setup initial values.
    const itemColumn = selectedItem
        ? columnOptions.filter(
              (option) => option.value === selectedItem.columnTypeElementPropertyId,
          )[0]
        : null;

    const itemCriteria = itemColumn
        ? getCriteriaOptions(itemColumn).filter(
              (option) => option.value === selectedItem?.criteriaType,
          )[0]
        : null;

    const valueId = selectedItem?.valueElementToReferenceId?.id;
    const itemCriteriaValue = valueId ? selectedItem?.valueElementToReferenceId : null;

    // Setup local values.
    const [column, setColumn] = useState<ColumnOption | null>(itemColumn);
    const [criteria, setCriteria] = useState<CriteriaOption | null>(itemCriteria);
    const [criteriaValue, setCriteriaValue] = useState(itemCriteriaValue);

    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [criteriaOptions, setCriteriaOptions] = useState<CriteriaOption[]>([]); // based on column type
    const [valuePickerTypeFilter, setValuePickerTypeFilter] = useState<ContentType | null>(null);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        setColumn(itemColumn);
        setCriteria(itemCriteria);
        setCriteriaValue(itemCriteriaValue);
    }, [selectedItemIndex]);

    // Applicable criteria options need to be adjusted based on the column property type.
    // These options will be different for a string type column, boolean, number, ...
    useEffect(() => {
        if (column) {
            setCriteriaOptions(getCriteriaOptions(column));
        }
    }, [column]);

    // If the criteria is set to 'IS_EMPTY' then we should only allow Boolean types in Value Picker.
    useEffect(() => {
        const isCriteriaEmpty = criteria && criteria.value === 'IS_EMPTY';
        setValuePickerTypeFilter(isCriteriaEmpty ? 'ContentBoolean' : null);
    }, [criteria]);

    const onCancel = () => {
        cancel(); // reset some shared bits so list and editor sync up
        setColumn(null);
        setCriteria(null);
        setCriteriaValue(null);
        setHasSubmitted(false);
    };

    const onSave = async () => {
        // Before saving make sure all condition options have been selected/configured.
        const allConfigured = column && criteria && criteriaValue;

        setHasSubmitted(true);

        if (!allConfigured) {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: 'Cannot save as the condition is not fully configured!',
                isPersistent: false,
            });
            return;
        }

        const valueOption = await getValueOption(
            criteriaValue.id as string,
            criteriaValue.typeElementPropertyId,
            addNotification,
        );

        // Update/create a condition.
        const newCondition = {
            columnTypeElementPropertyDeveloperName: column.label,
            columnTypeElementPropertyId: column.value,
            criteriaType: criteria.value,
            criteriaTypeFriendly: criteria.label,
            valueElementToReferenceDeveloperName: valueOption?.developerName || '',
            // Save value in the DB format.
            valueElementToReferenceId: {
                command: null,
                id: valueOption?.id || '',
                typeElementPropertyId: valueOption?.typeElementPropertyId || null,
            },
        };

        // If editing, replace existing condition with new, else add new condition to the others.
        if (selectedItemIndex !== null && dataSourceFilter) {
            const updatedWhereFilter = [...whereItems];
            updatedWhereFilter[selectedItemIndex] = { ...newCondition };

            save({
                ...dataSourceFilter,
                where: updatedWhereFilter,
            });
        } else if (dataSourceFilter) {
            const updatedWhereFilter = whereItems?.length
                ? [...whereItems, newCondition]
                : [newCondition];

            save({
                ...dataSourceFilter,
                where: updatedWhereFilter,
            });
        }

        // When all done, reset the condition mini editor and close it.
        onCancel();
    };

    return (
        <div className="sidebar-mini-editor">
            <h4 className="sidebar-section-heading">
                {selectedItemIndex !== null ? 'Edit condition' : 'Add new condition'}
            </h4>

            <FormGroup
                label="Filter by column"
                htmlFor="filter-column"
                isValid={!isNullOrEmpty(column)}
                validationMessage={translations.PAGE_BUILDER_field_is_required_validation_message}
                showValidation={hasSubmitted}
                isRequired
            >
                <Select
                    inputId="filter-column"
                    className="select-field"
                    styles={getSharedStyles<ColumnOption>()}
                    options={columnOptions}
                    onChange={(selectedColumn) => setColumn(selectedColumn)}
                    placeholder="Available columns"
                    value={column}
                    noOptionsMessage={() => 'No results found'}
                />
            </FormGroup>

            <FormGroup
                label="Filter criteria"
                htmlFor="filter-criteria"
                isValid={!isNullOrEmpty(criteria)}
                validationMessage={translations.PAGE_BUILDER_field_is_required_validation_message}
                showValidation={hasSubmitted}
                isRequired
            >
                <Select
                    inputId="filter-criteria"
                    className="select-field"
                    styles={getSharedStyles<CriteriaOption>()}
                    options={criteriaOptions}
                    onChange={(selectedCriteria) => setCriteria(selectedCriteria)}
                    placeholder="Available criteria"
                    value={criteria}
                    noOptionsMessage={() => 'No results found'}
                />
            </FormGroup>

            <FormGroup
                label="Value"
                htmlFor="filter-value"
                isValid={!isNullOrEmpty(criteriaValue)}
                validationMessage={translations.PAGE_BUILDER_field_is_required_validation_message}
                showValidation={hasSubmitted}
                isRequired
            >
                <ValueSelectorModal
                    value={criteriaValue}
                    onChangeAsValueReference={setCriteriaValue}
                    contentType={valuePickerTypeFilter}
                    typeElementId={criteriaValue?.typeElementPropertyId ?? null}
                    container={null}
                    includeSystemValues={true}
                />
            </FormGroup>

            <footer className="sidebar-mini-editor-footer">
                <ButtonDefault onClick={onCancel}>Cancel</ButtonDefault>
                <ButtonPrimary onClick={onSave}>Save</ButtonPrimary>
            </footer>
        </div>
    );
};

export default FilterWhereEditor;
