import ButtonPrimary from '../../../../buttons/ButtonPrimary';
import Glyphicon from '../../../../generic/Glyphicon';
import translations from '../../../../../translations';

import { useMapElement } from '../../contextProviders/MapElementProvider';
import { useDataAction } from '../../contextProviders/DataActionProvider';
import { Trash } from '@phosphor-icons/react';
import Table, { type TableColumnList } from '../../../../generic/Table';
import type { DataAction } from '../../../../../types';

const DataActions = () => {
    const { mapElement } = useMapElement();
    const { onCreateDataAction, onEditDataAction, onDeleteDataAction } = useDataAction();

    const { dataActions } = mapElement;

    const columns: TableColumnList<DataAction> = [
        {
            renderHeader: () => translations.COMMON_TABLE_name,
            renderCell: ({ item, rowIndex }) => (
                <button
                    className="link-emulate"
                    title={`Edit ${item.developerName}`}
                    onClick={() => onEditDataAction(item, rowIndex)}
                    type="button"
                >
                    {item.developerName ?? ''}
                </button>
            ),
        },
        {
            renderHeader: () => translations.COMMON_TABLE_order,
            renderCell: ({ item: { order } }) => order,
            size: '5rem',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_actions,
            renderCell: ({ item, rowIndex }) => (
                <div className="action-btn-wrapper">
                    <button
                        title={`Delete ${item.developerName}`}
                        className="table-icon table-icon-delete margin-left"
                        aria-label={`Delete ${item.developerName}`}
                        onClick={() => onDeleteDataAction(rowIndex)}
                        type="button"
                    >
                        <Trash />
                    </button>
                </div>
            ),
            size: '5rem',
        },
    ];

    return (
        <>
            <h4>{translations.DATA_ACTION_heading}</h4>
            <div className="margin-bottom flex">
                <ButtonPrimary title="Add Data Action" onClick={onCreateDataAction}>
                    <Glyphicon glyph="plus" />
                    {translations.DATA_ACTION_add_button_text}
                </ButtonPrimary>
            </div>
            <Table
                columns={columns}
                wrapperClassName="margin-bottom-large"
                items={dataActions ?? []}
                testId="data-action-list"
                tableId="data-action-list"
            />
        </>
    );
};

export default DataActions;
