import classnames from 'classnames';
import { pathOr } from 'ramda';
import { Component, createRef } from 'react';
import { connect } from 'react-redux';
import '../../../../css/flow/authorization-config.less';
import '../../../../css/flow/editor.less';
import '../../../../css/modal.less';
import Presence from '../../../ts/components/collaboration/Presence';
import {
    addNotification,
    notifyError,
    notifySuccess,
} from '../../actions/reduxActions/notification';
import {
    NOTIFICATION_TYPES,
    ONE_OUTCOME_ELEMENTS,
    MAP_ELEMENT_CONFIGS,
    FAULT_OUTCOME_ELEMENTS,
    FAULT_OUTCOME_ID,
    COLLABORATION_ITEM_TYPES,
    FLOW_EDITING_TOKEN,
} from '../../../ts/constants';
import { getMapElement as getMapElementAPI } from '../../../ts/sources/graph';
import { getByID } from '../../../ts/utils/collection';
import { isEqualCaseInsensitive } from '../../../ts/utils/string';
import { guid } from '../../../ts/utils/guid';
import Graph from '../graph/Graph';
import { useGraphHOC } from '../graph/GraphProvider';
import MetadataEditor from '../../../ts/components/MetadataEditor';
import Delete from '../../../ts/components/flow/elementConfigurations/delete/Delete';
import GroupConfiguration from '../../../ts/components/flow/elementConfigurations/group/GroupConfiguration';
import Load from '../../../ts/components/flow/elementConfigurations/load/Load';
import Message from '../../../ts/components/flow/elementConfigurations/message/Message';
import Operator from '../../../ts/components/flow/elementConfigurations/operator/Operator';
import CreateNewOutcome from '../../../ts/components/flow/elementConfigurations/outcome/CreateNewOutcome';
import Outcome from '../../../ts/components/flow/elementConfigurations/outcome/Outcome';
import OutcomeRedirect from '../../../ts/components/flow/elementConfigurations/outcome/OutcomeRedirect';
import OutcomeSourceRedirect from '../../../ts/components/flow/elementConfigurations/outcome/OutcomeSourceRedirect';
import Process from '../../../ts/components/flow/elementConfigurations/process/Process';
import Save from '../../../ts/components/flow/elementConfigurations/save/Save';
import Step from '../../../ts/components/flow/elementConfigurations/step/Step';
import SubFlow from '../../../ts/components/flow/elementConfigurations/subflow/SubFlow';
import Swimlane from '../../../ts/components/flow/elementConfigurations/swinlane/Swimlane';
import Wait from '../../../ts/components/flow/elementConfigurations/common/wait/Wait';
import MultiElementDelete from '../../../ts/components/flow/elementConfigurations/common/MultiElementDelete';
import OutcomeInsights from '../../../ts/components/flow/insights/OutcomeInsights';
import { createNewOutcomeAndNewMapElement } from '../graph/utils';
import OpenApi from '../../../ts/components/flow/elementConfigurations/openapi/OpenApi';
import Page from '../../../ts/components/flow/elementConfigurations/page/Page';
import Modal from '../../../ts/components/flow/elementConfigurations/page/Modal';
import Decision from '../../../ts/components/flow/elementConfigurations/decision/Decision';

export const SELECTED_FLOW_KEY = 'selected-flow';

class FlowEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedMapElementId: null,
            newPageMoveDate: null,
            configPanel: {
                component: null,
                props: null,
            },
            users: [],
        };

        this.containerRef = createRef();
        this.flowEditorWrapperRef = createRef();

        this.setSelectedMapElement = this.setSelectedMapElement.bind(this);
        this.toggleMetadata = this.toggleMetadata.bind(this);
        this.openConfig = this.openConfig.bind(this);
        this.closeConfig = this.closeConfig.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.dismissMapElementConfig = this.dismissMapElementConfig.bind(this);
    }

    componentDidMount() {
        document.addEventListener('keydown', this.onKeyDown);

        this.props.collaboration.itemOpened(
            this.props.flowId,
            undefined,
            COLLABORATION_ITEM_TYPES.flow,
        );

        window.sessionStorage.setItem(SELECTED_FLOW_KEY, this.props.flowId);
    }

    componentDidUpdate(previousProps) {
        if (this.props.flowId === previousProps.flowId) {
            return;
        }

        window.sessionStorage.setItem(SELECTED_FLOW_KEY, this.props.flowId);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onKeyDown);
        this.props.collaboration.itemClosed(this.props.flowId, COLLABORATION_ITEM_TYPES.flow);

        window.sessionStorage.removeItem(SELECTED_FLOW_KEY);
    }

    onKeyDown(e) {
        if (this.props.isActive) {
            if (e.key === 'Escape') {
                const openElementId = this.state.configPanel?.props?.id;
                this.props.focusAndSelectElement(openElementId);

                // Only call this expensive refresh if a config is open
                if (this.state.configPanel?.component || this.props.isMapElementPickerOpen) {
                    // Reset the flow canvas to reset possible local outcome changes
                    this.props.refreshFlow();
                }
                this.props.setIsMapElementPickerOpen(false);
                this.props.setMapElementPickerFromMapElementId();
            }
        }
    }

    setSelectedMapElement(id, elementType) {
        this.setState({
            selectedMapElementId: id,
            selectedMapElementType: elementType,
        });
        this.props.setIsMetadataMenuOpen(true);
    }

    saveReturnElement = async (configProps, clearAllSideBarDragElements) => {
        const id = guid();
        const developerName = 'Return to Parent Flow';

        await this.props.saveMapElement({
            ...configProps,
            id,
            developerName,
        });

        if (configProps.fromMapElementId) {
            await createNewOutcomeAndNewMapElement(
                configProps.fromMapElementId,
                this.props.mapElements,
                developerName,
                id,
                this.props.saveMapElement,
                this.props.getMapElement,
                notifyError,
            );
        }

        // clears temporary sidebar dragging element
        clearAllSideBarDragElements?.();
    };

    openConfiguration = (configProps, component) => {
        this.props.setIsConfigMenuOpen(true);
        this.setState({
            configPanel: {
                component,
                props: configProps,
            },
        });
    };

    openNote = async (configProps, clearAllSideBarDragElements) => {
        await this.props.saveNote(
            {
                ...configProps,
                developerName: 'New Note',
            },
            true,
        );

        // clears temporary sidebar dragging element
        clearAllSideBarDragElements?.();
    };

    outcomeSourceRedirect = async (configProps) => {
        try {
            const response = await Promise.all([
                getMapElementAPI(
                    configProps.sourceMapElementId,
                    this.props.flowId,
                    FLOW_EDITING_TOKEN,
                ),
                getMapElementAPI(
                    configProps.targetMapElementId,
                    this.props.flowId,
                    FLOW_EDITING_TOKEN,
                ),
            ]);

            const outcomeToMove = getByID(configProps.outcomeId, response[0]?.outcomes);
            const errorMessage = this.outcomeSourceTargetIsValid(response[1], outcomeToMove);

            if (errorMessage === null) {
                configProps.sourceMapElementProp = response[0];
                configProps.targetMapElementProp = response[1];

                this.openConfiguration(configProps, OutcomeSourceRedirect);
            } else {
                this.props.addNotification({
                    type: NOTIFICATION_TYPES.error,
                    message: errorMessage,
                    isPersistent: true,
                });

                this.props.refreshFlow();
            }
        } catch (error) {
            this.props.addNotification({
                type: NOTIFICATION_TYPES.error,
                message: error.message,
                isPersistent: true,
            });
        }
    };

    addNewOutcome = (configProps) => {
        const sourceElement = this.props.mapElements.find(
            (me) => me.id === configProps.sourceMapElementId,
        );

        if (
            ONE_OUTCOME_ELEMENTS.includes(sourceElement.elementType.toLowerCase()) &&
            sourceElement.outcomes?.length >= 1
        ) {
            this.props.addNotification({
                type: NOTIFICATION_TYPES.error,
                message: `Element type ${sourceElement.elementType.toUpperCase()} can only have one outcome.`,
                isPersistent: true,
            });
        } else {
            this.openConfiguration(configProps, CreateNewOutcome);
        }
    };

    async openConfig(configName, configProps = {}, clearAllSideBarDragElements = () => undefined) {
        configProps.refreshFlow = this.props.refreshFlow;
        configProps.focusAndSelectElement = this.props.focusAndSelectElement;
        configProps.currentUserId = this.props.currentUserId;

        switch (configName) {
            case MAP_ELEMENT_CONFIGS.step:
                return this.openConfiguration(configProps, Step);
            case MAP_ELEMENT_CONFIGS.input:
                return this.openConfiguration(configProps, Page);
            case MAP_ELEMENT_CONFIGS.modal:
                return this.openConfiguration(configProps, Modal);
            case MAP_ELEMENT_CONFIGS.decision:
                return this.openConfiguration(configProps, Decision);
            case MAP_ELEMENT_CONFIGS.operator:
                return this.openConfiguration(configProps, Operator);
            case MAP_ELEMENT_CONFIGS.message:
                return this.openConfiguration(configProps, Message);
            case MAP_ELEMENT_CONFIGS.process:
                return this.openConfiguration(configProps, Process);
            case MAP_ELEMENT_CONFIGS.wait:
                return this.openConfiguration(configProps, Wait);
            case MAP_ELEMENT_CONFIGS.databaseLoad:
                return this.openConfiguration(configProps, Load);
            case MAP_ELEMENT_CONFIGS.databaseSave:
                return this.openConfiguration(configProps, Save);
            case MAP_ELEMENT_CONFIGS.databaseDelete:
                return this.openConfiguration(configProps, Delete);
            case MAP_ELEMENT_CONFIGS.swimlane:
                return this.openConfiguration(configProps, Swimlane);
            case MAP_ELEMENT_CONFIGS.subflow:
                return this.openConfiguration(configProps, SubFlow);
            case MAP_ELEMENT_CONFIGS.return:
                return this.saveReturnElement(configProps, clearAllSideBarDragElements);
            case MAP_ELEMENT_CONFIGS.group:
                return this.openConfiguration(configProps, GroupConfiguration);
            case MAP_ELEMENT_CONFIGS.note:
                return this.openNote(configProps, clearAllSideBarDragElements);
            case MAP_ELEMENT_CONFIGS.outcome:
                return this.openConfiguration(configProps, Outcome);
            case MAP_ELEMENT_CONFIGS.outcomeRedirect:
                return this.openConfiguration(configProps, OutcomeRedirect);
            case MAP_ELEMENT_CONFIGS.outcomeSourceRedriect:
                return await this.outcomeSourceRedirect(configProps);
            case MAP_ELEMENT_CONFIGS.addOutcome:
                return this.addNewOutcome(configProps);
            case MAP_ELEMENT_CONFIGS.multiDelete:
                return this.openConfiguration(configProps, MultiElementDelete);
            case MAP_ELEMENT_CONFIGS.openapi:
                return this.openConfiguration(configProps, OpenApi);
        }

        console.error(`Could not open config for ${configName}`);
    }

    closeConfig(_callback, response) {
        const rootFaults = pathOr(null, ['mapElementInvokeResponses', 0, 'rootFaults'], response);

        if (response && rootFaults) {
            const rootFaultsAsJson = JSON.parse(rootFaults);
            Object.keys(rootFaults).forEach((fault) => {
                if (rootFaultsAsJson[fault]) {
                    const error = rootFaultsAsJson[fault];

                    this.props.addNotification({
                        type: NOTIFICATION_TYPES.error,
                        message: error.responseBody,
                    });
                }
            });
        }
    }

    toggleMetadata() {
        this.props.refreshFlow();
        this.props.setIsMetadataMenuOpen(!this.props.isMetadataMenuOpen);
    }

    dismissMapElementConfig() {
        if (this.props.isActive) {
            this.props.setIsConfigMenuOpen(false);
            this.setState({
                configPanel: {
                    component: null,
                    props: null,
                },
            });
        }
    }

    outcomeSourceTargetIsValid = (target, outcome) => {
        if (
            ONE_OUTCOME_ELEMENTS.includes(target.elementType.toLowerCase()) &&
            target.outcomes?.length >= 1
        ) {
            return `Target element type ${target.elementType.toUpperCase()} can only have one outcome.`;
        }

        if (target.elementType.toLowerCase() === 'return') {
            return 'The RETURN element cannot have outcomes.';
        }

        if (isEqualCaseInsensitive(outcome.id, FAULT_OUTCOME_ID)) {
            if (!FAULT_OUTCOME_ELEMENTS.includes(target.elementType.toLowerCase())) {
                return `FAULT outcome cannot be moved to ${target.elementType.toUpperCase()} element.`;
            }
            if (
                target.outcomes?.some((outcome) =>
                    isEqualCaseInsensitive(outcome.id, FAULT_OUTCOME_ID),
                )
            ) {
                return 'There can only be one FAULT outcome per element.';
            }
        }

        return null;
    };

    onMoveToCursor = (userId) => {
        this.props.collaboration.moveToCursor(this.props.flowId, userId);
    };

    render() {
        const { configPanel } = this.state;
        const ConfigPanelComponent = configPanel?.component ?? null;
        const configPanelProps = configPanel?.props ?? null;

        const insightOutcomes = this.props.mapElements.find(
            (mapElement) => mapElement.id === this.props.insightsConfigView?.mapElementId,
        )?.outcomes;

        const classes = classnames({
            admin: true,
            active: this.props.isActive,
            'flex-column': this.props.isActive,
            'flow-editor': true,
        });

        return (
            <div id={`flow-${this.props.flowId}`} className={classes} ref={this.containerRef}>
                <Graph
                    openMetadataEditor={this.setSelectedMapElement}
                    flowId={this.props.flowId}
                    openConfig={this.openConfig}
                    showBackdrop={this.props.isConfigMenuOpen || this.props.isMapElementPickerOpen}
                    isActive={this.props.isActive}
                    users={this.state.users}
                    currentUserId={this.props.currentUserId}
                    modalContainer={this.containerRef.current}
                    notifyError={this.props.notifyError}
                    notifySuccess={this.props.notifySuccess}
                    setTabTitle={this.props.setTabTitle}
                    releases={this.props.releases}
                    environments={this.props.environments}
                    loadReleases={this.props.loadReleases}
                    isLoadingReleases={this.props.isLoadingReleases}
                    getFlag={this.props.getFlag}
                />

                {ConfigPanelComponent && (
                    <ConfigPanelComponent
                        {...configPanelProps}
                        container={this.containerRef.current}
                        dismissMapElementConfig={this.dismissMapElementConfig}
                        flowId={this.props.flowId}
                        editingToken={FLOW_EDITING_TOKEN}
                        tenantId={this.props.tenantId}
                        mapElements={this.props.mapElements}
                        save={this.props.saveMapElement}
                        notifyError={this.props.notifyError}
                        notifySuccess={this.props.notifySuccess}
                    />
                )}

                <MetadataEditor
                    container={this.containerRef.current}
                    isVisible={this.props.isMetadataMenuOpen}
                    id={this.state.selectedMapElementId}
                    elementType={this.state.selectedMapElementType}
                    flowId={this.props.flowId}
                    toggleMetadata={this.toggleMetadata}
                    notifyError={this.props.notifyError}
                    notifySuccess={this.props.notifySuccess}
                />

                {!!this.props.insightsConfigView && this.props.getFlag('IOE') && (
                    <OutcomeInsights
                        outcomes={insightOutcomes}
                        isOpen={!!this.props.insightsConfigView}
                        container={this.containerRef.current}
                        mapElementId={this.props.insightsConfigView?.mapElementId}
                        flowId={this.props.flowId}
                        onClose={() => this.props.setInsightsConfigView(null)}
                        addNotification={this.props.addNotification}
                    />
                )}

                <Presence
                    users={this.props.collaboration.users}
                    presentUsers={this.props.collaboration.items[this.props.flowId]?.users}
                    currentUserId={this.props.currentUserId}
                    onUserDoubleClick={this.onMoveToCursor}
                />
            </div>
        );
    }
}

export default connect(null, {
    addNotification,
    notifyError,
    notifySuccess,
    // biome-ignore lint/correctness/useHookAtTopLevel: Treat warnings as errors, fix later
})(useGraphHOC(FlowEditor));
