import actionTypes from '../actions/reduxActions/actionTypes';

const initialState = {
    all: [],
    activated: [],
    selectedFlowId: null,
    // Export props
    exportedJSON: null,
    sharingToken: null,
    includePasswords: false,
    // Import props
    importedJSON: null,
    importToken: null,
    showImportConfirmation: false,
    importSuccess: false,
    importConflict: false,
    importConflictDependents: {},
    isDeleting: false,
    isLoading: false,
};

const updateFlowLabels = (currentAll, payload) => {
    const newAll = currentAll.filter((x) => x.id !== payload.id);
    newAll.push(payload);
    return newAll;
};

// biome-ignore lint/style/useDefaultParameterLast: Called automatically by Redux, not an issue
const flows = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.FLOW_LIST_LOADING:
            return {
                ...state,
                isLoading: true,
            };

        case actionTypes.FLOW_LIST_RESPONSE:
            return {
                ...state,
                all: payload,
                isLoading: false,
            };
        case actionTypes.FLOWS_ACTIVATED_RESPONSE:
            return {
                ...state,
                activated: payload,
            };

        case actionTypes.FLOW_SELECT_EXPORT:
            return {
                ...state,
                exportedJSON: null,
                sharingToken: null,
                selectedFlowId: payload,
            };

        case actionTypes.FLOW_SET_EXPORTED_ESCAPED_JSON:
            return {
                ...state,
                exportedJSON: payload,
            };

        case actionTypes.FLOW_SET_GENERATED_SHARED_TOKEN:
            return {
                ...state,
                sharingToken: payload.token,
            };

        case actionTypes.FLOW_SET_IMPORT_TOKEN:
            return {
                ...state,
                importToken: payload,
            };

        case actionTypes.FLOW_SET_IMPORTED_PACKAGE:
            return {
                ...state,
                importedJSON: payload,
            };

        case actionTypes.FLOW_INCLUDE_PASSWORDS:
            return {
                ...state,
                includePasswords: !!payload,
            };

        case actionTypes.FLOW_SHOW_IMPORT_CONFIRMATION:
            return {
                ...state,
                showImportConfirmation: payload,
                importConflict: false,
                importConflictDependents: {},
            };

        case actionTypes.FLOW_IMPORT_SUCCESS:
            return {
                ...state,
                importedJSON: null,
                showImportConfirmation: false,
                importSuccess: payload,
                importConflict: false,
                importConflictDependents: {},
            };

        case actionTypes.FLOW_IMPORT_CONFLICT:
            return {
                ...state,
                showImportConfirmation: false,
                importSuccess: false,
                importConflict: payload.hasConflict,
                importConflictDependents: payload.conflictingDependents,
            };

        default:
            return state;
    }
};

export default flows;
