import { useState, createContext, useContext, type ReactElement, type ChangeEvent } from 'react';
import { isNullOrEmpty } from '../../../../utils';
import { useMapElement } from './MapElementProvider';
import type {
    OpenApiAction,
    OpenApiParameterInfo,
    OpenApiSchemaInfo,
    OpenApiServiceAPI,
    ValueElementIdReferenceAPI,
} from '../../../../types';
import screens, { type Screen } from '../common/screens';
import { GetOpenApiServices, getOpenApiSchemaInfoFromServiceId } from '../../../../sources/openapi';

export type SetHasSubmitted = (state: boolean) => void;
export type OnApplyOpenApiAction = (index: number) => void;
export type OnCreateOpenApiAction = () => void;
export type OnEditOpenApiAction = (openApiAction: OpenApiAction, index: number) => void;
export type OnDeleteOpenApiAction = (index: number) => void;
export type OnReturnToDefaultScreen = () => void;
export type OnValueChanged = (value: ValueElementIdReferenceAPI | null) => void;
export type OnAdditionalValueChanged = (value: ValueElementIdReferenceAPI | null) => void;
export type OnActionValueChanged = (
    value: ValueElementIdReferenceAPI | null,
    item: OpenApiParameterInfo,
) => void;
export type SetOpenApiSchemaInfo = (schemaInfo: OpenApiSchemaInfo | undefined) => void;
export type Operations = 'Load' | 'Save' | 'Update' | 'Delete';
export type OnOperationChange = ({ target: { value } }: ChangeEvent<HTMLSelectElement>) => void;
export type OnPathChange = ({ target: { value } }: ChangeEvent<HTMLSelectElement>) => void;
export type OnServiceChange = ({ target: { value } }: ChangeEvent<HTMLSelectElement>) => void;
export type GetParameterInfo = (path: string) => void;
export type SetOpenApiServices = (openApiServices: OpenApiServiceAPI[]) => void;
export type OnDeleteTypeChange = ({ target: { value } }: ChangeEvent<HTMLSelectElement>) => void;

interface Props {
    defaultScreen: Screen;
    children?: ReactElement;
}

interface OpenApiActionTestProps {
    hasChanged: boolean;
    hasChangedComplete: boolean;
}

interface OpenApiActionToEditState {
    index: null | number;
    openApiAction: null | OpenApiAction;
    testProps?: OpenApiActionTestProps;
}

interface OpenApiContext {
    hasSubmitted: boolean;
    setHasSubmitted: SetHasSubmitted;
    operationTypes: string[];
    operationLoad: string;
    operationSave: string;
    operationUpdate: string;
    operationDelete: string;
    onOperationChange: OnOperationChange;
    isNameValid: () => boolean;
    onUpdateName: (developerName: string) => void;
    isValidOpenApiElement: () => boolean;
    isValidOpenApiAction: () => boolean;
    isValidServiceValue: () => boolean;
    isValidPath: () => boolean;
    isValidParameters: () => boolean;
    openApiActionToEdit: OpenApiActionToEditState;
    onCreateOpenApiAction: OnCreateOpenApiAction;
    onEditOpenApiAction: OnEditOpenApiAction;
    onDeleteOpenApiAction: OnDeleteOpenApiAction;
    onApplyOpenApiAction: OnApplyOpenApiAction;
    onReturnToDefaultScreen: OnReturnToDefaultScreen;
    isActionNameValid: () => boolean;
    onUpdateActionName: (developerName: string) => void;
    onValueChanged: OnValueChanged;
    onActionValueChanged: OnActionValueChanged;
    setOpenApiSchemaInfo: SetOpenApiSchemaInfo;
    openApiSchemaInfo: OpenApiSchemaInfo | undefined;
    onPathChange: OnPathChange;
    getParameterInfo: GetParameterInfo;
    openApiServices: OpenApiServiceAPI[];
    setOpenApiServices: SetOpenApiServices;
    onServiceChange: OnServiceChange;
    deleteType: string;
    setDeleteType: (deleteType: string) => void;
    deleteTypeConnector: string;
    deleteTypeValue: string;
    onDeleteTypeChange: OnDeleteTypeChange;
    isValidService: () => boolean;
    onAdditionalValueChanged: OnAdditionalValueChanged;
}

const operationLoad = 'Load';
const operationSave = 'Save';
const operationDelete = 'Delete';
const operationUpdate = 'Update';

const deleteTypeConnector = 'Connector';
const deleteTypeValue = 'Value';

const Context = createContext<OpenApiContext | undefined>(undefined);

const OpenApiProvider = (props: Props) => {
    const { mapElement, setMapElement, onSwitchScreen } = useMapElement();
    const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
    const [openApiActionToEdit, setOpenApiActionToEdit] = useState<OpenApiActionToEditState>({
        openApiAction: null,
        index: null,
    });
    const operationTypes = [operationLoad, operationSave, operationUpdate, operationDelete];
    const elementToEdit = openApiActionToEdit.openApiAction;
    const [openApiSchemaInfo, setOpenApiSchemaInfo] = useState<OpenApiSchemaInfo>();
    const [openApiServices, setOpenApiServices] = useState<OpenApiServiceAPI[]>([]);
    const [deleteType, setDeleteType] = useState<string>('connector');

    const initialOpenApiAction: OpenApiAction = { operationType: operationLoad };

    const onOperationChange = async ({ target: { value } }: ChangeEvent<HTMLSelectElement>) => {
        setOpenApiActionToEdit({
            ...openApiActionToEdit,
            openApiAction: {
                ...openApiActionToEdit.openApiAction,
                operationType: value,
                path: null,
                parameters: [],
                serviceId: null,
                value: null,
                additionalValue: null,
            },
        });

        if (value === operationDelete) {
            setOpenApiServices(await GetOpenApiServices());
        }
    };

    const onPathChange = ({ target: { value } }: ChangeEvent<HTMLSelectElement>) => {
        setOpenApiActionToEdit({
            ...openApiActionToEdit,
            openApiAction: { ...openApiActionToEdit.openApiAction, path: value, parameters: [] },
        });

        getParameterInfo(value);
        return;
    };

    const onServiceChange = async ({ target: { value } }: ChangeEvent<HTMLSelectElement>) => {
        setOpenApiActionToEdit({
            ...openApiActionToEdit,
            openApiAction: {
                ...openApiActionToEdit.openApiAction,
                serviceId: value,
                path: null,
                parameters: [],
            },
        });
        const info = await getOpenApiSchemaInfoFromServiceId({ serviceId: value });
        if (info?.schemaInfo) {
            setOpenApiSchemaInfo(info.schemaInfo);
        }

        return;
    };

    const getParameterInfo = (path: string) => {
        if (openApiSchemaInfo) {
            const matchingPath = openApiSchemaInfo.paths.find((x) => x.path === path);
            if (!matchingPath) {
                return;
            }
            setOpenApiActionToEdit({
                ...openApiActionToEdit,
                openApiAction: {
                    ...openApiActionToEdit.openApiAction,
                    parameters: matchingPath.parameterInfos,
                    path: path,
                },
            });
        }
    };

    const isNameValid = () => !isNullOrEmpty(mapElement.developerName);

    const onUpdateName = (developerName: string) => {
        setMapElement({
            ...mapElement,
            developerName,
        });
        return;
    };

    const isActionNameValid = () => !isNullOrEmpty(elementToEdit?.developerName);

    const onUpdateActionName = (developerName: string) => {
        setOpenApiActionToEdit({
            ...openApiActionToEdit,
            openApiAction: { ...openApiActionToEdit.openApiAction, developerName },
        });
        return;
    };

    const isValidOpenApiElement = () => {
        const isValid = isNameValid();

        return isValid;
    };

    const isValidOpenApiAction = () => {
        return isActionNameValid() && isValidServiceValue() && isValidPath() && isValidParameters();
    };

    const isValidServiceValue = () => {
        if (
            elementToEdit?.operationType === operationDelete &&
            deleteType === deleteTypeConnector
        ) {
            return true;
        }

        if (!elementToEdit || isNullOrEmpty(elementToEdit?.value)) {
            return false;
        }
        return true;
    };

    const isValidPath = () => {
        return !isNullOrEmpty(elementToEdit?.path);
    };

    const isValidParameters = () => {
        //There could be no parameters and this is fine.
        if (elementToEdit?.path && isNullOrEmpty(elementToEdit.parameters)) {
            return true;
        }

        if (elementToEdit?.parameters) {
            const hasEmptyRequiredParams = elementToEdit?.parameters.some((element) => {
                if (element.required && isNullOrEmpty(element.value)) {
                    return true;
                }
                return false;
            });
            if (hasEmptyRequiredParams) {
                return false;
            }
        }
        return true;
    };

    const isValidService = () => {
        if (elementToEdit?.serviceId) {
            return true;
        }
        return false;
    };

    const onValueChanged = (value: ValueElementIdReferenceAPI | null) => {
        setOpenApiActionToEdit({
            ...openApiActionToEdit,
            openApiAction: {
                ...openApiActionToEdit.openApiAction,
                value,
                additionalValue: null,
                path: null,
                parameters: [],
            },
        });
    };

    const onAdditionalValueChanged = (additionalValue: ValueElementIdReferenceAPI | null) => {
        setOpenApiActionToEdit({
            ...openApiActionToEdit,
            openApiAction: {
                ...openApiActionToEdit.openApiAction,
                additionalValue,
            },
        });
    };

    const onActionValueChanged = (
        value: ValueElementIdReferenceAPI | null,
        item: OpenApiParameterInfo,
    ) => {
        const parameters = openApiActionToEdit.openApiAction?.parameters ?? [];
        const param = parameters.find((x) => x.name === item.name);

        if (param) {
            parameters[parameters.indexOf(param)] = { ...param, value };
        } else {
            parameters.push({ ...item, value } as OpenApiParameterInfo);
        }

        setOpenApiActionToEdit({
            ...openApiActionToEdit,
            openApiAction: { ...openApiActionToEdit.openApiAction, parameters },
        });
    };

    const onCreateOpenApiAction = () => {
        const totalOpenApiActions = mapElement.openApiActions
            ? mapElement.openApiActions.length
            : 0;
        setOpenApiActionToEdit({
            openApiAction: { ...initialOpenApiAction },
            index: totalOpenApiActions + 1,
        });
        setDeleteType('Connector');
        onSwitchScreen(screens.openApiDetails);
    };

    const onEditOpenApiAction = (openApiAction: OpenApiAction, index: number) => {
        setOpenApiActionToEdit({ openApiAction, index });
        const isDeleteOperation = openApiAction.operationType === operationDelete;
        setDeleteType(
            isDeleteOperation && openApiAction?.serviceId
                ? 'Connector'
                : isDeleteOperation && openApiAction?.value
                  ? 'Value'
                  : 'Connector',
        );
        onSwitchScreen(screens.openApiDetails);
    };

    const onDeleteOpenApiAction = (index: number) => {
        setMapElement({
            ...mapElement,
            openApiActions: mapElement?.openApiActions?.filter((_, i) => i !== index) ?? [],
        });
    };

    const onDeleteTypeChange = ({ target: { value } }: ChangeEvent<HTMLSelectElement>) => {
        setDeleteType(value);
        setOpenApiActionToEdit({
            ...openApiActionToEdit,
            openApiAction: {
                ...openApiActionToEdit.openApiAction,
                value: null,
                additionalValue: null,
                serviceId: null,
                path: null,
                parameters: [],
            },
        });
        setOpenApiSchemaInfo(undefined);
    };

    const onApplyOpenApiAction = (index: number) => {
        resetState();
        const actionExists = mapElement.openApiActions
            ? mapElement.openApiActions.find((_, i) => i === index)
            : null;
        const localAction = openApiActionToEdit.openApiAction;

        if (localAction) {
            const openApiActions: OpenApiAction[] | null = actionExists
                ? (mapElement?.openApiActions?.map((existingAction, i) =>
                      i === index ? localAction : existingAction,
                  ) ?? [])
                : [...(mapElement.openApiActions ?? []), localAction ?? []];

            setMapElement({ ...mapElement, openApiActions });
        }

        onSwitchScreen(props.defaultScreen);
    };

    const onReturnToDefaultScreen = () => {
        resetState();
        onSwitchScreen(props.defaultScreen);
    };

    const resetState = () => {
        setHasSubmitted(false);
        setOpenApiSchemaInfo(undefined);
    };

    const contextValue: OpenApiContext = {
        hasSubmitted,
        setHasSubmitted,
        operationTypes,
        operationLoad,
        operationSave,
        operationUpdate,
        operationDelete,
        onOperationChange,
        isNameValid,
        onUpdateName,
        isValidOpenApiElement,
        isValidServiceValue,
        isValidPath,
        isValidParameters,
        openApiActionToEdit,
        onApplyOpenApiAction,
        onCreateOpenApiAction,
        onDeleteOpenApiAction,
        onEditOpenApiAction,
        isValidOpenApiAction,
        onReturnToDefaultScreen,
        isActionNameValid,
        onUpdateActionName,
        onValueChanged,
        onActionValueChanged,
        setOpenApiSchemaInfo,
        openApiSchemaInfo,
        onPathChange,
        getParameterInfo,
        openApiServices,
        setOpenApiServices,
        onServiceChange,
        deleteType,
        setDeleteType,
        deleteTypeConnector,
        deleteTypeValue,
        onDeleteTypeChange,
        isValidService,
        onAdditionalValueChanged,
    };

    return <Context.Provider value={contextValue}>{props.children}</Context.Provider>;
};

const useOpenApi = () => {
    const context = useContext(Context);
    if (context === undefined) {
        throw new Error('useOpenApi must be used within a OpenApiProvider');
    }
    return context;
};

export { OpenApiProvider, useOpenApi };
