import { useEffect, useState } from 'react';
import { useAuth } from '../../AuthProvider';
import CopyableText from '../../generic/CopyableText';
import Table, { type TableColumnList } from '../../generic/Table';
import { RUNTIME_URI } from '../../../constants';
import translations from '../../../translations';
import { isNullOrUndefined } from '../../../utils/guard';
import SearchInput from '../../generic/SearchInput';
import FlowIcon from '../../icons/FlowIcon';
import { useEnvironments } from './EnvironmentsProvider';
import type { Environment, EnvironmentFlow } from '../../../types/environment';

type Props = {
    environment: Environment | null;
};

const EnvironmentFlows = ({ environment }: Props) => {
    const { getEnvironmentFlows, tenantId, environmentFlowsLoading } = useEnvironments();
    const { tenant } = useAuth();

    const [flows, setFlows] = useState<EnvironmentFlow[]>([]);
    const [page, setPage] = useState(1);
    const pageSize = 20;
    const [total, setTotal] = useState<number>(0);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        loadEnvironmentFlows(1, null);
    }, []);

    async function loadEnvironmentFlows(page: number, term: string | null) {
        if (environment) {
            const params = {
                environmentId: environment.id,
                page: page ?? 1,
                pageSize,
                searchTerm: term,
            };

            const response = await getEnvironmentFlows(params);

            setFlows(response?.items || []);
            setTotal(response?._meta.total || 0);
        }
    }

    const onSearch = (term: string) => {
        setPage(1);
        setSearchQuery(term);
        loadEnvironmentFlows(1, term);
    };

    function getFlowUrl(flowId: string) {
        let url = '';
        if (!tenant?.tenantSettings?.themes) {
            url = `${RUNTIME_URI}/${tenantId}/play/${environment?.defaultPlayerName}?flow-id=${flowId}&environment-id=${environment?.id}`;
        } else if (tenant?.tenantSettings?.themes) {
            url = `${RUNTIME_URI}/${tenantId}/play/theme/${environment?.defaultThemeName}?flow-id=${flowId}&environment-id=${environment?.id}`;
        }
        return url;
    }

    const columns: TableColumnList<EnvironmentFlow> = [
        {
            renderHeader: () => 'Type',
            renderCell: ({ item }) => {
                if (!isNullOrUndefined(item)) {
                    return <FlowIcon width="20" height="20" />;
                }

                return null;
            },
            size: '60px',
        },
        {
            renderHeader: () => 'Name',
            renderCell: ({ item }) => item.name,
        },
        {
            renderHeader: () => 'Version ID',
            renderCell: ({ item }) => (
                <CopyableText copyableText={item.versionId} hasCopyButton={true} />
            ),
        },
        {
            renderHeader: () => 'Deployment Notes',
            renderCell: ({ item }) => item.summary,
            size: '35rem',
        },
        {
            renderHeader: () => 'Last Modified',
            renderCell: ({ item }) => {
                return new Date(item.updatedAt).toLocaleString();
            },
        },
        {
            renderHeader: () => 'Run/Preview',
            renderCell: ({ item }) => {
                const flowUrl = getFlowUrl(item.flowId);
                return (
                    <a href={flowUrl} target="_blank" rel="noopener noreferrer">
                        {translations.ENVIRONMENT_run_in + environment?.name}
                    </a>
                );
            },
        },
    ];

    return (
        <>
            <SearchInput className="margin-top" onChange={onSearch} value={searchQuery} />

            <Table
                wrapperClassName="table margin-top margin-bottom"
                columns={columns}
                items={flows}
                isLoading={environmentFlowsLoading}
                pagination={{
                    page,
                    total,
                    pageSize,
                    changePage: (nextPage) => {
                        setPage(nextPage);
                        loadEnvironmentFlows(nextPage, null);
                    },
                }}
            />
        </>
    );
};

export default EnvironmentFlows;
