import translations from '../../../translations';
import { Trash } from '@phosphor-icons/react';
import Table, { type TableColumnList } from '../../../components/generic/Table';
import type { ElementAPI } from '../../../types';

type Props = {
    tableData: ElementAPI[];
    removeElement: (id: string) => void;
};

const SharedElementsTable = ({ tableData, removeElement }: Props) => {
    const tableColumns = () => {
        const columns: TableColumnList<ElementAPI> = [];

        columns.push({
            renderHeader: () => translations.COMMON_TABLE_name,
            renderCell: ({ item }) => item.developerName,
        });

        columns.push({
            renderHeader: () => translations.COMMON_TABLE_actions,
            renderCell: ({ item }) => (
                <div className="action-btn-wrapper">
                    <button
                        title={`Delete ${item.developerName}`}
                        className="table-icon table-icon-delete"
                        aria-label={`Delete ${item.developerName}`}
                        onClick={() => removeElement(item.id)}
                        type="button"
                    >
                        <Trash />
                    </button>
                </div>
            ),
            size: '6rem',
        });

        return columns;
    };

    return (
        <Table
            items={tableData}
            columns={tableColumns()}
            pagination={true}
            testId={'shared-elements-main'}
        />
    );
};

export default SharedElementsTable;
