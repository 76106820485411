import type { ComponentProps } from 'react';
import '../../../../css/organization-invite-form.less';
import { GUID_REQUIRED_LENGTH, TENANT_NAME_MAX_LENGTH } from '../../constants';
import translations from '../../translations';
import { getIdValidityMessage, getTenantNameValidityMessage } from '../../utils/validation';
import FormGroup from '../generic/FormGroup';
import InviteModal from './InviteModal';
import { useOrganizationInvite } from './OrganizationInviteProvider';

type TenantInviteModalProps = { fetchTenants: () => Promise<void> } & Pick<
    ComponentProps<typeof InviteModal>,
    'container' | 'show' | 'onHide'
>;

const TenantInviteModal = ({
    show = false,
    container,
    onHide,
    fetchTenants,
}: TenantInviteModalProps) => {
    const { sendTenantInvite } = useOrganizationInvite();

    return (
        <InviteModal
            title={translations.FORG_invite_tenant_title}
            container={container}
            show={show}
            modes={[
                {
                    name: 'name',
                    label: 'name',
                    helpText: translations.FORG_tenant_invite_form_name_help_text,
                },
                {
                    name: 'id',
                    label: 'ID',
                    helpText: translations.FORG_invite_form_id_help_text,
                },
            ]}
            onHide={onHide}
            onSend={async (input, mode) => {
                if (mode.name === 'name') {
                    await sendTenantInvite(input);
                } else {
                    await sendTenantInvite(undefined, input);
                }
                // Re-fetch tenants in case of automatic invite acceptance (i.e., when the user has invited a tenant they're a member of)
                await fetchTenants();
            }}
            onValidate={(input, mode) => {
                const message =
                    mode.name === 'name'
                        ? getTenantNameValidityMessage(input)
                        : getIdValidityMessage(input);

                return message;
            }}
            renderBody={({
                input,
                inputMode,
                validationMessage,
                hasSubmitted,
                handleInputChange,
            }) => {
                return (
                    <>
                        <span className="help-block">
                            {translations.FORG_tenant_invite_form_help_text}
                        </span>
                        <FormGroup
                            label={
                                inputMode.name === 'name'
                                    ? translations.FORG_tenant_invite_form_tenant_name_input_label
                                    : translations.FORG_tenant_invite_form_tenant_id_input_label
                            }
                            htmlFor="tenant-invite-input"
                            isRequired
                            isValid={validationMessage.length === 0}
                            validationMessage={validationMessage}
                            showValidation={hasSubmitted}
                        >
                            <input
                                id="tenant-invite-input"
                                className="form-control"
                                maxLength={
                                    inputMode.name === 'name'
                                        ? TENANT_NAME_MAX_LENGTH
                                        : GUID_REQUIRED_LENGTH
                                }
                                onChange={({ target: { value } }) => handleInputChange(value)}
                                value={input}
                            />
                        </FormGroup>
                    </>
                );
            }}
        />
    );
};

export default TenantInviteModal;
