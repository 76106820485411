import React, { useCallback, useMemo } from 'react';
import { GRAPH_ELEMENT_TYPES, MAP_ELEMENT_CONFIGS } from '../../../ts/constants';
import { getByID } from '../../../ts/utils/collection';
import GroupIcon from '../../../ts/components/icons/GroupIcon';
import DragElement from './elements/DragElement';
import GroupElement from '../../../ts/components/graph/elements/group/GroupElement';
import MapElement from '../../../ts/components/graph/elements/map/MapElement';
import { useGraph } from './GraphProvider';
import Outcome from './Outcome';
import { executeIfNotDragging, getMarqueeDimensions, hasSelectedAncestors } from './utils';
import { MagicWand as AutoArrangeIcon, XCircle as DeleteIcon } from '@phosphor-icons/react';

const MemoizedSVGContentLayered = ({
    flowId,
    openConfig,
    sidebarDragElementVisible,
    sidebarDragElementProps,
    sidebarDraggingWithKeyboard,
    newGroup,
    makeGroupIconRow,
    dragging,
    calculateSVGPositionFromXY,
    openMetadataEditor,
    zoomLevel,
    canvasSettings,
    zoomViewBox,
    graphElement,
    hoveringMapElementId,
}) => {
    const {
        groupSelectedElements,
        editingToken,
        mapElements,
        groupElements,
        selectedElementIds,
        selectedOutcomeId,
        selectedOutcomeMapElementId,
        isActive,
        tenantId,
        autoArrangeSelectedElements,
    } = useGraph();

    let selectionBoundary = null;
    let selectionMarquee = null;

    if (selectedElementIds.length > 1) {
        selectionBoundary = [
            <rect
                key="select-rect"
                data-testid="new-group-rect"
                stroke="gray"
                strokeDasharray={2}
                fill="none"
                {...newGroup}
            />,
            <foreignObject key="select-object" data-testid="new-group" {...makeGroupIconRow}>
                <DeleteIcon
                    size="1em"
                    weight="fill"
                    onClick={() => openConfig(MAP_ELEMENT_CONFIGS.multiDelete)}
                    onMouseUp={(e) => executeIfNotDragging(e, dragging)}
                    className="group-element-icon group-element-delete"
                    glyph="remove-sign"
                    alt="Delete"
                    data-testid="selection-marquee-delete"
                />
                <GroupIcon
                    onClick={() =>
                        groupSelectedElements({
                            ...newGroup,
                        })
                    }
                    onMouseUp={(e) => executeIfNotDragging(e, dragging)}
                    className="group-element-icon group-element-link"
                    title="Group"
                />
                <AutoArrangeIcon
                    onClick={() => autoArrangeSelectedElements({ graphElement, zoomViewBox })}
                    onMouseUp={(e) => executeIfNotDragging(e, dragging)}
                    className="group-element-icon group-element-arrange"
                    alt="Auto Arrange"
                    size="1em"
                />
            </foreignObject>,
        ];
    }

    if (isActive && dragging && dragging.dragType === GRAPH_ELEMENT_TYPES.marquee) {
        const { previousMousePosition, initialMousePosition, hasMovedEnough } = dragging;

        if (hasMovedEnough) {
            const { x, y, width, height } = getMarqueeDimensions(
                initialMousePosition,
                previousMousePosition,
                calculateSVGPositionFromXY,
            );

            selectionMarquee = (
                <rect
                    stroke="gray"
                    strokeDasharray={2}
                    fill="none"
                    x={x}
                    y={y}
                    width={width}
                    height={height}
                    data-testid="selection-marquee"
                />
            );
        }
    }

    const draggingMapElementId =
        dragging?.hasMovedEnough &&
        dragging.dragType === GRAPH_ELEMENT_TYPES.map &&
        dragging.elementId;

    const draggingGroupElementId =
        dragging?.hasMovedEnough &&
        (dragging.dragType === GRAPH_ELEMENT_TYPES.group ||
            dragging.dragType === GRAPH_ELEMENT_TYPES.resize) &&
        dragging.elementId;

    // Sometimes the active element doesn't have to be selected
    // e.g. when resizing a group
    const activeElementIds =
        draggingGroupElementId && !selectedElementIds.includes(draggingGroupElementId)
            ? [...selectedElementIds, draggingGroupElementId]
            : draggingMapElementId && !selectedElementIds.includes(draggingMapElementId)
              ? [...selectedElementIds, draggingMapElementId]
              : selectedElementIds;

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    const highestActiveMapElements = useMemo(
        () =>
            draggingMapElementId || draggingGroupElementId
                ? activeElementIds.filter((selectedId) => {
                      const mapEle = mapElements.find((ele) => ele.id === selectedId);
                      return (
                          mapEle && !hasSelectedAncestors(mapEle, groupElements, selectedElementIds)
                      );
                  })
                : [],
        // eslint-disable-next-line react-hooks/exhaustive-deps -- Treat warnings as errors, fix later
        [draggingMapElementId, draggingGroupElementId, selectedElementIds, mapElements.length],
    );

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    const highestActiveGroupElements = useMemo(
        () =>
            draggingMapElementId || draggingGroupElementId
                ? activeElementIds.filter((selectedId) => {
                      const groupEle = groupElements.find((ele) => ele.id === selectedId);
                      return (
                          groupEle &&
                          !hasSelectedAncestors(groupEle, groupElements, selectedElementIds)
                      );
                  })
                : [],
        // eslint-disable-next-line react-hooks/exhaustive-deps -- Treat warnings as errors, fix later
        [draggingMapElementId, draggingGroupElementId, selectedElementIds, groupElements.length],
    );

    const renderInvalidGroupElementIdElements = useCallback(
        () => (
            <>
                {mapElements
                    .filter(
                        (e) =>
                            e.groupElementId !== null &&
                            groupElements.some((g) => g.id === e.groupElementId) === false,
                    )
                    .sort(
                        (eA, eB) =>
                            new Date(eA.dateModified).getTime() -
                            new Date(eB.dateModified).getTime(),
                    )
                    .map((mapElement) => (
                        <MapElement
                            key={mapElement.id}
                            mapElement={mapElement}
                            openConfig={openConfig}
                            flowId={flowId}
                            editingToken={editingToken}
                            openMetadataEditor={openMetadataEditor}
                            groupElements={groupElements}
                            zoomLevel={zoomLevel}
                            zoomViewBox={zoomViewBox}
                            graphElement={graphElement}
                            tenantId={tenantId}
                        />
                    ))}
                {groupElements
                    .filter(
                        (e) =>
                            e.groupElementId !== null &&
                            groupElements.some((g) => g.id === e.groupElementId) === false,
                    )
                    .sort(
                        (eA, eB) =>
                            new Date(eA.dateModified).getTime() -
                            new Date(eB.dateModified).getTime(),
                    )
                    .map((groupElement) => (
                        <GroupElement
                            key={groupElement.id}
                            groupElement={groupElement}
                            openConfig={openConfig}
                            flowId={flowId}
                            editingToken={editingToken}
                            openMetadataEditor={openMetadataEditor}
                            groupElements={groupElements}
                            zoomLevel={zoomLevel}
                            zoomViewBox={zoomViewBox}
                            graphElement={graphElement}
                        />
                    ))}
            </>
        ),
        [
            editingToken,
            flowId,
            graphElement,
            groupElements,
            mapElements,
            openConfig,
            openMetadataEditor,
            tenantId,
            zoomLevel,
            zoomViewBox,
        ],
    );

    const renderMapElementsInGroup = useCallback(
        (groupId = null) =>
            mapElements
                .filter(
                    (m) => m.groupElementId === groupId && !highestActiveMapElements.includes(m.id),
                )
                .sort(
                    (mA, mB) =>
                        new Date(mA.dateModified).getTime() - new Date(mB.dateModified).getTime(),
                )
                .map((mapElement) => (
                    <MapElement
                        key={mapElement.id}
                        mapElement={mapElement}
                        openConfig={openConfig}
                        flowId={flowId}
                        editingToken={editingToken}
                        openMetadataEditor={openMetadataEditor}
                        groupElements={groupElements}
                        zoomLevel={zoomLevel}
                        zoomViewBox={zoomViewBox}
                        graphElement={graphElement}
                        tenantId={tenantId}
                    />
                )),
        [
            highestActiveMapElements,
            editingToken,
            flowId,
            graphElement,
            groupElements,
            mapElements,
            openConfig,
            openMetadataEditor,
            tenantId,
            zoomLevel,
            zoomViewBox,
        ],
    );

    const renderElementsInGroup = useCallback(
        (groupId = null) => (
            <>
                {renderMapElementsInGroup(groupId)}
                {groupElements
                    .filter(
                        (g) =>
                            g.groupElementId === groupId &&
                            !highestActiveGroupElements.includes(g.id),
                    )
                    // larger group elements are rendered last, so that if a smaller is overlapping, the larger occludes it, showing to the user that overlap
                    .sort((gA, gB) => gA.width * gA.height - gB.width * gB.height)
                    .map((groupElement) => (
                        <React.Fragment key={`${groupElement.id}-fragment`}>
                            <GroupElement
                                key={groupElement.id}
                                groupElement={groupElement}
                                openConfig={openConfig}
                                flowId={flowId}
                                editingToken={editingToken}
                                openMetadataEditor={openMetadataEditor}
                                groupElements={groupElements}
                                zoomLevel={zoomLevel}
                                zoomViewBox={zoomViewBox}
                                graphElement={graphElement}
                            />
                            {renderElementsInGroup(groupElement.id)}
                        </React.Fragment>
                    ))}
            </>
        ),
        [
            highestActiveGroupElements,
            renderMapElementsInGroup,
            groupElements,
            openConfig,
            flowId,
            editingToken,
            openMetadataEditor,
            zoomLevel,
            zoomViewBox,
            graphElement,
        ],
    );

    return (
        <>
            {/* Render element in invalid groups first, so they appear behind groups */}
            {useMemo(
                () => renderInvalidGroupElementIdElements(),
                [renderInvalidGroupElementIdElements],
            )}
            {/* Render top level elements and downwards */}
            {useMemo(() => renderElementsInGroup(), [renderElementsInGroup])}
            {/* Dragging group element/s rendered on top of other groups as it can be added into them */}
            {(draggingMapElementId || draggingGroupElementId) &&
                highestActiveGroupElements.map((groupId) => (
                    <React.Fragment key={`${groupId}-dragging`}>
                        <GroupElement
                            key={groupId}
                            groupElement={groupElements.find((g) => g.id === groupId)}
                            openConfig={openConfig}
                            flowId={flowId}
                            editingToken={editingToken}
                            openMetadataEditor={openMetadataEditor}
                            groupElements={groupElements}
                            zoomLevel={zoomLevel}
                            zoomViewBox={zoomViewBox}
                            graphElement={graphElement}
                        />
                        {renderElementsInGroup(groupId)}
                    </React.Fragment>
                ))}
            {/* Dragging map element/s rendered on top of groups as it can be added into them */}
            {(draggingMapElementId || draggingGroupElementId) &&
                highestActiveMapElements.map((mapId) => (
                    <MapElement
                        key={`${mapId}-dragging`}
                        mapElement={mapElements.find((m) => m.id === mapId)}
                        openConfig={openConfig}
                        flowId={flowId}
                        editingToken={editingToken}
                        openMetadataEditor={openMetadataEditor}
                        groupElements={groupElements}
                        zoomLevel={zoomLevel}
                        zoomViewBox={zoomViewBox}
                        graphElement={graphElement}
                        tenantId={tenantId}
                    />
                ))}
            {/* All outcomes from all map elements rendered on top of groups as they shouldn't be behind the group translucent background */}
            {/* biome-ignore lint/correctness/useExhaustiveDependencies: <explanation> */}
            {useMemo(
                () => [
                    mapElements.map((mapElement) => {
                        if (mapElement && Array.isArray(mapElement.outcomes)) {
                            return mapElement.outcomes.map((outcome) => {
                                if (
                                    outcome.id === selectedOutcomeId &&
                                    mapElement.id === selectedOutcomeMapElementId
                                ) {
                                    // render selected outcome further down instead
                                } else {
                                    return (
                                        <Outcome
                                            key={outcome.id + mapElement.id}
                                            mapElement={mapElement}
                                            outcome={outcome}
                                            openConfig={openConfig}
                                            flowId={flowId}
                                            editingToken={editingToken}
                                        />
                                    );
                                }
                            });
                        }
                    }),
                ],
                [mapElements, selectedOutcomeId, selectedOutcomeMapElementId],
            )}
            {/* Selected outcome rendered on top of other outcomes */}
            {/* biome-ignore lint/correctness/useExhaustiveDependencies: <explanation> */}
            {useMemo(() => {
                const selectedOutcomeMapElement = getByID(selectedOutcomeMapElementId, mapElements);
                const selectedOutcome =
                    selectedOutcomeMapElement &&
                    getByID(selectedOutcomeId, selectedOutcomeMapElement.outcomes);
                return (
                    selectedOutcomeMapElement &&
                    selectedOutcome && (
                        <Outcome
                            key={`${selectedOutcomeId + selectedOutcomeMapElementId}-selected`}
                            mapElement={selectedOutcomeMapElement}
                            outcome={selectedOutcome}
                            openConfig={openConfig}
                            flowId={flowId}
                            editingToken={editingToken}
                        />
                    )
                );
            }, [mapElements, selectedOutcomeId, selectedOutcomeMapElementId])}
            {/* Hovered map element rendered on top of outcomes so the map element new outcomes arrows aren't blocked by incoming outcomes */}
            {!(draggingMapElementId || draggingGroupElementId) && hoveringMapElementId && (
                <MapElement
                    key={`${hoveringMapElementId}-hovered`}
                    mapElement={mapElements.find((m) => m.id === hoveringMapElementId)}
                    openConfig={openConfig}
                    flowId={flowId}
                    editingToken={editingToken}
                    openMetadataEditor={openMetadataEditor}
                    groupElements={groupElements}
                    zoomLevel={zoomLevel}
                    zoomViewBox={zoomViewBox}
                    graphElement={graphElement}
                    tenantId={tenantId}
                    isClone
                />
            )}
            {/* New drag element from sidebar panel rendered on top of groups as it can be added into them */}
            {useMemo(
                () =>
                    sidebarDragElementVisible ? (
                        <DragElement
                            {...sidebarDragElementProps}
                            zoomLevel={zoomLevel}
                            canvasSettings={canvasSettings}
                            sidebarDraggingWithKeyboard={sidebarDraggingWithKeyboard}
                        />
                    ) : null,
                [
                    sidebarDragElementVisible,
                    sidebarDragElementProps,
                    sidebarDraggingWithKeyboard,
                    zoomLevel,
                    canvasSettings,
                ],
            )}
            {selectionBoundary}
            {selectionMarquee}
        </>
    );
};

export default MemoizedSVGContentLayered;
