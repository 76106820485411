import type { ReactNode } from 'react';
import GenericModal from '../generic/modal/GenericModal';
import ConfigModalHeader from '../flow/elementConfigurations/common/ConfigModalHeader';

interface Props {
    children: ReactNode;
    id: string | null;
    title: string;
    elementType: string;
    onHide: () => void;
    container: HTMLElement | null;
    currentUserId?: string | undefined;
}

const ConfigModal = ({
    children,
    id,
    title,
    elementType,
    currentUserId,
    onHide,
    container,
}: Props) => {
    return (
        <GenericModal
            onHide={onHide}
            container={container}
            dialogClassName={'modal-large config-modal map-element-config-modal'}
            className={`${elementType}-modal`}
            renderHeader={() => (
                <ConfigModalHeader
                    id={id}
                    title={title}
                    elementType={elementType}
                    currentUserId={currentUserId}
                />
            )}
        >
            {children}
        </GenericModal>
    );
};

export default ConfigModal;
