import type { Command, ContentType } from '../../../../types';

type OperationCommandDescriptor = {
    command: Command;
    displayName: string;
    contentTypes: ContentType[];
};

type OperationCommandDescriptorKey =
    | 'value_of'
    | 'getLength'
    | 'getFirst'
    | 'getLast'
    | 'getNext'
    | 'setEqual'
    | 'setRelativeDate'
    | 'filter'
    | 'remove'
    | 'update'
    | 'add'
    | 'subtract'
    | 'divide'
    | 'multiply'
    | 'modulo'
    | 'join'
    | 'new'
    | 'empty'
    | 'sort';

type OperationCommandDescriptors = Record<
    OperationCommandDescriptorKey,
    OperationCommandDescriptor
>;

export const OPERATION_COMMANDS: OperationCommandDescriptors = {
    /** Cannot use 'valueOf' because it's a keyword */
    value_of: {
        command: 'VALUE_OF',
        displayName: 'Value Of',
        contentTypes: [
            'ContentString',
            'ContentPassword',
            'ContentContent',
            'ContentEncrypted',
            'ContentNumber',
            'ContentDateTime',
            'ContentDate',
            'ContentBoolean',
            'ContentList',
            'ContentObject',
        ],
    },
    getLength: {
        command: 'GET_LENGTH',
        displayName: 'Length Of',
        contentTypes: [
            'ContentList',
            'ContentString',
            'ContentPassword',
            'ContentContent',
            'ContentEncrypted',
        ],
    },
    getFirst: {
        command: 'GET_FIRST',
        displayName: 'First Record Of',
        contentTypes: ['ContentList'],
    },
    getLast: {
        command: 'GET_LAST',
        displayName: 'Last Record Of',
        contentTypes: ['ContentList'],
    },
    getNext: {
        command: 'GET_NEXT',
        displayName: 'Next Record Of',
        contentTypes: ['ContentList'],
    },
    setEqual: {
        command: 'SET_EQUAL',
        displayName: 'Set Equal To',
        contentTypes: [
            'ContentString',
            'ContentPassword',
            'ContentContent',
            'ContentEncrypted',
            'ContentNumber',
            'ContentDateTime',
            'ContentDate',
            'ContentBoolean',
            'ContentList',
            'ContentObject',
        ],
    },
    setRelativeDate: {
        command: 'SET_RELATIVE',
        displayName: 'Set Relative To',
        contentTypes: ['ContentDateTime', 'ContentDate'],
    },
    // Not yet supported - requires additional criteria
    filter: {
        command: 'FILTER',
        displayName: 'Filter',
        contentTypes: [],
    },
    remove: {
        command: 'REMOVE',
        displayName: 'Remove',
        contentTypes: ['ContentList', 'ContentObject'],
    },
    update: {
        command: 'ADD',
        displayName: 'Update',
        contentTypes: ['ContentList', 'ContentObject'],
    },
    add: {
        command: 'ADD',
        displayName: 'Add',
        contentTypes: ['ContentNumber'],
    },
    subtract: {
        command: 'REMOVE',
        displayName: 'Subtract',
        contentTypes: ['ContentNumber'],
    },
    divide: {
        command: 'DIVIDE',
        displayName: 'Divide',
        contentTypes: ['ContentNumber'],
    },
    multiply: {
        command: 'MULTIPLY',
        displayName: 'Multiply',
        contentTypes: ['ContentNumber'],
    },
    modulo: {
        command: 'MODULO',
        displayName: 'Modulo',
        contentTypes: ['ContentNumber'],
    },
    join: {
        command: 'ADD',
        displayName: 'Join',
        contentTypes: ['ContentString', 'ContentPassword', 'ContentContent', 'ContentEncrypted'],
    },
    new: {
        command: 'NEW',
        displayName: 'Create New',
        contentTypes: [
            'ContentString',
            'ContentPassword',
            'ContentContent',
            'ContentEncrypted',
            'ContentNumber',
            'ContentDateTime',
            'ContentDate',
            'ContentBoolean',
            'ContentList',
            'ContentObject',
        ],
    },
    empty: {
        command: 'EMPTY',
        displayName: 'Empty',
        contentTypes: [
            'ContentString',
            'ContentPassword',
            'ContentContent',
            'ContentEncrypted',
            'ContentNumber',
            'ContentDateTime',
            'ContentDate',
            'ContentBoolean',
            'ContentList',
            'ContentObject',
        ],
    },
    sort: {
        command: 'SORT',
        displayName: 'Sort',
        contentTypes: ['ContentList'],
    },
};

export const TIME_UNITS = {
    SECONDS: 'Second(s)',
    MINUTES: 'Minutes(s)',
    HOURS: 'Hour(s)',
    DAYS: 'Day(s)',
    WEEKS: 'Week(s)',
    MONTHS: 'Month(s)',
    YEARS: 'Year(s)',
};

export const RELATIVE_DATETIME_UNITS = [
    TIME_UNITS.SECONDS,
    TIME_UNITS.MINUTES,
    TIME_UNITS.HOURS,
    TIME_UNITS.DAYS,
    TIME_UNITS.WEEKS,
    TIME_UNITS.MONTHS,
    TIME_UNITS.YEARS,
];

export const RELATIVE_DATE_UNITS = [
    TIME_UNITS.DAYS,
    TIME_UNITS.WEEKS,
    TIME_UNITS.MONTHS,
    TIME_UNITS.YEARS,
];
