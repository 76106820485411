import { useEffect, useState } from 'react';
import { SaveOpenApiSchemaConfig, getOpenApiSchemaInfo } from '../../../sources/service';
import type { OpenApiPathInfo, ServiceElementResponseAPI } from '../../../types/service';
import Table from '../../generic/Table';
import translations from '../../../translations';
import Toggle from '../../inputs/Toggle';
import { NOTIFICATION_TYPES } from '../../../constants';
import { OpenApiTypeInput } from './OpenApiTypeInput';
import type { ServiceViews } from '../ServiceConstants';
import { useServices } from '../contextProviders/ServicesProvider';
import Loader from '../../loader/Loader';
import Breadcrumb from '../../Breadcrumb';
import FormGroup from '../../generic/FormGroup';
import { useOpenApiConnector } from '../contextProviders/OpenApiConnectorProvider';
import { isNullOrEmpty } from '../../../utils';
import { OpenApiTableConfigModal } from './OpenApiTableConfiguration';
import { ButtonFlavor, ButtonType, ExButton } from '@boomi/exosphere';

interface OpenApiConfigurationProps {
    currentServiceData: ServiceElementResponseAPI | null | undefined;
    switchView: (view: ServiceViews) => void;
}

const OpenApiConfiguration = ({ currentServiceData, switchView }: OpenApiConfigurationProps) => {
    const [isInfoLoading, setIsInfoLoading] = useState<boolean>(false);
    const [showTableModal, setShowTableModal] = useState<boolean>(false);
    const { servicesState, addNotification } = useServices();
    const schemaValueId =
        servicesState?.selectedValues?.find((x) => x.name === 'Schema Value')?.value?.id ??
        currentServiceData?.configurationValues?.find((x) => x.developerName === 'Schema Value')
            ?.valueElementToReferenceId?.id ??
        servicesState?.selectedValues?.find((x) => x.name === 'Schema URL')?.value?.id ??
        currentServiceData?.configurationValues?.find((x) => x.developerName === 'Schema URL')
            ?.valueElementToReferenceId?.id;

    const {
        hasSubmitted,
        setHasSubmitted,
        schemaInfo,
        setSchemaInfo,
        onSecurityChange,
        onUserInfoUrlChange,
        setOpenApiAPI,
        openApiAPI,
        onFirstNameChange,
        onLastNameChange,
        onEmailChange,
        onUserIdChange,
        onUsernameChange,
        onScopesChange,
        isSecurityValid,
        setEditingPath,
    } = useOpenApiConnector();
    const { breadcrumbs } = useServices();
    const isBasic = schemaInfo?.security === 'BASIC';
    const isOIDC = schemaInfo?.security === 'OIDC';
    const shouldConfigureMe = schemaInfo?.security === 'OAUTH2' || isBasic;

    const onItemChange = (info: OpenApiPathInfo, rowIndex: number) => {
        if (!schemaInfo) {
            return;
        }
        const schema = { ...schemaInfo };
        schema.paths[rowIndex] = info;
        setSchemaInfo(schema);
    };

    useEffect(() => {
        const getSchemaInfo = async () => {
            setIsInfoLoading(true);
            try {
                if (!schemaValueId) {
                    addNotification({
                        type: NOTIFICATION_TYPES.error,
                        message: 'An OpenApi schema value must be set',
                        isPersistent: true,
                    });
                    goBack();
                    return;
                }
                const openApiAPI = await getOpenApiSchemaInfo({ schemaValueId });
                if (openApiAPI) {
                    setOpenApiAPI(openApiAPI);
                    setSchemaInfo(openApiAPI?.schemaInfo);
                }
            } catch (error) {
                addNotification({
                    type: NOTIFICATION_TYPES.error,
                    message: (error as Error).message,
                    isPersistent: true,
                });
                setIsInfoLoading(false);
                setOpenApiAPI(null);
                setSchemaInfo(null);
            }
            setIsInfoLoading(false);
        };
        getSchemaInfo();
    }, [addNotification, schemaValueId, setOpenApiAPI, setSchemaInfo]);

    const goBack = () => switchView(breadcrumbs.trail[breadcrumbs.trail.length - 2].id);

    const saveSchemaConfiguration = async () => {
        try {
            if (!schemaInfo) {
                addNotification({
                    type: NOTIFICATION_TYPES.error,
                    message: translations.OPENAPI_TABLE_empty_schema_error,
                    isPersistent: true,
                });
                return;
            }
            if (schemaInfo.paths.filter((x) => x.shouldGenerateType).length === 0) {
                addNotification({
                    type: NOTIFICATION_TYPES.error,
                    message: translations.OPENAPI_TABLE_no_types_error,
                    isPersistent: true,
                });
                return;
            }
            setHasSubmitted(true);
            const securityValidationResult = isSecurityValid();
            if (openApiAPI && schemaInfo && securityValidationResult.isValid) {
                const openApiAPIToSave = { ...openApiAPI, schemaInfo: schemaInfo };
                if (isNullOrEmpty(schemaInfo.security)) {
                    openApiAPIToSave.schemaInfo.security = 'NONE';
                }
                await SaveOpenApiSchemaConfig(openApiAPIToSave);
                addNotification({
                    type: NOTIFICATION_TYPES.success,
                    message: 'Successfully saved OpenApi Schema configuration',
                    isPersistent: true,
                });
                goBack();
            } else {
                addNotification({
                    type: NOTIFICATION_TYPES.error,
                    message: securityValidationResult.validationMessage
                        ? securityValidationResult.validationMessage
                        : translations.OPENAPI_validation_fail,
                    isPersistent: true,
                });
            }
        } catch (error) {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: (error as Error).message,
                isPersistent: true,
            });
        }
    };

    if (isInfoLoading) {
        return <Loader />;
    }

    return (
        <>
            <div className="full-height flex-column">
                <div className="admin-page">
                    <div className="margin-bottom" data-testid="connector-breadcrumbs">
                        <Breadcrumb
                            trail={breadcrumbs.trail}
                            activeItemId={breadcrumbs.activeItemId}
                        />
                    </div>
                    {schemaInfo && (
                        <>
                            <h1>{translations.OPENAPI_security}</h1>
                            <FormGroup label="Security" htmlFor="security-select">
                                <select
                                    id="security-select"
                                    value={
                                        schemaInfo.security ?? translations.OPENAPI_security_none
                                    }
                                    onChange={onSecurityChange}
                                    required
                                    className="form-control form-control-width"
                                >
                                    <option value="NONE">
                                        {translations.OPENAPI_security_none}
                                    </option>
                                    <option value="OAUTH2">
                                        {translations.OPENAPI_security_oauth}
                                    </option>
                                    <option value="OIDC">
                                        {translations.OPENAPI_security_oidc}
                                    </option>
                                    <option value="BASIC">
                                        {translations.OPENAPI_security_basic}
                                    </option>
                                </select>
                            </FormGroup>
                            {shouldConfigureMe && (
                                <>
                                    <FormGroup
                                        label={translations.OPENAPI_security_userinfo}
                                        isRequired
                                        showValidation={hasSubmitted}
                                        htmlFor="openapi-security-userinfo"
                                    >
                                        <input
                                            id="openapi-security-userinfo"
                                            value={schemaInfo?.me?.userInfoUrl ?? ''}
                                            onChange={onUserInfoUrlChange}
                                            required={!isBasic}
                                            className="form-control form-control-width"
                                            type="text"
                                        />
                                    </FormGroup>
                                    <FormGroup
                                        label={translations.OPENAPI_security_firstname}
                                        isRequired={!isBasic}
                                        showValidation={hasSubmitted && !isBasic}
                                        htmlFor="openapi-security-firstName"
                                    >
                                        <input
                                            id="openapi-security-firstName"
                                            value={schemaInfo?.me?.firstName ?? ''}
                                            onChange={onFirstNameChange}
                                            required={!isBasic}
                                            className="form-control form-control-width"
                                            type="text"
                                        />
                                    </FormGroup>
                                    <FormGroup
                                        label={translations.OPENAPI_security_lastname}
                                        isRequired={!isBasic}
                                        showValidation={hasSubmitted && !isBasic}
                                        htmlFor="openapi-security-lastName"
                                    >
                                        <input
                                            id="openapi-security-lastName"
                                            value={schemaInfo?.me?.lastName ?? ''}
                                            onChange={onLastNameChange}
                                            required={!isBasic}
                                            className="form-control form-control-width"
                                            type="text"
                                        />
                                    </FormGroup>
                                    <FormGroup
                                        label={translations.OPENAPI_security_email}
                                        isRequired={!isBasic}
                                        showValidation={hasSubmitted && !isBasic}
                                        htmlFor="openapi-security-email"
                                    >
                                        <input
                                            id="openapi-security-email"
                                            value={schemaInfo?.me?.email ?? ''}
                                            onChange={onEmailChange}
                                            required={!isBasic}
                                            className="form-control form-control-width"
                                            type="text"
                                        />
                                    </FormGroup>
                                    <FormGroup
                                        label={translations.OPENAPI_security_username}
                                        isRequired={!isBasic}
                                        showValidation={hasSubmitted && !isBasic}
                                        htmlFor="openapi-security-username"
                                    >
                                        <input
                                            id="openapi-security-username"
                                            value={schemaInfo?.me?.username ?? ''}
                                            onChange={onUsernameChange}
                                            required={!isBasic}
                                            className="form-control form-control-width"
                                            type="text"
                                        />
                                    </FormGroup>
                                    <FormGroup
                                        label={translations.OPENAPI_security_userId}
                                        isRequired={!isBasic}
                                        showValidation={hasSubmitted && !isBasic}
                                        htmlFor="openapi-security-userId"
                                    >
                                        <input
                                            id="openapi-security-userId"
                                            value={schemaInfo?.me?.userId ?? ''}
                                            onChange={onUserIdChange}
                                            required={!isBasic}
                                            className="form-control form-control-width"
                                            type="text"
                                        />
                                    </FormGroup>
                                </>
                            )}
                            {isOIDC && (
                                <FormGroup
                                    label={translations.OPENAPI_security_scopes}
                                    isRequired={!isBasic}
                                    showValidation={hasSubmitted && !isBasic}
                                    htmlFor="openapi-security-scopes"
                                >
                                    <textarea
                                        id="openapi-security-scopes"
                                        value={schemaInfo?.scopes ?? ''}
                                        onChange={onScopesChange}
                                        required={!isBasic}
                                        className="form-control form-control-width"
                                    />
                                </FormGroup>
                            )}
                            <h1>{translations.OPENAPI_schema}</h1>
                            <h2>{schemaInfo.serverUrl}</h2>
                            <Table<OpenApiPathInfo>
                                wrapperClassName="margin-top"
                                columns={[
                                    {
                                        renderHeader: () => translations.OPENAPI_TABLE_create_type,
                                        renderCell: ({ item, rowIndex }) => (
                                            <Toggle
                                                id={`should-create-type-${rowIndex}`}
                                                isOn={item.shouldGenerateType}
                                                onChange={({ isOn }) =>
                                                    onItemChange(
                                                        { ...item, shouldGenerateType: isOn },
                                                        rowIndex,
                                                    )
                                                }
                                                testId={`should-create-type-${rowIndex}`}
                                            />
                                        ),
                                        size: '7rem',
                                    },
                                    {
                                        renderHeader: () => translations.OPENAPI_TABLE_path,
                                        renderCell: ({ item }) => item.path,
                                    },
                                    {
                                        renderHeader: () =>
                                            translations.OPENAPI_TABLE_request_object_name,
                                        renderCell: ({ item, rowIndex }) => (
                                            <OpenApiTypeInput
                                                pathInfo={item}
                                                request={true}
                                                onItemChange={onItemChange}
                                                rowIndex={rowIndex}
                                            />
                                        ),
                                    },
                                    {
                                        renderHeader: () =>
                                            translations.OPENAPI_TABLE_response_object_name,
                                        renderCell: ({ item, rowIndex }) => (
                                            <OpenApiTypeInput
                                                pathInfo={item}
                                                request={false}
                                                onItemChange={onItemChange}
                                                rowIndex={rowIndex}
                                            />
                                        ),
                                    },
                                    {
                                        renderHeader: () => translations.OPENAPI_TABLE_operation,
                                        renderCell: ({ item }) => item.operation.method,
                                    },
                                    {
                                        renderHeader: () => translations.OPENAPI_TABLE_description,
                                        renderCell: ({ item }) => item.responseSchema?.description,
                                    },
                                    {
                                        renderHeader: () => translations.COMMON_TABLE_actions,
                                        renderCell: ({ item, rowIndex }) => {
                                            if (!item.responseSchema) {
                                                return <></>;
                                            }
                                            return (
                                                <ExButton
                                                    title={translations.COMMON_back}
                                                    onClick={() => {
                                                        setEditingPath({
                                                            index: rowIndex,
                                                            path: item,
                                                        });
                                                        setShowTableModal(true);
                                                    }}
                                                    flavor={ButtonFlavor.BRANDED}
                                                    type={ButtonType.PRIMARY}
                                                >
                                                    {translations.OPENAPI_TABLE_configure_table}
                                                </ExButton>
                                            );
                                        },
                                    },
                                ]}
                                items={schemaInfo.paths}
                                isLoading={isInfoLoading}
                            />
                        </>
                    )}
                </div>
                <OpenApiTableConfigModal show={showTableModal} setShow={setShowTableModal} />
                <div className="admin-footer">
                    <button type="button" className="btn btn-default outcome" onClick={goBack}>
                        {'Back'}
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary outcome"
                        onClick={saveSchemaConfiguration}
                    >
                        {'Save'}
                    </button>
                </div>
            </div>
        </>
    );
};

export default OpenApiConfiguration;
