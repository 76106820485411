import { COMPONENT_CONFIGURATION_LABELS } from '../../../../../../constants';
import ConfigSection from '../../ConfigSection';
import type { ComposerElement } from '../../../../../../../../types';
import FormGroup from '../../../../../../../generic/FormGroup';

interface Props {
    modifierOptions: { [key: string]: boolean };
    modifierValues: Partial<ComposerElement>;
    updateEditable: (isEditable: boolean) => void;
    updateMultiSelect: (isMultiSelect: boolean) => void;
    updateSearchable: (isSearchable: boolean) => void;
    updateSearchableDisplayColumns: (hasSearchableDisplayColumns: boolean) => void;
    updateAutoUpload: (autoUpload: boolean) => void;
    updateSortableColumns: (sortable: boolean) => void;
    updateCsvExportButton: (csvButton: boolean) => void;
    updateCsvFileName: (fileName: string) => void;
}

const ModifiersSection = ({
    modifierOptions,
    modifierValues,
    updateEditable,
    updateMultiSelect,
    updateSearchable,
    updateSearchableDisplayColumns,
    updateAutoUpload,
    updateSortableColumns,
    updateCsvExportButton,
    updateCsvFileName,
}: Props) => {
    if (!(modifierOptions || modifierValues)) {
        return null;
    }

    const {
        hasEditable,
        hasMultiselect,
        hasSearchable,
        hasAutoUpload,
        hasSortableColumns,
        hasCsvExportButton,
        hasCsvFileName,
    } = modifierOptions ?? {};

    const { isEditable, isMultiSelect, isSearchable, isSearchableDisplayColumns, attributes } =
        modifierValues;

    const isAutoUpload =
        typeof attributes?.['$autoUpload'] === 'string' &&
        attributes?.['$autoUpload'].toLowerCase() === 'true';

    const isSortableColumns =
        typeof attributes?.['$hasSortableColumns'] === 'string' &&
        attributes?.['$hasSortableColumns'].toLowerCase() === 'true';

    const isCsvExportButton =
        typeof attributes?.['$exportCsvButton'] === 'string' &&
        attributes?.['$exportCsvButton'].toLowerCase() === 'true';

    const csvFileName = attributes?.['$csvFileName'] as string;

    const hideModifersSection =
        modifierOptions && Object.values(modifierOptions).every((val) => !val);

    if (hideModifersSection) {
        return null;
    }

    return (
        <>
            <ConfigSection contentLayout="flex-row" dataTestId="modifiers-section">
                {hasEditable && (
                    <div className="form-group">
                        <label>
                            <input
                                type="checkbox"
                                onChange={() => {
                                    updateEditable(!isEditable);
                                }}
                                checked={isEditable}
                            />
                            {COMPONENT_CONFIGURATION_LABELS['EDITABLE']}
                        </label>
                    </div>
                )}

                {hasMultiselect && (
                    <div className="form-group">
                        <label>
                            <input
                                type="checkbox"
                                onChange={() => {
                                    updateMultiSelect(!isMultiSelect);
                                }}
                                checked={isMultiSelect}
                            />
                            {COMPONENT_CONFIGURATION_LABELS['MULTISELECT']}
                        </label>
                    </div>
                )}

                {hasSearchable && (
                    <div className="form-group">
                        <label>
                            <input
                                type="checkbox"
                                onChange={() => {
                                    updateSearchable(!isSearchable);
                                }}
                                checked={isSearchable}
                            />
                            {COMPONENT_CONFIGURATION_LABELS['SEARCHABLE']}
                        </label>
                    </div>
                )}

                {hasSearchable && isSearchable && (
                    <div className="form-group">
                        <label>
                            <input
                                type="checkbox"
                                onChange={() => {
                                    updateSearchableDisplayColumns(!isSearchableDisplayColumns);
                                }}
                                checked={isSearchableDisplayColumns}
                            />
                            {COMPONENT_CONFIGURATION_LABELS['SEARCHABLEDISPLAYCOLUMNS']}
                        </label>
                    </div>
                )}

                <>
                    {hasAutoUpload && (
                        <div className="form-group">
                            <label>
                                <input
                                    type="checkbox"
                                    onChange={() => {
                                        updateAutoUpload(!isAutoUpload);
                                    }}
                                    checked={isAutoUpload}
                                />
                                {COMPONENT_CONFIGURATION_LABELS['AUTO_UPLOAD']}
                            </label>
                        </div>
                    )}

                    {hasSortableColumns && (
                        <div className="form-group">
                            <label>
                                <input
                                    type="checkbox"
                                    onChange={() => {
                                        updateSortableColumns(!isSortableColumns);
                                    }}
                                    checked={isSortableColumns}
                                />
                                {COMPONENT_CONFIGURATION_LABELS['SORTABLE_COLUMNS']}
                            </label>
                        </div>
                    )}

                    {hasCsvExportButton && (
                        <div className="form-group">
                            <label>
                                <input
                                    type="checkbox"
                                    onChange={() => {
                                        updateCsvExportButton(!isCsvExportButton);
                                    }}
                                    checked={isCsvExportButton}
                                />
                                {COMPONENT_CONFIGURATION_LABELS['EXPORT_CSV_BUTTON']}
                            </label>
                        </div>
                    )}
                </>
            </ConfigSection>

            {hasCsvExportButton && hasCsvFileName && isCsvExportButton && (
                <ConfigSection>
                    <FormGroup
                        label={COMPONENT_CONFIGURATION_LABELS['CSV_FILE_NAME']}
                        htmlFor="csv-file-name"
                    >
                        <div className="grouped-control">
                            <input
                                onChange={(e) => updateCsvFileName(e.target.value)}
                                className="with-extension form-control"
                                id="csv-file-name"
                                type="text"
                                value={csvFileName}
                            />
                            <div className="grouped-control-extension align-center flex padding">
                                .csv
                            </div>
                        </div>
                    </FormGroup>
                </ConfigSection>
            )}
        </>
    );
};

export default ModifiersSection;
