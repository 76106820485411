import translations from '../../../../translations';
import type { OpenApiParameterInfo, ValueElementIdReferenceAPI } from '../../../../types';
import { useOpenApi } from '../contextProviders/OpenApiProvider';
import Table from '../../../generic/Table';
import { useMapElement } from '../contextProviders/MapElementProvider';
import ValueSelectorModal from '../../../values/selector/ValueSelectorModal';

const OpenApiParameterList = () => {
    const { container } = useMapElement();
    const { onActionValueChanged, openApiActionToEdit, hasSubmitted, isValidParameters } =
        useOpenApi();

    const columns = [
        {
            renderHeader: () => translations.COMMON_TABLE_name,
            renderCell: ({ item }: { item: OpenApiParameterInfo }) => item.name ?? '',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_location,
            renderCell: ({ item }: { item: OpenApiParameterInfo }) => item.location ?? '',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_type,
            renderCell: ({ item }: { item: OpenApiParameterInfo }) => item.schema.type ?? '',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_value,
            renderCell: ({ item }: { item: OpenApiParameterInfo }) => {
                return (
                    <ValueSelectorModal
                        value={item?.value ?? null}
                        onChangeAsValueReference={(value: ValueElementIdReferenceAPI | null) => {
                            onActionValueChanged(value, item);
                        }}
                        includeSystemValues={false}
                        container={container}
                    />
                );
            },
            size: '31rem',
        },
    ];

    return (
        <>
            <h4>{translations.OPENAPI_parameters}</h4>
            <Table<OpenApiParameterInfo>
                wrapperClassName="margin-bottom-large"
                columns={columns}
                items={openApiActionToEdit.openApiAction?.parameters ?? []}
                testId="parameter-list"
            />
            {!isValidParameters() && hasSubmitted && (
                <span className="help-block error-state">
                    {translations.OPENAPI_invalid_parameters}
                </span>
            )}
        </>
    );
};

export default OpenApiParameterList;
