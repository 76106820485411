import translations from '../../../translations';
import FormGroup from '../../generic/FormGroup';
import ServiceAuthentication from '../authentication/ServiceAuthentication';

import { type ChangeEvent, type ReactNode, useEffect, useState } from 'react';
import { addNotification } from '../../../../js/actions/reduxActions/notification';
import { getIdentityProviderList } from '../../../../ts/sources/identityprovider';
import type { ServiceElementResponseAPI } from '../../../types/service';
import { SUPPORTED_SERVICES } from '../ServiceConstants';
import { isNullOrEmpty } from '../../../utils/guard';

interface ServiceDetailsProps {
    service: ServiceElementResponseAPI;
    onChange: (data: ServiceElementResponseAPI) => void;
    hasSubmitted: boolean;
}

const ServiceDetails = ({ service, onChange, hasSubmitted }: ServiceDetailsProps) => {
    const [identityProviderOptions, setIdentityProviderOptions] = useState<ReactNode>(null);

    const supportedService = SUPPORTED_SERVICES.find((s) => service.uri === s.url);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        async function GetIdps(): Promise<void> {
            try {
                const data = await getIdentityProviderList({
                    limit: 1000000,
                    types: ['boomiApiKey'],
                });

                let idpOptions: ReactNode[] = [];

                if (data.items) {
                    idpOptions = data.items.map((idp) => (
                        <option value={idp.id} key={idp.id}>
                            {idp.developerName}
                        </option>
                    ));
                    idpOptions.unshift(
                        <option value="" key="no identity provider">
                            {translations.SC_identity_provider_none_selected_text}
                        </option>,
                    );

                    setIdentityProviderOptions(idpOptions);
                }
            } catch (error) {
                addNotification({
                    type: 'error',
                    message: (error as Error).toString(),
                });
            }
        }

        if (supportedService?.canHaveIdentity) {
            GetIdps();
        }
    }, []);

    const updateSelectedIdentityProvider = (event: ChangeEvent<HTMLSelectElement>) => {
        onChange({
            ...service,
            identityProviderId:
                event?.target?.value && event?.target?.value.length > 0
                    ? event?.target?.value
                    : null,
        });
    };

    return (
        <>
            <FormGroup
                isRequired
                htmlFor="connector-name"
                label="Name"
                validationMessage={translations.SC_name_field_validation_message}
                isValid={!isNullOrEmpty(service?.developerName)}
                showValidation={hasSubmitted}
            >
                <input
                    id="connector-name"
                    className="form-control form-control-long"
                    value={service === null ? '' : (service.developerName as string)}
                    onChange={({ target }) => onChange({ ...service, developerName: target.value })}
                />
            </FormGroup>
            <FormGroup
                isRequired
                htmlFor="connector-url"
                label="The URL for this connector"
                validationMessage={translations.SC_url_field_validation_message}
                isValid={!isNullOrEmpty(service?.uri)}
                showValidation={hasSubmitted}
            >
                <input
                    id="connector-url"
                    className="form-control form-control-long"
                    value={service === null ? '' : service.uri}
                    onChange={({ target }) => onChange({ ...service, uri: target.value })}
                />
            </FormGroup>

            <ServiceAuthentication serviceData={service} onSetServiceData={onChange} />

            <FormGroup
                htmlFor="connector-auth"
                label={translations.SC_authentication_text}
                className={`${supportedService?.canHaveIdentity ? '' : 'hidden'} form-control-long`}
            >
                <select
                    id="connector-auth"
                    onChange={updateSelectedIdentityProvider}
                    className="form-control validation-type"
                    data-testid="authSelect"
                    value={service.identityProviderId?.toString()}
                >
                    {identityProviderOptions}
                </select>
            </FormGroup>

            <FormGroup htmlFor="connector-comments" label="Comments about this connector">
                <textarea
                    id="connector-comments"
                    maxLength={1000}
                    rows={3}
                    className="form-control form-control-long"
                    value={service === null ? '' : (service.developerSummary as string)}
                    onChange={({ target }) =>
                        onChange({ ...service, developerSummary: target.value })
                    }
                />
            </FormGroup>
        </>
    );
};

export default ServiceDetails;
