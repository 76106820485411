import { useNavigate } from 'react-router-dom';
import { Plus, Trash } from '@phosphor-icons/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { TAB_TYPES } from '../../../constants';
import translations from '../../../translations';
import type { FlowResponseAPI, NotifyError, OrderDirection } from '../../../types';
import InlineInput from '../../inputs/InlineInput';
import {
    deleteFlow,
    getFlowLabels,
    getFlowsPaged,
    getLatest,
    setLatest,
} from '../../../sources/flow';
import Select, { type MultiValue } from 'react-select';
import { useAuth } from '../../AuthProvider';
import ButtonPrimary from '../../buttons/ButtonPrimary';
import SearchInput from '../../generic/SearchInput';
import Sortable from '../../generic/Sortable';
import Table, { type TableColumnList } from '../../generic/Table';
import FooterButtons from '../../generic/modal/FooterButtons';
import GenericModal from '../../generic/modal/GenericModal';
import ComponentWithTooltip from '../../generic/tooltip/ComponentWithTooltip';
import Loader from '../../loader/Loader';
import FlowLabels from '../FlowLabels';
import FlowUsers from './FlowUsers';
import GettingStarted from './GettingStarted';
import { getSharedStyles } from '../../../utils/select';
import { stringReplace } from '../../../utils/string';
import { generateRouteUrl, generateTabKey } from '../../../utils/routing';
import { isTooltipRequired } from '../../../utils/display';
import NewFlowModal from './NewFlowModal';
import { notifyError as notifyErrorAction } from '../../../../js/actions/reduxActions/notification';

interface Props {
    isEditorActive: boolean;
    notifyError: NotifyError;
}

const FlowList = ({ isEditorActive, notifyError }: Props) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [allFlowLabels, setAllFlowLabels] = useState<string[]>([]);
    const [filterLabels, setFilterLabels] = useState<string[]>([]);
    const [filteredFlows, setFilteredFlows] = useState<FlowResponseAPI[]>([]);
    const [sortProperty, setSortProperty] = useState('dateModified');
    const [sortDirection, setSortDirection] = useState<OrderDirection>('DESC');

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showNewFlowModal, setShowNewFlowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFlow, setSelectedFlow] = useState<FlowResponseAPI | null>(null);

    const [descriptionEditorOpen, setDescriptionEditorOpen] = useState<string | null>(null);

    const { user, tenant } = useAuth();

    const navigate = useNavigate();

    const flowListRef = useRef(null);

    const [pagination, setPagination] = useState({
        page: 1,
        total: 0,
        pageSize: 20,
    });

    /** Used to make sure we only show the getting started page AFTER loading flows */
    const [hasFetchedFlowList, setHasFetchedFlowList] = useState(false);

    const showGettingStarted =
        hasFetchedFlowList &&
        !isLoading &&
        searchQuery === '' &&
        filterLabels.length === 0 &&
        pagination.total === 0;

    const fetchFlowLabels = useCallback(async () => {
        try {
            const response = await getFlowLabels();
            setAllFlowLabels(response);
        } catch (_error) {
            notifyError('Unable to load flow labels');
        }
    }, [notifyError]);

    const fetchFlows = useCallback(async () => {
        setIsLoading(true);

        try {
            const filter = {
                search: searchQuery,
                page: pagination.page,
                orderBy: sortProperty,
                orderDirection: sortDirection,
            };

            const response = await getFlowsPaged(filter, filterLabels);

            setFilteredFlows(response.items);
            setPagination(response._meta);
        } catch (_error) {
            notifyError('Unable to load flows');
        } finally {
            setIsLoading(false);
            setHasFetchedFlowList(true);
        }
    }, [pagination.page, notifyError, searchQuery, filterLabels, sortDirection, sortProperty]);

    useEffect(() => {
        if (!(tenant && isEditorActive)) {
            return;
        }

        fetchFlowLabels();
    }, [fetchFlowLabels, isEditorActive, tenant]);

    useEffect(() => {
        if (!(tenant && isEditorActive)) {
            return;
        }

        fetchFlows();
    }, [fetchFlows, isEditorActive, tenant]);

    const onClickNewFlow = () => {
        if (!tenant) {
            return;
        }

        setShowNewFlowModal(true);
    };

    const onFlowCreated = (flowId: string) => {
        if (!tenant) {
            return;
        }

        const route = generateRouteUrl({
            tabType: TAB_TYPES.flow,
            tenantId: tenant.id,
            options: {
                elementId: flowId,
                tabKey: generateTabKey(),
            },
        });

        setShowNewFlowModal(false);
        navigate(route);
    };

    const onClickEditFlow = (flow: FlowResponseAPI) => {
        if (!tenant) {
            return;
        }

        const route = generateRouteUrl({
            tabType: TAB_TYPES.flow,
            tenantId: tenant.id,
            options: { elementId: flow.id.id },
        });

        if (!route) {
            return;
        }

        navigate(route);
    };

    const onClickDeleteFlow = (flow: FlowResponseAPI) => {
        setShowDeleteModal(true);
        setSelectedFlow(flow);
    };

    const deleteFlowConfirmation = async () => {
        if (!(selectedFlow?.id.id && tenant)) {
            return;
        }

        await deleteFlow(selectedFlow.id.id, tenant.id);

        setShowDeleteModal(false);
        fetchFlows();
        fetchFlowLabels();
    };

    const updateFlowLabels = async (labels: string[], flow: FlowResponseAPI) => {
        const flowData = await getLatest(flow.id.id);
        const editedFlow = {
            ...flowData,
            tags: labels,
        };

        await setLatest(editedFlow);

        fetchFlows();
        fetchFlowLabels();
    };

    const handleFilterLabelsChange = (options: MultiValue<{ value: string; label: string }>) => {
        if (options) {
            setFilterLabels(options.map((o) => o.label));
        } else {
            setFilterLabels([]);
        }
    };

    const updateFlowDescription = async (flowId: string, value: string) => {
        const flowData = await getLatest(flowId);
        const editedFlow = {
            ...flowData,
            developerSummary: value,
        };

        await setLatest(editedFlow);

        fetchFlows();
    };

    const controlsBar = (
        <div className="flow-controls-bar">
            <div className="controls-left">
                <SearchInput
                    value={searchQuery}
                    onChange={setSearchQuery}
                    placeholder={translations.HOME_flow_search_placeholder}
                />
                <div className="label-filter">
                    <Select
                        inputId="flow-label-filter"
                        options={allFlowLabels.map((label) => ({
                            value: label,
                            label,
                        }))}
                        onChange={(
                            selectedOption: MultiValue<{
                                value: string;
                                label: string;
                            }>,
                        ) => handleFilterLabelsChange(selectedOption)}
                        isMulti={true}
                        styles={getSharedStyles<{ label: string; value: string }, true>()}
                        placeholder={translations.HOME_flow_filter_placeholder}
                        noOptionsMessage={() => translations.HOME_flow_filter_none}
                    />
                </div>
            </div>
            <div className="controls-right">
                <ButtonPrimary onClick={onClickNewFlow}>
                    <Plus /> {translations.HOME_new_flow}
                </ButtonPrimary>
            </div>
        </div>
    );

    const columns: TableColumnList<FlowResponseAPI> = [
        {
            renderHeader: () => (
                <Sortable
                    onSort={(direction) => {
                        setSortProperty('developerName');
                        setSortDirection(direction);
                    }}
                    direction={sortProperty === 'developerName' ? sortDirection : null}
                >
                    {translations.COMMON_TABLE_name}
                </Sortable>
            ),
            renderCell: ({ item }) =>
                isTooltipRequired(item.developerName, 25) ? (
                    <ComponentWithTooltip
                        trigger={['hover', 'focus']}
                        showDelay={500}
                        fadeTime={0.2}
                        tooltipPlacement="top"
                        tooltipContent={item.developerName}
                        wrapperClass="width-expand-to-full"
                    >
                        <button
                            className="link-emulate overflow-ellipsis"
                            onClick={() => onClickEditFlow(item)}
                            type="button"
                        >
                            {item.developerName}
                        </button>
                    </ComponentWithTooltip>
                ) : (
                    <button
                        className="link-emulate overflow-ellipsis"
                        onClick={() => onClickEditFlow(item)}
                        type="button"
                    >
                        {item.developerName}
                    </button>
                ),
        },
        {
            renderHeader: () => (
                <Sortable
                    defaultDirection={'ASC'}
                    onSort={(direction) => {
                        setSortProperty('developerSummary');
                        setSortDirection(direction);
                    }}
                    direction={sortProperty === 'developerSummary' ? sortDirection : null}
                >
                    {translations.COMMON_TABLE_description}
                </Sortable>
            ),
            renderCell: ({ item }) => (
                <InlineInput
                    defaultValue={item.developerSummary ?? ''}
                    saveInputValue={(value) => updateFlowDescription(item.id.id, value)}
                    editButtonTitle={`Edit ${item.developerName} description`}
                    confirmButtonTitle={`Confirm ${item.developerName} description`}
                    cancelButtonTitle={`Cancel ${item.developerName} description`}
                    isOpen={descriptionEditorOpen === item.id.id}
                    setIsOpen={(isOpen) => {
                        if (isOpen) {
                            setDescriptionEditorOpen(item.id.id);
                        } else {
                            setDescriptionEditorOpen(null);
                        }
                    }}
                />
            ),
            cellClassName: 'generic-cell-simple',
        },
        {
            renderHeader: () => (
                <Sortable
                    onSort={(direction) => {
                        setSortProperty('dateModified');
                        setSortDirection(direction);
                    }}
                    direction={sortProperty === 'dateModified' ? sortDirection : null}
                    defaultDirection={'ASC'}
                >
                    {translations.COMMON_TABLE_last_modified}
                </Sortable>
            ),
            renderCell: ({ item }) =>
                new Date(item.dateModified).toLocaleString(undefined, {
                    dateStyle: 'medium',
                    timeStyle: 'short',
                }),
            size: '11rem',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_users,
            renderCell: ({ item }) => <FlowUsers flowId={item.id.id} />,
            size: '12rem',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_labels,
            renderCell: ({ item }) => (
                <FlowLabels
                    currentLabels={item.tags}
                    allFlowLabels={allFlowLabels}
                    saveFlowLabels={(labels: string[]) => updateFlowLabels(labels, item)}
                />
            ),
        },
        {
            renderHeader: () => translations.COMMON_TABLE_actions,
            renderCell: ({ item }) => (
                <div className="action-btn-wrapper">
                    <button
                        title={`${translations.COMMON_delete} ${item.developerName}`}
                        className="table-icon table-icon-delete"
                        onClick={() => onClickDeleteFlow(item)}
                        aria-label={`${translations.COMMON_delete} ${item.developerName}`}
                        type="button"
                    >
                        <Trash />
                    </button>
                </div>
            ),
            size: '5rem',
        },
    ];

    const onConfirmDeleteCancel = () => {
        setSelectedFlow(null);
        setShowDeleteModal(false);
    };

    const renderBody = () => {
        const confirmMessage = stringReplace(
            translations.HOME_delete_confirm,
            selectedFlow?.developerName ?? '',
        );

        return (
            <div className="container-fluid">
                <div className="message-line">{confirmMessage}</div>
                <div className="message-line">{translations.COMMON_cannot_be_undone}</div>
            </div>
        );
    };

    const renderFooter = () => (
        <FooterButtons
            confirm={deleteFlowConfirmation}
            confirmButtonText={translations.COMMON_delete}
            confirmButtonClasses="btn-danger"
            cancel={onConfirmDeleteCancel}
            cancelButtonText={translations.COMMON_cancel}
        />
    );

    const renderMainContent = () => {
        if (showGettingStarted) {
            return <GettingStarted onNewFlowClick={onClickNewFlow} />;
        }
        if (isLoading) {
            return (
                <>
                    {controlsBar}
                    <Loader message={translations.HOME_loading_flows} />
                </>
            );
        }
        return (
            <>
                {controlsBar}
                <Table
                    items={filteredFlows}
                    columns={columns}
                    pagination={{
                        ...pagination,
                        changePage: (page) =>
                            setPagination((pagination) => ({ ...pagination, page })),
                    }}
                    isLoading={isLoading}
                />
            </>
        );
    };

    return (
        <div ref={flowListRef}>
            {showDeleteModal ? (
                <GenericModal
                    className="confirm-modal"
                    onHide={onConfirmDeleteCancel}
                    renderHeader={() => (
                        <h4 className="modal-title">
                            {translations.FLOW_delete_confirmation_title}
                        </h4>
                    )}
                    renderBody={renderBody}
                    renderFooter={renderFooter}
                    container={flowListRef.current}
                />
            ) : null}
            <NewFlowModal
                show={showNewFlowModal}
                onCancel={() => setShowNewFlowModal(false)}
                onError={(error) => notifyError(error)}
                onFlowCreated={onFlowCreated}
                ref={flowListRef.current}
            />
            <h1>
                {translations.HOME_welcome_back}, {user?.firstName}
            </h1>
            <h4>{translations.HOME_heading}</h4>
            {renderMainContent()}
        </div>
    );
};

const mapDispatchToProps = {
    notifyError: notifyErrorAction,
};

export default connect(null, mapDispatchToProps)(FlowList);
