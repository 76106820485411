import { useEffect, useState } from 'react';
import NavigationEditor from './NavigationEditor';
import {
    getNavigationElements as getNavElements,
    deleteNavigationElement as deleteNavElement,
} from '../../../sources/flow';
import Loader from '../../loader/Loader';
import Modal from '../../generic/modal/GenericModal';
import Glyphicon from '../../generic/Glyphicon';
import ButtonDanger from '../../buttons/ButtonDanger';
import ButtonDefault from '../../buttons/ButtonDefault';
import ButtonPrimary from '../../buttons/ButtonPrimary';
import translations from '../../../translations';
import { Trash } from '@phosphor-icons/react';
import Table from '../../generic/Table';
import { getTenantId } from '../../../utils/tenant';
import type {
    AddNotification,
    FlowGraphMapElementResponseAPI,
    NavigationElementResponseAPI,
} from '../../../types';

type Props = {
    container: HTMLElement | null;
    close: () => void;
    addNotification: AddNotification;
    flowId: string;
    mapElements: FlowGraphMapElementResponseAPI[];
};

const NavigationModal = ({ flowId, addNotification, close, container, mapElements }: Props) => {
    const tenantId = getTenantId();
    const [navigationElements, setNavigationElements] = useState<NavigationElementResponseAPI[]>(
        [],
    );
    const [editingId, setEditingId] = useState<string | null>(null);

    const [showingEditPage, setShowingEditPage] = useState(false);
    const [showingDeletePage, setShowingDeletePage] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getNavigationData();
        // eslint-disable-next-line react-hooks/exhaustive-deps -- Treat warnings as errors, fix later
    }, []);

    const getNavigationData = async () => {
        try {
            const response = await getNavElements({
                tenantId: tenantId,
                flowId: flowId,
            });

            setNavigationElements(response);
            setIsLoading(false);
        } catch (error) {
            addNotification({
                type: 'error',
                message: (error as Error).message,
                isPersistent: true,
            });
        }
    };

    const deleteNavigationElement = async (id: string | null) => {
        if (id) {
            try {
                await deleteNavElement({
                    tenantId: tenantId,
                    flowId: flowId,
                    navigationElementId: id,
                });

                refreshData();
            } catch (error) {
                addNotification({
                    type: 'error',
                    message: (error as Error).message,
                    isPersistent: true,
                });
            }
        }
    };

    const findNavigationName = (id: string | null) => {
        if (id) {
            const foundElement = navigationElements.find((element) => {
                return element.id === id;
            });

            return foundElement?.developerName;
        }

        return undefined;
    };

    const refreshData = () => {
        closeEditor();
        setIsLoading(true);
        getNavigationData();
    };

    const showEditor = (id: string) => {
        setEditingId(id);
        setShowingEditPage(true);
    };

    const showDeleteConfirmation = (id: string) => {
        setEditingId(id);
        setShowingDeletePage(true);
    };

    const closeEditor = () => {
        setEditingId(null);
        setShowingEditPage(false);
        setShowingDeletePage(false);
    };

    const renderLoadingSection = <Loader message="Loading navigations table" />;

    const mainPageHeader = () => {
        return <h4 className="modal-title">Navigations</h4>;
    };

    const mainPageBody = () => {
        return isLoading ? (
            renderLoadingSection
        ) : (
            <div className="table-container">
                <div className="items-header">
                    <ButtonDefault className="btn-sm" onClick={refreshData}>
                        <Glyphicon glyph="refresh" />
                    </ButtonDefault>
                    <ButtonPrimary
                        className="btn-sm header-btn"
                        title="New Navigation"
                        onClick={() => setShowingEditPage(true)}
                    >
                        <Glyphicon glyph="plus" />
                        <span>New Navigation</span>
                    </ButtonPrimary>
                </div>
                <Table
                    items={navigationElements}
                    columns={[
                        {
                            renderHeader: () => translations.COMMON_TABLE_name,
                            renderCell: ({ item }) => (
                                <button
                                    className="link-emulate"
                                    onClick={() => showEditor(item.id)}
                                    type="button"
                                >
                                    {item.developerName}
                                </button>
                            ),
                        },
                        {
                            renderHeader: () => translations.COMMON_TABLE_summary,
                            renderCell: ({ item }) => <div>{item.developerSummary}</div>,
                        },
                        {
                            renderHeader: () => translations.COMMON_TABLE_actions,
                            renderCell: ({ item }) => (
                                <div className="action-btn-wrapper">
                                    <button
                                        title={`Delete ${item.developerName}`}
                                        className="table-icon table-icon-delete"
                                        aria-label={`Delete ${item.developerName}`}
                                        onClick={() => showDeleteConfirmation(item.id)}
                                        type="button"
                                    >
                                        <Trash />
                                    </button>
                                </div>
                            ),
                            size: '5rem',
                        },
                    ]}
                    pagination={true}
                />
            </div>
        );
    };

    const mainPageFooter = () => {
        return (
            <ButtonDefault title="Close Modal" onClick={close}>
                Close
            </ButtonDefault>
        );
    };

    const deletePageHeader = () => {
        return <h4 className="modal-title">Delete Navigation</h4>;
    };

    const deletePageBody = () => {
        return (
            <>
                <h2>Confirm Delete</h2>
                <p data-testid="delete-subtext">
                    Are you sure you want to delete the Navigation '{findNavigationName(editingId)}
                    '? <strong>This cannot be undone.</strong>
                </p>
            </>
        );
    };

    const deletePageFooter = () => {
        return (
            <>
                <ButtonDefault title="Close Delete" onClick={closeEditor}>
                    Don't Delete
                </ButtonDefault>
                <ButtonDanger
                    title="Confirm Delete"
                    onClick={() => deleteNavigationElement(editingId)}
                >
                    Delete
                </ButtonDanger>
            </>
        );
    };

    return showingEditPage ? (
        <NavigationEditor
            navigationElementId={editingId}
            tenantId={tenantId}
            flowId={flowId}
            addNotification={addNotification}
            close={closeEditor}
            closeModal={close}
            done={refreshData}
            container={container}
            mapElements={mapElements}
        />
    ) : (
        <Modal
            className="config-modal"
            renderHeader={showingDeletePage ? deletePageHeader : mainPageHeader}
            renderBody={showingDeletePage ? deletePageBody : mainPageBody}
            renderFooter={showingDeletePage ? deletePageFooter : mainPageFooter}
            container={container}
            onHide={close}
        />
    );
};

export default NavigationModal;
