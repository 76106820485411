import { type ComponentProps, type ReactNode, useState } from 'react';
import '../../../../css/organization-invite-form.less';
import translations from '../../translations';
import { stringReplace } from '../../utils';
import GenericModal from '../generic/modal/GenericModal';
import { useOrganizationInvite } from './OrganizationInviteProvider';
import ButtonLoading from '../buttons/ButtonLoading';

type RenderBodyProps<TMode> = {
    input: string;
    hasSubmitted: boolean;
    inputMode: TMode;
    validationMessage: string;
    handleClose: () => void;
    resetForm: (resetMode: boolean) => void;
    handleInputChange: (value: string) => void;
};

type InviteModalProps<TMode> = {
    modes: readonly TMode[];
    onSend: (input: string, mode: TMode) => void;
    onValidate: (input: string, mode: TMode) => string;
    renderBody: (props: RenderBodyProps<TMode>) => ReactNode;
} & Pick<ComponentProps<typeof GenericModal>, 'container' | 'show' | 'title' | 'onHide'>;

const InviteModal = <TMode extends { name: string; label: string; helpText: string }>({
    show = false,
    title = '',
    container,
    modes,
    renderBody,
    onValidate,
    onSend,
    onHide,
}: InviteModalProps<TMode>) => {
    const [inputMode, setInputMode] = useState<(typeof modes)[number]>(modes[0]);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [validationMessage, setValidationMessage] = useState('');
    const [input, setInput] = useState('');

    const { isLoading } = useOrganizationInvite();

    const handleValidate = (input: string, mode: TMode) => {
        const message = onValidate(input, mode);
        setValidationMessage(message);
        return message.length === 0;
    };

    const handleInputChange = (value: string) => {
        setInput(value);

        if (!hasSubmitted) {
            return;
        }

        handleValidate(value, inputMode);
    };

    const resetForm = (resetMode = true) => {
        setHasSubmitted(false);
        setValidationMessage('');
        setInput('');
        if (resetMode) {
            setInputMode(modes[0]);
        }
    };

    const handleClose = () => {
        resetForm();
        onHide();
    };

    const handleModeChange = (mode: TMode) => {
        setInputMode(mode);
        resetForm(false);
    };

    const handleSendInvite = () => {
        setHasSubmitted(true);

        if (!handleValidate(input, inputMode)) {
            return;
        }

        onSend(input, inputMode);
        resetForm();
    };

    return (
        <GenericModal
            show={show}
            container={container}
            title={title}
            onHide={handleClose}
            renderBody={() => (
                <>
                    {renderBody({
                        input,
                        inputMode,
                        validationMessage,
                        hasSubmitted,
                        handleClose,
                        resetForm,
                        handleInputChange,
                    })}
                    <div>
                        {modes.map((mode) => (
                            <label
                                key={mode.name}
                                className="organization-invite-radio-button-container"
                            >
                                <input
                                    onChange={() => handleModeChange(mode)}
                                    type="radio"
                                    value={mode.name}
                                    name="input-type"
                                    defaultChecked={mode.name === inputMode.name}
                                />
                                <span className="organization-invite-radio-button-label">
                                    {stringReplace(translations.FORG_invite_modal_mode_label, {
                                        mode: mode.label,
                                    })}
                                </span>
                            </label>
                        ))}
                    </div>
                    <span className="help-block">{inputMode.helpText}</span>
                </>
            )}
            footerClassName="organization-invite-modal-footer"
            renderFooter={() => (
                <>
                    <button type="button" className="btn btn-sm btn-default" onClick={handleClose}>
                        {translations.FORG_invite_form_cancel_button_label}
                    </button>
                    <ButtonLoading
                        className="btn btn-sm btn-primary"
                        isLoading={isLoading}
                        onClick={handleSendInvite}
                    >
                        {isLoading
                            ? translations.FORG_invite_form_send_in_progress_button_label
                            : translations.FORG_invite_form_send_button_label}
                    </ButtonLoading>
                </>
            )}
        />
    );
};

export default InviteModal;
