import translations from '../../translations';
import type { FeatureFlagAPI } from '../../types';
import FormGroup from '../generic/FormGroup';
import Toggle from '../inputs/Toggle';
import { useFeatureFlag } from './FeatureFlagProvider';

const Features = () => {
    const { flags, setFeatureFlag } = useFeatureFlag();

    const handleChange = async (id: string, state: boolean) => {
        await setFeatureFlag(id, state);
    };

    const flagToggles = flags
        .sort((first: FeatureFlagAPI, second: FeatureFlagAPI) => {
            return first.name.localeCompare(second.name);
        })
        .map((flag) => {
            const id = `feature-${flag.id}`;
            return (
                <div key={flag.id} className="margin-bottom">
                    <FormGroup htmlFor={id} label={flag.name} labelPosition="after">
                        <Toggle
                            id={id}
                            isOn={flag.enabled}
                            onChange={({ isOn }) => handleChange(flag.id, isOn)}
                        />
                    </FormGroup>
                    <p>{flag.description}</p>
                </div>
            );
        });

    return (
        <span className="admin-transition">
            <div className="flex-column full-height">
                <div className="admin-page">
                    <h1>{translations.FEATURES_heading}</h1>
                    <p>{translations.FEATURES_description}</p>
                    {flagToggles}
                </div>
            </div>
        </span>
    );
};

export default Features;
