import Modal from '../../../../../../ts/components/generic/modal/GenericModal';
import ButtonPrimary from '../../../../../../ts/components/buttons/ButtonPrimary';
import ButtonDefault from '../../../../../../ts/components/buttons/ButtonDefault';
import Loader from '../../../../../../ts/components/loader/Loader';
import CodeEditor from '../../../../../../ts/components/generic/CodeEditor';

import { getPage } from '../../../../../sources/page';
import { useEffect, useState } from 'react';
import { isNullOrUndefined } from '../../../../../utils/guard';
import { usePageEditor } from '../../../../../../ts/components/page-editor/components/PageEditorProvider';
import { useComposer } from '../../../../../../ts/components/page-editor/components/gui/composer/ComposerProvider';
import type {
    PageComponent,
    PageElementRequestAPI,
    PageElementResponseAPI,
} from '../../../../../types';

interface Props {
    showModal: (show: boolean) => void;
    pageId: string;
}

const Metadata = ({ showModal, pageId }: Props) => {
    const { onSave, addNotification, updatePage, container } = usePageEditor();
    const { dragDropElements, elementToEdit } = useComposer();

    const [pageMetaData, setPageMetaData] = useState<{
        all: string | null;
        visible: string | null;
    }>({ all: null, visible: null });

    const selectedPageComponent = dragDropElements.find(
        (element) => element.id === elementToEdit && element.componentType !== undefined,
    );

    const saveMetaData = async () => {
        const parsedVisibleData = JSON.parse(
            pageMetaData.visible as string,
        ) as PageElementRequestAPI;
        const parsedAllData = JSON.parse(pageMetaData.all as string) as PageElementRequestAPI;
        let dataToSave = parsedVisibleData;

        // Patch the page metadata with the updated component metadata
        // if a component is currently selected.
        if (selectedPageComponent !== undefined) {
            const updatedPageComponents = parsedAllData.pageComponents?.map((element) =>
                element.id === selectedPageComponent.id ? parsedVisibleData : element,
            ) as PageComponent[];
            dataToSave = { ...parsedAllData, pageComponents: updatedPageComponents };
        }

        const savedPage = await onSave(dataToSave);

        if (savedPage) {
            updatePage(savedPage as PageElementRequestAPI);
            showModal(false);
        }
    };

    const fetchPage = async () => {
        try {
            const allPageData = await getPage(pageId);
            let visiblePageData: PageElementResponseAPI | PageComponent = allPageData;

            // Extract the currently selected page component metadata
            // to be displayed in the metadata editor in isolation
            // fall back to whole page metadata if the component has yet to be saved
            if (selectedPageComponent !== undefined && allPageData.pageComponents !== null) {
                const pageComponent = allPageData.pageComponents.find(
                    (element) => element.id === selectedPageComponent.id,
                );
                if (!isNullOrUndefined(pageComponent)) {
                    visiblePageData = pageComponent;
                }
            }

            setPageMetaData({
                all: JSON.stringify(allPageData, null, 4),
                visible: JSON.stringify(visiblePageData, null, 4),
            });
        } catch (error) {
            addNotification({
                type: 'error',
                message: (error as Error).message,
                isPersistent: true,
            });
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: Treat warnings as errors, fix later
    useEffect(() => {
        // The metadata editor should only ever display the page metadata
        // saved to the db. Never what is in client state.
        fetchPage();
    }, []);

    if (!pageMetaData.visible) {
        return <Loader />;
    }

    return (
        <Modal
            container={container}
            show
            className="metadata-editor config-modal"
            title="Page Metadata"
            onHide={() => showModal(false)}
            renderBody={() => (
                <CodeEditor
                    value={pageMetaData.visible}
                    name={'metadata-editor'}
                    onChange={(data) => setPageMetaData({ all: pageMetaData.all, visible: data })}
                />
            )}
            renderFooter={() => (
                <>
                    <ButtonDefault title="Cancel Settings" onClick={() => showModal(false)}>
                        Cancel
                    </ButtonDefault>
                    <ButtonPrimary onClick={saveMetaData} title="Save page metadata">
                        Save
                    </ButtonPrimary>
                </>
            )}
        />
    );
};

export default Metadata;
