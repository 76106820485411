import type { CustomIconProps } from '../../types';

const FlowIcon = (props: CustomIconProps) => (
    <svg
        width="20"
        height="20"
        viewBox="-2 -2 23 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <title>Flow Icon</title>
        <path
            d="M2.5 5.05H1.55V6.95H2.5V5.05ZM6 6V6.95H6.36119L6.63115 6.71004L6 6ZM10.5 2V1.05H10.1388L9.86885 1.28996L10.5 2ZM13.5 2.95C14.0247 2.95 14.45 2.52467 14.45 2C14.45 1.47533 14.0247 1.05 13.5 1.05V2.95ZM2.5 6.95H6V5.05H2.5V6.95ZM6.63115 6.71004L11.1311 2.71004L9.86885 1.28996L5.36885 5.28996L6.63115 6.71004ZM10.5 2.95H13.5V1.05H10.5V2.95Z"
            fill="#033D58"
        />
        <path
            d="M2 10.05H1.05V11.95H2V10.05ZM7 11V11.95H7.36119L7.63115 11.71L7 11ZM12.625 6V5.05H12.2638L11.9939 5.28996L12.625 6ZM17 6.95C17.5247 6.95 17.95 6.52467 17.95 6C17.95 5.47533 17.5247 5.05 17 5.05V6.95ZM2 11.95H7V10.05H2V11.95ZM7.63115 11.71L13.2561 6.71004L11.9939 5.28996L6.36885 10.29L7.63115 11.71ZM12.625 6.95H17V5.05H12.625V6.95Z"
            fill="#033D58"
        />
        <path
            d="M4 15.05C3.47533 15.05 3.05 15.4753 3.05 16C3.05 16.5247 3.47533 16.95 4 16.95V15.05ZM8.66667 16V16.95H9.04667L9.32184 16.6879L8.66667 16ZM13.9167 11V10.05H13.5367L13.2615 10.3121L13.9167 11ZM17.5 11.95H18.45V10.05H17.5V11.95ZM4 16.95H8.66667V15.05H4V16.95ZM9.32184 16.6879L14.5718 11.6879L13.2615 10.3121L8.01149 15.3121L9.32184 16.6879ZM13.9167 11.95H17.5V10.05H13.9167V11.95Z"
            fill="#033D58"
        />
        <circle cx="9.5" cy="9.5" r="8.75" stroke="#033D58" strokeWidth="1.5" />
    </svg>
);

export default FlowIcon;
