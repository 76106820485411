import ConfigSection from '../../ConfigSection';
import { COMPONENT_CONFIGURATION_PATH as cfgPath } from '../../../../../../constants';
import { useComposer } from '../../../ComposerProvider';
import FormGroup from '../../../../../../../generic/FormGroup';
import InputOrValuePicker from '../../../../../../../generic/InputOrValuePicker';
import type { ValueElementIdAPI } from '../../../../../../../../types';
import { usePageEditor } from '../../../../../PageEditorProvider';

type Attribute = { value: string; key: string };
type AvailableAttribute = { value: string; label: string };

interface Props {
    id: string;
    attributes: Attribute[];
    attributesList: AvailableAttribute[];
}

const WritableAttributesConfig = ({ id, attributes, attributesList }: Props) => {
    const { onPageElementUpdate } = useComposer();
    const { container } = usePageEditor();

    const updateAttribute = (key: string) => (value: string | ValueElementIdAPI | null) =>
        onPageElementUpdate(id || '', cfgPath['ATTRIBUTES'], {
            ...attributes.reduce(
                (previousValue, currentValue) =>
                    Object.assign(previousValue, { [currentValue.key]: currentValue.value }),
                {},
            ),
            [key]: value ?? '',
        });

    const findAttributeValue = (key: string) =>
        attributes.find((attribute) => attribute.key === key)?.value;

    return (
        <ConfigSection title="Attributes" dataTestId="writable-attributes-section">
            {attributesList.map((attr) => (
                <FormGroup key={attr.value} label={attr.label} htmlFor={`${attr.value}-attribute`}>
                    <InputOrValuePicker
                        value={findAttributeValue(attr.value) ?? null}
                        onChangeAsValueReference={updateAttribute(attr.value)}
                        includeSystemValues={true}
                        container={container}
                    >
                        <input
                            className="form-control"
                            id={`${attr.value}-attribute`}
                            type="text"
                            value={(findAttributeValue(attr.value) as string) ?? ''}
                            onChange={(event) => {
                                updateAttribute(attr.value)(event.target.value);
                            }}
                        />
                    </InputOrValuePicker>
                </FormGroup>
            ))}
        </ConfigSection>
    );
};

export default WritableAttributesConfig;
