import MapElementModal from '../../../graph/MapElementModal';
import { MAP_ELEMENT_TYPES } from '../../../../constants';
import screens from '../common/screens';
import { DataActionProvider } from '../contextProviders/DataActionProvider';
import {
    type MapElementConfigProps,
    MapElementProvider,
} from '../contextProviders/MapElementProvider';
import DeleteRouter from './DeleteRouter';
import { CRUD_OPERATION_TYPES } from '../common/constants';

const Delete = (props: MapElementConfigProps) => {
    return (
        <MapElementProvider
            {...props}
            defaultScreen={screens.delete}
            elementType={MAP_ELEMENT_TYPES.databaseDelete}
            isLoading={false}
        >
            <MapElementModal>
                <DataActionProvider
                    defaultScreen={screens.delete}
                    crudOperationType={CRUD_OPERATION_TYPES['delete']}
                >
                    <DeleteRouter />
                </DataActionProvider>
            </MapElementModal>
        </MapElementProvider>
    );
};

export default Delete;
