import { type ComponentRef, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { notifyError } from '../../../js/actions/reduxActions/notification';
import { useScrollSpy } from '../../hooks/scroll';
import { getTenants, getUsers } from '../../sources/organization';
import translations from '../../translations';
import type { OrganizationTenant, OrganizationUser } from '../../types/organization';
import { useAuth } from '../AuthProvider';
import CopyableText from '../generic/CopyableText';
import JoinedTenants from './JoinedTenants';
import JoinedUsers from './JoinedUsers';
import LeaveOrg from './LeaveOrg';
import PendingTenantInviteList from './PendingTenantInviteList';
import PendingUserInviteList from './PendingUserInviteList';
import ProvisionOrganizationTenant from './ProvisionOrganizationTenant';
import ReceivedTenantInviteList from './ReceivedTenantInvites';
import ReceivedUserInviteList from './ReceivedUserInvites';
import TenantInviteModal from './TenantInviteModal';
import UserInviteModal from './UserInviteModal';
import LocalRuntimeList from './localRuntimes/LocalRuntimeList';

type AdminUIProps = {
    setActiveSectionId: (activeSectionId: string | null) => void;
    hasMenu: boolean;
};

const AdminUI = ({ setActiveSectionId, hasMenu }: AdminUIProps) => {
    const { tenant } = useAuth();

    const contentRef = useRef<ComponentRef<'div'>>(null);

    const [showUserInviteModal, setShowUserInviteModal] = useState(false);
    const [showTenantInviteModal, setShowTenantInviteModal] = useState(false);
    const [showProvisionTenantModal, setShowProvisionTenantModal] = useState(false);

    const [tenants, setTenants] = useState<OrganizationTenant[]>([]);
    const [users, setUsers] = useState<OrganizationUser[]>([]);

    const [isLoadingTenants, setIsLoadingTenants] = useState(false);
    const [isLoadingUsers, setIsLoadingUsers] = useState(false);

    const dispatch = useDispatch();

    const fetchTenants = useCallback(async () => {
        try {
            setIsLoadingTenants(true);

            const tenants = await getTenants();

            setTenants(tenants);
        } catch (error) {
            dispatch(notifyError(error));
        } finally {
            setIsLoadingTenants(false);
        }
    }, [dispatch]);

    const fetchUsers = useCallback(async () => {
        try {
            setIsLoadingUsers(true);

            const users = await getUsers();

            setUsers(users);
        } catch (error) {
            dispatch(notifyError(error));
        } finally {
            setIsLoadingUsers(false);
        }
    }, [dispatch]);

    useEffect(() => {
        fetchTenants();
        fetchUsers();
    }, [fetchTenants, fetchUsers]);

    // using a scrollspy based on the intersection observer to report back to
    // the side-menu the currently active section so the side-menu can update
    // itself (every content page with a side-menu will have to implement this
    // hook)
    useScrollSpy(contentRef, hasMenu, setActiveSectionId);

    const organization = tenant?.organization;

    if (!organization) {
        return null;
    }

    return (
        <div data-testid="org-members" className="admin-page" ref={contentRef}>
            <h2 id="organizationDetails">{translations.FORG_organization_details_heading}</h2>
            <div className="margin-bottom">
                <CopyableText
                    labelText={translations.FORG_organization_name_label}
                    copyableText={organization.name}
                    hasCopyButton
                />
                <CopyableText
                    labelText={translations.FORG_organization_id_label}
                    copyableText={organization.id}
                    hasCopyButton
                />
            </div>
            <LeaveOrg
                isLoading={isLoadingUsers}
                container={contentRef.current}
                setIsLoading={setIsLoadingUsers}
                fetchUsers={fetchUsers}
            />
            <hr />
            <h2 id="organizationInvitations">
                {translations.FORG_organization_invitations_heading}
            </h2>
            <ReceivedTenantInviteList />
            <ReceivedUserInviteList />
            <h2 id="organizationTenants">{translations.FORG_organization_tenants_heading}</h2>
            <div className="margin-bottom">
                <button
                    className="btn btn-primary"
                    onClick={() => setShowTenantInviteModal(true)}
                    type="button"
                >
                    <span className="glyphicon glyphicon-plus" />
                    {translations.FORG_invite_tenant_button_label}
                </button>
                <button
                    className="btn btn-default"
                    onClick={() => setShowProvisionTenantModal(true)}
                    type="button"
                >
                    <span className="glyphicon glyphicon-plus" />
                    {translations.FORG_create_tenant_button_label}
                </button>
            </div>
            <TenantInviteModal
                container={contentRef.current}
                show={showTenantInviteModal}
                onHide={() => setShowTenantInviteModal(false)}
                fetchTenants={fetchTenants}
            />
            <ProvisionOrganizationTenant
                isLoading={isLoadingTenants}
                container={contentRef.current}
                show={showProvisionTenantModal}
                setIsLoading={setIsLoadingTenants}
                onHide={() => setShowProvisionTenantModal(false)}
                fetchTenants={fetchTenants}
            />
            <JoinedTenants
                isLoading={isLoadingTenants}
                setIsLoading={setIsLoadingTenants}
                tenants={tenants}
                container={contentRef.current}
                fetchTenants={fetchTenants}
            />
            <PendingTenantInviteList organizationId={organization.id} />
            <h2 id="organizationAdministrators">{translations.FORG_administrators_heading}</h2>
            <div className="margin-bottom">
                <button
                    className="btn btn-primary"
                    onClick={() => setShowUserInviteModal(true)}
                    type="button"
                >
                    <span className="glyphicon glyphicon-plus" />
                    {translations.FORG_invite_user_button_label}
                </button>
            </div>
            <UserInviteModal
                container={contentRef.current}
                show={showUserInviteModal}
                onHide={() => setShowUserInviteModal(false)}
            />
            <JoinedUsers
                users={users}
                isLoading={isLoadingUsers}
                container={contentRef.current}
                setIsLoading={setIsLoadingUsers}
                fetchUsers={fetchUsers}
            />
            <PendingUserInviteList organizationId={organization.id} />
            <LocalRuntimeList container={contentRef.current} />
        </div>
    );
};

export default AdminUI;
