import { useCallback, useEffect, useState } from 'react';
import { getNavigationElement, getNavigationElements } from '../../../../../sources/flow';
import { isNullOrEmpty } from '../../../../../utils/guard';
import { getByID } from '../../../../../utils/collection';
import ButtonDefault from '../../../../buttons/ButtonDefault';
import ButtonPrimary from '../../../../buttons/ButtonPrimary';
import FormGroup from '../../../../generic/FormGroup';
import Toggle from '../../../../inputs/Toggle';
import { useMapElement } from '../../contextProviders/MapElementProvider';
import NavigationUrlInputs from '../../../NavigationUrlInputs';
import { URL_TARGET } from '../../../constants';
import Select from 'react-select';
import ModalBody from '../../../../generic/modal/ModalBody';
import ModalFooter from '../../../../generic/modal/ModalFooter';
import { getSharedStyles } from '../../../../../utils/select';
import type {
    NavigationElementResponseAPI,
    NavigationItemAPI,
    NavigationOverride,
    ValueElementIdAPI,
} from '../../../../../types';

const NavigationOverrideDetails = () => {
    const {
        flowId,
        mapElement,
        onSwitchScreen,
        selectedNavigationIndex,
        setSelectedNavigationIndex,
        tenantId,
        notifyError,
        mapElements,
        defaultScreen,
        setMapElement,
        setConfigTitle,
    } = useMapElement();

    const selectedNavigation =
        typeof selectedNavigationIndex === 'number'
            ? (mapElement.navigationOverrides?.[selectedNavigationIndex] ?? null)
            : null;

    // State values
    const [navigations, setNavigations] = useState<NavigationElementResponseAPI[]>([]);
    const [selectedNavigationId, setSelectedNavigationId] = useState(
        selectedNavigation?.navigationElementId ?? null,
    );
    const [selectedItemId, setSelectedItemId] = useState(
        selectedNavigation?.navigationItemId ?? null,
    );
    const [navigationItems, setNavigationItems] = useState<NavigationItemAPI[]>([]);
    const [selectedMapElementId, setSelectedMapElementId] = useState(
        selectedNavigation?.locationMapElementId ?? null,
    );
    const [selectedMapElementName, setSelectedMapElementName] = useState(
        selectedNavigation?.locationMapElementDeveloperName ?? null,
    );
    const [isEnabled, setIsEnabled] = useState(selectedNavigation?.isEnabled ?? true);
    const [isVisible, setIsVisible] = useState(selectedNavigation?.isVisible ?? true);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [isUrl, setIsUrl] = useState(!isNullOrEmpty(selectedNavigation?.url));
    const [url, setUrl] = useState(selectedNavigation?.url ?? null);
    const [urlTarget, setUrlTarget] = useState<string | null>(
        selectedNavigation?.urlTarget ?? URL_TARGET.BLANK,
    );
    const [hasUrlError, setHasUrlError] = useState(false);

    const isNavigationValid = selectedNavigationId !== null;
    const isItemValid = selectedItemId !== null;
    const isUrlValid = !isUrl || (isUrl && url !== null);

    const isFormValid = isNavigationValid && isItemValid && isUrlValid;

    const onLocationChange = (option: { label: string | null; value: string } | null) => {
        const label = option?.label;
        const value = option?.value;

        if (value === 'URL') {
            setIsUrl(true);
            return;
        }

        setIsUrl(false);
        setUrl(null);
        setUrlTarget(null);
        setSelectedMapElementName(label ?? null);
        setSelectedMapElementId(value ?? null);
    };

    const onSave = () => {
        setHasSubmitted(true);
        if (isFormValid) {
            const selectedNavigationElement = getByID(selectedNavigationId, navigations);
            const selectedMapElement = selectedMapElementId
                ? getByID(selectedMapElementId, mapElements)
                : null;
            const selectedItem = navigationItems.find((item) => item.id === selectedItemId);

            const navigationOverride: NavigationOverride = {
                isEnabled,
                isVisible,
                locationMapElementDeveloperName: selectedMapElement?.developerName ?? null,
                locationMapElementId: selectedMapElementId,
                navigationElementDeveloperName: selectedNavigationElement?.developerName ?? null,
                navigationElementId: selectedNavigationId,
                navigationItemDeveloperName: selectedItem?.developerName ?? null,
                navigationItemId: selectedItemId,
                url: isUrl ? url : null,
                urlTarget: isUrl ? urlTarget : null,
                urlValueDeveloperName: null,
            };

            const index = selectedNavigationIndex ?? mapElement.navigationOverrides?.length ?? 0;
            const navigationOverrides = [...(mapElement.navigationOverrides || [])];
            navigationOverrides[index] = navigationOverride;

            setMapElement({
                ...mapElement,
                navigationOverrides,
            });

            onSwitchScreen(defaultScreen);
        } else if (!isUrlValid) {
            setHasUrlError(true);
        }
    };

    const onCancel = () => {
        onSwitchScreen(defaultScreen);
        setSelectedNavigationIndex(null);
    };

    const fetchNavigations = useCallback(async () => {
        try {
            const response = await getNavigationElements({
                tenantId,
                flowId,
            });

            setNavigations(response);
        } catch (error) {
            notifyError(error);
        }
    }, [tenantId, flowId, notifyError]);

    const fetchNavigationItems = useCallback(
        async (navigationElementId: string) => {
            try {
                const response = await getNavigationElement({
                    tenantId,
                    flowId,
                    navigationElementId,
                });

                setNavigationItems(response.navigationItems || []);
            } catch (error) {
                notifyError(error);
            }
        },
        [tenantId, flowId, notifyError],
    );

    const onChangeNavigation = ({ target: { value } }: { target: { value: string } }) => {
        fetchNavigationItems(value);
        setSelectedNavigationId(value);
    };

    const onUrlChanged = (value: ValueElementIdAPI | null) => {
        setUrl(value);
    };

    const updateUrlTarget = (e: React.ChangeEvent<HTMLSelectElement>) => {
        e.preventDefault();

        setUrlTarget(e.currentTarget.value);
    };

    const renderBody = () => (
        <>
            <FormGroup
                label="Which Navigation do you want to override?"
                isRequired
                isValid={isNavigationValid}
                validationMessage="Please select a Navigation."
                showValidation={hasSubmitted}
                htmlFor="navigation-select"
            >
                <select
                    className="form-control form-select form-control-width"
                    value={selectedNavigationId ?? ''}
                    onChange={onChangeNavigation}
                    id="navigation-select"
                    data-testid="navigation-select"
                >
                    {selectedNavigationId === null && (
                        <option value="" key="empty">
                            Select a Navigation
                        </option>
                    )}
                    {navigations.map((navigation) => (
                        <option value={navigation.id} key={navigation.id}>
                            {navigation.developerName}
                        </option>
                    ))}
                </select>
            </FormGroup>

            <FormGroup
                label="Which item do you want to override?"
                isRequired
                isValid={isItemValid}
                validationMessage="Please select a Navigation item."
                showValidation={hasSubmitted}
                htmlFor="navigation-item-select"
            >
                <select
                    className="form-control form-select form-control-width"
                    value={selectedItemId ?? ''}
                    onChange={({ target: { value } }) => setSelectedItemId(value)}
                    disabled={selectedNavigationId === null}
                    id="navigation-item-select"
                    data-testid="navigation-item-select"
                >
                    {selectedItemId === null && selectedNavigationId !== null && (
                        <option value="" key="empty">
                            Select an item
                        </option>
                    )}
                    {navigationItems.map((navigation) => (
                        <option value={navigation.id ?? ''} key={navigation.id}>
                            {navigation.developerName ?? navigation.label}
                        </option>
                    ))}
                </select>
            </FormGroup>

            <FormGroup
                label="Change the location of the item to:"
                htmlFor="navigation-location-select"
            >
                <Select
                    inputId="navigation-location-select"
                    data-testid="navigation-location-select"
                    styles={{
                        ...getSharedStyles<{ label: string | null; value: string }>({
                            container: (baseStyles) => ({
                                ...baseStyles,
                                width: '300px',
                            }),
                        }),
                    }}
                    options={[{ label: 'URL', value: 'URL' }].concat(
                        mapElements.map((mapElementLocation) => ({
                            label: mapElementLocation.developerName ?? '',
                            value: mapElementLocation.id ?? '',
                        })),
                    )}
                    onChange={onLocationChange}
                    placeholder="Select a map element or go to a URL"
                    noOptionsMessage={() => 'No results found'}
                    value={
                        isUrl
                            ? { label: 'URL', value: 'URL' }
                            : selectedMapElementId
                              ? { label: selectedMapElementName, value: selectedMapElementId }
                              : null
                    }
                    isClearable
                    menuPosition="fixed"
                    menuPortalTarget={document.querySelector(`#flow-${flowId} .modal`)}
                    closeMenuOnScroll={(e) => {
                        if (e.target === document.querySelector(`#flow-${flowId} .modal-body`)) {
                            return true;
                        }
                        return false;
                    }}
                />
            </FormGroup>

            {isUrl && (
                <NavigationUrlInputs
                    hasUrlError={hasUrlError}
                    url={url}
                    urlTarget={urlTarget}
                    onUrlChanged={onUrlChanged}
                    updateUrlTarget={updateUrlTarget}
                    controlWidth={true}
                />
            )}

            <FormGroup>
                <label htmlFor="is-visible-to-users-toggle">
                    <Toggle
                        id="is-visible-to-users-toggle"
                        isOn={isVisible}
                        onChange={() => setIsVisible(!isVisible)}
                    />
                    Should the item be visible to users?
                </label>
            </FormGroup>

            <FormGroup>
                <label htmlFor="is-enabled-for-users-toggle">
                    <Toggle
                        id="is-enabled-for-users-toggle"
                        isOn={isEnabled}
                        onChange={() => setIsEnabled(!isEnabled)}
                    />
                    Should the item be enabled for users to select?
                </label>
            </FormGroup>
        </>
    );

    useEffect(() => {
        fetchNavigations();

        if (selectedNavigationId !== null) {
            fetchNavigationItems(selectedNavigationId);
        }
    }, [fetchNavigations, selectedNavigationId, fetchNavigationItems]);

    useEffect(() => {
        setConfigTitle(`Navigation Override for: ${mapElement.developerName}`);

        return () => setConfigTitle(null);
    }, [mapElement.developerName, setConfigTitle]);

    return (
        <>
            <ModalBody>{renderBody()}</ModalBody>
            <ModalFooter>
                <ButtonDefault className="flex-child-right" onClick={onCancel}>
                    Cancel
                </ButtonDefault>
                <ButtonPrimary className="margin-left" onClick={onSave}>
                    {selectedNavigationIndex === null ? 'Add' : 'Update'}
                </ButtonPrimary>
            </ModalFooter>
        </>
    );
};

export default NavigationOverrideDetails;
