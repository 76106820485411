import type { ChangeEvent } from 'react';
import FormGroup from '../../../generic/FormGroup';
import { isPageValid, type NavigationElement, type NavigationErrors } from '../utils';

type Props = {
    navigationElement: NavigationElement;
    errors: NavigationErrors;
    onChangeNavigationElement: (navigationElement: NavigationElement) => void;
    showValidation: boolean;
};

const NavigationForm = ({
    navigationElement,
    errors,
    onChangeNavigationElement,
    showValidation,
}: Props) => {
    const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        onChangeNavigationElement({
            ...navigationElement,
            developerName: e.target.value,
        });
    };

    const handleLabelChange = (e: ChangeEvent<HTMLInputElement>) => {
        onChangeNavigationElement({
            ...navigationElement,
            label: e.target.value,
        });
    };

    const isValid = isPageValid(navigationElement.developerName || null, errors);

    return (
        <div className="nav-details-bar">
            <FormGroup
                label="Name"
                isRequired
                isValid={isValid}
                showValidation={showValidation}
                validationMessage={'This field is required.'}
                htmlFor="name"
            >
                <input
                    type="text"
                    className="form-control"
                    maxLength={150}
                    size={25}
                    value={navigationElement.developerName || ''}
                    onChange={handleNameChange}
                    required
                    data-testid="name-input"
                    id="name"
                />
            </FormGroup>
            <FormGroup label="Label" htmlFor="label">
                <input
                    type="text"
                    className="form-control"
                    maxLength={150}
                    size={25}
                    value={navigationElement.label}
                    onChange={handleLabelChange}
                    id="label"
                    data-testid="label-input"
                />
            </FormGroup>
        </div>
    );
};

export default NavigationForm;
