import ButtonDefault from '../../buttons/ButtonDefault';
import Glyphicon from '../../generic/Glyphicon';
import SearchInput from '../../generic/SearchInput';

type Props = {
    setSearchQuery: (query: string) => void;
    getElements: () => void;
};

const SearchImportExisting = ({ setSearchQuery, getElements }: Props) => (
    <div className="import-content-header">
        <SearchInput
            className="tab-header-search"
            onChange={(searchText) => {
                setSearchQuery(searchText);
            }}
        />
        <ButtonDefault className="btn-sm" title="Refresh" onClick={getElements}>
            <Glyphicon glyph="refresh" />
        </ButtonDefault>
    </div>
);

export default SearchImportExisting;
