import { useMapElement } from '../contextProviders/MapElementProvider';
import OpenApiConfiguration from './OpenApiConfiguration';
import OpenApiDetails from './OpenApiDetails';

const OpenApiRouter = () => {
    const { currentScreen } = useMapElement();

    switch (currentScreen) {
        case 'openApi':
            return <OpenApiConfiguration />;

        case 'openApiDetails':
            return <OpenApiDetails />;

        default:
            return null;
    }
};

export default OpenApiRouter;
