import { prop, sortBy, assocPath } from 'ramda';
import { createContext, useContext, useEffect, useReducer, useState, type ReactNode } from 'react';
import {
    BREADCRUMB_ACTION_TYPES,
    createBreadcrumbReducer,
    type BreadcrumbState,
} from '../../../../reducers/breadcrumbs';
import { getTenantId } from '../../../../utils/tenant';
import { guid } from '../../../../utils/guid';
import { isNullOrEmpty } from '../../../../utils/guard';
import {
    createNewOutcomeAndNewMapElement,
    filterOutNotes,
} from '../../../../../js/components/graph/utils';
import { COLLABORATION_ITEM_TYPES, FLOW_EDITING_TOKEN } from '../../../../constants';
import screens, { type Screen } from '../common/screens';
import { useCollaboration } from '../../../../collaboration/CollaborationProvider';
import {
    getMapElement,
    saveMapElement,
    type MapElementRequestAPI,
} from '../../../../sources/graph';
import { useAuth } from '../../../AuthProvider';
import { useGraph } from '../../../../../js/components/graph/GraphProvider';
import type {
    FlowGraphMapElementResponseAPI,
    FormalValueReference,
    MapElement,
    NotifyError,
} from '../../../../types';
import { FRIENDLY_SCREEN_NAMES } from '../common/constants';

type Selector = {
    selectedValueMeta?: { id: string; typeElementPropertyId: string | null };
    formalValueId?: string;
    valueElementInSubflowId: { id: string | null };
    valueElementToApplyId: { id: string | null; typeElementPropertyId: string | null };
};

export type MapElementContext = {
    id: string | null;
    title: string | null;
    elementType: string;
    onHide: () => void;
    container: HTMLElement | null;
    currentUserId: string;
    tenantId: string;
    editingToken: string;
    flowId: string;
    mapElement: MapElement;
    currentScreen: Screen;
    breadcrumbs: BreadcrumbState<string>;
    onReturnToDefaultScreen: () => void;
    onSaveMapElement: (mapElement: MapElementRequestAPI) => void;
    onClose: () => void;
    onUpdateName: (name: string) => void;
    onUpdateTitle: (title: string) => void;
    onUpdateComments: (comments: string) => void;
    onUpdateStatusMessage: (message: string) => void;
    onUpdateNotAuthorizedMessage: (message: string) => void;
    onUpdateSubFlowArguments: (selectors: FormalValueReference[]) => void;
    onPushBreadCrumb: (id: Screen, content: string) => void;
    onSetActiveBreadCrumb: (activeItemId: Screen) => void;
    setMapElement: (mapElement: MapElement) => void;
    onSwitchScreen: (screen: Screen) => void;
    setConfigTitle: (title: string | null) => void;
    mapElements: MapElement[];
    onEditOutcome: (index: number) => void;
    onDeleteOutcome: (index: number) => void;
    selectedOutcomeIndex: number | null;
    setSelectedOutcomeIndex: (index: number | null) => void;
    defaultScreen: Screen;
    onEditNavigation: (index: number) => void;
    onDeleteNavigation: (index: number) => void;
    selectedNavigationIndex: number | null;
    setSelectedNavigationIndex: (index: number | null) => void;
    notifyError: NotifyError;
};

export type MapElementConfigProps = {
    outcomeId: string | null;
    nextMapElementId: string | null;
    groupElementId?: string | null | undefined;
    mapElements: FlowGraphMapElementResponseAPI[];
    id: string | null;
    x: number;
    y: number;
    flowId: string;
    notifyError: NotifyError;
    container: HTMLElement | null;
    dismissMapElementConfig: () => void;
    fromMapElementId?: string | null | undefined;
    setIsLoading: (isLoading: boolean) => void;
    refreshFlow: () => void;
    focusAndSelectElement: (id: string) => void;
    children?: ReactNode;
};

export type MapElementProviderProps = MapElementConfigProps & {
    defaultScreen: Screen;
    elementType: string;
    isLoading: boolean;
};

const Context = createContext<MapElementContext | undefined>(undefined);

/**
 * The core map element context provider.
 * Every map element needs to subscribe to this context.
 */
const MapElementProvider = ({
    groupElementId = null,
    defaultScreen,
    mapElements,
    elementType,
    id,
    x,
    y,
    flowId,
    notifyError,
    container,
    children,
    dismissMapElementConfig,
    fromMapElementId = null,
    isLoading,
    setIsLoading,
    refreshFlow,
    focusAndSelectElement,
}: MapElementProviderProps) => {
    const tenantId = getTenantId();
    const { user } = useAuth();

    const currentUserId = user?.id;

    const [mapElementId, setMapElementId] = useState<string | null>(id);
    const [isLoadingMapElement, setIsLoadingMapElement] = useState(true);
    const [selectedOutcomeIndex, setSelectedOutcomeIndex] = useState<number | null>(null);
    const [selectedNavigationIndex, setSelectedNavigationIndex] = useState<number | null>(null);
    const [currentScreen, setCurrentScreen] = useState<Screen>(defaultScreen);
    const [configTitle, setConfigTitle] = useState<string | null>('');

    try {
        // biome-ignore lint/correctness/useHookAtTopLevel: Treat warnings as errors, fix later
        const graphContext = useGraph() as {
            isLoading: boolean;
            setIsLoading: (isLoading: boolean) => void;
        };
        isLoading = graphContext.isLoading;
        setIsLoading = graphContext.setIsLoading;
    } catch (_event) {
        // This component is not being used within the GraphProvider.
        // Do nothing.
    }

    const { invoke, itemOpened, itemClosed, itemChanged, getItem } = useCollaboration();

    const breadCrumbLabel =
        FRIENDLY_SCREEN_NAMES[defaultScreen as keyof typeof FRIENDLY_SCREEN_NAMES];

    const [breadcrumbs, setBreadcrumbs] = useReducer(createBreadcrumbReducer(), {
        trail: [
            {
                id: defaultScreen,
                content: breadCrumbLabel ?? '',
                onClick: () => onReturnToDefaultScreen(),
            },
        ],
        activeItemId: defaultScreen,
    });

    const setMapElement = (newMapElement: MapElement) => {
        if (mapElementId) {
            itemChanged(mapElementId, 1, newMapElement, !!id);
        }
    };

    const onSwitchScreen = (screen: Screen) => {
        if (!Object.prototype.hasOwnProperty.call(screens, screen)) {
            throw Error(`The screen named: ${screen} is not valid`);
        }

        setCurrentScreen(screen);
    };

    const onPushBreadCrumb = (id: Screen, content: string) => {
        setBreadcrumbs({
            type: BREADCRUMB_ACTION_TYPES.push_breadcrumb,
            payload: {
                id,
                content,
                onClick: () => onSetActiveBreadCrumb(id),
            },
        });
    };

    const onSetActiveBreadCrumb = (activeItemId: Screen) => {
        setBreadcrumbs({
            type: BREADCRUMB_ACTION_TYPES.set_active_breadcrumb,
            payload: {
                activeItemId,
            },
        });
        onSwitchScreen(activeItemId);
    };

    const onReturnToDefaultScreen = () => {
        onSetActiveBreadCrumb(defaultScreen);
    };

    const onSaveMapElement = async (newMapElement: MapElementRequestAPI) => {
        setIsLoading(true);

        try {
            const newMapElementResponse = await saveMapElement(newMapElement, flowId);
            invoke('GraphChanged', flowId, [newMapElementResponse.id], null);
            onClose(newMapElementResponse.id);
        } catch (error) {
            notifyError(error);
        } finally {
            setIsLoading(false);
        }

        if (fromMapElementId) {
            await createNewOutcomeAndNewMapElement(
                fromMapElementId,
                mapElements,
                newMapElement.developerName,
                newMapElement.id,
                (mapElement: MapElementRequestAPI) => saveMapElement(mapElement, flowId),
                (mapElementId: string) => getMapElement(mapElementId, flowId),
                notifyError,
            );
            onClose(fromMapElementId);
            setIsLoading(false);
        }
    };

    // Provide an optional ID that is used instead. Useful for new elements that don't have an ID yet.
    const onClose = (newElementId = id, refresh = true) => {
        if (refresh) {
            refreshFlow();
        }

        if (newElementId) {
            dismissMapElementConfig();
            itemClosed(id, COLLABORATION_ITEM_TYPES.mapElement, !!id);
            focusAndSelectElement(newElementId);
        }
    };

    const onUpdateName = (developerName: string) => {
        if (mapElementId) {
            setMapElement({ ...(getItem(mapElementId) as MapElement), developerName });
        }
    };

    const onUpdateTitle = (title: string) => {
        if (mapElementId) {
            setMapElement({ ...(getItem(mapElementId) as MapElement), title });
        }
    };

    const onUpdateComments = (developerSummary: string) => {
        if (mapElementId) {
            setMapElement({ ...(getItem(mapElementId) as MapElement), developerSummary });
        }
    };

    const onUpdateStatusMessage = (statusMessage: string) => {
        if (mapElementId) {
            setMapElement({ ...(getItem(mapElementId) as MapElement), statusMessage });
        }
    };

    const onUpdateNotAuthorizedMessage = (notAuthorizedMessage: string) => {
        if (mapElementId) {
            setMapElement({ ...(getItem(mapElementId) as MapElement), notAuthorizedMessage });
        }
    };

    const onUpdateSubFlowArguments = (selectors: FormalValueReference[]) => {
        const subFlowArguments = selectors
            ? selectors.reduce((acc, selector) => {
                  if (selector.selectedValueMeta) {
                      acc.push({
                          valueElementInSubflowId: {
                              id: selector.formalValueId || null,
                          },
                          valueElementToApplyId: {
                              id: selector.selectedValueMeta.id || null,
                              typeElementPropertyId:
                                  selector.selectedValueMeta.typeElementPropertyId || null,
                          },
                      });
                  }
                  return acc;
              }, [] as Selector[])
            : null;

        if (mapElementId) {
            setMapElement(
                assocPath(
                    ['subflow', 'arguments'],
                    subFlowArguments,
                    getItem(mapElementId) as MapElement,
                ),
            );
        }
    };

    const onEditOutcome = (outcomeIndex: number) => {
        setSelectedOutcomeIndex(outcomeIndex);
        onSwitchScreen(screens.outcome);
        onPushBreadCrumb(screens.outcome, screens.outcome);
    };

    const onDeleteOutcome = (outcomeIndex: number) => {
        if (mapElementId) {
            const mapElement = getItem(mapElementId) as MapElement;
            const newMapElement = {
                ...mapElement,
                outcomes: (mapElement.outcomes ?? []).filter((_, index) => index !== outcomeIndex),
            };

            setMapElement(newMapElement);
        }
    };

    const onEditNavigation = (navigationIndex: number) => {
        setSelectedNavigationIndex(navigationIndex);
        onSwitchScreen(screens.navigationOverrides);
        onPushBreadCrumb(screens.navigationOverrides, screens.navigationOverrides);
    };

    const onDeleteNavigation = (navigationIndex: number) => {
        if (mapElementId) {
            const mapElement = getItem(mapElementId) as MapElement;
            setMapElement({
                ...mapElement,
                navigationOverrides: (mapElement.navigationOverrides ?? []).filter(
                    (_, index) => index !== navigationIndex,
                ),
            });
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const load = async () => {
            setIsLoading(true);

            let mapElement: MapElementRequestAPI | null = null;

            try {
                if (isNullOrEmpty(id)) {
                    mapElement = {
                        groupElementId,
                        elementType,
                        x,
                        y,
                        developerName: '',
                        id: guid(),
                        dataActions: [],
                        messageActions: [],
                        listeners: [],
                        operations: [],
                        processes: [],
                        subflow: null,
                    };
                } else {
                    mapElement = await getMapElement(id, flowId);
                }

                if (!mapElement) {
                    return;
                }

                mapElement.operations = sortBy(prop('order'), mapElement?.operations ?? []);
                mapElement.dataActions = sortBy(prop('order'), mapElement?.dataActions ?? []);
                mapElement.messageActions = sortBy(prop('order'), mapElement?.messageActions ?? []);
                mapElement.processes = sortBy(prop('order'), mapElement?.processes ?? []);
                mapElement.listeners = mapElement?.listeners ?? [];
                mapElement.navigationOverrides = mapElement?.navigationOverrides ?? [];
                mapElement.outcomes = sortBy(prop('order'), mapElement?.outcomes ?? []);

                setMapElementId(mapElement.id);

                itemOpened(
                    mapElement.id,
                    flowId,
                    COLLABORATION_ITEM_TYPES.mapElement,
                    mapElement,
                    !!id,
                );
            } catch (error) {
                notifyError(error);
            } finally {
                setIsLoading(false);
                setIsLoadingMapElement(false);
            }
        };

        load();
    }, []);

    const mapElement = getItem(mapElementId ?? '') as MapElement;

    if (mapElement) {
        mapElement.dataActions = sortBy(prop('order'), mapElement?.dataActions ?? []);
        mapElement.messageActions = sortBy(prop('order'), mapElement?.messageActions ?? []);
        mapElement.processes = sortBy(prop('order'), mapElement?.processes ?? []);
        mapElement.listeners = mapElement?.listeners ?? [];
        mapElement.navigationOverrides = mapElement?.navigationOverrides ?? [];
        mapElement.outcomes = sortBy(prop('order'), mapElement?.outcomes ?? []);
    }

    if (!currentUserId || isLoading || isLoadingMapElement) {
        return <></>;
    }

    const contextValue: MapElementContext = {
        id: id,
        title:
            configTitle && configTitle.length > 0 ? configTitle : (mapElement?.developerName ?? ''),
        elementType: mapElement?.elementType ?? elementType,
        onHide: onClose,
        container,
        currentUserId,
        tenantId,
        editingToken: FLOW_EDITING_TOKEN,
        flowId,
        mapElement,
        currentScreen,
        breadcrumbs,
        onReturnToDefaultScreen,
        onSaveMapElement,
        onClose,
        onUpdateName,
        onUpdateTitle,
        onUpdateComments,
        onUpdateStatusMessage,
        onUpdateNotAuthorizedMessage,
        onUpdateSubFlowArguments,
        onPushBreadCrumb,
        onSetActiveBreadCrumb,
        setMapElement,
        onSwitchScreen,
        setConfigTitle,
        mapElements: filterOutNotes(mapElements),
        onEditOutcome,
        onDeleteOutcome,
        selectedOutcomeIndex,
        setSelectedOutcomeIndex,
        defaultScreen,
        onEditNavigation,
        onDeleteNavigation,
        selectedNavigationIndex,
        setSelectedNavigationIndex,
        notifyError,
    };

    return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

const useMapElement = () => {
    const context = useContext(Context);
    if (context === undefined) {
        throw new Error('useMapElement must be used within a MapElementProvider');
    }
    return context;
};

export { MapElementProvider, useMapElement };
