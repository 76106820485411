import { useMapElement } from '../flow/elementConfigurations/contextProviders/MapElementProvider';

import type { ReactNode } from 'react';

import ConfigModal from './ConfigModal';
import { MAP_ELEMENT_DISPLAY_NAMES } from '../../constants';
import { isNullOrWhitespace } from '../../utils/guard';

interface Props {
    children: ReactNode;
}

const MapElementModal = ({ children }: Props) => {
    const { onHide, id, title, elementType, container, currentUserId } = useMapElement();

    const titleWithDefault = isNullOrWhitespace(title)
        ? `New ${MAP_ELEMENT_DISPLAY_NAMES[elementType] || ''}`
        : title;

    return (
        <ConfigModal
            id={id}
            title={titleWithDefault}
            elementType={elementType}
            container={container}
            currentUserId={currentUserId}
            onHide={onHide}
        >
            {children}
        </ConfigModal>
    );
};

export default MapElementModal;
