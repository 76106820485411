import GenericModal from '../../../../generic/modal/GenericModal';
import ButtonDefault from '../../../../buttons/ButtonDefault';
import type { ContentType, ValueElementIdReferenceAPI } from '../../../../../types';
import translations from '../../../../../translations';
import Loader from '../../../../loader/Loader';
import { useEffect, useState } from 'react';
import { getValueList } from '../../../../../sources/value';
import { CONTENT_TYPES_LIST, SYSTEM_TYPE_NAME_ID_MAPPINGS } from '../../../../../constants';
import { suggestSupportedContentTypes, suggestSupportedContentTypesString } from './constants';
import { partition } from 'ramda';
import Table from '../../../../generic/Table';
import { stringReplace } from '../../../../../utils/string';
import { usePageEditor } from '../../PageEditorProvider';
import {
    getValueName,
    getValuePropertyName,
    getValuePropertyType,
    getValueType,
} from '../../../../values/selector/value-selector-utils';

const ComponentSuggestObjectProperties = ({ isLoading }: { isLoading: boolean }) => {
    const { state, container, setStopComponentSuggest, selectComponentSuggestValue } =
        usePageEditor();
    const { componentSuggestValue } = state;
    const [propertyReferences, setPropertyReferences] = useState<
        ValueElementIdReferenceAPI[] | null
    >(null);
    const [invalidPropertyReferences, setInvalidPropertyReferences] = useState<
        ValueElementIdReferenceAPI[] | null
    >(null);

    const updatePropertyReferences = async (id: string | undefined | null) => {
        if (id) {
            const references = await getValueList({ id });
            const [validProperties, invalidProperties] = partition(
                // Partition out references that are valid and invalid
                // valid references must be a supportedContentType
                // or an Object and a Payment Card type id
                (reference) =>
                    (reference.contentType === 'ContentObject' &&
                        reference.typeElementPropertyTypeElementId ===
                            SYSTEM_TYPE_NAME_ID_MAPPINGS['$Payment Card']) ||
                    suggestSupportedContentTypes.includes(reference.contentType as ContentType),
                references,
            );
            setPropertyReferences(validProperties);
            setInvalidPropertyReferences(invalidProperties);
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: Treat warnings as errors, fix later
    useEffect(() => {
        updatePropertyReferences(componentSuggestValue?.id);
    }, [componentSuggestValue?.id]);

    return (
        <GenericModal
            container={container}
            show
            className="config-modal component-suggestion-modal"
            onHide={setStopComponentSuggest}
            animation={false}
            title={
                propertyReferences?.length === 0
                    ? translations.COMPONENT_SUGGEST_no_suggestions
                    : translations.COMPONENT_SUGGEST_object_properties_view_title
            }
            renderBody={() => {
                if (
                    isLoading ||
                    propertyReferences === null ||
                    invalidPropertyReferences === null
                ) {
                    return <Loader message={translations.COMPONENT_SUGGEST_loading_suggestions} />;
                }
                if (propertyReferences.length === 0) {
                    return (
                        <>
                            <div className="margin-bottom">
                                {stringReplace(
                                    translations.COMPONENT_SUGGEST_object_properties_none_valid,
                                    componentSuggestValue?.developerName,
                                )}
                            </div>
                            <Table<ValueElementIdReferenceAPI>
                                columns={[
                                    {
                                        renderHeader: () =>
                                            `${translations.COMMON_TABLE_value} / ${translations.COMMON_TABLE_property}`,
                                        renderCell: ({
                                            item,
                                        }: {
                                            item: ValueElementIdReferenceAPI;
                                        }) => {
                                            return (
                                                <div className="value-selector-values-option">
                                                    <span className="value-selector-values-option-name">
                                                        {getValueName(item)}
                                                    </span>
                                                    <span className="value-selector-values-option-meta">
                                                        {getValueType(item)}
                                                    </span>
                                                </div>
                                            );
                                        },
                                    },
                                    {
                                        renderHeader: () => translations.COMMON_TABLE_kind,
                                        renderCell: ({
                                            item,
                                        }: {
                                            item: ValueElementIdReferenceAPI;
                                        }) =>
                                            CONTENT_TYPES_LIST.find(
                                                (contentType) =>
                                                    item.contentType === contentType.key,
                                            )?.label,
                                    },
                                    {
                                        renderHeader: () => translations.COMMON_TABLE_type,
                                        renderCell: ({
                                            item,
                                        }: {
                                            item: ValueElementIdReferenceAPI;
                                        }) => item.typeElementDeveloperName,
                                    },
                                ]}
                                items={invalidPropertyReferences}
                            />
                            {translations.COMPONENT_SUGGEST_object_properties_valid_info_1}
                            <ul>
                                <li>{suggestSupportedContentTypesString}</li>
                                <li>
                                    {translations.COMPONENT_SUGGEST_object_properties_valid_info_2}
                                </li>
                            </ul>
                        </>
                    );
                }
                return (
                    <div className="full-width">
                        <div>
                            {stringReplace(
                                translations.COMPONENT_SUGGEST_object_properties_some_valid,
                                componentSuggestValue?.developerName,
                            )}
                        </div>
                        <hr />
                        {propertyReferences.map((property) => {
                            return (
                                <button
                                    key={`${property.id ?? ''}${
                                        property.typeElementPropertyId ?? ''
                                    }`}
                                    className="value-selector-values-option"
                                    onClick={() => selectComponentSuggestValue(property)}
                                    type="button"
                                >
                                    <span className="value-selector-values-option-name">
                                        {getValuePropertyName(property)}
                                    </span>
                                    <span className="value-selector-values-option-meta">
                                        {getValuePropertyType(property)}
                                    </span>
                                </button>
                            );
                        })}
                    </div>
                );
            }}
            renderFooter={() => (
                <ButtonDefault title="Cancel" onClick={setStopComponentSuggest}>
                    Cancel
                </ButtonDefault>
            )}
        />
    );
};

export default ComponentSuggestObjectProperties;
