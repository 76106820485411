import type { ChangeEventHandler } from 'react';
import { connect, type ConnectedProps } from 'react-redux';
import { changeOtlpSetting } from '../../../js/actions/reduxActions/currentTenant';
import translations from '../../translations';
import type { ObservabilitySettingsAPI } from '../../types/Tenant';
import FormGroup from '../generic/FormGroup';

const mapDispatchToProps = {
    changeOtlpSetting,
};

const connector = connect(null, mapDispatchToProps);

type OtlpSettingsProps = {
    settings: ObservabilitySettingsAPI;
} & ConnectedProps<typeof connector>;

const OtlpSettings = ({ settings, changeOtlpSetting }: OtlpSettingsProps) => {
    const onChangeOtlpEndpoint: ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => {
        changeOtlpSetting({
            settingPath: ['otlpEndpoint'],
            value,
        });
    };

    const onChangeOtlpHeaders: ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => {
        changeOtlpSetting({
            settingPath: ['otlpHeaders'],
            value,
        });
    };

    const onChangeOtlpSensitiveData: ChangeEventHandler<HTMLSelectElement> = ({
        target: { value },
    }) => {
        changeOtlpSetting({
            settingPath: ['otlpSensitiveDataLogging'],
            value: Number.parseInt(value),
        });
    };

    const onChangeOtlpProtocol: ChangeEventHandler<HTMLSelectElement> = ({ target: { value } }) => {
        changeOtlpSetting({
            settingPath: ['otlpProtocol'],
            value: Number.parseInt(value),
        });
    };

    return (
        <>
            <p>
                {translations.TENANT_observability_description_1}
                <br />
                {translations.TENANT_observability_description_2}
            </p>
            <FormGroup htmlFor="otlp-endpoint" label={translations.OTLP_endpoint_label}>
                <input
                    id="otlp-endpoint"
                    className="form-control"
                    type="text"
                    onChange={onChangeOtlpEndpoint}
                    value={settings?.otlpEndpoint || ''}
                />
                <p className="help-block">
                    {translations.OTLP_endpoint_help_1}
                    <br />
                </p>
            </FormGroup>
            <FormGroup htmlFor="otlp-protocol" label={translations.OTLP_protocol_label}>
                <select
                    id="otlp-protocol"
                    value={settings?.otlpProtocol ?? 0}
                    onChange={onChangeOtlpProtocol}
                    className="form-control"
                >
                    <option value="0">{translations.OTLP_protocol_grpc}</option>
                    <option value="1">{translations.OTLP_protocol_http}</option>
                </select>
            </FormGroup>
            <FormGroup htmlFor="otlp-headers" label={translations.OTLP_headers_label}>
                <input
                    id="otlp-headers"
                    className="form-control"
                    type="text"
                    onChange={onChangeOtlpHeaders}
                    value={settings?.otlpHeaders || ''}
                />
                <p className="help-block">
                    {translations.OTLP_headers_help_1}
                    <br />
                    {translations.OTLP_headers_help_2}
                </p>
            </FormGroup>
            <FormGroup htmlFor="otlp-sensitive-data" label={translations.OTLP_sensitive_data_label}>
                <select
                    id="otlp-sensitive-data"
                    value={settings?.otlpSensitiveDataLogging ?? 0}
                    onChange={onChangeOtlpSensitiveData}
                    className="form-control"
                >
                    <option value="0">{translations.OTLP_sensitive_none}</option>
                    <option value="1">{translations.OTLP_sensitive_on_error}</option>
                    <option value="2">{translations.OTLP_sensitive_all}</option>
                </select>
                <p className="help-block">
                    {translations.OTLP_sensitive_data_help_1}
                    <br />
                    {translations.OTLP_sensitive_data_help_2}
                </p>
            </FormGroup>
        </>
    );
};

export default connector(OtlpSettings);
