import {
    type HubConnection,
    HubConnectionBuilder,
    HttpTransportType,
    LogLevel,
} from '@microsoft/signalr';
import { ENGINE_API_URL } from '../constants';

export interface McrDeployResult {
    success: boolean;
    message: string;
    messageId: string;
}

let connection: HubConnection | null = null;

const connectToEngineSocket = (): Promise<McrDeployResult> => {
    const deployResult: Promise<McrDeployResult> = new Promise((resolve, reject) => {
        connection = new HubConnectionBuilder()
            .withUrl(`${ENGINE_API_URL ?? ''}/designtime`, {
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets,
            })
            .configureLogging(LogLevel.Information)
            .build();
        const onDeployResult = (result: McrDeployResult) => {
            if (result.success) {
                resolve(result);
            } else {
                reject(new Error(result.message));
            }
        };
        connection.on('DeployResult', onDeployResult);
        connection.start().catch(() => {
            throw new Error('Unable to start Cloud Socket Connection.');
        });

        setTimeout(
            () =>
                reject(
                    new Error(
                        'Please ensure your MCR node is up to date and online as the publish attempt was not acknowledged.',
                    ),
                ),
            60000,
        );
    });

    return deployResult;
};

const disconnectFromEngineSocket = async () => {
    if (connection !== null) {
        await connection.stop();
    }
};

export { connectToEngineSocket, disconnectFromEngineSocket };
