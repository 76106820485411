import { useEffect, useState } from 'react';
import translations from '../../../../translations';
import { SUBFLOW_TYPES } from './constants';
import { ACCESS_LEVELS } from '../../../../constants';
import { isNullOrEmpty, isNullOrWhitespace } from '../../../../utils/guard';
import FormGroup from '../../../generic/FormGroup';
import OutcomeList from '../common/outcomes/OutcomeList';
import { useMapElement } from '../contextProviders/MapElementProvider';
import FlowSelectBox from './FlowSelectBox';
import SubFlowArguments from './SubFlowArguments';
import ModalBody from '../../../generic/modal/ModalBody';
import ModalFooter from '../../../generic/modal/ModalFooter';
import ValueSelectorModal from '../../../values/selector/ValueSelectorModal';
import { getLatest } from '../../../../sources/flow';
import Footer from '../common/Footer';
import type {
    Subflow,
    FormalValueReference,
    ValueElementIdReferenceAPI,
    ValueElementIdAPI,
} from '../../../../types';
import type { MapElementRequestAPI } from '../../../../sources/graph';
import type { SingleValue } from 'react-select';

const SubFlowConfiguration = () => {
    const {
        flowId,
        mapElement,
        onSaveMapElement,
        onClose,
        setMapElement,
        onUpdateName,
        onUpdateComments,
        onUpdateSubFlowArguments,
        tenantId,
        container,
        notifyError,
    } = useMapElement();

    const subflowResponse = mapElement.subflow as Subflow | null;

    const selectedSubFlowId = subflowResponse?.flowId?.id ?? '';
    const selectedSubFlowArguments = subflowResponse?.arguments ?? '';
    const selectedSubFlowValue = subflowResponse?.value ?? null;
    const nameValid = isNullOrWhitespace(mapElement?.developerName) === false;
    const subFlowValid = isNullOrEmpty(subflowResponse?.flowId?.id) === false;
    const subflowValueValid = isNullOrEmpty(subflowResponse?.value) === false;
    const isFormValid = nameValid && (subFlowValid || subflowValueValid);

    const [inputArgs, setInputArgs] = useState<FormalValueReference[]>([]);
    const [outputArgs, setOutputArgs] = useState<FormalValueReference[]>([]);
    const [inputOutputArgs, setInputOutputArgs] = useState<FormalValueReference[]>([]);
    const [publicArgs, setPublicArgs] = useState<FormalValueReference[]>([]);
    const [subFlowArgumentsLoading, setSubFlowArgumentsLoading] = useState(true);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [subflowType, setSubflowType] = useState(
        selectedSubFlowValue ? SUBFLOW_TYPES.value : SUBFLOW_TYPES.predefined,
    );

    const [selectedSubFlowName, setSelectedSubFlowName] = useState<string | null>(null);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        getLatest(selectedSubFlowId)
            .then((flow) => setSelectedSubFlowName(flow.developerName))
            .catch(notifyError);
    }, [selectedSubFlowId, flowId, notifyError, tenantId]);

    const clearSubflowArgs = () => {
        setInputArgs([]);
        setOutputArgs([]);
        setInputOutputArgs([]);
        setPublicArgs([]);
    };

    const concatSubflowArgs = () => {
        return ([] as FormalValueReference[])
            .concat(...inputArgs)
            .concat(...outputArgs)
            .concat(...inputOutputArgs)
            .concat(...publicArgs);
    };

    const onSave = () => {
        setHasSubmitted(true);

        if (isFormValid) {
            onSaveMapElement(mapElement as MapElementRequestAPI);
        }
    };

    const onSubflowChange = (
        event: SingleValue<{ label: string | null; value: string | null }>,
    ) => {
        const subflowId = event?.value;
        const subflowLabel = event?.label;

        if (subflowLabel && subflowId && subflowResponse?.flowId?.id !== subflowId) {
            // We need to do some cleanup etc in the component state
            // before we update the subflow map element in the MapElementProvider store
            clearSubflowArgs();
            setMapElement({
                ...mapElement,
                subflow: {
                    ...(mapElement.subflow as Subflow),
                    arguments: [],
                    flowId: { id: subflowId, versionId: '' },
                    flowDeveloperName: subflowLabel,
                },
            });
        }
    };

    /** Sets the subflow arguments in state. There are 4 different states as this is done using 4 async calls.
     *  These are later concatenated later when it comes to rendering
     */
    const setSubflowArguments = (subFlowArgs: FormalValueReference[], type: string) => {
        switch (type) {
            case ACCESS_LEVELS['INPUT'].value: {
                setInputArgs(inputArgs.concat(subFlowArgs));
                setSubFlowArgumentsLoading(false);
                break;
            }
            case ACCESS_LEVELS['OUTPUT'].value: {
                setOutputArgs(outputArgs.concat(subFlowArgs));
                setSubFlowArgumentsLoading(false);
                break;
            }
            case ACCESS_LEVELS['INPUT_OUTPUT'].value: {
                setInputOutputArgs(inputOutputArgs.concat(subFlowArgs));
                setSubFlowArgumentsLoading(false);
                break;
            }
            case ACCESS_LEVELS['PUBLIC'].value: {
                setPublicArgs(publicArgs.concat(subFlowArgs));
                setSubFlowArgumentsLoading(false);
                break;
            }
        }
    };

    /** The actual arguments sent to the subflow will at this point have been automatically matched to the
     *  formal arguments defined by the selected subflow. So we just want to set the updated arguments in state.
     */
    const setMatchedSubFlowArguments = (
        subFlowArguments: FormalValueReference[],
        matches: number,
    ) => {
        if (matches === 0) {
            notifyError({ message: translations.SF_no_matching_values_text });
        }

        // Subflow arguments come back as one array so for the sake of keeping states in sync let's split these into
        // the four types as we have done before.
        const _inputArgs = [];
        const _outputArgs = [];
        const _inputOutputArgs = [];
        const _publicArgs = [];

        for (const arg of subFlowArguments) {
            if (arg.access === ACCESS_LEVELS['INPUT'].value) {
                _inputArgs.push(arg);
            } else if (arg.access === ACCESS_LEVELS['OUTPUT'].value) {
                _outputArgs.push(arg);
            } else if (arg.access === ACCESS_LEVELS['INPUT_OUTPUT'].value) {
                _inputOutputArgs.push(arg);
            } else if (arg.access === ACCESS_LEVELS['PUBLIC'].value) {
                _publicArgs.push(arg);
            }
        }

        // Set the subflow arguments on the MapElementProvider
        onUpdateSubFlowArguments(
            ([] as FormalValueReference[]).concat(
                ..._inputArgs,
                ..._outputArgs,
                ..._inputOutputArgs,
                ..._publicArgs,
            ),
        );

        // Set the subflow arguments on the state
        setInputArgs(_inputArgs);
        setOutputArgs(_outputArgs);
        setInputOutputArgs(_inputOutputArgs);
        setPublicArgs(_publicArgs);
        setSubFlowArgumentsLoading(false);
    };

    const togglePickerForArgArray = (
        argArray: FormalValueReference[],
        setStateFunction: (args: FormalValueReference[]) => void,
        isOpen: boolean,
        id: string,
    ) => {
        let changeMade = false;
        const updatedArgs = argArray.map((arg) => {
            if (arg.id === id) {
                changeMade = true;
                return {
                    ...arg,
                    isOpen,
                };
            }

            return arg;
        });

        setStateFunction(updatedArgs);

        return changeMade;
    };

    /**
     * @param {Boolean} isOpen Should the value picker modal be displayed
     * @param {String} id The ID of the subflow argument that we are representing in component state
     * @description Determine whether a value picker modal should be displayed for manually
     * selecting the value to pass into/ out of the subflow
     */
    const togglePicker = (isOpen: boolean, id: string) => {
        // Run the next function only if the previous didn't make a change.
        !(
            togglePickerForArgArray(inputArgs, setInputArgs, isOpen, id) ||
            togglePickerForArgArray(outputArgs, setOutputArgs, isOpen, id) ||
            togglePickerForArgArray(inputOutputArgs, setInputOutputArgs, isOpen, id)
        ) && togglePickerForArgArray(publicArgs, setPublicArgs, isOpen, id);
    };

    const selectArgumentForArgArray = (
        argArray: FormalValueReference[],
        type: string,
        selectedValueMeta: ValueElementIdReferenceAPI | null | ValueElementIdAPI,
        id: string,
    ) => {
        let changeMade = false;
        const updatedArgs = argArray.map((arg) => {
            if (arg.id === id) {
                changeMade = true;
                return {
                    ...arg,
                    isOpen: false,
                    selectedValueMeta,
                };
            }

            return arg;
        });

        if (changeMade) {
            switch (type) {
                case ACCESS_LEVELS['INPUT'].value: {
                    onUpdateSubFlowArguments(
                        updatedArgs.concat(...outputArgs, ...inputOutputArgs, ...publicArgs),
                    );
                    setInputArgs(updatedArgs);
                    break;
                }
                case ACCESS_LEVELS['OUTPUT'].value: {
                    onUpdateSubFlowArguments(
                        updatedArgs.concat(...inputArgs, ...inputOutputArgs, ...publicArgs),
                    );
                    setOutputArgs(updatedArgs);
                    break;
                }
                case ACCESS_LEVELS['INPUT_OUTPUT'].value: {
                    onUpdateSubFlowArguments(
                        updatedArgs.concat(...inputArgs, ...outputArgs, ...publicArgs),
                    );
                    setInputOutputArgs(updatedArgs);
                    break;
                }
                case ACCESS_LEVELS['PUBLIC'].value: {
                    onUpdateSubFlowArguments(
                        updatedArgs.concat(...inputArgs, ...outputArgs, ...inputOutputArgs),
                    );
                    setPublicArgs(updatedArgs);
                    break;
                }
            }
        }

        return changeMade;
    };

    /**
     * @param {Object} selectedValueMeta Metadata that represents the value that has been selected to pass
     * to the specified argument
     * @param {String} id The ID of the argument representation in component state
     * @description Sets the selected value for the specified subflow argument
     */
    const selectArgument = (
        selectedValueMeta: ValueElementIdReferenceAPI | null | ValueElementIdAPI,
        id: string,
    ) => {
        // Run the next function only if the previous didn't make a change.
        !(
            selectArgumentForArgArray(
                inputArgs,
                ACCESS_LEVELS['INPUT'].value,
                selectedValueMeta,
                id,
            ) ||
            selectArgumentForArgArray(
                outputArgs,
                ACCESS_LEVELS['OUTPUT'].value,
                selectedValueMeta,
                id,
            ) ||
            selectArgumentForArgArray(
                inputOutputArgs,
                ACCESS_LEVELS['INPUT_OUTPUT'].value,
                selectedValueMeta,
                id,
            )
        ) &&
            selectArgumentForArgArray(
                publicArgs,
                ACCESS_LEVELS['PUBLIC'].value,
                selectedValueMeta,
                id,
            );
    };

    const renderBody = () => (
        <>
            <FormGroup
                label={translations.SUBFLOW_name_input_label}
                htmlFor="subflow-name"
                isRequired
                isValid={nameValid}
                showValidation={hasSubmitted}
                validationMessage={translations.SUBFLOW_name_input_validation_message}
            >
                <input
                    id="subflow-name"
                    className="form-control form-control-width"
                    value={mapElement?.developerName ?? ''}
                    onChange={({ target: { value } }) => onUpdateName(value)}
                    type="text"
                    maxLength={255}
                    required
                    autoFocus
                />
            </FormGroup>
            <FormGroup
                isRequired
                validationMessage={translations.SUBFLOW_type_input_validation_message}
                isValid={!isNullOrEmpty(subflowType)}
                showValidation={hasSubmitted}
                label={translations.SUBFLOW_type_input_label}
                htmlFor="subflow-type"
            >
                <select
                    id="subflow-type"
                    value={subflowType}
                    onChange={({ target: { value } }) => {
                        setSubflowType(value);
                        setHasSubmitted(false);
                        setMapElement({
                            ...mapElement,
                            subflow: {
                                ...(mapElement.subflow as Subflow),
                                value: null,
                                flowId: null,
                            },
                        });
                    }}
                    required
                    className="form-control form-control-width"
                >
                    <option value={SUBFLOW_TYPES.predefined}>{SUBFLOW_TYPES.predefined}</option>
                    <option value={SUBFLOW_TYPES.value}>{SUBFLOW_TYPES.value}</option>
                </select>
            </FormGroup>
            {subflowType === SUBFLOW_TYPES.predefined && (
                <FlowSelectBox
                    tenantId={tenantId}
                    onChange={onSubflowChange}
                    selectedItem={selectedSubFlowName}
                    flowId={flowId}
                    isRequired
                    isValid={subFlowValid}
                    showValidation={hasSubmitted}
                    validationMessage={translations.SUBFLOW_flow_select_validation_message}
                    menuPosition="fixed"
                    menuPortalTarget={document.querySelector(`#flow-${flowId} .modal`)}
                    closeMenuOnScroll={(e) => {
                        return e.target === document.querySelector(`#flow-${flowId} .modal-body`);
                    }}
                    notifyError={notifyError}
                />
            )}
            {subflowType === SUBFLOW_TYPES.value && (
                <FormGroup
                    label={translations.SUBFLOW_value_select_label}
                    isRequired
                    isValid={subflowValueValid}
                    showValidation={hasSubmitted}
                    validationMessage={translations.MAP_ELEMENT_value_field_validation_message}
                    htmlFor="subflow-value"
                >
                    <ValueSelectorModal
                        value={subflowResponse?.value || null}
                        contentType="ContentString"
                        onChange={(value) => {
                            setMapElement({
                                ...mapElement,
                                subflow: {
                                    ...(mapElement.subflow as Subflow),
                                    value: value,
                                    flowId: null,
                                },
                            });
                        }}
                        container={container}
                        includeSystemValues={false}
                    />
                </FormGroup>
            )}
            {selectedSubFlowId && (
                <SubFlowArguments
                    tenantId={tenantId}
                    subFlowId={selectedSubFlowId}
                    subFlowArguments={selectedSubFlowArguments}
                    subFlowArgumentsToBeRendered={concatSubflowArgs()}
                    setSubFlowArguments={setSubflowArguments}
                    onSelect={selectArgument}
                    togglePicker={togglePicker}
                    isLoading={subFlowArgumentsLoading}
                    onMatch={setMatchedSubFlowArguments}
                />
            )}
            <OutcomeList />
            <FormGroup label={translations.SUBFLOW_comment_label} htmlFor="subflow-comments">
                <textarea
                    id="subflow-comments"
                    className="form-control form-textarea"
                    value={mapElement?.developerSummary ?? ''}
                    onChange={({ target: { value } }) => onUpdateComments(value)}
                />
            </FormGroup>
        </>
    );

    const renderFooter = () => (
        <Footer
            cancel={onClose}
            save={onSave}
            saveButtonText={translations.GRAPH_config_panel_save}
            cancelButtonText={translations.GRAPH_config_panel_cancel}
        />
    );

    return (
        <>
            <ModalBody>{renderBody()}</ModalBody>
            <ModalFooter>{renderFooter()}</ModalFooter>
        </>
    );
};

export default SubFlowConfiguration;
