import type { HttpMethod } from '../types';
import { fetchAndParse, getTenantId } from '../utils';

export const sendRequest = async (url: string, method: HttpMethod, requestBody: unknown) => {
    return await fetchAndParse({
        url: `${url.startsWith('/') ? '' : '/'}${url}`,
        method,
        headers: {
            ManyWhoTenant: getTenantId(),
            'Content-Type': 'application/json',
        },
        body: method === 'GET' ? null : requestBody,
    });
};

export const fetchApiEndpoints = async () => {
    type SwaggerResponse = { paths: Record<string, string> };

    let definition: SwaggerResponse;

    try {
        definition = await fetchAndParse<SwaggerResponse>({
            url: `${window.location.protocol}//${window.location.host}/swagger/v1/swagger.json`,
        });
    } catch {
        definition = await fetchAndParse<SwaggerResponse>({
            url: 'https://flow.manywho.com/swagger/v1/swagger.json',
        });
    }

    return definition.paths;
};
