import classnames from 'classnames';
import {
    useState,
    type MouseEvent,
    type KeyboardEventHandler,
    type KeyboardEvent,
    type ReactNode,
} from 'react';
import { MAP_ELEMENT_TYPES } from '../../../../constants';
import { useGraph } from '../../../../../js/components/graph/GraphProvider';
import {
    highlightMapElement,
    isArrowKeyPressed,
    isSelectedOrNothingSelected,
} from '../../../../../js/components/graph/utils';
import type { Dragging, ElementStyles, MapElement } from '../../../../types';

export interface MapElementRenderingProps {
    id: string;
    onOver: () => void;
    onOut: () => void;
    startDrag: (
        e:
            | KeyboardEvent<SVGGElement>
            | MouseEvent<SVGForeignObjectElement>
            | MouseEvent<SVGRectElement>,
        usingKeyboard?: boolean,
    ) => void;
    onSelect: (
        e: MouseEvent<SVGRectElement> | KeyboardEvent<SVGGElement>,
        selectedElementIds: string[],
    ) => void;
    isSelected: boolean;
    isDragging: boolean;
    dragging: Dragging;
    styles: ElementStyles;
    onDelete: (() => void) | undefined;
    ports: ReactNode;
    developerName: string;
    onDoubleClick: (
        event: KeyboardEvent<SVGGElement> | MouseEvent<SVGGElement>,
        usingKeyboard?: boolean,
    ) => void;
    isHovered: boolean;
    actionText: ReactNode;
    elementType: string;
    keyboardDragging: boolean;
    isColorFillMode: boolean;
    selectedElementIds: string[];
    x: number;
    y: number;
    component: React.ElementType;
    isOutOfSearch: boolean;
    onCancelDrag: () => void;
    mapElement: MapElement;
    movingNewElementWithKeyboard?: boolean;
    onToggleContextMenu: (toggle?: boolean) => void;
    isClone?: boolean | undefined;
}

/**
 * @description Accepts a component as a prop and uses component composition
 * in order to allow for different types of element to be rendered on the canvas.
 */
const MapElementBase = (props: MapElementRenderingProps) => {
    const {
        id,
        onOver,
        onOut,
        isSelected,
        isHovered,
        isDragging,
        x,
        y,
        component: Component,
        styles,
        isOutOfSearch,
        onDoubleClick,
        startDrag,
        onCancelDrag,
        onSelect,
        elementType,
        selectedElementIds,
        mapElement,
        movingNewElementWithKeyboard,
        onToggleContextMenu,
    } = props;

    const [keyboardInUse, setKeyboardInUse] = useState<boolean>(false);

    const className = classnames({
        'map-element': true,
        'note-element': elementType.toLowerCase() === MAP_ELEMENT_TYPES.note,
        hover: isHovered,
        drag: isDragging,
        select: isSelected,
        'out-of-search': isOutOfSearch,
        'no-focus-border': true,
        'keyboard-focused-map': keyboardInUse && !isSelected,
        // Selected focused border if an element is the only one selected or if it's being dragged regardless of other selected elements.
        'keyboard-focused-selected':
            keyboardInUse &&
            ((isSelected && (isSelectedOrNothingSelected(id, selectedElementIds) as boolean)) ||
                isDragging),
        'new-element-keyboard-drag': movingNewElementWithKeyboard, // New map elements being dragged on from the sidebar with the keyboard
    });

    const onMouseEnter = () => {
        setKeyboardInUse(false);

        if (onOver) {
            onOver();
        }
    };

    const keyUp = (event: KeyboardEvent) => {
        // Modifier keys shouldn't turn on keyboard controls as they are used when mouse clicking
        if (event.key !== 'Control' && event.key !== 'Shift') {
            setKeyboardInUse(true);
        }
    };
    const { setHighlightedElementIds } = useGraph();

    const keyDown: KeyboardEventHandler<SVGGElement> = (event) => {
        if (event.key === 'Escape') {
            onCancelDrag();
            onToggleContextMenu(true);
        } else if (!event.shiftKey && event.key === ' ') {
            onDoubleClick(event, true);
        } else if (event.key === 'ContextMenu') {
            onToggleContextMenu();
        }
        // If the element is focused/selected and nothing else is selected then the arrow keys should start moving the element.
        else if (
            event.key === 'Enter' ||
            (isArrowKeyPressed(event) && isSelectedOrNothingSelected(id, selectedElementIds))
        ) {
            onSelect(event, selectedElementIds);
            startDrag(event, true);
        } else if (event.key === 'l') {
            highlightMapElement(mapElement, setHighlightedElementIds);
        }
    };

    return (
        <g
            {...styles.mapElement}
            id={id}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onOut}
            className={className}
            transform={`matrix(1,0,0,1,${x},${y})`}
            tabIndex={-1}
            onKeyDown={keyDown}
            onKeyUp={keyUp}
            aria-label={`Element of type ${elementType} with the name: ${
                mapElement?.developerName ?? 'unnamed'
            }`}
        >
            <Component {...props} />
        </g>
    );
};

export default MapElementBase;
