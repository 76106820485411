import ActivateRuntime from '../flow/ActivateRuntime';
import { type Dispatch, type ReactNode, type SetStateAction, useEffect, useState } from 'react';
import GenericModal from '../generic/modal/GenericModal';
import { publishTheme } from '../../sources/theme';
import ErrorBoundary from '../generic/ErrorBoundary';
import type { TenantRuntime } from '../../types/Tenant';
import { connectToEngineSocket, disconnectFromEngineSocket } from '../../mcr/signalRConnection';
import { guid } from '../../utils/guid';
import Loader from '../loader/Loader';
import { getRuntimesForTenant } from '../../sources/runtime';

interface Props {
    tenantId: string;
    tenantDeveloperName: string;
    showPublishModal: boolean;
    setShowPublishModal: Dispatch<SetStateAction<boolean>>;
    container?: HTMLDivElement | undefined;
    themeName: string;
}

const PublishThemeModal = ({
    tenantId,
    tenantDeveloperName,
    showPublishModal,
    setShowPublishModal,
    container,
    themeName,
}: Props) => {
    const [error, setError] = useState<string | null>(null);
    const [runtime, setRuntime] = useState<TenantRuntime | undefined>(undefined);
    const [comment, setComment] = useState('');
    const [viewState, setViewState] = useState<string>('PUBLISH');
    const [runtimes, setRuntimes] = useState<TenantRuntime[]>([]);
    const closeModal = () => {
        setShowPublishModal(false);
        setViewState('PUBLISH');
    };
    const loadRuntimes = async () => {
        setRuntimes((await getRuntimesForTenant()) as TenantRuntime[]);
    };
    useEffect(() => {
        loadRuntimes();
    });
    const sendBuildThemeRequest = async () => {
        try {
            if (runtime?.id) {
                setViewState('LOADING');
                const messageId: string = guid();
                const publishPromise = connectToEngineSocket();
                await publishTheme({
                    runtimes: [
                        {
                            id: runtime.id,
                            isActive: true,
                            isDefault: true,
                            messageId,
                        },
                    ],
                    themeName,
                });

                try {
                    const deployResult = await publishPromise;

                    if (deployResult.messageId !== messageId) {
                        throw new Error('Returned Multi Cloud Runtime message ID does not match');
                    }
                    if (!deployResult.success) {
                        throw new Error(deployResult.message);
                    }
                    setViewState('SUCCESS');
                } catch (e) {
                    setError((e as Error).message);
                    setViewState('FAILED');
                } finally {
                    await disconnectFromEngineSocket();
                }
            } else {
            }
        } catch (e) {
            setError((e as Error).message);
            setViewState('FAILED');
        }
    };
    const onRuntimeChanged = (runtime: TenantRuntime | undefined) => setRuntime(runtime);
    let body: ReactNode;
    let footer: ReactNode;
    switch (viewState) {
        case 'LOADING': {
            body = <Loader />;
            footer = null;
            break;
        }
        case 'PUBLISH': {
            body = (
                <ErrorBoundary>
                    <ActivateRuntime
                        tenantId={tenantId}
                        tenantDeveloperName={tenantDeveloperName}
                        runtimes={runtimes}
                        onChange={onRuntimeChanged}
                        comment={comment}
                        onCommentChanged={(e) => setComment(e.currentTarget.value)}
                    />
                </ErrorBoundary>
            );
            footer = (
                <>
                    <button
                        type="button"
                        onClick={closeModal}
                        className="btn btn-default"
                        data-testid="close-modal-button"
                    >
                        Close
                    </button>
                    <button
                        type="button"
                        onClick={sendBuildThemeRequest}
                        className="btn btn-primary"
                    >
                        Publish Theme
                    </button>
                </>
            );
            break;
        }
        case 'FAILED': {
            body = <h3>{`Publishing to MCR failed with the error: ${error}.`}</h3>;
            footer = (
                <button
                    type="button"
                    onClick={closeModal}
                    className="btn btn-primary"
                    data-testid="close-modal-button"
                >
                    Close
                </button>
            );
            break;
        }
        case 'SUCCESS': {
            body = (
                <h3>
                    Theme: {themeName} has successfully been published to {runtime?.developerName}
                </h3>
            );
            footer = (
                <button
                    type="button"
                    onClick={closeModal}
                    className="btn btn-primary"
                    data-testid="close-modal-button"
                >
                    Close
                </button>
            );
            break;
        }
    }
    return (
        <GenericModal
            container={container}
            title="Publish Theme to MCR"
            onHide={closeModal}
            show={showPublishModal}
            renderBody={() => <>{body}</>}
            renderFooter={() => <>{footer}</>}
        />
    );
};

export default PublishThemeModal;
