import FormGroup from '../../../generic/FormGroup';
import type { DispatchFlowConfiguration } from './reducer';

interface Props {
    stateExpirationLength: number;
    idleStateExpirationLength: number;
    dispatchFlowConfiguration: DispatchFlowConfiguration;
}

const State = ({
    stateExpirationLength,
    idleStateExpirationLength,
    dispatchFlowConfiguration,
}: Props) => {
    const safeNumber = (maybeNumber: number | undefined) => {
        if (typeof maybeNumber === 'number') {
            return maybeNumber;
        }
        return 0;
    };

    return (
        <>
            <h2>State</h2>
            <FormGroup
                htmlFor="properties-state"
                label="Number of days before the in-flight States should be deleted"
            >
                <input
                    id="properties-state"
                    className="form-control-dynamic form-control"
                    type="number"
                    min={0}
                    max={180}
                    placeholder="Never"
                    value={stateExpirationLength === 0 ? undefined : stateExpirationLength}
                    onChange={({ target }) => {
                        dispatchFlowConfiguration({
                            type: 'updateStateExpirationLength',
                            payload: {
                                length: safeNumber(target.valueAsNumber),
                            },
                        });
                    }}
                />
            </FormGroup>
            <FormGroup
                htmlFor="properties-inactive-state"
                label="Number of days of inactivity before the in-flight States should be deleted"
            >
                <input
                    id="properties-inactive-state"
                    className="form-control-dynamic form-control"
                    type="number"
                    min={0}
                    max={180}
                    placeholder="Never"
                    value={idleStateExpirationLength === 0 ? undefined : idleStateExpirationLength}
                    onChange={({ target }) => {
                        dispatchFlowConfiguration({
                            type: 'updateIdleStateExpirationLength',
                            payload: {
                                length: safeNumber(target.valueAsNumber),
                            },
                        });
                    }}
                />
            </FormGroup>
        </>
    );
};

export default State;
