import { MAP_ELEMENT_TYPES, GROUP_ELEMENTS } from '../../../ts/constants';
import { getElementStyles, SNAP_DISTANCE } from './elements/elementStyles';
import { getByID } from '../../../ts/utils/collection';
import { isNullOrEmpty } from '../../../ts/utils/guard';
import { safeToLower } from '../../../ts/utils/string';
import { guid } from '../../../ts/utils/guid';
import { clone, groupBy, partition, prop, sortBy, uniqBy } from 'ramda';
import cytoscape from 'cytoscape';
import translations from '../../../ts/translations';
import { addPositions } from '../../../ts/components/graph/utils';

export const pickByPrefix = (prefix, obj) =>
    Object.entries(obj).reduce((picked, [key, val]) => {
        if (key.startsWith(prefix)) {
            picked.push(val);
        }
        return picked;
    }, []);

export const getParentOffset = (parents) =>
    parents.reduce(
        (offset, parent) => ({
            x: offset.x + parent.x,
            y: offset.y + parent.y,
        }),
        { x: 0, y: 0 },
    );

export const getParents = (element, groupElements, parents = []) => {
    if (element?.groupElementId) {
        const parent = groupElements.find((grp) => grp.id === element.groupElementId);
        if (!parent) {
            //stop the missing parent error cascading and corrects the data error if saved
            element.groupElementId = null;
            throw new Error(
                `Could not find parent with ID ${element.groupElementId} of ${element.elementType} "${element.developerName}" with ID ${element.id}`,
            );
        }
        parents.push(parent);
        return getParents(parent, groupElements, parents);
    }

    return parents;
};

export const getChildren = (groupId, mapElements, groupElements) => {
    const maps = mapElements.filter((mapElement) => mapElement.groupElementId === groupId);
    const groups = groupElements.filter((mapElement) => mapElement.groupElementId === groupId);
    return { mapElements: maps, groupElements: groups };
};

export const generateIDs = (configProps) => ({
    ...configProps,
    id: configProps.id || guid(),
});

/** Round the given `value` to the `nearest` multiple of a number (default 30) */
export const snap = (value, nearest = SNAP_DISTANCE) => Math.round(value / nearest) * nearest;

export const getElementDimensions = (element) => {
    // In this case the 'element' is just an x,y co-ord (used when dragging outcome to the mouse position)
    if (isNullOrEmpty(element?.elementType)) {
        // So we just return 0,0, as everything should just go directly to the mouse
        return {
            width: 0,
            height: 0,
        };
    }
    const styles = getElementStyles(element.elementType);
    return {
        // Map elements have the .mapElement property
        // Group elements are dynamic, and we use the width/height set on the element
        width: styles.mapElement?.width || element.width,
        height: styles.mapElement?.height || element.height,
    };
};

export const getElementsDimensions = (elementIds, mapElements, groupElements) => {
    let lowestX;
    let highestX;
    let lowestY;
    let highestY;

    for (const elementId of elementIds) {
        const element =
            mapElements.find((el) => el.id === elementId) ||
            groupElements.find((el) => el.id === elementId);
        if (!element) {
            continue;
        }
        //if we allow this to error the graph won't load
        let parents = [];
        try {
            parents = getParents(element, groupElements);
        } catch (error) {
            console.log(error);
        }

        const parentOffset = getParentOffset(parents);
        const { width, height } = getElementDimensions(element);

        const leftX = element.x + parentOffset.x;
        const rightX = leftX + width;
        const upperY = element.y + parentOffset.y;
        const bottomY = upperY + height;

        if (lowestX === undefined || leftX < lowestX) {
            lowestX = leftX;
        }
        if (highestX === undefined || rightX > highestX) {
            highestX = rightX;
        }
        if (lowestY === undefined || upperY < lowestY) {
            lowestY = upperY;
        }
        if (highestY === undefined || bottomY > highestY) {
            highestY = bottomY;
        }
    }

    return { lowestX, highestX, lowestY, highestY };
};

export const getMarqueeDimensions = (
    initialMousePosition,
    previousMousePosition,
    calculateSVGPositionFromXY,
) => {
    const initialMousePosInSVG = calculateSVGPositionFromXY(initialMousePosition);
    const previousMousePosInSVG = calculateSVGPositionFromXY(previousMousePosition);

    const horizontalSize = initialMousePosInSVG.x - previousMousePosInSVG.x;
    const verticalSize = initialMousePosInSVG.y - previousMousePosInSVG.y;

    return {
        x: horizontalSize > 0 ? initialMousePosInSVG.x - horizontalSize : initialMousePosInSVG.x,
        y: verticalSize > 0 ? initialMousePosInSVG.y - verticalSize : initialMousePosInSVG.y,
        width: Math.abs(horizontalSize),
        height: Math.abs(verticalSize),
    };
};

export const calculateElementBounds = (element, groupElements) => {
    const elementParentOffset = getParentOffset(getParents(element, groupElements));

    // Turn snapped new element position into bounds
    const snappedNewElementGlobalBounds = {
        ...addPositions(elementParentOffset, element),
        ...getElementDimensions(element),
    };

    return snappedNewElementGlobalBounds;
};

export const snapPosition = (position, nearest) => ({
    x: snap(position.x, nearest),
    y: snap(position.y, nearest),
});

export const calculateIfBoundIsWithinAnotherBounds = ({
    innerBound: { x: boundsX, y: boundsY, width: boundsWidth, height: boundsHeight },
    outerBound,
}) => {
    // If all corners of the inside bound are within the outside bound,
    // ┌───────┐
    // │ ●──●  │
    // │ │  │  │
    // │ ●──●  │
    // └───────┘
    // then the inner object is completely within the outer bound
    if (
        // Top left corner check
        calculateIfPointIsWithinBounds({
            point: { x: boundsX, y: boundsY },
            bounds: outerBound,
        }) &&
        // Top right corner check
        calculateIfPointIsWithinBounds({
            point: { x: boundsX + boundsWidth, y: boundsY },
            bounds: outerBound,
        }) &&
        // Bottom left corner check
        calculateIfPointIsWithinBounds({
            point: { x: boundsX, y: boundsY + boundsHeight },

            bounds: outerBound,
        }) &&
        // Bottom right corner check
        calculateIfPointIsWithinBounds({
            point: { x: boundsX + boundsWidth, y: boundsY + boundsHeight },
            bounds: outerBound,
        })
    ) {
        return true;
    }
    return false;
};

export const calculateIfPointIsWithinBounds = ({
    point: { x: pointX, y: pointY },
    bounds: { x: boundsX, y: boundsY, width: boundsWidth, height: boundsHeight },
}) => {
    // If the point's x coordinate lies with the bound's horizontal area
    // │ ●     │
    // and the point's y coordinate lies with the bound's vertical area,
    //  ───────
    //   ●
    //  ───────
    // then the point is within the bound
    // ┌───────┐
    // │ ●     │
    // └───────┘
    if (
        // Check if the point is inside the bounds horizontally
        pointX >= boundsX &&
        pointX <= boundsX + boundsWidth &&
        // Check if the point is inside the bounds vertically
        pointY >= boundsY &&
        pointY <= boundsY + boundsHeight
    ) {
        return true;
    }
    return false;
};

// Dragging is currently handled in Graph.js,
// and if a onMouseUp event ever stops propagation before it's caught in OnDragEnd,
// then the dragging won't end correctly.
// This function makes sure we only stop propagation when it doesn't interfere with dragging.
export const executeIfNotDragging = (event, dragging, functionToExecute = null) => {
    // If the user is dragging, do nothing to allow the drag to end
    if (!dragging) {
        // If the user is not dragging, stop propagation of this event so deselection doesn't occur
        // except right click which stops contextmenu
        if (event.button !== 2) {
            event.stopPropagation();
        }
        if (functionToExecute) {
            functionToExecute();
        }
    }
};

export const filterOutNotes = (mapElements) =>
    mapElements.filter((m) => m.elementType !== MAP_ELEMENT_TYPES.note);

// Recursively jump up the family tree by using the Id of parent element (groupElementId)
// Terminate if the element/parent has no parents or the parent is selected
export const hasSelectedAncestors = (childElement, groupElements, selectedElementIds) => {
    // Check this first as most of the time there won't be a parent
    if (!childElement.groupElementId) {
        return false;
    }

    // The parent has already been selected
    if (selectedElementIds.includes(childElement.groupElementId)) {
        return true;
    }

    const parentElement = groupElements.find(
        (element) => element.id === childElement.groupElementId,
    );

    // Parent isn't selected but its parent might be, run function again
    if (parentElement.groupElementId) {
        return hasSelectedAncestors(parentElement, groupElements, selectedElementIds);
    }

    return false;
};

// For map elements, determines whether to use the colour borders style or the classic colour fill mode from the old canvas, or a hybrid of the two.
// For group elements, currently only determines the text and icon colour so it matches the map elements.
export const isColorFillMode = (mapElementColorStyle, zoomLevel) => {
    // How far zoomed out the graph has to be for the colour fill to change when in 'Color Fill When Zoomed Out' mode.
    const isZoomOutFillSwitchThresholdMet = zoomLevel >= 2;
    return (
        mapElementColorStyle === 'Color Fill' ||
        (mapElementColorStyle === 'Color Fill When Zoomed Out' && isZoomOutFillSwitchThresholdMet)
    );
};

// Function for overriding line thickness and fill style on the base style, based on some parameters
export const getVariableStyles = (baseStyles, canvasSettings, zoomLevel) => {
    const { lineThickness, mapElementColorStyle } = canvasSettings;
    // Borders are actually made using an inner shape over the top of a background colour so set to 0 for full colour fill mode.
    if (isColorFillMode(mapElementColorStyle, zoomLevel)) {
        baseStyles.fill = {
            ...baseStyles.fill,
            x: 0,
            y: 0,
            width: 0,
            height: 0,
        };
    } else {
        baseStyles.fill = {
            ...baseStyles.fill,
            x: lineThickness,
            y: lineThickness,
            width: baseStyles.mapElement.width - lineThickness * 2,
            height: baseStyles.mapElement.height - lineThickness * 2,
        };
    }

    return baseStyles;
};

export const moveControlPoints = (mapElement, diffXY) => ({
    ...mapElement,
    outcomes: mapElement.outcomes?.map((outcome) => ({
        ...outcome,
        controlPoints: outcome?.controlPoints?.map((point) => addPositions(point, diffXY)),
    })),
});

// Useful for dynamically turning on the arrow key keyboard movement without needing to hit enter first
// providing that nothing is selected or the current element is the only one selected.
export const isSelectedOrNothingSelected = (id, selectedElementIds) => {
    return (
        selectedElementIds.length === 0 ||
        (selectedElementIds.length === 1 && selectedElementIds.includes(id))
    );
};

export const isArrowKeyPressed = (event) => {
    return (
        event.key === 'ArrowUp' ||
        event.key === 'ArrowRight' ||
        event.key === 'ArrowDown' ||
        event.key === 'ArrowLeft'
    );
};

export const returnMapElementAndOutcomes = (mapElement, selectNextElementOnOutcomes = false) => {
    const { id, outcomes } = mapElement;
    // The map element is highlighted
    const ids = [id];
    // for each outcome...
    for (const { id: outcomeId, nextMapElementId } of outcomes || []) {
        // ...those outcomes are highlighted...
        ids.push(outcomeId);
        if (selectNextElementOnOutcomes) {
            // ...and the map elements they go to are highlighted if needed
            ids.push(nextMapElementId);
        }
    }

    return ids;
};

export const highlightMapElement = (mapElement, setHighlightedElementIds) => {
    const ids = returnMapElementAndOutcomes(mapElement, true);
    setHighlightedElementIds(ids);
};

export const findHighlightDescendants = (groupId, mapElements, groupElements) => {
    // The group element is highlighted
    const ids = [groupId];
    for (const element of mapElements) {
        if (element.groupElementId === groupId) {
            // highlight each map element under this group element
            ids.push(...returnMapElementAndOutcomes(element));
        }
    }
    for (const element of groupElements) {
        if (element.groupElementId === groupId) {
            // highlight each group element under this group element
            // and also repeat the check for any maps and groups under that group element
            ids.push(...findHighlightDescendants(element.id, mapElements, groupElements));
        }
    }

    return ids;
};

export const focusElement = (id) => {
    const elementToFocus = document.getElementById(id);

    if (!isNullOrEmpty(elementToFocus)) {
        elementToFocus.focus();
    }
};

export const zoomToFit = (
    mapElements,
    groupElements,
    graphElement,
    zoomViewBox,
    filteredMapElementIds = null,
    filteredGroupElementIds = null,
) => {
    const mapElementIds = filteredMapElementIds || mapElements.map((map) => map.id);
    const groupElementIds = filteredGroupElementIds || groupElements.map((group) => group.id);
    const allElementIds = [...mapElementIds, ...groupElementIds];

    let { lowestX, lowestY, highestX, highestY } = getElementsDimensions(
        allElementIds,
        mapElements,
        groupElements,
    );

    let width = highestX - lowestX;
    let height = highestY - lowestY;

    const minSize = 300;

    // if the view is smaller than minSize by minSize box
    // move the view to the center of a minSize by minSize box
    if (width < minSize) {
        lowestX -= (minSize - width) / 2;
        width = minSize;
    }
    if (height < minSize) {
        lowestY -= (minSize - height) / 2;
        height = minSize;
    }

    // pad height or width to match available aspect ratio
    // so that elements appear centered
    const { height: availableHeight, width: availableWidth } = graphElement.getBoundingClientRect();
    const availableRatio = availableWidth / availableHeight;
    // If the graph isn't visible, e.g., due to tab change, width and height will be zero and the result Nan, resulting in errors later
    if (Number.isNaN(availableRatio)) {
        return;
    }

    if (width / height > availableRatio) {
        // height needs padding to match available ratio
        const newHeight = width / availableRatio;
        lowestY -= (newHeight - height) / 2;
        height = newHeight;
    } else {
        // width needs padding to match available ratio
        const newWidth = height * availableRatio;
        lowestX -= (newWidth - width) / 2;
        width = newWidth;
    }

    const neededXZoom = width / availableWidth;
    const neededYZoom = height / availableHeight;

    const zoomAmount = neededXZoom > neededYZoom ? neededXZoom : neededYZoom;

    const newWidth = availableWidth * zoomAmount;
    const newHeight = availableHeight * zoomAmount;

    zoomViewBox(
        {
            x: lowestX,
            y: lowestY,
            width: newWidth,
            height: newHeight,
        },
        zoomAmount,
    );
};

export const transformPositionToScreen = (graphElement, { x, y }) => {
    const point = graphElement.createSVGPoint();
    point.x = x;
    point.y = y;
    return point.matrixTransform(graphElement.getScreenCTM());
};

export const fitQuery = (
    inputRef,
    mapElements,
    groupElements,
    canvasNotes,
    graphElement,
    zoomViewBox,
    elementId,
) => {
    const query = inputRef ? safeToLower(inputRef.current.value) : '';

    const doesNoteIncludeQuery = (map, query) => {
        const noteContent = getByID(map.id, canvasNotes)?.meta?.userContent;
        return noteContent ? safeToLower(noteContent).includes(query) : false;
    };

    const filteredMapElementIds = mapElements
        .filter((map) => {
            if (elementId) {
                return map.id === elementId;
            }

            return (
                safeToLower(map.developerName).includes(query) || doesNoteIncludeQuery(map, query)
            );
        })
        .map((map) => map.id);

    const filteredGroupElementIds = groupElements
        .filter((group) => {
            if (elementId) {
                return group.id === elementId;
            }
            return safeToLower(group.developerName).includes(query);
        })
        .map((group) => group.id);

    const setStartMapElement = (filteredOutcomes, map) => {
        if (filteredOutcomes.length > 0) {
            filteredMapElementIds.push(map.id);
        }
    };

    const setEndMapElement = (filteredOutcomes) => {
        for (const out of filteredOutcomes) {
            filteredMapElementIds.push(out.nextMapElementId);
        }
    };

    if (!elementId) {
        // add start and end map elements for matching outcomes
        for (const map of mapElements) {
            if (map.outcomes) {
                const filteredOutcomes = map.outcomes.filter((out) =>
                    safeToLower(out.developerName).includes(query),
                );
                setStartMapElement(filteredOutcomes, map);
                setEndMapElement(filteredOutcomes);
            }
        }
    }

    if (isNullOrEmpty(filteredMapElementIds) && isNullOrEmpty(filteredGroupElementIds)) {
        return;
    }

    zoomToFit(
        mapElements,
        groupElements,
        graphElement,
        zoomViewBox,
        filteredMapElementIds,
        filteredGroupElementIds,
    );
};

export const fitGroupAroundMapElements = (childElements, group, width, height) => {
    const padding = 30;
    const groupHeaderHeight = getElementStyles(group.elementType).header.height;

    // Our children are currently at 0,0 in our top left,
    // that doesn't look great so give them a bit of padding and avoid our group header space
    for (const element of childElements) {
        element.x += padding;
        element.y += padding + groupHeaderHeight;
    }

    const newWidth = width + padding * 2;
    const newHeight = height + padding * 2 + groupHeaderHeight;

    const groupGotBigger = newWidth > group.width || newHeight > group.height;

    // Group needs to be resized and saved too
    group.width = newWidth;
    group.height = newHeight;

    return groupGotBigger;
};

/**
 * Returns the distance from point to element in the specified axis
 * returning 0 if the point is within the element in the specified axis
 * e.g. for axis='y'              e.g. for axis='x'
 * 2 ˄                          2  1  0  0  0  0  0  0  1  2
 * 1 ˄                          <──<──┼──────────────┼──>──>
 * 0 ┼  ┌──────────────┐
 * 0 │  ├──────────────┤              ┌──────────────┐
 * 0 │  │              │              ├──────────────┤
 * 0 ┼  └──────────────┘              │              │
 * 1 ˅                                └──────────────┘
 * 2 ˅
 */
const getAxisDistance = (point, axis, element) => {
    if (point[axis] < element[axis]) {
        // return distance from top of element
        return element[axis] - point[axis];
    }
    const dimension = axis === 'x' ? element.width : element.height;
    if (point[axis] > element[axis] + dimension) {
        // return distance from bottom of element
        return point[axis] - (element[axis] + dimension);
    }
    return 0;
};

// returns the element in the `candidates` array that is closest to the given `point` using x,y,width,height properties
const findClosestElementToPoint = (point, candidates) => {
    if (candidates.length === 1) {
        return candidates[0];
    }
    return candidates.reduce((currentClosest, candidate) => {
        const distX = getAxisDistance(point, 'x', candidate);
        const distY = getAxisDistance(point, 'y', candidate);

        // find hypotenuse using Pythagoras's theorem
        const distance = Math.sqrt(distX ** 2 + distY ** 2);
        if (currentClosest.distance === undefined || distance < currentClosest.distance) {
            return { distance, candidate };
        }
        return currentClosest;
    }, {}).candidate;
};

export const arrangeGraph = (mapElements, maintainElementPositions = false) => {
    if (mapElements.length === 0) {
        return {
            newMapElements: [],
            width: 0,
            height: 0,
        };
    }

    const elements = [];

    // We may have been given notes, which we do not want to arrange (they are not connected to other map elements)
    const [otherElements, noteElements] = partition(
        (m) => m.elementType !== MAP_ELEMENT_TYPES.note,
        mapElements,
    );

    for (const mapElement of otherElements) {
        elements.push({
            data: {
                id: mapElement.id,
                ...getElementDimensions(mapElement),
            },
        });
        for (const outcome of mapElement.outcomes || []) {
            const nextMapElement = getByID(outcome.nextMapElementId, otherElements);
            if (nextMapElement) {
                // Only add the outcome if it points to a map element we have been given
                // (We may not have been given every map element)
                elements.push({
                    data: {
                        // Our outcome ids are not unique, and are not referenced by id
                        // So we can just randomize the id here
                        id: Math.random().toString(),
                        source: mapElement.id.toLowerCase(),
                        target: outcome.nextMapElementId.toLowerCase(),
                    },
                });
            }
        }
    }

    // Initialize cytoscape graph
    const graph = cytoscape({
        container: document.getElementById('cytoscape-container'),
        elements,
    });

    // Add widths and heights
    for (const node of graph.nodes()) {
        // We add on some width as the algorithm seems to ignore horizontal spacingFactor
        // and place elements on large graphs touching together
        node.css('width', node.data().width + 60);
        node.css('height', node.data().height + 60);
        node.css('shape', 'round-rectangle');
    }

    for (const edge of graph.edges()) {
        edge.css('curve-style', 'taxi');
        edge.css('target-arrow-shape', 'triangle');
    }

    const roots = new Set(
        mapElements
            .filter(({ elementType }) => elementType.toLowerCase() === 'start')
            .map(({ id }) => id),
    );

    // For each disconnectedGraph in the graph
    for (const disconnectedGraph of graph.elements().components()) {
        const cytoRoots = disconnectedGraph.nodes().roots();
        if (cytoRoots.length > 0) {
            for (const root of cytoRoots) {
                roots.add(root.data().id);
            }
            continue;
        }
        // we must select a root, so just pick the first
        roots.add(disconnectedGraph.nodes()[0].data().id);
    }

    // Layout the graph
    const layout = graph.layout({
        name: 'breadthfirst',
        spacingFactor: 1,
        roots: Array.from(roots),
        // breadthfirst places roots at the top and flows down,
        // so we rotate x and y to make the flow go left to right
        transform: (_node, position) => ({
            x: position.y,
            y: position.x,
        }),
    });
    const layoutFinished = layout.promiseOn('layoutstop');
    layout.run();

    // Position cleanup
    return layoutFinished.then(() => {
        // Set the map element positions to the cytoscape positions
        const newMapElements = [];

        // Instantiate newMapElements including cytoscape positions
        for (const node of graph.nodes()) {
            const { id } = node.data();
            const mapElement = getByID(id, mapElements);

            const position = node.position();

            if (mapElement) {
                const newMapElement = clone(mapElement);

                // Set cytoscape positions
                newMapElement.x = position.x;
                newMapElement.y = position.y;

                newMapElements.push(newMapElement);
            }
        }

        // Columns of map elements share the same x property
        const newMapElementColumns = groupBy(prop('x'), newMapElements);
        let x = 0;
        const columnHeights = [];
        // Horizontally squish together whole columns of elements based on the widest element in the previous column
        for (const columnOfMapElements of Object.values(newMapElementColumns)) {
            let y = 0;
            const getWidth = (el) => getElementDimensions(el).width;
            const getHeight = (el) => getElementDimensions(el).height;
            const widestInColumn = Math.max(...columnOfMapElements.map((el) => getWidth(el)));
            // Within this column only:
            // x: Horizontally centre elements to widest of all in column
            //     ⁞■  ⁞ -> ⁞ ■ ⁞
            //     ⁞▀▀▀⁞    ⁞▀▀▀⁞
            // y: Vertically squish together elements
            for (const mapElementInColumn of sortBy(prop('y'), columnOfMapElements)) {
                mapElementInColumn.x = x + widestInColumn / 2 - getWidth(mapElementInColumn) / 2;
                mapElementInColumn.y = y;

                y += getHeight(mapElementInColumn) + SNAP_DISTANCE;
            }

            y -= SNAP_DISTANCE;
            columnHeights.push(y);
            x += widestInColumn + SNAP_DISTANCE;
        }

        const tallestColumn = Math.max(...columnHeights);
        // Vertically centre columns of elements to tallest of all columns
        // ▀█▀ -> ■█■
        Object.values(newMapElementColumns).forEach((columnOfMapElements, i) => {
            const columnOffset = tallestColumn / 2 - columnHeights[i] / 2;
            for (const mapElementInColumn of columnOfMapElements) {
                mapElementInColumn.y += columnOffset;
            }
        });

        const noteAssignedElements = {};

        // Increment the assigned notes to this element
        // Return the new number of notes on this element
        const assignToElement = (elementId) => {
            if (noteAssignedElements[elementId]) {
                const newValue = noteAssignedElements[elementId] + 1;
                noteAssignedElements[elementId] = newValue;
                return newValue;
            }

            noteAssignedElements[elementId] = 1;
            return 1;
        };

        const { width: noteWidth, height: noteHeight } = getElementStyles(
            MAP_ELEMENT_TYPES.note,
        ).mapElement;

        // Now position note elements to be next to their previous nearest element
        for (const note of noteElements) {
            let x = 0;
            let y = 0;
            if (note.nearestElementId) {
                const nearestMapElement = getByID(note.nearestElementId, newMapElements);
                // Position notes left to right along the top of the map element
                x = nearestMapElement.x + noteWidth * (assignToElement(note.nearestElementId) - 1);
                y = nearestMapElement.y - noteHeight;
            } else {
                x = noteWidth * (assignToElement('none') - 1);
            }
            newMapElements.push({
                ...note,
                x,
                y,
            });
        }

        const { lowestX, lowestY, highestX, highestY } = getElementsDimensions(
            newMapElements.map(({ id }) => id),
            // We remove groupElementIds as our mock graph has no groups
            newMapElements.map((element) => ({ ...element, groupElementId: null })),
        );

        // Centre the graph because cytoscape places them far from 0,0
        for (const element of newMapElements) {
            element.x -= lowestX;
            element.y -= lowestY;
        }

        // If this is the root, return the elements to where we found them
        if (maintainElementPositions) {
            const { lowestX: previousLowestX, lowestY: previousLowestY } = getElementsDimensions(
                mapElements.map(({ id }) => id),
                // We remove groupElementIds as our mock graph has no groups
                mapElements.map((element) => ({ ...element, groupElementId: null })),
            );
            for (const element of newMapElements) {
                element.x += previousLowestX;
                element.y += previousLowestY;
            }
        }

        return {
            newMapElements,
            width: highestX - lowestX,
            height: highestY - lowestY,
        };
    });
};

export const isMapElement = (element) => !isGroupElement(element);

export const isGroupElement = (element) => GROUP_ELEMENTS.includes(element.elementType);

export const autoArrange = async ({
    setIsLoading,
    setIsPreviewingAutoArrange,

    mapElements,
    setSomeMapElements,

    groupElements,
    setSomeGroupElements,

    graphElement,
    zoomViewBox,

    notifyError,

    topLevelGroupId = null,
    selectedElementIds = null,
}) => {
    if (
        mapElements.length + groupElements.length === 0 ||
        (selectedElementIds && selectedElementIds.length === 0)
    ) {
        notifyError(translations.ARRANGE_no_elements_error);
        return;
    }

    // If the arrangement is just one map element,
    // we assume this is a mistake and instead rearrange the whole flow,
    // by discarding the selectedElementIds filter
    if (selectedElementIds?.length === 1 && getByID(selectedElementIds[0], mapElements)) {
        selectedElementIds = null;
    }

    // Verify selected element ids are valid to be arranged
    if (selectedElementIds) {
        topLevelGroupId = 'unset';

        // Selected elements are valid if they are all "connected"
        // e.g. simply, they should all be in the same parent, but also,
        // you can select elements in deeper groups, but you need to select the group too

        // For every selected element
        for (const id of selectedElementIds) {
            const element = getByID(id, mapElements) ?? getByID(id, groupElements);
            // Append on an empty parent as that is a valid parent to check for and set as our topLevelGroupId.
            const parents = [...getParents(element, groupElements), { id: null }];
            let foundMaxParent = false;
            // For every parent of that element
            for (const parent of parents) {
                if (foundMaxParent === false) {
                    // Make sure that their parent is in the selection
                    const isSelected = selectedElementIds.includes(parent.id);
                    // If the parent can't be found,
                    if (isSelected === false) {
                        // If this hasn't happened before,
                        if (topLevelGroupId === 'unset') {
                            // that's fine, log this 'not found' parent as the highest parent within the selected elements
                            topLevelGroupId = parent.id;
                        }
                        // Make sure this 'not found' parent matches what we found before
                        if (topLevelGroupId !== parent.id) {
                            // If it doesn't, fail
                            // because it means a group is missing in the selection and the arrangement doesn't make sense
                            notifyError(translations.ARRANGE_illegal_selections_error);
                            return;
                        }
                        // If it does, move on
                        foundMaxParent = true;
                    }
                }
            }
        }
    }

    setIsLoading(true);
    setIsPreviewingAutoArrange(true);

    try {
        let elementsToSave = [];

        const mapElementsWithNotesNearestNeighbours = clone(mapElements);

        // We only want group elements at the very top layer, not any within lower groups
        const topLevelGroupElementsToArrange = groupElements.filter(
            (gE) => gE.groupElementId === topLevelGroupId,
        );
        // For selected elements, we don't just want all group elements in that top group,
        // only the ones they selected
        const selectedTopLevelGroupElementsToArrange = selectedElementIds
            ? topLevelGroupElementsToArrange.filter(({ id }) => selectedElementIds.includes(id))
            : topLevelGroupElementsToArrange;

        const calculateNoteNeighboursInElements = (elements) => {
            const [otherElements, noteElements] = partition(
                (m) => m.elementType !== MAP_ELEMENT_TYPES.note,
                elements.map((element) => ({
                    ...element,
                    ...getElementDimensions(element),
                })),
            );

            // Find the element closest to each note
            for (const note of noteElements) {
                getByID(note.id, mapElementsWithNotesNearestNeighbours).nearestElementId =
                    otherElements.length > 0
                        ? findClosestElementToPoint(note, otherElements).id
                        : null;
            }
        };

        const calculateNoteNeighboursInGroups = (groups) => {
            for (const groupElement of groups) {
                // We need to layout all of our groups first
                const { groupElements: childGroups, mapElements: childMaps } = getChildren(
                    groupElement.id,
                    mapElements,
                    groupElements,
                );
                calculateNoteNeighboursInGroups(childGroups);

                calculateNoteNeighboursInElements([...childMaps, ...childGroups]);
            }
        };

        // All notes in selected top level groups will be arrange, so we need to calculate their neighbours before
        calculateNoteNeighboursInGroups(selectedTopLevelGroupElementsToArrange);

        const topLevelMapElementsToBeArranged = mapElementsWithNotesNearestNeighbours.filter(
            (mE) => mE.groupElementId === topLevelGroupId,
        );

        const selectedTopLevelMapElementsToBeArranged = selectedElementIds
            ? topLevelMapElementsToBeArranged.filter(({ id }) => selectedElementIds.includes(id))
            : topLevelMapElementsToBeArranged;

        // If there's a selection, only selected notes in the top level will be arranged, so we'll calculate their neighbours
        calculateNoteNeighboursInElements([
            ...selectedTopLevelMapElementsToBeArranged,
            ...selectedTopLevelGroupElementsToArrange,
        ]);

        const mockMapElementsForCytoscape = mapElementsWithNotesNearestNeighbours;

        const positionGroups = async (groups) => {
            for (const groupElement of groups) {
                // We need to layout all of our groups first
                const { groupElements: childGroups } = getChildren(
                    groupElement.id,
                    [],
                    groupElements,
                );
                await positionGroups(childGroups);

                // Our groups just added themselves as a map element child of us,
                // so now we can grab our children map elements
                const { mapElements: childMaps } = getChildren(
                    groupElement.id,
                    mockMapElementsForCytoscape,
                    [],
                );

                // Arrange this group and get its size
                const { newMapElements, width, height } = await arrangeGraph(childMaps);

                // Find any outcomes going from our children and add them to this group
                let outcomes = [];
                for (const element of childMaps) {
                    outcomes = outcomes.concat(element.outcomes ?? []);
                }

                // Find any outcomes going to our children and redirect them to this group
                for (const element of mockMapElementsForCytoscape) {
                    for (const outcome of element.outcomes) {
                        if (childMaps.find((child) => child.id === outcome.nextMapElementId)) {
                            outcome.nextMapElementId = groupElement.id;
                        }
                    }
                }

                fitGroupAroundMapElements(newMapElements, groupElement, width, height);

                // Add these children to be saved at the end
                elementsToSave = elementsToSave.concat(newMapElements ?? []);

                // Push our group as a mock map element of the correct size needed,
                // if this group has a parent then we have just given that group a new child map element
                mockMapElementsForCytoscape.push({
                    ...groupElement,
                    outcomes,
                });
            }
        };

        // Start positioning all groups,
        // starting from the top level, and taking out any groups within lower groups
        await positionGroups(selectedTopLevelGroupElementsToArrange);

        // After this we now have mock map elements of the correct size
        // instead of any groups in this flow

        // We only want map elements at the very top layer, not any within lower groups
        // This is recalculated and selectedTopLevelMapElementsToBeArranged is not used as new fake group elements ay have been added in positionGroups
        const topLevelElementsToArrange = mockMapElementsForCytoscape.filter(
            (mE) => mE.groupElementId === topLevelGroupId,
        );

        // Whenever a user arranges the whole graph (no topLevelGroupId) or arranges a subset of the graph (selectedElementIds)
        // we keep the elements where they were, instead of placing them at 0,0
        const maintainCurrentPosition = selectedElementIds !== null;

        // So we can arrange the flow as if it's just lots of map elements of different sizes
        const { newMapElements, width, height } = await arrangeGraph(
            // For selected elements, we don't just want all elements in that top group,
            // only the ones they selected
            selectedElementIds
                ? topLevelElementsToArrange.filter(({ id }) => selectedElementIds.includes(id))
                : topLevelElementsToArrange,
            maintainCurrentPosition,
        );

        // These new map elements need to be saved too
        elementsToSave = elementsToSave.concat(newMapElements);

        // Fix broken outcomes that now go to groups instead of going to map elements
        for (const element of elementsToSave) {
            element.x = snap(element.x);
            element.y = snap(element.y);

            const originalMapElement = getByID(element.id, mapElements);
            if (originalMapElement) {
                // This is a map element
                // restore any outcomes a group may have taken
                // Also, clear the outcome control points as they are no longer relevant
                element.outcomes = originalMapElement.outcomes?.map((outcome) => {
                    const outcomeClone = clone(outcome);
                    outcomeClone.controlPoints = [];
                    return outcomeClone;
                });
            } else {
                // This is a group element
                // remove any outcomes a group may have taken
                element.outcomes = undefined;
            }
        }

        // Split the elements into groups and maps
        let [arrangedGroupElements, arrangedMapElements] = partition(
            (element) => getByID(element.id, groupElements),
            elementsToSave,
        );

        let allNewMapElements = uniqBy(prop('id'), [...arrangedMapElements, ...mapElements]);
        let allNewGroupElements = uniqBy(prop('id'), [...arrangedGroupElements, ...groupElements]);

        // If we were given/calculated a topLevelGroupId,
        // it means our arrangement was scoped within a group
        // Then we should resize the group to fit the newly positioned map elements
        if (topLevelGroupId) {
            const filterGroup = getByID(topLevelGroupId, groupElements);

            let didGroupGetBigger = false;

            if (selectedElementIds) {
                // For selection arrangement,
                // we may not have arranged all elements within the highest group
                // so we need to find those now
                const elementsInsideArrangedGroup = [...mapElements, ...groupElements]
                    .filter((e) => e.groupElementId === topLevelGroupId)
                    .map(({ id }) => id);
                // Then find the dimensions of all these contents
                const { highestX, highestY } = getElementsDimensions(
                    elementsInsideArrangedGroup,
                    allNewMapElements,
                    allNewGroupElements,
                );
                // These positions are global, so we need to find the offset of the group, to know where the group starts globally
                const filterGroupOffset = addPositions(
                    filterGroup,
                    getParentOffset(getParents(filterGroup, allNewGroupElements)),
                );
                // Add a padding, and take off the group's start point to get the width and height we want the group to be
                const newWidth = highestX + 30 - filterGroupOffset.x;
                const newHeight = highestY + 30 - filterGroupOffset.y;
                if (newWidth > filterGroup.width || newHeight > filterGroup.height) {
                    didGroupGetBigger = true;
                }
                // Only resize if our new numbers are bigger, as this group wasn't selected for arrangement,
                // we only resize if we have to (e.g. the arranged elements would overflow out of the group)
                filterGroup.width = Math.max(filterGroup.width, newWidth);
                filterGroup.height = Math.max(filterGroup.height, newHeight);
            } else {
                // For group arrangement,
                // the arrangeGraph already gave us the width and height,
                // and we can use the same function we use when we arrange sub groups
                didGroupGetBigger = fitGroupAroundMapElements(
                    elementsToSave.filter((mE) => mE.groupElementId === topLevelGroupId),
                    filterGroup,
                    width,
                    height,
                );
                // The arrangement was just an arrangement of this group
                // The group didn't get bigger, so the following code won't add it to the arranged element and won't save it
                // But we should save it, as we arranged all of its contents, so its new size is the best size
                if (didGroupGetBigger === false) {
                    arrangedGroupElements.push(filterGroup);
                }
            }

            // If the group didn't get bigger, then skip the next code section as we just need to save the arranged elements
            if (didGroupGetBigger) {
                arrangedGroupElements.push(filterGroup);
                // At this point, we have just made a group larger, but it may now extend beyond its parent
                // So we need to check that
                let justResizedGroup = filterGroup;

                const parents = getParents(filterGroup, groupElements);
                // For every parent of that element
                for (const parent of parents) {
                    // Only do this if the last group child got bigger
                    if (didGroupGetBigger) {
                        didGroupGetBigger = false;

                        // It's a bit simpler to know the new size here, as we know just one group got bigger
                        // the new size is just the offset of that group, the height/width, and some nice SNAP_DISTANCE-sized padding
                        const maxWidth =
                            SNAP_DISTANCE + justResizedGroup.x + justResizedGroup.width;
                        const maxHeight =
                            SNAP_DISTANCE + justResizedGroup.y + justResizedGroup.height;

                        // If this parent got bigger, keep a note of this
                        // as it means we'll also check if this parent got bigger than its own parent
                        if (maxWidth > parent.width || maxHeight > parent.height) {
                            arrangedGroupElements.push(parent);
                            didGroupGetBigger = true;
                        }
                        parent.width = Math.max(parent.width, maxWidth);
                        parent.height = Math.max(parent.height, maxHeight);

                        justResizedGroup = parent;
                    }
                }
            }
        } else if (
            selectedElementIds === null ||
            selectedElementIds.length === mapElements.length + groupElements.length
        ) {
            // If there is no top level group id (this else case)
            // And there were no selected elements
            // Or the selected elements were ALL the elements
            const topLevelFilter = (element) => element.groupElementId === null;
            const { lowestX, lowestY } = getElementsDimensions(
                elementsToSave.filter(topLevelFilter).map(({ id }) => id),
                arrangedMapElements,
                // We remove groupElementIds as our mock graph has no groups
                arrangedGroupElements,
            );
            // Then the entire graph just got arranged
            // For this instance, we want to centre the flow at 60, 240, as this is where the initial viewport views
            const adjustment = snapPosition({
                x: -lowestX + 60,
                y: -lowestY + 240 - height / 2,
            });
            arrangedMapElements = arrangedMapElements.map((mapElement) =>
                topLevelFilter(mapElement) ? addPositions(mapElement, adjustment) : mapElement,
            );
            arrangedGroupElements = arrangedGroupElements.map((groupElement) =>
                topLevelFilter(groupElement)
                    ? addPositions(groupElement, adjustment)
                    : groupElement,
            );
            // Recalculate all elements as we just changed arranged elements
            allNewMapElements = uniqBy(prop('id'), [...arrangedMapElements, ...mapElements]);
            allNewGroupElements = uniqBy(prop('id'), [...arrangedGroupElements, ...groupElements]);
        }

        zoomToFit(
            allNewMapElements,
            allNewGroupElements,
            graphElement,
            zoomViewBox,
            arrangedMapElements.map(({ id }) => id),
            arrangedGroupElements.map(({ id }) => id),
        );

        setSomeMapElements(arrangedMapElements);
        setSomeGroupElements(arrangedGroupElements);
    } catch (e) {
        notifyError(e.message);
        setIsPreviewingAutoArrange(false);
    }

    setIsLoading(false);
};

export const createNewOutcomeAndNewMapElement = async (
    fromMapElementId,
    _mapElements,
    developerName,
    id,
    saveMapElement,
    getMapElement,
    notifyError,
) => {
    // find map element with id above
    const fromMapElement = await getMapElement(fromMapElementId);
    if (!fromMapElement) {
        return;
    }
    const outcomeGoingToNewMapElement = {
        id: guid(),
        developerName: `Go to ${developerName}`,
        label: `Go to ${developerName}`,
        nextMapElementId: id,
        pageActionBindingType: 'SAVE',
        order: fromMapElement.outcomes?.length ?? 0,
    };
    fromMapElement.outcomes = fromMapElement.outcomes
        ? [...fromMapElement.outcomes, outcomeGoingToNewMapElement]
        : [outcomeGoingToNewMapElement];
    // save it
    try {
        await saveMapElement(fromMapElement);
    } catch (error) {
        notifyError(error);
    }
};
