import { type ReactElement, useEffect, useState } from 'react';
import Loader from '../../loader/Loader';
import translations from '../../../translations';
import { useEnvironments } from './EnvironmentsProvider';
import ReleasesListFilters from './ReleasesListFilters';
import Release from './Release';
import { isNullOrEmpty } from '../../../utils/guard';
import { ENVIRONMENT_RELEASE_FILTER_DATETIME_OPTIONS } from './constants';
import { TAB_TYPES } from '../../../constants';
import { connect } from 'react-redux';
import { addDays, addYears } from '../../../utils/date';
import type { Tab } from '../../../types';
import type { Environment } from '../../../types/environment';

type Props = {
    tabs: Tab[];
    environmentId: string;
    passedEnvironments: Environment[];
};

const ReleasesList = ({ tabs, environmentId, passedEnvironments }: Props) => {
    const { getReleases, releasesLoading, releases, environmentsLoading, environments } =
        useEnvironments();
    const [dateTimeOption, setDateTimeOption] = useState('');
    const [fromDateFilter, setFromDateFilter] = useState<Date | null>(null);
    const [environmentFilter, setEnvironmentFilter] = useState(environmentId);
    const [searchTermFilter, setSearchTermFilter] = useState('');

    const reload = async (
        dateTimeFilter: Date | null,
        environmentIdFilter: string,
        termFilter: string,
    ) => {
        const filter = {
            environmentId: environmentIdFilter !== '' ? environmentIdFilter : null,
            fromDateTimeOffset: dateTimeFilter ?? null,
            searchTerm: termFilter,
        };

        await getReleases(filter);
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        reload(fromDateFilter, environmentFilter, searchTermFilter);
    }, [environments]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (!isNullOrEmpty(tabs) && fromDateFilter) {
            const environmentsTab = tabs.find(
                (t) => t.type === TAB_TYPES.environments && t.isActive,
            );
            const environmentTab = tabs.find((t) => t.type === TAB_TYPES.environment && t.isActive);
            if (!(isNullOrEmpty(environmentsTab) && isNullOrEmpty(environmentTab))) {
                reload(fromDateFilter, environmentFilter, searchTermFilter);
            }
        }
    }, [tabs]);

    useEffect(() => {
        onDateTimeFilterChange(ENVIRONMENT_RELEASE_FILTER_DATETIME_OPTIONS.lastThirtyDays);
    }, []);

    const onDateTimeFilterChange = (value: string) => {
        setDateTimeOption(value);
        let fromDate: Date | null = null;
        switch (value) {
            case ENVIRONMENT_RELEASE_FILTER_DATETIME_OPTIONS.all: {
                fromDate = null;
                break;
            }
            case ENVIRONMENT_RELEASE_FILTER_DATETIME_OPTIONS.lastYear: {
                fromDate = addYears(new Date(), -1);
                break;
            }
            case ENVIRONMENT_RELEASE_FILTER_DATETIME_OPTIONS.lastThirtyDays: {
                fromDate = addDays(new Date(), -30);
                break;
            }
            case ENVIRONMENT_RELEASE_FILTER_DATETIME_OPTIONS.lastSevenDays: {
                fromDate = addDays(new Date(), -7);
                break;
            }
            case ENVIRONMENT_RELEASE_FILTER_DATETIME_OPTIONS.lastTwentyFourHours: {
                fromDate = addDays(new Date(), -1);
                break;
            }
        }
        setFromDateFilter(fromDate);
        reload(fromDate, environmentFilter, searchTermFilter);
    };

    const onEnvironmentFilterChange = (value: string) => {
        setEnvironmentFilter(value);
        reload(fromDateFilter, value, searchTermFilter);
    };

    const onSearchTermFilterChange = (value: string) => {
        setSearchTermFilter(value);
        reload(fromDateFilter, environmentFilter, value);
    };

    let content: ReactElement | ReactElement[] | null = null;
    if (releasesLoading) {
        content = <Loader message="Loading releases" />;
    } else if (releases.length === 0) {
        content = <h5>{translations.ENVIRONMENT_no_releases}</h5>;
    } else if (!environmentsLoading) {
        content = releases.map((release) => (
            <Release
                release={release}
                key={release.id}
                reload={() => {
                    reload(fromDateFilter, environmentFilter, searchTermFilter);
                }}
                environmentId={environmentFilter}
                showRollback={release.canRollback}
                passedEnvironments={passedEnvironments ?? environments}
            />
        ));
    }

    return (
        <>
            <ReleasesListFilters
                environments={
                    environmentId
                        ? null
                        : passedEnvironments?.length > 0
                          ? passedEnvironments
                          : environments
                }
                onDateTimeFilterChange={onDateTimeFilterChange}
                dateTimeOption={dateTimeOption}
                onEnvironmentFilterChange={onEnvironmentFilterChange}
                environmentFilter={environmentFilter}
                onSearchTermFilterChange={onSearchTermFilterChange}
                searchTermFilter={searchTermFilter}
            />
            <div
                className={
                    environmentId ? 'expandable-area-window-large' : 'expandable-area-window'
                }
            >
                {content}
            </div>
        </>
    );
};

const mapStateToProps = ({ tabs }: { tabs: Tab[] }) => ({
    tabs,
});

export default connect(mapStateToProps)(ReleasesList);
