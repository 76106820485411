import Glyphicon from '../../../../generic/Glyphicon';
import { useMapElement } from '../../contextProviders/MapElementProvider';
import ButtonPrimary from '../../../../buttons/ButtonPrimary';
import { useWait } from '../../contextProviders/WaitProvider';
import WaitDescription from '../../../../graph/elements/map/wait/WaitDescription';
import type { Wait } from '../../../../../types/graph';
import translations from '../../../../../translations';
import { Trash } from '@phosphor-icons/react';
import Table from '../../../../generic/Table';

const WaitList = () => {
    const { mapElement } = useMapElement();
    const { onCreateWait, onEditWait, onDeleteWait } = useWait();

    const columns = [
        {
            renderHeader: () => translations.COMMON_TABLE_name,
            renderCell: ({ item, rowIndex }: { item: Wait; rowIndex: number }) => (
                <button
                    className="link-emulate"
                    title={`Edit ${item.developerName ?? 'Wait'}`}
                    onClick={() => onEditWait(item, rowIndex)}
                    type="button"
                >
                    {item.developerName ?? ''}
                </button>
            ),
        },
        {
            renderHeader: () => translations.COMMON_TABLE_wait,
            renderCell: ({ item }: { item: Wait }) => {
                const props = { wait: item };
                return <WaitDescription {...props} />;
            },
        },
        {
            renderHeader: () => translations.COMMON_TABLE_actions,
            renderCell: ({ rowIndex, item }: { rowIndex: number; item: Wait }) => (
                <div className="action-btn-wrapper">
                    <button
                        title={`Delete ${item.developerName ?? 'Wait'}`}
                        className="table-icon table-icon-delete"
                        aria-label="Delete"
                        onClick={() => onDeleteWait(rowIndex)}
                        type="button"
                    >
                        <Trash />
                    </button>
                </div>
            ),
            size: '5rem',
        },
    ];

    return (
        <>
            <h4>Waits</h4>
            <div className="margin-bottom flex">
                <ButtonPrimary onClick={onCreateWait}>
                    <Glyphicon glyph="plus" />
                    Add Wait
                </ButtonPrimary>
            </div>
            <Table<Wait>
                wrapperClassName="margin-bottom-large"
                columns={columns}
                items={mapElement?.waits ?? []}
                testId="wait-list"
            />
        </>
    );
};

export default WaitList;
