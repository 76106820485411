import {
    AlertBannerType,
    ExAlertBanner,
    ExEmptyState,
    ExPagination,
    ExStructuredList,
    ExStructuredListBody,
    ExStructuredListCol,
    ExStructuredListRow,
} from '@boomi/exosphere';
import { format } from 'date-fns';
import { addMonths, endOfDay, startOfDay } from 'date-fns/fp';
import flow from 'lodash.flow';
import queryString from 'query-string';
import { useEffect, useState, type ReactNode } from 'react';
import '../../../../css/audit-logs.less';
import { downloadAuditLogsCsv, getAuditLogs } from '../../sources/logs';
import { getAllUsersForCurrentTenant } from '../../sources/user';
import translations from '../../translations';
import type {
    AuditEvent,
    ItemCollectionResponse,
    TimePeriod,
    TimePeriodOption,
    TypeOption,
    UserAPI,
    UserOption,
} from '../../types';
import { tryGetErrorMessage } from '../../utils';
import { stringReplace } from '../../utils/string';
import CopyableText from '../generic/CopyableText';
import AuditLogsFilters from './AuditLogsFilters';
import { AUDIT_TIME_PERIODS, DATE_FNS_FORMAT_NO_ZONE, PUBLIC_USER } from './constants';
import { getTypeOptions, humanizeAuditUser } from './utils';

type AuditLogProps = {
    tenantId: string;
};

const AuditLogs = ({ tenantId }: AuditLogProps) => {
    const [logs, setLogs] = useState<ItemCollectionResponse<AuditEvent>>();
    const [users, setUsers] = useState<UserAPI[]>([]);
    const [pageSize, setPageSize] = useState(15);
    const [usersFilter, setUsersFilter] = useState<readonly UserOption[]>([]);
    const [typesFilter, setTypesFilter] = useState<readonly TypeOption[]>([]);
    const [periodFilter, setPeriodFilter] = useState<TimePeriodOption>(AUDIT_TIME_PERIODS.day);
    const [dateFilter, setDateFilter] = useState(new Date());
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const loadInitialLogs = async () => {
            try {
                const tenantUsers = await getAllUsersForCurrentTenant();
                tenantUsers.push(PUBLIC_USER);
                setUsers(tenantUsers);
                await loadLogs(
                    1,
                    pageSize,
                    periodFilter.value,
                    dateFilter,
                    typesFilter,
                    usersFilter,
                );
            } catch (error) {
                setErrorMessage(tryGetErrorMessage(error));
            }
        };

        loadInitialLogs();
    }, [dateFilter, pageSize, periodFilter, typesFilter, usersFilter]);

    const getLogRecords = async (detail: { selectedPage: number; pageSize: number }) => {
        if (detail.selectedPage && detail.pageSize) {
            setPageSize(detail.pageSize);
            await loadLogs(
                detail.selectedPage,
                detail.pageSize,
                periodFilter.value,
                dateFilter,
                typesFilter,
                usersFilter,
            );
        }
    };

    const updatePeriodFilter = async (period: TimePeriodOption) => {
        setPeriodFilter(period);
        await loadLogs(1, pageSize, period.value, dateFilter, typesFilter, usersFilter);
    };

    const updateDateFilter = async (date: Date) => {
        setDateFilter(date);
        await loadLogs(1, pageSize, periodFilter.value, date, typesFilter, usersFilter);
    };

    const updateTypeFilter = async (types: readonly TypeOption[]) => {
        setTypesFilter(types);
        await loadLogs(1, pageSize, periodFilter.value, dateFilter, types, usersFilter);
    };

    const updateUserFilter = async (users: readonly UserOption[]) => {
        setUsersFilter(users);
        await loadLogs(1, pageSize, periodFilter.value, dateFilter, typesFilter, users);
    };

    const refreshLogs = async () => {
        await loadLogs(1, pageSize, periodFilter.value, dateFilter, typesFilter, usersFilter);
    };

    const loadLogs = async (
        pageNumber: number,
        pageSize: number,
        period: TimePeriod,
        date: Date,
        types: readonly TypeOption[],
        users: readonly UserOption[],
    ) => {
        try {
            const from = calculateFromDate(date, period);
            const to = calculateToDate(date, period);

            const parameters = {
                from: from ? format(from, DATE_FNS_FORMAT_NO_ZONE) : null,
                to: format(to, DATE_FNS_FORMAT_NO_ZONE),
                type: types.map((type) => type.value),
                user: users.map((user) => user.value),
                page: pageNumber,
                pageSize,
                orderBy: 'occurredAt',
                orderDirection: 'DESC',
            };

            setLogs(await getAuditLogs(queryString.stringify(parameters)));
            setErrorMessage('');
        } catch (error) {
            setErrorMessage(tryGetErrorMessage(error));
        }
    };

    const downloadCsv = async () => {
        try {
            const from = calculateFromDate(dateFilter, periodFilter.value);
            const to = calculateToDate(dateFilter, periodFilter.value);

            const parameters = {
                from: from ? format(from, DATE_FNS_FORMAT_NO_ZONE) : null,
                to: format(to, DATE_FNS_FORMAT_NO_ZONE),
                type: typesFilter.map((type) => type.value),
                user: usersFilter.map((user) => user.value),
                page: 1,
                pageSize,
                orderBy: 'occurredAt',
                orderDirection: 'DESC',
            };

            return await downloadAuditLogsCsv(queryString.stringify(parameters));
        } catch (error) {
            setErrorMessage(tryGetErrorMessage(error));
        }
        return undefined;
    };

    const loadLogTemplate = (log: AuditEvent) => {
        let logMessage: string = translations.AUDIT_event_default;

        const event = getTypeOptions().find(({ value }) => value === log.type);
        if (event?.logTemplate) {
            logMessage = event.logTemplate;
        }

        return stringReplace(logMessage, {
            datetime: format(new Date(log.occurredAt!), 'LLL do yyyy, k:mm:ss'),
            user: humanizeAuditUser(log.user),
            eventType: log.type,
            flowName: log.flow ? log.flow.developerName : null,
            newUserFirstName: log?.data?.firstName ?? log?.data?.data?.Member?.firstName,
            newUserLastName: log?.data?.lastName ?? log?.data?.data?.Member?.lastName,
            newUserEmail: log?.data?.email ?? log?.data?.data?.Member?.email,
            oldRoleName: log?.data?.data?.Role?.from ?? log?.data?.role?.from,
            newRoleName: log?.data?.data?.Role?.to ?? log?.data?.role?.to,
            releaseName: log.data?.releaseName,
            environmentName: log.data?.environmentName,
            variableName: log.data?.environmentVariableName,
            themeName: log.data?.themeName,
        });
    };

    const calculateFromDate = (filterDate: string | number | Date, period: TimePeriod) => {
        switch (period) {
            case AUDIT_TIME_PERIODS.day.value:
                return flow(startOfDay())(new Date(filterDate));
            case AUDIT_TIME_PERIODS.month.value:
                return flow(addMonths(-1), startOfDay())(new Date());
            case AUDIT_TIME_PERIODS.threeMonths.value:
                return flow(addMonths(-3), startOfDay())(new Date());
            case AUDIT_TIME_PERIODS.sixMonths.value:
                return flow(addMonths(-6), startOfDay())(new Date());
            case AUDIT_TIME_PERIODS.twelveMonths.value:
                return flow(addMonths(-12), startOfDay())(new Date());
        }
    };

    const calculateToDate = (filterDate: Date, period: TimePeriod) => {
        if (AUDIT_TIME_PERIODS.day.value === period) {
            return flow(endOfDay())(new Date(filterDate));
        }
        return flow(endOfDay())(new Date());
    };

    let listContent: ReactNode;
    if (errorMessage) {
        listContent = (
            <ExAlertBanner open={!!errorMessage} type={AlertBannerType.ERROR}>
                {errorMessage}
            </ExAlertBanner>
        );
    } else if (logs && logs.items.length > 0) {
        listContent = (
            <ExStructuredList className="ex-mt-medium overflow-y-scroll">
                <ExStructuredListBody>
                    {logs.items.map((log) => {
                        const comments = (log.data?.['comments'] as string) ?? '';
                        return (
                            <ExStructuredListRow key={log.id}>
                                <ExStructuredListCol className="action-column">
                                    <div>
                                        {loadLogTemplate(log)}
                                        <CopyableText
                                            copyableText={
                                                loadLogTemplate(log) +
                                                (comments ? ` - ${comments}` : '')
                                            }
                                            hasCopyButton={true}
                                            showCopyText={false}
                                        />
                                    </div>
                                    {comments ? <div className="ex-fs-micro">{comments}</div> : ''}
                                </ExStructuredListCol>
                            </ExStructuredListRow>
                        );
                    })}
                </ExStructuredListBody>
                <ExPagination
                    totalItems={logs._meta.total}
                    pageSize={logs._meta.pageSize}
                    selectedPage={logs._meta.page}
                    onChange={(e: CustomEvent) => getLogRecords(e.detail)}
                    pageSizeOptions={[15, 25, 50, 100]}
                />
            </ExStructuredList>
        );
    } else {
        listContent = <ExEmptyState text="" />;
    }

    return (
        <div className="admin-page">
            <div className="editor-container macro-editor">
                <h1>{translations.AUDIT_title}</h1>
                <AuditLogsFilters
                    tenantId={tenantId}
                    users={users}
                    filterDate={dateFilter}
                    filterPeriod={periodFilter}
                    filterTypes={typesFilter}
                    filterUsers={usersFilter}
                    fetchLogs={refreshLogs}
                    updateTimePeriodFilter={updatePeriodFilter}
                    updateDateFilter={updateDateFilter}
                    updateLogTypeFilter={updateTypeFilter}
                    updateUserFilter={updateUserFilter}
                    downloadCsv={downloadCsv}
                />
                {listContent}
            </div>
        </div>
    );
};

export default AuditLogs;
