import classnames from 'classnames';
import OpenInNewWindow from '../../icons/OpenInNewWindow';
import type { HTMLProps } from 'react';

interface DropdownListLinkProps extends HTMLProps<HTMLAnchorElement> {
    linkText: string;
    linkSummary: string;
    linkHref: string;
    hasIcon: boolean;
    isSelected?: boolean;
}

const DropdownListLink = ({
    linkText,
    linkSummary,
    linkHref,
    hasIcon,
    isSelected = false,
    ...otherProps
}: DropdownListLinkProps) => {
    const classes = classnames('dropdown-link', {
        selected: isSelected,
    });

    return (
        <a className={classes} href={linkHref} {...otherProps}>
            <div className="dropdown-content">
                {linkText}
                {linkSummary && <p>{linkSummary}</p>}
            </div>
            {hasIcon && <OpenInNewWindow />}
        </a>
    );
};

export default DropdownListLink;
