import { createRef, useState } from 'react';
import type { ThemeEdit } from '../../types';
import ThemeDetail from './ThemeDetail';
import ThemeList from './ThemeList';
import { connect, type ConnectedProps } from 'react-redux';
import { addNotification } from '../../../js/actions/reduxActions/notification';

type Props = ConnectedProps<typeof connector>;

const connector = connect(null, { addNotification });

const Themes = ({ addNotification }: Props) => {
    const [itemToEdit, setItemToEdit] = useState<ThemeEdit | null>(null);
    const wrapperRef = createRef<HTMLDivElement>();

    return (
        <div className="page-wrapper" ref={wrapperRef}>
            {itemToEdit === null ? (
                <ThemeList setItemToEdit={setItemToEdit} addNotification={addNotification} />
            ) : (
                <ThemeDetail
                    container={wrapperRef}
                    theme={itemToEdit}
                    returnToList={() => setItemToEdit(null)}
                    addNotification={addNotification}
                />
            )}
        </div>
    );
};

export default connector(Themes);
