import { useEffect, useId, useState } from 'react';
import { getAllProcesses, getProcessMetadata } from '../../../../sources/integration';
import FormGroup from '../../../generic/FormGroup';
import type { OptionProps, SingleValue } from 'react-select';
import ButtonDefault from '../../../buttons/ButtonDefault';
import ButtonPrimary from '../../../buttons/ButtonPrimary';
import translations from '../../../../translations';
import { useMapElement } from '../contextProviders/MapElementProvider';
import { useProcess } from '../contextProviders/ProcessProvider';
import Toggle from '../../../inputs/Toggle';
import { isNullOrEmpty } from '../../../../utils/guard';
import DynamicProcessPropertiesTable from './DynamicProcessPropertiesTable';
import type { DynamicProcessProperty } from '../../../../types/graph';
import classnames from 'classnames';
import './process-details.css';
import ProcessPropertiesTable from './ProcessPropertiesTable';
import ModalBody from '../../../generic/modal/ModalBody';
import ModalFooter from '../../../generic/modal/ModalFooter';
import ValueSelectorModal from '../../../values/selector/ValueSelectorModal';
import AsyncSelect from 'react-select/async';
import debounce from 'lodash.debounce';
import { getSharedStyles } from '../../../../utils';

interface SelectOption {
    processId: string;
    accountId: string;
    label: string;
    folderName: string;
}

const ProcessDetails = () => {
    const { onReturnToDefaultScreen, container, notifyError } = useMapElement();
    const {
        processToEdit,
        onProcessAndAccountChange,
        onWaitForProcessToFinishChange,
        onDisabledChange,
        onOrderChange,
        onDynamicProcessPropertiesChange,
        onApplyProcess,
        onOutputChange,
    } = useProcess();

    const { process, index } = processToEdit;

    const inputId = useId();

    const [selectedOption, setSelectedOption] = useState<SelectOption | null>(null);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [loading, setLoading] = useState(true);

    const isProcessIdValid = !(
        isNullOrEmpty(process?.processId) || isNullOrEmpty(process?.accountId)
    );
    const isOrderValid = !(isNullOrEmpty(process?.order) || Number.isNaN(process?.order));
    const isFormValid = isProcessIdValid && isOrderValid;

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const load = async () => {
            if (process.processId && process.accountId) {
                try {
                    setLoading(true);

                    const result = await getProcessMetadata(process.accountId, process.processId);

                    if (result) {
                        setSelectedOption({
                            processId: result.id,
                            accountId: result.accountId,
                            label: result.name,
                            folderName: result.folderName,
                        });
                    }
                } catch (error) {
                    notifyError((error as Error).message);
                } finally {
                    setLoading(false);
                }
            }
        };

        load();
    }, []);

    const loadOptions = debounce(
        async (search: string, callback: (options: SelectOption[]) => void) => {
            try {
                setLoading(true);

                const results = await getAllProcesses(search);

                callback(
                    results.map<SelectOption>((process) => ({
                        processId: process.id,
                        accountId: process.accountId,
                        label: process.name,
                        folderName: process.folderName,
                    })),
                );
            } catch (error) {
                notifyError((error as Error).message);
            } finally {
                setLoading(false);
            }
        },
        500,
    ) as (inputValue: string, callback: (options: SelectOption[]) => void) => void;

    const onProcessChange = (value: SingleValue<SelectOption>) => {
        onProcessAndAccountChange(value?.processId || '', value?.accountId || '');
        setSelectedOption(value as SelectOption);
    };

    const onDynamicProcessPropertyChange = (value: DynamicProcessProperty[]) => {
        onDynamicProcessPropertiesChange(value);
    };

    const onSave = () => {
        setHasSubmitted(true);

        if (isFormValid) {
            onApplyProcess(index);
        }
    };

    const Option = (props: OptionProps<SelectOption>) => {
        return (
            <div
                {...props.innerProps}
                className={classnames('process-option', {
                    selected: process?.processId === props.data.processId,
                })}
            >
                <span className="process-option-label">{props.data?.label}</span>
                <span className="process-option-folder-name">{props.data?.folderName}</span>
                <span className="process-option-account-id">{props.data?.accountId}</span>
                <a
                    href={`https://platform.boomi.com/AtomSphere.html#build;accountId=${
                        props.data.accountId || ''
                    };components=${props.data.processId || ''};componentIdOnFocus=${
                        props.data.processId || ''
                    }`}
                    target="_blank"
                    rel="noreferrer"
                    className="process-option-link"
                >
                    {translations.PROCESS_option_open}
                </a>
            </div>
        );
    };

    const renderBody = () => (
        <>
            <FormGroup
                label={translations.PROCESS_label}
                isRequired={true}
                isValid={isProcessIdValid}
                showValidation={hasSubmitted}
                validationMessage={translations.PROCESS_is_required}
                htmlFor={inputId}
                className="form-control-long"
            >
                <AsyncSelect
                    inputId={inputId}
                    isMulti={false}
                    isSearchable={true}
                    className="margin-bottom-small"
                    loadOptions={loadOptions}
                    onChange={onProcessChange}
                    value={selectedOption}
                    isLoading={loading}
                    components={{ Option }}
                    defaultOptions
                    styles={getSharedStyles<SelectOption>()}
                />
                {isProcessIdValid ? (
                    <div className="process-metadata">
                        {selectedOption ? (
                            <>
                                <span className="process-metadata-folder-name">
                                    {selectedOption.folderName}
                                </span>
                                <span className="process-metadata-account-id">
                                    {process.accountId}
                                </span>
                            </>
                        ) : null}
                        <a
                            href={`https://platform.boomi.com/AtomSphere.html#build;accountId=${
                                process.accountId || ''
                            };components=${process.processId || ''};componentIdOnFocus=${
                                process.processId || ''
                            }`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {translations.PROCESS_open_in_integrate}
                        </a>
                    </div>
                ) : null}
            </FormGroup>
            <FormGroup>
                <label htmlFor="wait-for-process">
                    <Toggle
                        id="wait-for-process"
                        testId="wait-for-process"
                        isOn={process.waitForProcessToFinish}
                        onChange={({ isOn }) => onWaitForProcessToFinishChange(isOn)}
                    />
                    {translations.PROCESS_wait_for_process_to_finish_label}
                </label>
            </FormGroup>
            <DynamicProcessPropertiesTable
                value={process?.dynamicProcessProperties}
                onChange={onDynamicProcessPropertyChange}
                container={container}
            />
            <ProcessPropertiesTable />
            <FormGroup label={translations.PROCESS_output_label} htmlFor="process-output">
                <ValueSelectorModal
                    includeSystemValues={false}
                    value={process.outputs[0]}
                    onChange={onOutputChange}
                    container={container}
                />
            </FormGroup>
            <FormGroup
                label={translations.PROCESS_order_label}
                htmlFor="process-order"
                isRequired
                validationMessage={translations.MAP_ELEMENT_order_field_validation_message}
                isValid={isOrderValid}
                showValidation={hasSubmitted}
            >
                <input
                    id="process-order"
                    value={Number.isNaN(process.order) ? '' : process.order}
                    onChange={(e) => onOrderChange(e.target.valueAsNumber)}
                    required
                    className="form-control form-control-width"
                    type="number"
                />
            </FormGroup>
            <div className="form-group">
                <label htmlFor="process-disabled">
                    <Toggle
                        id="process-disabled"
                        isOn={process.disabled}
                        onChange={({ isOn }) => onDisabledChange(isOn)}
                        testId="is-fixed"
                    />
                    {translations.PROCESS_disable_label}
                </label>
            </div>
        </>
    );

    const renderFooter = () => (
        <>
            <ButtonDefault className="flex-child-right" onClick={onReturnToDefaultScreen}>
                Cancel
            </ButtonDefault>
            <ButtonPrimary className="margin-left" onClick={onSave}>
                {processToEdit.isEditing
                    ? translations.GRAPH_config_panel_save
                    : translations.GRAPH_config_panel_add}
            </ButtonPrimary>
        </>
    );

    return (
        <>
            <ModalBody>{renderBody()}</ModalBody>
            <ModalFooter>{renderFooter()}</ModalFooter>
        </>
    );
};

export default ProcessDetails;
