import screens from '../common/screens';
import { useMapElement } from '../contextProviders/MapElementProvider';
import NavigationOverrideDetails from '../common/navigationOverrides/NavigationOverrideDetails';
import OperatorConfiguration from './OperatorConfiguration';
import OperationDetails from './OperationDetails';
import MacroDetails from './MacroDetails';

const OperatorRouter = () => {
    const { currentScreen } = useMapElement();

    switch (currentScreen) {
        case screens.operator:
            return <OperatorConfiguration />;

        case screens.operationDetails:
            return <OperationDetails />;

        case screens.macroDetails:
            return <MacroDetails />;

        case screens.navigationOverrides:
            return <NavigationOverrideDetails />;

        default:
            return null;
    }
};

export default OperatorRouter;
