import { useEffect, useState } from 'react';
import { getAllServices } from '../../../../../sources/service';
import translations from '../../../../../translations';
import { isNullOrEmpty } from '../../../../../utils/guard';
import ButtonDefault from '../../../../buttons/ButtonDefault';
import ButtonPrimary from '../../../../buttons/ButtonPrimary';
import FormGroup from '../../../../generic/FormGroup';
import Table, { type TableColumnList } from '../../../../generic/Table';
import Toggle from '../../../../inputs/Toggle';
import { useMapElement } from '../../contextProviders/MapElementProvider';
import { useMessageAction } from '../../contextProviders/MessageActionProvider';
import ModalBody from '../../../../generic/modal/ModalBody';
import ModalFooter from '../../../../generic/modal/ModalFooter';
import ValueSelectorTable from '../../../../values/selector/ValueSelectorTable';
import type {
    Input,
    Output,
    ServiceActionRequestAPI,
    ServiceElementResponseAPI,
    ServiceValueRequestAPI,
} from '../../../../../types';

const MessageActionDetails = () => {
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [services, setServices] = useState<ServiceElementResponseAPI[]>([]);
    const [serviceActions, setServiceActions] = useState<ServiceActionRequestAPI[]>([]);

    const { mapElement, notifyError, setConfigTitle, container } = useMapElement();

    const {
        messageActionToEdit,
        onChangeName,
        onChangeOrder,
        onChangeDisabledFlag,
        onChangeService,
        selectMessageAction,
        setInputValue,
        setOutputValue,
        onApplyMessageAction,
        onReturnToDefaultScreen,
        setInputsAndOutputs,
    } = useMessageAction();

    const { messageAction, index } = messageActionToEdit;

    const isNameValid = !isNullOrEmpty(messageAction?.developerName);
    const isOrderValid = !isNullOrEmpty(messageAction?.order);
    const isFormValid = isNameValid && isOrderValid;

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        getServiceActionsList();
    }, [messageAction?.serviceElementId ?? null, services]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const fetchServices = async () => {
            try {
                const services = await getAllServices();
                setServices(services);
            } catch (error) {
                notifyError(error);
            }
        };
        fetchServices();
    }, []);

    useEffect(() => {
        setConfigTitle(`Message Action for: ${mapElement.developerName}`);

        return () => setConfigTitle(null);
    }, [mapElement.developerName, setConfigTitle]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const updatedServiceAction = serviceActions.find(
            (action) => action.uriPart === messageAction?.uriPart,
        );

        if (!updatedServiceAction) {
            return;
        }

        // When the service is updated, merge the new actions with the existing actions
        // updatedServiceAction is from the service, and is fresh
        // messageAction is from the metadata, and is possibly out of date
        // Some actions may have been added or removed since the last metadata update

        // Because we trust the updated service,
        // map over those entries and make a new list based on those
        const inputOutputMapper =
            <T extends Input | Output>(existingEntries: T[]) =>
            (newEntry: ServiceValueRequestAPI) => {
                const alreadySavedEntry = existingEntries?.find(
                    (existingEntry) => existingEntry.developerName === newEntry.developerName,
                );
                if (alreadySavedEntry) {
                    return alreadySavedEntry;
                }
                return newEntry;
            };

        const inputs = updatedServiceAction.serviceInputs?.map(
            inputOutputMapper<Input>(messageAction?.inputs ?? []),
        );
        const outputs = updatedServiceAction.serviceOutputs?.map(
            inputOutputMapper<Output>(messageAction?.outputs ?? []),
        );

        setInputsAndOutputs({ inputs, outputs });
    }, [serviceActions]);

    if (messageAction === null) {
        return null;
    }

    const onSave = () => {
        setHasSubmitted(true);

        if (isFormValid && index !== null) {
            onApplyMessageAction(index);
        }
    };

    const getServiceActionsList = () => {
        if (messageAction?.serviceElementId) {
            const currentService = services.find((service) => {
                return service.id === messageAction.serviceElementId;
            });

            setServiceActions(currentService?.actions ?? []);
        }
    };

    const actionListColumns: TableColumnList<ServiceActionRequestAPI> = [
        {
            renderHeader: () => translations.COMMON_TABLE_actions,
            renderCell: ({ item }) => (
                <ButtonDefault onClick={() => selectMessageAction(item)}>Select</ButtonDefault>
            ),
        },
        {
            renderHeader: () => translations.COMMON_TABLE_name,
            renderCell: ({ item }) => item.developerName,
        },
        {
            renderHeader: () => translations.COMMON_TABLE_summary,
            renderCell: ({ item }) => item.developerSummary,
        },
    ];

    const renderActionList = () => (
        <>
            <span className="label">Select an Action</span>
            <Table items={serviceActions} columns={actionListColumns} />
        </>
    );

    const renderMessageActionEditor = () => {
        return (
            <>
                <h4>{`Connector: ${messageAction.serviceElementDeveloperName}`}</h4>
                <FormGroup
                    label="Name"
                    htmlFor="action-name-input"
                    isRequired
                    isValid={isNameValid}
                    validationMessage="Please enter a name."
                    showValidation={hasSubmitted}
                >
                    <input
                        id="action-name-input"
                        type="text"
                        value={messageAction.developerName ?? ''}
                        onChange={({ target: { value } }) => onChangeName(value)}
                        required
                        className="form-control form-control-width"
                    />
                </FormGroup>
                <h4>Inputs</h4>
                <ValueSelectorTable
                    items={messageAction.inputs ?? []}
                    selectValue={(value, item) => setInputValue(value, item as Input)}
                    includeSystemValues={true}
                    modalContainer={container}
                />
                <h4>Outputs</h4>
                <ValueSelectorTable
                    items={messageAction.outputs ?? []}
                    selectValue={(value, item) => setOutputValue(value, item as Output)}
                    includeSystemValues={false}
                    modalContainer={container}
                />
                <FormGroup
                    label="The order in which this Message should be sent"
                    htmlFor="action-order-input"
                    isRequired
                    isValid={isOrderValid}
                    validationMessage="Please enter an order."
                    showValidation={hasSubmitted}
                >
                    <input
                        id="action-order-input"
                        type="number"
                        value={messageAction.order}
                        onChange={({ target: { valueAsNumber } }) => onChangeOrder(valueAsNumber)}
                        required
                        size={10}
                        className="form-control"
                    />
                </FormGroup>
                <FormGroup>
                    <label htmlFor="enable-message-toggle">
                        <Toggle
                            id="enable-message-toggle"
                            isOn={!messageAction.disabled}
                            onChange={({ isOn }) => onChangeDisabledFlag(!isOn)}
                        />
                        Enable this message
                    </label>
                </FormGroup>
            </>
        );
    };

    const renderServiceSelector = () => (
        <FormGroup label="The connector to send the message" htmlFor="service-select">
            <select
                id="service-select"
                className="form-control form-control-dynamic"
                value={messageAction?.serviceElementId ?? ''}
                onChange={({ target }) =>
                    onChangeService(target.value, target.selectedOptions[0]?.textContent ?? '')
                }
            >
                <option key="-1" hidden />
                {services
                    ? services.map((service) => (
                          <option key={service.id} value={service.id}>
                              {service.developerName}
                          </option>
                      ))
                    : null}
            </select>
        </FormGroup>
    );

    const renderBody = () => {
        if (isNullOrEmpty(messageAction?.serviceElementId)) {
            return renderServiceSelector();
        }

        if (isNullOrEmpty(messageAction?.uriPart)) {
            return renderActionList();
        }

        return renderMessageActionEditor();
    };

    const showSaveButton =
        messageAction !== null &&
        messageAction.serviceElementId !== null &&
        messageAction.uriPart !== null;

    const renderFooter = () => (
        <>
            <ButtonDefault className="flex-child-right" onClick={onReturnToDefaultScreen}>
                Cancel
            </ButtonDefault>
            {showSaveButton && (
                <ButtonPrimary className="margin-left" onClick={onSave}>
                    {messageActionToEdit.isEditing
                        ? translations.GRAPH_config_panel_save
                        : translations.GRAPH_config_panel_add}
                </ButtonPrimary>
            )}
        </>
    );

    return (
        <>
            <ModalBody>{renderBody()}</ModalBody>
            <ModalFooter>{renderFooter()}</ModalFooter>
        </>
    );
};

export default MessageActionDetails;
