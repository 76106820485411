import Select, { type SingleValue } from 'react-select';
import { getType } from '../../../../../../../../sources/type';
import translations from '../../../../../../../../translations';
import FormGroup from '../../../../../../../generic/FormGroup';
import { NOTIFICATION_TYPES } from '../../../../../../../../constants';
import { isNullOrEmpty } from '../../../../../../../../utils/guard';
import { usePageEditor } from '../../../../../PageEditorProvider';
import { useEffect, useState } from 'react';
import type {
    ObjectDataRequest,
    ComponentConfigurationOptions,
} from '../../../../../../../../types';
import TypeElementDropdown from '../../../../../../../types/TypeElementDropdown';
import { getSharedStyles } from '../../../../../../../../utils/select';

interface SelectOption {
    label: string | null;
    value: string | null;
}

interface Props {
    dataSourceConnector: ObjectDataRequest | null;
    updateDataSourceConnectorType: (connector: ObjectDataRequest) => void;
    updateDataSourceConnectorBinding: (connector: ObjectDataRequest) => void;
    requiredConfiguration: ComponentConfigurationOptions;
}

const DataSourceConnectorConfig = ({
    dataSourceConnector,
    updateDataSourceConnectorType,
    updateDataSourceConnectorBinding,
    requiredConfiguration,
}: Props) => {
    const { addNotification } = usePageEditor();
    const { isDataSourceRequired } = requiredConfiguration;

    const [dataBindingOptions, setDataBindingOptions] = useState<
        { value: string; label: string }[]
    >([]);

    const [isLoading, setIsLoading] = useState(true);

    const dataTypeId = dataSourceConnector?.typeElementId ?? null;

    const dataBinding =
        dataBindingOptions.find((dbo) => dbo.value === dataSourceConnector?.typeElementBindingId) ??
        null;

    const dataBindingLabel = dataBinding?.label;
    const dataBindingValue = dataBinding?.value;

    const setDataType = (typeElementId: string | null) => {
        if (dataSourceConnector) {
            updateDataSourceConnectorType({
                ...dataSourceConnector,
                typeElementId,
                typeElementBindingId: null,
            });
        }
    };

    const setDataBinding = (selection: SingleValue<SelectOption>) => {
        if (dataSourceConnector && selection) {
            updateDataSourceConnectorBinding({
                ...dataSourceConnector,
                typeElementBindingId: selection.value,
            });
        }
    };

    // Preload and preselect any types and bindings if we have some in the connector config.
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (dataSourceConnector) {
            setIsLoading(true);
            const fetchTypeAndBindings = async (typeId: string | null) => {
                try {
                    const typeElement = typeId ? await getType(typeId) : null;

                    // Figure out the binding options for this connector.
                    if (typeElement?.bindings?.length) {
                        const options = typeElement.bindings.map((binding) => ({
                            value: binding.id,
                            label: binding.developerName,
                        }));

                        setDataBindingOptions(options);
                    }
                } catch (error) {
                    addNotification({
                        type: NOTIFICATION_TYPES.error,
                        message: `${translations.PAGE_BUILDER_unable_to_load_type_and_bindings}: ${(error as Error).toString()}`,
                        isPersistent: true,
                    });
                } finally {
                    setIsLoading(false);
                }
            };
            fetchTypeAndBindings(dataSourceConnector.typeElementId);
        }
    }, [dataTypeId]);

    return (
        <>
            <TypeElementDropdown
                isValid={!isNullOrEmpty(dataTypeId)}
                validationMessage={translations.PAGE_BUILDER_field_is_required_validation_message}
                isRequired={isDataSourceRequired}
                label="The Type of the provided data"
                typeElementId={dataTypeId}
                onChange={setDataType}
            />
            <FormGroup
                label="Use this binding to get the data"
                htmlFor="binding-select"
                isValid={!isNullOrEmpty(dataBinding)}
                validationMessage={translations.PAGE_BUILDER_field_is_required_validation_message}
                showValidation={!isLoading} // We don't want to validate this field whilst the bindings are being fetched
                isRequired={isDataSourceRequired}
            >
                <Select
                    inputId="binding-select"
                    className="select-field"
                    styles={getSharedStyles<{ label: string; value: string }>()}
                    options={dataBindingOptions}
                    onChange={(
                        selectedBinding: {
                            value: string;
                            label: string;
                        } | null,
                    ) => setDataBinding(selectedBinding)}
                    placeholder="Available bindings"
                    value={
                        dataBindingValue && dataBindingLabel
                            ? { value: dataBindingValue, label: dataBindingLabel }
                            : null
                    }
                    noOptionsMessage={() => 'No results found'}
                />
            </FormGroup>
        </>
    );
};

export default DataSourceConnectorConfig;
