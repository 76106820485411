import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { equals, path, pathOr } from 'ramda';
import { useMemo } from 'react';
import { connect } from 'react-redux';
import '../../../../../../css/graph/map-element.less';
import { toggleNoteVisibility as toggleNoteVisibilityAction } from '../../../../../js/actions/reduxActions/canvasNotes';
import {
    setDraggingData as setDraggingDataAction,
    setHoveringMapElementID as setHoveringMapElementIDAction,
    setContextMenuData as setContextMenuDataAction,
} from '../../../../../js/actions/reduxActions/graphEditor';
import { addNotification as addNotificationAction } from '../../../../../js/actions/reduxActions/notification';
import { GRAPH_ELEMENT_TYPES, MAP_ELEMENT_TYPES } from '../../../../../ts/constants';
import { safeToLower } from '../../../../utils/string';
import { useGraph } from '../../../../../js/components/graph/GraphProvider';
import { getVariableStyles, isColorFillMode } from '../../../../../js/components/graph/utils';
import { getElementStyles } from '../../../../../js/components/graph/elements/elementStyles';
import { sharedGraphElementProps } from '../../../../../js/components/graph/elements/graphElementUtils';
import MapElementBase from './MapElementBase';
import NoteElement from '../../../../../js/components/graph/elements/map/notes/NoteElement';
import StandardElement from './StandardElement';
import WaitDescription from './wait/WaitDescription';
import { useAuth } from '../../../AuthProvider';
import type {
    CanvasNote,
    Dragging,
    ElementStyles,
    GraphEditorStore,
    MapElement as MapElementType,
    SubflowResponse,
    UseGraphForMapGroupElements,
} from '../../../../types';
import type { GroupElementAPI } from '../../../../sources/graph';

interface HasXYMapElement extends MapElementType {
    x: number;
    y: number;
}

interface NotesStore {
    canvasNotes: CanvasNote[];
}

interface Props
    extends Pick<
            GraphEditorStore,
            'hoveringMapElementId' | 'dragging' | 'searchQueries' | 'contextMenu'
        >,
        NotesStore {
    hoveringMapElementId: string;
    setHoveringMapElementID: (id: string | null) => void;
    dragging: Dragging;
    setDraggingData: (data: Dragging) => void;
    tenantId: string;
    zoomLevel: number;
    mapElement: MapElementType;
    doNotRender: boolean;
    openConfig: () => void;
    flowId: string;
    editingToken: string;
    openMetadataEditor: () => void;
    groupElements: GroupElementAPI[];
    zoomViewBox: () => void;
    graphElement: SVGElement;
    toggleNoteVisibility: () => void;
    addNotification: () => void;
    setContextMenuData: () => void;
    isClone?: boolean | undefined;
}

const MapElement = (props: Props) => {
    const {
        hoveringMapElementId,
        setHoveringMapElementID,
        dragging,
        setDraggingData,
        tenantId,
        zoomLevel,
    } = props;
    let { mapElement, doNotRender } = props;

    if (!mapElement) {
        mapElement = {
            id: '',
            elementType: '',
            developerName: '',
        };
        doNotRender = true;
    }
    const { id, elementType, developerName } = mapElement;

    const { userSettings } = useAuth();
    const { canvasSettings } = userSettings;

    const navigate = useNavigate();

    const styles = useMemo(
        () =>
            getVariableStyles(
                getElementStyles(elementType),
                canvasSettings,
                zoomLevel,
            ) as ElementStyles,
        [elementType, canvasSettings, zoomLevel],
    );

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    const ports = useMemo(
        () =>
            [
                { style: styles.portLeft, key: 'left' },
                { style: styles.portRight, key: 'right' },
                { style: styles.portTop, key: 'up' },
                { style: styles.portBottom, key: 'down' },
            ].map(({ style, key }) => (
                <foreignObject
                    data-testid={`port-${key}-${developerName as string}`}
                    key={key}
                    onMouseDown={(e) => {
                        e.stopPropagation();
                        setHoveringMapElementID(null);
                        setDraggingData({
                            dragType: GRAPH_ELEMENT_TYPES.outcome,
                            elementId: id,
                            previousMousePosition: { x: e.clientX, y: e.clientY },
                        });
                    }}
                    {...style.foreignobject}
                    className="map-element-hover-wrapper"
                >
                    <style.icon
                        className="map-element-hover-icon"
                        weight="fill"
                        alt="Drag to create an outcome"
                    />
                </foreignObject>
            )),
        [
            id,
            setDraggingData,
            setHoveringMapElementID,
            styles.portBottom,
            styles.portLeft,
            styles.portRight,
            styles.portTop,
        ],
    );

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    const actionText = useMemo(
        () =>
            [MAP_ELEMENT_TYPES.input, MAP_ELEMENT_TYPES.modal].includes(
                elementType.toLowerCase(),
            ) ? (
                <button
                    id={`${id}-hyper-link`}
                    className={classNames({
                        'map-element-description': true,
                        'link-emulate': true,
                        'color-white': isColorFillMode(
                            canvasSettings.mapElementColorStyle,
                            zoomLevel,
                        ),
                    })}
                    title={path(['page', 'developerName'], mapElement)}
                    onClick={() =>
                        navigate(`/${tenantId}/pages/${pathOr('', ['page', 'id'], mapElement)}`)
                    }
                    tabIndex={-1}
                    type="button"
                >
                    {path(['page', 'developerName'], mapElement)}
                </button>
            ) : equals(elementType.toLowerCase(), MAP_ELEMENT_TYPES.subflow) ? (
                <>
                    {(mapElement?.subflow as SubflowResponse).id && (
                        <button
                            id={`${id}-hyper-link`}
                            className={classNames({
                                'map-element-description': true,
                                'link-emulate': true,
                                'color-white': isColorFillMode(
                                    canvasSettings.mapElementColorStyle,
                                    zoomLevel,
                                ),
                            })}
                            title={path(['subflow', 'developerName'], mapElement)}
                            onClick={() =>
                                navigate(
                                    `/${tenantId}/flows/${pathOr(
                                        '',
                                        ['subflow', 'id'],
                                        mapElement,
                                    )}`,
                                )
                            }
                            tabIndex={-1}
                            type="button"
                        >
                            {path(['subflow', 'developerName'], mapElement)}
                        </button>
                    )}
                    {(mapElement?.subflow as SubflowResponse).isSetAsValue && (
                        <div
                            className={classNames({
                                'color-white': isColorFillMode(
                                    canvasSettings.mapElementColorStyle,
                                    zoomLevel,
                                ),
                            })}
                        >
                            Set as Value
                        </div>
                    )}
                </>
            ) : equals(elementType.toLowerCase(), MAP_ELEMENT_TYPES.wait) ? (
                <WaitDescription
                    wait={mapElement?.wait}
                    className={classNames({
                        'color-white': isColorFillMode(
                            canvasSettings.mapElementColorStyle,
                            zoomLevel,
                        ),
                    })}
                />
            ) : null,
        [canvasSettings.mapElementColorStyle, tenantId, elementType, id, mapElement, zoomLevel],
    );

    const useGraphData: UseGraphForMapGroupElements = useGraph();

    if (doNotRender) {
        return null;
    }

    return (
        <MapElementBase
            // Passing components in so that any element can inherit the base dragging functionality
            component={
                safeToLower(mapElement.elementType) === MAP_ELEMENT_TYPES.note
                    ? NoteElement
                    : StandardElement
            }
            mapElement={mapElement}
            styles={styles}
            dragging={dragging}
            {...sharedGraphElementProps({
                ...props,
                ...useGraphData,
                ...(mapElement as HasXYMapElement),
                element: mapElement,
                dragType: GRAPH_ELEMENT_TYPES.map,
                hoveringElementId: hoveringMapElementId,
                setHoveringElementID: setHoveringMapElementID,
                developerName: mapElement.developerName,
            })}
            ports={ports}
            actionText={actionText}
            isColorFillMode={isColorFillMode(canvasSettings.mapElementColorStyle, zoomLevel)}
            isClone={props.isClone}
        />
    );
};

export default connect(
    (
        {
            graphEditor: { hoveringMapElementId, dragging, searchQueries, contextMenu },
            canvasNotes,
        }: { graphEditor: GraphEditorStore; canvasNotes: CanvasNote[] },
        ownProps,
    ) => ({
        ...ownProps,
        hoveringMapElementId,
        dragging,
        canvasNotes,
        searchQueries,
        contextMenu,
    }),
    {
        setHoveringMapElementID: setHoveringMapElementIDAction,
        setDraggingData: setDraggingDataAction,
        toggleNoteVisibility: toggleNoteVisibilityAction,
        addNotification: addNotificationAction,
        setContextMenuData: setContextMenuDataAction,
    },
)(MapElement);
