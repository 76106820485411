import FormGroup from '../../generic/FormGroup';
import { type ServiceViews, SUPPORTED_SERVICES } from '../ServiceConstants';
import type { ReactNode } from 'react';
import type { ContentType } from '../../../types';
import { useFeatureFlag } from '../../featureFlags/FeatureFlagProvider';

interface NewServiceTypeConfig {
    contentType: ContentType;
    default: string | number | Date | boolean | null;
    description: string;
    name: string;
    required: boolean;
}

export interface NewServiceType {
    name: string | undefined;
    description?: ReactNode;
    url?: string | undefined;
    view: ServiceViews | undefined;
    techPreview?: boolean;
    configuration?: NewServiceTypeConfig[];
    canHaveIdentity: boolean;
}

interface NewServiceSelectProps {
    selectedService: string | undefined;
    selectServiceType: (newServiceType: NewServiceType) => void;
}

/**
 * Allow the user to select from a dropdown of supported Flow services, or they can choose to install an Integration or Custom Service
 * @param {String} selectedService = {} - String of which Service is being selected
 * @param {Function} selectServiceType = {} - Function to update ServiceController with new selected service details
 */
const NewServiceSelect = ({ selectedService, selectServiceType }: NewServiceSelectProps) => {
    const { getFlag } = useFeatureFlag();

    return (
        <FormGroup isRequired htmlFor="connector-type" label="Connector Type">
            <select
                id="connector-type"
                key="service-select"
                className="form-control form-control-long"
                value={selectedService}
                onChange={({ target }) => {
                    const newService = SUPPORTED_SERVICES.find(
                        (service) => service.name === (target ? target.value : ''),
                    );
                    if (newService) {
                        selectServiceType(newService as NewServiceType);
                    } else {
                        selectServiceType({
                            name: undefined,
                            description: undefined,
                            url: undefined,
                            view: 'NewServiceSelect',
                            canHaveIdentity: false,
                        });
                    }
                }}
            >
                <option key="" value="">
                    Please select a connector type
                </option>
                {SUPPORTED_SERVICES.filter((service) => {
                    if (service.name === 'Atomsphere' && !getFlag('INTCON')) {
                        return false;
                    }
                    if (service.name === 'Salesforce') {
                        return false;
                    }
                    if (service.name === 'OpenAI' && !getFlag('OPENAI')) {
                        return false;
                    }
                    return true;
                }).map((service) => (
                    <option key={service.name} value={service.name}>
                        {service.name}
                    </option>
                ))}
            </select>
        </FormGroup>
    );
};

export default NewServiceSelect;
