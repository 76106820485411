import {
    Flag as StartIcon,
    FlowArrow as OutcomeIcon,
    Browser as StepIcon,
    Layout as PageIcon,
    Browsers as ModalIcon,
    MathOperations as OperatorIcon,
    EnvelopeSimple as MessageIcon,
    DownloadSimple as LoadIcon,
    FloppyDiskBack as SaveIcon,
    Trash as DeleteIcon,
    IdentificationBadge as SwimlaneIcon,
    KeyReturn as ReturnIcon,
    Note as NoteIcon,
    Question as UnknownIcon,
    Clock as WaitIcon,
    ArrowFatLeft,
    ArrowFatRight,
    ArrowFatUp,
    ArrowFatDown,
    PlugsConnected as OpenApiIcon,
} from '@phosphor-icons/react';
import GroupIcon from '../../../../ts/components/icons/GroupIcon';
import SubflowIcon from '../../../../ts/components/icons/SubflowIcon';
import DecisionIcon from '../../../../ts/components/icons/DecisionIcon';
import { MAP_ELEMENT_TYPES } from '../../../../ts/constants';
import ProcessIcon from '../../../../ts/components/icons/ProcessIcon';

export const MAP_ELEMENT_WIDTH = 120;
export const MAP_ELEMENT_HEIGHT = 30;

export const START_ELEMENT_WIDTH = 60;

const MAP_ELEMENT_OUT_BORDER_RADIUS = 5;
const MAP_ELEMENT_BORDER_RADIUS = 2.1;
const PORT_SIZE = 10;

export const GROUP_ELEMENT_WIDTH = 390;
export const GROUP_ELEMENT_HEIGHT = 120;

export const SNAP_DISTANCE = 30;

export const COLOUR_DANGER = '#DE6854';
const COLOUR_OUTCOME = '#4b4b4b';

const COLOUR_START = '#9BD5B9';
const COLOUR_STEP = '#67ABE1';
const COLOUR_PAGE = '#3392DB';
const COLOUR_MODAL = '#135097';
const COLOUR_DECISION = '#0EA076';
const COLOUR_OPERATOR = '#157E56';
const COLOUR_MESSAGE = '#235A41';
const COLOUR_PROCESS = '#234735';
const COLOUR_WAIT = '#234735';
const COLOUR_LOAD = '#FA4B6D';
const COLOUR_SAVE = '#C73D58';
const COLOUR_DELETE = '#8A3243';
const COLOUR_SWIMLANE = '#EFA037';
const COLOUR_SUBFLOW = '#808080';
const COLOUR_RETURN = '#ABABAB';
const COLOUR_GROUP = '#B49BC9';
const COLOUR_NOTE = '#A580BA';
const COLOUR_OPENAPI = '#4F1C26';

const COLOUR_SELECTED_OUTLINE = '#9c9c9c';

export const COLOUR_MAPPINGS = {
    outcome: COLOUR_OUTCOME,
    START: COLOUR_START,
    input: COLOUR_PAGE,
    modal: COLOUR_MODAL,
    step: COLOUR_STEP,
    decision: COLOUR_DECISION,
    operator: COLOUR_OPERATOR,
    message: COLOUR_MESSAGE,
    process: COLOUR_PROCESS,
    database_load: COLOUR_LOAD,
    database_save: COLOUR_SAVE,
    database_delete: COLOUR_DELETE,
    swimlane: COLOUR_SWIMLANE,
    group: COLOUR_GROUP,
    subflow: COLOUR_SUBFLOW,
    return: COLOUR_RETURN,
    note: COLOUR_NOTE,
    wait: COLOUR_WAIT,
    openapi: COLOUR_OPENAPI,
};

const baseStyles = {
    mapElement: {
        width: MAP_ELEMENT_WIDTH,
        height: MAP_ELEMENT_HEIGHT,
    },
    outBorder: {
        x: -MAP_ELEMENT_OUT_BORDER_RADIUS,
        y: -MAP_ELEMENT_OUT_BORDER_RADIUS,
        width: MAP_ELEMENT_WIDTH + MAP_ELEMENT_OUT_BORDER_RADIUS * 2,
        height: MAP_ELEMENT_HEIGHT + MAP_ELEMENT_OUT_BORDER_RADIUS * 2,
        stroke: COLOUR_SELECTED_OUTLINE,
        rx: 7,
        strokeWidth: 0.7,
        strokeDasharray: 2,
        fillOpacity: 0,
    },
    border: {
        width: MAP_ELEMENT_WIDTH,
        height: MAP_ELEMENT_HEIGHT,
        fill: '#333',
        rx: 5,
        strokeWidth: 0,
    },
    fill: {
        fill: '#fff',
        rx: 3,
        strokeWidth: 0,
        x: MAP_ELEMENT_BORDER_RADIUS,
        y: MAP_ELEMENT_BORDER_RADIUS,
        width: MAP_ELEMENT_WIDTH - MAP_ELEMENT_BORDER_RADIUS * 2,
        height: MAP_ELEMENT_HEIGHT - MAP_ELEMENT_BORDER_RADIUS * 2,
    },
    icon: UnknownIcon,
    content: {
        x: 5,
        y: 5,
        height: MAP_ELEMENT_HEIGHT - 10,
        width: MAP_ELEMENT_WIDTH - 10,
    },
    portLeft: {
        foreignobject: {
            x: -PORT_SIZE,
            y: (MAP_ELEMENT_HEIGHT - PORT_SIZE) / 2,
            width: PORT_SIZE,
            height: PORT_SIZE,
        },
        icon: ArrowFatLeft,
    },
    portRight: {
        foreignobject: {
            x: MAP_ELEMENT_WIDTH,
            y: (MAP_ELEMENT_HEIGHT - PORT_SIZE) / 2,
            width: PORT_SIZE,
            height: PORT_SIZE,
        },
        icon: ArrowFatRight,
    },
    portTop: {
        foreignobject: {
            x: (MAP_ELEMENT_WIDTH - PORT_SIZE) / 2,
            y: -PORT_SIZE,
            width: PORT_SIZE,
            height: PORT_SIZE,
        },
        icon: ArrowFatUp,
    },
    portBottom: {
        foreignobject: {
            x: (MAP_ELEMENT_WIDTH - PORT_SIZE) / 2,
            y: MAP_ELEMENT_HEIGHT,
            width: PORT_SIZE,
            height: PORT_SIZE,
        },
        icon: ArrowFatDown,
    },
    deleteIcon: {
        height: 10,
        width: 10,
        x: MAP_ELEMENT_WIDTH + MAP_ELEMENT_OUT_BORDER_RADIUS - 10 / 2,
        y: -MAP_ELEMENT_OUT_BORDER_RADIUS - 10 / 2,
        image: 'remove-sign',
    },
};

const pageStyles = {
    border: {
        fill: COLOUR_PAGE,
    },
    icon: PageIcon,
};

const modalStyles = {
    border: {
        fill: COLOUR_MODAL,
    },
    icon: ModalIcon,
};

const stepStyles = {
    border: {
        fill: COLOUR_STEP,
    },
    icon: StepIcon,
};

const decisionStyles = {
    border: {
        fill: COLOUR_DECISION,
    },
    icon: DecisionIcon,
};

const operatorStyles = {
    border: {
        fill: COLOUR_OPERATOR,
    },
    icon: OperatorIcon,
};

const messageStyles = {
    border: {
        fill: COLOUR_MESSAGE,
    },
    icon: MessageIcon,
};

const processStyles = {
    border: {
        fill: COLOUR_PROCESS,
    },
    icon: ProcessIcon,
};

const loadStyles = {
    border: {
        fill: COLOUR_LOAD,
    },
    icon: LoadIcon,
};

const saveStyles = {
    border: {
        fill: COLOUR_SAVE,
    },
    icon: SaveIcon,
};

const deleteStyles = {
    border: {
        fill: COLOUR_DELETE,
    },
    icon: DeleteIcon,
};

const subflowStyles = {
    border: {
        fill: COLOUR_SUBFLOW,
    },
    icon: SubflowIcon,
};

const returnStyles = {
    border: {
        fill: COLOUR_RETURN,
    },
    icon: ReturnIcon,
};

const noteStyles = {
    outBorder: {
        x: -MAP_ELEMENT_OUT_BORDER_RADIUS,
        y: -MAP_ELEMENT_OUT_BORDER_RADIUS,
        width: 40,
        height: 40,
        stroke: COLOUR_SELECTED_OUTLINE,
        rx: 1000,
        strokeWidth: 0.7,
        strokeDasharray: 2,
        fillOpacity: 0,
    },
    border: {
        fill: COLOUR_NOTE,
        width: 30,
        rx: 1000,
    },
    mapElement: {
        height: 30,
        width: 30,
        rx: 1000,
    },
    fill: {
        height: 30 - MAP_ELEMENT_BORDER_RADIUS * 2,
        width: 30 - MAP_ELEMENT_BORDER_RADIUS * 2,
        rx: 1000,
    },
    content: {
        height: 30,
        width: 30,
        rx: 1000,
        x: 0,
        y: 0,
    },
    icon: NoteIcon,
};

const startStyles = {
    mapElement: {
        width: START_ELEMENT_WIDTH,
    },
    border: {
        fill: COLOUR_START,
        width: START_ELEMENT_WIDTH,
        rx: 12,
    },
    icon: StartIcon,
    outBorder: {
        width: START_ELEMENT_WIDTH + MAP_ELEMENT_OUT_BORDER_RADIUS * 2,
        rx: 16,
    },
    fill: {
        width: START_ELEMENT_WIDTH - MAP_ELEMENT_BORDER_RADIUS * 2,
        rx: 10,
    },
    content: {
        width: START_ELEMENT_WIDTH - 10,
    },
    portRight: {
        foreignobject: {
            ...baseStyles.portRight.foreignobject,
            x: START_ELEMENT_WIDTH,
        },
    },
    portTop: {
        foreignobject: {
            ...baseStyles.portTop.foreignobject,
            x: (START_ELEMENT_WIDTH - PORT_SIZE) / 2,
        },
    },
    portBottom: {
        foreignobject: {
            ...baseStyles.portBottom.foreignobject,
            x: (START_ELEMENT_WIDTH - PORT_SIZE) / 2,
        },
    },
    deleteIcon: {
        x: START_ELEMENT_WIDTH + MAP_ELEMENT_OUT_BORDER_RADIUS - 10 / 2,
    },
};

const swimlaneStyles = {
    groupElement: {
        width: GROUP_ELEMENT_WIDTH,
        height: GROUP_ELEMENT_HEIGHT,
    },
    outBorder: {
        x: -5,
        y: -5,
        stroke: COLOUR_SELECTED_OUTLINE,
        rx: 9,
        strokeWidth: 0.7,
        strokeDasharray: 2,
        fill: 'none',
    },
    border: {
        width: MAP_ELEMENT_WIDTH,
        height: MAP_ELEMENT_HEIGHT,
        fill: COLOUR_SWIMLANE,
        rx: 1,
        strokeWidth: 0,
        fillOpacity: 0.2,
    },
    header: {
        height: 30,
    },
    fill: {
        x: MAP_ELEMENT_BORDER_RADIUS,
        y: MAP_ELEMENT_BORDER_RADIUS,
        width: MAP_ELEMENT_WIDTH - MAP_ELEMENT_BORDER_RADIUS * 2,
        height: MAP_ELEMENT_HEIGHT - MAP_ELEMENT_BORDER_RADIUS * 2,
        fill: '#fff',
        rx: 0.5,
        strokeWidth: 0,
    },
    content: {
        x: 5,
        y: 5,
        height: MAP_ELEMENT_HEIGHT - 10,
        width: MAP_ELEMENT_WIDTH - 10,
    },
    iconRow: {
        height: 10,
        width: 20,
        y: -10,
    },
    icon: SwimlaneIcon,
};

const groupStyles = {
    border: {
        fill: COLOUR_GROUP,
    },
    icon: GroupIcon,
    iconRow: {
        width: 40,
    },
};

const waitStyles = {
    border: {
        fill: COLOUR_WAIT,
    },
    icon: WaitIcon,
};

const openApiStyles = {
    border: {
        fill: COLOUR_OPENAPI,
    },
    icon: OpenApiIcon,
};

const outcomeStyles = {
    // used in config modal header
    border: {
        fill: COLOUR_OUTCOME,
    },
    icon: OutcomeIcon,
};

const mergeStyles = (base, overrides) =>
    Object.entries(base).reduce((merged, [key, val]) => {
        merged[key] =
            typeof val === 'object' && key !== 'icon'
                ? {
                      ...val,
                      ...overrides[key],
                  }
                : overrides[key] || val;

        return merged;
    }, {});

export const getElementStyles = (elementType) => {
    switch (elementType.toLowerCase()) {
        case MAP_ELEMENT_TYPES.start:
            return mergeStyles(baseStyles, startStyles);

        case MAP_ELEMENT_TYPES.input:
            return mergeStyles(baseStyles, pageStyles);

        case MAP_ELEMENT_TYPES.modal:
            return mergeStyles(baseStyles, modalStyles);

        case MAP_ELEMENT_TYPES.step:
            return mergeStyles(baseStyles, stepStyles);

        case MAP_ELEMENT_TYPES.decision:
            return mergeStyles(baseStyles, decisionStyles);

        case MAP_ELEMENT_TYPES.operator:
            return mergeStyles(baseStyles, operatorStyles);

        case MAP_ELEMENT_TYPES.message:
            return mergeStyles(baseStyles, messageStyles);

        case MAP_ELEMENT_TYPES.process:
            return mergeStyles(baseStyles, processStyles);

        case MAP_ELEMENT_TYPES.databaseLoad:
            return mergeStyles(baseStyles, loadStyles);

        case MAP_ELEMENT_TYPES.databaseSave:
            return mergeStyles(baseStyles, saveStyles);

        case MAP_ELEMENT_TYPES.databaseDelete:
            return mergeStyles(baseStyles, deleteStyles);

        case MAP_ELEMENT_TYPES.subflow:
            return mergeStyles(baseStyles, subflowStyles);

        case MAP_ELEMENT_TYPES.return:
            return mergeStyles(baseStyles, returnStyles);

        case MAP_ELEMENT_TYPES.note:
            return mergeStyles(baseStyles, noteStyles);

        case MAP_ELEMENT_TYPES.swimlane:
            return swimlaneStyles;

        case MAP_ELEMENT_TYPES.group:
            return mergeStyles(swimlaneStyles, groupStyles);

        case MAP_ELEMENT_TYPES.wait:
            return mergeStyles(baseStyles, waitStyles);

        case MAP_ELEMENT_TYPES.outcome:
            return mergeStyles(baseStyles, outcomeStyles);

        case MAP_ELEMENT_TYPES.openapi:
            return mergeStyles(baseStyles, openApiStyles);

        default:
            return baseStyles;
    }
};
