import { connect, type ConnectedProps } from 'react-redux';
import AssetManager from './AssetManager';
import { addNotification } from '../../../js/actions/reduxActions/notification';

type Props = ConnectedProps<typeof connector>;

const connector = connect(null, { addNotification });

const AssetTab = ({ addNotification }: Props) => (
    <div className="admin-page">
        <h1>Assets</h1>
        <AssetManager addNotification={addNotification} />
    </div>
);

export default connector(AssetTab);
