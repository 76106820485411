import MapElementModal from '../../../graph/MapElementModal';
import { MAP_ELEMENT_TYPES } from '../../../../constants';
import screens from '../common/screens';
import { ListenerProvider } from '../contextProviders/ListenerProvider';
import {
    MapElementProvider,
    type MapElementConfigProps,
} from '../contextProviders/MapElementProvider';
import { MessageActionProvider } from '../contextProviders/MessageActionProvider';
import MessageRouter from './MessageRouter';

const Message = (props: MapElementConfigProps) => {
    return (
        <MapElementProvider
            {...props}
            defaultScreen={screens.message}
            elementType={MAP_ELEMENT_TYPES.message}
            isLoading={false}
        >
            <MapElementModal>
                <MessageActionProvider defaultScreen={screens.message}>
                    <ListenerProvider defaultScreen={screens.message}>
                        <MessageRouter />
                    </ListenerProvider>
                </MessageActionProvider>
            </MapElementModal>
        </MapElementProvider>
    );
};

export default Message;
