import { useState } from 'react';
import { getTypeList } from '../../../sources/type';
import {
    ExAlertBanner,
    AlertBannerType,
    ExBadge,
    BadgeShape,
    BadgeSize,
    BadgeColor,
} from '@boomi/exosphere';
import { CONTENT_TYPES_LIST, ACCESS_LEVELS } from '../../../constants';
import FormGroup from '../../generic/FormGroup';
import Select from 'react-select';
import translations from '../../../translations';
import { getSharedStyles } from '../../../utils/select';
import type { TypeElementResponseAPI } from '../../../types';

export interface ValueSelectorFilters {
    access: string | null;
    contentType: string[];
    typeElementId: string | null;
    limit: number;
    offset: number;
    search: string;
}

interface Props {
    value: ValueSelectorFilters;
    onChange: (value: ValueSelectorFilters) => void;
}

type Option = { label: string; value: string };

const ValueSelectorFilter = ({ value, onChange }: Props) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [types, setTypes] = useState<TypeElementResponseAPI[]>([]);
    const [error, setError] = useState<string | null>(null);

    const onToggleExpanded = () => {
        setExpanded(!expanded);

        if (!expanded && types.length === 0) {
            loadTypes();
        }
    };

    const loadTypes = async () => {
        setLoading(true);

        try {
            setTypes(await getTypeList(null));
        } catch (error) {
            setError((error as Error).message);
        } finally {
            setLoading(false);
        }
    };

    const onTypeChange = (typeElementId: Option | null) => {
        onChange({
            ...value,
            typeElementId: typeElementId?.value || null,
        });
    };

    const onContentTypeChange = (contentTypes: readonly Option[]) => {
        onChange({
            ...value,
            contentType: contentTypes.map((contentType) => contentType.value),
        });
    };

    const onAccessChange = (access: Option | null) => {
        onChange({
            ...value,
            access: access?.value || null,
        });
    };

    let numberOfFilters = 0;

    if (value.typeElementId) {
        numberOfFilters++;
    }

    if (value.contentType?.length > 0) {
        numberOfFilters++;
    }

    if (value.access) {
        numberOfFilters++;
    }

    const typeOptions = types.map<Option>((type) => ({
        label: type.developerName || '',
        value: type.id,
    }));
    const selectedType = typeOptions.find((option) => value.typeElementId === option.value) || null;

    const contentTypeOptions = CONTENT_TYPES_LIST.map<Option>((contentType) => ({
        value: contentType.key,
        label: contentType.label,
    }));
    const selectedContentTypes = contentTypeOptions.filter((option) =>
        value.contentType?.map((type) => type).includes(option.value),
    );

    const accessOptions = Object.values(ACCESS_LEVELS).map((access) => ({
        value: access.value,
        label: access.label,
    }));
    const selectedAccess = accessOptions.find((option) => value.access === option.value) || null;

    return (
        <div className="padding-default border-bottom">
            <button
                className="value-selector-filter-toggle"
                data-testid="value-selector-filter-toggle"
                onClick={onToggleExpanded}
                type="button"
            >
                {expanded
                    ? translations.VALUE_SELECTOR_hide_filters
                    : translations.VALUE_SELECTOR_show_filters}
                <ExBadge
                    className="inline-block margin-left-med"
                    color={BadgeColor.BLUE}
                    shape={BadgeShape.ROUND}
                    size={BadgeSize.SMALL}
                >
                    {numberOfFilters}
                </ExBadge>
            </button>
            <section className={`value-selector-filter-container ${expanded ? 'is-open' : ''}`}>
                {error ? (
                    <ExAlertBanner open={true} type={AlertBannerType.ERROR}>
                        {error}
                    </ExAlertBanner>
                ) : null}
                <FormGroup
                    className="value-selector-filter-type"
                    label={translations.VALUE_SELECTOR_filters_type}
                    htmlFor="value-selector-filter-type"
                >
                    <Select
                        styles={getSharedStyles<Option>()}
                        inputId="value-selector-filter-type"
                        onChange={onTypeChange}
                        placeholder={translations.VALUE_type_selector_placeholder_text}
                        value={selectedType}
                        options={typeOptions}
                        filterOption={(option: Option, input: string) => {
                            if (input) {
                                return option.label
                                    ?.toLowerCase()
                                    .includes(input?.toLocaleLowerCase().trim());
                            }

                            return true;
                        }}
                        isClearable
                        isDisabled={loading}
                        isLoading={loading}
                        menuPortalTarget={document.body}
                        closeMenuOnScroll={(e) => {
                            if (e.target === document.getElementsByClassName('modal-scroller')[0]) {
                                return true;
                            }
                            return false;
                        }}
                    />
                </FormGroup>
                <FormGroup
                    className="value-selector-filter-content-type"
                    label={translations.VALUE_SELECTOR_filters_content_type}
                    htmlFor="value-selector-filter-content-type"
                >
                    <Select
                        styles={getSharedStyles<Option, true>()}
                        inputId="value-selector-filter-content-type"
                        isMulti={true}
                        options={contentTypeOptions}
                        onChange={onContentTypeChange}
                        placeholder="&empty; none"
                        value={selectedContentTypes}
                        menuPortalTarget={document.body}
                        closeMenuOnScroll={(e) => {
                            if (e.target === document.getElementsByClassName('modal-scroller')[0]) {
                                return true;
                            }
                            return false;
                        }}
                    />
                </FormGroup>
                <FormGroup
                    className="value-selector-filter-access"
                    label={translations.VALUE_SELECTOR_filters_access}
                    htmlFor="value-selector-filter-access"
                >
                    <Select
                        styles={getSharedStyles<{ label: string; value: string }>()}
                        inputId="value-selector-filter-access"
                        options={accessOptions}
                        onChange={onAccessChange}
                        placeholder="&empty; none"
                        value={selectedAccess}
                        isClearable
                        menuPortalTarget={document.body}
                        closeMenuOnScroll={(e) => {
                            if (e.target === document.getElementsByClassName('modal-scroller')[0]) {
                                return true;
                            }
                            return false;
                        }}
                    />
                </FormGroup>
            </section>
        </div>
    );
};

export default ValueSelectorFilter;
