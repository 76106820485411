import { AlertBannerType, ExAlertBanner } from '@boomi/exosphere';
import {
    type ChangeEventHandler,
    type ComponentPropsWithoutRef,
    useEffect,
    useId,
    useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { notifyError, notifySuccess } from '../../../../js/actions/reduxActions/notification';
import { createRuntime, getRuntime, updateRuntime } from '../../../sources/runtime';
import translations from '../../../translations';
import type {
    RuntimeCreateRequest,
    RuntimeCreateResponse,
    RuntimeUpdateRequest,
} from '../../../types/runtime';
import { stringReplace } from '../../../utils';
import ButtonLoading from '../../buttons/ButtonLoading';
import FormGroup from '../../generic/FormGroup';
import GenericModal from '../../generic/modal/GenericModal';
import ClipboardInput from '../../inputs/ClipboardInput';
import './local-runtimes.css';
import LocalRuntimeOrgTenants from './LocalRuntimeOrgTenants';

type LocalRuntimeEditorProps = {
    runtimeId: string | null;
} & Pick<ComponentPropsWithoutRef<typeof GenericModal>, 'container' | 'onHide'>;

const LocalRuntimeEditModal = ({ runtimeId, container, onHide }: LocalRuntimeEditorProps) => {
    // The tenant ID's connected to the runtime
    const [connectedTenantIds, setConnectedTenantIds] = useState<string[]>([]);
    const [nameInput, setNameInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [createdRuntime, setCreatedRuntime] = useState<RuntimeCreateResponse | null>(null);
    const dispatch = useDispatch();

    const connectionNameInputId = useId();
    const serverKeyInputId = useId();

    useEffect(() => {
        const fetchRuntimeToEdit = async (runtimeId: string) => {
            try {
                setIsLoading(true);

                // Don't need to keep this runtime object around, just using it to initialize the form
                const runtime = await getRuntime({ id: runtimeId });
                setConnectedTenantIds(runtime.tenants.map((tenant) => tenant.id));
                setNameInput(runtime.developerName);
            } catch (error) {
                dispatch(notifyError(error));
            } finally {
                setIsLoading(false);
            }
        };

        if (!runtimeId) {
            return;
        }

        fetchRuntimeToEdit(runtimeId);
    }, [runtimeId, dispatch]);

    const resetForm = () => {
        setNameInput('');
        setConnectedTenantIds([]);
        setCreatedRuntime(null);
    };

    const handleClose = () => {
        resetForm();
        onHide();
    };

    const createLocalRuntime = async (request: RuntimeCreateRequest) => {
        try {
            setIsLoading(true);

            const newRuntime = await createRuntime(request);

            dispatch(
                notifySuccess(
                    stringReplace(translations.LOCAL_RUNTIME_runtime_create_success_message, {
                        name: newRuntime.developerName,
                    }),
                ),
            );

            // Display the runtime token, passing in the newly created runtime
            setCreatedRuntime(newRuntime);
        } catch (error) {
            dispatch(notifyError(error));
        } finally {
            setIsLoading(false);
        }
    };

    const updateLocalRuntime = async (runtimeId: string, updateRequest: RuntimeUpdateRequest) => {
        try {
            setIsLoading(true);

            const updatedRuntime = await updateRuntime(runtimeId, updateRequest);

            dispatch(
                notifySuccess(
                    stringReplace(translations.LOCAL_RUNTIME_runtime_update_success_message, {
                        name: updatedRuntime.developerName,
                    }),
                ),
            );

            handleClose();
        } catch (error) {
            dispatch(notifyError(error));
        } finally {
            setIsLoading(false);
        }
    };

    const handleSubmit = async () => {
        const request = {
            developerName: nameInput,
            tenants: connectedTenantIds.map((id) => ({ id })),
        } satisfies RuntimeCreateRequest | RuntimeUpdateRequest;

        if (runtimeId) {
            await updateLocalRuntime(runtimeId, request);
        } else {
            await createLocalRuntime(request);
        }
    };

    const handleChange: ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => {
        setNameInput(value);
    };

    const handleTenantSelect = (isSelected: boolean, tenantId: string) => {
        const selectedTenants = isSelected
            ? [...connectedTenantIds, tenantId]
            : connectedTenantIds.filter((t) => t !== tenantId);

        setConnectedTenantIds(selectedTenants);
    };

    return (
        <GenericModal
            title={stringReplace(translations.LOCAL_RUNTIME_edit_modal_title, {
                mode: runtimeId
                    ? translations.LOCAL_RUNTIME_edit_modal_edit_mode_text
                    : translations.LOCAL_RUNTIME_edit_modal_create_mode_text,
            })}
            container={container}
            onHide={handleClose}
            bodyClassName="local-runtime-edit-modal-body"
            renderBody={() =>
                createdRuntime ? (
                    <div className="local-runtime-server-key-display">
                        <ExAlertBanner
                            type={AlertBannerType.WARNING}
                            open
                            className="block margin-bottom"
                        >
                            {translations.LOCAL_RUNTIME_token_display_warning}
                        </ExAlertBanner>
                        <FormGroup
                            htmlFor={serverKeyInputId}
                            label={translations.LOCAL_RUNTIME_token_display_label}
                        >
                            <ClipboardInput
                                id={serverKeyInputId}
                                value={createdRuntime.installationToken}
                                buttonTitle={translations.copy_server_key_title}
                            />
                        </FormGroup>
                    </div>
                ) : (
                    <>
                        {!runtimeId && (
                            <>
                                <ExAlertBanner type={AlertBannerType.WARNING} open>
                                    {translations.LOCAL_RUNTIME_create_screen_setup_warning_text}
                                </ExAlertBanner>
                                <p>{translations.LOCAL_RUNTIME_create_screen_summary}</p>
                            </>
                        )}
                        <FormGroup
                            label={translations.LOCAL_RUNTIME_connection_name_input_label}
                            isRequired
                            htmlFor={connectionNameInputId}
                        >
                            <input
                                id={connectionNameInputId}
                                className="form-control"
                                value={nameInput}
                                onChange={handleChange}
                                size={50}
                            />
                        </FormGroup>
                        <p>{translations.LOCAL_RUNTIME_create_screen_shared_tenants_summary}</p>
                        <LocalRuntimeOrgTenants
                            onSelect={handleTenantSelect}
                            connectedTenantIds={connectedTenantIds}
                        />
                    </>
                )
            }
            footerClassName="admin-footer"
            renderFooter={() =>
                createdRuntime ? (
                    <button type="button" className="btn btn-primary" onClick={handleClose}>
                        {translations.LOCAL_RUNTIME_token_display_submit}
                    </button>
                ) : (
                    <>
                        <button type="button" className="btn btn-default" onClick={handleClose}>
                            {translations.COMMON_cancel}
                        </button>
                        <ButtonLoading
                            type="button"
                            className="btn btn-primary"
                            onClick={handleSubmit}
                            isLoading={isLoading}
                        >
                            {runtimeId
                                ? translations.LOCAL_RUNTIME_edit_modal_edit_mode_submit_button_label
                                : translations.LOCAL_RUNTIME_edit_modal_create_mode_submit_button_label}
                        </ButtonLoading>
                    </>
                )
            }
        />
    );
};

export default LocalRuntimeEditModal;
