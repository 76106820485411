import { memo, useCallback, useEffect, useReducer } from 'react';
import { AUTHENTICATION_TYPES } from '../../constants';
import FormGroup from '../generic/FormGroup';
import GroupOrUserSection from './GroupsUsersManager';
import { reducer } from './authorization-reducer';
import type {
    AuthorizationEntity,
    GlobalAuthenticationType,
    GroupAuthorizationAPI,
    NotifyError,
    ServiceElementResponseAPI,
} from '../../types';

interface Props {
    authorization: GroupAuthorizationAPI | null;
    connectors: ServiceElementResponseAPI[];
    onChangeAuthorization: (authorization: GroupAuthorizationAPI) => void;
    notifyError: NotifyError;
}

const Connector = ({
    authorization: initialAuthorization,
    connectors,
    onChangeAuthorization,
    notifyError,
}: Props) => {
    const [authorization, dispatchAuthorization] = useReducer(reducer, initialAuthorization);

    const onChangeGroups = useCallback((groups: AuthorizationEntity[] | null) => {
        dispatchAuthorization({ type: 'updateGroups', payload: { groups: groups ?? [] } });
    }, []);

    const onChangeUsers = useCallback((users: AuthorizationEntity[] | null) => {
        dispatchAuthorization({ type: 'updateUsers', payload: { users: users ?? [] } });
    }, []);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (authorization === null) {
            return;
        }
        onChangeAuthorization(authorization);
    }, [authorization]);

    if (authorization === null) {
        return null;
    }

    return (
        <>
            <FormGroup label="Connector" htmlFor="flow-properties-connector" className="margin-top">
                <select
                    id="flow-properties-connector"
                    className="form-control form-control-dynamic"
                    value={authorization.serviceElementId ?? ''}
                    onChange={({ target }) => {
                        dispatchAuthorization({
                            type: 'updateConnector',
                            payload: { connectorId: target.value },
                        });
                    }}
                >
                    <option key="-1" value="">
                        No authentication connector
                    </option>
                    {connectors
                        ? connectors.map((connector) => (
                              <option key={connector.id} value={connector.id}>
                                  {connector.developerName}
                              </option>
                          ))
                        : null}
                </select>
            </FormGroup>

            <FormGroup label="Access" htmlFor="flow-properties-connector-access">
                <select
                    id="flow-properties-connector-access"
                    className="form-control form-control-dynamic"
                    onChange={({ target }) => {
                        dispatchAuthorization({
                            type: 'updateAccess',
                            payload: {
                                authenticationType: target.value as GlobalAuthenticationType,
                            },
                        });
                    }}
                    value={authorization.globalAuthenticationType}
                    disabled={!authorization.serviceElementId}
                >
                    <option
                        key={AUTHENTICATION_TYPES.public.value}
                        value={AUTHENTICATION_TYPES.public.value}
                    >
                        {AUTHENTICATION_TYPES.public.label}
                    </option>
                    <option
                        key={AUTHENTICATION_TYPES.allUsers.value}
                        value={AUTHENTICATION_TYPES.allUsers.value}
                    >
                        {AUTHENTICATION_TYPES.allUsers.label}
                    </option>
                    <option
                        key={AUTHENTICATION_TYPES.specified.value}
                        value={AUTHENTICATION_TYPES.specified.value}
                    >
                        {AUTHENTICATION_TYPES.specified.label}
                    </option>
                </select>
            </FormGroup>
            {authorization.globalAuthenticationType === 'SPECIFIED' &&
            authorization.serviceElementId !== null ? (
                <>
                    <GroupOrUserSection
                        authorizationEntityType="group"
                        connectorId={authorization.serviceElementId}
                        groupsOrUsers={authorization.groups}
                        onChangeGroupsOrUsers={onChangeGroups}
                        notifyError={notifyError}
                    />
                    <GroupOrUserSection
                        authorizationEntityType="user"
                        connectorId={authorization.serviceElementId}
                        groupsOrUsers={authorization.users}
                        onChangeGroupsOrUsers={onChangeUsers}
                        notifyError={notifyError}
                    />
                </>
            ) : null}
        </>
    );
};

export default memo(Connector);
