import { useState } from 'react';
import type { ContentType } from '../../types';
import { isNullOrEmpty } from '../../utils/guard';
import Breadcrumb from '../Breadcrumb';
import ButtonDefault from '../buttons/ButtonDefault';
import ButtonPrimary from '../buttons/ButtonPrimary';
import Footer from '../generic/Footer';
import FormGroup from '../generic/FormGroup';
import { VALUE_TYPES } from '../values/selector/value-selector-utils';
import TypeElementDropdown from './TypeElementDropdown';
import { useTypes } from './TypesProvider';
import translations from '../../translations';

const TypePropertyDetail = () => {
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const { typeToEdit, propertyToEdit, returnToType, applyProperty, breadcrumbs } = useTypes();
    const [property, setProperty] = useState(propertyToEdit);

    const isComplexPropertyType =
        property.contentType === 'ContentObject' || property.contentType === 'ContentList';

    const isFormattablePropertyType =
        property.contentType === 'ContentDateTime' ||
        property.contentType === 'ContentDate' ||
        property.contentType === 'ContentNumber';

    const checkRequiredFields = () => {
        type RequiredFields = {
            defaultDeveloperName: string;
            defaultContentType: ContentType | null;
            defaultPropertyType?: string | null;
        };

        let requiredFields: RequiredFields = {
            defaultDeveloperName: property.developerName ?? '',
            defaultContentType: property.contentType ?? null,
        };

        if (isComplexPropertyType) {
            requiredFields = {
                ...requiredFields,
                defaultPropertyType: property?.typeElementId ?? null,
            };
        }

        return requiredFields;
    };

    const onApply = () => {
        setHasSubmitted(true);

        const isFormValid = Object.values(checkRequiredFields()).every(
            (field) => !isNullOrEmpty(field),
        );

        if (isFormValid) {
            applyProperty(property);
        }
    };

    const updatePropertyName = (developerName: string) => {
        setProperty({ ...property, developerName });
    };

    const updatePropertyContentType = (contentType: ContentType) => {
        setProperty({ ...property, contentType });
    };

    const updatePropertyType = (typeElementId: string | null) => {
        setProperty({ ...property, typeElementId });
    };

    const updatePropertyFormat = (contentFormat: string | null) => {
        setProperty({ ...property, contentFormat });
    };

    const { defaultDeveloperName, defaultContentType, defaultPropertyType } = checkRequiredFields();

    return (
        <>
            <div className="admin-page">
                <h1>
                    {translations.TYPES_property_detail_heading} {typeToEdit.developerName ?? ''}
                </h1>
                <div className="margin-bottom margin-top-large" data-testid="type-breadcrumbs">
                    <Breadcrumb trail={breadcrumbs.trail} activeItemId={breadcrumbs.activeItemId} />
                </div>
                <FormGroup
                    label={translations.COMMON_name}
                    htmlFor="type-property-name"
                    isRequired
                    validationMessage={translations.TYPES_name_field_validation_message}
                    isValid={!isNullOrEmpty(defaultDeveloperName)}
                    showValidation={hasSubmitted}
                >
                    <input
                        id="type-property-name"
                        className="form-control form-control-width"
                        value={defaultDeveloperName ?? ''}
                        onChange={({ target: { value } }) => updatePropertyName(value)}
                        autoFocus
                        required
                    />
                </FormGroup>
                <FormGroup
                    label={translations.TYPES_property_type_input_label}
                    htmlFor="type-property-type"
                    isRequired
                    validationMessage={translations.TYPES_content_type_field_validation_message}
                    isValid={!isNullOrEmpty(defaultContentType)}
                    showValidation={hasSubmitted}
                >
                    <select
                        id="type-property-type"
                        value={defaultContentType ?? ''}
                        onChange={({ target: { value } }) =>
                            updatePropertyContentType(value as ContentType)
                        }
                        className="form-control form-control-width"
                        required
                    >
                        <option value="" disabled hidden>
                            {translations.TYPES_property_type_input_label}
                        </option>
                        {VALUE_TYPES.map(({ value, label }) => (
                            <option key={value} value={value}>
                                {label}
                            </option>
                        ))}
                    </select>
                </FormGroup>
                {isComplexPropertyType && (
                    <TypeElementDropdown
                        isRequired
                        showValidation={hasSubmitted}
                        isValid={!isNullOrEmpty(defaultPropertyType)}
                        validationMessage={
                            translations.TYPES_property_type_field_validation_message
                        }
                        typeElementId={property.typeElementId}
                        onChange={updatePropertyType}
                        className="form-control-width"
                    />
                )}
                {isFormattablePropertyType && (
                    <FormGroup
                        label={translations.TYPES_property_format_input_label}
                        htmlFor="type-property-format"
                    >
                        <input
                            id="type-property-format"
                            className="form-control form-control-width"
                            value={property.contentFormat ?? ''}
                            onChange={({ target: { value } }) => updatePropertyFormat(value)}
                            placeholder={translations.TYPES_property_format_input_placeholder}
                        />
                    </FormGroup>
                )}
            </div>

            <Footer>
                <ButtonDefault
                    title={translations.COMMON_back}
                    className="flex-child-right"
                    onClick={returnToType}
                >
                    {translations.COMMON_back}
                </ButtonDefault>
                <ButtonPrimary
                    title={translations.TYPES_apply_property_button_label}
                    onClick={onApply}
                >
                    {translations.TYPES_apply_property_button_label}
                </ButtonPrimary>
            </Footer>
        </>
    );
};

export default TypePropertyDetail;
