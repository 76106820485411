import MapElementModal from '../../../graph/MapElementModal';
import { MAP_ELEMENT_TYPES } from '../../../../constants';
import {
    MapElementProvider,
    type MapElementConfigProps,
} from '../contextProviders/MapElementProvider';
import StepRouter from './StepRouter';

/**
 * Handles switching between the step configuration screens
 */
const Step = (props: MapElementConfigProps) => {
    return (
        <MapElementProvider
            {...props}
            defaultScreen={'step'}
            elementType={MAP_ELEMENT_TYPES.step}
            isLoading={false}
        >
            <MapElementModal>
                <StepRouter />
            </MapElementModal>
        </MapElementProvider>
    );
};

export default Step;
