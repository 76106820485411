/**
 * This module handles mapping url routes to the applications tabbing mechanism
 * and is exposed as a React hook.
 * There are three types of tabs that can be opened:
 *
 * - An admin tab e.g services, tenant settings
 * - A flow canvas tab
 * - A page editor tab
 *
 * An admin tab uses the following url structure: /:tenantId/:tabName?tab=:tabKey&page_name=:pageName
 * If the tab can be opened multiple times then a tab query parameter is appended to the url to make it unique
 * and therefore trigger the Router to re-render the application. If the tab is to open a new page editor,
 * then a page name query parameter is also included.
 *
 * A flow canvas tab uses the following url structure: /:tenantId/flows/:flowId
 *
 * A page editor tab uses the following url structure: /:tenantId/pages/:pageId
 */

import { useEffect, useState } from 'react';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import type { Dispatch } from 'redux';
import { closeAllTabs, openTab as openTabAction } from '../../js/actions/reduxActions/tabs';
import { tabDetailsObjects } from '../config/admin';
import { NOTIFICATION_TYPES, TAB_TYPES } from '../constants';
import translations from '../translations';
import type { AddNotification, UserRole } from '../types';
import { tryGetErrorMessage } from '../utils/error';

const openTab = ({
    slug,
    tabType,
    tabKey,
    elementId,
    queryParams,
    role,
    tenantId,
    dispatch,
}: {
    slug: string | null;
    tabType: string | null;
    tabKey: string | null;
    elementId: string | null;
    queryParams: URLSearchParams;
    role: UserRole;
    tenantId: string | null;
    dispatch: Dispatch;
}) => {
    const tabDetails = Object.values(tabDetailsObjects).find(({ type }) => type === tabType);

    if (!tabDetails) {
        throw new Error(`The tab named ${slug} is not recognised`);
    }

    if (!tabDetails.roles.includes(role)) {
        throw new Error(`You do not have permissions to view the ${slug} tab.`);
    }

    if (tabDetails.type === TAB_TYPES.newPage) {
        return dispatch(
            // @ts-expect-error tabs.js doesn't have types yet
            openTabAction({
                key: queryParams.get('tab'),
                type: tabDetails.type,
                title: queryParams.get('page_name'),
                elementId,
                tenantId,
            }),
        );
    }

    return dispatch(
        // @ts-expect-error tabs.js doesn't have types yet
        openTabAction({
            key: tabKey,
            type: tabDetails.type,
            title: tabDetails.title,
            elementId,
            tenantId,
        }),
    );
};

const getTabType = ({ slug, hasIdentifier }: { slug: string | null; hasIdentifier: boolean }) => {
    switch (slug) {
        case 'flows':
            return hasIdentifier ? TAB_TYPES.flow : TAB_TYPES.flows;
        case 'pages':
            return hasIdentifier ? TAB_TYPES.page : TAB_TYPES.pages;
        default:
            return Object.values(tabDetailsObjects).find((td) => td.slug === slug)?.type ?? slug;
    }
};

export const useTabRouting = (
    role: UserRole | undefined,
    environmentsIsOn: boolean,
    isAdministrator: boolean,
    addNotification: AddNotification,
    dispatch: Dispatch,
) => {
    const [previousTenantId, setPreviousTenantId] = useState('');
    const location = useLocation();
    const navigate = useNavigate();

    const queryParams = new URLSearchParams(location.search);
    const tenantId = useMatch('/:tenantId/*')?.params.tenantId ?? null;
    const slug = useMatch('/:tenantId/:slug/*')?.params.slug ?? null;
    const elementId = useMatch('/:tenantId/:slug/:elementId')?.params.elementId ?? null;

    const tabKey = queryParams.get('tab');
    const tabType = getTabType({ slug, hasIdentifier: elementId !== null });

    const onTabOpen = (openTabCallback: () => void, redirectTo: string) => {
        try {
            openTabCallback();
        } catch (error) {
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: tryGetErrorMessage(error),
                isPersistent: true,
            });

            navigate(`/${tenantId}/${redirectTo}`, { replace: true });
        }
    };

    useEffect(() => {
        // We need to have a reference the previous tenant ID
        // as there are conditions where hooks should not be executed
        // if the tenant ID from the previous execution does not match the current.
        if (tenantId && previousTenantId !== tenantId) {
            setPreviousTenantId(tenantId);
        }
    }, [previousTenantId, tenantId]);

    // Tab to open whenever navigating into a new tenant
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (!role) {
            return;
        }

        const fallbackUrl = tabType === TAB_TYPES.page ? 'pages' : 'flows';
        dispatch(closeAllTabs(false));

        if (!tabType) {
            return;
        }

        const canOpenTab = canOpenEnvironmentTab({
            environmentsIsOn,
            tabType,
            tenantId,
            redirectTo: fallbackUrl,
            isAdministrator,
        });

        if (!canOpenTab) {
            return;
        }

        onTabOpen(
            () =>
                openTab({
                    slug,
                    tabType,
                    tabKey,
                    elementId,
                    queryParams,
                    role,
                    tenantId,
                    dispatch,
                }),
            fallbackUrl,
        );
    }, [tenantId]);

    // Tab to open/set as active whenever navigating to an admin tab
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (!role) {
            return;
        }

        const fallbackUrl = tabType === TAB_TYPES.page ? 'pages' : 'flows';

        if (tenantId !== previousTenantId) {
            return;
        }

        const canOpenTab = canOpenEnvironmentTab({
            environmentsIsOn,
            tabType,
            tenantId,
            redirectTo: fallbackUrl,
            isAdministrator,
        });

        if (!canOpenTab) {
            return;
        }

        onTabOpen(
            () =>
                openTab({
                    slug,
                    tabType,
                    tabKey,
                    elementId,
                    queryParams,
                    role,
                    tenantId,
                    dispatch,
                }),
            fallbackUrl,
        );
    }, [tabType, tabKey, elementId]);

    const canOpenEnvironmentTab = ({
        environmentsIsOn,
        tabType,
        tenantId,
        redirectTo,
        isAdministrator,
    }: {
        environmentsIsOn: boolean;
        tabType: string | null;
        tenantId: string | null;
        redirectTo: string;
        isAdministrator: boolean;
    }) => {
        if (tabType === TAB_TYPES.environments || tabType === TAB_TYPES.environment) {
            if (!environmentsIsOn) {
                addNotification({
                    type: NOTIFICATION_TYPES.error,
                    message: translations.ENVIRONMENT_setting_turned_off,
                    isPersistent: true,
                });
                navigate(`/${tenantId}/${redirectTo}`, { replace: true });
                return false;
            }

            if (!isAdministrator) {
                addNotification({
                    type: NOTIFICATION_TYPES.error,
                    message: translations.ENVIRONMENT_must_be_admin,
                    isPersistent: true,
                });
                navigate(`/${tenantId}/${redirectTo}`, { replace: true });
                return false;
            }
        }

        return true;
    };
};
