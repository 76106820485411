import MapElementModal from '../../../graph/MapElementModal';
import { MAP_ELEMENT_TYPES } from '../../../../constants';
import {
    type MapElementConfigProps,
    MapElementProvider,
} from '../contextProviders/MapElementProvider';
import OutcomePanel from './OutcomePanel';

const Outcome = (props: MapElementConfigProps) => (
    <MapElementProvider
        {...props}
        elementType={MAP_ELEMENT_TYPES.outcome}
        isLoading={false}
        defaultScreen="outcome"
    >
        <MapElementModal>
            <OutcomePanel outcomeId={props.outcomeId} nextMapElementId={props.nextMapElementId} />
        </MapElementModal>
    </MapElementProvider>
);

export default Outcome;
