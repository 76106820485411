import { useState } from 'react';
import translations from '../../../../../ts/translations';
import ButtonDefault from '../../../../../ts/components/buttons/ButtonDefault';
import ButtonPrimary from '../../../../../ts/components/buttons/ButtonPrimary';
import { useMapElement } from '../contextProviders/MapElementProvider';
import NameInput from '../common/NameInput';
import ProcessList from './ProcessList';
import OutcomeList from '../common/outcomes/OutcomeList';
import ModalBody from '../../../generic/modal/ModalBody';
import ModalFooter from '../../../generic/modal/ModalFooter';
import { isNullOrWhitespace } from '../../../../utils/guard';
import type { MapElementRequestAPI } from '../../../../sources/graph';

const ProcessConfiguration = () => {
    const { mapElement, onSaveMapElement, onClose, onUpdateName } = useMapElement();

    const [hasSubmitted, setHasSubmitted] = useState(false);
    const isFormValid = isNullOrWhitespace(mapElement?.developerName) === false;

    const onSave = () => {
        setHasSubmitted(true);

        if (isFormValid) {
            onSaveMapElement(mapElement as MapElementRequestAPI);
        }
    };

    const renderBody = () => (
        <>
            <NameInput
                isValid={isFormValid}
                showValidation={hasSubmitted}
                id="process-name"
                name={mapElement.developerName ?? ''}
                onUpdateName={onUpdateName}
            />
            <ProcessList />
            <OutcomeList />
        </>
    );

    const renderFooter = () => (
        <>
            <ButtonDefault className="flex-child-right" onClick={onClose}>
                {translations.GRAPH_config_panel_cancel}
            </ButtonDefault>
            <ButtonPrimary className="margin-left" onClick={onSave}>
                {translations.GRAPH_config_panel_save}
            </ButtonPrimary>
        </>
    );

    return (
        <>
            <ModalBody>{renderBody()}</ModalBody>
            <ModalFooter>{renderFooter()}</ModalFooter>
        </>
    );
};

export default ProcessConfiguration;
