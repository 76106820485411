import { ExInput } from '@boomi/exosphere';
import translations from '../../../translations';
import FormGroup from '../../generic/FormGroup';
import FooterButtons from '../../generic/modal/FooterButtons';
import GenericModal from '../../generic/modal/GenericModal';
import { useOpenApiConnector } from '../contextProviders/OpenApiConnectorProvider';
import type { ListFilterMapping } from '../../../types';
import type { ChangeEventHandler } from 'react';

interface OpenApiTableConfigModalProps {
    show: boolean;
    setShow: (value: boolean) => void;
}

export const OpenApiTableConfigModal = ({ show, setShow }: OpenApiTableConfigModalProps) => {
    const { editingPath, setEditingPath, saveEditingPath } = useOpenApiConnector();
    const defaultMapping: ListFilterMapping = { listProp: 'root' };
    const responseSchemaMapping =
        editingPath?.path.responseSchema?.listFilterMapping ?? defaultMapping;
    const reset = () => {
        setShow(false);
        setEditingPath(null);
    };

    const onListPropertyChange: ChangeEventHandler<HTMLSelectElement> = ({ target: { value } }) => {
        if (!responseSchemaMapping || !editingPath) {
            return;
        }
        const editedMapping = { ...responseSchemaMapping };
        editedMapping.listProp = value;
        saveMapping(editedMapping);
    };
    const onListMappingChange = (prop: string, value: string) => {
        if (!responseSchemaMapping || !editingPath) {
            return;
        }
        const editedMapping = { ...responseSchemaMapping };
        editedMapping[prop as keyof typeof responseSchemaMapping] = value;
        saveMapping(editedMapping);
    };

    const saveMapping = (mapping: ListFilterMapping) => {
        if (!editingPath) {
            return;
        }
        setEditingPath({
            ...editingPath,
            path: {
                ...editingPath.path,
                responseSchema: {
                    ...editingPath.path.responseSchema,
                    listFilterMapping: mapping,
                },
            },
        });
    };

    const onSave = () => {
        saveEditingPath();
        reset();
    };
    return (
        <>
            <GenericModal
                title={translations.OPENAPI_table_configuration}
                renderBody={() => (
                    <>
                        <FormGroup>
                            <>
                                <h4 className="auto-margin-top">
                                    {translations.OPENAPI_list_property}
                                </h4>
                                <select
                                    id="list-property-select"
                                    autoFocus
                                    value={responseSchemaMapping?.listProp ?? ''}
                                    onChange={onListPropertyChange}
                                    required
                                    className="form-control form-control-width"
                                >
                                    <option key="root" value="root">
                                        {translations.OPENAPI_root_property}
                                    </option>
                                    {editingPath?.path.responseSchema?.properties?.map(
                                        (operationType) => (
                                            <option key={operationType} value={operationType}>
                                                {operationType}
                                            </option>
                                        ),
                                    )}
                                </select>
                                <span className="help-block">
                                    {translations.OPENAPI_property_help}
                                </span>
                                <h4>{translations.OPENAPI_list_filter_properties}</h4>
                                <p>
                                    {translations.OPENAPI_list_filter_help}
                                    <br />
                                    {translations.OPENAPI_list_filter_help_example}
                                </p>
                                <ExInput
                                    id="filter-id"
                                    label={translations.OPENAPI_id}
                                    className="form-control-width"
                                    required={false}
                                    type="text"
                                    value={responseSchemaMapping?.id ?? ''}
                                    data-testId="filter-id"
                                    onInput={(e: Event) =>
                                        onListMappingChange(
                                            'id',
                                            (e.currentTarget as HTMLInputElement).value,
                                        )
                                    }
                                />
                                <ExInput
                                    id="filter-search"
                                    label={translations.OPENAPI_search}
                                    className="form-control-width"
                                    required={false}
                                    type="text"
                                    value={responseSchemaMapping?.search ?? ''}
                                    data-testId="filter-search"
                                    onInput={(e: Event) =>
                                        onListMappingChange(
                                            'search',
                                            (e.currentTarget as HTMLInputElement).value,
                                        )
                                    }
                                />
                                <ExInput
                                    id="filter-order-by"
                                    label={translations.OPENAPI_order_by}
                                    className="form-control-width"
                                    required={false}
                                    type="text"
                                    value={responseSchemaMapping?.orderBy ?? ''}
                                    data-testId="filter-order-by"
                                    onInput={(e: Event) =>
                                        onListMappingChange(
                                            'orderBy',
                                            (e.currentTarget as HTMLInputElement).value,
                                        )
                                    }
                                />
                                <ExInput
                                    id="filter-order-direction"
                                    label={translations.OPENAPI_order_direction}
                                    className="form-control-width"
                                    required={false}
                                    type="text"
                                    value={responseSchemaMapping?.orderDirection ?? ''}
                                    data-testId="filter-order-direction"
                                    onInput={(e: Event) =>
                                        onListMappingChange(
                                            'orderDirection',
                                            (e.currentTarget as HTMLInputElement).value,
                                        )
                                    }
                                />
                                <ExInput
                                    id="filter-offset"
                                    label={translations.OPENAPI_offset}
                                    className="form-control-width"
                                    required={false}
                                    type="text"
                                    value={responseSchemaMapping?.offset ?? ''}
                                    data-testId="filter-offset"
                                    onInput={(e: Event) =>
                                        onListMappingChange(
                                            'offset',
                                            (e.currentTarget as HTMLInputElement).value,
                                        )
                                    }
                                />
                                <ExInput
                                    id="filter-limit"
                                    label={translations.OPENAPI_limit}
                                    className="form-control-width"
                                    required={false}
                                    type="text"
                                    value={responseSchemaMapping?.limit ?? ''}
                                    data-testId="filter-limit"
                                    onInput={(e: Event) =>
                                        onListMappingChange(
                                            'limit',
                                            (e.currentTarget as HTMLInputElement).value,
                                        )
                                    }
                                />
                            </>
                        </FormGroup>
                    </>
                )}
                renderFooter={() => (
                    <FooterButtons
                        confirm={onSave}
                        cancel={reset}
                        cancelButtonText={translations.COMMON_close}
                    />
                )}
                show={show}
                dialogClassName="modal-large openapi-table-config-modal"
                onHide={reset}
            />
        </>
    );
};
