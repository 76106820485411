import { useEffect, useRef, useState, type ElementRef, type MouseEventHandler } from 'react';
import { connect, type ConnectedProps } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import '../../../../css/tenant.less';
import {
    fetchCurrentTenant,
    saveTenant,
    updateSummary,
    updateTenantSettingEnvironment,
    updateTenantSettingThemes,
} from '../../../js/actions/reduxActions/currentTenant';
import { notifyError } from '../../../js/actions/reduxActions/notification';
import { closeTab } from '../../../js/actions/reduxActions/tabs';
import OtlpSettings from './OtlpSettings';
import { TAB_TYPES, type TENANT_VIEWS } from '../../constants';
import { useScrollSpy } from '../../hooks/scroll';
import translations from '../../translations';
import type { Tab } from '../../types';
import type { TenantResponseAPI } from '../../types/Tenant';
import { useAuth } from '../AuthProvider';
import FormGroup from '../generic/FormGroup';
import Toggle from '../inputs/Toggle';
import Loader from '../loader/Loader';
import DeveloperName from './DeveloperName';
import ExportTenant from './ExportTenant';
import ImportTenant from './ImportTenant';
import IntegrationAccounts from './IntegrationAccounts';
import Reporting from './Reporting';
import Security from './Security';
import SubtenantList from './SubtenantList';
import UserList from './UserList';
import SAMLSettings from './saml/SAMLSettings';
import { useFeatureFlag } from '../../../ts/components/featureFlags/FeatureFlagProvider';

const mapStateToProps = ({
    currentTenant,
    tabs,
}: {
    currentTenant: TenantResponseAPI | null | undefined;
    tabs: Tab[] | null | undefined;
}) => ({
    currentTenant,
    tabs,
});

const mapDispatchToProps = {
    fetchCurrentTenant,
    updateSummary,
    updateTenantSettingEnvironment,
    updateTenantSettingThemes,
    saveTenant,
    notifyError,
    closeTab,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type TenantProps = ConnectedProps<typeof connector> & {
    navigateTo: (view: keyof typeof TENANT_VIEWS) => void;
    setActiveSectionId: (id: string | null) => void;
    hasMenu: boolean;
};

const Tenant = ({
    currentTenant,
    navigateTo,
    setActiveSectionId,
    hasMenu,
    fetchCurrentTenant,
    updateSummary,
    saveTenant,
    notifyError,
    updateTenantSettingEnvironment,
    updateTenantSettingThemes,
    closeTab,
    tabs,
}: TenantProps) => {
    const { user, fetchUser, tenant: authorizedTenant } = useAuth();
    const {
        developerName,
        securitySettings,
        developerSummary,
        id: tenantId,
        observabilitySettings,
        parentTenant,
    } = currentTenant || {};

    const [isLoading, setIsLoading] = useState(false);
    const contentRef = useRef<HTMLDivElement>(null);
    const [environments, setEnvironments] = useState(
        !!authorizedTenant?.tenantSettings?.environments,
    );
    const [themes, setThemes] = useState(!!authorizedTenant?.tenantSettings?.themes);
    const [isConfigValid, setIsConfigValid] = useState(true);
    const { getFlag } = useFeatureFlag();

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                await fetchCurrentTenant(authorizedTenant?.id);
            } catch (error) {
                notifyError(error);
            } finally {
                setIsLoading(false);
            }
        })();
    }, [fetchCurrentTenant, authorizedTenant?.id, notifyError]);

    const navigate = useNavigate();

    // using a scrollspy based on the intersection observer to report back to
    // the side-menu the currently active section so the side-menu can update
    // itself (every content page with a side-menu will have to implement this
    // hook)
    useScrollSpy(contentRef, hasMenu, setActiveSectionId);

    const handleSave: MouseEventHandler<ElementRef<'button'>> = () => {
        if (!isConfigValid) {
            return;
        }

        updateTenantSettingEnvironment(environments);
        updateTenantSettingThemes(themes);
        saveTenant(tenantId, fetchUser);

        if (!tabs) {
            return;
        }

        for (const { key, type } of tabs) {
            if (
                !environments &&
                (type === TAB_TYPES.environment || type === TAB_TYPES.environments)
            ) {
                closeTab(key, tenantId, navigate);
            }

            if (type === (themes ? TAB_TYPES.players : TAB_TYPES.themes)) {
                closeTab(key, tenantId, navigate);
            }
        }
    };

    if (!(tenantId && user?.id && developerName)) {
        return null;
    }

    return (
        <>
            <div className="admin-page tenant-page" ref={contentRef}>
                {isLoading ? (
                    <Loader />
                ) : (
                    <>
                        <section data-testid="tenant-name-section">
                            <h2 id="tenant-name">{translations.TENANT_tenant_name_heading_text}</h2>
                            <DeveloperName setIsConfigValid={setIsConfigValid} />
                        </section>
                        <section data-testid="tenant-users-section">
                            <h2 id="tenant-users">
                                {translations.TENANT_tenant_users_heading_text}
                            </h2>
                            <p>{translations.TENANT_user_description}</p>
                            <UserList userId={user.id} />
                        </section>
                        <section data-testid="tenant-environment-section">
                            <h2 id="tenant-environment">{translations.ENVIRONMENT_name}</h2>
                            <p>{translations.ENVIRONMENT_description}</p>
                            <FormGroup
                                htmlFor="environments-setting"
                                label={translations.ENVIRONMENT_toggle_message}
                                labelPosition="after"
                            >
                                <Toggle
                                    id="environments-setting"
                                    isOn={environments}
                                    onChange={({ isOn }) => setEnvironments(isOn)}
                                    testId="environment-setting"
                                    isDisabled={
                                        (currentTenant?.hasMcr && !getFlag('MCRENV')) ?? false
                                    }
                                />
                            </FormGroup>
                            {currentTenant?.hasMcr && !getFlag('MCRENV') ? (
                                <p data-testid="environment-toggle-disabled-message">
                                    {translations.ENVIRONMENT_toggle_disabled}
                                </p>
                            ) : null}
                        </section>
                        <section data-test="tenant-themes-section">
                            <h2 id="tenant-themes">{translations.TENANT_theme_heading}</h2>
                            <p>{translations.TENANT_theme_description}</p>
                            <FormGroup
                                htmlFor="theme-setting"
                                label={translations.TENANT_toggle_message}
                                labelPosition="after"
                            >
                                <Toggle
                                    id="theme-setting"
                                    isOn={themes}
                                    onChange={({ isOn }) => setThemes(isOn)}
                                    testId="theme-setting"
                                />
                            </FormGroup>
                        </section>
                        <section data-testid="tenant-integration-accounts-section">
                            <h2 id="tenant-integration-accounts">
                                {translations.TENANT_integration_accounts}
                            </h2>
                            <IntegrationAccounts />
                        </section>
                        {!parentTenant && (
                            <section data-testid="tenant-subtenants-section">
                                <h2 id="tenant-subtenants">
                                    {translations.TENANT_subtenants_heading_text}
                                </h2>
                                <p>
                                    {translations.TENANT_subtenant_description_1}
                                    <br />
                                    {translations.TENANT_subtenant_description_2}
                                </p>
                                <SubtenantList navigateTo={navigateTo} tenantId={tenantId} />
                            </section>
                        )}
                        <section data-testid="tenant-single-sign-on-section">
                            <h2 id="tenant-single-sign-on">
                                {translations.TENANT_sso_heading_text}
                            </h2>
                            <p>{translations.TENANT_sso_description}</p>
                            <SAMLSettings
                                isActive={!!securitySettings?.isSamlEnabled}
                                settings={securitySettings?.samlSettings ?? null}
                                isSso={!!authorizedTenant?.isSso}
                            />
                        </section>
                        <section data-testid="tenant-security-section">
                            <h2 id="tenant-security">
                                {translations.TENANT_security_heading_text}
                            </h2>
                            <p>{translations.TENANT_security_description}</p>
                            <Security />
                        </section>
                        <section data-testid="tenant-reporting-section">
                            <h2 id="tenant-reporting">
                                {translations.TENANT_reporting_heading_text}
                            </h2>
                            <p>{translations.TENANT_reporting_description}</p>
                            <Reporting />
                        </section>
                        <section data-testid="tenant-observability-section">
                            <h2 id="tenant-observability">Observability</h2>
                            <OtlpSettings
                                settings={{
                                    otlpEndpoint: observabilitySettings?.otlpEndpoint ?? null,
                                    otlpHeaders: observabilitySettings?.otlpHeaders ?? null,
                                    otlpSensitiveDataLogging:
                                        observabilitySettings?.otlpSensitiveDataLogging ?? null,
                                    otlpProtocol: observabilitySettings?.otlpProtocol ?? null,
                                }}
                            />
                        </section>
                        <section data-testid="tenant-export-section">
                            <h2 id="tenant-export">Export</h2>
                            <ExportTenant
                                tenantId={tenantId}
                                tenantName={developerName}
                                notifyError={notifyError}
                            />
                        </section>
                        <section data-testid="tenant-import-section">
                            <h2 id="tenant-import">Import</h2>
                            <ImportTenant
                                tenantId={tenantId}
                                tenantName={developerName}
                                notifyError={notifyError}
                            />
                        </section>
                        <section data-testid="tenant-comments-section">
                            <h2 id="tenant-comments">
                                {translations.TENANT_tenant_comments_heading_text}
                            </h2>
                            <FormGroup
                                htmlFor="developer-summary"
                                label={translations.TENANT_developer_summary_label}
                            >
                                <textarea
                                    id="developer-summary"
                                    maxLength={1000}
                                    rows={3}
                                    className="form-control tenant-comments"
                                    value={developerSummary ?? ''}
                                    onChange={updateSummary}
                                />
                            </FormGroup>
                        </section>
                    </>
                )}
            </div>
            <div className="admin-footer">
                <button
                    type="button"
                    className="btn btn-primary outcome tenant-save"
                    onClick={handleSave}
                >
                    {translations.TENANT_save_settings_button_label}
                </button>
            </div>
        </>
    );
};

export default connector(Tenant);
