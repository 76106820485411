import { useEffect, useState } from 'react';
import ButtonDefault from '../../../buttons/ButtonDefault';
import ButtonPrimary from '../../../buttons/ButtonPrimary';
import FormGroup from '../../../generic/FormGroup';
import Loader from '../../../loader/Loader';
import screens from '../common/screens';
import { useMapElement } from '../contextProviders/MapElementProvider';
import { useOperator } from './OperatorProvider';
import ModalFooter from '../../../generic/modal/ModalFooter';
import ModalBody from '../../../generic/modal/ModalBody';
import { macroSource } from '../../../../sources/macro';
import type { MacroElementResponseAPI, OperationAPI } from '../../../../types';
import { isNullOrUndefined } from '../../../../utils';
import { createOperationAPI } from './utils';

const MacroDetails = () => {
    const { mapElement, onSwitchScreen, notifyError, setConfigTitle } = useMapElement();

    const { selectedOperationIndex, setSelectedOperationIndex } = useOperator();

    const [macros, setMacros] = useState<MacroElementResponseAPI[]>([]);
    const [isLoadingMacros, setIsLoadingMacros] = useState(false);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [selectedMacroId, setSelectedMacroId] = useState(
        typeof selectedOperationIndex === 'number'
            ? (mapElement.operations ?? [])[selectedOperationIndex]?.macroElementToExecuteId
            : null,
    );

    const isValid = selectedMacroId !== null;

    const onSave = () => {
        setHasSubmitted(true);
        if (isValid) {
            if (isNullOrUndefined(mapElement.operations)) {
                mapElement.operations = [];
            }
            const index = selectedOperationIndex ?? mapElement.operations.length;
            const operation: OperationAPI = createOperationAPI({
                order: index,
                disabled: false,
                macroElementToExecuteId: selectedMacroId,
                macroElementToExecuteDeveloperName:
                    macros.find((macro) => macro.id === selectedMacroId)?.developerName ?? null,
            });

            mapElement.operations[index] = operation;

            onSwitchScreen(screens.operator);
        }
    };

    const onCancel = () => {
        onSwitchScreen(screens.operator);
        setSelectedOperationIndex(null);
    };

    const getMacros = async () => {
        try {
            const response = await macroSource.getAll();

            setMacros(response);
        } catch (error) {
            notifyError(error);
        }
    };

    const renderBody = () => {
        if (isLoadingMacros) {
            return <Loader message="Loading Macros" />;
        }

        return (
            <FormGroup
                label="Which Macro do you want to run?"
                isRequired
                isValid={isValid}
                validationMessage="Please select a macro to run."
                showValidation={hasSubmitted}
                htmlFor="macro-select"
            >
                <select
                    id="macro-select"
                    data-testid="macro-select"
                    className="form-control form-select form-control-width"
                    value={selectedMacroId ?? ''}
                    onChange={({ target: { value } }) => setSelectedMacroId(value)}
                >
                    {selectedMacroId === null && <option value="">Select a Macro</option>}
                    {macros.map((macro) => (
                        <option value={macro.id} key={macro.id}>
                            {macro.developerName}
                        </option>
                    ))}
                </select>
            </FormGroup>
        );
    };

    useEffect(() => {
        setConfigTitle(`Macro for: ${mapElement.developerName}`);

        return () => setConfigTitle(null);
    }, [mapElement.developerName, setConfigTitle]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        setIsLoadingMacros(true);
        (async () => {
            await getMacros();
            setIsLoadingMacros(false);
        })();
    }, []);

    return (
        <>
            <ModalBody>{renderBody()}</ModalBody>
            <ModalFooter>
                <ButtonDefault className="flex-child-right" onClick={onCancel}>
                    Cancel
                </ButtonDefault>
                <ButtonPrimary className="margin-left" onClick={onSave}>
                    {selectedOperationIndex === null ? 'Add' : 'Update'}
                </ButtonPrimary>
            </ModalFooter>
        </>
    );
};

export default MacroDetails;
