import { useMapElement } from '../contextProviders/MapElementProvider';
import StepConfiguration from './StepConfiguration';
import NavigationOverrideDetails from '../common/navigationOverrides/NavigationOverrideDetails';
import OutcomeDetails from '../common/OutcomeDetails';
import screens from '../common/screens';

/**
 * Handles switching between the step configuration screens
 */
const StepRouter = () => {
    const {
        currentScreen,
        mapElement,
        selectedOutcomeIndex,
        setSelectedOutcomeIndex,
        onSwitchScreen,
    } = useMapElement();

    switch (currentScreen) {
        case screens.step:
            return <StepConfiguration />;

        case screens.navigationOverrides:
            return <NavigationOverrideDetails />;

        case screens.outcome: {
            const selectedOutcome =
                typeof selectedOutcomeIndex === 'number' &&
                mapElement?.outcomes?.[selectedOutcomeIndex]
                    ? mapElement?.outcomes?.[selectedOutcomeIndex]
                    : null;

            return (
                <OutcomeDetails
                    outcome={selectedOutcome}
                    onSave={() => onSwitchScreen(screens.step)}
                    onCancel={() => {
                        onSwitchScreen(screens.step);
                        setSelectedOutcomeIndex(null);
                    }}
                />
            );
        }

        default:
            return null;
    }
};

export default StepRouter;
