import { FILTER_TYPES } from '../../constants';
import FormGroup from '../../../../../generic/FormGroup';
import translations from '../../../../../../translations';
import { useDataAction } from '../../../contextProviders/DataActionProvider';

const FilterTypeDropdown = ({ onChange }: { onChange: (filterType: string) => void }) => {
    const { filterToEdit } = useDataAction();

    const isFilteredById = filterToEdit?.filterId;
    const isFilteredByWhereClause = filterToEdit?.where && !filterToEdit?.filterId;

    let filterType = FILTER_TYPES.noFilter.value;

    if (isFilteredById) {
        filterType = FILTER_TYPES.filterById.value;
    }

    if (isFilteredByWhereClause) {
        filterType = FILTER_TYPES.filterByWhereClause.value;
    }

    return (
        <FormGroup
            label={translations.DATA_ACTION_filter_type_label}
            htmlFor="filter-type"
            isRequired
        >
            <select
                id="filter-type"
                defaultValue={filterType}
                onChange={(e) => onChange(e.target.value)}
                className="form-control form-control-width"
            >
                <option value={FILTER_TYPES.noFilter.value}>{FILTER_TYPES.noFilter.label}</option>
                <option value={FILTER_TYPES.filterById.value}>
                    {FILTER_TYPES.filterById.label}
                </option>
                <option value={FILTER_TYPES.filterByWhereClause.value}>
                    {FILTER_TYPES.filterByWhereClause.label}
                </option>
            </select>
        </FormGroup>
    );
};

export default FilterTypeDropdown;
