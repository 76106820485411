import translations from '../../../ts/translations';
import openapiLogo from '../../../../img/serviceIcons/OpenAPI-logo.svg';
import samlLogo from '../../../../img/serviceIcons/SAML-logo.svg';
import azureLogo from '../../../../img/serviceIcons/Azure-logo.svg';
import emailLogo from '../../../../img/serviceIcons/Email-logo.svg';
import hubLogo from '../../../../img/serviceIcons/Hub-logo.svg';
import oktaLogo from '../../../../img/serviceIcons/Okta-logo.svg';
import pdfLogo from '../../../../img/serviceIcons/PDF-logo.svg';
import sqlLogo from '../../../../img/serviceIcons/SQL-logo.svg';
import graphQlLogo from '../../../../img/serviceIcons/GraphQL-logo.svg';
import salesforceLogo from '../../../../img/serviceIcons/Salesforce-logo.svg';
import awsLogo from '../../../../img/serviceIcons/AWS-logo.svg';
import timerLogo from '../../../../img/serviceIcons/Timer-logo.svg';
import customLogo from '../../../../img/serviceIcons/Custom-logo.svg';
import integrationLogo from '../../../../img/serviceIcons/Integration-logo.svg';
import type { ContentType } from '../../types';

export type BreadcrumbLabels =
    | 'New Connector'
    | 'Edit Connector'
    | 'Delete Connector'
    | 'Configure Connector'
    | 'Preview Types and Actions'
    | 'Configure OpenAPI';

export type ServiceViews =
    | 'ServiceList'
    | 'ServiceEditor'
    | 'ServicePreview'
    | 'ServiceConfiguration'
    | 'OpenApiConfiguration'
    | 'NewServiceSelect'
    | 'NewFlowService'
    | 'NewIntegrationService'
    | 'NewCustomService';

export type IntegrationServiceAuthTypes = 'BASIC' | 'CLIENT_CERTIFICATE';

export const OTHER_ATOM_CLOUD = 'Other';

export const ATOM_CLOUDS = [
    {
        name: 'The Boomi USA East Integration Cloud 1',
        baseUrl: 'https://c01-usa-east.integrate.boomi.com',
    },
    {
        name: 'The Boomi USA East Integration Cloud 2',
        baseUrl: 'https://c02-usa-east.integrate.boomi.com',
    },
    {
        name: 'The Boomi USA East Integration Cloud (ET) 1',
        baseUrl: 'https://c01-usa-east-et.integrate.boomi.com',
    },
    {
        name: 'The Boomi USA East Integration Cloud (ET) 2',
        baseUrl: 'https://c02-usa-east-et.integrate.boomi.com',
    },
    {
        name: 'The Boomi USA East Integration Cloud (ET) 3',
        baseUrl: 'https://c03-usa-east-et.integrate.boomi.com',
    },
    {
        name: 'The Boomi USA East Integration Cloud (ET) 4',
        baseUrl: 'https://c04-usa-east-et.integrate.boomi.com',
    },
    {
        name: 'The Boomi USA West Integration Cloud 1',
        baseUrl: 'https://c01-usa-west.integrate.boomi.com',
    },
    {
        name: 'The Boomi USA West Integration Cloud 2',
        baseUrl: 'https://c02-usa-west.integrate.boomi.com',
    },
    {
        name: 'The Boomi USA East Integration Test Cloud',
        baseUrl: 'https://c01-usa-east.integrate-test.boomi.com',
    },
    {
        name: 'The Boomi USA East Integration Test Cloud (ET) 1',
        baseUrl: 'https://c01-usa-east-et.integrate-test.boomi.com',
    },
    {
        name: 'The Boomi USA East Integration Test Cloud (ET) 2',
        baseUrl: 'https://c02-usa-east-et.integrate-test.boomi.com',
    },
    {
        name: 'The Boomi USA East Integration Test Cloud (ET) 3',
        baseUrl: 'https://c03-usa-east-et.integrate-test.boomi.com',
    },
    {
        name: 'The Boomi USA East Integration Test Cloud (ET) 4',
        baseUrl: 'https://c04-usa-east-et.integrate-test.boomi.com',
    },
    {
        name: 'The Boomi USA West Integration Test Cloud 1',
        baseUrl: 'https://c01-usa-west.integrate-test.boomi.com',
    },
    {
        name: 'The Boomi USA West Integration Test Cloud 2',
        baseUrl: 'https://c02-usa-west.integrate-test.boomi.com',
    },
    {
        name: 'The Boomi Canada Integration Cloud',
        baseUrl: 'https://c01-ca.integrate.boomi.com',
    },
    {
        name: 'The Boomi Canada Integration Test Cloud',
        baseUrl: 'https://c01-ca.integrate-test.boomi.com',
    },
    {
        name: 'The Boomi GBR Integration Cloud',
        baseUrl: 'https://c01-gbr.integrate.boomi.com',
    },
    {
        name: 'The Boomi GBR Integration Test Cloud',
        baseUrl: 'https://c01-gbr.integrate-test.boomi.com',
    },
    {
        name: 'The Boomi DEU Integration Cloud',
        baseUrl: 'https://c01-deu.integrate.boomi.com',
    },
    {
        name: 'The Boomi DEU Integration Test Cloud',
        baseUrl: 'https://c01-deu.integrate-test.boomi.com',
    },
    {
        name: 'The Boomi ANZ Integration Cloud',
        baseUrl: 'https://c01-aus.integrate.boomi.com',
    },
    {
        name: 'The Boomi ANZ Integration Test Cloud',
        baseUrl: 'https://c01-aus.integrate-test.boomi.com',
    },
    {
        name: 'The Boomi Singapore Integration Cloud',
        baseUrl: 'https://c01-sg.integrate.boomi.com',
    },
    {
        name: 'The Boomi Singapore Integration Test Cloud',
        baseUrl: 'https://c01-sg.integrate-test.boomi.com',
    },
    {
        name: 'The Boomi US Atom Cloud (Legacy) 1',
        baseUrl: 'https://connect.boomi.com',
    },
    {
        name: 'The Boomi US Atom Cloud (Legacy) 2',
        baseUrl: 'https://b2b.connect.boomi.com',
    },
    {
        name: 'The Boomi US Test Atom Cloud (Legacy)',
        baseUrl: 'https://test.connect.boomi.com',
    },
    {
        name: 'The Boomi GBR Integration Cloud (Legacy)',
        baseUrl: 'https://eu.connect.boomi.com',
    },
    {
        name: 'The Boomi EU Test Atom Cloud (Legacy)',
        baseUrl: 'https://eu.test.connect.boomi.com',
    },
];

export const SERVICE_URLS = {
    nextGenEmail: 'flow://email',
    nextGenSQL: 'flow://sql',
    nextGenPDF: 'flow://pdf',
    openapi: 'flow://openapi',
    graphql: 'flow://graphql',
    atomsphere: 'flowv2://atomsphere',
    openai: 'flowv2://openai',
    s3: 'https://services.manywho.com/api/aws/s3/1',
    azure: 'https://services.manywho.com/api/azure/1',
    email: 'https://services.manywho.com/api/email/1',
    hub: 'https://services.manywho.com/api/mdh/1',
    okta: 'https://services.manywho.com/api/okta/1',
    pdf: 'https://services.manywho.com/api/pdf/1',
    runtime: 'https://flow.manywho.com/plugins/manywho/api/run/1',
    salesforce: 'https://salesforce.manywho.com/plugins/api/salesforce/1',
    saml: 'https://services.manywho.com/api/saml/1',
    sql: 'https://services.manywho.com/api/sql/2',
    timer: 'https://services.manywho.com/api/timer/1',
};

export const SERVICE_ICONS = {
    [SERVICE_URLS.openapi]: { src: openapiLogo, alt: translations.SC_openapi_logo_alt_text },
    [SERVICE_URLS.saml]: { src: samlLogo, alt: translations.SC_saml_logo_alt_text },
    [SERVICE_URLS.azure]: { src: azureLogo, alt: translations.SC_azure_logo_alt_text },
    [SERVICE_URLS.email]: { src: emailLogo, alt: translations.SC_email_logo_alt_text },
    [SERVICE_URLS.nextGenEmail]: { src: emailLogo, alt: translations.SC_email_logo_alt_text },
    [SERVICE_URLS.hub]: { src: hubLogo, alt: translations.SC_hub_logo_alt_text },
    [SERVICE_URLS.okta]: { src: oktaLogo, alt: translations.SC_okta_logo_alt_text },
    [SERVICE_URLS.pdf]: { src: pdfLogo, alt: translations.SC_pdf_logo_alt_text },
    [SERVICE_URLS.graphql]: { src: graphQlLogo, alt: translations.SC_graphql_logo_alt_text },
    [SERVICE_URLS.atomsphere]: {
        src: integrationLogo,
        alt: translations.SC_integration_logo_alt_text,
    },
    [SERVICE_URLS.nextGenPDF]: { src: pdfLogo, alt: translations.SC_pdf_logo_alt_text },
    [SERVICE_URLS.sql]: { src: sqlLogo, alt: translations.SC_sql_logo_alt_text },
    [SERVICE_URLS.nextGenSQL]: { src: sqlLogo, alt: translations.SC_sql_logo_alt_text },
    [SERVICE_URLS.salesforce]: {
        src: salesforceLogo,
        alt: translations.SC_salesforce_logo_alt_text,
    },
    [SERVICE_URLS.s3]: { src: awsLogo, alt: translations.SC_s3_logo_alt_text },
    [SERVICE_URLS.timer]: { src: timerLogo, alt: translations.SC_timer_logo_alt_text },
    integration: { src: integrationLogo, alt: translations.SC_integration_logo_alt_text },
    default: { src: customLogo, alt: translations.SC_custom_logo_alt_text },
};

export const SUPPORTED_SERVICES = [
    {
        name: 'Boomi Integration Service',
        view: 'NewIntegrationService',
        canHaveIdentity: false,
    },
    {
        name: 'Boomi DataHub',
        techPreview: true,
        url: SERVICE_URLS.hub,
        description: <p>Connect flows to a Boomi DataHub Repository.</p>,
        configuration: [
            {
                name: 'Hub Hostname',
                contentType: 'ContentString' as ContentType,
                required: true,
                default: null,
                description: 'The host name or IP address of the repository',
            },
            {
                name: 'Hub Token',
                contentType: 'ContentPassword' as ContentType,
                required: true,
                default: null,
                description:
                    'The authentication token for the account’s interactions with the repository. A repository’s authentication token is automatically generated when the repository is created.',
            },
            {
                name: 'Hub Username',
                contentType: 'ContentString' as ContentType,
                required: true,
                default: null,
                description:
                    'The username for the account’s interactions with the repository. The prefix is the account ID.',
            },
        ],
        view: 'NewFlowService',
        canHaveIdentity: false,
    },
    {
        name: 'Custom Connector',
        view: 'NewCustomService',
        canHaveIdentity: false,
    },
    {
        name: 'Email',
        techPreview: false,
        url: SERVICE_URLS.nextGenEmail,
        // TSX files don't need React for JSX to work, so if React is imported and not explicitly used
        // TS will complain about it - TS6133: 'React' is declared but its value is never read.
        // However, if React is not imported, the Tooling won't work and fails to load
        // with this error - Uncaught ReferenceError: React is not defined.
        // So we are wrapping this bit of JSX in React.Fragment to make things work and keep both
        // TS and ESBuild happy.
        description: (
            <>
                <p>Send emails from your flows.</p>
            </>
        ),
        configuration: [
            {
                name: 'Password',
                contentType: 'ContentString' as ContentType,
                required: false,
                default: null,
                description: 'Your password',
            },
            {
                name: 'Port',
                contentType: 'ContentNumber' as ContentType,
                required: true,
                default: null,
                description: 'e.g. 587',
            },
            {
                name: 'Host',
                contentType: 'ContentString' as ContentType,
                required: true,
                default: null,
                description: 'host e.g. smtp.gmail.com',
            },
            {
                name: 'Transport',
                contentType: 'ContentString' as ContentType,
                required: true,
                default: null,
                description: 'valid values are: plain, ssl and tls',
            },
            {
                name: 'Username',
                contentType: 'ContentString' as ContentType,
                required: false,
                default: null,
                description: 'username e.g. example@yourdomain.com',
            },
        ],
        view: 'NewFlowService',
        canHaveIdentity: false,
    },
    {
        name: 'GraphQL',
        techPreview: false,
        url: SERVICE_URLS.graphql,
        description: <p>Connect flows to a GraphQL endpoint to perform actions</p>,
        configuration: [],
        view: 'NewFlowService',
        canHaveIdentity: true,
    },
    {
        name: 'Atomsphere',
        techPreview: false,
        url: SERVICE_URLS.atomsphere,
        description: <p>Connect flows to the Atomsphere API</p>,
        configuration: [],
        view: 'NewFlowService',
        canHaveIdentity: true,
    },
    {
        name: 'OpenAPI',
        techPreview: true,
        url: SERVICE_URLS.openapi,
        description: (
            <p>
                The Flow OpenAPI connector allows you to connect to and perform actions and
                operations on RESTful APIs.
            </p>
        ),
        configuration: [
            {
                name: 'Schema URL',
                contentType: 'ContentString' as ContentType,
                required: false,
                default: null,
                description:
                    'The complete path (URL) to a valid OpenAPI document, written in YAML, describing all of the required information about the target service. This can be an http or https address.',
            },
            {
                name: 'Schema Value',
                contentType: 'ContentCode' as ContentType,
                required: false,
                default: null,
                description:
                    'A valid OpenAPI document, written in YAML, describing all of the required information about the target service.',
            },
            {
                name: 'OAuth2/OIDC: Client Id',
                contentType: 'ContentString' as ContentType,
                required: false,
                default: null,
                description:
                    'Specify the Client ID to use for OAuth 2.0 or OIDC based authentication.',
            },
            {
                name: 'OAuth2/OIDC: Client Secret',
                contentType: 'ContentString' as ContentType,
                required: false,
                default: null,
                description:
                    'Specify the Client Secret to use for OAuth 2.0 or OIDC based authentication.',
            },
            {
                name: 'Authorization Groups',
                contentType: 'ContentString' as ContentType,
                required: false,
                default: null,
                description: '',
            },
            {
                name: 'Authorization Users',
                contentType: 'ContentString' as ContentType,
                required: false,
                default: null,
                description: '',
            },
            {
                name: 'API Key',
                contentType: 'ContentString' as ContentType,
                required: false,
                default: null,
                description:
                    'Specify an API key to use if API key based authentication is required.',
            },
        ],
        view: 'NewFlowService',
        canHaveIdentity: false,
    },
    {
        name: 'PDF',
        techPreview: false,
        url: SERVICE_URLS.nextGenPDF,
        description: <p>Allows your flows to work with PDF documents and forms.</p>,
        configuration: [],
        view: 'NewFlowService',
        canHaveIdentity: false,
    },
    {
        name: 'Salesforce',
        techPreview: false,
        url: 'https://salesforce.manywho.com/plugins/api/salesforce/1',
        description: <p>Integrate your flows with Salesforce, including authentication.</p>,
        configuration: [
            {
                name: 'AuthenticationUrl',
                contentType: 'ContentString' as ContentType,
                required: true,
                default: null,
                description:
                    'For developer edition and production accounts, this is: https://login.salesforce.com For sandboxes, this may be https://text.salesforce.com If you’re using your own domain, this would be the full URL of your own domain.',
            },
            {
                name: 'Username',
                contentType: 'ContentString' as ContentType,
                required: true,
                default: null,
                description:
                    'Your username or the username of an API user account. This is needed for design time access.',
            },
            {
                name: 'Password',
                contentType: 'ContentPassword' as ContentType,
                required: true,
                default: null,
                description:
                    'Your password or the password of an API user account. This is needed for design time access.',
            },
            {
                name: 'ChatterBaseUrl',
                contentType: 'ContentString' as ContentType,
                required: true,
                default: null,
                description:
                    'This is the address of your Salesforce instance captured in the instructions above e.g. https://na30.salesforce.com',
            },
            {
                name: 'AdminEmail',
                contentType: 'ContentString' as ContentType,
                required: true,
                default: null,
                description: 'Likely your email address.',
            },
            {
                name: 'SecurityToken',
                contentType: 'ContentString' as ContentType,
                required: false,
                default: null,
                description:
                    'Your security token or the security token of an API user account. This can be needed for design time access and when SuperUser Strategy is used.',
            },
            {
                name: 'Consumer Secret',
                contentType: 'ContentPassword' as ContentType,
                required: false,
                default: null,
                description:
                    'If you wish to leverage OAuth2 authentication, this is the Consumer Secret value you captured in the instructions above.',
            },
            {
                name: 'Consumer Key',
                contentType: 'ContentString' as ContentType,
                required: false,
                default: null,
                description:
                    'If you wish to leverage OAuth2 authentication, this is the Consumer Key value you captured in the instructions above.',
            },
            {
                name: 'Authentication Strategy',
                contentType: 'ContentString' as ContentType,
                required: false,
                default: 'Standard',
                description:
                    'Indicates how the connector will get the credentials to interact with Salesforce. Valid values are Standard (or not provided/blank), ActiveUser and SuperUser',
            },
            {
                name: 'Default From Email',
                contentType: 'ContentString' as ContentType,
                required: false,
                default: null,
                description:
                    'If you wish to send notification emails from ManyWho, this is usually the username of a valid email account e.g. mydemo@gmail.com',
            },
            {
                name: 'Email Username',
                contentType: 'ContentString' as ContentType,
                required: false,
                default: null,
                description: 'The email username of a valid email account e.g. mydemo@gmail.com',
            },
            {
                name: 'Email Password',
                contentType: 'ContentPassword' as ContentType,
                required: false,
                default: null,
                description: 'The email password of a valid email account.',
            },
            {
                name: 'Email SMTP',
                contentType: 'ContentString' as ContentType,
                required: false,
                default: null,
                description: 'The SMTP server details for a valid email host e.g. smtp.gmail.com',
            },
        ],
        view: 'NewFlowService',
        canHaveIdentity: false,
    },
    {
        name: 'SQL',
        techPreview: false,
        url: SERVICE_URLS.nextGenSQL,
        description: <p>Load, save and delete data using an SQL database.</p>,
        configuration: [
            {
                name: 'Username',
                contentType: 'ContentString' as ContentType,
                required: true,
                default: null,
                description: 'Username for your database.',
            },
            {
                name: 'Host',
                contentType: 'ContentString' as ContentType,
                required: true,
                default: null,
                description:
                    'Host of your database (e.g. localhost, mydatabase.example.us-east-1.rds.amazonaws.com, etc).',
            },
            {
                name: 'Port',
                contentType: 'ContentNumber' as ContentType,
                required: true,
                default: null,
                description: 'Port of your database (e.g. 3306, 5432, etc).',
            },
            {
                name: 'Database Type',
                contentType: 'ContentString' as ContentType,
                required: true,
                default: null,
                description: 'Type of database used (choices are: postgresql, mysql or sqlserver).',
            },
            {
                name: 'Database Name',
                contentType: 'ContentString' as ContentType,
                required: true,
                default: null,
                description: 'Name of your database.',
            },
            {
                name: 'Database schema',
                contentType: 'ContentString' as ContentType,
                required: true,
                default: null,
                description: 'Database schema.',
            },
            {
                name: 'Password',
                contentType: 'ContentPassword' as ContentType,
                required: true,
                default: null,
                description: 'Password for your database.',
            },
            {
                name: 'No SSL',
                contentType: 'ContentBoolean' as ContentType,
                required: true,
                default: null,
                description:
                    'Select true when your connection is not using SSL (the default value will use a SSL connection).',
            },
            {
                name: 'Server Public Certificate',
                contentType: 'ContentString' as ContentType,
                required: false,
                default: null,
                description: 'Certificate for server verification.',
            },
        ],
        view: 'NewFlowService',
        canHaveIdentity: false,
    },
    {
        name: 'OpenAI',
        techPreview: true,
        url: SERVICE_URLS.openai,
        description: (
            <>
                <p>Converse with ChatGPT</p>
            </>
        ),
        configuration: [
            {
                name: 'API Key',
                contentType: 'ContentPassword' as ContentType,
                required: true,
                default: null,
                description: 'Your OpenAI API key',
            },
            {
                name: 'Model',
                contentType: 'ContentString' as ContentType,
                required: false,
                default: 'gpt-3.5-turbo',
            },
            {
                name: 'System Messages',
                contentType: 'ContentList' as ContentType,
                required: false,
                default: null,
            },
        ],
        view: 'NewFlowService',
        canHaveIdentity: false,
    },
];
