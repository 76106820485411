import OutcomeDetails from '../common/OutcomeDetails';
import { isNullOrEmpty } from '../../../../utils/guard';
import { getByID } from '../../../../utils/collection';
import { useMapElement } from '../contextProviders/MapElementProvider';

type Props = {
    outcomeId: string | null;
    nextMapElementId: string | null;
};

/**
 * Handles switching between the outcome configuration screens
 */
const OutcomePanel = ({ outcomeId, nextMapElementId }: Props) => {
    const { mapElement, onClose, onSaveMapElement } = useMapElement();

    if (isNullOrEmpty(mapElement)) {
        return null;
    }

    return (
        <OutcomeDetails
            outcome={getByID(outcomeId, mapElement.outcomes || []) || { nextMapElementId }}
            onSave={onSaveMapElement}
            onCancel={onClose}
        />
    );
};

export default OutcomePanel;
