import { Desktop, DeviceMobile, Gear, Code, Copy, ListChecks } from '@phosphor-icons/react';
import { useState } from 'react';
import translations from '../../../../../../ts/translations';
import { isNullOrEmpty } from '../../../../../utils/guard';
import ButtonAction from '../../../../buttons/ButtonAction';
import ButtonPrimary from '../../../../buttons/ButtonPrimary';
import ConfirmModal from '../../../../generic/modal/ConfirmModal';
import { useComposer } from '../composer/ComposerProvider';
import { usePageEditor } from '../../PageEditorProvider';
import Settings from './Settings';
import Banner from './Banner';
import type { PreviewMode, Confirmation, PageElementRequestAPI } from '../../../../../types';

const Toolbar = () => {
    const { onSavePage } = useComposer();
    const {
        state,
        setPreviewMode,
        toggleUnsavedChanges,
        addNotification,
        setPageName,
        clonePage,
        setShowPageConditions,
        setModifiedPageConditions,
        setShowMetadataEditor,
        container,
    } = usePageEditor();

    const {
        page,
        tab,
        preview: currentMode,
        showPageConditions,
        modifiedPageCondition,
        showMetadataEditor,
    } = state;
    const showConfirmation = tab?.showConfirmation;
    const { pageComponents } = page;

    const [confirmProps, setConfirmProps] = useState<Confirmation | null>(null);
    const [showSettings, setShowSettings] = useState(false);
    const [showPageClone, setShowPageClone] = useState(false);

    const togglePreview = (selectedMode: PreviewMode) => {
        const updatedMode = selectedMode !== currentMode ? selectedMode : null;
        setPreviewMode(updatedMode);
    };

    const onSave = (pageData: PageElementRequestAPI) => {
        onSavePage(pageData);
    };

    const onPageConditions = () => {
        // We have some unsaved changes elsewhere in the page so we should warn the user about
        // that before opening page conditions because page conditions will reset the unsaved
        // change indicator.
        const hasUnsavedChangesElsewhere = showConfirmation && !showPageConditions;
        // We have made some changes to a page condition so we should warn the user about that
        // before moving away and closing page conditions.
        const isModifiedPageCondition = modifiedPageCondition && showPageConditions;

        if (hasUnsavedChangesElsewhere || isModifiedPageCondition) {
            setConfirmProps({
                show: true,
                title: hasUnsavedChangesElsewhere
                    ? translations.PAGE_BUILDER_unsaved_changes_title
                    : translations.PAGE_BUILDER_page_condition_unsaved_changes_title,
                messages: hasUnsavedChangesElsewhere
                    ? [translations.PAGE_BUILDER_unsaved_changes_banner_message]
                    : [translations.PAGE_BUILDER_page_condition_unsaved_changes_message],
                buttonStyle: 'danger',
                buttonCaption: 'OK',
                onCancel: () => setConfirmProps(null),
                onConfirm: () => {
                    setShowPageConditions(!showPageConditions);
                    setConfirmProps(null);
                    toggleUnsavedChanges(false);
                    setModifiedPageConditions(false);
                },
                container,
            });
        } else {
            setShowPageConditions(!showPageConditions);
        }
    };

    return (
        <div className="page-toolbar" data-testid="page-toolbar">
            <section className="page-toolbar-section page-info">
                <input
                    type="text"
                    className="page-name-input"
                    value={page.developerName || ''}
                    onChange={({ target }) => setPageName(target.value)}
                    onKeyDown={({ key }) => {
                        if (key === 'Enter' && !isNullOrEmpty(page.developerName)) {
                            setPageName(page.developerName);
                        }

                        if (key === 'Enter' && isNullOrEmpty(page.developerName)) {
                            addNotification({
                                type: 'error',
                                message: `${translations.PAGE_BUILDER_page_name_not_valid}`,
                                isPersistent: true,
                            });
                        }
                    }}
                    onBlur={() => setPageName(page?.developerName)}
                    data-testid={'page-name-input'}
                    placeholder="Enter a page name"
                />
            </section>

            <section className="page-toolbar-section page-banner">
                {showConfirmation && (
                    <Banner
                        type="info"
                        message={translations.PAGE_BUILDER_unsaved_changes_banner_message}
                    />
                )}
            </section>

            <section className="page-toolbar-section page-actions">
                <ButtonAction
                    className="btn-tertiary"
                    onClick={() => setShowSettings(!showSettings)}
                    title="Page Settings"
                >
                    <Gear size={24} />
                </ButtonAction>

                <ButtonAction
                    className="btn-tertiary"
                    disabled={page.id === null}
                    onClick={() => {
                        setShowMetadataEditor(!showMetadataEditor);
                    }}
                    title="Metadata Editor"
                >
                    <Code size={24} />
                </ButtonAction>

                <ButtonAction
                    className="btn-tertiary"
                    onClick={() => setShowPageClone(!showPageClone)}
                    title="Clone Page"
                >
                    <Copy size={24} />
                </ButtonAction>

                <ButtonAction
                    className={`btn-tertiary ${showPageConditions ? 'is-activated' : ''}`}
                    onClick={onPageConditions}
                    title="Page Conditions"
                    disabled={!!currentMode || !pageComponents?.length}
                >
                    <ListChecks size={24} />
                </ButtonAction>

                <ButtonAction
                    className={`btn-tertiary ${currentMode === 'MOBILE' ? 'is-activated' : ''}`}
                    onClick={() => togglePreview('MOBILE')}
                    title="Preview mobile"
                    disabled={showPageConditions}
                >
                    <DeviceMobile size={24} />
                </ButtonAction>

                <ButtonAction
                    className={`btn-tertiary ${currentMode === 'DESKTOP' ? 'is-activated' : ''}`}
                    onClick={() => togglePreview('DESKTOP')}
                    title="Preview desktop"
                    disabled={showPageConditions}
                >
                    <Desktop size={24} />
                </ButtonAction>

                <ButtonPrimary
                    className="save-page-button"
                    onClick={() => onSave(page)}
                    title="Save current page (CTRL+S)"
                    data-testid="save-page-button"
                >
                    Save
                </ButtonPrimary>
            </section>
            {confirmProps !== null ? <ConfirmModal {...confirmProps} /> : null}
            <Settings
                show={showSettings}
                showModal={setShowSettings}
                onSave={onSave}
                pageData={page}
                title="Page Settings"
                shouldClone={false}
            />

            {showPageClone ? (
                <Settings
                    show={showPageClone}
                    showModal={setShowPageClone}
                    onSave={clonePage}
                    pageData={page}
                    shouldClone
                    title="Clone Page"
                />
            ) : null}
        </div>
    );
};

export default Toolbar;
