import type { OperationAPI } from '../../../../types';

export const createOperationAPI = (partialOperation: Partial<OperationAPI>) => ({
    order: partialOperation.order ?? 0,
    disabled: partialOperation.disabled ?? false,
    valueElementToApplyId: partialOperation.valueElementToApplyId ?? null,
    valueElementToReferenceId: partialOperation.valueElementToReferenceId ?? null,
    macroElementToExecuteId: partialOperation.macroElementToExecuteId ?? null,
    macroElementToExecuteDeveloperName: partialOperation.macroElementToExecuteDeveloperName ?? null,
    valueElementToApplyDeveloperName: partialOperation.valueElementToApplyDeveloperName ?? null,
    valueElementToApplyPropertyDeveloperName:
        partialOperation.valueElementToApplyPropertyDeveloperName ?? null,
    valueElementToReferenceDeveloperName:
        partialOperation.valueElementToReferenceDeveloperName ?? null,
    valueElementToReferencePropertyDeveloperName:
        partialOperation.valueElementToReferencePropertyDeveloperName ?? null,
    valueElementToApplyCommand: partialOperation.valueElementToApplyCommand ?? null,
    valueElementToReferenceCommand: partialOperation.valueElementToReferenceCommand ?? null,
    valueElementToApplyCommandFriendly: partialOperation.valueElementToApplyCommandFriendly ?? null,
    valueElementToReferenceCommandFriendly:
        partialOperation.valueElementToReferenceCommandFriendly ?? null,
    valueElementToApplyContentType: partialOperation.valueElementToApplyContentType ?? null,
    valueElementToApplyPropertyContentType:
        partialOperation.valueElementToApplyPropertyContentType ?? null,
    valueElementToApplyTypeElementId: partialOperation.valueElementToApplyTypeElementId ?? null,
    valueElementToReferenceContentType: partialOperation.valueElementToReferenceContentType ?? null,
    valueElementToReferencePropertyContentType:
        partialOperation.valueElementToReferencePropertyContentType ?? null,
    valueElementToReferenceTypeElementId:
        partialOperation.valueElementToReferenceTypeElementId ?? null,
    valueElementToApplySortById: partialOperation.valueElementToApplySortById ?? null,
    valueElementToApplySortByDeveloperName:
        partialOperation.valueElementToApplySortByDeveloperName ?? null,
    valueElementToApplySortDirection: partialOperation.valueElementToApplySortDirection ?? null,
    valueElementToReferenceRelativeId: partialOperation.valueElementToReferenceRelativeId ?? null,
    valueElementToReferenceRelativeDeveloperName:
        partialOperation.valueElementToReferenceRelativeDeveloperName ?? null,
    valueElementToReferenceRelativePropertyDeveloperName:
        partialOperation.valueElementToReferenceRelativePropertyDeveloperName ?? null,
    valueElementToReferenceRelativeContentType:
        partialOperation.valueElementToReferenceRelativeContentType ?? null,
});
