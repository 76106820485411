import DecisionConfiguration from './DecisionConfiguration';
import OutcomeDetails from '../common/OutcomeDetails';
import screens from '../common/screens';
import { useMapElement } from '../contextProviders/MapElementProvider';

const DecisionRouter = () => {
    const {
        currentScreen,
        mapElement,
        selectedOutcomeIndex,
        setSelectedOutcomeIndex,
        onSwitchScreen,
    } = useMapElement();

    switch (currentScreen) {
        case screens.decision:
            return <DecisionConfiguration />;
        case screens.outcome: {
            const selectedOutcome = mapElement.outcomes?.[selectedOutcomeIndex ?? 0] ?? null;

            return (
                <OutcomeDetails
                    outcome={selectedOutcome}
                    onSave={() => onSwitchScreen(screens.decision)}
                    onCancel={() => {
                        onSwitchScreen(screens.decision);
                        setSelectedOutcomeIndex(null);
                    }}
                />
            );
        }

        default:
            return null;
    }
};

export default DecisionRouter;
