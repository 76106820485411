import ButtonDefault from '../../buttons/ButtonDefault';
import { FRIENDLY_DEPENDENCY_NAMES, SYSTEM_TYPE_IDS, SYSTEM_VALUE_IDS } from '../../../constants';
import translations from '../../../translations';
import Table, { type TableColumnList } from '../../generic/Table';
import type { AddNotification, ElementAPI } from '../../../types';
import { useCallback, useEffect, useState } from 'react';
import SearchImportExisting from './SearchImportExisting';
import Loader from '../../loader/Loader';

const SYSTEM_VALUE_AND_TYPE_IDS = SYSTEM_VALUE_IDS.concat(SYSTEM_TYPE_IDS);

type Props = {
    onFetch: () => Promise<ElementAPI[]>;
    elementType: string;
    importElement: (id: string) => void;
    addNotification: AddNotification;
    isOpen: boolean;
};

const ImportedElements = ({
    elementType,
    importElement,
    addNotification,
    isOpen,
    onFetch,
}: Props) => {
    const [tableData, setTableData] = useState<ElementAPI[]>([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const excludeSummaryRow = elementType === FRIENDLY_DEPENDENCY_NAMES.VALUE_ELEMENT;

    const tableColumns = () => {
        const columns: TableColumnList<ElementAPI> = [];

        columns.push({
            renderHeader: () => translations.COMMON_TABLE_name,
            renderCell: ({ item }) => item.developerName,
        });

        if (!excludeSummaryRow) {
            columns.push({
                renderHeader: () => translations.COMMON_TABLE_summary,
                renderCell: ({ item }) => item.developerSummary,
            });
        }

        columns.push({
            renderHeader: () => translations.COMMON_TABLE_actions,
            renderCell: ({ item }) => (
                <div className="action-btn-wrapper">
                    <ButtonDefault
                        title={`Import ${item.developerName}`}
                        className="btn-sm"
                        aria-label={`Import ${item.developerName}`}
                        onClick={() => importElement(item.id)}
                    >
                        Import
                    </ButtonDefault>
                </div>
            ),
            size: '6rem',
        });

        return columns;
    };

    const getValueElements = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await onFetch();
            setTableData(filterElements(response));
        } catch (error) {
            addNotification({
                type: 'error',
                message: (error as Error).message,
                isPersistent: true,
            });
        } finally {
            setIsLoading(false);
        }
    }, [addNotification, onFetch]);

    const filterElements = (newData: ElementAPI[]) => {
        return newData
            .filter((item) => !SYSTEM_VALUE_AND_TYPE_IDS.includes(item.id))
            .filter((item) => {
                return tableData.every((filter) => {
                    return filter.id !== item.id;
                });
            });
    };

    const filteredTableData = tableData.filter((row) => {
        const name = row.developerName ? row.developerName.toLowerCase() : '';
        return name.includes(searchQuery.toLowerCase());
    });

    useEffect(() => {
        if (isOpen) {
            getValueElements();
        }
    }, [getValueElements, isOpen]);

    if (!isOpen) {
        return null;
    }

    if (isLoading) {
        return <Loader message="Loading data..." />;
    }

    return (
        <>
            <SearchImportExisting setSearchQuery={setSearchQuery} getElements={getValueElements} />
            <Table
                items={filteredTableData}
                columns={tableColumns()}
                pagination={true}
                testId={'shared-elements-import'}
            />
        </>
    );
};

export default ImportedElements;
