import {
    MapElementProvider,
    type MapElementProviderProps,
} from '../contextProviders/MapElementProvider';
import MapElementModal from '../../../graph/MapElementModal';
import { MAP_ELEMENT_TYPES } from '../../../../constants';
import { OpenApiProvider } from '../contextProviders/OpenApiProvider';
import OpenApiRouter from './OpenApiRouter';
/**
 * The openapi config screen
 */
const OpenApi = (props: MapElementProviderProps) => {
    return (
        <MapElementProvider
            {...props}
            defaultScreen={'openApi'}
            elementType={MAP_ELEMENT_TYPES.openapi}
        >
            <MapElementModal>
                <OpenApiProvider defaultScreen={'openApi'}>
                    <OpenApiRouter />
                </OpenApiProvider>
            </MapElementModal>
        </MapElementProvider>
    );
};

export default OpenApi;
