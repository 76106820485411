import * as Icons from '@phosphor-icons/react';
import { useMemo } from 'react';
import Select, { components, type OptionProps, type SingleValueProps } from 'react-select';
import { getSharedStyles } from '../../../utils/select';
import { useComponents } from './CustomPageComponentsProvider';

type IconPickerOption = {
    label: string;
    value: Icons.Icon;
};

const Option = (props: OptionProps<IconPickerOption>) => {
    // Remove onMouseMove and onMouseOver to improve react-select performance
    // Extract innerProps
    const { innerProps, ...restProps } = props;
    // Extract onMouseMove and onMouseOver from innerProps
    const { onMouseMove, onMouseOver, ...restInnerProps } = innerProps;
    // Recombine props without onMouseMove and onMouseOver
    const editedProps = { ...restProps, innerProps: restInnerProps };

    return (
        // @ts-expect-error Wrong return type but still works
        <components.Option
            // Separate from expect-error comment so that props still get checked
            {...editedProps}
        >
            <span className="icon-option" title={props.label}>
                <props.data.value />
                <span>{props.label}</span>
            </span>
        </components.Option>
    );
};

const SingleValue = (props: SingleValueProps<IconPickerOption>) => {
    return (
        // @ts-expect-error Wrong return type but still works
        <components.SingleValue
            // Separate from expect-error comment so that props still get checked
            className="icon-value"
            {...props}
        >
            <props.data.value />
            <span>{props.data.label}</span>
        </components.SingleValue>
    );
};

const IconPicker = () => {
    const { editingComponent, setEditingComponent } = useComponents();

    const options = useMemo(
        () =>
            (
                Object.entries(Icons)
                    // Unsure if the ultimate filter ([string,Icons.Icon][]) is correct but it's the only solution I can find to eliminate type errors when the type of this options array is used elsewhere
                    .filter(([_, value]) => {
                        // @ts-expect-error Neither 'render' nor 'displayName' are part of any exported types (they may be in IconBaseProps but that's not exported) but they apparently *can* both exist on 'value' as the tests fail if either is not used, e.g., when using an alternative filter condition
                        return value?.render && value?.displayName !== 'IconBase';
                    }) as [string, Icons.Icon][]
            ).map(([key, value]) => ({
                label: key,
                value: value,
            })),
        [],
    );

    const value = useMemo(() => {
        const selectedIcon = options.find(
            ({ label }) => label.toUpperCase() === editingComponent?.icon?.toUpperCase(),
        );

        return selectedIcon ?? null;
    }, [options, editingComponent]);

    const styles = getSharedStyles<IconPickerOption>({
        menuList: (baseStyles) => ({
            ...baseStyles,
            display: 'flex',
            flexWrap: 'wrap',
        }),
        option: (baseStyles) => ({
            ...baseStyles,
            width: '33%',
            padding: 0,
        }),
    });

    const handleChange = (option: IconPickerOption | null) => {
        if (!editingComponent) {
            throw new Error('No component selected to edit');
        }

        setEditingComponent({
            ...editingComponent,
            icon: option?.label ?? '',
        });
    };

    return (
        <Select
            inputId="component-icon"
            className="form-control-long"
            isClearable
            classNamePrefix="icon-picker"
            styles={styles}
            onChange={handleChange}
            placeholder="Select an icon for the page builder option"
            value={value}
            options={options}
            components={{
                Option,
                SingleValue,
            }}
        />
    );
};

export default IconPicker;
