import { TENANT_VIEWS } from '../../constants';
import translations from '../../translations';
import { isSubtenant } from '../../utils';
import PageSwitcher from '../generic/PageSwitcher';
import CreateSubtenant from './CreateSubtenant';
import Tenant from './Tenant';

type TenantPageSwitcherProps = {
    tenantName: string;
};

const TenantPageSwitcher = ({ tenantName }: TenantPageSwitcherProps) => {
    return (
        <PageSwitcher
            pages={[
                {
                    id: TENANT_VIEWS.tenant,
                    label: translations.TENANT_page_title_tenant,
                    parent: null,
                    menu: [
                        {
                            label: 'Tenant Name',
                            link: '#tenant-name',
                            visible: true,
                        },
                        {
                            label: 'Users',
                            link: '#tenant-users',
                            visible: true,
                        },
                        {
                            label: 'Environments',
                            link: '#tenant-environment',
                            visible: true,
                        },
                        {
                            label: 'Themes',
                            link: '#tenant-themes',
                            visible: true,
                        },
                        {
                            label: 'Integration Accounts',
                            link: '#tenant-integration-accounts',
                            visible: true,
                        },
                        {
                            label: 'Subtenants',
                            link: '#tenant-subtenants',
                            visible: !isSubtenant(tenantName),
                        },
                        {
                            label: 'Single Sign-on',
                            link: '#tenant-single-sign-on',
                            visible: true,
                        },
                        {
                            label: 'Security',
                            link: '#tenant-security',
                            visible: true,
                        },
                        {
                            label: 'Reporting',
                            link: '#tenant-reporting',
                            visible: true,
                        },
                        {
                            label: 'Observability',
                            link: '#tenant-observability',
                            visible: true,
                        },
                        {
                            label: 'Export',
                            link: '#tenant-export',
                            visible: true,
                        },
                        {
                            label: 'Import',
                            link: '#tenant-import',
                            visible: true,
                        },
                        {
                            label: 'Comments',
                            link: '#tenant-comments',
                            visible: true,
                        },
                    ],
                    component: Tenant,
                },
                {
                    id: TENANT_VIEWS.newSubtenant,
                    label: translations.TENANT_page_title_create_subtenant,
                    parent: 'tenant',
                    menu: null,
                    component: CreateSubtenant,
                },
            ]}
        />
    );
};

export default TenantPageSwitcher;
