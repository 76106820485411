import translations from '../../../../../../translations';
import FormGroup from '../../../../../generic/FormGroup';
import { useDataAction } from '../../../contextProviders/DataActionProvider';
import { useMapElement } from '../../../contextProviders/MapElementProvider';
import { isNullOrEmpty } from '../../../../../../utils/guard';
import ValueSelectorModal from '../../../../../values/selector/ValueSelectorModal';

type Props = {
    idToFilterBy: string | null;
    hasSubmitted: boolean;
};

const FilterById = ({ idToFilterBy, hasSubmitted }: Props) => {
    const { filterToEdit, onSetFilterId } = useDataAction();
    const { container } = useMapElement();

    const filterId = filterToEdit?.filterId;

    if (!filterId) {
        return null;
    }

    return (
        <FormGroup
            isRequired
            label={translations.DATA_ACTION_filter_value_identifier_label}
            showValidation={hasSubmitted}
            validationMessage={translations.MAP_ELEMENT_value_field_validation_message}
            isValid={!isNullOrEmpty(idToFilterBy)}
        >
            <ValueSelectorModal
                value={filterId}
                onChange={onSetFilterId}
                container={container}
                includeSystemValues
            />
        </FormGroup>
    );
};

export default FilterById;
