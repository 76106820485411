import {
    MapElementProvider,
    type MapElementProviderProps,
} from '../contextProviders/MapElementProvider';
import MapElementModal from '../../../graph/MapElementModal';
import { ProcessProvider } from '../contextProviders/ProcessProvider';
import ProcessRouter from './ProcessRouter';

const Process = (props: MapElementProviderProps) => {
    return (
        <MapElementProvider {...props} defaultScreen={'process'} elementType={'process'}>
            <MapElementModal>
                <ProcessProvider defaultScreen={'process'}>
                    <ProcessRouter />
                </ProcessProvider>
            </MapElementModal>
        </MapElementProvider>
    );
};

export default Process;
