import { useRef } from 'react';
import translations from '../../translations';
import AnomalyOverview from './AnomalyOverview';
import AnomalyFilters from './AnomalyFilters';
import AnomalyChartWrapper from './AnomalyChartWrapper';
import { View } from '../developerDashboard/InsightsDashboard';
import { useAnomalyDashboard } from './AnomalyProvider';
import { ExEmptyState } from '@boomi/exosphere';

import '../../../../css/anomaly-dashboard.less';

interface Props {
    setCurrentView: (view: View) => void;
    screen?: string;
}

const AnomalyDashboard = ({ setCurrentView }: Props) => {
    const { isLoadingData, data, filteredData } = useAnomalyDashboard();
    const modalContainerRef = useRef<HTMLDivElement | null>(null);

    return (
        <div className="anomaly-detection" ref={modalContainerRef}>
            <span className="title-bar">
                <h1 className="anomaly-title">
                    <button
                        className="link-emulate"
                        onClick={() => setCurrentView(View.summary)}
                        type="button"
                    >
                        {translations.DASHBOARD_header}
                    </button>
                    {' > '}
                    {translations.ANOMALY_anomaly_detection_title}
                    <span className="beta-tag">Beta</span>
                </h1>
            </span>
            <AnomalyFilters />
            {!isLoadingData && data.length > 0 ? (
                filteredData.length > 0 ? (
                    <>
                        <AnomalyChartWrapper />
                        <AnomalyOverview container={modalContainerRef.current} />
                    </>
                ) : (
                    <div className="insights-empty-state">
                        <ExEmptyState
                            label={translations.ANOMALY_no_anomalies_found}
                            text={translations.ANOMALY_check_your_filters}
                        />
                    </div>
                )
            ) : (
                <div className="insights-empty-state">
                    <ExEmptyState
                        label={translations.ANOMALY_no_anomalies_found}
                        text={translations.ANOMALY_no_anomalies_7_days}
                    />
                </div>
            )}
        </div>
    );
};

export default AnomalyDashboard;
