import { useState, useEffect } from 'react';
import ValueSelectorTable from '../../../components/values/selector/ValueSelectorTable';
import Loader from '../../loader/Loader';
import translations from '../../../translations';
import { getServiceConfigurationValues } from '../../../sources/service';
import { addNotification } from '../../../../js/actions/reduxActions/notification';
import { connect } from 'react-redux';
import type { AddNotification, ValueElementIdAPI } from '../../../types';
import type { ServiceValueRequestAPI } from '../../../types/service';

interface ConfigurationListProps {
    serviceName: string;
    configurationValues: ServiceValueRequestAPI[];
    setConfigurationValues: (values: ServiceValueRequestAPI[]) => void;
    id: string;
    url: string;
    username: string | null | undefined;
    password: string | null | undefined;
    tenantId: string;
    addNotification: AddNotification;
    httpAuthenticationClientCertificateReference: string | null | undefined;
    httpAuthenticationClientCertificatePasswordReference: string | null | undefined;
}

interface CustomError {
    uri: string;
    statusCode: number;
    message: string;
}

/**
 * @param {Object[]} configurationValues - List of selected configuration values for the service
 */
const ConfigurationList = ({
    serviceName,
    configurationValues,
    setConfigurationValues,
    id,
    url,
    username,
    password,
    addNotification,
    httpAuthenticationClientCertificateReference,
    httpAuthenticationClientCertificatePasswordReference,
}: ConfigurationListProps) => {
    // This tells us the what config values are available to the service
    const [serviceConfigurationValues, setServiceConfigurationValues] = useState<
        ServiceValueRequestAPI[]
    >([]);

    // These are all the selected and empty configuration values.
    // This is what determines what gets displayed in the table of config values
    const [allConfigurationOptions, setAllConfigurationOptions] = useState<
        ServiceValueRequestAPI[]
    >([]);
    const [isLoadingConfigOptions, setIsLoadingConfigOptions] = useState<boolean>(true);

    // biome-ignore lint/correctness/useExhaustiveDependencies: Treat warnings as errors, fix later
    useEffect(() => {
        if (serviceConfigurationValues) {
            // A config value must have been removed or added,
            // so let's perform a remap to determine what to display.
            setAllConfigurationOptions(mapConfigValues(serviceConfigurationValues));
        }
    }, [configurationValues, configurationValues?.length, serviceConfigurationValues.length]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: Treat warnings as errors, fix later
    useEffect(() => {
        async function fetchAllConfigurationOptions() {
            const hasValidLoginCredentials = (username && password) || !(username || password);

            if (hasValidLoginCredentials) {
                try {
                    // Grab the config options available for this service.
                    // At this point we only know what values have been selected.
                    const configData = await getServiceConfigurationValues({
                        uri: url,
                        basicUsername: username,
                        basicPassword: password,
                        httpAuthenticationClientCertificateReference,
                        httpAuthenticationClientCertificatePasswordReference,
                        id,
                    });

                    // We keep the config data in state so that we don't have to ask the
                    // the server every time a config value is modified.
                    setServiceConfigurationValues(configData ?? []);
                    setAllConfigurationOptions(mapConfigValues(configData));
                } catch (error) {
                    // The call to the external service failed,
                    // so we just use the configuration Values from the Service data we have,
                    // these will only be the config Values for the Service that they have set
                    setAllConfigurationOptions(configurationValues);
                    const errorObject = JSON.parse((error as Error).message) as CustomError;
                    addNotification({
                        type: 'error',
                        message: `${errorObject.statusCode} - ${errorObject.message}: ${errorObject.uri}`,
                        isPersistent: true,
                    });
                } finally {
                    setIsLoadingConfigOptions(false);
                }
            } else {
                // Just use the configuration Values from the Service data we have,
                // these will only be the config Values for the Service that they have set
                setAllConfigurationOptions(configurationValues);
                setIsLoadingConfigOptions(false);
            }
        }
        fetchAllConfigurationOptions();
    }, []);

    // This allows us to determine which config values have been selected
    // and which have not.
    const mapConfigValues = (configData: ServiceValueRequestAPI[]) =>
        configData.map((cd) => {
            const acc = {} as ServiceValueRequestAPI;
            acc.developerName = cd.developerName;
            const selectedConfigurationValue = configurationValues
                ? configurationValues.find((value) => value.developerName === cd.developerName)
                : null;
            acc.valueElementToReferenceId = selectedConfigurationValue
                ? ({
                      id: selectedConfigurationValue.valueElementToReferenceId?.id,
                      typeElementPropertyId:
                          selectedConfigurationValue.valueElementToReferenceId
                              ?.typeElementPropertyId,
                      command: selectedConfigurationValue.valueElementToReferenceId?.command,
                  } as ValueElementIdAPI)
                : null;

            acc.contentType = cd.contentType;
            acc.order = cd.order;
            acc.typeElementDeveloperName = cd.typeElementDeveloperName;
            return acc;
        });

    const onSelectValue = (value: ValueElementIdAPI | null, item: ServiceValueRequestAPI) => {
        const updatedConfigurationValues = allConfigurationOptions
            .map((option) =>
                option.developerName === item.developerName
                    ? {
                          ...item,
                          valueElementToReferenceId: value
                              ? {
                                    ...item.valueElementToReferenceId,
                                    id: value.id,
                                    typeElementPropertyId: value.typeElementPropertyId,
                                }
                              : null,
                      }
                    : option,
            )
            .filter((option) => option.valueElementToReferenceId !== null);
        setConfigurationValues(updatedConfigurationValues as ServiceValueRequestAPI[]);
    };

    const getStartingValueName = (item: ServiceValueRequestAPI) => {
        return `${serviceName} - ${item.developerName}`;
    };

    if (isLoadingConfigOptions) {
        return <Loader />;
    }

    return (
        <>
            <h2>Configuration</h2>
            <div className="alert alert-warning">
                {translations.SC_configuration_values_warning_message}
            </div>
            <ValueSelectorTable
                items={allConfigurationOptions}
                selectValue={(value, item) => onSelectValue(value, item as ServiceValueRequestAPI)}
                getStartingValueName={(item) =>
                    getStartingValueName(item as ServiceValueRequestAPI)
                }
                modalContainer={null}
            />
        </>
    );
};

const mapDispatchToProps = {
    addNotification,
};

export default connect(null, mapDispatchToProps)(ConfigurationList);
