interface Props {
    label: string | null;
}

const DownloadButton = ({ label }: Props) => {
    const text = typeof label === 'string' && label.length > 0 ? label : 'Download file';

    return (
        <div data-testid="component-preview-download-button">
            <div className="component-preview-download-button-container">
                <button type="button" className="btn btn-default ">
                    {text}
                </button>
            </div>
        </div>
    );
};

export default DownloadButton;
