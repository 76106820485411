import { MAP_ELEMENT_DISPLAY_NAMES } from '../../../constants';
import {} from '../../../sources/graph';
import type {
    Box,
    Dimensions,
    FlowGraphGroupElementResponseAPI,
    FlowGraphMapElementResponseAPI,
    Flowchart,
    GroupNodeCollection,
    GroupNode,
    LeafNode,
    INode,
    LeafNodeCollection,
    MapElementType,
    Point,
    Side,
    GraphElement,
} from '../../../types';
import { isNullOrUndefined } from '../../../utils/guard';
import { addPositions, subtractPositions } from '../../graph/utils';
import { NODE_STYLES } from '../render/nodeStyles';
import type { FlowEditorState, GroupHandle } from '../state/types';
import { generatePoints, getHeadRotation } from './edge';
import { calculateIfBoundIsWithinAnotherBounds } from './position';
import { snap } from './svg';

type LayeredGroupNode = GroupNode & { depth: number };

export const createNodes = <T>(
    elements: (FlowGraphMapElementResponseAPI | FlowGraphGroupElementResponseAPI)[],
    groupElements: FlowGraphGroupElementResponseAPI[],
) =>
    elements.reduce(
        (groupNodes, groupElement) => {
            const node = elementToNode(groupElement, groupElements);
            groupNodes[node.id] = node;
            return groupNodes;
        },
        {} as Record<string, INode>,
    ) as Record<string, T>;

/** returns the outcome offset on the given side for an element of the given width and height */
export const getNodePortPosition = (width: number, height: number, side: Side): Point => {
    switch (side) {
        case 'top':
            return { x: width / 2, y: 0 };
        case 'bottom':
            return { x: width / 2, y: height };
        case 'left':
            return { x: 0, y: height / 2 };
        case 'right':
            return { x: width, y: height / 2 };
    }
};

const isGroupElement = (
    element: FlowGraphMapElementResponseAPI | FlowGraphGroupElementResponseAPI,
): element is FlowGraphGroupElementResponseAPI => {
    return (
        (element as FlowGraphGroupElementResponseAPI).width !== undefined &&
        (element as FlowGraphGroupElementResponseAPI).height !== undefined
    );
};

export const getElementDimensions = (
    element: FlowGraphMapElementResponseAPI | FlowGraphGroupElementResponseAPI,
): Dimensions => {
    if (isGroupElement(element)) {
        return {
            width: element.width,
            height: element.height,
        };
    }

    const isStart = element.elementType.toLowerCase() === 'start';
    const style = isStart
        ? NODE_STYLES.START
        : NODE_STYLES[element.elementType.toLowerCase() as MapElementType];

    return {
        width: style.width,
        height: style.height,
    };
};

/** returns the opposite side to the given side */
export const reverseSide = (side: Side): Side => {
    switch (side) {
        case 'top':
            return 'bottom';
        case 'bottom':
            return 'top';
        case 'left':
            return 'right';
        case 'right':
            return 'left';
    }
};

export const elementToNode = (
    element: FlowGraphMapElementResponseAPI | FlowGraphGroupElementResponseAPI,
    groupElements: FlowGraphGroupElementResponseAPI[],
): LeafNode | GroupNode => {
    const parentOffset = getElementOffset(element, groupElements);
    const { x, y } = addPositions(element, parentOffset);

    const node = createNode({
        id: element.id,
        elementType: element.elementType,
        developerName: element.developerName ?? 'unnamed',
        groupElementId: element.groupElementId ?? null,
        x,
        y,
    });

    if (isGroupElement(element)) {
        node.height = element.height;
        node.width = element.width;
    }

    return node;
};

const updateNodePositionFromSnapshot = (
    snapshotNodes: Record<string, INode>,
    difference: Point,
    node: INode,
) => {
    const nodeSnapshot = snapshotNodes[node.id];
    const { x, y } = addPositions({ x: nodeSnapshot?.x ?? 0, y: nodeSnapshot?.y ?? 0 }, difference);
    node.x = snap(x);
    node.y = snap(y);
};

export const isLeafNode = (item: unknown): item is LeafNode => {
    return (
        !isNullOrUndefined(item) &&
        Object.hasOwn(item as object, 'nodeType') &&
        (item as INode).nodeType === 'leaf'
    );
};

export const isGroupNode = (item: unknown): item is GroupNode => {
    return (
        !isNullOrUndefined(item) &&
        Object.hasOwn(item as object, 'nodeType') &&
        (item as INode).nodeType === 'group'
    );
};

export const getSelectedNodes = (selectedObjectIds: string[], flowchart: Flowchart) => {
    const selectedNodes = selectedObjectIds.reduce((nodes, id) => {
        const node = flowchart.leafNodes[id] || flowchart.groupNodes[id];
        if (node) {
            nodes.push(node);
        }
        return nodes;
    }, [] as INode[]);

    return selectedNodes;
};

export const dragNodes = (dragDifference: Point, state: FlowEditorState) => {
    // All leaf nodes affected by the drag action
    const leafNodes: LeafNodeCollection = {};
    // All group nodes affected by the drag action
    const groupNodes: GroupNodeCollection = {};

    // Collect all selected nodes and their descendants
    state.selectedObjectIds.forEach((id) => {
        if (isLeafNode(state.flowchart.leafNodes[id])) {
            leafNodes[id] = state.flowchart.leafNodes[id];
        }

        if (isGroupNode(state.flowchart.groupNodes[id])) {
            groupNodes[id] = state.flowchart.groupNodes[id];

            const descendants = getDescendants(
                [
                    ...Object.values(state.flowchart.groupNodes),
                    ...Object.values(state.flowchart.leafNodes),
                ],
                state.flowchart.groupNodes[id],
            );

            descendants.forEach((node) => {
                if (isLeafNode(node)) {
                    leafNodes[node.id] = node;
                }

                if (isGroupNode(node)) {
                    groupNodes[node.id] = node;
                }
            });
        }
    });

    // Move all affected leaf nodes
    Object.values(leafNodes).forEach((leafNode) => {
        if (state.dragSnapshot !== null) {
            updateNodePositionFromSnapshot(
                state.dragSnapshot.flowchart.leafNodes,
                dragDifference,
                leafNode,
            );

            const hoveredGroup = checkHasDroppedInGroup(leafNode, state.flowchart.groupNodes);

            if (hoveredGroup) {
                state.flowchart.groupNodes[hoveredGroup.id].isHovered = true;
                Object.values(state.flowchart.groupNodes).forEach((groupNode) => {
                    if (groupNode.id === hoveredGroup.id) {
                        groupNode.isHovered = true;
                    } else {
                        groupNode.isHovered = false;
                    }
                });
            } else {
                Object.values(state.flowchart.groupNodes).forEach((groupNode) => {
                    groupNode.isHovered = false;
                });
            }
        }
    });

    // Move all affected group nodes
    Object.values(groupNodes).forEach((groupNode) => {
        if (state.dragSnapshot !== null) {
            updateNodePositionFromSnapshot(
                state.dragSnapshot.flowchart.groupNodes,
                dragDifference,
                groupNode,
            );
        }
    });

    Object.values(state.flowchart.edges)
        .filter(
            // Filter all edges to just those that are attached to the affected/moving leaf node(s)
            (edge) =>
                !isNullOrUndefined(leafNodes[edge.nodeIds[0]]) ||
                (edge.nodeIds[1] && !isNullOrUndefined(leafNodes[edge.nodeIds[1]])),
        )
        .forEach((edge) => {
            // Update edges attached to the affected/moving leaf node(s)
            const originNode = state.flowchart.leafNodes[edge.nodeIds[0]];
            const nextNode = edge.nodeIds[1] ? state.flowchart.leafNodes[edge.nodeIds[1]] : null;

            if (originNode !== null && nextNode !== null && state.dragSnapshot) {
                const returnsToSelf = originNode === nextNode;
                const points = generatePoints(
                    [originNode, nextNode],
                    edge.controlPoint,
                    returnsToSelf,
                );
                const headRotation = getHeadRotation(points);

                edge.points = points;
                edge.headRotation = headRotation;
            }
        });

    const selectedNodes = getSelectedNodes(state.selectedObjectIds, state.flowchart);
    state.flowchart.selectionBox = selectedNodes.length > 1 ? getBoundingBox(selectedNodes) : null;
};

const getChildren = (nodes: INode[], nodeId: string) =>
    nodes.filter((node) => node.groupId === nodeId);

export const getDescendants = (nodes: INode[], node: INode) => {
    if (node.nodeType === 'leaf') {
        return [];
    }

    const children = getChildren(nodes, node.id);

    if (children.length === 0) {
        return [];
    }

    const descendants: INode[] = children.flatMap((child) => getDescendants(nodes, child));

    return children.concat(descendants);
};

const getParentElements = (
    element: FlowGraphMapElementResponseAPI | FlowGraphGroupElementResponseAPI,
    groupElements: FlowGraphGroupElementResponseAPI[],
    parents: FlowGraphGroupElementResponseAPI[] = [],
): FlowGraphGroupElementResponseAPI[] => {
    if (element?.groupElementId) {
        const parent = groupElements.find((grp) => grp.id === element.groupElementId);
        if (!parent) {
            const id = element.groupElementId;
            //stop the missing parent error cascading and corrects the data error if saved
            element.groupElementId = null;
            throw new Error(
                `Could not find parent with ID ${id} of ${element.elementType} "${
                    element.developerName ?? ''
                }" with ID ${element.id}`,
            );
        }
        parents.push(parent);
        return getParentElements(parent, groupElements, parents);
    }

    return parents;
};

const getChildElements = (elements: GraphElement[], elementId: string) =>
    elements.filter((element) => element.groupElementId === elementId);

export const getDescendantElements = (elements: GraphElement[], element: GraphElement) => {
    if (!isGroupElement(element)) {
        return [];
    }

    const children = getChildElements(elements, element.id);

    if (children.length === 0) {
        return [];
    }

    const descendants: GraphElement[] = children.flatMap((child) =>
        getDescendantElements(elements, child),
    );

    return children.concat(descendants);
};

export const getElementOffset = (
    element: FlowGraphMapElementResponseAPI | FlowGraphGroupElementResponseAPI,
    groupElements: FlowGraphGroupElementResponseAPI[],
): Point => {
    const parents = getParentElements(element, groupElements);
    const offset = parents.reduce(
        (offset, parent) => ({
            x: offset.x + parent.x,
            y: offset.y + parent.y,
        }),
        { x: 0, y: 0 },
    );

    return offset;
};

export const getNodeRelativePosition = (node: INode, groupNodes: GroupNodeCollection): Point => {
    if (node.groupId === null) {
        return {
            x: node.x,
            y: node.y,
        };
    }

    const parent = groupNodes[node.groupId];

    if (!parent) {
        return {
            x: node.x,
            y: node.y,
        };
    }

    const relativePosition = subtractPositions(node, parent);
    return relativePosition;
};

export const getBoundingBox = (nodes: INode[]) => {
    if (nodes.length === 0) {
        return null;
    }

    const box = nodes.reduce((box: Box | null, node) => {
        if (box === null) {
            const { x, y, width, height } = node;
            return { x, y, width, height };
        }

        const x = Math.min(box.x, node.x);
        const y = Math.min(box.y, node.y);

        const x2 = Math.max(box.x + box.width, node.x + node.width);
        const y2 = Math.max(box.y + box.height, node.y + node.height);

        const width = x2 - x;
        const height = y2 - y;

        return { x, y, width, height };
    }, null);

    return box;
};

interface CreateNodeParams {
    id: string;
    x: number;
    y: number;
    elementType: MapElementType;
    developerName: string;
    groupElementId: string | null;
    height?: number;
    width?: number;
}
export const createNode = (params: CreateNodeParams) => {
    const { id, elementType, developerName, groupElementId, x, y } = params;
    const type = elementType.toLowerCase() as MapElementType;
    const isStartNode = type === 'start';
    const style = NODE_STYLES[isStartNode ? 'START' : type];
    const { borderRadius, borderWidth, color, iconName } = style;
    const label = developerName ?? 'unnamed';
    const description = `Element of type ${MAP_ELEMENT_DISPLAY_NAMES[type]} with the name: ${label}`;

    const node = {
        id,
        groupId: groupElementId,
        iconName,
        x,
        y,
        borderRadius,
        borderWidth,
        color,
        label,
        description,
        isSelected: false,
        isStartNode,
        canDelete: false,
        isHovered: false,
    };

    if (type === 'group' || type === 'swimlane') {
        const height = params.width ?? 120;
        const width = params.width ?? 390;

        return {
            ...node,
            nodeType: 'group',
            height,
            width,
        } as GroupNode;
    }

    const { height, width } = style;

    return {
        ...node,
        nodeType: 'leaf',
        height,
        width,
        isFilled: false,
        actionText: (params as FlowGraphMapElementResponseAPI).page?.developerName ?? '',
    } as LeafNode;
};

const checkHasDroppedInGroup = (
    droppedElement: LeafNode | GroupNode,
    groupNodes: GroupNodeCollection,
) => {
    const matches = (Object.values(groupNodes) as LayeredGroupNode[]).filter((groupNode) => {
        if (droppedElement.id === groupNode.id) {
            return false;
        }

        const groupBounds = {
            x: groupNode.x,
            y: groupNode.y,
            width: groupNode.width,
            height: groupNode.height,
        };

        return calculateIfBoundIsWithinAnotherBounds({
            innerBound: droppedElement,
            outerBound: groupBounds,
        });
    });

    const map: Record<string, LayeredGroupNode> = Object.fromEntries(
        matches.map((match) => [match.id, match]),
    );

    matches.forEach((match) => {
        match.depth = getDepth(match.id, map);
    });

    function getDepth(id: string, map: Record<string, LayeredGroupNode>) {
        let depth = 0;
        let groupId = id as string | null;

        while (groupId && map[groupId].groupId) {
            depth++;
            groupId = map[groupId].groupId;
        }

        return depth;
    }

    const sorted = matches.sort((a, b) => a.depth - b.depth);

    return sorted[sorted.length - 1];
};

export const updateMapElementCoords = (
    mapElements: FlowGraphMapElementResponseAPI[],
    groupElements: FlowGraphGroupElementResponseAPI[],
    flowchart: Flowchart,
) => {
    groupElements.forEach((groupElement) => {
        const matchedNode = flowchart.groupNodes[groupElement.id];

        if (matchedNode === undefined) {
            return;
        }

        const hoveredGroup = checkHasDroppedInGroup(matchedNode, flowchart.groupNodes);

        if (hoveredGroup) {
            matchedNode.groupId = hoveredGroup.id;
        } else {
            matchedNode.groupId = null;
        }

        matchedNode.isHovered = false;

        const relativePosition = getNodeRelativePosition(matchedNode, flowchart.groupNodes);

        groupElement.x = relativePosition.x;
        groupElement.y = relativePosition.y;
        groupElement.groupElementId = matchedNode.groupId;
        groupElement.width = matchedNode.width;
        groupElement.height = matchedNode.height;
    });

    mapElements.forEach((mapElement) => {
        const matchedNode = flowchart.leafNodes[mapElement.id];

        if (matchedNode === undefined) {
            return;
        }

        const hoveredGroup = checkHasDroppedInGroup(matchedNode, flowchart.groupNodes);

        calculateIfBoundIsWithinAnotherBounds;

        if (hoveredGroup) {
            matchedNode.groupId = hoveredGroup.id;
        } else {
            matchedNode.groupId = null;
        }

        const relativePosition = getNodeRelativePosition(matchedNode, flowchart.groupNodes);

        mapElement.x = relativePosition.x;
        mapElement.y = relativePosition.y;
        mapElement.groupElementId = matchedNode.groupId;
        mapElement.outcomes?.forEach((outcome) => {
            const affectedEdge = flowchart.edges[outcome.id];
            if (affectedEdge !== undefined && outcome.controlPoints) {
                if (affectedEdge.controlPoint === null) {
                    outcome.controlPoints = [];
                } else {
                    outcome.controlPoints[0] = affectedEdge.controlPoint;
                }
            }
        });
    });

    return {
        mapElements,
        groupElements,
    };
};

export const resizeGroupNode = (
    delta: Point,
    state: FlowEditorState,
    handle: GroupHandle | null,
) => {
    const groupNodes: GroupNodeCollection = {};
    const groupsSnapShot = state.dragSnapshot?.flowchart.groupNodes;

    if (groupsSnapShot && handle) {
        const groupToResizeSnapShot = groupsSnapShot[state.selectedObjectIds[0]];

        state.selectedObjectIds.forEach((id) => {
            groupNodes[id] = state.flowchart.groupNodes[id];
        });

        Object.values(groupNodes).forEach((groupNode) => {
            if (state.dragSnapshot !== null) {
                if (handle.includes('top')) {
                    groupNode.height = snap(groupToResizeSnapShot.height - delta.y);
                    groupNode.y = snap(groupToResizeSnapShot.y + delta.y);
                }
                if (handle.includes('bottom')) {
                    groupNode.height = snap(groupToResizeSnapShot.height + delta.y);
                }
                if (handle.includes('left')) {
                    groupNode.width = snap(groupToResizeSnapShot.width - delta.x);
                    groupNode.x = snap(groupToResizeSnapShot.x + delta.x);
                }
                if (handle.includes('right')) {
                    groupNode.width = snap(groupToResizeSnapShot.width + delta.x);
                }
            }
        });
    }
};
