import DataActionConfiguration from '../common/dataActions/DataActionConfiguration';
import OutcomeDetails from '../common/OutcomeDetails';
import ListenerDetails from '../common/listeners/ListenerDetails';
import screens from '../common/screens';
import { useMapElement } from '../contextProviders/MapElementProvider';
import SaveConfiguration from './SaveConfiguration';

const Save = () => {
    const {
        currentScreen,
        mapElement,
        selectedOutcomeIndex,
        onReturnToDefaultScreen,
        setSelectedOutcomeIndex,
    } = useMapElement();

    let screen = null;

    switch (currentScreen) {
        case screens.save:
            screen = <SaveConfiguration />;
            break;
        case screens.dataAction:
            screen = <DataActionConfiguration />;
            break;
        case screens.outcome: {
            const selectedOutcome =
                typeof selectedOutcomeIndex === 'number' &&
                mapElement?.outcomes?.[selectedOutcomeIndex]
                    ? mapElement?.outcomes?.[selectedOutcomeIndex]
                    : null;

            screen = (
                <OutcomeDetails
                    outcome={selectedOutcome}
                    onSave={onReturnToDefaultScreen}
                    onCancel={() => {
                        onReturnToDefaultScreen();
                        setSelectedOutcomeIndex(null);
                    }}
                />
            );
            break;
        }

        case screens.listener:
            screen = <ListenerDetails />;
            break;
        default:
            break;
    }

    return screen;
};

export default Save;
