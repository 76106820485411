import { useReducer } from 'react';
import FormGroup from '../../generic/FormGroup';
import ButtonDefault from '../../buttons/ButtonDefault';
import ButtonPrimary from '../../buttons/ButtonPrimary';
import Footer from '../../generic/Footer';
import Dependents from '../../Dependents';
import IdentityProviderSAML from './saml/IdentityProviderSAML';
import IdentityProviderOIDC from './oidc/IdentityProviderOIDC';
import IdentityProviderOAUTH2 from './oauth2/IdentityProviderOAUTH2';
import { saveIdentityProvider } from '../../../sources/identityprovider';
import type {
    AuthenticationType,
    IdentityProviderAPI,
    OAUTH2IdentityProviderAPI,
    OIDCIdentityProviderAPI,
    SAMLIdentityProviderAPI,
} from '../../../types';
import { isNullOrEmpty } from '../../../utils/guard';

interface Props {
    itemToEdit: IdentityProviderAPI;
    returnToList: () => void;
}

interface State {
    item: IdentityProviderAPI;
    isValid: boolean;
    hasSubmitted: boolean;
}

export type IdentityAction =
    | { type: 'updateItem'; payload: Partial<IdentityProviderAPI> }
    | { type: 'submit' };

const isValid = (item: IdentityProviderAPI) => {
    if (isNullOrEmpty(item.developerName)) {
        return false;
    }

    if (isNullOrEmpty(item.type)) {
        return false;
    }

    switch (item.type) {
        case 'saml': {
            const provider = item as SAMLIdentityProviderAPI;

            if (
                provider.inactiveSessionTimeout === null ||
                provider.activeSessionTimeout === null
            ) {
                return false;
            }

            if (
                Number.isNaN(provider.inactiveSessionTimeout) ||
                provider.inactiveSessionTimeout < 1
            ) {
                return false;
            }

            if (
                Number.isNaN(provider.activeSessionTimeout) ||
                provider.activeSessionTimeout < 1 ||
                provider.activeSessionTimeout < provider.inactiveSessionTimeout
            ) {
                return false;
            }
            return true;
        }

        case 'oidc': {
            const provider = item as OIDCIdentityProviderAPI;
            if (isNullOrEmpty(provider.clientId)) {
                return false;
            }

            if (isNullOrEmpty(provider.wellKnownUrl)) {
                return false;
            }

            if (isNullOrEmpty(provider.allowedAudience)) {
                return false;
            }

            if (isNullOrEmpty(provider.grantTypes)) {
                return false;
            }

            if (isNullOrEmpty(provider.scope)) {
                return false;
            }

            if (isNullOrEmpty(provider.pkce)) {
                return false;
            }
            return true;
        }

        case 'oauth2': {
            const provider = item as OAUTH2IdentityProviderAPI;
            if (isNullOrEmpty(provider.clientId)) {
                return false;
            }

            if (isNullOrEmpty(provider.wellKnownUrl)) {
                return false;
            }

            if (isNullOrEmpty(provider.allowedAudience)) {
                return false;
            }

            if (isNullOrEmpty(provider.grantTypes)) {
                return false;
            }

            if (isNullOrEmpty(provider.scope)) {
                return false;
            }
            return true;
        }
    }

    return false;
};

const reducer = (state: State, action: IdentityAction): State => {
    switch (action.type) {
        case 'updateItem': {
            const updatedItem = {
                ...state.item,
                ...action.payload,
            };

            const updatedItemIsValid = isValid(updatedItem);

            return {
                ...state,
                item: updatedItem,
                isValid: updatedItemIsValid,
            };
        }

        case 'submit':
            return {
                ...state,
                hasSubmitted: true,
            };
    }
};

const IdentityProviderDetail = ({ itemToEdit, returnToList }: Props) => {
    const initialState: State = {
        item: itemToEdit,
        isValid: false,
        hasSubmitted: false,
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    const onTypeChange: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
        const type = e.target.value as AuthenticationType;

        switch (type) {
            case 'saml': {
                dispatch({
                    type: 'updateItem',
                    payload: {
                        type,
                        metadata: '',
                        entityId: '',
                        flowMetadata: null,
                        inactiveSessionTimeout: 15,
                        activeSessionTimeout: 60,
                        attributeMappings: {
                            email: null,
                            firstname: null,
                            lastname: null,
                            role: null,
                            groups: null,
                            primarygroupid: null,
                            primarygroupname: null,
                        },
                        allowedAudience: '',
                    } as Partial<SAMLIdentityProviderAPI>,
                });
                break;
            }

            case 'oidc': {
                dispatch({
                    type: 'updateItem',
                    payload: {
                        type,
                        clientId: '',
                        clientSecret: '',
                        wellKnownUrl: '',
                        attributeMappings: {
                            email: null,
                            firstname: null,
                            lastname: null,
                            role: null,
                            groups: null,
                        },
                        allowedAudience: '',
                        resource: '',
                        scope: 'email profile openid',
                        pkce: 'None',
                    } as Partial<OIDCIdentityProviderAPI>,
                });
                break;
            }

            case 'oauth2': {
                dispatch({
                    type: 'updateItem',
                    payload: {
                        type,
                        clientId: '',
                        clientSecret: '',
                        wellKnownUrl: '',
                        attributeMappings: {
                            email: null,
                            firstname: null,
                            lastname: null,
                            role: null,
                            groups: null,
                        },
                        allowedAudience: '',
                        resource: '',
                        scope: 'email profile openid',
                    } as Partial<OAUTH2IdentityProviderAPI>,
                });
                break;
            }
        }
    };

    const onNameChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        dispatch({
            type: 'updateItem',
            payload: { developerName: e.currentTarget.value },
        });
    };

    const updateItemOAUTH2 = (item: Partial<OAUTH2IdentityProviderAPI>) =>
        dispatch({
            type: 'updateItem',
            payload: item,
        });

    const updateItemSAML = (item: Partial<SAMLIdentityProviderAPI>) =>
        dispatch({
            type: 'updateItem',
            payload: item,
        });

    const updateItemOIDC = (item: Partial<OIDCIdentityProviderAPI>) =>
        dispatch({
            type: 'updateItem',
            payload: item,
        });

    const onSubmit = async () => {
        dispatch({ type: 'submit' });

        const itemIsValid = isValid(state.item);

        if (itemIsValid) {
            const response = await saveIdentityProvider(state.item);

            if (response) {
                returnToList();
            }
        }
    };

    return (
        <>
            <div className="admin-page">
                <h1>Identity Provider: {state.item.developerName || ''}</h1>

                <FormGroup
                    label="Name"
                    htmlFor="identity-provider-name"
                    isRequired
                    validationMessage="Name field is required"
                    isValid={!isNullOrEmpty(state.item.developerName)}
                    showValidation={state.hasSubmitted}
                >
                    <input
                        id="identity-provider-name"
                        className="form-control form-control-long"
                        value={state.item.developerName ?? ''}
                        onChange={onNameChange}
                        type="text"
                        placeholder="A name for this identity provider"
                    />
                </FormGroup>

                <FormGroup
                    label="Type"
                    htmlFor="identity-provider-type"
                    isRequired
                    validationMessage="Type field is required"
                    isValid={!isNullOrEmpty(state.item.type)}
                    showValidation={state.hasSubmitted}
                >
                    <select
                        id="identity-provider-type"
                        className="form-control form-control-dynamic"
                        value={state.item.type ?? ''}
                        onChange={onTypeChange}
                        disabled={state.item.id !== ''}
                    >
                        <option>Select a type</option>
                        <option value="saml">SAML</option>
                        <option value="oidc">OIDC</option>
                    </select>
                </FormGroup>

                {state.item.type === 'saml' && (
                    <IdentityProviderSAML
                        item={state.item as SAMLIdentityProviderAPI}
                        updateItem={updateItemSAML}
                        hasSubmitted={state.hasSubmitted}
                    />
                )}
                {state.item.type === 'oidc' && (
                    <IdentityProviderOIDC
                        item={state.item as OIDCIdentityProviderAPI}
                        updateItem={updateItemOIDC}
                        hasSubmitted={state.hasSubmitted}
                    />
                )}
                {state.item.type === 'oauth2' && (
                    <IdentityProviderOAUTH2
                        item={state.item as OAUTH2IdentityProviderAPI}
                        updateItem={updateItemOAUTH2}
                        hasSubmitted={state.hasSubmitted}
                    />
                )}

                {state.item.id ? <Dependents id={state.item.id} /> : null}
            </div>
            <Footer>
                <ButtonDefault title="Back" className="flex-child-right" onClick={returnToList}>
                    Back
                </ButtonDefault>
                <ButtonPrimary title="Save" onClick={onSubmit}>
                    Save
                </ButtonPrimary>
            </Footer>
        </>
    );
};

export default IdentityProviderDetail;
