import { useEffect } from 'react';
import Loader from '../../../loader/Loader';
import { addNotification as addNotificationAction } from '../../../../../js/actions/reduxActions/notification';
import { generateSubFlowArgumentSelectors } from './subflowUtils';
import { connect } from 'react-redux';
import { ACCESS_LEVELS } from '../../../../constants';
import translations from '../../../../translations';
import Table, { type TableColumnList } from '../../../generic/Table';
import { useMapElement } from '../contextProviders/MapElementProvider';
import ValueSelectorModal from '../../../values/selector/ValueSelectorModal';
import type {
    AddNotification,
    ValueElementIdAPI,
    ValueElementIdReferenceAPI,
    SubflowArgument,
    FormalValueReference,
} from '../../../../types';

type Props = {
    tenantId: string;
    setSubFlowArguments: (subflowArguments: FormalValueReference[], access: string) => void;
    subFlowId: string;
    valueReferences: ValueElementIdReferenceAPI[];
    access: string;
    subFlowArguments: SubflowArgument[];
    subFlowArgumentsToBeRendered: FormalValueReference[];
    onSelect: (value: ValueElementIdAPI | null, id: string) => void;
    isLoading: boolean;
    addNotification: AddNotification;
};

const InputOutputValues = ({
    tenantId,
    setSubFlowArguments,
    subFlowId,
    valueReferences,
    access,
    subFlowArguments,
    subFlowArgumentsToBeRendered,
    onSelect,
    isLoading,
    addNotification,
}: Props) => {
    const { container } = useMapElement();

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        getTableContents();
    }, [subFlowId]);

    const getTableContents = () => {
        generateSubFlowArgumentSelectors(
            access,
            tenantId,
            subFlowId,
            valueReferences,
            subFlowArguments,
        )
            .then((subFlowArgumentsSelectors) =>
                setSubFlowArguments(subFlowArgumentsSelectors, access),
            )
            .catch(({ message }) => {
                addNotification({
                    type: 'error',
                    message,
                    isPersistent: true,
                });
            });
    };

    // We are only interested in displaying the values of the specified access type
    const rows = subFlowArgumentsToBeRendered.filter(
        (subFlowArgument) => subFlowArgument.access === access,
    );

    const columns: TableColumnList<FormalValueReference> = [
        {
            renderHeader: () => translations.COMMON_TABLE_accepted_value,
            renderCell: ({ item }) => item.formalValueName,
        },
        {
            renderHeader: () => ACCESS_LEVELS[access].description,
            renderCell: ({ item }) =>
                ACCESS_LEVELS[access] === ACCESS_LEVELS['PUBLIC'] ? (
                    ACCESS_LEVELS[access].description
                ) : (
                    <ValueSelectorModal
                        value={item.selectedValueMeta}
                        onChange={(value) => {
                            onSelect(value, item.id);
                        }}
                        container={container}
                        includeSystemValues={false}
                    />
                ),
        },
    ];

    return (
        <div>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <h2>{ACCESS_LEVELS[access].labelShort}</h2>
                    <Table
                        key={ACCESS_LEVELS[access].label}
                        columns={columns}
                        items={rows}
                        isLoading={isLoading}
                        pagination={true}
                    />
                </>
            )}
        </div>
    );
};

const mapDispatchToProps = {
    addNotification: addNotificationAction,
};

export default connect(null, mapDispatchToProps)(InputOutputValues);
