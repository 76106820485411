import { useState } from 'react';
import { CONTENT_TYPES_LIST } from '../../constants';
import { ExCheckbox, ExInput, ExIcon, IconVariant } from '@boomi/exosphere';
import './css/type-property-picker.less';
import translations from '../../translations';
import { stringContains } from '../../utils/string';
import type { TypeElementResponseAPI } from '../../types';

interface Props {
    value: TypeElementResponseAPI;
    scalarPropertiesOnly: boolean;
    onChange: (ids: string[]) => void;
}

const TypePropertyPicker = ({ value, scalarPropertiesOnly, onChange }: Props) => {
    const properties = scalarPropertiesOnly
        ? value.properties?.filter(
              (prop) => prop.contentType !== 'ContentObject' && prop.contentType !== 'ContentList',
          )
        : value.properties;

    const wereAnyColumnsObjectList =
        value?.properties?.length && value?.properties?.length > properties.length;

    const [selectedProperties, setSelectedProperties] = useState<string[]>(
        properties.map((property) => property.id),
    );

    const [search, setSearch] = useState<string>('');

    const onSearchChange = (e: Event) => {
        setSearch((e.currentTarget as HTMLInputElement).value);
    };

    const onPropertyChange = (id: string) => {
        const newSelectedProperties = selectedProperties.includes(id)
            ? selectedProperties.filter((propertyId) => propertyId !== id)
            : [...selectedProperties, id];

        setSelectedProperties(newSelectedProperties);
        onChange(newSelectedProperties);
    };

    const onSelectAll = () => {
        const ids = properties.map((property) => property.id);
        setSelectedProperties(ids);
        onChange(ids);
    };

    const onDeselectAll = () => {
        setSelectedProperties([]);
        onChange([]);
    };

    const items = properties
        ?.filter((property) => stringContains(property?.developerName, search, false))
        ?.map((property) => (
            <div
                className="type-property-picker-property"
                key={property.id}
                title={property.developerName}
            >
                <input
                    type="checkbox"
                    id={property.id}
                    onChange={() => onPropertyChange(property.id)}
                    checked={selectedProperties.includes(property.id)}
                    data-testid={`${property.id}-selection`}
                />
                <label htmlFor={property.id}>
                    <span className="type-property-picker-property-name">
                        {property.developerName}
                    </span>
                    <span className="type-property-picker-property-content-type">
                        {CONTENT_TYPES_LIST?.find((item) => item.key === property.contentType)
                            ?.label ?? ''}
                    </span>
                </label>
            </div>
        ));

    const areAllSelected = selectedProperties.length === properties.length;
    const selectDeselectText = areAllSelected
        ? translations.TYPE_PROPERTY_PICKER_deselect_all
        : translations.TYPE_PROPERTY_PICKER_select_all;

    return (
        <div className="type-property-picker">
            <ExInput
                placeholder={translations.TYPE_PROPERTY_PICKER_search_placeholder}
                clearable={true}
                value={search}
                onInput={onSearchChange}
                type="search"
                data-testid="type-property-picker-search"
            >
                <ExIcon
                    icon="magnifying-glass"
                    label={translations.TYPE_PROPERTY_PICKER_search_placeholder}
                    variant={IconVariant.ICON}
                    slot="prefix"
                />
            </ExInput>
            <ExCheckbox
                checked={areAllSelected}
                onChange={() => {
                    setSearch('');

                    if (areAllSelected) {
                        onDeselectAll();
                    } else {
                        onSelectAll();
                    }
                }}
                indeterminate={
                    selectedProperties.length > 0 && selectedProperties.length !== properties.length
                }
                data-testid="type-property-picker-select-all"
                className="type-property-picker-select-all"
            >
                {selectDeselectText}
            </ExCheckbox>
            <div className="type-property-picker-properties">{items}</div>
            {wereAnyColumnsObjectList && (
                <p>{translations.TYPE_PROPERTY_PICKER_properties_removed}</p>
            )}
        </div>
    );
};

export default TypePropertyPicker;
