import { useEffect, useState, type ReactNode } from 'react';
import ErrorBoundary from '../generic/ErrorBoundary';
import ActivateFailed from './ActivateFailed';
import ActivateSuccess from './ActivateSuccess';
import ActivateSuccessRuntime from './ActivateSuccessRuntime';
import ActivatePublish from './ActivatePublish';
import Modal from '../generic/modal/GenericModal';
import Loader from '../loader/Loader';
import '../../../../css/flow/activate.less';
import { RUNTIME_URI, NOTIFICATION_TYPES } from '../../constants';
import ActivateRuntime from './ActivateRuntime';
import ActivateBuild from './ActivateBuild';
import { EnvironmentsProvider } from '../admin/environments/EnvironmentsProvider';
import { buildFlow, activateFlow, snapshotFlow, deployFlow } from '../../sources/build';
import { getDevelopmentEnvironment } from '../../sources/environments';
import { guid } from '../../utils/guid';
import { connectToEngineSocket, disconnectFromEngineSocket } from '../../mcr/signalRConnection';
import translations from '../../translations';
import ButtonPrimary from '../buttons/ButtonPrimary';
import type { Environment } from '../../types/environment';
import type { TenantRuntime } from '../../types/Tenant';
import type { AddNotification } from '../../types';
import type { CultureApi } from '../../types/translation';
import { getRuntimesForTenant } from '../../sources/runtime';
import { useRun } from '../graph/RunProvider';
import { getLatest } from '../../sources/flow';

interface Props {
    tenantId: string;
    tenantDeveloperName: string;
    isAdmin: boolean;
    isMCR: boolean;
    addNotification: AddNotification;
    flowId: string;
    onClose: () => void;
    container?: HTMLElement | null;
    environmentsEnabled: boolean;
    themesEnabled: boolean;
    loadReleases: () => void;
}

type ViewState =
    | 'LOADING'
    | 'PUBLISH'
    | 'RUNTIME'
    | 'BUILD'
    | 'SUCCESS'
    | 'SUCCESS_RUNTIME'
    | 'FAILED'
    | 'PUBLISH_CONFIRM'
    | 'BUILD_CONFIRM';

const Activate = ({
    tenantId,
    tenantDeveloperName,
    isAdmin,
    isMCR,
    addNotification,
    flowId,
    onClose,
    container,
    environmentsEnabled,
    themesEnabled,
    loadReleases,
}: Props) => {
    const [error, setError] = useState<string | null>(null);
    const [runtimes, setRuntimes] = useState<TenantRuntime[]>([]);
    const [runtime, setRuntime] = useState<TenantRuntime | undefined>(undefined);
    const [developmentEnvironment, setDevelopmentEnvironment] = useState<Environment | null>(null);
    const [comment, setComment] = useState<string | null>(null);
    const [releaseName, setReleaseName] = useState<string | null>(null);
    const [releaseId, setReleaseId] = useState<string | null>(null);
    const [viewState, setViewState] = useState<ViewState>('LOADING');
    const [culture, setCulture] = useState<CultureApi | undefined>(undefined);

    const { player, theme, setPlayer, setTheme, players, themes, getPlayers, getThemes } = useRun();

    const determineBuildView = async () => {
        setViewState('LOADING');
        const isPublic = await isFlowPublic(flowId);
        if (isPublic === true) {
            setViewState('BUILD_CONFIRM');
        } else if (isPublic === false) {
            setViewState('BUILD');
        } else {
            setViewState('BUILD');
        }
    };

    const determinePublishView = async () => {
        setViewState('LOADING');

        await loadRuntimes();
        const isPublic = await isFlowPublic(flowId);
        if (isMCR) {
            setViewState('RUNTIME');
        } else if (!isMCR && isPublic === true) {
            setViewState('PUBLISH_CONFIRM');
        } else if (!isMCR && isPublic === false) {
            setViewState('PUBLISH');
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: Treat warnings as errors, fix later
    useEffect(() => {
        if (environmentsEnabled) {
            determineBuildView();
        } else {
            determinePublishView();
        }
    }, []);

    const loadRuntimes = async () => {
        // Only administrators have read permissions for /api/admin/1/tenant/runtimes
        if (isAdmin && isMCR) {
            setRuntimes((await getRuntimesForTenant()) as TenantRuntime[]);
        }
    };

    const isFlowPublic = async (flowId: string) => {
        try {
            const flow = await getLatest(flowId);
            return (
                flow.authorization?.globalAuthenticationType === 'PUBLIC' &&
                flow.identityProvider === null
            );
        } catch (e) {
            setError((e as Error).message);
            setViewState('FAILED');
        }

        return undefined;
    };

    const onRuntimeChanged = (runtime: TenantRuntime | undefined) => {
        setRuntime(runtime);
    };

    const onReleaseIdChanged = (id: string | null) => {
        setReleaseId(id);
    };

    const onReleaseNameChanged = (name: string | null) => {
        setReleaseName(name);
    };

    const onPublish = async () => {
        setViewState('LOADING');

        try {
            const snapshot = await snapshotFlow({
                flowId,
                comment,
            });

            const {
                id: { versionId },
            } = snapshot;

            if (runtime) {
                const messageId: string = guid();
                const publishPromise = connectToEngineSocket();

                await deployFlow(flowId, versionId, {
                    runtimes: [
                        {
                            id: runtime.id,
                            isActive: true,
                            isDefault: true,
                            messageId,
                        },
                    ],
                });

                try {
                    const deployResult = await publishPromise;

                    if (deployResult.messageId !== messageId) {
                        throw new Error('Returned Multi Cloud Runtime message ID does not match');
                    }
                } finally {
                    await disconnectFromEngineSocket();
                }
            } else {
                await activateFlow(flowId, versionId);
            }

            await getPlayers();
            await getThemes();
        } catch (e) {
            setError((e as Error).message);
            setViewState('FAILED');
        } finally {
            if (runtime) {
                setViewState('SUCCESS_RUNTIME');
            } else {
                setViewState('SUCCESS');
            }
        }
    };

    const onBuild = async () => {
        setError(null);
        setViewState('LOADING');

        await buildFlow({
            releaseId,
            releaseName,
            flowId,
            comment,
        });

        await getPlayers();
        await getThemes();
        loadReleases();

        setDevelopmentEnvironment(await getDevelopmentEnvironment());
        setViewState('SUCCESS');
    };

    let body: ReactNode;
    const footer = [
        <button className="btn btn-default" key="close" onClick={onClose} type="button">
            Close
        </button>,
    ];

    let url = '';

    if (environmentsEnabled && developmentEnvironment && !themesEnabled) {
        url = `${RUNTIME_URI}/${tenantId}/play/${developmentEnvironment.defaultPlayerName}/?flow-id=${flowId}&environment-id=${developmentEnvironment.id}`;
    } else if (environmentsEnabled && themesEnabled && developmentEnvironment) {
        url = `${RUNTIME_URI}/${tenantId}/play/theme/${developmentEnvironment.defaultThemeName}/?flow-id=${flowId}&environment-id=${developmentEnvironment.id}`;
    } else if (themesEnabled) {
        url = `${RUNTIME_URI}/${tenantId}/play/theme/${theme}/?flow-id=${flowId}`;
    } else {
        url = `${RUNTIME_URI}/${tenantId}/play/${player}/?flow-id=${flowId}`;
    }

    if (culture && !culture.isDefault) {
        // New cultures will have a null brand, country, language and variant, for these
        // we want to stick the code e.g. en-US in the url as its nicer
        if (culture?.brand || culture?.country || culture?.language || culture?.variant) {
            url += `&culture=${culture.id}`;
        } else {
            url += `&culture=${culture.code}`;
        }
    }

    switch (viewState) {
        case 'LOADING': {
            body = <Loader />;
            break;
        }

        case 'PUBLISH': {
            body = (
                <ErrorBoundary>
                    <ActivatePublish
                        comment={comment}
                        onCommentChanged={(e) => setComment(e.currentTarget.value)}
                    />
                </ErrorBoundary>
            );
            footer.push(
                <ButtonPrimary onClick={onPublish} key="publish" name="Publish">
                    Publish
                </ButtonPrimary>,
            );
            break;
        }

        case 'PUBLISH_CONFIRM': {
            body = (
                <ErrorBoundary>
                    <div>This Flow is public, are you sure you want to publish?</div>
                </ErrorBoundary>
            );
            footer.push(
                <ButtonPrimary
                    onClick={() => setViewState('PUBLISH')}
                    key="publish-confirm"
                    name="Publish Confirm"
                >
                    {translations.COMMON_confirm}
                </ButtonPrimary>,
            );
            break;
        }

        case 'BUILD_CONFIRM': {
            body = (
                <ErrorBoundary>
                    <div>
                        This Flow is public, are you sure you want to add this build to a release?
                    </div>
                </ErrorBoundary>
            );
            footer.push(
                <ButtonPrimary
                    onClick={() => setViewState('BUILD')}
                    key="build-confirm"
                    name="Build Confirm"
                >
                    {translations.COMMON_confirm}
                </ButtonPrimary>,
            );
            break;
        }

        case 'RUNTIME': {
            body = (
                <ErrorBoundary>
                    <ActivateRuntime
                        tenantId={tenantId}
                        tenantDeveloperName={tenantDeveloperName}
                        runtimes={runtimes}
                        onChange={onRuntimeChanged}
                        comment={comment}
                        onCommentChanged={(e) => setComment(e.currentTarget.value)}
                    />
                </ErrorBoundary>
            );
            footer.push(
                <ButtonPrimary onClick={onPublish} key="publish" name="Publish">
                    Publish
                </ButtonPrimary>,
            );
            break;
        }

        case 'BUILD': {
            body = (
                <ErrorBoundary>
                    <EnvironmentsProvider tenantId={tenantId}>
                        <ActivateBuild
                            onCommentChanged={(e) => setComment(e.currentTarget.value)}
                            onReleaseIdChanged={onReleaseIdChanged}
                            onReleaseNameChanged={onReleaseNameChanged}
                            isThemeBuild={false}
                        />
                    </EnvironmentsProvider>
                </ErrorBoundary>
            );
            footer.push(
                <ButtonPrimary
                    onClick={onBuild}
                    disabled={!(releaseName || releaseId)}
                    key="build"
                    name="Build"
                >
                    {translations.ENVIRONMENT_build_flow}
                </ButtonPrimary>,
            );
            break;
        }

        case 'SUCCESS': {
            body = (
                <ErrorBoundary>
                    <ActivateSuccess
                        flowId={flowId}
                        url={url}
                        players={players}
                        selectedPlayer={player}
                        setSelectedPlayer={(e) => setPlayer(e.currentTarget.value)}
                        themes={themes}
                        selectedTheme={theme}
                        setSelectedTheme={(e) => setTheme(e.currentTarget.value)}
                        themesEnabled={themesEnabled}
                        environmentsEnabled={environmentsEnabled}
                        onError={(error) =>
                            addNotification({
                                message: error,
                                isPersistent: true,
                                type: NOTIFICATION_TYPES.error,
                            })
                        }
                        culture={culture}
                        onCultureChange={setCulture}
                    />
                </ErrorBoundary>
            );
            footer.push(
                <a
                    className="btn btn-primary"
                    key="run"
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Run
                </a>,
            );
            break;
        }

        case 'SUCCESS_RUNTIME': {
            body = runtime ? (
                <ErrorBoundary>
                    <ActivateSuccessRuntime
                        flowId={flowId}
                        tenantId={tenantId}
                        selectedRuntime={runtime}
                    />
                </ErrorBoundary>
            ) : null;
            break;
        }

        case 'FAILED': {
            body = (
                <ActivateFailed
                    error={error}
                    retry={environmentsEnabled ? onBuild : onPublish}
                    environmentsEnabled={environmentsEnabled}
                    isMCR={!!runtime}
                />
            );
            break;
        }
    }

    const bodyClassName =
        viewState === 'FAILED'
            ? 'text-center'
            : viewState === 'SUCCESS' || viewState === 'SUCCESS_RUNTIME'
              ? 'activation-successful'
              : viewState === 'PUBLISH_CONFIRM' || viewState === 'BUILD_CONFIRM'
                ? 'auto-height'
                : '';

    return (
        <Modal
            show
            onHide={onClose}
            container={container}
            className="config-modal"
            title={environmentsEnabled ? 'Build Flow' : 'Publish Flow'}
            headerClassName="pendo-publish-flow-modal-header"
            bodyClassName={bodyClassName}
            renderBody={() => <>{body}</>}
            renderFooter={() => <>{footer}</>}
        />
    );
};

export default Activate;
