import { useCallback, useEffect, useReducer } from 'react';
import { deleteSnapshot, deleteTheme, getThemeList, getThemeSnapshots } from '../../sources/theme';
import type {
    AddNotification,
    Filter,
    ItemCollectionResponse,
    OrderDirection,
    Paging,
    ThemeAPI,
    ThemeEdit,
    ThemeSnapshotInfoAPI,
} from '../../types';
import Table, { type TableColumnList } from '../generic/Table';
import {
    ExButton,
    ButtonFlavor,
    ButtonType,
    ButtonSize,
    ExDialog,
    ExIcon,
    IconVariant,
} from '@boomi/exosphere';
import { ArrowsClockwise, Plus, Trash } from '@phosphor-icons/react';
import translations from '../../translations';
import Sortable from '../generic/Sortable';
import SearchInput from '../generic/SearchInput';
import { NOTIFICATION_TYPES } from '../../constants/notification';

type ViewState = 'list' | 'loading';

interface State {
    items: ThemeAPI[];
    viewState: ViewState;
    selectedThemeId: null | string;
    selectedThemeName: null | string;
    showConfirmDeleteModal: boolean;
    isDeleting: boolean;
    search: string;
    showSnapshotsModal: boolean;
    snapshots: ThemeSnapshotInfoAPI[];
    paging: Paging;
    sortBy: {
        property: keyof ThemeAPI;
        direction: OrderDirection;
    };
}

const initialState: State = {
    items: [],
    viewState: 'list',
    selectedThemeId: null,
    selectedThemeName: null,
    showConfirmDeleteModal: false,
    isDeleting: false,
    search: '',
    showSnapshotsModal: false,
    snapshots: [],
    paging: {
        total: 0,
        pageSize: 20,
        page: 1,
    },
    sortBy: {
        property: 'dateModified',
        direction: 'DESC',
    },
};

const createTheme = (): ThemeEdit => ({
    id: null,
    developerName: '',
    developerSummary: null,
    cssURLs: [],
    cssContent: null,
    jsURLs: [],
    jsContent: null,
    elementType: 'THEME',
    documentTitle: null,
    logoURL: null,
    title: null,
    properties: {
        '--color-primary': '#033d58',
        '--color-primary-font': '#ffffff',
        '--color-font': '#454545',
        '--color-background': '#ffffff',
        '--color-border': '#454545',
        '--color-highlight-background': '#4d7689',
        '--color-highlight-font': '#ffffff',
        '--color-selected-background': '#127b87',
        '--color-selected-font': '#ffffff',
        '--color-background-alternate': '#eeeeee',
        '--font-family':
            "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
    },
    discourageSearchEngines: true,
});

type Action =
    | { type: 'itemsLoadRequest' }
    | {
          type: 'itemsLoadSuccess';
          payload: { response: ItemCollectionResponse<ThemeAPI> };
      }
    | { type: 'itemsLoadFail' }
    | {
          type: 'clickThemeDelete';
          payload: { themeId: string };
      }
    | {
          type: 'themeDeleteCancel';
      }
    | {
          type: 'themeDeleteRequest';
      }
    | {
          type: 'themeDeleteSuccess';
      }
    | {
          type: 'themeDeleteFail';
      }
    | {
          type: 'sort';
          payload: { property: keyof ThemeAPI; direction: OrderDirection; paging: Paging };
      }
    | {
          type: 'themeSearch';
          payload: { search: string };
      }
    | {
          type: 'clickManageSnapshots';
      }
    | {
          type: 'loadSnapshotsSuccess';
          payload: ThemeSnapshotInfoAPI[];
      }
    | {
          type: 'loadSnapshotsFail';
      }
    | {
          type: 'manageSnapshotsDone';
      }
    | {
          type: 'clickThemeSnapshotDelete';
          payload: { themeName: string };
      }
    | {
          type: 'finishThemeSnapshotDelete';
      };

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'itemsLoadRequest':
            return {
                ...state,
                viewState: 'loading',
            };

        case 'itemsLoadSuccess':
            return {
                ...state,
                items: action.payload.response.items,
                viewState: 'list',
                paging: action.payload.response._meta,
            };

        case 'itemsLoadFail':
            return {
                ...state,
                viewState: 'list',
            };

        case 'clickThemeDelete':
            return {
                ...state,
                selectedThemeId: action.payload.themeId,
                showConfirmDeleteModal: true,
            };

        case 'themeDeleteCancel':
            return {
                ...state,
                showConfirmDeleteModal: false,
                selectedThemeId: null,
            };

        case 'themeDeleteRequest':
            return {
                ...state,
                isDeleting: true,
            };

        case 'themeDeleteSuccess':
            return {
                ...state,
                isDeleting: false,
                showConfirmDeleteModal: false,
                selectedThemeId: null,
            };

        case 'themeDeleteFail':
            return {
                ...state,
                isDeleting: false,
                showConfirmDeleteModal: false,
                selectedThemeId: null,
            };

        case 'sort':
            return {
                ...state,
                sortBy: {
                    property: action.payload.property,
                    direction: action.payload.direction,
                },
                paging: { ...state.paging, page: 1 },
            };
        case 'themeSearch':
            return {
                ...state,
                search: action.payload.search,
                paging: { ...state.paging, page: 1 },
            };
        case 'clickManageSnapshots':
            return {
                ...state,
                showSnapshotsModal: true,
            };
        case 'manageSnapshotsDone':
            return {
                ...state,
                showSnapshotsModal: false,
            };
        case 'loadSnapshotsSuccess':
            return { ...state, snapshots: action.payload };
        case 'loadSnapshotsFail':
            return { ...state, showSnapshotsModal: false, snapshots: [] };
        case 'clickThemeSnapshotDelete':
            return { ...state, selectedThemeName: action.payload.themeName };
        case 'finishThemeSnapshotDelete':
            return { ...state, selectedThemeName: null };
    }
};

interface Props {
    addNotification: AddNotification;
    setItemToEdit: (item: ThemeEdit) => void;
}

const ThemeList = ({ setItemToEdit, addNotification }: Props) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const fetchItems = useCallback(
        async (filter?: Filter) => {
            dispatch({ type: 'itemsLoadRequest' });
            try {
                const response = await getThemeList(filter);
                dispatch({ type: 'itemsLoadSuccess', payload: { response } });
            } catch (error) {
                dispatch({ type: 'itemsLoadFail' });
                addNotification({
                    type: NOTIFICATION_TYPES.error,
                    message: (error as Error).message,
                    isPersistent: true,
                });
            }
        },
        [addNotification],
    );

    const sortItems = useCallback(
        async ({
            property,
            direction,
            state,
        }: {
            property: keyof ThemeAPI;
            direction: OrderDirection;
            state: State;
        }) => {
            try {
                await fetchItems({
                    search: state.search,
                    orderDirection: direction,
                    orderBy: property,
                    page: 1,
                    pageSize: state.paging.pageSize,
                });
                dispatch({ type: 'sort', payload: { property, direction, paging: state.paging } });
            } catch (error) {
                addNotification({
                    type: NOTIFICATION_TYPES.error,
                    message: (error as Error).message,
                    isPersistent: true,
                });
            }
        },
        [addNotification, fetchItems],
    );

    const onPageChange = useCallback(
        async (page: number, state: State) => {
            await fetchItems({
                search: state.search,
                orderBy: state.sortBy.property,
                orderDirection: state.sortBy.direction,
                page: page,
                pageSize: state.paging.pageSize,
            });
        },
        [fetchItems],
    );

    const onSearch = useCallback(
        async (searchTerm: string, state: State) => {
            dispatch({ type: 'themeSearch', payload: { search: searchTerm } });
            await fetchItems({
                search: searchTerm,
                orderBy: state.sortBy.property,
                orderDirection: state.sortBy.direction,
                page: 1,
                pageSize: state.paging.pageSize,
            });
        },
        [fetchItems],
    );

    const onRefresh = useCallback(
        async (state: State) => {
            await fetchItems({
                search: state.search,
                orderBy: state.sortBy.property,
                orderDirection: state.sortBy.direction,
                page: state.paging.page,
                pageSize: state.paging.pageSize,
            });
        },
        [fetchItems],
    );

    const deleteThemeConfirm = useCallback(async () => {
        if (state.selectedThemeId === null) {
            return;
        }

        dispatch({ type: 'themeDeleteRequest' });
        try {
            await deleteTheme({ themeId: state.selectedThemeId });
            dispatch({ type: 'themeDeleteSuccess' });
            await fetchItems();
        } catch (error) {
            dispatch({ type: 'themeDeleteFail' });
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: (error as Error).message,
                isPersistent: true,
            });
        }
    }, [addNotification, fetchItems, state.selectedThemeId]);

    const onManageSnapshots = useCallback(async () => {
        dispatch({ type: 'clickManageSnapshots' });
        try {
            const response = await getThemeSnapshots();
            dispatch({ type: 'loadSnapshotsSuccess', payload: response });
        } catch (error) {
            dispatch({ type: 'loadSnapshotsFail' });
            addNotification({
                type: NOTIFICATION_TYPES.error,
                message: (error as Error).message,
                isPersistent: true,
            });
        }
    }, [addNotification]);

    const deleteSnapshotConfirm = useCallback(
        async (themeName: string) => {
            if (!themeName) return;
            try {
                await deleteSnapshot({ themeName });
                addNotification({
                    type: NOTIFICATION_TYPES.success,
                    message: 'Snapshot deleted successfully.',
                    isPersistent: true,
                });
                dispatch({ type: 'finishThemeSnapshotDelete' });
                const response = await getThemeSnapshots();
                dispatch({ type: 'loadSnapshotsSuccess', payload: response });
            } catch (error) {
                dispatch({ type: 'finishThemeSnapshotDelete' });
                addNotification({
                    type: NOTIFICATION_TYPES.error,
                    message: (error as Error).message,
                    isPersistent: true,
                });
            }
        },
        [addNotification],
    );

    const columns: TableColumnList<ThemeAPI> = [
        {
            renderHeader: () => (
                <Sortable
                    onSort={(direction) =>
                        sortItems({ property: 'developerName', direction, state })
                    }
                    direction={
                        state.sortBy.property === 'developerName' ? state.sortBy.direction : null
                    }
                    defaultDirection={'ASC'}
                >
                    Name
                </Sortable>
            ),
            renderCell: ({ item: theme }) => (
                <button className="link-emulate" onClick={() => setItemToEdit(theme)} type="button">
                    {theme.developerName}
                </button>
            ),
        },
        {
            renderHeader: () => 'Description',
            renderCell: ({ item: theme }) => theme.developerSummary,
        },
        {
            renderHeader: () => (
                <Sortable
                    onSort={(direction) =>
                        sortItems({ property: 'dateModified', direction, state })
                    }
                    direction={
                        state.sortBy.property === 'dateModified' ? state.sortBy.direction : null
                    }
                    defaultDirection={'DESC'}
                >
                    Date Modified
                </Sortable>
            ),
            renderCell: ({ item: theme }) => new Date(theme.dateModified).toLocaleString(),
        },
        {
            renderHeader: () => 'Actions',
            renderCell: ({ item: theme }) => (
                <div className="action-btn-wrapper">
                    <button
                        className="table-icon table-icon-delete"
                        onClick={() =>
                            dispatch({ type: 'clickThemeDelete', payload: { themeId: theme.id } })
                        }
                        aria-label={`${translations.COMMON_delete} ${theme.developerName}`}
                        type="button"
                    >
                        <Trash />
                    </button>
                </div>
            ),
            size: '5rem',
        },
    ];

    const snapshotColumns: TableColumnList<ThemeSnapshotInfoAPI> = [
        {
            renderHeader: () => 'Name',
            renderCell: ({ item: snapshot }) => snapshot.developerName,
        },
        {
            renderHeader: () => 'Snapshot Created Date',
            renderCell: ({ item: snapshot }) => new Date(snapshot.dateCreated).toLocaleString(),
        },
        {
            renderHeader: () => 'Actions',
            renderCell: ({ item: snapshot }) => (
                <div className="action-btn-wrapper">
                    <button
                        className="table-icon table-icon-delete"
                        onClick={() =>
                            dispatch({
                                type: 'clickThemeSnapshotDelete',
                                payload: { themeName: snapshot.developerName },
                            })
                        }
                        aria-label={`${translations.COMMON_delete} ${snapshot.developerName}`}
                        type="button"
                    >
                        <Trash />
                    </button>
                </div>
            ),
            size: '5rem',
        },
    ];

    useEffect(() => {
        fetchItems({
            page: initialState.paging.page,
            pageSize: initialState.paging.pageSize,
            orderBy: initialState.sortBy.property,
            orderDirection: initialState.sortBy.direction,
        });
    }, [fetchItems]);

    return (
        <div className="admin-page theme-list flow-wrapper">
            <h1>Themes</h1>
            <div className="flow-controls-bar">
                <div className="controls-left">
                    <SearchInput
                        value={''}
                        onChange={(search: string) => onSearch(search, state)}
                        placeholder={translations.HOME_flow_search_placeholder}
                    />
                </div>
                <div className="controls-right">
                    <ExButton
                        className="flex-child-right"
                        onClick={() => setItemToEdit(createTheme())}
                        flavor={ButtonFlavor.BRANDED}
                        type={ButtonType.PRIMARY}
                        size={ButtonSize.DEFAULT}
                    >
                        <Plus /> <p>New Theme</p>
                    </ExButton>
                    <ExButton
                        title={'Manage Snapshots'}
                        className="flex-child-right"
                        onClick={onManageSnapshots}
                        flavor={ButtonFlavor.BRANDED}
                        type={ButtonType.SECONDARY}
                        size={ButtonSize.DEFAULT}
                    >
                        Manage Snapshots
                    </ExButton>
                    <ExButton
                        aria-label={translations.COMMON_refresh_results}
                        title={translations.COMMON_refresh_results}
                        onClick={() => onRefresh(state)}
                        flavor={ButtonFlavor.BRANDED}
                        type={ButtonType.SECONDARY}
                        size={ButtonSize.DEFAULT}
                    >
                        <ArrowsClockwise />
                    </ExButton>
                </div>
            </div>
            <Table<ThemeAPI>
                wrapperClassName="margin-top"
                items={state.items}
                columns={columns}
                isLoading={state.viewState === 'loading'}
                pagination={{
                    ...state.paging,
                    changePage: (page: number) => onPageChange(page, state),
                }}
            />
            {state.showConfirmDeleteModal && (
                <ExDialog
                    open
                    dialogTitle="Delete this theme?"
                    onCancel={() => dispatch({ type: 'themeDeleteCancel' })}
                >
                    This will permanently delete the theme.
                    <ExIcon slot="icon" icon="trash" variant={IconVariant.DANGER} />
                    <ExButton
                        slot="footer"
                        flavor={ButtonFlavor.RISKY}
                        type={ButtonType.SECONDARY}
                        size={ButtonSize.LARGE}
                        onClick={deleteThemeConfirm}
                    >
                        {translations.COMMON_delete}
                    </ExButton>
                    <ExButton
                        slot="footer"
                        flavor={ButtonFlavor.BRANDED}
                        type={ButtonType.SECONDARY}
                        size={ButtonSize.LARGE}
                        onClick={() => dispatch({ type: 'themeDeleteCancel' })}
                    >
                        {translations.COMMON_cancel}
                    </ExButton>
                </ExDialog>
            )}
            {state.showSnapshotsModal && (
                <ExDialog
                    open
                    dialogTitle={
                        !state.selectedThemeName
                            ? 'Manage Theme Snapshots'
                            : 'Delete this snapshot?'
                    }
                    onCancel={() => dispatch({ type: 'manageSnapshotsDone' })}
                >
                    {!state.selectedThemeName && (
                        <>
                            <span className="body-text">
                                <p>
                                    A snapshot is a version of the theme which is created each time
                                    a change to a theme is made.
                                </p>
                                <p>
                                    Deleting a snapshot here will remove it from the themes
                                    available when running Flows, this is useful to remove themes
                                    which have been renamed or deleted.
                                </p>
                                <p>
                                    With environments enabled this will remove snapshots from all
                                    environments and releases.
                                </p>
                            </span>
                            <Table<ThemeSnapshotInfoAPI>
                                wrapperClassName="margin-top ninety-width"
                                items={state.snapshots}
                                columns={snapshotColumns}
                            />
                            <ExButton
                                slot="footer"
                                flavor={ButtonFlavor.BRANDED}
                                type={ButtonType.SECONDARY}
                                size={ButtonSize.LARGE}
                                onClick={() => dispatch({ type: 'manageSnapshotsDone' })}
                            >
                                {translations.COMMON_close}
                            </ExButton>
                        </>
                    )}
                    {state.selectedThemeName && (
                        <>
                            This will permanently delete the snapshot.
                            <ExButton
                                slot="footer"
                                flavor={ButtonFlavor.RISKY}
                                type={ButtonType.SECONDARY}
                                size={ButtonSize.LARGE}
                                onClick={() => deleteSnapshotConfirm(state.selectedThemeName ?? '')}
                            >
                                {translations.COMMON_delete}
                            </ExButton>
                            <ExButton
                                slot="footer"
                                flavor={ButtonFlavor.BRANDED}
                                type={ButtonType.SECONDARY}
                                size={ButtonSize.LARGE}
                                onClick={() => dispatch({ type: 'finishThemeSnapshotDelete' })}
                            >
                                {translations.COMMON_back}
                            </ExButton>
                        </>
                    )}
                </ExDialog>
            )}
        </div>
    );
};

export default ThemeList;
