import '../../../../../css/modal.less';
import ButtonPrimary from '../../buttons/ButtonPrimary';
import GenericModal from './GenericModal';
import translations from '../../../translations';
import ButtonLoading from '../../buttons/ButtonLoading';
import ButtonDefault from '../../buttons/ButtonDefault';
import ButtonDanger from '../../buttons/ButtonDanger';
import type { Confirmation } from '../../../types';
import type { ReactElement } from 'react';

const actionCaption = (caption: string) => {
    switch (caption) {
        case translations.COMMON_delete:
            return 'Deleting...';
        case translations.COMMON_remove:
            return 'Removing...';
        default:
            return 'Working...';
    }
};
const ConfirmModal = ({
    show,
    onCancel,
    onConfirm,
    title,
    messages,
    container,
    buttonStyle = 'primary',
    buttonCaption = translations.COMMON_confirm,
    isInProgress = null, // use the LoadingButton with the spinner if progress is given
}: Confirmation) =>
    show ? (
        <GenericModal
            className="confirm-modal"
            title={title}
            container={container}
            onHide={onCancel}
            renderBody={() => (
                <div className="container-fluid">
                    {messages.map((message) => (
                        <div
                            className="message-line"
                            key={(message as ReactElement)?.key ?? (message as string)}
                        >
                            {message}
                        </div>
                    ))}
                </div>
            )}
            renderHeader={() => <h4 className="modal-title">{title}</h4>}
            renderFooter={() => (
                <>
                    {isInProgress === null ? (
                        <>
                            <ButtonDefault onClick={onCancel}>
                                {translations.COMMON_cancel}
                            </ButtonDefault>
                            {buttonStyle === 'danger' ? (
                                <ButtonDanger onClick={onConfirm}>{buttonCaption}</ButtonDanger>
                            ) : (
                                <ButtonPrimary onClick={onConfirm}>{buttonCaption}</ButtonPrimary>
                            )}
                        </>
                    ) : (
                        <>
                            <ButtonDefault onClick={onCancel} disabled={isInProgress}>
                                {translations.COMMON_cancel}
                            </ButtonDefault>
                            <ButtonLoading
                                className="btn btn-danger"
                                onClick={onConfirm}
                                isLoading={isInProgress}
                            >
                                {isInProgress ? actionCaption(buttonCaption) : buttonCaption}
                            </ButtonLoading>
                        </>
                    )}
                </>
            )}
        />
    ) : null;

export default ConfirmModal;
