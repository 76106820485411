import { CONTENT_TYPES_LIST } from '../../constants';
import { ExIcon } from '@boomi/exosphere';
import translations from '../../translations';
import type { TypeElementPropertyAPI, TypeElementResponseAPI } from '../../types';

type Types = Record<string, TypeElementResponseAPI>;

interface Props {
    property: TypeElementPropertyAPI;
    isMatched: boolean;
    showMatch: boolean;
    types: Types;
}

const PropertyDetails = ({ property, isMatched, showMatch, types }: Props) => {
    const typeName = property.typeElementId
        ? types[property.typeElementId].developerName
        : CONTENT_TYPES_LIST?.find((item) => item.key === property.contentType)?.label;

    return (
        <div className="property-details" data-testid={`property-details-${property.id}`}>
            <div className="property-name clipped-text">
                <span>{property.developerName}</span>
                {!isMatched && showMatch ? (
                    <ExIcon
                        icon="circle-warning"
                        className="unmatched-icon"
                        title={translations.AUTO_MAPPING_type_no_property_match}
                        data-testid="unmatched-icon"
                    />
                ) : null}
            </div>
            <span className="property-type clipped-text">{typeName}</span>
            {showMatch ? (
                <div style={{ position: 'relative', visibility: isMatched ? 'visible' : 'hidden' }}>
                    <div className="property-match" />
                    <div className="property-match-end" />
                </div>
            ) : null}
        </div>
    );
};

export default PropertyDetails;
