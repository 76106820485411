import translations from '../../translations';
import InviteList from './InviteList';
import { useOrganizationInvite } from './OrganizationInviteProvider';

type PendingTenantInviteListProps = {
    organizationId: string;
};

const PendingTenantInviteList = ({ organizationId }: PendingTenantInviteListProps) => {
    const {
        sentTenantInvites,
        loadSentTenantInvites,
        acknowledgeRejectedTenantInvite,
        cancelTenantInvite,
    } = useOrganizationInvite();

    return (
        <InviteList
            caption={translations.FORG_tenant_invite_list_caption}
            invitations={sentTenantInvites}
            organizationId={organizationId}
            loadInvites={loadSentTenantInvites}
            onAcknowledge={acknowledgeRejectedTenantInvite}
            onCancel={cancelTenantInvite}
        />
    );
};

export default PendingTenantInviteList;
