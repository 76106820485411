import classNames from 'classnames';
import { useDebug } from '../DebugProvider';
import { ExEmptyState, ExIcon, ExCheckbox, ExIconButton, IconButtonType } from '@boomi/exosphere';
import ComponentWithTooltip from '../../../generic/tooltip/ComponentWithTooltip';

const CallStack = () => {
    const {
        callStack,
        isDebuggerAttached,
        onClickCallStackItem,
        callStackItemsArePinned,
        setCallStackItemsPinned,
        dispatch,
    } = useDebug();

    if (!isDebuggerAttached) {
        return (
            <div className="full-height flex align-center">
                <div className="center">
                    <ExEmptyState
                        label="The debugger is not connected"
                        text="Enter a state ID to connect to a running state"
                    >
                        <ExIcon icon="Offline" slot="header" />
                    </ExEmptyState>
                </div>
            </div>
        );
    }

    if (isDebuggerAttached && !callStack.length) {
        return (
            <div className="full-height flex align-center">
                <div className="center callstack-waiting">Waiting...</div>
            </div>
        );
    }

    return (
        <>
            <div className="callstack-header flex align-center">Call Stack</div>
            <div className="full-height call-stack-outer" data-testid="call-stack">
                <div className="call-stack-inner">
                    {callStack.map((callStackItem) => {
                        const hasRootFaults = callStackItem.rootFaults !== null;
                        const hasHitBreakpoints = callStackItem.breakpointsHit !== null;
                        const classes = classNames({
                            'call-stack-item': true,
                            'is-selected': callStackItem.isSelected,
                        });

                        return (
                            <button
                                onClick={() => onClickCallStackItem(callStackItem.id)}
                                className={classes}
                                key={callStackItem.id}
                                type="button"
                            >
                                <span>{callStackItem.developerName}</span>
                                {hasRootFaults && (
                                    <ComponentWithTooltip
                                        trigger={['hover', 'focus']}
                                        fadeTime={0.1}
                                        tooltipContent={
                                            Object.keys(callStackItem.rootFaults || []).length
                                        }
                                        tooltipPlacement="right"
                                        clearance={10}
                                        arrowClearance={7}
                                    >
                                        <ExIcon className="root-faults-found" icon="stop-filled" />
                                    </ComponentWithTooltip>
                                )}
                                {hasHitBreakpoints && (
                                    <ComponentWithTooltip
                                        trigger={['hover', 'focus']}
                                        fadeTime={0.1}
                                        tooltipContent={`${callStackItem.breakpointsHit?.length} hits`}
                                        tooltipPlacement="right"
                                        clearance={10}
                                        arrowClearance={7}
                                    >
                                        <ExIcon className="break-points-found" icon="stop-filled" />
                                    </ComponentWithTooltip>
                                )}
                                <span className="flex-child-right">
                                    {callStackItem.dateTimeExecuted}
                                </span>
                            </button>
                        );
                    })}
                </div>
            </div>
            <span className="call-stack-footer flex align-center padding">
                <ExCheckbox
                    checked={callStackItemsArePinned}
                    onChange={({ target }: CustomEvent) => {
                        setCallStackItemsPinned((target as HTMLInputElement).checked);
                    }}
                >
                    Pin selected items
                </ExCheckbox>
                <div className="flex-child-right">
                    <ComponentWithTooltip
                        trigger={['hover', 'focus']}
                        fadeTime={0.1}
                        tooltipContent="Clear call stack"
                        tooltipPlacement="right"
                        clearance={10}
                        arrowClearance={7}
                    >
                        <ExIconButton
                            data-testid="clear-callstack"
                            circular
                            hideBrowserTooltip
                            onClick={() => dispatch({ type: 'clearCallStack' })}
                            icon="Disabled"
                            type={IconButtonType.SECONDARY}
                        />
                    </ComponentWithTooltip>
                </div>
            </span>
        </>
    );
};

export default CallStack;
