import translations from '../../../../translations';

import { useState, createContext, useContext } from 'react';
import { useMapElement } from './MapElementProvider';
import { getType } from '../../../../sources/type';
import { FILTER_TYPES } from '../common/constants';
import type {
    CriteriaType,
    DataAction,
    DataActionListFilter,
    OperationType,
    ValueElementIdAPI,
    ValueElementIdReferenceAPI,
    WhereStatement,
} from '../../../../types';
import type { Screen } from '../common/screens';

const DATA_ACTION_SCREENS: Record<string, { key: Screen; label: string }> = {
    detail: { key: 'dataAction', label: 'Data Action' },
    filter: { key: 'filter', label: 'Filter' },
    criteria: { key: 'criteria', label: 'Criteria' },
};

type Props = {
    defaultScreen: Screen;
    crudOperationType: OperationType;
    children: React.ReactNode;
};

type CriteriaEdit = {
    criteria: WhereStatement | null;
    index: number | null;
};

type DataActionEdit = { dataAction: DataAction | null; index: number | null };

type ContextValue = {
    dataActionToEdit: DataActionEdit;
    onCreateDataAction: () => void;
    onEditDataAction: (dataAction: DataAction, index: number) => void;
    onDeleteDataAction: (index: number) => void;
    onSetDataActionLoadValue: (value: ValueElementIdReferenceAPI | null) => void;
    onUpdateDataActionName: (name: string) => void;
    onUpdateDataActionBinding: (bindingId: string) => void;
    onApplyDataAction: (index: number | null) => void;
    onCreateDataActionFilterCriteria: () => void;
    onEditDataActionFilterCriteria: (criteria: WhereStatement, index: number) => void;
    onDeleteDataActionFilterCriteria: (index: number) => void;
    onReturnToDataActionScreen: () => void;
    onReturnToDataActionFilterScreen: () => void;
    onReturnToDefaultScreen: () => void;
    onSetFilterComparisonType: (comparisonType: string) => void;
    onSetFilterOrderDirectionType: (orderByDirectionType: string) => void;
    onSetFilterOrderByTypePropertyId: (orderByTypeElementPropertyId: string) => void;
    onSetFilterType: (filterType: string) => void;
    onApplyCriteria: (index: number) => void;
    onSetCriteriaTypePropertyId: (
        columnTypeElementPropertyId: string,
        columnTypeElementPropertyDeveloperName: string,
    ) => void;
    onSetCriteriaType: (criteriaType: CriteriaType, criteriaTypeFriendly: string) => void;
    onSetCriteriaValueElementToReference: (value: ValueElementIdReferenceAPI | null) => void;
    criteriaToEdit: CriteriaEdit;
    onSetFilterId: (value: ValueElementIdAPI | null) => void;
    onEditDataActionFilter: () => void;
    filterToEdit: DataActionListFilter | null;
    onApplyDataActionFilter: () => void;
    onUpdateDataActionOrder: (order: number) => void;
    onUpdateDataActionDisabled: ({ isOn }: { isOn: boolean }) => void;
    onUpdateDataActionSmartSave: ({ isOn }: { isOn: boolean }) => void;
    onSetFilterRecordLimit: (limit: number) => void;
};

const Context = createContext<ContextValue | undefined>(undefined);

const DataActionProvider = ({ defaultScreen, crudOperationType, children }: Props) => {
    const {
        mapElement,
        onPushBreadCrumb,
        setMapElement,
        onSwitchScreen,
        onSetActiveBreadCrumb,
        notifyError,
    } = useMapElement();

    const initialDataAction = {
        crudOperationType,
        developerName: null,
        disabled: false,
        isSmartSave: false,
        objectDataRequest: null,
        order: 0,
        valueElementToApplyId: {
            command: null,
            id: null,
            typeElementPropertyId: null,
        },
        valueElementToReferenceId: {
            command: null,
            id: null,
            typeElementPropertyId: null,
        },
    };

    const initialCriteria = {
        columnTypeElementPropertyDeveloperName: null,
        columnTypeElementPropertyId: null,
        criteriaType: null,
        criteriaTypeFriendly: null,
        valueElementToReferenceDeveloperName: null,
        valueElementToReferenceId: {
            command: null,
            id: null,
            typeElementPropertyId: null,
        },
    };

    const [dataActionToEdit, setDataActionToEdit] = useState<DataActionEdit>({
        dataAction: null,
        index: null,
    });
    const [filterToEdit, setFilterToEdit] = useState<DataActionListFilter | null>(null);
    const [criteriaToEdit, setCriteriaToEdit] = useState<CriteriaEdit>({
        criteria: null,
        index: null,
    });

    const onCreateDataAction = () => {
        if (mapElement.dataActions) {
            const totalDataActions = mapElement.dataActions.length;
            setDataActionToEdit({
                dataAction: { ...initialDataAction, order: totalDataActions },
                index: totalDataActions + 1,
            });
            onSwitchScreen(DATA_ACTION_SCREENS['detail'].key);
            onPushBreadCrumb(
                DATA_ACTION_SCREENS['detail'].key,
                DATA_ACTION_SCREENS['detail'].label,
            );
        }
    };

    const onEditDataAction = (dataAction: DataAction, index: number) => {
        setDataActionToEdit({ dataAction, index });
        onSwitchScreen(DATA_ACTION_SCREENS['detail'].key);
        onPushBreadCrumb(DATA_ACTION_SCREENS['detail'].key, DATA_ACTION_SCREENS['detail'].label);
    };

    const onDeleteDataAction = (index: number) => {
        if (mapElement.dataActions) {
            setMapElement({
                ...mapElement,
                dataActions: mapElement.dataActions.filter((_, i) => i !== index),
            });
        }
    };

    const checkIsServiceType = async (id: string) => {
        try {
            const type = await getType(id);
            return !!type.serviceElementId;
        } catch (error) {
            notifyError((error as Error).message);
            return false;
        }
    };

    const onSetDataActionLoadValue = async (value: ValueElementIdReferenceAPI | null) => {
        if (dataActionToEdit.dataAction) {
            const isServiceType = value?.typeElementId
                ? await checkIsServiceType(value.typeElementId)
                : false;
            if (!value || (value && isServiceType)) {
                const dataAction = {
                    ...dataActionToEdit.dataAction,
                    objectDataRequest: {
                        ...dataActionToEdit.dataAction.objectDataRequest,
                        typeElementId: value ? value.typeElementId : null,
                        typeElementBindingId: null,
                    },
                    valueElementToApplyId: {
                        ...dataActionToEdit.dataAction.valueElementToApplyId,
                        id: value ? value.id : null,
                    },
                    valueElementToReferenceId: {
                        ...dataActionToEdit.dataAction.valueElementToReferenceId,
                        id: value ? value.id : null,
                    },
                };
                setDataActionToEdit({ ...dataActionToEdit, dataAction });
            } else {
                notifyError(translations.DATA_ACTION_value_with_no_connector_error);
            }
        }
    };

    const onUpdateDataActionName = (developerName: string) => {
        if (dataActionToEdit.dataAction) {
            setDataActionToEdit({
                ...dataActionToEdit,
                dataAction: { ...dataActionToEdit.dataAction, developerName },
            });
        }
    };

    const onUpdateDataActionOrder = (order: number) => {
        if (dataActionToEdit.dataAction) {
            setDataActionToEdit({
                ...dataActionToEdit,
                dataAction: { ...dataActionToEdit.dataAction, order },
            });
        }
    };

    const onUpdateDataActionDisabled = ({ isOn }: { isOn: boolean }) => {
        if (dataActionToEdit.dataAction) {
            setDataActionToEdit({
                ...dataActionToEdit,
                dataAction: { ...dataActionToEdit.dataAction, disabled: isOn },
            });
        }
    };

    const onUpdateDataActionSmartSave = ({ isOn }: { isOn: boolean }) => {
        if (dataActionToEdit.dataAction) {
            setDataActionToEdit({
                ...dataActionToEdit,
                dataAction: { ...dataActionToEdit.dataAction, isSmartSave: isOn },
            });
        }
    };

    const onUpdateDataActionBinding = (bindingId: string) => {
        if (dataActionToEdit.dataAction && dataActionToEdit.dataAction.objectDataRequest !== null) {
            const dataAction = {
                ...dataActionToEdit.dataAction,
                objectDataRequest: {
                    ...dataActionToEdit.dataAction.objectDataRequest,
                    typeElementBindingId: bindingId,
                },
            };
            setDataActionToEdit({ ...dataActionToEdit, dataAction });
        }
    };

    const onApplyDataAction = (index: number | null) => {
        if (mapElement.dataActions && dataActionToEdit.dataAction !== null) {
            const dataActionExists = mapElement.dataActions
                ? mapElement.dataActions.find((_, i) => i === index)
                : null;

            const dataActions = dataActionExists
                ? mapElement.dataActions.map((existingDataAction, i) =>
                      i === index && dataActionToEdit.dataAction !== null
                          ? dataActionToEdit.dataAction
                          : existingDataAction,
                  )
                : [...mapElement.dataActions, dataActionToEdit.dataAction];

            setMapElement({ ...mapElement, dataActions });
            onSwitchScreen(defaultScreen);
        }
    };

    const onEditDataActionFilter = () => {
        setFilterToEdit(dataActionToEdit?.dataAction?.objectDataRequest?.listFilter ?? null);
        onSwitchScreen(DATA_ACTION_SCREENS['filter'].key);
        onPushBreadCrumb(DATA_ACTION_SCREENS['filter'].key, DATA_ACTION_SCREENS['filter'].label);
    };

    const onSetFilterComparisonType = (comparisonType: string) => {
        if (filterToEdit) {
            setFilterToEdit({
                ...filterToEdit,
                comparisonType,
            });
        }
    };

    const onSetFilterOrderDirectionType = (orderByDirectionType: string) => {
        if (filterToEdit) {
            setFilterToEdit({
                ...filterToEdit,
                orderByDirectionType,
            });
        }
    };

    const onSetFilterRecordLimit = (limit: number) => {
        if (filterToEdit) {
            setFilterToEdit({
                ...filterToEdit,
                limit,
            });
        }
    };

    const onSetFilterOrderByTypePropertyId = (orderByTypeElementPropertyId: string) => {
        if (filterToEdit) {
            setFilterToEdit({
                ...filterToEdit,
                orderByTypeElementPropertyId,
            });
        }
    };

    const onSetFilterId = (value: ValueElementIdAPI | null) => {
        if (filterToEdit) {
            setFilterToEdit({
                ...filterToEdit,
                filterId: {
                    command: null,
                    id: value ? value.id : null,
                    typeElementPropertyId: value ? value.typeElementPropertyId : null,
                },
            });
        }
    };

    const onSetFilterType = (filterType: string) => {
        let updatedFilter = null;
        if (filterType === FILTER_TYPES.filterById.value) {
            updatedFilter = {
                ...filterToEdit,
                filterId: {
                    id: null,
                    typeElementPropertyId: null,
                    command: null,
                    relativeUnit: null,
                },
                where: null,
                comparisonType: null,
                orderByDirectionType: null,
                orderByTypeElementPropertyId: null,
            };
        }

        if (filterType === FILTER_TYPES.filterByWhereClause.value) {
            updatedFilter = {
                ...filterToEdit,
                where: [],
                filterId: null,
            };
        }

        setFilterToEdit(updatedFilter);
    };

    const onApplyDataActionFilter = () => {
        if (dataActionToEdit?.dataAction?.objectDataRequest) {
            setDataActionToEdit({
                ...dataActionToEdit,
                dataAction: {
                    ...dataActionToEdit.dataAction,
                    objectDataRequest: {
                        ...dataActionToEdit.dataAction.objectDataRequest,
                        listFilter: filterToEdit,
                    },
                },
            });
            onSwitchScreen(DATA_ACTION_SCREENS['detail'].key);
        }
    };

    const onCreateDataActionFilterCriteria = () => {
        if (filterToEdit?.where) {
            const totalCriterias = filterToEdit.where.length;
            setCriteriaToEdit({
                criteria: { ...initialCriteria },
                index: totalCriterias + 1,
            });
            onSwitchScreen(DATA_ACTION_SCREENS['criteria'].key);
            onPushBreadCrumb(
                DATA_ACTION_SCREENS['criteria'].key,
                DATA_ACTION_SCREENS['criteria'].label,
            );
        }
    };

    const onEditDataActionFilterCriteria = (criteria: WhereStatement, index: number) => {
        setCriteriaToEdit({ criteria, index });
        onSwitchScreen(DATA_ACTION_SCREENS['criteria'].key);
        onPushBreadCrumb(
            DATA_ACTION_SCREENS['criteria'].key,
            DATA_ACTION_SCREENS['criteria'].label,
        );
    };

    const onDeleteDataActionFilterCriteria = (index: number) => {
        if (filterToEdit?.where) {
            setFilterToEdit({
                ...filterToEdit,
                where: filterToEdit.where.filter((_, i) => i !== index),
            });
        }
    };

    const onSetCriteriaTypePropertyId = (
        columnTypeElementPropertyId: string,
        columnTypeElementPropertyDeveloperName: string,
    ) => {
        if (criteriaToEdit.criteria) {
            setCriteriaToEdit({
                ...criteriaToEdit,
                criteria: {
                    ...criteriaToEdit.criteria,
                    columnTypeElementPropertyId,
                    columnTypeElementPropertyDeveloperName,
                },
            });
        }
    };

    const onSetCriteriaType = (criteriaType: CriteriaType, criteriaTypeFriendly: string) => {
        if (criteriaToEdit.criteria) {
            setCriteriaToEdit({
                ...criteriaToEdit,
                criteria: { ...criteriaToEdit.criteria, criteriaType, criteriaTypeFriendly },
            });
        }
    };

    const onSetCriteriaValueElementToReference = (value: ValueElementIdReferenceAPI | null) => {
        if (!value) {
            return;
        }

        const generateValueDisplayFormat = () => {
            const { developerName, typeElementPropertyDeveloperName } = value;
            let valueElementToReferenceDeveloperName = `[${developerName}`;
            if (typeElementPropertyDeveloperName) {
                valueElementToReferenceDeveloperName += ` / ${value.typeElementPropertyDeveloperName}`;
            }
            valueElementToReferenceDeveloperName += ']';
            return valueElementToReferenceDeveloperName;
        };

        if (criteriaToEdit.criteria) {
            setCriteriaToEdit({
                ...criteriaToEdit,
                criteria: {
                    ...criteriaToEdit.criteria,
                    valueElementToReferenceId: {
                        command: null,
                        id: value ? value.id : null,
                        typeElementPropertyId: value ? value.typeElementPropertyId : null,
                    },
                    valueElementToReferenceDeveloperName: value
                        ? generateValueDisplayFormat()
                        : null,
                },
            });
        }
    };

    const onApplyCriteria = (index: number) => {
        if (filterToEdit?.where && criteriaToEdit.criteria) {
            const criteriaExists = filterToEdit.where
                ? filterToEdit.where.find((_, i) => i === index)
                : null;

            const criterias = criteriaExists
                ? filterToEdit.where.map((existingCriteria, i) =>
                      i === index && criteriaToEdit.criteria
                          ? criteriaToEdit.criteria
                          : existingCriteria,
                  )
                : [...(filterToEdit.where || []), criteriaToEdit.criteria];

            setFilterToEdit({
                ...filterToEdit,
                where: criterias,
            });

            onSwitchScreen(DATA_ACTION_SCREENS['filter'].key);
        }
    };

    const onReturnToDataActionScreen = () => {
        onSetActiveBreadCrumb(DATA_ACTION_SCREENS['detail'].key);
    };

    const onReturnToDataActionFilterScreen = () => {
        onSetActiveBreadCrumb(DATA_ACTION_SCREENS['filter'].key);
    };

    const onReturnToDefaultScreen = () => {
        onSetActiveBreadCrumb(defaultScreen);
    };

    const contextValue = {
        dataActionToEdit,
        onCreateDataAction,
        onEditDataAction,
        onDeleteDataAction,
        onSetDataActionLoadValue,
        onUpdateDataActionName,
        onUpdateDataActionBinding,
        onApplyDataAction,
        onCreateDataActionFilterCriteria,
        onEditDataActionFilterCriteria,
        onDeleteDataActionFilterCriteria,
        onReturnToDataActionScreen,
        onReturnToDataActionFilterScreen,
        onReturnToDefaultScreen,
        onSetFilterComparisonType,
        onSetFilterOrderDirectionType,
        onSetFilterOrderByTypePropertyId,
        onSetFilterType,
        onApplyCriteria,
        onSetCriteriaTypePropertyId,
        onSetCriteriaType,
        onSetCriteriaValueElementToReference,
        criteriaToEdit,
        onSetFilterId,
        onEditDataActionFilter,
        filterToEdit,
        onApplyDataActionFilter,
        onUpdateDataActionOrder,
        onUpdateDataActionDisabled,
        onUpdateDataActionSmartSave,
        onSetFilterRecordLimit,
    };

    return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

const useDataAction = () => {
    const context = useContext(Context);
    if (context === undefined) {
        throw new Error('useDataAction must be used within a DataActionProvider');
    }
    return context;
};

export { DataActionProvider, useDataAction };
