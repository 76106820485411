import { useEffect, useState } from 'react';
import { getValueList } from '../../../sources/value';
import Table, { type TableColumnList } from '../../generic/Table';
import translations from '../../../translations';
import type {
    ContentType,
    Input,
    Output,
    ValueElementIdAPI,
    ValueElementIdReferenceAPI,
} from '../../../types';
import ValueSelectorModal from './ValueSelectorModal';
import { ExLoader } from '@boomi/exosphere';

interface Item {
    developerName: string;
    contentType: string;
    typeElementId: string | null;
    order: number;
    valueElementToReferenceId?: ValueElementIdAPI | null;
    valueElementToApplyId?: ValueElementIdAPI | null;
}

interface Props {
    items: Input[] | Output[];
    selectValue: (value: ValueElementIdAPI | null, item: Input | Output) => void;
    getStartingValueName?: (item: Item) => string;
    includeSystemValues?: boolean | undefined;
    modalContainer: HTMLElement | null;
}

const isInput = (item: Item): item is Input => item.valueElementToReferenceId !== undefined;

const ValueSelectorTable = ({
    items,
    selectValue,
    getStartingValueName,
    includeSystemValues,
    modalContainer,
}: Props) => {
    const [valueReferences, setValueReferences] = useState<ValueElementIdReferenceAPI[]>([]);
    const [loading, setLoading] = useState(true);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const load = async () => {
            if (!Array.isArray(items) || items.length === 0) {
                setLoading(false);
                return;
            }

            try {
                setValueReferences(
                    await getValueList({
                        id: items
                            .map((item) => {
                                if (isInput(item)) {
                                    return item.valueElementToReferenceId?.id;
                                }
                                return item.valueElementToApplyId?.id;
                            })
                            .filter((item) => !!item) as string[],
                    }),
                );
            } finally {
                setLoading(false);
            }
        };

        load();
    }, []);

    const columns: TableColumnList<Input | Output> = [
        {
            renderHeader: () => translations.COMMON_TABLE_name,
            renderCell: ({ item }) => item.developerName,
            size: '25%',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_value,
            renderCell: ({ item }) => {
                const onChange = (value: ValueElementIdAPI | null) => {
                    selectValue(value, item);
                };

                const value =
                    valueReferences.find(
                        (valueReference) =>
                            ((item as Input).valueElementToReferenceId?.id === valueReference.id &&
                                (item as Input).valueElementToReferenceId?.typeElementPropertyId ===
                                    valueReference.typeElementPropertyId) ||
                            ((item as Output).valueElementToApplyId?.id === valueReference.id &&
                                (item as Output).valueElementToApplyId?.typeElementPropertyId ===
                                    valueReference.typeElementPropertyId),
                    ) ||
                    (item as Input).valueElementToReferenceId ||
                    (item as Output).valueElementToApplyId ||
                    null;

                return (
                    <ValueSelectorModal
                        includeSystemValues={
                            includeSystemValues !== undefined ? includeSystemValues : true
                        }
                        value={value}
                        contentType={item.contentType as ContentType}
                        typeElementId={item.typeElementId}
                        startingValueName={getStartingValueName?.(item)}
                        onChange={onChange}
                        container={modalContainer}
                        key={item.developerName}
                    />
                );
            },
        },
    ];

    return loading ? (
        <ExLoader />
    ) : (
        <Table items={items ?? []} columns={columns} testId="values-table" />
    );
};

export default ValueSelectorTable;
