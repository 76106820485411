import screens from '../common/screens';
import DecisionRouter from './DecisionRouter';

import {
    MapElementProvider,
    type MapElementConfigProps,
} from '../contextProviders/MapElementProvider';
import MapElementModal from '../../../graph/MapElementModal';
import { MAP_ELEMENT_TYPES } from '../../../../constants';

const Decision = (props: MapElementConfigProps) => {
    return (
        <MapElementProvider
            {...props}
            defaultScreen={screens.decision}
            elementType={MAP_ELEMENT_TYPES.decision}
            isLoading={false}
        >
            <MapElementModal>
                <DecisionRouter />
            </MapElementModal>
        </MapElementProvider>
    );
};

export default Decision;
