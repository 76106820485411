import IdentityProvidersList from './IdentityProvidersList';
import IdentityProviderDetail from './IdentityProviderDetail';
import type { IdentityProviderAPI } from '../../../types';
import { useState } from 'react';
import { connect, type ConnectedProps } from 'react-redux';
import { addNotification } from '../../../../js/actions/reduxActions/notification';

type Props = ConnectedProps<typeof connector>;

const connector = connect(null, { addNotification });

const IdentityProviders = ({ addNotification }: Props) => {
    const [itemToEdit, setItemToEdit] = useState<IdentityProviderAPI | null>(null);

    return (
        <div className="page-wrapper">
            {itemToEdit === null ? (
                <IdentityProvidersList
                    addNotification={addNotification}
                    setItemToEdit={setItemToEdit}
                />
            ) : (
                <IdentityProviderDetail
                    itemToEdit={itemToEdit}
                    returnToList={() => setItemToEdit(null)}
                />
            )}
        </div>
    );
};

export default connector(IdentityProviders);
