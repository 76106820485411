import { type ChangeEvent, type ReactNode, useEffect, useState } from 'react';
import { MAP_ELEMENT_TYPES, COLLABORATION_ITEM_TYPES } from '../../../../constants';
import translations from '../../../../translations';
import { isNullOrWhitespace } from '../../../../utils/guard';
import { guid } from '../../../../utils/guid';
import FormGroup from '../../../generic/FormGroup';
import { getElementStyles } from '../../../../../js/components/graph/elements/elementStyles';
import { useCollaboration } from '../../../../collaboration/CollaborationProvider';
import ModalBody from '../../../generic/modal/ModalBody';
import ModalFooter from '../../../generic/modal/ModalFooter';
import ConfigModal from '../../../graph/ConfigModal';
import useGroup from './useGroup';
import Footer from '../common/Footer';
import type { NotifyError } from '../../../../types';
import type { GroupElementAPI, GroupElementRequestAPI } from '../../../../sources/graph';

type SwimlaneStyle = {
    groupElement: {
        width: number;
        height: number;
    };
    outBorder: unknown;
    border: unknown;
    header: unknown;
    fill: unknown;
    content: unknown;
    iconRow: unknown;
    icon: ReactNode;
};

type Props = {
    groupElementId: string | null;
    id: string | null;
    x: number;
    y: number;
    flowId: string;
    dismissMapElementConfig: () => void;
    refreshFlow: () => void;
    container: HTMLElement;
    notifyError: NotifyError;
    currentUserId: string | undefined;
    focusAndSelectElement: () => void;
};

const GroupConfiguration = ({
    groupElementId: parentGroupElementId,
    id = null,
    flowId,
    x,
    y,
    container,
    notifyError,
    dismissMapElementConfig,
    currentUserId,
    refreshFlow,
    focusAndSelectElement,
}: Props) => {
    const [groupElementId, setGroupElementId] = useState(id);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const { itemOpened, itemClosed, itemChanged, getItem } = useCollaboration();

    const retrieveItem = getItem as (id: string) => GroupElementRequestAPI;

    const currentGroupElement = retrieveItem(groupElementId ?? '');
    const isFormValid = isNullOrWhitespace(currentGroupElement?.developerName) === false;

    const { getGroupElement, saveGroupElement } = useGroup({
        flowId,
        notifyError,
        setIsLoading,
        refreshFlow,
        focusAndSelectElement,
    });

    const setGroupElement = (newGroupElement: GroupElementAPI) => {
        if (groupElementId) {
            itemChanged(
                groupElementId,
                COLLABORATION_ITEM_TYPES.groupElement,
                newGroupElement,
                !!id,
            );
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const load = async () => {
            setIsLoading(true);

            let groupElement = null;

            try {
                if (id) {
                    groupElement = await getGroupElement(id);
                } else {
                    groupElement = {
                        elementType: MAP_ELEMENT_TYPES.group,
                        x,
                        y,
                        width: (getElementStyles(MAP_ELEMENT_TYPES.group) as SwimlaneStyle)
                            .groupElement.width,
                        height: (getElementStyles(MAP_ELEMENT_TYPES.group) as SwimlaneStyle)
                            .groupElement.height,
                        developerName: '',
                        id: guid(),
                        groupElementId: parentGroupElementId ?? null,
                    };
                }

                if (groupElement) {
                    setGroupElementId(groupElement.id);
                    itemOpened(
                        groupElement.id,
                        flowId,
                        COLLABORATION_ITEM_TYPES.groupElement,
                        groupElement,
                        !!id,
                    );
                }
            } catch (error) {
                notifyError(error);
            } finally {
                setIsLoading(false);
            }
        };
        load();
    }, []);

    const onDeveloperNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (groupElementId) {
            setGroupElement({
                ...retrieveItem(groupElementId),
                developerName: e.currentTarget.value,
            });
        }
    };

    const onDeveloperSummaryChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        if (groupElementId) {
            setGroupElement({
                ...retrieveItem(groupElementId),
                developerSummary: e.currentTarget.value,
            });
        }
    };

    const onSave = async () => {
        setHasSubmitted(true);

        if (isFormValid) {
            setIsLoading(true);

            // mapElement.id will be empty if we are creating a new map element
            // The API requires us to generate an id when saving a new map element
            if (isNullOrWhitespace(currentGroupElement.id)) {
                currentGroupElement.id = guid();
            }

            await saveGroupElement(currentGroupElement);

            await onClose();
            setIsLoading(false);
        }
    };

    const onClose = () => {
        dismissMapElementConfig();
        refreshFlow();
        itemClosed(id, COLLABORATION_ITEM_TYPES.groupElement, !!id);
    };

    const renderBody = () => (
        <>
            <FormGroup
                isValid={isFormValid}
                showValidation={hasSubmitted}
                validationMessage="Please enter a name."
                label="Name"
                htmlFor="group-name"
                isRequired
            >
                <input
                    id="group-name"
                    autoFocus
                    value={currentGroupElement.developerName ?? ''}
                    onChange={onDeveloperNameChange}
                    maxLength={255}
                    required
                    className="form-control form-control-width"
                    type="text"
                />
            </FormGroup>
            <FormGroup
                key="group-comments"
                label={translations.GROUP_comment_label}
                htmlFor="group-comments"
            >
                <textarea
                    id="group-comments"
                    className="form-control form-textarea"
                    value={currentGroupElement.developerSummary ?? ''}
                    onChange={onDeveloperSummaryChange}
                />
            </FormGroup>
        </>
    );

    const renderFooter = () => {
        return <Footer save={onSave} cancel={onClose} />;
    };

    return isLoading ? null : (
        <ConfigModal
            id={currentGroupElement?.id}
            title={
                isNullOrWhitespace(currentGroupElement?.developerName)
                    ? `New ${MAP_ELEMENT_TYPES.group}`
                    : currentGroupElement.developerName
            }
            elementType={MAP_ELEMENT_TYPES.group}
            container={container}
            currentUserId={currentUserId}
            onHide={onClose}
        >
            <ModalBody>{renderBody()}</ModalBody>
            <ModalFooter>{renderFooter()}</ModalFooter>
        </ConfigModal>
    );
};

export default GroupConfiguration;
