import GenericModal from '../../../../generic/modal/GenericModal';
import ButtonDefault from '../../../../buttons/ButtonDefault';
import ButtonPrimary from '../../../../buttons/ButtonPrimary';
import { useRef, useState } from 'react';
import { PAGE_ELEMENT_TYPES } from '../../../constants';
import type {
    column,
    ValueElementIdReferenceAPI,
    ComponentRegistry,
    ComposerElement,
    TypeElementResponseAPI,
} from '../../../../../types';
import { stringReplace } from '../../../../../utils/string';
import Select from 'react-select';
import { getValidProperties, invalidContentTypesString } from './constants';
import Loader from '../../../../loader/Loader';
import translations from '../../../../../translations';
import FormGroup from '../../../../generic/FormGroup';
import Tiles from '../../preview/tiles/Tiles';
import { Plus, Trash } from '@phosphor-icons/react';
import Table from '../../../../generic/Table';
import { AlertBannerType, ExAlertBanner } from '@boomi/exosphere';
import { usePageEditor } from '../../PageEditorProvider';
import { useComposer } from '../composer/ComposerProvider';
import { getValueName } from '../../../../values/selector/value-selector-utils';
import { getSharedStyles } from '../../../../../utils/select';

interface SelectOption {
    label: string | null;
    value: string | null;
}

const ComponentSuggestTiles = ({
    selectedComponent,
    goToOptionsScreen,
    type,
    mainContainer,
    order,
}: {
    selectedComponent: ComponentRegistry;
    goToOptionsScreen: () => void;
    type: TypeElementResponseAPI | null;
    mainContainer: string;
    order: number;
}) => {
    const ref = useRef<HTMLDivElement>(null);

    const { state, container, setStopComponentSuggest } = usePageEditor();
    const { onPageElementDrop } = useComposer();
    const componentSuggestValue = state.componentSuggestValue as ValueElementIdReferenceAPI;

    const validProperties = getValidProperties(type);
    const wereAnyColumnsObjectList = (type?.properties?.length as number) > validProperties.length;

    const [selectedHeaderProperty, setSelectedHeaderProperty] = useState<SelectOption | null>({
        value: validProperties?.[0]?.id ?? '',
        label: validProperties?.[0]?.developerName ?? '',
    });
    const [selectedBodyProperty, setSelectedBodyProperty] = useState<SelectOption | null>({
        value: validProperties?.[0]?.id ?? '',
        label: validProperties?.[0]?.developerName ?? '',
    });
    const [selectedFooterProperties, setSelectedFooterProperties] = useState<column[]>([]);
    const setFooterPropertyColumn = (newColumn: SelectOption | null, columnIndex: number) => {
        if (newColumn === null) {
            removeFooterProperty(columnIndex);
            return;
        }
        const existingProperties = [...selectedFooterProperties];
        const updatedProperty = existingProperties[columnIndex];
        if (updatedProperty) {
            // If the label was the same as the column
            if (updatedProperty.label === updatedProperty.typeElementPropertyDeveloperName) {
                // Rename it to the new column
                updatedProperty.label = newColumn.label;
            }
            updatedProperty.typeElementPropertyDeveloperName = newColumn.label;
            updatedProperty.typeElementPropertyId = newColumn.value;
            setSelectedFooterProperties(existingProperties);
        }
    };
    const setFooterPropertyLabel = (newLabel: string, columnIndex: number) => {
        const existingProperties = [...selectedFooterProperties];
        const updatedProperty = existingProperties[columnIndex];
        if (updatedProperty) {
            updatedProperty.label = newLabel;
            setSelectedFooterProperties(existingProperties);
        }
    };
    const removeFooterProperty = (columnIndex: number) => {
        setSelectedFooterProperties(
            selectedFooterProperties.filter((_, index) => {
                if (index === columnIndex) {
                    return false;
                }
                return true;
            }),
        );
    };

    const calculateCombinedTilesColumns = () => {
        const columns = [
            {
                isDisplayValue: true,
                order: 0,
                typeElementPropertyDeveloperName: selectedHeaderProperty?.label,
                typeElementPropertyId: selectedHeaderProperty?.value,
            },
        ] as column[];

        if (selectedBodyProperty) {
            columns.push({
                isDisplayValue: true,
                order: 1,
                typeElementPropertyDeveloperName: selectedBodyProperty?.label,
                typeElementPropertyId: selectedBodyProperty?.value,
                boundTypeElementPropertyId: null,
                isBound: false,
                isEditable: false,
                componentType: null,
                typeElementPropertyToDisplayId: null,
                isPinned: false,
            });
        }
        if (selectedFooterProperties) {
            columns.push(
                ...selectedFooterProperties.map(
                    (
                        {
                            isDisplayValue,
                            label,
                            typeElementPropertyDeveloperName,
                            typeElementPropertyId,
                        },
                        index,
                    ) => ({
                        label,
                        order: index + 2,
                        isDisplayValue,
                        typeElementPropertyId,
                        typeElementPropertyDeveloperName,
                        boundTypeElementPropertyId: null,
                        isBound: false,
                        isEditable: false,
                        componentType: null,
                        typeElementPropertyToDisplayId: null,
                        isPinned: false,
                    }),
                ),
            );
        }
        return columns;
    };

    const createTilesComponent = () => {
        if (!(selectedHeaderProperty && selectedBodyProperty)) {
            return;
        }

        let partialElement = {} as Partial<ComposerElement>;

        partialElement = {
            developerName: `"${getValueName(componentSuggestValue)}"`,
            valueElementDataBindingReferenceId: {
                id: componentSuggestValue.id,
                typeElementPropertyId: componentSuggestValue.typeElementPropertyId as string,
                command: null,
                relativeUnit: null,
            },
            columns: calculateCombinedTilesColumns(),
        };

        onPageElementDrop({
            id: null,
            targetId: mainContainer,
            order,
            pageElementType: PAGE_ELEMENT_TYPES['component'],
            componentType: selectedComponent.type.toLowerCase(),
            select: true,
            partialElement,
        });
        setStopComponentSuggest();
    };

    return (
        <GenericModal
            container={container}
            ref={ref}
            show
            className="config-modal component-suggestion-modal"
            onHide={setStopComponentSuggest}
            title={translations.COMPONENT_SUGGEST_multiple_columns_view_title}
            animation={false}
            renderBody={() => {
                if (type === null) {
                    return <Loader />;
                }

                if (validProperties.length === 0) {
                    return (
                        <>
                            {stringReplace(
                                translations.COMPONENT_SUGGEST_list_has_no_valid_properties as string,
                                invalidContentTypesString,
                            )}
                        </>
                    );
                }

                return (
                    <div className="suggest-tiles-preview">
                        <div className="left-section">
                            {wereAnyColumnsObjectList && (
                                <ExAlertBanner
                                    className="margin-bottom"
                                    type={AlertBannerType.INFO}
                                    open
                                >
                                    {invalidContentTypesString} properties have been removed
                                </ExAlertBanner>
                            )}
                            <FormGroup label="Heading column" htmlFor="heading-column" isRequired>
                                <Select
                                    styles={getSharedStyles<SelectOption>()}
                                    inputId="heading-column"
                                    options={validProperties.map(({ id, developerName }) => ({
                                        label: developerName,
                                        value: id,
                                    }))}
                                    onChange={(option: SelectOption | null) =>
                                        setSelectedHeaderProperty(option)
                                    }
                                    placeholder="Select a property to render"
                                    noOptionsMessage={() => 'No results found'}
                                    required={true}
                                    value={selectedHeaderProperty}
                                    menuPosition="fixed"
                                    menuPortalTarget={ref?.current}
                                />
                            </FormGroup>
                            <FormGroup label="Body column" htmlFor="body-column" isRequired>
                                <Select
                                    styles={getSharedStyles<SelectOption>()}
                                    inputId="body-column"
                                    options={validProperties.map(({ id, developerName }) => ({
                                        label: developerName,
                                        value: id,
                                    }))}
                                    onChange={(option: SelectOption | null) =>
                                        setSelectedBodyProperty(option)
                                    }
                                    placeholder="Select a property to render"
                                    noOptionsMessage={() => 'No results found'}
                                    required={true}
                                    value={selectedBodyProperty}
                                    menuPosition="fixed"
                                    menuPortalTarget={ref?.current}
                                />
                            </FormGroup>
                            <div className="flex flex-child-right">
                                <ButtonPrimary
                                    title="Add footer column"
                                    onClick={() =>
                                        setSelectedFooterProperties([
                                            ...selectedFooterProperties,
                                            {
                                                isDisplayValue: true,
                                                order: selectedFooterProperties.length + 2,
                                                label: validProperties?.[0]?.developerName,
                                                typeElementPropertyDeveloperName:
                                                    validProperties?.[0]?.developerName,
                                                typeElementPropertyId: validProperties?.[0]?.id,
                                                boundTypeElementPropertyId: null,
                                                isBound: false,
                                                isEditable: false,
                                                componentType: null,
                                                typeElementPropertyToDisplayId: null,
                                                isPinned: false,
                                            },
                                        ])
                                    }
                                >
                                    <Plus />
                                    Add Footer Column
                                </ButtonPrimary>
                            </div>
                            <Table
                                columns={[
                                    {
                                        renderHeader: () => 'Column',
                                        renderCell: ({
                                            item,
                                            rowIndex,
                                        }: {
                                            item: column;
                                            rowIndex: number;
                                        }) => (
                                            <div
                                                title={
                                                    item.typeElementPropertyDeveloperName ||
                                                    undefined
                                                }
                                            >
                                                <label
                                                    className="hidden"
                                                    htmlFor={`footer-column-select-${rowIndex}`}
                                                >{`footer-column-select-${rowIndex}`}</label>
                                                <Select
                                                    styles={getSharedStyles<SelectOption>()}
                                                    inputId={`footer-column-select-${rowIndex}`}
                                                    value={{
                                                        label: item.typeElementPropertyDeveloperName,
                                                        value: item.typeElementPropertyId,
                                                    }}
                                                    onChange={(selection: SelectOption | null) =>
                                                        setFooterPropertyColumn(selection, rowIndex)
                                                    }
                                                    options={validProperties.map(
                                                        ({ id, developerName }) => ({
                                                            label: developerName,
                                                            value: id,
                                                        }),
                                                    )}
                                                    menuPosition="fixed"
                                                    menuPortalTarget={ref?.current}
                                                />
                                            </div>
                                        ),
                                        cellClassName: 'generic-cell-overflow',
                                        size: '50%',
                                    },
                                    {
                                        renderHeader: () => 'Label',
                                        renderCell: ({ item, rowIndex }) => (
                                            <input
                                                data-testid={`footer-column-input-${rowIndex}`}
                                                value={item.label ?? ''}
                                                onChange={({ target: { value } }) =>
                                                    setFooterPropertyLabel(value, rowIndex)
                                                }
                                                className="form-control"
                                                type="text"
                                            />
                                        ),
                                    },
                                    {
                                        renderHeader: () => translations.COMMON_TABLE_actions,
                                        renderCell: ({ rowIndex }) => (
                                            <div className="action-btn-wrapper">
                                                <button
                                                    data-testid={`footer-column-delete-${rowIndex}`}
                                                    title="Delete"
                                                    className="table-icon table-icon-delete"
                                                    aria-label="Delete"
                                                    onClick={() => removeFooterProperty(rowIndex)}
                                                    type="button"
                                                >
                                                    <Trash />
                                                </button>
                                            </div>
                                        ),
                                        size: '5rem',
                                    },
                                ]}
                                items={selectedFooterProperties}
                            />
                        </div>
                        <div className="right-section">
                            <Tiles
                                label="Preview Tiles"
                                columns={calculateCombinedTilesColumns()}
                                isEditable={false}
                                isRequired={false}
                                isSearchable={false}
                            />
                        </div>
                    </div>
                );
            }}
            renderFooter={() => (
                <>
                    <ButtonDefault title="Back" onClick={goToOptionsScreen}>
                        Back
                    </ButtonDefault>
                    <ButtonPrimary
                        onClick={createTilesComponent}
                        title={`Create ${selectedComponent.ui.caption} component`}
                        disabled={validProperties.length === 0}
                    >
                        Create {selectedComponent.ui.caption} component
                    </ButtonPrimary>
                </>
            )}
        />
    );
};

export default ComponentSuggestTiles;
