export default {
    NOTIFICATION_ADD: 'NOTIFICATION_ADD',
    NOTIFICATION_REMOVE: 'NOTIFICATION_REMOVE',
    REMOVE_ALL_NOTIFICATIONS: 'REMOVE_ALL_NOTIFICATIONS',

    CURRENT_TENANT_SET: 'CURRENT_TENANT_SET',
    CURRENT_TENANT_SECURITY_SETTING_UPDATE: 'CURRENT_TENANT_SECURITY_SETTING_UPDATE',
    CURRENT_TENANT_SAML_SETTING_UPDATE: 'CURRENT_TENANT_SAML_SETTING_UPDATE',
    CURRENT_TENANT_OTLP_SETTING_UPDATE: 'CURRENT_TENANT_OTLP_SETTING_UPDATE',
    CURRENT_TENANT_DEVELOPER_NAME_UPDATE: 'CURRENT_TENANT_DEVELOPER_NAME_UPDATE',
    CURRENT_TENANT_SUMMARY_UPDATE: 'CURRENT_TENANT_SUMMARY_UPDATE',
    CURRENT_TENANT_IP_ENTRY_CONFIRM: 'CURRENT_TENANT_IP_ENTRY_CONFIRM',
    CURRENT_TENANT_IP_RANGE_ADD: 'CURRENT_TENANT_IP_RANGE_ADD',
    CURRENT_TENANT_IP_RANGE_REMOVE: 'CURRENT_TENANT_IP_RANGE_REMOVE',
    CURRENT_TENANT_SERVICE_INVOKER_LOGGING_UPDATE: 'CURRENT_TENANT_SERVICE_INVOKER_LOGGING_UPDATE',
    CURRENT_TENANT_REPORTING_ENDPOINT_UPDATE: 'CURRENT_TENANT_REPORTING_ENDPOINT_UPDATE',
    CURRENT_TENANT_ENVIRONMENT_SETTING_UPDATE: 'CURRENT_TENANT_ENVIRONMENT_SETTING_UPDATE',
    CURRENT_TENANT_INTEGRATION_ACCOUNT_ADD: 'CURRENT_TENANT_INTEGRATION_ACCOUNT_ADD',
    CURRENT_TENANT_INTEGRATION_ACCOUNT_REMOVE: 'CURRENT_TENANT_INTEGRATION_ACCOUNT_REMOVE',
    CURRENT_TENANT_THEME_SETTING_UPDATE: 'CURRENT_TENANT_THEME_SETTING_UPDATE',

    FLOW_CONFIGURATIONS_REGISTER: 'FLOW_CONFIGURATIONS_REGISTER',
    FLOW_CONFIGURATIONS_UNREGISTER: 'FLOW_CONFIGURATIONS_UNREGISTER',
    FLOW_CONFIGURATIONS_CHANGE_FLOW_NAME: 'FLOW_CONFIGURATIONS_CHANGE_FLOW_NAME',
    FLOW_CONFIGURATIONS_CHANGE_FLOW_ACCESS: 'FLOW_CONFIGURATIONS_CHANGE_FLOW_ACCESS',
    FLOW_CONFIGURATIONS_CHANGE_SOCIAL_FEED: 'FLOW_CONFIGURATIONS_CHANGE_SOCIAL_FEED',
    FLOW_CONFIGURATIONS_CHANGE_FLOW_JUMPING: 'FLOW_CONFIGURATIONS_CHANGE_FLOW_JUMPING',
    FLOW_CONFIGURATIONS_CHANGE_FLOW_STATE_LIFETIME:
        'FLOW_CONFIGURATIONS_CHANGE_FLOW_STATE_LIFETIME',
    FLOW_CONFIGURATIONS_CHANGE_FLOW_IDLE_STATE_LIFETIME:
        'FLOW_CONFIGURATIONS_CHANGE_FLOW_IDLE_STATE_LIFETIME',
    FLOW_CONFIGURATIONS_CHANGE_FLOW_COMMENT: 'FLOW_CONFIGURATIONS_CHANGE_FLOW_COMMENT',
    FLOW_CONFIGURATIONS_CHANGE_FLOW_SERVICE: 'FLOW_CONFIGURATIONS_CHANGE_FLOW_SERVICE',
    FLOW_CONFIGURATIONS_CHANGE_FLOW_GROUP: 'FLOW_CONFIGURATIONS_CHANGE_FLOW_GROUP',
    FLOW_CONFIGURATIONS_CHANGE_FLOW_GROUP_ATTRIBUTE:
        'FLOW_CONFIGURATIONS_CHANGE_FLOW_GROUP_ATTRIBUTE',
    FLOW_CONFIGURATIONS_DELETE_FLOW_GROUP: 'FLOW_CONFIGURATIONS_DELETE_FLOW_GROUP',
    FLOW_CONFIGURATIONS_CHANGE_FLOW_USER: 'FLOW_CONFIGURATIONS_CHANGE_FLOW_USER',
    FLOW_CONFIGURATIONS_CHANGE_FLOW_USER_ATTRIBUTE:
        'FLOW_CONFIGURATIONS_CHANGE_FLOW_USER_ATTRIBUTE',
    FLOW_CONFIGURATIONS_DELETE_FLOW_USER: 'FLOW_CONFIGURATIONS_DELETE_FLOW_USER',
    FLOW_CONFIGURATIONS_RESTRICTION_ACTIVE_UPDATE: 'FLOW_CONFIGURATIONS_RESTRICTION_ACTIVE_UPDATE',
    FLOW_CONFIGURATIONS_RESTRICTION_ADD: 'FLOW_CONFIGURATIONS_RESTRICTION_ADD',
    FLOW_CONFIGURATIONS_RESTRICTION_REMOVE: 'FLOW_CONFIGURATIONS_RESTRICTION_REMOVE',
    FLOW_SET_FLOW_USERS_GROUPS_SELECTION: 'FLOW_SET_FLOW_USERS_GROUPS_SELECTION',
    FLOW_ADD_FLOW_USERS_GROUPS_SELECTION: 'FLOW_ADD_FLOW_USERS_GROUPS_SELECTION',
    FLOW_CONFIGURATIONS_CHANGE_FLOW_IDP: 'FLOW_CONFIGURATIONS_CHANGE_FLOW_IDP',
    FLOW_CONFIGURATIONS_CHANGE_IDP_GROUPS: 'FLOW_CONFIGURATIONS_CHANGE_IDP_GROUPS',
    FLOW_CONFIGURATIONS_CHANGE_IDP_USERS: 'FLOW_CONFIGURATIONS_CHANGE_IDP_USERS',

    SET_SELECTED_FLOW: 'SET_SELECTED_FLOW',

    GRAPH_SET_HOVER_MAP_ID: 'GRAPH_SET_HOVER_MAP_ID',
    GRAPH_SET_HOVER_GROUP_ID: 'GRAPH_SET_HOVER_GROUP_ID',
    GRAPH_SET_DRAG_OVER_GROUP_ID: 'GRAPH_SET_DRAG_OVER_GROUP_ID',
    GRAPH_SET_DRAGGING_DATA: 'GRAPH_SET_DRAGGING_DATA',
    GRAPH_SET_CONTEXT_MENU_DATA: 'GRAPH_SET_CONTEXT_MENU_DATA',
    GRAPH_SET_SEARCH: 'GRAPH_SET_SEARCH',
    GRAPH_SET_CLIPBOARD: 'GRAPH_SET_CLIPBOARD',

    FLOW_LIST_LOADING: 'FLOW_LIST_LOADING',
    FLOW_LIST_RESPONSE: 'FLOW_LIST_RESPONSE',
    FLOWS_ACTIVATED_RESPONSE: 'FLOWS_ACTIVATED_RESPONSE',
    FLOW_CLOSE: 'FLOW_CLOSE',
    FLOW_SELECT_EXPORT: 'FLOW_SELECT_EXPORT',
    FLOW_INCLUDE_PASSWORDS: 'FLOW_INCLUDE_PASSWORDS',
    FLOW_SET_EXPORTED_ESCAPED_JSON: 'FLOW_SET_EXPORTED_ESCAPED_JSON',
    FLOW_SET_GENERATED_SHARED_TOKEN: 'FLOW_SET_GENERATED_SHARED_TOKEN',
    FLOW_SET_IMPORT_TOKEN: 'FLOW_SET_IMPORT_TOKEN',
    FLOW_SET_IMPORTED_PACKAGE: 'FLOW_SET_IMPORTED_PACKAGE',
    FLOW_SHOW_IMPORT_CONFIRMATION: 'FLOW_SHOW_IMPORT_CONFIRMATION',
    FLOW_IMPORT_SUCCESS: 'FLOW_IMPORT_SUCCESS',
    FLOW_IMPORT_CONFLICT: 'FLOW_IMPORT_CONFLICT', // Import will be overwriting existing shared elements

    SIDEBAR_TOGGLE: 'SIDEBAR_TOGGLE',

    ORGANIZATION_SET_RECEIVED_TENANT_INVITATIONS: 'ORGANIZATION_SET_RECEIVED_TENANT_INVITATIONS',
    ORGANIZATION_SET_RECEIVED_USER_INVITATIONS: 'ORGANIZATION_SET_RECEIVED_USER_INVITATIONS',
    ORGANIZATION_SET_SENT_TENANT_INVITATIONS: 'ORGANIZATION_SET_SENT_TENANT_INVITATIONS',
    ORGANIZATION_SET_SENT_USER_INVITATIONS: 'ORGANIZATION_SET_SENT_USER_INVITATIONS',
    ORGANIZATION_TENANTS_SET_ALL: 'ORGANIZATION_TENANTS_SET_ALL',
    ORGANIZATION_USERS_SET_ALL: 'ORGANIZATION_USERS_SET_ALL',

    LOCAL_RUNTIME_SET: 'LOCAL_RUNTIME_SET',
    LOCAL_RUNTIME_CREATE: 'LOCAL_RUNTIME_CREATE',
    LOCAL_RUNTIME_SHOW_LOADER: 'LOCAL_RUNTIME_SHOW_LOADER',
    LOCAL_RUNTIME_UPDATE: 'LOCAL_RUNTIME_UPDATE',
    LOCAL_RUNTIME_DELETING: 'LOCAL_RUNTIME_DELETING',
    LOCAL_RUNTIME_DELETE: 'LOCAL_RUNTIME_DELETE',
    LOCAL_RUNTIME_FOR_TENANT_SET: 'LOCAL_RUNTIME_FOR_TENANT_SET',

    NOTE_ADD: 'NOTE_ADD',
    NOTE_SET_ALL: 'NOTE_SET_ALL',
    NOTE_UPDATE: 'NOTE_UPDATE',
    NOTE_EDIT: 'NOTE_EDIT',
    NOTE_TOGGLE_VISIBILITY: 'NOTE_TOGGLE_VISIBILITY',
    NOTE_DELETE: 'NOTE_DELETE',
    NOTE_DELETE_MULTIPLE: 'NOTE_DELETE_MULTIPLE',

    TAB_OPEN: 'TAB_OPEN',
    TAB_CLOSE: 'TAB_CLOSE',
    TAB_CLOSE_ALL: 'TAB_CLOSE_ALL',
    TAB_SET_TITLE: 'TAB_SET_TITLE',
    TAB_UPDATE: 'TAB_UPDATE',

    FEATURE_FLAGS_SET_ALL: 'FEATURE_FLAGS_SET_ALL',
    FEATURE_FLAGS_SET_LOADER: 'FEATURE_FLAGS_SET_LOADER',
};
