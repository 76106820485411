import { type ComponentPropsWithoutRef, useState } from 'react';
import { notifyError, notifySuccess } from '../../../js/actions/reduxActions/notification';
import { removeUser } from '../../sources/organization';
import translations from '../../translations';
import { resolveOrgUserRole } from '../../utils/organization';
import { stringReplace } from '../../utils/string';
import { useAuth } from '../AuthProvider';
import ConfirmModal from '../generic/modal/ConfirmModal';
import { useDispatch } from 'react-redux';

type LeaveOrgProps = {
    isLoading: boolean;
    setIsLoading: (isLoading: boolean) => void;
    fetchUsers: () => Promise<void>;
} & Pick<ComponentPropsWithoutRef<typeof ConfirmModal>, 'container'>;

const LeaveOrg = ({ fetchUsers, container }: LeaveOrgProps) => {
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { user, tenant, fetchUser } = useAuth();
    const dispatch = useDispatch();

    const handleShowModal = () => {
        setShowModal(true);
    };

    const handleHideModal = () => {
        setShowModal(false);
    };

    const handleLeaveConfirm = async () => {
        if (!user?.id || !tenant) {
            return;
        }

        try {
            setIsLoading(true);

            await removeUser(user.id);

            // Reload the current user in case they have been removed from the current organization
            await fetchUser();

            if (resolveOrgUserRole(user, tenant).isUserAdmin) {
                await fetchUsers();
            }

            dispatch(
                notifySuccess(
                    stringReplace(translations.FORG_left_confirmation, {
                        user: user.email,
                    }),
                ),
            );

            handleHideModal();
        } catch (error) {
            dispatch(notifyError(error));
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <button className="btn btn-danger" onClick={handleShowModal} type="button">
                {translations.FORG_leave_organization_button_label}
            </button>
            <ConfirmModal
                show={showModal}
                onCancel={handleHideModal}
                onConfirm={handleLeaveConfirm}
                title={translations.FORG_leave_confirmation_title}
                messages={[translations.FORG_leave_confirmation]}
                container={container}
                isInProgress={isLoading}
            />
        </>
    );
};

export default LeaveOrg;
