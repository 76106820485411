import {
    ButtonFlavor,
    ButtonSize,
    ButtonType,
    ExButton,
    ExEmptyState,
    ExIcon,
} from '@boomi/exosphere';

const EmptyBreakpointList = ({ addBreakpoint }: { addBreakpoint: () => void }) => {
    return (
        <div className="full-height flex align-center">
            <div className="center">
                <ExEmptyState
                    label="There are no assertions to display"
                    text="Try adding a new assertion"
                >
                    <ExIcon icon="Check list" slot="header" />
                </ExEmptyState>
                <div className="flex">
                    <ExButton
                        flavor={ButtonFlavor.BRANDED}
                        type={ButtonType.SECONDARY}
                        size={ButtonSize.DEFAULT}
                        className="center"
                        onClick={() => addBreakpoint()}
                    >
                        Add Assertion
                    </ExButton>
                </div>
            </div>
        </div>
    );
};

export default EmptyBreakpointList;
