import { add } from 'date-fns';

const isValidDate = (dateToCheck: Date) => dateToCheck.toString() !== 'Invalid Date';

const padStart = (value: { toString: () => string }) => value.toString().padStart(2, '0');

export const getDateString = (date: Date, isUTC: boolean) => {
    if (isValidDate(date)) {
        if (isUTC) {
            const dateStr = `${date.getUTCFullYear()}-${padStart(
                date.getUTCMonth() + 1,
            )}-${padStart(date.getUTCDate())}`;
            return dateStr;
        }
        const dateStr = `${date.getFullYear()}-${padStart(date.getMonth() + 1)}-${padStart(
            date.getDate(),
        )}`;
        return dateStr;
    }

    return '';
};

export const getTimeString = (date: Date, isUTC: boolean) => {
    if (isValidDate(date)) {
        const hours = isUTC ? date.getUTCHours() : date.getHours();
        const minutes = isUTC ? date.getUTCMinutes() : date.getMinutes();

        return `${padStart(hours)}:${padStart(minutes)}`;
    }

    return '';
};

export const getDateDisplay = (date: Date | string) => {
    return new Date(date).toLocaleString();
};

export const addHours = (date: Date, hours: number) => add(date, { hours });
export const addDays = (date: Date, days: number) => add(date, { days });
export const addMonths = (date: Date, months: number) => add(date, { months });
export const addYears = (date: Date, years: number) => add(date, { years });

export const getLocale = () => {
    const locale = (typeof navigator !== 'undefined' && navigator.language) || 'en-US';

    try {
        Intl.DateTimeFormat.supportedLocalesOf(locale);
        return locale;
    } catch (_error) {
        return 'en-US';
    }
};
