import { ArrowsClockwise, DownloadSimple } from '@phosphor-icons/react';
import { useState } from 'react';
import '../../../../css/audit-logs.less';
import ButtonDefault from '../buttons/ButtonDefault';
import translations from '../../translations';
import { AUDIT_TIME_PERIODS } from './constants';
import { exportBlobToCsv } from '../../utils/csv';
import Select from 'react-select';
import { getSharedStyles } from '../../utils/select';
import type { TimePeriodOption, TypeOption, UserAPI, UserOption } from '../../types';
import { getPeriodOptions, getTypeOptions, getUserOptions } from './utils';

type AuditLogFiltersProps = {
    tenantId: string;
    users: UserAPI[];
    filterDate: Date;
    filterPeriod: TimePeriodOption;
    filterTypes: readonly TypeOption[];
    filterUsers: readonly UserOption[];
    fetchLogs: () => Promise<void>;
    updateTimePeriodFilter: (period: TimePeriodOption) => Promise<void>;
    updateDateFilter: (date: Date) => Promise<void>;
    updateLogTypeFilter: (types: readonly TypeOption[]) => Promise<void>;
    updateUserFilter: (users: readonly UserOption[]) => Promise<void>;
    downloadCsv: () => Promise<Blob | undefined>;
};

const AuditLogsFilters = ({
    tenantId,
    users,
    filterDate,
    filterPeriod,
    filterTypes,
    filterUsers,
    fetchLogs,
    updateTimePeriodFilter,
    updateDateFilter,
    updateLogTypeFilter,
    updateUserFilter,
    downloadCsv,
}: AuditLogFiltersProps) => {
    const [downloadingCsv, setDownloadingCsv] = useState(false);
    const typeOptions = getTypeOptions();
    const userOptions = getUserOptions(users);
    const periods = getPeriodOptions();

    const dateString =
        typeof filterDate === 'string' ? filterDate : filterDate.toISOString().split('T')[0];

    const isDate = (date: number | string | Date): date is Date => {
        return new Date(date).toString() !== 'Invalid Date' && !Number.isNaN(new Date(date));
    };

    const generateCsv = async () => {
        setDownloadingCsv(true);
        const results = await downloadCsv();
        if (results) {
            exportBlobToCsv(`AuditLogs_${tenantId}.csv`, results);
        }
        setDownloadingCsv(false);
    };

    return (
        <div className="audit-filters">
            <div className="type-filter filter-column">
                <Select
                    aria-label={translations.AUDIT_time_period_filter_select_label}
                    styles={getSharedStyles<(typeof periods)[number]>()}
                    options={periods}
                    id="time-period-filter"
                    onChange={(period: TimePeriodOption | null) => {
                        if (!period) {
                            return;
                        }
                        updateTimePeriodFilter(period);
                    }}
                    value={filterPeriod}
                />
            </div>
            <div
                className="date-filter filter-column"
                hidden={filterPeriod.value !== AUDIT_TIME_PERIODS.day.value}
            >
                <input
                    aria-label={translations.AUDIT_date_range_input_label}
                    className="form-control date-range-filter-input"
                    value={dateString}
                    type="date"
                    onChange={(e) =>
                        isDate(e.target.value) ? updateDateFilter(e.target.value) : null
                    }
                />
            </div>
            <div className="type-filter filter-column">
                <Select
                    aria-label={translations.AUDIT_event_type_filter_select_label}
                    styles={getSharedStyles<(typeof typeOptions)[number], true>()}
                    isMulti
                    options={typeOptions}
                    onChange={(selectedValue) => {
                        if (!selectedValue) {
                            return;
                        }
                        updateLogTypeFilter(selectedValue);
                    }}
                    placeholder={translations.AUDIT_FILTER_all_event_types}
                    value={filterTypes}
                    noOptionsMessage={() => translations.COMMON_SELECT_no_results_found}
                    id="type-filter"
                />
            </div>
            <div className="user-filter filter-column">
                <Select
                    aria-label={translations.AUDIT_user_filter_select_label}
                    styles={getSharedStyles<(typeof userOptions)[number], true>()}
                    isMulti
                    options={userOptions}
                    onChange={(selectValue) => updateUserFilter(selectValue)}
                    placeholder={translations.AUDIT_FILTER_all_users}
                    value={filterUsers}
                    noOptionsMessage={() => translations.COMMON_SELECT_no_results_found}
                    id="user-filter"
                />
            </div>
            <div className="filter-column">
                <ButtonDefault
                    title={translations.AUDIT_FILTER_refresh_results}
                    onClick={fetchLogs}
                >
                    <ArrowsClockwise weight="fill" size={18} />
                </ButtonDefault>
                <ButtonDefault
                    title={translations.AUDIT_FILTER_download_csv}
                    onClick={generateCsv}
                    disabled={downloadingCsv}
                >
                    <DownloadSimple weight="fill" size={18} />
                </ButtonDefault>
            </div>
        </div>
    );
};

export default AuditLogsFilters;
