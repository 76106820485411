import { useEffect, useState } from 'react';
import { BUSINESS_RULES_ELEMENTS, MAP_ELEMENT_TYPES, UI_ELEMENTS } from '../../../../constants';
import translations from '../../../../translations';
import { isNullOrEmpty } from '../../../../utils/guard';
import { stringReplace } from '../../../../utils/string';
import { getByID } from '../../../../utils/collection';
import ButtonDefault from '../../../buttons/ButtonDefault';
import ButtonPrimary from '../../../buttons/ButtonPrimary';
import FormGroup from '../../../generic/FormGroup';
import { useGraph } from '../../../../../js/components/graph/GraphProvider';
import Loader from '../../../loader/Loader';
import ConfigLayoutWrapper from '../common/ConfigLayoutWrapper';
import type { MapElement, Outcome } from '../../../../types';

type Props = {
    dismissMapElementConfig: () => void;
    container?: HTMLElement | undefined;
    outcomeId: string;
    sourceMapElementProp: MapElement;
    targetMapElementProp: MapElement;
};

const OutcomeSourceRedirect = ({
    dismissMapElementConfig,
    container,
    outcomeId,
    sourceMapElementProp,
    targetMapElementProp,
}: Props) => {
    const {
        saveMapElements,
        refreshFlow,
    }: { saveMapElements: (mapElements: MapElement[]) => Promise<void>; refreshFlow: () => void } =
        useGraph();
    const [sourceMapElement, setSourceMapElement] = useState<MapElement | null>(null);
    const [targetMapElement, setTargetMapElement] = useState<MapElement | null>(null);
    const [outcome, setOutcome] = useState<Outcome | null>(null);
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const title = translations.OUTCOME_confirm_move;
    const message = stringReplace(
        translations.OUTCOME_confirm_move_message,
        targetMapElement?.developerName,
    );
    const elementType = MAP_ELEMENT_TYPES.outcome;

    // Target
    const targetHasBusinessRules = targetMapElement
        ? BUSINESS_RULES_ELEMENTS.includes(targetMapElement?.elementType)
        : false;
    const targetIsUIElement = targetMapElement
        ? UI_ELEMENTS.includes(targetMapElement?.elementType)
        : false;

    // Source
    const sourceHasBusinessRules = sourceMapElement
        ? BUSINESS_RULES_ELEMENTS.includes(sourceMapElement?.elementType)
        : false;
    const sourceIsUIElement = sourceMapElement
        ? UI_ELEMENTS.includes(sourceMapElement?.elementType)
        : false;

    // Validation
    const isLabelValid = !isNullOrEmpty(outcome?.label);
    const isNameValid = !isNullOrEmpty(outcome?.developerName);
    const formValid = (isLabelValid || !targetIsUIElement) && isNameValid;

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        setSourceMapElement(sourceMapElementProp);
        setTargetMapElement(targetMapElementProp);
        setOutcome(getByID(outcomeId, sourceMapElementProp?.outcomes ?? []) as Outcome);
    }, []);

    const removeOutcome = (outcomes: Outcome[]) => {
        const outcomeArr = outcomes;

        return outcomeArr.filter((oc) => {
            return oc.id !== outcomeId;
        });
    };

    const updateOutcomeProperties = (editedOutcome: Outcome) => {
        if (!targetHasBusinessRules && sourceHasBusinessRules) {
            editedOutcome.comparison = null;
        }

        if (!targetIsUIElement && sourceIsUIElement) {
            editedOutcome.label = null;
            editedOutcome.pageActionBindingType = 'SAVE';
            editedOutcome.pageActionType = null;
        }

        editedOutcome.controlPoints = null;

        return editedOutcome;
    };

    const onSave = async () => {
        setHasSubmitted(true);

        if (formValid && sourceMapElement && targetMapElement && outcome) {
            const source = sourceMapElement;
            source.outcomes = removeOutcome(source.outcomes ?? []);

            const target = targetMapElement;
            const editedOutcome = updateOutcomeProperties(outcome);

            if (target.outcomes === null || target.outcomes === undefined) {
                target.outcomes = [];
            }

            editedOutcome.order = target.outcomes.length;
            target.outcomes.push(editedOutcome);

            await saveMapElements([source, target]);
            dismissMapElementConfig();
        }
    };

    const onCancel = () => {
        // Reset the flow canvas
        refreshFlow();
        dismissMapElementConfig();
    };

    const renderBusinessRulesUi = (
        <p>
            <strong>Warning:</strong> Target map element does not have a Business Rules section.
            Moving this outcome will lose all Business Rule data.
        </p>
    );

    const renderIsUiElementUi = (
        <div className="margin-bottom-large">
            <FormGroup
                label="Label"
                isRequired
                isValid={isLabelValid}
                validationMessage="Please enter a label."
                showValidation={hasSubmitted}
                htmlFor="label-input"
            >
                <input
                    id="label-input"
                    value={outcome?.label ?? ''}
                    onChange={({ target: { value } }) =>
                        setOutcome({ ...outcome, label: value } as Outcome)
                    }
                    size={25}
                    required
                    className="form-control"
                    type="text"
                />
                <span className="help-block">A label is required to move this outcome.</span>
            </FormGroup>
        </div>
    );

    const renderBody = () => (
        <>
            <p>{message}</p>
            <div className="margin-top">
                {targetIsUIElement && !sourceIsUIElement ? renderIsUiElementUi : null}
                {!targetHasBusinessRules && sourceHasBusinessRules ? renderBusinessRulesUi : null}
            </div>
        </>
    );

    const renderFooter = () => (
        <>
            <ButtonDefault className="flex-child-right" onClick={onCancel}>
                {translations.GRAPH_config_panel_cancel}
            </ButtonDefault>
            <ButtonPrimary className="margin-left" onClick={onSave}>
                {translations.GRAPH_config_panel_save}
            </ButtonPrimary>
        </>
    );

    if (!(sourceMapElement && targetMapElement)) {
        return <Loader />;
    }

    return (
        <ConfigLayoutWrapper
            id={outcomeId}
            renderBody={renderBody}
            renderFooter={renderFooter}
            title={title}
            elementType={elementType}
            onHide={onCancel}
            container={container}
        />
    );
};

export default OutcomeSourceRedirect;
