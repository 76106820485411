import { type ReactNode, useEffect } from 'react';
import Loader from '../loader/Loader';
import { useFeatureFlag } from './FeatureFlagProvider';

type FeatureFlagGuardProps = {
    children?: ReactNode;
};

const FeatureFlagGuard = ({ children }: FeatureFlagGuardProps) => {
    const { isLoading, fetchFeatureFlags } = useFeatureFlag();

    useEffect(() => {
        fetchFeatureFlags();
    }, [fetchFeatureFlags]);

    return (
        <>
            {children}
            {isLoading && <Loader />}
        </>
    );
};

export default FeatureFlagGuard;
