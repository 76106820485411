import ComponentsDetail from './CustomPageComponentsDetail';
import ComponentsList from './CustomPageComponentsList';
import { useComponents } from './CustomPageComponentsProvider';
import './CustomPageComponents.less';
import ScreenRouter from '../../generic/ScreenRouter';

const CustomPageComponents = () => {
    const { COMPONENT_SCREENS, currentScreen } = useComponents();

    return (
        <div className="page-wrapper">
            <ScreenRouter currentScreen={currentScreen}>
                <ComponentsDetail screen={COMPONENT_SCREENS.componentDetail} />
                <ComponentsList screen={COMPONENT_SCREENS.componentList} />
            </ScreenRouter>
        </div>
    );
};

export default CustomPageComponents;
